import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import ReCAPTCHA from "react-google-recaptcha";
import { setCookie, getCookie } from '../common_component/cookie.js';

function Signin() {

	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	let [captchaToken, setCaptchaToken] = useState("");
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [passwordType, setPasswordType] = useState("password");
	function onChange(value) {
		setCaptchaToken(value)
	}

	useEffect(() => {
		//console.log("access_token",typeof getCookie("access_token"))
		if (getCookie("access_token") !== undefined && getCookie("organization_id") !== undefined && getCookie("organization_id") !== "") {
			let object = {};
			object.access_token = getCookie("access_token");
			object.organization_id = getCookie("organization_id");
			var headers = {
				headers: {
					"Content-Type": "application/json",
					"Accept": "*/*"
				}
			}

			axios.post(`${process.env.REACT_APP_SERVER_URL}/validate-access-token`, object, headers).then((res) => {
				if (res.data.operation == "success") {
					window.location.href = "/dashboard"
				}
			})
		}
	}, [])

	const login = (e) => {

		//let domain = (new URL(url));
		e.preventDefault();
		var object = {};
		// object.email = document.getElementById("email").value;
		// object.password = document.getElementById("password").value;
		object.email = email;
		object.password = password;
		object.token = captchaToken;

		var exp = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (object.email == "") {
			swal("Oops!", "Please enter an email id", "error");
			return false;
		}
		else if (!object.email.match(exp)) {
			swal("Oops!", "Please enter a valid email id", "error");
			return false;
		}

		if (object.password == "") {
			swal("Oops!", "Please enter password.", "error");
			return false;
		}
		else if (!isNaN(object.password)) {
			//swal("Oops!", "Please enter a valid password.", "error");
			//return false;
		}

		if (object.token == "") {
			swal("Oops!", "Please Verify the captcha", "error");
			return false;
		}
		else if (!isNaN(object.token)) {
			swal("Oops!", "Please Verify the captcha", "error");
			return false;
		}

		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*"
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, object, headers).then((res) => {

			if (res.data.operation == "success") {
				setCookie("access_token", "", -1, true);
				setCookie("organization_id", "", -1, true);
				if (res.data.quick_set_up == 0) {
					var object = {};
					var headers = {
						headers: {
							"Content-Type": "application/json",
							"token": res.data.token,
							"Accept": "*/*"
						}
					}
					axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res1) => {
						if (res1.data.length > 0) {
							setCookie('organization_id', res1.data[0].organization_id, 1, true);
							setCookie('access_token', res.data.token, 1, true);
							onsubmit();
						}
					})
				}
				else {
					setMainSubmitButtonState(false)
					setCookie('access_token', res.data.token, 1, true);
					window.location.href = "/quick-set-up";
				}
			}
			else {
				setMainSubmitButtonState(false)
				if (res.data.message != "danger") {
					if (res.data.message == "Non Admin") {
						swal({
							title: "New set up required",
							text: "Do you continue a new set up for your organization",
							icon: "warning",
							buttons: {
								okay: "Sign Out",
								redirect: "Proceed"
							}
						})
							.then((val) => {
								switch (val) {
									case "okay": setCookie("access_token", "", -1, true); setCookie("organization_id", "", -1, true); break;
									case "redirect": setCookie('access_token', res.data.token, 1, true); window.location.href = "/quick-set-up"; break;
								}
							})
					}
					else {
						swal("Oops!", res.data.message, "error").then((res) => {
							window.location.reload();
						});
					}
				}
				else {
					swal("Oops!", "Username or Password is wrong", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
	}

	const onsubmit = () => {
		setMainSubmitButtonState(false);
		if (getCookie("lastpage") != "") {
			window.location.href = getCookie("lastpage");
		}
		else {
			window.location.href = "/dashboard"
		}
	}

	const togglePassword = () => {
		if (passwordType === "password") {
			setPasswordType("text")
			return;
		}
		setPasswordType("password")
	}

	return (
		<>
			<div className="d-flex flex-column flex-root" id="kt_app_root">

				<div className="d-flex flex-column flex-lg-row flex-column-fluid">

					<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2" style={{ backgroundImage: "url(assets/media/misc/auth-bg.png)" }}>

						<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">

							<a href="https://www.spoterp.com" className="mb-12">
								<img alt="Logo" src={`${process.env.REACT_APP_LOGO}`} className="h-75px" />
							</a>

							<img className="mx-auto w-275px w-md-50 w-xl-600px mb-10 mb-lg-20" src="https://cdnstatic.adoerp.com/static/spot-erp-login-screen.png" alt="" />

							<h1 className="text-white fs-2qx fw-bolder text-center mb-7">SpotERP - Ultimate Business Management Solution!</h1>

							<div className="text-white fs-base text-center">Ready to streamline your business operations like never before?
								<br /><a href="https://app.spoterp.com/register" className="opacity-75-hover text-warning fw-bold me-1">Join</a> thousands of businesses that are maximizing their <a href="https://www.spoterp.com/erp-software-features.html" className="opacity-75-hover text-warning fw-bold me-1">productivity</a> with <a href="https://www.spoterp.com" className="opacity-75-hover text-warning fw-bold me-1">SpotERP</a>.
								<br />Signing up is quick and easy & it only takes a minute!</div>

						</div>

					</div>

					<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1">

						<div className="d-flex flex-center flex-column flex-lg-row-fluid">

							<div className="w-lg-500px p-10">

								<form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="../../demo1/dist/index.html" action="#">

									<div className="text-center mb-11">

										<h1 className="text-dark fw-bolder mb-3">Sign In</h1>

										{/* <div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div> */}

									</div>
									<div className="fv-row mb-8">

										<input type="text" placeholder="Email" name="email" value={email} onChange={(e) => { setEmail(e.target.value); }} autoComplete="off" className="form-control bg-transparent" />
									</div>
									<div className="position-relative mb-3">
										<input className="form-control bg-transparent" type={passwordType} placeholder="Password" name="password" autoComplete="off" value={password} onChange={(e) => { setPassword(e.target.value); }} />
										<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={(e) => { togglePassword(e) }}>
											{passwordType === "password" ?
												<i className="bi bi-eye-slash fs-2"></i>
												:
												<i className="bi bi-eye fs-2"></i>}
										</span>
									</div>

									<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
										<div></div>

										<a href="/forgot-password-email" className="link-primary">Forgot Password ?</a>

									</div>

									<div className="d-grid mb-10">
										<div className="text-center">
											<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={onChange} className="btn btn-lg" size="compact" />
											<br />
											<button type="submit" onClick={(e) => { login(e) }} data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} className="btn btn-lg btn-primary w-100 mb-5">
												<span className="indicator-label">Login</span>
												<span className="indicator-progress">Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
										</div>
									</div>

									<div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
										<a href="/register" className="link-primary"> Sign up</a></div>

								</form>

							</div>

						</div>

						<div className="d-flex flex-center flex-wrap px-5">
							<div className="d-flex fw-semibold text-primary fs-base">
								<a href="https://www.spoterp.com/terms.html" className="px-5" target="_blank">Terms</a>
								<a href="https://www.spoterp.com/faq.html" className="px-5" target="_blank">FAQs</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Signin;