import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import 'react-dropzone-uploader/dist/styles.css'
import moment from 'moment';

import { Modal } from 'react-bootstrap';
import { Pagination } from "antd";

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from "../common_component/PageError.js";
import StatusBadge from "../common_component/StatusBadge.js";

function AccountCategory(props) {
    const [state, setState] = useState(2);


    const [addAccCatModalShow, setAddAccCatModalShow] = useState(false)
    const [editAccCatModalShow, setEditAccCatModalShow] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)

    const [newCategory, setNewCategory] = useState({ name: "", description: "" })
    const [accountCategoryList, setAccountCategoryList] = useState([])

    const [editCategory, SetEditCategory] = useState({ name: "", description: "" })

    const [submitButton, SetSubmitButton] = useState(false)

    var page_name = "account-category";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getaccountcategories() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-account-category`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setAccountCategoryList(body.data)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getaccountcategories();
            Promise.all([p1])
                .then(() => {
                    setState(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setState(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    const editSetup = (category) => {
        SetEditCategory(category)
        setEditAccCatModalShow(true);
    }


    const newCategorySubmit = () => {
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 \/\\&|@#()\\[\]_.,:"']*)?$/;
        //Name check
        if (newCategory.name === "") {
            swal("Warning!", "Category must have name", "warning");
            return
        }
        if (!newCategory.name.match(nexp)) {
            swal("Warning!", "Category name cannot contain a number or symbols", "warning");
            return
        }
        // let flag = false
        // accountCategoryList.map((category) => {
        //     // if (category.wid !== object.wid) {
        //     if (category.name.trim().toLowerCase() === newCategory.name.toLowerCase()) {
        //         flag = true

        //     }
        //     // }
        // })
        // if (flag) {
        //     swal("Warning!", "Name already exists", "warning");
        //     return
        // }

        let object = { ...newCategory, page_name: page_name, organization_id: organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        SetSubmitButton(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-account-category`, object, headers).then((res) => {
            SetSubmitButton(false)
            if (res.data.operation === "success") {
                swal("Great!", res.data.message, "success").then((value) => {
                    // window.location.reload();
                    getaccountcategories()
                    setAddAccCatModalShow(false)
                });
            }
            else {
                swal("Oops!", res.data.message.substring(0, 30), "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const editCategorySubmit = () => {
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 \/\\&|@#()\\[\]_.,:"']*)?$/;
        //Name check
        if (editCategory.name === "") {
            swal("Warning!", "Category must have name", "warning");
            return
        }
        if (!editCategory.name.match(nexp)) {
            swal("Warning!", "Category name cannot contain a number or symbols", "warning");
            return
        }
        let flag = false
        accountCategoryList.map((category) => {
            if (category.uq_ac !== editCategory.uq_ac) {
                if (category.name.trim().toLowerCase() === editCategory.name.toLowerCase()) {
                    flag = true
                }
            }
        })
        if (flag) {
            swal("Warning!", "Name already exists", "warning");
            return
        }

        let object = { ...editCategory, page_name: page_name, service: "accounting" };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        // console.log(object)
        SetSubmitButton(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-account-category`, object, headers).then((res) => {
            SetSubmitButton(false)
            // if (res.data.operation ==== "same_email") {
            //     swal("Oops!", "Email already exists!!", "error");
            // }
            // else 
            if (res.data.operation === "success") {
                swal("Great!", res.data.message, "success").then((value) => {
                    // window.location.reload();
                    getaccountcategories()
                    setEditAccCatModalShow(false)
                });
            }
            else {
                swal("Oops!", res.data.message.substring(0, 30), "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const filteredList = useMemo(() => {
        if (searchValue) {
            return accountCategoryList.filter((x) => {
                let r = new RegExp(searchValue, "gi")
                if (r.test(x.name) ||
                    r.test(x.add_date_time) ||
                    r.test(x.tax_status)
                ) {
                    return x
                }
            })
        }
        else {
            return accountCategoryList
        }
    }, [accountCategoryList, searchValue])

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Account Category

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className='btn btn-success' onClick={(e) => { setAddAccCatModalShow(true) }}><i class="las la-plus"></i>
                                Add New
                            </button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className='mb-5'>
                                                <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                            </div>

                                            <div className="mb-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    showQuickJumper={true}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                />
                                            </div>
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Name</th>
                                                            <th className="min-w-150px">Default</th>
                                                            <th className="min-w-150px">Add Date</th>
                                                            <th className="min-w-150px">Status</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredList.length > 0 ?
                                                                filteredList.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((obj) => {
                                                                    return (
                                                                        <tr key={obj.uq_ac}>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.name}</span>
                                                                            </td>
                                                                            <td>
                                                                                {obj.default ? <span className="badge badge-secondary me-auto rounded">Default</span> : <span className="badge badge-warning me-auto rounded">Custom</span>}
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.add_date_time).format('hh:mm A, DD MMM YYYY')}</span>
                                                                            </td>
                                                                            <td>
                                                                                <StatusBadge status={obj.status} entity={""} />
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <div className="dropdown" style={{ position: "unset" }}>
                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="fas fa-bars px-0"></i>
                                                                                    </button>
                                                                                    {
                                                                                        write &&
                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                            <li><span onClick={() => { editSetup(obj) }} className="dropdown-item cursor-pointer">Edit details</span></li>
                                                                                        </ul>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={5} className="text-center">
                                                                        <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="mt-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <PageError />
                        }
                    </div>
                </div>

            </div>
            <Modal show={addAccCatModalShow} onHide={() => { setAddAccCatModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Category</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddAccCatModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Name</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { setNewCategory({ ...newCategory, name: e.target.value }) }}
                                    placeholder='Name of Category'
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Description</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <textarea type="text" className="form-control form-control-solid"
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { setNewCategory({ ...newCategory, description: e.target.value }) }}
                                    placeholder='Description for account category'
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded me-auto" onClick={() => { setAddAccCatModalShow(false) }}>Close</button>
                    {
                        // write === 1 &&
                        <button className="btn btn-success rounded" data-kt-indicator={submitButton ? "on" : "off"} disabled={submitButton} onClick={() => { newCategorySubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={editAccCatModalShow} onHide={() => { setEditAccCatModalShow(false) }} size="lg" centered>
                {/* <Modal show={addAccCatModalShow} size="lg" centered> */}
                <Modal.Header>
                    <Modal.Title>Edit Category</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setEditAccCatModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Name</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid"
                                    value={editCategory.name}
                                    onChange={(e) => { SetEditCategory({ ...editCategory, name: e.target.value }) }}
                                    placeholder='Name of Category'
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Description</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <textarea type="text" className="form-control form-control-solid"
                                    // value={primaryContact.firstName}
                                    onChange={(e) => { SetEditCategory({ ...editCategory, description: e.target.value }) }}
                                    placeholder='Description for account category'
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded me-auto" onClick={() => { setEditAccCatModalShow(false) }}>Close</button>
                    {
                        // write === 1 &&
                        <button className="btn btn-success rounded" data-kt-indicator={submitButton ? "on" : "off"} disabled={submitButton} onClick={() => { editCategorySubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AccountCategory;