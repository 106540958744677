import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import swal from 'sweetalert';
import { Tooltip } from 'antd';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';

import { barConfig, lineConfig, pieConfig } from '../common_component/nivoGraphConfiguration.js';
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import formatNumber from '../common_component/formatNumber.js';
import { useTheme } from "./theme-provider.js"

function Dashboard() {
	const [state, setstate] = useState(2);
	// const { theme } = useTheme()

	const [purchase_flag, setpurchase_flag] = useState("");
	const [subscription_details, setsubscription_details] = useState(null);

	const [starting_date, setstarting_date] = useState(moment().format("YYYY-MM-DD HH:mm"));
	const [ending_date, setending_date] = useState("");
	const [verification_date, setverification_date] = useState("");
	const [duration, setduration] = useState("");

	const [verification_flag, setverification_flag] = useState("");
	const [message_flag, setmessage_flag] = useState("");
	const [verification_status, setverification_status] = useState("");
	const [verification_id, setverification_id] = useState("");

	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [Organization_admin, setOrganization_admin] = useState("");



	const [baseCurrency, setBaseCurrency] = useState(null);

	const [insightDropdownOpen, setInsightDropdownOpen] = useState(false)
	const [selectedInsightOption, setSelectedInsightOption] = useState("Today")
	const [insightsData, setInsightsData] = useState(null)
	const insightsApiController = useRef(new AbortController())
	const [insightsApiState, setInsightsApiState] = useState(false)

	const [collectiveOrderInvoiceData, setCollectiveOrderInvoiceData] = useState([])
	const [latestCollectiveData, setLatestCollectiveData] = useState(null)

	const [totalCountInStock, setTotalCountInStock] = useState(0)
	const [totalCommittedStock, setTotalCommittedStock] = useState(0)
	const [pendingItems, setPendingItems] = useState(null)

	const [totalReceivablesData, setTotalReceivablesData] = useState(null)
	const [totalPayablesData, setTotalPayablesData] = useState(null)

	const [expenseData, setExpenseData] = useState(null)

	const [monthlyInvoiceSummary, setMonthlyInvoiceSummary] = useState(null)
	const [monthlyBillSummary, setMonthlyBillSummary] = useState(null)
	const [monthlyExpenseSummary, setMonthlyExpenseSummary] = useState(null)

	const [cashFlowDropdownOpen, setCashFlowDropdownOpen] = useState(false)
	const [selectedCashFlowOption, setSelectedCashFlowOption] = useState("Last 7 days")
	const [cashFlowData, setCashFlowData] = useState(null)



	const [sendInvitationModalShow, setSendVerificationModalShow] = useState(false);
	const [resendVerificationModalShow, setResendVerificationModalShow] = useState(false);

	var page_name = "dashboard"

	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	let formatNumberOptions = {
		removeTrailingZeros: true
	}

	async function getbasecurrency() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		//console.log(body)
		if (body.length > 0) {
			setBaseCurrency(body[0]);
		}
	}

	async function getpurchaseflagdetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-active-plan-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success") {
			if(body.data.length==1 && body.data.find(x=>x.purchase_flag == "Trial" && x.add_status=="Active")!==undefined)
			{
				setending_date(moment(body.data[0].ending_date, "YYYY-MM-DD HH:mm"));
				setsubscription_details(body.data[0]);
				setpurchase_flag("Trial");
			}
			else if(body.data.find(x=>x.purchase_flag == "Purchase" && x.add_status=="Active")!==undefined)
			{
				setpurchase_flag("Purchase");
			}

		}
	}

	async function getOrganizationAdmin() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-Organization-Admin`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.data.length > 0) {
			setOrganization_admin(body.data[0].user_id);
		}
	}

	async function getverificationstatus() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-verification-status`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.length > 0) {
			//console.log(body);
			setverification_status(body[0].verification_status);
			setverification_flag(body[0].verification_flag);
			setmessage_flag(body[0].message_flag);
			setverification_id(body[0].unique_id);

			if (body[0].mail_date_time != undefined) {
				setverification_date(moment(body[0].mail_date_time).format("YYYY-MM-DD HH:mm"));
				setduration(-moment.duration(moment(body[0].mail_date_time).diff(starting_date)).asHours());
			}
		}
	}


	async function getInsightsData(io) {
		try {
			if (insightsApiState) {
				insightsApiController.current.abort()
				insightsApiController.current = new AbortController()
			}
			setInsightsApiState(true);
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-insights-data`, { signal: insightsApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "insight_option": io, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			// console.log("body", body);

			setInsightsApiState(false);
			if (body.operation === "success") {
				setInsightsData(body.data)
			}
		} catch (err) {
			if (err.name !== 'AbortError') {
				console.log(err)
			}
		}
	}

	async function getCollectiveInvoiceOrderData() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-collective-order-invoice-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setCollectiveOrderInvoiceData(body.data);

			if (body.data.length > 0) {
				setLatestCollectiveData(body.data.find(item => moment(item.date).isSame(moment(), 'day')))
			}
		}
	}

	async function getTotalStockData() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-total-stock-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setTotalCountInStock(body.data.totalCountInStock)
			setTotalCommittedStock(body.data.totalCommittedStock)
		}
	}

	async function getPendingItems() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-pending-items`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setPendingItems(body.data)
		}
	}

	async function getTotalReceivablesData() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-total-receivables-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setTotalReceivablesData(body.data)
		}
	}

	async function getTotalPayablesData() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-total-payables-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setTotalPayablesData(body.data)
		}
	}

	async function getExpenseData() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-expense-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setExpenseData(body.data)
		}
	}

	async function getMonthlyInvoiceSummary() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-monthly-invoice-summary`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setMonthlyInvoiceSummary(body.data)
		}
	}

	async function getMonthlyBillSummary() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-monthly-bill-summary`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setMonthlyBillSummary(body.data)
		}
	}

	async function getMonthlyExpenseSummary() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-monthly-expense-summary`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setMonthlyExpenseSummary(body.data)
		}
	}

	async function getCashFlowData() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-cash-flow-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log("body", body);

		if (body.operation === "success") {
			setCashFlowData(body.data)
		}
	}

	useEffect(() => {
		if ((read || write) && (user.email && visibility)) {

			let parr = [
				getbasecurrency(),
				getpurchaseflagdetails(),
				getOrganizationAdmin(),
				getverificationstatus(),
				getInsightsData(selectedInsightOption),
				getCollectiveInvoiceOrderData(),
				getTotalStockData(),
				getPendingItems(),
				getTotalReceivablesData(),
				getTotalPayablesData(),
				getExpenseData(),
				getMonthlyInvoiceSummary(),
				getMonthlyBillSummary(),
				getMonthlyExpenseSummary(),
				getCashFlowData(),
			]

			Promise.all(parr)
				.then(async () => {
					setstate(1)
					console.log("all apis done")
				})
				.catch((err) => {
					setstate(3)
					console.log(err)
				})
		}
	}, [user, visibility, read, write]);

	const sendverificationmail = (id) => {
		console.log(id)
		let values = { "id": id, "page_name": page_name, "organization_id": organization_id };

		setMainSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/send-verification-mail`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Mail sent successfully!", "success")
				setSendVerificationModalShow(false);
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	const Resendverificationmail = (id) => {
		console.log(id)
		let values = { "id": id, "page_name": page_name, "organization_id": organization_id };

		setMainSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/resend-verification-email`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Mail sent successfully!", "success")
				setResendVerificationModalShow(false);
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	const customOrderInvoiceBarConfig = {
		...barConfig,
		keys: [
			'Sale Order',
			'Sale Invoice'
		],
		indexBy: "date",
		margin: { top: 30, right: 0, bottom: 80, left: 60 },
		groupMode: "grouped",
		valueScale: { type: 'linear' },
		indexScale: { type: 'band', round: true },
		borderColor: {
			from: 'color',
			modifiers: [
				[
					'darker',
					1.6
				]
			]
		},
		axisBottom: {
			tickSize: 1,
			tickPadding: 4,
			tickRotation: 0
		},
		enableLabel: false,
		// legends:[
		// 	{
		// 		dataFrom: 'keys',
		// 	  anchor: 'top-right',
		// 	  direction: 'column',
		// 	  justify: false,
		// 	  translateX: '0',
		// 	  translateY: '0',
		// 	  itemsSpacing: 2,
		// 	  itemWidth: 100,
		// 	  itemHeight: 20,
		// 	  itemDirection: 'left-to-right',
		// 	  itemOpacity: 0.75,
		// 	  symbolSize: 12,
		// 	  symbolShape: 'circle',
		// 	  // ... other legend props
		// 	}
		//   ],
		legends: [
			{
				dataFrom: 'keys',
				anchor: 'bottom',
				direction: 'row',
				justify: false,
				translateX: 0,
				translateY: 60,
				itemWidth: 85,
				itemHeight: 20,
				itemsSpacing: 12,
				symbolSize: 20,
				itemDirection: 'left-to-right'
			}
		],
		colors: { scheme: 'pastel1' }, // Override color scheme
	};

	const cashFlowLineConfig = {
		...lineConfig,
		margin: { top: 50, right: 50, bottom: 50, left: 50 },
		xScale: { type: 'point' },
		yScale: {
			type: 'linear',
			min: 'auto',
			max: 'auto',
			stacked: true,
			reverse: false
		},
		curve: "monotoneX",
		colors: { scheme: 'category10' },
		tooltip: ({ point }) => {
			return <div className='bg-white shadow fs-6 p-5' style={{ transform: "translate(60%, 60%)" }}>
				<table>
					<tbody>
						<tr>
							<td className='text-end text-gray-500 pe-5'>Opening Balance</td>
							<td>{baseCurrency.currency_symbol} {formatNumber(point.data.opening_balance, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</td>
						</tr>
						<tr>
							<td className='text-end text-success pe-5'>Incoming</td>
							<td>{baseCurrency.currency_symbol} {formatNumber(point.data.incoming, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</td>
						</tr>
						<tr>
							<td className='text-end text-danger pe-5'>Outgoing</td>
							<td>{baseCurrency.currency_symbol} {formatNumber(point.data.outgoing, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</td>
						</tr>
						<tr>
							<td className='text-end text-primary pe-5'>Ending Balance</td>
							<td>{baseCurrency.currency_symbol} {formatNumber(point.data.y, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</td>
						</tr>
					</tbody>
				</table>
			</div>
		},
		animate: false,
		lineWidth: 3,
		pointSize: 15,
		pointColor: { theme: 'background' },
		pointBorderWidth: 2,
		pointBorderColor: { from: 'serieColor' },
		enableTouchCrosshair: true,
		useMesh: true,
	};

	const customSalesPurchaseExpenseBarConfig = {
		...barConfig,
		keys: [
			'sales',
			'purchase',
			'expense'
		],
		indexBy: "month",
		margin: { top: 30, right: 0, bottom: 80, left: 60 },
		padding: 0.3,
		groupMode: "grouped",
		valueScale: { type: 'linear' },
		indexScale: { type: 'band', round: true },
		colors: ({ id }) => id === "sales" ? "#50cd89" : id === "purchase" ? "#ffc700" : "#f1416c",
		borderColor: {
			from: 'color',
			modifiers: [
				[
					'darker',
					1.6
				]
			]
		},
		axisBottom: {
			tickSize: 1,
			tickPadding: 4,
			tickRotation: 0
		},
		enableLabel: false,
		legends: [
			{
				dataFrom: 'keys',
				anchor: 'bottom',
				direction: 'row',
				justify: false,
				translateX: 0,
				translateY: 60,
				itemWidth: 85,
				itemHeight: 20,
				itemsSpacing: 50,
				symbolSize: 20,
				itemDirection: 'left-to-right'
			}
		],
	};

	const customTopExpensesPieConfig = {
		...pieConfig,
		margin: { top: -170, right: 30, bottom: 0, left: 30 },
		colors: { scheme: 'pastel1' }, // Override color scheme
	};

	const insightBox = (amount, percentage, boxIcon, label) => {

		let bgcolor, tcolor, icon
		if (percentage > 0) {
			bgcolor = "badge-light-success"
			tcolor = "text-success"
			icon = "bi-arrow-up"
		}
		else if (percentage < 0) {
			bgcolor = "badge-light-danger"
			tcolor = "text-danger"
			icon = "bi-arrow-down"
		}
		else {
			bgcolor = "badge-secondary"
			tcolor = "text-black"
			icon = "bi-dash"
		}

		return <Tooltip title={<div className='fs-2'>{baseCurrency.currency_symbol} {formatNumber(amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>} >
			<div className="bg-gray-100 bg-opacity-70 rounded-2 p-3">
				<div>
					{boxIcon}
					<span className="fw-semibold fs-6">{label}</span>
				</div>

				<div className="m-0">
					<span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">{baseCurrency.currency_symbol} {formatNumber(amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</span>
					<span className={`badge ${bgcolor} fs-base`}>
						<i className={`bi ${icon} ${tcolor}`}></i>{Math.round(Math.abs(percentage) * 100) / 100}%
					</span>
				</div>
			</div>
		</Tooltip>
	}

	const calculateCashFlowData = (option) => {

		let start_date
		if (option === "Last 7 days") {
			start_date = moment().subtract(6, "days")
		}
		else if (option === "Last 30 days") {
			start_date = moment().subtract(29, "days")
		}
		else if (option === "Last 6 months") {
			start_date = moment().subtract(5, "months").startOf("month")
		}
		else if (option === "Last 12 months") {
			start_date = moment().subtract(11, "months").startOf("month")
		}
		// console.log(start_date.format("YYYY-MM-DD"));


		let fr = []
		if (option === "Last 7 days" || option === "Last 30 days") {
			let acc_incoming = cashFlowData.reduce((p, o) => moment(o.date).isBefore(moment(start_date).subtract(1, "day")) ? p + o.incoming : p, 0)
			let acc_outgoing = cashFlowData.reduce((p, o) => moment(o.date).isBefore(moment(start_date).subtract(1, "day")) ? p + o.outgoing : p, 0)
			let op_bal = acc_incoming - acc_outgoing

			for (let i = start_date; i.isBefore(moment()); i.add(1, 'day')) {
				let t1 = cashFlowData.find(x => x.date === i.format("YYYY-MM-DD"))

				let end_bal = op_bal + (t1?.incoming || 0) - (t1?.outgoing || 0)
				fr.push({
					"x": i.format("Do"),
					"y": end_bal,
					"opening_balance": op_bal,
					"incoming": t1?.incoming || 0,
					"outgoing": t1?.outgoing || 0
				})
				op_bal = end_bal
			}
		}
		else {
			let monthly_grouped_data = cashFlowData.reduce((p, o) => {
				let t = p.find(x => x.date === moment(o.date).format("YYYY-MM"))
				if (t) {
					t.incoming += o.incoming
					t.outgoing += o.outgoing
				} else {
					p.push({
						date: moment(o.date).format("YYYY-MM"),
						incoming: o.incoming,
						outgoing: o.outgoing,
					})
				}
				return p
			}, [])
			// console.log(monthly_grouped_data);

			let acc_incoming = monthly_grouped_data.reduce((p, o) => moment(o.date).isBefore(moment(start_date).subtract(1, "day")) ? p + o.incoming : p, 0)
			let acc_outgoing = monthly_grouped_data.reduce((p, o) => moment(o.date).isBefore(moment(start_date).subtract(1, "day")) ? p + o.outgoing : p, 0)
			let op_bal = acc_incoming - acc_outgoing

			for (let i = start_date; i.isBefore(moment()); i.add(1, 'month')) {
				let t1 = monthly_grouped_data.find(x => x.date === i.format("YYYY-MM"))

				let end_bal = op_bal + (t1?.incoming || 0) - (t1?.outgoing || 0)

				fr.push({
					"x": i.format("MMM, YY"),
					"y": end_bal,
					"opening_balance": op_bal,
					"incoming": t1?.incoming || 0,
					"outgoing": t1?.outgoing || 0
				})
				op_bal = end_bal
			}
		}

		// console.log(fr)

		return fr
	}

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dashboard
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{
							state == 1 ?
								<>
									{
										purchase_flag === "Trial" &&
										<>
											{
												(Math.floor(moment.duration(ending_date.diff(starting_date)).asDays()) > 0 && Math.floor(moment.duration(ending_date.diff(starting_date)).asDays()) < 7) ?
													<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-6">
														<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
																<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
															</svg>
														</span>
														<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
															<div className="mb-3 mb-md-0 fw-bold">
																<h4 className="text-gray-800 fw-bolder">Activate Subscription Plan.</h4>
																{
																	Math.floor(moment.duration(ending_date.diff(starting_date)).asDays()) > 1 ?
																		<div className="fs-6 text-gray-600 pe-7">Trial plan expires in {Math.floor(moment.duration(ending_date.diff(starting_date)).asDays())} days.</div> :
																		0 < Math.floor(moment.duration(ending_date.diff(starting_date)).asDays()) < 1 &&
																		<div className="fs-6 text-gray-600 pe-7">Trial plan expires in less than 1 days.</div>
																}
															</div>
															{
																(write && user.email == Organization_admin) ?
																	<a href={`${process.env.REACT_APP_PAYMENT_SERVER_URL}/subscription-list`} className="btn btn-primary px-6 align-self-center text-nowrap rounded">Buy plan</a>
																	:
																	<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Buy plan</button>
															}
														</div>
													</div>
													:
													Math.floor(moment.duration(ending_date.diff(starting_date)).asDays()) < 0 &&
													<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 mb-6">
														<span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
															<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
																<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
															</svg>
														</span>
														<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
															<div className="mb-3 mb-md-0 fw-bold">
																<h4 className="text-gray-800 fw-bolder">Activate Subscription Plan.</h4>
																<div className="fs-6 text-gray-600 pe-7">Trial plan has expired.</div>
															</div>
															{
																write ?
																	<a href={`${process.env.REACT_APP_PAYMENT_SERVER_URL}/subscription-list`} className="btn btn-primary px-6 align-self-center text-nowrap rounded">Buy plan</a> :
																	<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Buy plan</button>
															}
														</div>
													</div>
											}


										</>
									}

									{
										verification_flag === "not verified" &&
										<>
											{verification_date !== "" && Math.floor((process.env.REACT_APP_VALIDATION_MAIL_EXPIRY * 24) - duration) > 0 ?
												<>
													{(verification_id != "" && message_flag == "later") &&
														<div className="notice d-flex p-5 mb-5 border rounded shadow-lg" style={{ backgroundColor: "#e0f7ef", borderColor: "#00ae73" }}>
															<i className="bi bi-envelope"></i>
															<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
																<div className="mb-3 mb-md-0 fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Send Verfication Mail.</h4>
																	{Math.floor((process.env.REACT_APP_VALIDATION_MAIL_EXPIRY * 24) - duration) > 1 ?
																		<div className="fs-6 text-gray-600 pe-7">Verfication Mail expires in {Math.floor((process.env.REACT_APP_VALIDATION_MAIL_EXPIRY * 24) - duration)} hrs.</div>
																		:
																		0 < Math.floor(duration) < 1 &&
																		<div className="fs-6 text-gray-600 pe-7">Verfication Mail expires in less than 1 hrs.</div>
																	}
																</div>
																{
																	(write && duration < Math.floor(process.env.REACT_APP_VALIDATION_MAIL_EXPIRY * 24)) &&
																	<button type="button" onClick={(e) => { sendverificationmail(verification_id); }} disabled={mainSubmitButtonState} className="btn btn-primary px-6 align-self-center text-nowrap rounded"><span className="indicator-label" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>Send</span>
																		<span className="indicator-progress">Please wait...
																			<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
																}
																{
																	(write && duration > Math.floor(process.env.REACT_APP_VALIDATION_MAIL_EXPIRY * 24)) &&
																	<button type="button" onClick={(e) => { Resendverificationmail(verification_id); }} disabled={mainSubmitButtonState} className="btn btn-primary px-6 align-self-center text-nowrap rounded"><span className="indicator-label" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>Resend</span>
																		<span className="indicator-progress">Please wait...
																			<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
																}
															</div>
														</div>
													}
												</>
												:
												<div className="notice d-flex p-5 mb-5 border rounded shadow-lg" style={{ backgroundColor: "#e0f7ef", borderColor: "#00ae73" }}>
													<i className="bi bi-envelope ps-5 display-5"></i>
													<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
														<div className="mb-3 mb-md-0 fw-bold ps-3">
															<h4 className="text-gray-800 fw-bolder">Send Verfication Mail.</h4>
															{
																<div className="fs-6 text-gray-600 pe-7">Verfication Mail has expired..</div>
															}
														</div>
														{
															(verification_date !== "" && write && duration < Math.floor(process.env.REACT_APP_VALIDATION_MAIL_EXPIRY * 24)) &&
															<button type="button" onClick={(e) => { sendverificationmail(verification_id); }} disabled={mainSubmitButtonState} className="btn btn-primary px-6 align-self-center text-nowrap rounded"><span className="indicator-label" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>Send</span>
																<span className="indicator-progress">Please wait...
																	<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
														}
														{
															(verification_date !== "" && write && duration > Math.floor(process.env.REACT_APP_VALIDATION_MAIL_EXPIRY * 24)) &&
															<button type="button" onClick={(e) => { Resendverificationmail(verification_id); }} disabled={mainSubmitButtonState} className="btn btn-primary px-6 align-self-center text-nowrap rounded"><span className="indicator-label" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>Resend</span>
																<span className="indicator-progress">Please wait...
																	<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
														}
													</div>
												</div>
											}
										</>
									}

									{/* CHARTS */}
									{/* ///////////////////////////////////////////////////////////////////////////////////// */}

									<div className="row gy-5 mt-5 g-xl-10">
										<div className="col-xl-5">
											<div className="card card-flush h-lg-100">
												<div className="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px h-background-wavy" data-bs-theme="light">
													<div className="card-title flex-column pt-15 m-0 w-100">
														<div className="fw-bold mb-3">AI-Driven Insights</div>
														<div className=''>
															<div className='position-relative cursor-pointer' tabIndex={-1} onClick={() => { setInsightDropdownOpen(!insightDropdownOpen) }} onBlur={() => { setInsightDropdownOpen(false) }} >
																<span>
																	{selectedInsightOption}
																	{insightDropdownOpen ? <i className="bi bi-caret-up-fill text-black"></i> : <i className="bi bi-caret-down-fill text-black"></i>}
																	{insightsApiState && <div className="spinner-border spinner-border-sm ms-3"></div>}
																</span>
																<div className='position-absolute' style={{ opacity: insightDropdownOpen ? "1" : "0", visibility: insightDropdownOpen ? "visible" : "hidden", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "rgb(172, 138, 184)", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1 }}>
																	{
																		["Today", "Yesterday", "Last 7 days", "Last 30 days"].map((x, i) => {
																			return <div key={i} className="bg-hover-info rounded p-2" style={selectedInsightOption === x ? { backgroundColor: "lightsteelblue" } : null} onClick={() => { setSelectedInsightOption(x); getInsightsData(x) }}>{x}</div>
																		})
																	}
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="card-body mt-n15">
													<div className="position-relative" style={{ marginTop: "-8em" }}>
														<div className="row g-3">
															<div className="col-4">
																{
																	insightBox(
																		insightsData.co_results.current,
																		insightsData.co_results.percentage,
																		<div className="symbol symbol-30px me-2">
																			<span className="symbol-label">
																				<i className="bi bi-receipt fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Order"
																	)
																}
															</div>
															<div className="col-4">
																{
																	insightBox(
																		insightsData.ci_results.current,
																		insightsData.ci_results.percentage,
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-cash-stack fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Invoice"
																	)
																}
															</div>
															<div className="col-4">
																{
																	insightBox(
																		insightsData.pay_results.current,
																		insightsData.pay_results.percentage,
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-wallet2 fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Payments"
																	)
																}
															</div>

															<div className="col-4">
																{
																	insightBox(
																		0,
																		0,
																		<div className="symbol symbol-30px me-2">
																			<span className="symbol-label">
																				<i className="bi bi-receipt fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Credit Note"
																	)
																}
															</div>
															<div className="col-4">
																{
																	insightBox(
																		0,
																		0,
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-cash-stack fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Sales Return"
																	)
																}
															</div>
															<div className="col-4">
																{
																	insightBox(
																		insightsData.exp_results.current,
																		insightsData.exp_results.percentage * -1,
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-wallet2 fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Expenses"
																	)
																}
															</div>

															<div className="col-4">
																{
																	insightBox(
																		insightsData.po_results.current,
																		insightsData.po_results.percentage * -1,
																		<div className="symbol symbol-30px me-2">
																			<span className="symbol-label">
																				<i className="bi bi-receipt fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Purch. Order"
																	)
																}
															</div>
															<div className="col-4">
																{
																	insightBox(
																		insightsData.bill_results.current,
																		insightsData.bill_results.percentage * -1,
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-cash-stack fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Bills"
																	)
																}
															</div>
															<div className="col-4">
																{
																	insightBox(
																		insightsData.bp_results.current,
																		insightsData.bp_results.percentage * -1,
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-wallet2 fs-1 text-info"></i>
																			</span>
																		</div>,
																		"Bill Payments"
																	)
																}
															</div>
														</div>
														<hr />
														<div className="row g-3">

															<div className="col-4">
																<div className="bg-gray-100 bg-opacity-70 rounded-2 p-3">
																	<div>
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-bag-check-fill fs-1 text-primary"></i>
																			</span>
																		</div>
																		<span className="fw-semibold fs-6">Available Stock</span>
																	</div>

																	<div className="m-0">
																		<Tooltip title={<div className='fs-2'>{formatNumber(totalCountInStock, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>} >
																			<span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">{formatNumber(totalCountInStock, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</span>
																		</Tooltip>
																	</div>
																</div>
															</div>
															<div className="col-4">
																<div className="bg-gray-100 bg-opacity-70 rounded-2 p-3">
																	<div>
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-bag-dash-fill fs-1 text-warning"></i>
																			</span>
																		</div>
																		<span className="fw-semibold fs-6">Commit Stock</span>
																	</div>

																	<div className="m-0">
																		<Tooltip title={<div className='fs-2'>{formatNumber(totalCommittedStock, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>} >
																			<span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">{formatNumber(totalCommittedStock, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</span>
																		</Tooltip>
																	</div>
																</div>
															</div>
															<div className="col-4">
																<div className="bg-gray-100 bg-opacity-70 rounded-2 p-3">
																	<div>
																		<div className="symbol symbol-30px">
																			<span className="symbol-label">
																				<i className="bi bi-bag-plus-fill fs-1 text-success"></i>
																			</span>
																		</div>
																		<span className="fw-semibold fs-6">Inbound Stock</span>
																	</div>

																	<div className="m-0">
																		<Tooltip title={<div className='fs-2'>{formatNumber(pendingItems.reduce((p, o) => p + o.total_pending_count, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>} >
																			<span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">{formatNumber(pendingItems.reduce((p, o) => p + o.total_pending_count, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}</span>
																		</Tooltip>
																	</div>
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="col-xl-7">
											<div className="card card-flush h-xl-100">
												<div className="card-header">
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-800">Date wise Order & Invoice</span>
														<span className="text-gray-500 mt-1 fw-semibold fs-6">{moment().subtract(7, 'days').format('ll')} - {moment().format('ll')}</span>
													</h3>
												</div>
												<div className="card-body py-0 overflow-auto">
													<div style={{ width: "100%", height: "400px", minWidth: "500px" }}>
														<ResponsiveBar
															data={
																collectiveOrderInvoiceData.map((obj) => ({
																	"date": moment(obj.date).format("D MMM"),
																	"Sale Order": obj.customer_order_amount,
																	"Sale Invoice": obj.customer_invoice_amount
																}))
															}
															{...customOrderInvoiceBarConfig}
														/>
													</div>
												</div>
												<div className='card-footer py-2'>
													<div className='row'>
														<div className='col-6' style={{ borderRight: "1px lightgray solid" }}>
															<label className='form-label fs-3 text-success'>Total Order Amount</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(collectiveOrderInvoiceData.reduce((p, o) => p + o.customer_order_amount, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
														<div className='col-6'>
															<label className='form-label fs-3 text-warning'>Total Invoice Amount</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(collectiveOrderInvoiceData.reduce((p, o) => p + o.customer_invoice_amount, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="row my-5">
										<div className="col-md-6 my-1">
											<div className="card">
												<div className="card-header min-h-50px">
													<div className="card-title">
														<span className="card-label fs-3 fw-bold text-gray-900">Total Receivables</span>
														<Tooltip title="Current and overdue amount that you're yet to receive from customers">
															<i className="fa fa-info-circle mx-1 fs-4 text-hover-primary cursor-pointer" ></i>
														</Tooltip>
													</div>
												</div>
												<div className="card-body">
													<div className='fs-3'>
														<span className='px-3'>Total Unpaid Invoices</span>
														<span className='px-3'>{baseCurrency.currency_symbol} {formatNumber(totalReceivablesData ? totalReceivablesData.current.amount + totalReceivablesData.overdue.amount : 0, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>
													</div>
													<div className="progress h-10px w-100 mt-3">
														{
															totalReceivablesData &&
															<>
																<Tooltip title={<div><div className='fs-2'>{baseCurrency.currency_symbol} {formatNumber(totalReceivablesData.current.amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div><div>Current</div></div>} ><div className="progress-bar bg-warning" style={{ width: `${totalReceivablesData.current.amount / (totalReceivablesData.current.amount + totalReceivablesData.overdue.amount) * 100}%` }}></div></Tooltip>
																<Tooltip title={<div><div className='fs-2'>{baseCurrency.currency_symbol} {formatNumber(totalReceivablesData.overdue.amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div><div>Overdue</div></div>} ><div className="progress-bar bg-danger" style={{ width: `${totalReceivablesData.overdue.amount / (totalReceivablesData.current.amount + totalReceivablesData.overdue.amount) * 100}%` }}></div></Tooltip>
															</>
														}
													</div>
												</div>
												<div className='card-footer py-2'>
													<div className='row'>
														<div className='col-6' style={{ borderRight: "1px lightgray solid" }}>
															<label className='form-label fs-3 text-warning'>Current</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(totalReceivablesData ? totalReceivablesData.current.amount : 0, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
														<div className='col-6'>
															<label className='form-label fs-3 text-danger'>Overdue</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(totalReceivablesData ? totalReceivablesData.overdue.amount : 0, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-6 my-1">
											<div className="card">
												<div className="card-header min-h-50px">
													<div className="card-title">
														<span className="card-label fs-3 fw-bold text-gray-900">Total Payables</span>
														<Tooltip title="Current and overdue amount that you're yet to pay your suppliers">
															<i className="fa fa-info-circle mx-1 fs-4 text-hover-primary cursor-pointer" ></i>
														</Tooltip>
													</div>
												</div>
												<div className="card-body">
													<div className='fs-3'>
														<span className='px-3'>Total Unpaid Bills</span>
														<span className='px-3'>{baseCurrency.currency_symbol} {formatNumber(totalPayablesData ? totalPayablesData.current.amount + totalPayablesData.overdue.amount : 0, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>
													</div>
													<div className="progress h-10px w-100 mt-3">
														{
															totalPayablesData &&
															<>
																<Tooltip title={<div><div className='fs-2'>{baseCurrency.currency_symbol} {formatNumber(totalPayablesData.current.amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div><div>Current</div></div>} ><div className="progress-bar bg-warning" style={{ width: `${totalPayablesData.current.amount / (totalPayablesData.current.amount + totalPayablesData.overdue.amount) * 100}%` }}></div></Tooltip>
																<Tooltip title={<div><div className='fs-2'>{baseCurrency.currency_symbol} {formatNumber(totalPayablesData.overdue.amount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div><div>Overdue</div></div>} ><div className="progress-bar bg-danger" style={{ width: `${totalPayablesData.overdue.amount / (totalPayablesData.current.amount + totalPayablesData.overdue.amount) * 100}%` }}></div></Tooltip>
															</>
														}
													</div>
												</div>
												<div className='card-footer py-2'>
													<div className='row'>
														<div className='col-6' style={{ borderRight: "1px lightgray solid" }}>
															<label className='form-label fs-3 text-warning'>Current</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(totalPayablesData ? totalPayablesData.current.amount : 0, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
														<div className='col-6'>
															<label className='form-label fs-3 text-danger'>Overdue</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(totalPayablesData ? totalPayablesData.overdue.amount : 0, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="row my-5">
										<div className="col-12 my-1">
											<div className="card">
												<div className="card-header min-h-50px">
													<div className='card-title flex-grow-1'>
														<span className="card-label fs-3 fw-bold text-gray-900">Cash Flow</span>
														<Tooltip title="Amount of money moving in and out of your business">
															<i className="fa fa-info-circle mx-1 fs-4 text-hover-primary cursor-pointer" ></i>
														</Tooltip>

														<div className='position-relative cursor-pointer ms-auto' tabIndex={-1} onClick={() => { setCashFlowDropdownOpen(!cashFlowDropdownOpen) }} onBlur={() => { setCashFlowDropdownOpen(false) }} >
															<span>
																{selectedCashFlowOption}
																{cashFlowDropdownOpen ? <i className="bi bi-caret-up-fill text-black"></i> : <i className="bi bi-caret-down-fill text-black"></i>}
															</span>
															<div className='position-absolute' style={{ opacity: cashFlowDropdownOpen ? "1" : "0", visibility: cashFlowDropdownOpen ? "visible" : "hidden", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "rgb(172, 138, 184)", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, right: 0 }}>
																{
																	["Last 7 days", "Last 30 days", "Last 6 months", "Last 12 months",].map((x, i) => {
																		return <div key={i} className="bg-hover-info rounded p-2" style={selectedCashFlowOption === x ? { backgroundColor: "lightsteelblue" } : null} onClick={() => { setSelectedCashFlowOption(x); }}>{x}</div>
																	})
																}
															</div>
														</div>
													</div>
												</div>
												<div className="card-body py-0 h-cash-flow-wrapper">
													<div className='row' style={{ minWidth: "1000px" }}>
														<div className='col-10' style={{ borderRight: "1px lightgray solid" }}>
															<div style={{ width: "100%", height: "400px" }}>
																<ResponsiveLine
																	data={
																		[
																			{
																				"id": "main",
																				"data": calculateCashFlowData(selectedCashFlowOption)
																			}
																		]
																	}
																	{...cashFlowLineConfig}
																/>
															</div>
														</div>
														<div className='col-2 text-end'>
															<div className='py-5'>
																<label className='fs-4 text-success'>Incoming</label>
																<div className='fs-1'><span className='fw-bold px-2'>{baseCurrency.currency_symbol} {formatNumber(cashFlowData.reduce((p, o) => p + o.incoming, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>+</div>
															</div>
															<div className='py-5'>
																<label className='fs-4 text-danger'>Outgoing</label>
																<div className='fs-1'><span className='fw-bold px-2'>{baseCurrency.currency_symbol} {formatNumber(cashFlowData.reduce((p, o) => p + o.outgoing, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>-</div>
															</div>
															<div className='py-5'>
																<label className='fs-4 text-primary'>Cash as on {moment().format("YYYY-MM-DD")}</label>
																<div className='fs-1'><span className='fw-bold px-2'>{baseCurrency.currency_symbol} {formatNumber(cashFlowData.reduce((p, o) => p + o.incoming, 0) - cashFlowData.reduce((p, o) => p + o.outgoing, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span>=</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="row my-5">
										<div className="col-md-8 my-1">
											<div className="card">
												<div className="card-header min-h-50px">
													<div className='card-title'>
														<span className="card-label fs-3 fw-bold text-gray-900">Sales and Purchase</span>
														<Tooltip title="Displays total Sales, Purchase and Expense for current year">
															<i className="fa fa-info-circle mx-1 fs-4 text-hover-primary cursor-pointer" ></i>
														</Tooltip>
													</div>
												</div>
												<div className="card-body py-0" style={{ overflowX: "auto" }}>
													<div className="me-10" style={{ width: "100%", height: "400px", minWidth: "700px" }}>
														<ResponsiveBar
															data={
																monthlyInvoiceSummary.map(x => {
																	let t = monthlyBillSummary.find(y => ((y.month === x.month) && (y.year === x.year)))
																	let t2 = monthlyExpenseSummary.find(y => ((y.month === x.month) && (y.year === x.year)))

																	return {
																		month: moment(`${x.year}/${x.month}/01`).format("MMM YY"),
																		sales: x.totalInvoiceAmount,
																		purchase: t.total_bill_amount,
																		expense: t2.total_expense_amount
																	}
																})
															}
															{...customSalesPurchaseExpenseBarConfig}
														/>
													</div>
												</div>
												<div className='card-footer py-2'>
													<div className='row'>
														<div className='col-4' style={{ borderRight: "1px lightgray solid" }}>
															<label className='form-label fs-3 text-success'>Total Sales</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(monthlyInvoiceSummary.reduce((p, o) => p + o.totalInvoiceAmount, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
														<div className='col-4' style={{ borderRight: "1px lightgray solid" }}>
															<label className='form-label fs-3 text-warning'>Total Purchase</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(monthlyBillSummary.reduce((p, o) => p + o.total_bill_amount, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
														<div className='col-4'>
															<label className='form-label fs-3 text-danger'>Total Expense</label>
															<div className='fs-3 fw-bold'>{baseCurrency.currency_symbol} {formatNumber(monthlyExpenseSummary.reduce((p, o) => p + o.total_expense_amount, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-4 my-1">
											<div className="card">
												<div className="card-header min-h-50px">
													<div className="card-title">
														<span className="card-label fs-3 fw-bold text-gray-900">Top Expenses</span>
														<Tooltip title="Top expenses across various categories for the chosen period">
															<i className="fa fa-info-circle mx-1 fs-4 text-hover-primary cursor-pointer" ></i>
														</Tooltip>
													</div>
												</div>
												<div className="card-body p-2">
													<div className='d-flex align-items-center justify-content-center' style={{ height: "500px" }}>
														<ResponsivePie
															data={
																expenseData.reduce((p, o, i, arr) => {
																	p.push({
																		"id": o.account_name,
																		"label": o.account_name,
																		"value": o.totalApprovedAmount
																	})

																	if (i === arr.length - 1) {
																		p.push({
																			"id": "Pending Approval",
																			"label": "Pending Approval",
																			"value": arr.reduce((p, o) => p + o.totalPendingApprovalAmount, 0)
																		})
																	}

																	return p
																}, []).filter(x => x.value !== 0).toSorted((a, b) => b.value - a.value).slice(0, 7)
															}
															{...customTopExpensesPieConfig}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
								:
								<>
									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Dashboard</h2>
														</div>
													</div>

													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2"></div>
														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>
															</div>
														</div>
													</div>

												</div>
											</div>

										</>
									}
								</>
						}

					</div>
				</div>
			</div>

			<Modal show={sendInvitationModalShow} onHide={() => { setSendVerificationModalShow() }} size="md" centered>
				<Modal.Header>
					<Modal.Title>Send Invitation</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setSendVerificationModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<div className="col-lg-12">
							<h3>Are you sure about send invitation?</h3>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={(e) => { setSendVerificationModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write && <form >
						<button type="button" onClick={(e) => { sendverificationmail() }} className="btn btn-danger" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Send</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>

			<Modal show={resendVerificationModalShow} onHide={() => { setResendVerificationModalShow() }} size="md" centered>
				<Modal.Header>
					<Modal.Title>Resend Invitation</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setResendVerificationModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<div className="col-lg-12">
							<h3>Are you sure about resend invitation?</h3>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={(e) => { setResendVerificationModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write && <form >
						<button type="button" onClick={(e) => { Resendverificationmail() }} className="btn btn-danger" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Resend</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>
		</>
	);
}
export default Dashboard;