import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Collapse } from 'antd';
import { Link, Redirect } from "react-router-dom";
import PageError from '../common_component/PageError.js';
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

function AdminDashboard() {
	let [state, setstate] = useState(2);
	const [pageList, setPageList] = useState([])
	const [filteredPageList, setFilteredPageList] = useState([])
	const [tempPageList, setTempPageList] = useState([])
	const [searchPage, setSearchPage] = useState('');
    const [activeKey, setActiveKey] = useState([]);
	const { Panel } = Collapse;

	var page_name = "admin-dashboard";

	const { user, read, write, visibility, organization_id, user_role, rolePermissionDetails } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		if (rolePermissionDetails !== null) {
			setTempPageList(rolePermissionDetails.find(x => x.key == "Admin View").inside_components)
		}
	}, [rolePermissionDetails])

	useEffect(() => {
		if (tempPageList.length > 0) {
			const basicReports = [];
			const otherReports = [];

			for (const obj of tempPageList) {
				if (!obj.hasOwnProperty("key") && obj.read === 1 && obj.write === 1 && Object.keys(obj)[0] !== "admin-dashboard") {
					basicReports.push({ report_name: "BASIC PAGES", pages: [{ page_name: Object.keys(obj)[0].replaceAll("-", " ").toUpperCase(), page_url: Object.keys(obj)[0] }] });
				} else {
					const nestedReports = [];
					if (obj.inside_components) {
						for (const innerObj of obj.inside_components) {
							//console.log(Object.keys(innerObj))
							if (innerObj.read === 1 && innerObj.write === 1 && !Object.keys(innerObj)[0].includes("update")) {
								nestedReports.push({ page_name: Object.keys(innerObj)[0].replaceAll("-", " ").toUpperCase(), page_url: Object.keys(innerObj)[0] });
							}
						}
					}
					if (nestedReports.length > 0) {
						otherReports.push({ report_name: obj.key.replaceAll("_", " ").toUpperCase(), pages: nestedReports });
					}
				}
			}

			const basicReportObj = basicReports.length > 0 ? { report_name: "BASIC PAGES", pages: basicReports.reduce((acc, curr) => acc.concat(curr.pages), []) } : null;
			setstate(1);
			setPageList([basicReportObj, ...otherReports]);
			setFilteredPageList([basicReportObj, ...otherReports]);
		}

	}, [tempPageList])

	function searchPages(searchTerm) {
		const results = [];
		searchTerm = searchTerm.toLowerCase(); // Convert search term to lowercase for case-insensitive matching

		for (const report of pageList) {
			const matchingPages = report.pages.filter(page => {
				const pageNameLower = page.page_name.toLowerCase();
				const pageUrlLower = page.page_url.toLowerCase();

				return (
					pageNameLower.includes(searchTerm) ||
					pageUrlLower.includes(searchTerm) ||
					report.report_name.toLowerCase().includes(searchTerm)
				);
			});

			if (matchingPages.length) {
				results.push({
					report_name: report.report_name,
					pages: matchingPages,
				});
			} else if (searchTerm === "") { // Handle case where search term is empty (return all reports)
				results.push(report);
			}
		}

		return results;
	}

	useEffect(() => {
		if (searchPage !== "") {
			const searchRes = searchPages(searchPage);
			setFilteredPageList(searchRes);
			setActiveKey(new Array(searchRes.length).fill(0).map((_, i) => i));
		}
		else {
			setFilteredPageList(pageList);
			setActiveKey([]);
		}
	}, [searchPage])

	const handleChange = (key) => {
		const newActiveKey = activeKey.includes(key)
			? activeKey.filter((item) => item !== key) // Reset for clicked key
			: key; // Update for newly clicked key
		setActiveKey(newActiveKey);
	};

	return (
		<>
			{(user !== null && user.email) ?
				<>
					<div className="d-flex flex-column flex-column-fluid">
						<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
							<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
								<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Admin Dashboard
									<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
									<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
								</h1>
								<div className="d-flex align-items-stretch">

								</div>
							</div>
						</div>
						<div id="kt_app_content" className="app-content flex-column-fluid">
							<div id="kt_app_content_container" className="app-container container-xxl">
								{
									state === 1 ?
										<>
											<div className="card mb-5 mb-xl-8">
												<div className="card-body py-3">
													<div className='m-5'>
														<input type="text" className='form-control fw-bold border border-primary' value={searchPage} onChange={(e) => { setSearchPage(e.target.value) }} placeholder='Search Page...' />
													</div>
													<Collapse onChange={handleChange} activeKey={activeKey}>
														{filteredPageList.length > 0 ?
															<>
																{filteredPageList.map((report, index) => {
																	return (
																		<Panel header={report.report_name} key={index}>
																			<div class="d-flex align-items-center gap-2 gap-lg-3 flex-wrap">
																				{report.pages.map((page, index1) => {
																					return (
																						<>
																							<Link to={page.page_url} class="btn btn-sm btn-light d-flex align-items-center">
																								<div class="text-gray-600 fw-bold">{page.page_name}</div>
																							</Link>
																						</>
																					)
																				})}
																			</div>
																		</Panel>)
																})}
															</>
															:
															<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
																<div className="d-flex flex-stack flex-grow-1">
																	<div className="fw-bold">
																		<h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
																		<div className="fs-6 text-gray-600"> </div>
																	</div>
																</div>
															</div>}
													</Collapse>
												</div>
											</div>
										</>
										:
										state === 2 ?
											<div className="card">
												<div className="card-body pt-6">
													<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
												</div>
											</div>
											:
											<PageError />
								}

							</div>
						</div>
					</div>
				</>
				:
				<>
					<div className="d-flex flex-column flex-column-fluid">
						<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
							<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
								<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Admin Dashboard
									<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
									<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
								</h1>
								<div className="d-flex align-items-stretch">

								</div>
							</div>
						</div>
						<div id="kt_app_content" className="app-content flex-column-fluid">
							<div id="kt_app_content_container" className="app-container container-xxl">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Dashboard</h2>
														</div>
													</div>


													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2">


														</div>


														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>

										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}
		</>
	);
}
export default AdminDashboard;