import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import uuid from 'react-uuid';
import moment from 'moment';

import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import country_arr from './common_arr/countries-arr.js';
import s_a from './states-arr.js';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"


function AccountOverview() {
	let [state, setstate] = useState(2);
	let [account_id, setaccount_id] = useState("");
	let [account_name, setaccount_name] = useState("");
	let [Phone, setPhone] = useState("");
	let [State, setState] = useState("");
	let [Country, setCountry] = useState("");
	let [Email, setEmail] = useState("");
	const [OrganizationDetails, setOrganizationDetails] = useState([]);
	let [image, setImage] = useState("/assets/media/avatars/blank.png");
	let [file, setfile] = useState([]);

	const [unique_id, setUnique_id] = useState(uuid());
	const [showForm, setShowForm] = useState(false);
	const [timezoneList, setTimezoneList] = useState([]);
	const [timezone, setTimezone] = useState(null);

	let [country_array, set_country_array] = useState([]);
	let [state_array, set_state_array] = useState([]);
	let [object, setobject] = useState([]);
	let [object_contact, setobject_contact] = useState([]);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

	let [folder_name, setfolder_name] = useState("profile_image");

	var page_name = "account-overview"
	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};


	function handleChange(event) {
		setImage(URL.createObjectURL(event.target.files[0]))
		setfile(event.target.files[0]);
		setMainSubmitButtonState(true)
		const file = event.target.files[0];

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		const data = new FormData()
		data.append('user_id', user.email)
		data.append('page_name', page_name)
		data.append('unique_id', unique_id)
		data.append('organization_id', organization_id)
		data.append('file', file)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/upload-profile`, data, headers)
			.then(res => {
				if (res.data && res.data.message === "success") {
					setMainSubmitButtonState(false);
				}
			})
	}

	useEffect(() => {
		var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);
	}, [])


	async function getAccountdetails() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-account-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		let obj = {}
		if (body.length > 0) {
			setstate(1)
			setaccount_id(body[0].aid)
			if (body[0].country != "") {
				loadState(body[0].country)
			}
			setTimezone(body[0].timezone + " || " + body[0].timezone_value);
			if (body[0].file_name != undefined) {
				setImage(`${process.env.REACT_APP_GENERAL_IMAGE_BUCKET}/${folder_name}/${body[0].file_name}`);
			}
			if (body[0].file_name.length == 0) {
				setImage("/assets/media/avatars/blank.png")
			}
			setaccount_name(body[0].account_name)
			setPhone(body[0].phone)
			setEmail(body[0].user_id)
			setState(body[0].state)
			setCountry(body[0].country)
		}
	}
	async function getOrganizationRelatedModules() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-organization-related-modules`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		let obj = {}
		if (body.length > 0) {
			setOrganizationDetails(body)
		}
	}

	async function getCommonFiles() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-common-files`, { method: 'POST', body: JSON.stringify({ "email": user.email, "type": "timezone", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.data.length > 0) {
			setTimezoneList(body.data.map(obj => { return { label: obj.display_name, value: obj.utc_offset } }))
		}
	}


	useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getAccountdetails();
			let p2 = getOrganizationRelatedModules();
			//let p3 = getCommonFiles();
			Promise.all([p1, p2/*, p3*/])
				.then(() => {
					// getaccountheads();
					setState(1);
					console.log("all apis done")
				})
				.catch((err) => {
					setState(0)
					// console.log(err)
				})
		}

	}, [user, visibility, read, write])

	const updateorganization = () => {
		var id = user.email;

		if (account_name == "") {
			swal("Oops!", "Account Name can't be empty", "warning");
			return false;
		}
		else if (!isNaN(account_name)) {
			swal("Oops!", "only charecters are allowed", "warning");
			return false;
		}
		if (State == "") {
			swal("Oops!", "Please select a State", "warning");
			return false;
		}
		if (Country == "") {
			swal("Oops!", "Please select a country.", "warning");
			return false;
		}

		let t1 = object_contact.find(o => {
			var regexp = /^([0|\+[0-9]{1,5})?([0-9]{1,15})$/
			if (o.phone == "") {
				swal("Oops!", "Please enter phone number", "warning");
				return false;
			}
			if (o.phone != "" && !regexp.test(o.phone)) {
				swal("Oops!", "Please enter valid phone number", "warning");
				return;
			}
		})

		var timezone = document.getElementById("timezone").value;

		var file1 = [];
		//file1 = file;
		file1.push(file.name);
		var values = { "id": account_id, "unique_id": unique_id, "account_name": account_name, "Email": Email, "Phone": Phone, "Country": Country, "State": State, "file_names": file1, "timezone": timezone, "page_name": page_name, "organization_id": organization_id };
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-account-information`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Account saved successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	function loadState(country) {
		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country) {
				selectedCountryIndex = index;
			}
		})
		set_state_array([]);
		var state_arr = s_a.s_a[selectedCountryIndex].split("|");

		var obj_array = [];
		var obj2 = {};

		obj_array.push({ value: "", label: "Select State" });
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		set_state_array(obj_array);
	}

	const updatePassword = () => {
		var exp2 = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!$%^&*-]).{8,}$/;

		if (document.getElementById("currentpassword").value == "") {
			swal("Oops!", "Please enter your current password", "error");
			return false;
		}
		// else if (document.getElementById("currentpassword").value.includes("@") || document.getElementById("currentpassword").value.includes(".")) {
		// 	swal("Warning!", "Current Password cannot contain '@' and '.' symbols within it.", "warning");
		// 	return false;
		// }
		// else if (!document.getElementById("currentpassword").value.match(exp2)) {
		// 	swal("Warning!", "Current Password is not valid, try with a different password.", "warning");
		// 	return false;
		// }
		else if (document.getElementById("newpassword").value == "") {
			swal("Warning!", "Please enter your new password", "warning");
			return false;
		}
		// else if (document.getElementById("newpassword").value.includes("@") || document.getElementById("newpassword").value.includes(".")) {
		// 	swal("Warning!", "New Password cannot contain '@' and '.' symbols within it.", "warning");
		// 	return false;
		// }
		// else if (!document.getElementById("newpassword").value.match(exp2)) {
		// 	swal("Warning!", "New Password is not valid, try with a different password.", "warning");
		// 	return false;
		// }
		else if (document.getElementById("confirmpassword").value == "") {
			swal("Warning!", "Please re-enter your new password", "warning");
			return false;
		}
		else if (document.getElementById("confirmpassword").value != document.getElementById("newpassword").value) {
			swal("Warning!", "Please check your new password and confirm password", "warning");
			return false;
		}
		if (document.getElementById("kt_password_submit")) {
			document.getElementById("kt_password_submit").setAttribute("data-kt-indicator", "on");
			document.getElementById("kt_password_submit").disabled = !0;
		}

		var object = {};
		object.email = user.email;
		object.currentpassword = document.getElementById("currentpassword").value;
		object.newpassword = document.getElementById("newpassword").value;
		object.token = getCookie("access_token");
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/generate-change-password-id`, object).then((res) => {

			if (res.data && res.data.operation == "success") {
				swal("Great!", "password changed successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error").then((value) => {
					window.location.reload();
				});
			}
		});
	}

	return (
		<>

			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Account Overview
							<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state == 1 ?
							<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
								<br />
								<div className="card mb-5 mb-xl-10">
									<div className="card-body pt-9 pb-0">
									</div>
								</div>

								<div className="card mb-5 mb-xl-10">
									<div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
										<div className="card-title m-0">
											<h3 className="fw-bolder m-0">Profile Details</h3>
										</div>
									</div>
									<div id="kt_account_profile_details" className="collapse show">
										<form id="kt_account_profile_details_form" className="form">
											<div className="card-body border-top p-9">
												<div className="row mb-6">
													<label className="col-lg-4 col-form-label fw-bold fs-6">Profile Image</label>
													<div className="col-lg-8">
														<div className="image-input image-input-outline" data-kt-image-input="true" style={{ "background-image": "url(assets/media/avatars/blank.png)" }} >
															<div className="image-input-wrapper w-125px h-125px" style={{ "background-image": `url('${image}')` }} ></div>
															<label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change profile">
																<i className="bi bi-pencil-fill fs-7"></i>
																<input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={(event) => { handleChange(event) }} />
																<input type="hidden" name="avatar_remove" />
															</label>
															{/* <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
																	<i className="bi bi-x fs-2"></i>
																</span>

																<span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
																	<i className="bi bi-x fs-2"></i>
																</span> 
																*/}
														</div>
														<div className="form-text">Allowed file types: png, jpg, jpeg.</div>
													</div>
												</div>
												<div className="row mb-6">
													<label className="col-lg-4 col-form-label required fw-bold fs-6">Name</label>
													<div className="col-lg-8">
														<div className="row">
															<div className="col-lg-6 fv-row">
																<input type="text" id="account_name" name="fname" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First name" value={account_name} onChange={(e) => { setaccount_name(e.target.value) }} />
																<span id="firstname" className="text-danger font-weight-bold"></span>
															</div>
														</div>
													</div>
												</div>
												<div className="row mb-6">
													<label className="col-lg-4 col-form-label  fw-bold fs-6">Time Zone</label>
													<div className="col-lg-6 fv-row">
														{/* <Select
															placeholder="Select Time Zone"
															options={timezoneList}
															value={timezone}
															onChange={(val) => {
																setTimezone(val);
															}}
														/> */}
														<select name="timezone" aria-label="Select a Timezone" id="timezone" data-placeholder="Select a timezone.." className="form-select form-select-solid form-select-lg" onChange={(e) => { /*console.log("abc", e); console.log(e.target.value);*/ setTimezone(e.target.value); }} value={timezone}>
															<option value="">Select a Timezone..</option>
															<option data-bs-offset="-39600" value="International Date Line West || -11:00">(GMT-11:00) International Date Line West</option>
															<option data-bs-offset="-39600" value="Midway Island || -11:00">(GMT-11:00) Midway Island</option>
															<option data-bs-offset="-39600" value="Samoa || -11:00">(GMT-11:00) Samoa</option>
															<option data-bs-offset="-36000" value="Hawaii || -11:00">(GMT-10:00) Hawaii</option>
															<option data-bs-offset="-28800" value="Alaska || -11:00">(GMT-11:00) Alaska</option>
															<option data-bs-offset="-25200" value="Pacific Time (US &amp; Canada) || -07:00">(GMT-07:00) Pacific Time (US &amp; Canada)</option>
															<option data-bs-offset="-25200" value="Tijuana || -07:00">(GMT-07:00) Tijuana</option>
															<option data-bs-offset="-25200" value="Arizona">(GMT-07:00) Arizona</option>
															<option data-bs-offset="-21600" value="Mountain Time (US &amp; Canada) || -06:00">(GMT-06:00) Mountain Time (US &amp; Canada)</option>
															<option data-bs-offset="-21600" value="Chihuahua || -06:00">(GMT-06:00) Chihuahua</option>
															<option data-bs-offset="-21600" value="Mazatlan || -06:00">(GMT-06:00) Mazatlan</option>
															<option data-bs-offset="-21600" value="Saskatchewan || -06:00">(GMT-06:00) Saskatchewan</option>
															<option data-bs-offset="-21600" value="Central America ||-06:00">(GMT-06:00) Central America</option>
															<option data-bs-offset="-18000" value="Central Time (US &amp; Canada) || -05:00">(GMT-05:00) Central Time (US &amp; Canada)</option>
															<option data-bs-offset="-18000" value="Guadalajara || -05:00">(GMT-05:00) Guadalajara</option>
															<option data-bs-offset="-18000" value="Mexico City || -05:00">(GMT-05:00) Mexico City</option>
															<option data-bs-offset="-18000" value="Monterrey || -05:00">(GMT-05:00) Monterrey</option>
															<option data-bs-offset="-18000" value="Bogota || -05:00">(GMT-05:00) Bogota</option>
															<option data-bs-offset="-18000" value="Lima || -05:00">(GMT-05:00) Lima</option>
															<option data-bs-offset="-18000" value="Quito || -05:00">(GMT-05:00) Quito</option>
															<option data-bs-offset="-14400" value="Eastern Time (US &amp; Canada) || -04:00">(GMT-04:00) Eastern Time (US &amp; Canada)</option>
															<option data-bs-offset="-14400" value="Indiana (East) || -04:00">(GMT-04:00) Indiana (East)</option>
															<option data-bs-offset="-14400" value="Caracas || -04:00">(GMT-04:00) Caracas</option>
															<option data-bs-offset="-14400" value="La Paz || -04:00">(GMT-04:00) La Paz</option>
															<option data-bs-offset="-14400" value="Georgetown || -04:00">(GMT-04:00) Georgetown</option>
															<option data-bs-offset="-10800" value="Atlantic Time (Canada) || -03:00">(GMT-03:00) Atlantic Time (Canada)</option>
															<option data-bs-offset="-10800" value="Santiago || -03:00">(GMT-03:00) Santiago</option>
															<option data-bs-offset="-10800" value="Brasilia || -03:00">(GMT-03:00) Brasilia</option>
															<option data-bs-offset="-10800" value="Buenos Aires || -03:00">(GMT-03:00) Buenos Aires</option>
															<option data-bs-offset="-9000" value="Newfoundland || -02:30">(GMT-02:30) Newfoundland</option>
															<option data-bs-offset="-7200" value="Greenland || -02:00">(GMT-02:00) Greenland</option>
															<option data-bs-offset="-7200" value="Mid-Atlantic || -02:00">(GMT-02:00) Mid-Atlantic</option>
															<option data-bs-offset="-3600" value="Cape Verde Is. || -01:00">(GMT-01:00) Cape Verde Is.</option>
															<option data-bs-offset="0" value="Azores || +00:00">(GMT) Azores</option>
															<option data-bs-offset="0" value="Monrovia || +00:00">(GMT) Monrovia</option>
															<option data-bs-offset="0" value="UTC || +00:00">(GMT) UTC</option>
															<option data-bs-offset="3600" value="Dublin || +01:00">(GMT+01:00) Dublin</option>
															<option data-bs-offset="3600" value="Edinburgh || +01:00">(GMT+01:00) Edinburgh</option>
															<option data-bs-offset="3600" value="Lisbon || +01:00">(GMT+01:00) Lisbon</option>
															<option data-bs-offset="3600" value="London || +01:00">(GMT+01:00) London</option>
															<option data-bs-offset="3600" value="Casablanca || +01:00">(GMT+01:00) Casablanca</option>
															<option data-bs-offset="3600" value="West Central Africa || +01:00">(GMT+01:00) West Central Africa</option>
															<option data-bs-offset="7200" value="Belgrade || +02:00">(GMT+02:00) Belgrade</option>
															<option data-bs-offset="7200" value="Bratislava || +02:00">(GMT+02:00) Bratislava</option>
															<option data-bs-offset="7200" value="Budapest || +02:00">(GMT+02:00) Budapest</option>
															<option data-bs-offset="7200" value="Ljubljana || +02:00">(GMT+02:00) Ljubljana</option>
															<option data-bs-offset="7200" value="Prague || +02:00">(GMT+02:00) Prague</option>
															<option data-bs-offset="7200" value="Sarajevo || +02:00">(GMT+02:00) Sarajevo</option>
															<option data-bs-offset="7200" value="Skopje || +02:00">(GMT+02:00) Skopje</option>
															<option data-bs-offset="7200" value="Warsaw || +02:00">(GMT+02:00) Warsaw</option>
															<option data-bs-offset="7200" value="Zagreb || +02:00">(GMT+02:00) Zagreb</option>
															<option data-bs-offset="7200" value="Brussels || +02:00">(GMT+02:00) Brussels</option>
															<option data-bs-offset="7200" value="Copenhagen || +02:00">(GMT+02:00) Copenhagen</option>
															<option data-bs-offset="7200" value="Madrid || +02:00">(GMT+02:00) Madrid</option>
															<option data-bs-offset="7200" value="Paris || +02:00">(GMT+02:00) Paris</option>
															<option data-bs-offset="7200" value="Amsterdam || +02:00">(GMT+02:00) Amsterdam</option>
															<option data-bs-offset="7200" value="Berlin || +02:00">(GMT+02:00) Berlin</option>
															<option data-bs-offset="7200" value="Bern || +02:00">(GMT+02:00) Bern</option>
															<option data-bs-offset="7200" value="Rome || +02:00">(GMT+02:00) Rome</option>
															<option data-bs-offset="7200" value="Stockholm || +02:00">(GMT+02:00) Stockholm</option>
															<option data-bs-offset="7200" value="Vienna || +02:00">(GMT+02:00) Vienna</option>
															<option data-bs-offset="7200" value="Cairo || +02:00">(GMT+02:00) Cairo</option>
															<option data-bs-offset="7200" value="Harare || +02:00">(GMT+02:00) Harare</option>
															<option data-bs-offset="7200" value="Pretoria || +02:00">(GMT+02:00) Pretoria</option>
															<option data-bs-offset="10800" value="Bucharest || +03:00">(GMT+03:00) Bucharest</option>
															<option data-bs-offset="10800" value="Helsinki || +03:00">(GMT+03:00) Helsinki</option>
															<option data-bs-offset="10800" value="Kiev || +03:00">(GMT+03:00) Kiev</option>
															<option data-bs-offset="10800" value="Kyiv || +03:00">(GMT+03:00) Kyiv</option>
															<option data-bs-offset="10800" value="Riga || +03:00">(GMT+03:00) Riga</option>
															<option data-bs-offset="10800" value="Sofia || +03:00">(GMT+03:00) Sofia</option>
															<option data-bs-offset="10800" value="Tallinn || +03:00">(GMT+03:00) Tallinn</option>
															<option data-bs-offset="10800" value="Vilnius || +03:00">(GMT+03:00) Vilnius</option>
															<option data-bs-offset="10800" value="Athens || +03:00">(GMT+03:00) Athens</option>
															<option data-bs-offset="10800" value="Istanbul || +03:00">(GMT+03:00) Istanbul</option>
															<option data-bs-offset="10800" value="Minsk || +03:00">(GMT+03:00) Minsk</option>
															<option data-bs-offset="10800" value="Jerusalem || +03:00">(GMT+03:00) Jerusalem</option>
															<option data-bs-offset="10800" value="Moscow || +03:00">(GMT+03:00) Moscow</option>
															<option data-bs-offset="10800" value="St. Petersburg || +03:00">(GMT+03:00) St. Petersburg</option>
															<option data-bs-offset="10800" value="Volgograd || +03:00">(GMT+03:00) Volgograd</option>
															<option data-bs-offset="10800" value="Kuwait || +03:00">(GMT+03:00) Kuwait</option>
															<option data-bs-offset="10800" value="Riyadh || +03:00">(GMT+03:00) Riyadh</option>
															<option data-bs-offset="10800" value="Nairobi || +03:00">(GMT+03:00) Nairobi</option>
															<option data-bs-offset="10800" value="Baghdad || +03:00">(GMT+03:00) Baghdad</option>
															<option data-bs-offset="14400" value="Abu Dhabi || +04:00">(GMT+04:00) Abu Dhabi</option>
															<option data-bs-offset="14400" value="Muscat || +04:00">(GMT+04:00) Muscat</option>
															<option data-bs-offset="14400" value="Baku || +04:00">(GMT+04:00) Baku</option>
															<option data-bs-offset="14400" value="Tbilisi || +04:00">(GMT+04:00) Tbilisi</option>
															<option data-bs-offset="14400" value="Yerevan || +04:00">(GMT+04:00) Yerevan</option>
															<option data-bs-offset="16200" value="Tehran || +04:30">(GMT+04:30) Tehran</option>
															<option data-bs-offset="16200" value="Kabul || +04:30">(GMT+04:30) Kabul</option>
															<option data-bs-offset="18000" value="Ekaterinburg || +05:00">(GMT+05:00) Ekaterinburg</option>
															<option data-bs-offset="18000" value="Islamabad || +05:00">(GMT+05:00) Islamabad</option>
															<option data-bs-offset="18000" value="Karachi || +05:00">(GMT+05:00) Karachi</option>
															<option data-bs-offset="18000" value="Tashkent || +05:00">(GMT+05:00) Tashkent</option>
															<option data-bs-offset="19800" value="Chennai || +05:30">(GMT+05:30) Chennai</option>
															<option data-bs-offset="19800" value="Kolkata || +05:30">(GMT+05:30) Kolkata</option>
															<option data-bs-offset="19800" value="Mumbai || +05:30">(GMT+05:30) Mumbai</option>
															<option data-bs-offset="19800" value="New Delhi || +05:30">(GMT+05:30) New Delhi</option>
															<option data-bs-offset="19800" value="Sri Jayawardenepura || +05:30">(GMT+05:30) Sri Jayawardenepura</option>
															<option data-bs-offset="20700" value="Kathmandu || +05:45">(GMT+05:45) Kathmandu</option>
															<option data-bs-offset="21600" value="Astana || +06:00">(GMT+06:00) Astana</option>
															<option data-bs-offset="21600" value="Dhaka || +06:00">(GMT+06:00) Dhaka</option>
															<option data-bs-offset="21600" value="Almaty || +06:00">(GMT+06:00) Almaty</option>
															<option data-bs-offset="21600" value="Urumqi || +06:00">(GMT+06:00) Urumqi</option>
															<option data-bs-offset="23400" value="Rangoon || +06:30">(GMT+06:30) Rangoon</option>
															<option data-bs-offset="25200" value="Novosibirsk || +07:00">(GMT+07:00) Novosibirsk</option>
															<option data-bs-offset="25200" value="Bangkok || +07:00">(GMT+07:00) Bangkok</option>
															<option data-bs-offset="25200" value="Hanoi || +07:00">(GMT+07:00) Hanoi</option>
															<option data-bs-offset="25200" value="Jakarta || +07:00">(GMT+07:00) Jakarta</option>
															<option data-bs-offset="25200" value="Krasnoyarsk || +07:00">(GMT+07:00) Krasnoyarsk</option>
															<option data-bs-offset="28800" value="Beijing || +08:00">(GMT+08:00) Beijing</option>
															<option data-bs-offset="28800" value="Chongqing || +08:00">(GMT+08:00) Chongqing</option>
															<option data-bs-offset="28800" value="Hong Kong || +08:00">(GMT+08:00) Hong Kong</option>
															<option data-bs-offset="28800" value="Kuala Lumpur || +08:00">(GMT+08:00) Kuala Lumpur</option>
															<option data-bs-offset="28800" value="Singapore || +08:00">(GMT+08:00) Singapore</option>
															<option data-bs-offset="28800" value="Taipei || +08:00">(GMT+08:00) Taipei</option>
															<option data-bs-offset="28800" value="Perth || +08:00">(GMT+08:00) Perth</option>
															<option data-bs-offset="28800" value="Irkutsk || +08:00">(GMT+08:00) Irkutsk</option>
															<option data-bs-offset="28800" value="Ulaan Bataar || +08:00">(GMT+08:00) Ulaan Bataar</option>
															<option data-bs-offset="32400" value="Seoul || +09:00">(GMT+09:00) Seoul</option>
															<option data-bs-offset="32400" value="Osaka || +09:00">(GMT+09:00) Osaka</option>
															<option data-bs-offset="32400" value="Sapporo || +09:00">(GMT+09:00) Sapporo</option>
															<option data-bs-offset="32400" value="Tokyo || +09:00">(GMT+09:00) Tokyo</option>
															<option data-bs-offset="32400" value="Yakutsk || +09:00">(GMT+09:00) Yakutsk</option>
															<option data-bs-offset="34200" value="Darwin || +09:30">(GMT+09:30) Darwin</option>
															<option data-bs-offset="34200" value="Adelaide || +09:30">(GMT+09:30) Adelaide</option>
															<option data-bs-offset="36000" value="Canberra || +10:00">(GMT+10:00) Canberra</option>
															<option data-bs-offset="36000" value="Melbourne || +10:00">(GMT+10:00) Melbourne</option>
															<option data-bs-offset="36000" value="Sydney || +10:00">(GMT+10:00) Sydney</option>
															<option data-bs-offset="36000" value="Brisbane || +10:00">(GMT+10:00) Brisbane</option>
															<option data-bs-offset="36000" value="Hobart || +10:00">(GMT+10:00) Hobart</option>
															<option data-bs-offset="36000" value="Vladivostok || +10:00">(GMT+10:00) Vladivostok</option>
															<option data-bs-offset="36000" value="Guam || +10:00">(GMT+10:00) Guam</option>
															<option data-bs-offset="36000" value="Port Moresby || +10:00">(GMT+10:00) Port Moresby</option>
															<option data-bs-offset="36000" value="Solomon Is. || +10:00">(GMT+10:00) Solomon Is.</option>
															<option data-bs-offset="39600" value="Magadan || +11:00">(GMT+11:00) Magadan</option>
															<option data-bs-offset="39600" value="New Caledonia || +11:00">(GMT+11:00) New Caledonia</option>
															<option data-bs-offset="43200" value="Fiji || +12:00">(GMT+12:00) Fiji</option>
															<option data-bs-offset="43200" value="Kamchatka || +12:00">(GMT+12:00) Kamchatka</option>
															<option data-bs-offset="43200" value="Marshall Is. || +12:00">(GMT+12:00) Marshall Is.</option>
															<option data-bs-offset="43200" value="Auckland || +12:00">(GMT+12:00) Auckland</option>
															<option data-bs-offset="43200" value="Wellington || +12:00">(GMT+12:00) Wellington</option>
															<option data-bs-offset="46800" value="Nuku'alofa || +13:00">(GMT+13:00) Nuku'alofa</option>
														</select>
														<span id="timezone1" className="text-danger font-weight-bold"></span>
													</div>
												</div>
												<div className="row mb-6">
													<label className="col-lg-4 col-form-label required fw-bold fs-6">Phone</label>
													<div className="col-lg-8">
														<div className="row">
															<div className="col-lg-6 fv-row">
																<input className="form-control" name="billing_order_city" placeholder="" value={Phone} onChange={(e) => {
																	setPhone(e.target.value);
																}} />
															</div>
														</div>
													</div>
												</div>
												<div className="row mb-6">
													<label className="col-lg-4 col-form-label required fw-bold fs-6">Email</label>
													<div className="col-lg-8">
														<div className="row">
															<div className="col-lg-6 fv-row">
																<input className="form-control" name="billing_order_city" placeholder="" value={Email} onChange={(e) => {
																	setEmail(e.target.value);
																}} readOnly />
															</div>
														</div>
													</div>
												</div>
												<div className="row mb-6">
													<label className="col-lg-4 col-form-label required fw-bold fs-6">Country</label>
													<div className="col-lg-8">
														<div className="row">
															<div className="col-lg-6 fv-row">
																<Select
																	placeholder=""
																	options={country_array}
																	onChange={(e) => {
																		setCountry(e.value);
																		loadState(e.value);

																	}}
																	value={country_array.filter(option =>
																		option.value == Country)[0]}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="row mb-6">
													<label className="col-lg-4 col-form-label required fw-bold fs-6">State</label>
													<div className="col-lg-8">
														<div className="row">
															<div className="col-lg-6 fv-row">
																<Select
																	placeholder="Select State"
																	options={state_array}
																	value={
																		state_array.filter(option =>
																			option.value == State)[0]
																	}
																	onChange={(e) => {
																		setState(e.value);
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
												<br />
											</div>
											<div className="card-footer d-flex justify-content-end py-6 px-9">
												<form >
													{
														write &&
														<button type="button" className="btn btn-primary rounded" onClick={updateorganization} data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}><span className="indicator-label">Save Changes</span>
															<span className="indicator-progress">Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
													}
												</form>
											</div>
										</form>
										<div className="d-flex flex-column gap-5 gap-md-7">
											<div className="fs-3 fw-bolder mb-n2">Organization Details</div>
											{OrganizationDetails.length > 0 && OrganizationDetails.map((obj, index) => {
												return (
													<>
														<div className="d-flex align-items-center collapsible py-3 toggle mb-0 collapsed" data-bs-toggle="collapse" data-bs-target={`#job_${obj.organization_id}`} aria-expanded="false">
															<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
																<span className="svg-icon toggle-on svg-icon-primary svg-icon-1">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
																		<rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
																	</svg>
																</span>
																<span className="svg-icon toggle-off svg-icon-1">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
																		<rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
																		<rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
																	</svg>
																</span>
															</div>
															<h4 className="text-gray-700 fw-bold cursor-pointer mb-0">{obj.organization_name}</h4>
														</div>
														<div id={`job_${obj.organization_id}`} className="fs-6 ms-1 collapse">
															<div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
																<div className="d-flex flex-wrap pt-5">
																	<div className="d-flex flex-column me-7 me-lg-16 pt-sm-3 pt-6">
																		{obj.expense == true &&
																			<div className="d-flex align-items-center mb-3 mb-sm-6">
																				<span className="bullet bullet-dot bg-primary me-2 h-10px w-10px"></span>
																				<span className="fw-bold text-gray-600 fs-6">Expense</span>
																			</div>
																		}
																		{obj.distributor == true &&
																			<div className="d-flex align-items-center">
																				<span className="bullet bullet-dot bg-danger me-2 h-10px w-10px"></span>
																				<span className="fw-bold text-gray-600 fs-6">Distributor</span>
																			</div>}
																	</div>
																	<div className="d-flex flex-column me-7 me-lg-16 pt-sm-3 pt-6">
																		{obj.invoice == true &&
																			<div className="d-flex align-items-center mb-3 mb-sm-6">
																				<span className="bullet bullet-dot bg-success me-2 h-10px w-10px"></span>
																				<span className="fw-bold text-gray-600 fs-6">invoice</span>
																			</div>
																		}
																	</div>

																	<div className="d-flex flex-column pt-sm-3 pt-6">
																		{obj.support == true &&
																			<div className="d-flex align-items-center mb-3 mb-sm-6">
																				<span className="bullet bullet-dot bg-info me-2 h-10px w-10px"></span>
																				<span className="fw-bold text-gray-600 fs-6">TV Campaign</span>
																			</div>
																		}
																	</div>
																</div>
															</div>
														</div>
													</>
												)
											})}
										</div>
									</div>
								</div>
								<div className="card mb-5 mb-xl-10">
									<div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
										<div className="card-title m-0">
											<h3 className="fw-bolder m-0">Sign-in Method</h3>
										</div>
									</div>
									<div id="kt_account_signin_method" className="collapse show">
										<div className="card-body border-top p-9">
											<div className="d-flex flex-wrap align-items-center mb-10">
												<div id="kt_signin_password" className={`${showForm ? "d-none" : ""}`}>
													<div className="fs-6 fw-bolder mb-1">Password</div>
													<div className="fw-bold text-gray-600">************</div>
												</div>
												<div id="kt_signin_password_edit" className={`${showForm ? "flex-row-fluid" : "flex-row-fluid d-none"}`}>
													<div className="row mb-1">
														<div className="col-lg-4">
															<div className="fv-row mb-0">
																<label for="currentpassword" className="form-label fs-6 fw-bolder mb-3">Current Password</label>
																<input type="password" className="form-control form-control-lg form-control-solid" name="currentpassword" id="currentpassword" />
															</div>
														</div>
														<div className="col-lg-4">
															<div className="fv-row mb-0">
																<label for="newpassword" className="form-label fs-6 fw-bolder mb-3">New Password</label>
																<input type="password" className="form-control form-control-lg form-control-solid" name="newpassword" id="newpassword" />
															</div>
														</div>
														<div className="col-lg-4">
															<div className="fv-row mb-0">
																<label for="confirmpassword" className="form-label fs-6 fw-bolder mb-3">Confirm New Password</label>
																<input type="password" className="form-control form-control-lg form-control-solid" name="confirmpassword" id="confirmpassword" />
															</div>
														</div>
													</div>
													<div className="form-text mb-5">Use 8 or more characters with a mix of letters, numbers & symbols. Password cannot contain "@" and "." Symbols within it.</div>
													<div className="d-flex">

														<button className="btn btn-primary rounded" id="kt_password_submit" onClick={(event) => { updatePassword(); }} >
															<span className="indicator-label">Update Password</span>
															<span className="indicator-progress">Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
														</button>

														<button onClick={() => { setShowForm(!showForm) }} id="kt_password_cancel" type="button" className="btn btn-color-gray-400 btn-active-light-primary px-6 rounded">Cancel</button>
													</div>
												</div>
												<div className={`ms-auto ${showForm ? "d-none" : ""}`}>
													<button onClick={() => { setShowForm(!showForm) }} className="btn btn-light btn-active-light-primary rounded">Reset Password</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<br />
							</div>
							:
							<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
								<br />

								{state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:
									<>
										<div className="flex-lg-row-fluid me-lg-15">
											<div className="card card-flush pt-3 mb-5 mb-lg-10">

												<div className="card-header">
													<div className="card-title">
														<h2 className="fw-bolder">Organization Profile</h2>
													</div>
												</div>


												<div className="card-body pt-0">
													<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
													<div className="d-flex align-items-center p-3 mb-2">


													</div>


													<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
														<div className="d-flex flex-stack flex-grow-1">
															<div className="fw-bold">
																<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																<div className="fs-6 text-gray-600">
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								}
								<br />
							</div>
						}
					</div>
				</div>
			</div>
		</>
	);
}
export default AccountOverview;