import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { Modal, OverlayTrigger, Popover, Overlay } from "react-bootstrap";

import country_arr from './common_arr/countries-arr.js';
import s_a from './states-arr.js';
import currency_arr from './common_arr/currency-arr.js';
import Select from 'react-select';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

export const ShowOrganizations = () => {
	let [state, setstate] = useState(2);
	const [defaultOrganizationId, setDefaultOrganizationId] = useState("");
	const [OrgList, setOrgList] = useState([]);
	const [pendingOrgList, setPendingOrgList] = useState([]);

	let [orgModalShow, setOrgModalShow] = useState(false);
	let [statusModalShow, setStatusModalShow] = useState(false);
	let [status, setStatus] = useState("");
	let [defaultsetupModalShow, setDefaultsetupModalShow] = useState(false);

	const [Selected_organization_id, setSelected_organization_id] = useState("");
	const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

	let [OrganizationName, setOrganizationName] = useState("");
	let [CurrencyCode, setCurrencyCode] = useState("INR");
	const [Currencylist, setCurrencylist] = useState([])
	const [Industrylist, setIndustrylist] = useState([{ label: "Agriculture", value: "Agriculture" }, { label: "Construction", value: "Construction" }, { label: "Education", value: "Education" }, { label: "Engineering", value: "Engineering" }, { label: "Entertainment", value: "Entertainment" }, { label: "Others", value: "Others" }])
	const [Languagelist, setLanguagelist] = useState([{ label: "English", value: "English" }])
	let [timezone, settimezone] = useState("");
	let [country, setcountry] = useState("");
	let [Industry, setIndustry] = useState("");
	let [Language, setLanguage] = useState("English");
	let [state1, setstate1] = useState("");
	let [country_array, set_country_array] = useState([]);
	let [state_array, set_state_array] = useState([]);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
	let [alert_count, set_alert_count] = useState({});
	let [Skip_set_up, setSkip_set_up] = useState(false);

	var page_name = "show-organizations";

	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	let dropdownStyleDark = {
		option: (provided, state) => ({
			...provided,
			color: '#dddddf',
			backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: "#2b2b40",
			borderColor: "#4c4c71"
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: "#dddddf",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#dddddf",
		}),
		menu: (provided, state) => ({
			...provided,
			backgroundColor: "#2b2b40",
		})
	}

	useEffect(() => {

		if (read || write) {
			user.email && visibility && getorganizationdetails();
		}

		var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);

	}, [user, visibility, read, write])

	useEffect(() => {
		var obj_array = [];
		var jsonParsedArray = currency_arr.currency_arr[0]
		for (let key in jsonParsedArray) {
			var obj2 = {};
			if (jsonParsedArray.hasOwnProperty(key) && key == "INR") {
				obj2.label = jsonParsedArray[key].currency_name;
				obj2.value = key;
				obj_array.push(obj2);
			}
		}
		//console.log(obj_array)
		setCurrencylist(obj_array);
	}, []);

	async function getorganizationdetails() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/show-all-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		console.log(body.data);
		if (body.data.organization_list.length > 0) {
			setstate(1);
			//console.log(body.data);
			setDefaultOrganizationId(body.data.default_org);
			setOrgList(body.data.organization_list);
			setPendingOrgList(body.data.pending_org_set_up);
		}
		else {
			setstate(0);
		}
	}

	function loadCountry(country) {

		setcountry(country.value);

		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})

		var state_arr = s_a.s_a[selectedCountryIndex].split("|");
		//console.log(state_arr)
		var obj_array = [];
		var obj2 = {};
		//obj_array.push({label:"select state",value:""});
		obj_array.push({ value: "", label: "Select State" });
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		set_state_array(obj_array);
	}

	const submitOrganization = (e) => {
		e.preventDefault();

		var values = { "page_name": page_name, "Skip_set_up": Skip_set_up, "organization_id": organization_id, "OrganizationName": OrganizationName, "country": country, "state": state1, "timezone": timezone, "CurrencyCode": CurrencyCode, "language": Language, "Industry": Industry };
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-organization`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Organization details saved successfully!", "success").then((value) => {
					//window.location.reload();
					getorganizationdetails();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	const setOrganizationAsDefault = () => {
		//setstate(2)
		var values = { "id": Selected_organization_id, "organization_id": organization_id, "page_name": page_name };
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/set-organization-as-default`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Default organization marked successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}
	const update_status_organization = (e) => {
		e.preventDefault();
		var values = { "id": Selected_organization_id, "organization_id": organization_id, "page_name": page_name };
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-organization`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Status changed successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Organization List

							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<div className="d-flex align-items-center gap-2 gap-lg-3">
							<a className="btn btn-sm fw-bold btn-primary" onClick={() => { setOrgModalShow(true) }}>Create Organization</a>
						</div>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state == 1 ?
							<>
								<div className="row g-6 g-xl-9">
									{OrgList.length > 0 && OrgList.map((obj, index) => {
										return (
											<>
												<div className="col-lg-6">
													<div className="card card-flush h-lg-100">
														<div className="card-header mt-6">
															<div className="card-title flex-column">
																<h3 className="fw-bolder mb-1">{obj.organization_name}</h3>
																{obj.add_status == "Active" ? <span className="badge badge-light-success me-auto rounded">Active</span> : <span className="badge badge-light-danger me-auto rounded">Deactivated</span>}
																{(obj.organization_id === defaultOrganizationId) && <span className="badge badge-light-primary me-auto rounded">Default</span>}
															</div>
															<div className="card-toolbar">
																<div className="dropdown">
																	{write && (obj.organization_id != defaultOrganizationId || obj.set_up_id == null) &&
																		<>
																			<button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
																				<i className="fas fa-bars px-0"></i>
																			</button>
																			<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
																				{(obj.organization_id != defaultOrganizationId) && <li className="py-1"><a onClick={(e) => { setSelected_organization_id(obj.organization_id); setDefaultsetupModalShow(true); }} className="dropdown-item">Set as default</a></li>}
																				{(obj.organization_id != defaultOrganizationId) && <li className="py-1"><a onClick={(e) => { setSelected_organization_id(obj.organization_id); setStatus(obj.add_status); setStatusModalShow(true); }} className="dropdown-item">Change Status</a></li>}
																				{obj.set_up_id == null && <li className="py-1"><Link to={`/quick-set-up/${obj.organization_id}`} className="dropdown-item">Set up organization</Link></li>}
																			</ul>
																		</>
																	}
																</div>
															</div>
														</div>
														<div className="d-flex flex-wrap">
															<div className="card-body p-9">
																<div className="fs-6 d-flex justify-content-between mb-4">
																	<div className="fw-bold">Organization Domain</div>
																	<div className="d-flex fw-bolder">
																		{obj.organization_domain}</div>
																</div>
																<div className="separator separator-dashed"></div>
																<div className="fs-6 d-flex justify-content-between my-4">
																	<div className="fw-bold">Created on</div>
																	<div className="d-flex fw-bolder">
																		{moment(obj.add_date_time).format('ll')}</div>
																</div>
																<div className="separator separator-dashed"></div>
																<div className="fs-6 d-flex justify-content-between mt-4">
																	<div className="fw-bold">Organization id</div>

																	<div className="d-flex fw-bolder">{obj.organization_id}</div>
																</div><br />
																<div className="separator separator-dashed"></div>
																<div className="fs-6 d-flex justify-content-between mt-4">
																	<div className="fw-bold">Default</div>
																	<div className="symbol-group symbol-hover">
																		{obj.default_org == "yes" &&
																			<div className="badge badge-light-success rounded">
																				Yes
																			</div>
																		}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										);
									})
									}
								</div>
							</>
							:
							<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
								<br />
								{state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:
									<>
										<div className="flex-lg-row-fluid me-lg-15">
											<div className="card card-flush pt-3 mb-5 mb-lg-10">

												<div className="card-header">
													<div className="card-title">
														<h2 className="fw-bolder">Organization List</h2>

													</div>
												</div>
												<div className="card-body pt-0">
													<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
													<div className="d-flex align-items-center p-3 mb-2">
													</div>
													<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
														<div className="d-flex flex-stack flex-grow-1">
															<div className="fw-bold">
																<h4 className="text-gray-800 fw-bolder">No Organization Available</h4>
																<div className="fs-6 text-gray-600">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								}
								<br />
							</div>
						}
					</div>
				</div>
			</div>


			<Modal show={statusModalShow} onHide={() => { setStatusModalShow() }} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Change Status</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setStatusModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<div className="col-lg-12">
							Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
							<h3>Are you sure about changing status?</h3>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={(e) => { setStatusModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					{write &&
						<button onClick={(e) => { update_status_organization(e) }} type="submit" id="kt_modal_new_address_submit" className="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
							<span className="indicator-label">Save</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
						</button>
					}
				</Modal.Footer>
			</Modal>
			<Modal show={defaultsetupModalShow} onHide={() => { setDefaultsetupModalShow() }} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Mark as default</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDefaultsetupModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<h3>Are you sure you want to mark it as a default Organization?</h3>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={(e) => { setDefaultsetupModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
					{write &&
						<button onClick={(e) => { setOrganizationAsDefault(Selected_organization_id); }} className="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
							<span className="indicator-label">Save</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
						</button>
					}
				</Modal.Footer>
			</Modal>
			<Modal show={orgModalShow} onHide={() => { setOrgModalShow() }} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Create Organization</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setOrgModalShow(false) }}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Organization name</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={OrganizationName} onChange={(e) => { setOrganizationName(e.target.value) }} />
							</div>
						</div>
						<div className="d-flex flex-column mb-5 fv-row">
							<label className="d-flex align-items-center fs-5 fw-bold mb-2">
								<span className="required">Country</span>
								<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i>
							</label>
							{
								country_array.length > 0 &&
								<Select
									styles={userTheme == 'dark' ? dropdownStyleDark : {}}
									name="country"
									id="country"
									options={country_array}
									value={
										country_array.filter(option =>
											option.value == country)[0]
									}
									onChange={(e) => { setcountry(e.value); setstate1(""); loadCountry(e); }}
								/>
							}
							<span id="country1" className="text-danger font-weight-bold"></span>
						</div>
						<div className="d-flex flex-column mb-5 fv-row">
							<label className="d-flex align-items-center fs-5 fw-bold mb-2">
								<span className="required">State</span>
								{/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i> */}
							</label>

							{
								state_array.length > 0 &&
								<Select
									styles={userTheme == 'dark' ? dropdownStyleDark : {}}
									name="state"
									id="state"
									options={state_array}
									onChange={(e) => { setstate1(e.value); }}
									value={
										state_array.filter(option =>
											option.value == state1)[0]
									}
								/>
							}
							<span id="state2" className="text-danger font-weight-bold"></span>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="col-lg-4 col-form-label required fw-bold fs-6">Time Zone</label>
								<div className="col-lg-12 fv-row">
									<select name="timezone" aria-label="Select a Timezone" id="timezone" data-control="select2" data-placeholder="Select a timezone.." className="form-select form-select-solid form-select-lg" value={timezone} onChange={(e) => { settimezone(e.target.value) }}>
										<option value="">Select a Timezone..</option>
										<option data-bs-offset="-39600" value="International Date Line West || -11:00">(GMT-11:00) International Date Line West</option>
										<option data-bs-offset="-39600" value="Midway Island || -11:00">(GMT-11:00) Midway Island</option>
										<option data-bs-offset="-39600" value="Samoa || -11:00">(GMT-11:00) Samoa</option>
										<option data-bs-offset="-36000" value="Hawaii || -11:00">(GMT-10:00) Hawaii</option>
										<option data-bs-offset="-28800" value="Alaska || -11:00">(GMT-11:00) Alaska</option>
										<option data-bs-offset="-25200" value="Pacific Time (US &amp; Canada) || -07:00">(GMT-07:00) Pacific Time (US &amp; Canada)</option>
										<option data-bs-offset="-25200" value="Tijuana || -07:00">(GMT-07:00) Tijuana</option>
										<option data-bs-offset="-25200" value="Arizona">(GMT-07:00) Arizona</option>
										<option data-bs-offset="-21600" value="Mountain Time (US &amp; Canada) || -06:00">(GMT-06:00) Mountain Time (US &amp; Canada)</option>
										<option data-bs-offset="-21600" value="Chihuahua || -06:00">(GMT-06:00) Chihuahua</option>
										<option data-bs-offset="-21600" value="Mazatlan || -06:00">(GMT-06:00) Mazatlan</option>
										<option data-bs-offset="-21600" value="Saskatchewan || -06:00">(GMT-06:00) Saskatchewan</option>
										<option data-bs-offset="-21600" value="Central America ||-06:00">(GMT-06:00) Central America</option>
										<option data-bs-offset="-18000" value="Central Time (US &amp; Canada) || -05:00">(GMT-05:00) Central Time (US &amp; Canada)</option>
										<option data-bs-offset="-18000" value="Guadalajara || -05:00">(GMT-05:00) Guadalajara</option>
										<option data-bs-offset="-18000" value="Mexico City || -05:00">(GMT-05:00) Mexico City</option>
										<option data-bs-offset="-18000" value="Monterrey || -05:00">(GMT-05:00) Monterrey</option>
										<option data-bs-offset="-18000" value="Bogota || -05:00">(GMT-05:00) Bogota</option>
										<option data-bs-offset="-18000" value="Lima || -05:00">(GMT-05:00) Lima</option>
										<option data-bs-offset="-18000" value="Quito || -05:00">(GMT-05:00) Quito</option>
										<option data-bs-offset="-14400" value="Eastern Time (US &amp; Canada) || -04:00">(GMT-04:00) Eastern Time (US &amp; Canada)</option>
										<option data-bs-offset="-14400" value="Indiana (East) || -04:00">(GMT-04:00) Indiana (East)</option>
										<option data-bs-offset="-14400" value="Caracas || -04:00">(GMT-04:00) Caracas</option>
										<option data-bs-offset="-14400" value="La Paz || -04:00">(GMT-04:00) La Paz</option>
										<option data-bs-offset="-14400" value="Georgetown || -04:00">(GMT-04:00) Georgetown</option>
										<option data-bs-offset="-10800" value="Atlantic Time (Canada) || -03:00">(GMT-03:00) Atlantic Time (Canada)</option>
										<option data-bs-offset="-10800" value="Santiago || -03:00">(GMT-03:00) Santiago</option>
										<option data-bs-offset="-10800" value="Brasilia || -03:00">(GMT-03:00) Brasilia</option>
										<option data-bs-offset="-10800" value="Buenos Aires || -03:00">(GMT-03:00) Buenos Aires</option>
										<option data-bs-offset="-9000" value="Newfoundland || -02:30">(GMT-02:30) Newfoundland</option>
										<option data-bs-offset="-7200" value="Greenland || -02:00">(GMT-02:00) Greenland</option>
										<option data-bs-offset="-7200" value="Mid-Atlantic || -02:00">(GMT-02:00) Mid-Atlantic</option>
										<option data-bs-offset="-3600" value="Cape Verde Is. || -01:00">(GMT-01:00) Cape Verde Is.</option>
										<option data-bs-offset="0" value="Azores || +00:00">(GMT) Azores</option>
										<option data-bs-offset="0" value="Monrovia || +00:00">(GMT) Monrovia</option>
										<option data-bs-offset="0" value="UTC || +00:00">(GMT) UTC</option>
										<option data-bs-offset="3600" value="Dublin || +01:00">(GMT+01:00) Dublin</option>
										<option data-bs-offset="3600" value="Edinburgh || +01:00">(GMT+01:00) Edinburgh</option>
										<option data-bs-offset="3600" value="Lisbon || +01:00">(GMT+01:00) Lisbon</option>
										<option data-bs-offset="3600" value="London || +01:00">(GMT+01:00) London</option>
										<option data-bs-offset="3600" value="Casablanca || +01:00">(GMT+01:00) Casablanca</option>
										<option data-bs-offset="3600" value="West Central Africa || +01:00">(GMT+01:00) West Central Africa</option>
										<option data-bs-offset="7200" value="Belgrade || +02:00">(GMT+02:00) Belgrade</option>
										<option data-bs-offset="7200" value="Bratislava || +02:00">(GMT+02:00) Bratislava</option>
										<option data-bs-offset="7200" value="Budapest || +02:00">(GMT+02:00) Budapest</option>
										<option data-bs-offset="7200" value="Ljubljana || +02:00">(GMT+02:00) Ljubljana</option>
										<option data-bs-offset="7200" value="Prague || +02:00">(GMT+02:00) Prague</option>
										<option data-bs-offset="7200" value="Sarajevo || +02:00">(GMT+02:00) Sarajevo</option>
										<option data-bs-offset="7200" value="Skopje || +02:00">(GMT+02:00) Skopje</option>
										<option data-bs-offset="7200" value="Warsaw || +02:00">(GMT+02:00) Warsaw</option>
										<option data-bs-offset="7200" value="Zagreb || +02:00">(GMT+02:00) Zagreb</option>
										<option data-bs-offset="7200" value="Brussels || +02:00">(GMT+02:00) Brussels</option>
										<option data-bs-offset="7200" value="Copenhagen || +02:00">(GMT+02:00) Copenhagen</option>
										<option data-bs-offset="7200" value="Madrid || +02:00">(GMT+02:00) Madrid</option>
										<option data-bs-offset="7200" value="Paris || +02:00">(GMT+02:00) Paris</option>
										<option data-bs-offset="7200" value="Amsterdam || +02:00">(GMT+02:00) Amsterdam</option>
										<option data-bs-offset="7200" value="Berlin || +02:00">(GMT+02:00) Berlin</option>
										<option data-bs-offset="7200" value="Bern || +02:00">(GMT+02:00) Bern</option>
										<option data-bs-offset="7200" value="Rome || +02:00">(GMT+02:00) Rome</option>
										<option data-bs-offset="7200" value="Stockholm || +02:00">(GMT+02:00) Stockholm</option>
										<option data-bs-offset="7200" value="Vienna || +02:00">(GMT+02:00) Vienna</option>
										<option data-bs-offset="7200" value="Cairo || +02:00">(GMT+02:00) Cairo</option>
										<option data-bs-offset="7200" value="Harare || +02:00">(GMT+02:00) Harare</option>
										<option data-bs-offset="7200" value="Pretoria || +02:00">(GMT+02:00) Pretoria</option>
										<option data-bs-offset="10800" value="Bucharest || +03:00">(GMT+03:00) Bucharest</option>
										<option data-bs-offset="10800" value="Helsinki || +03:00">(GMT+03:00) Helsinki</option>
										<option data-bs-offset="10800" value="Kiev || +03:00">(GMT+03:00) Kiev</option>
										<option data-bs-offset="10800" value="Kyiv || +03:00">(GMT+03:00) Kyiv</option>
										<option data-bs-offset="10800" value="Riga || +03:00">(GMT+03:00) Riga</option>
										<option data-bs-offset="10800" value="Sofia || +03:00">(GMT+03:00) Sofia</option>
										<option data-bs-offset="10800" value="Tallinn || +03:00">(GMT+03:00) Tallinn</option>
										<option data-bs-offset="10800" value="Vilnius || +03:00">(GMT+03:00) Vilnius</option>
										<option data-bs-offset="10800" value="Athens || +03:00">(GMT+03:00) Athens</option>
										<option data-bs-offset="10800" value="Istanbul || +03:00">(GMT+03:00) Istanbul</option>
										<option data-bs-offset="10800" value="Minsk || +03:00">(GMT+03:00) Minsk</option>
										<option data-bs-offset="10800" value="Jerusalem || +03:00">(GMT+03:00) Jerusalem</option>
										<option data-bs-offset="10800" value="Moscow || +03:00">(GMT+03:00) Moscow</option>
										<option data-bs-offset="10800" value="St. Petersburg || +03:00">(GMT+03:00) St. Petersburg</option>
										<option data-bs-offset="10800" value="Volgograd || +03:00">(GMT+03:00) Volgograd</option>
										<option data-bs-offset="10800" value="Kuwait || +03:00">(GMT+03:00) Kuwait</option>
										<option data-bs-offset="10800" value="Riyadh || +03:00">(GMT+03:00) Riyadh</option>
										<option data-bs-offset="10800" value="Nairobi || +03:00">(GMT+03:00) Nairobi</option>
										<option data-bs-offset="10800" value="Baghdad || +03:00">(GMT+03:00) Baghdad</option>
										<option data-bs-offset="14400" value="Abu Dhabi || +04:00">(GMT+04:00) Abu Dhabi</option>
										<option data-bs-offset="14400" value="Muscat || +04:00">(GMT+04:00) Muscat</option>
										<option data-bs-offset="14400" value="Baku || +04:00">(GMT+04:00) Baku</option>
										<option data-bs-offset="14400" value="Tbilisi || +04:00">(GMT+04:00) Tbilisi</option>
										<option data-bs-offset="14400" value="Yerevan || +04:00">(GMT+04:00) Yerevan</option>
										<option data-bs-offset="16200" value="Tehran || +04:30">(GMT+04:30) Tehran</option>
										<option data-bs-offset="16200" value="Kabul || +04:30">(GMT+04:30) Kabul</option>
										<option data-bs-offset="18000" value="Ekaterinburg || +05:00">(GMT+05:00) Ekaterinburg</option>
										<option data-bs-offset="18000" value="Islamabad || +05:00">(GMT+05:00) Islamabad</option>
										<option data-bs-offset="18000" value="Karachi || +05:00">(GMT+05:00) Karachi</option>
										<option data-bs-offset="18000" value="Tashkent || +05:00">(GMT+05:00) Tashkent</option>
										<option data-bs-offset="19800" value="Chennai || +05:30">(GMT+05:30) Chennai</option>
										<option data-bs-offset="19800" value="Kolkata || +05:30">(GMT+05:30) Kolkata</option>
										<option data-bs-offset="19800" value="Mumbai || +05:30">(GMT+05:30) Mumbai</option>
										<option data-bs-offset="19800" value="New Delhi || +05:30">(GMT+05:30) New Delhi</option>
										<option data-bs-offset="19800" value="Sri Jayawardenepura || +05:30">(GMT+05:30) Sri Jayawardenepura</option>
										<option data-bs-offset="20700" value="Kathmandu || +05:45">(GMT+05:45) Kathmandu</option>
										<option data-bs-offset="21600" value="Astana || +06:00">(GMT+06:00) Astana</option>
										<option data-bs-offset="21600" value="Dhaka || +06:00">(GMT+06:00) Dhaka</option>
										<option data-bs-offset="21600" value="Almaty || +06:00">(GMT+06:00) Almaty</option>
										<option data-bs-offset="21600" value="Urumqi || +06:00">(GMT+06:00) Urumqi</option>
										<option data-bs-offset="23400" value="Rangoon || +06:30">(GMT+06:30) Rangoon</option>
										<option data-bs-offset="25200" value="Novosibirsk || +07:00">(GMT+07:00) Novosibirsk</option>
										<option data-bs-offset="25200" value="Bangkok || +07:00">(GMT+07:00) Bangkok</option>
										<option data-bs-offset="25200" value="Hanoi || +07:00">(GMT+07:00) Hanoi</option>
										<option data-bs-offset="25200" value="Jakarta || +07:00">(GMT+07:00) Jakarta</option>
										<option data-bs-offset="25200" value="Krasnoyarsk || +07:00">(GMT+07:00) Krasnoyarsk</option>
										<option data-bs-offset="28800" value="Beijing || +08:00">(GMT+08:00) Beijing</option>
										<option data-bs-offset="28800" value="Chongqing || +08:00">(GMT+08:00) Chongqing</option>
										<option data-bs-offset="28800" value="Hong Kong || +08:00">(GMT+08:00) Hong Kong</option>
										<option data-bs-offset="28800" value="Kuala Lumpur || +08:00">(GMT+08:00) Kuala Lumpur</option>
										<option data-bs-offset="28800" value="Singapore || +08:00">(GMT+08:00) Singapore</option>
										<option data-bs-offset="28800" value="Taipei || +08:00">(GMT+08:00) Taipei</option>
										<option data-bs-offset="28800" value="Perth || +08:00">(GMT+08:00) Perth</option>
										<option data-bs-offset="28800" value="Irkutsk || +08:00">(GMT+08:00) Irkutsk</option>
										<option data-bs-offset="28800" value="Ulaan Bataar || +08:00">(GMT+08:00) Ulaan Bataar</option>
										<option data-bs-offset="32400" value="Seoul || +09:00">(GMT+09:00) Seoul</option>
										<option data-bs-offset="32400" value="Osaka || +09:00">(GMT+09:00) Osaka</option>
										<option data-bs-offset="32400" value="Sapporo || +09:00">(GMT+09:00) Sapporo</option>
										<option data-bs-offset="32400" value="Tokyo || +09:00">(GMT+09:00) Tokyo</option>
										<option data-bs-offset="32400" value="Yakutsk || +09:00">(GMT+09:00) Yakutsk</option>
										<option data-bs-offset="34200" value="Darwin || +09:30">(GMT+09:30) Darwin</option>
										<option data-bs-offset="34200" value="Adelaide || +09:30">(GMT+09:30) Adelaide</option>
										<option data-bs-offset="36000" value="Canberra || +10:00">(GMT+10:00) Canberra</option>
										<option data-bs-offset="36000" value="Melbourne || +10:00">(GMT+10:00) Melbourne</option>
										<option data-bs-offset="36000" value="Sydney || +10:00">(GMT+10:00) Sydney</option>
										<option data-bs-offset="36000" value="Brisbane || +10:00">(GMT+10:00) Brisbane</option>
										<option data-bs-offset="36000" value="Hobart || +10:00">(GMT+10:00) Hobart</option>
										<option data-bs-offset="36000" value="Vladivostok || +10:00">(GMT+10:00) Vladivostok</option>
										<option data-bs-offset="36000" value="Guam || +10:00">(GMT+10:00) Guam</option>
										<option data-bs-offset="36000" value="Port Moresby || +10:00">(GMT+10:00) Port Moresby</option>
										<option data-bs-offset="36000" value="Solomon Is. || +10:00">(GMT+10:00) Solomon Is.</option>
										<option data-bs-offset="39600" value="Magadan || +11:00">(GMT+11:00) Magadan</option>
										<option data-bs-offset="39600" value="New Caledonia || +11:00">(GMT+11:00) New Caledonia</option>
										<option data-bs-offset="43200" value="Fiji || +12:00">(GMT+12:00) Fiji</option>
										<option data-bs-offset="43200" value="Kamchatka || +12:00">(GMT+12:00) Kamchatka</option>
										<option data-bs-offset="43200" value="Marshall Is. || +12:00">(GMT+12:00) Marshall Is.</option>
										<option data-bs-offset="43200" value="Auckland || +12:00">(GMT+12:00) Auckland</option>
										<option data-bs-offset="43200" value="Wellington || +12:00">(GMT+12:00) Wellington</option>
										<option data-bs-offset="46800" value="Nuku'alofa || +13:00">(GMT+13:00) Nuku'alofa</option>
									</select>
									<span id="timezone1" className="text-danger font-weight-bold"></span>
								</div>

							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Currency:</label>
								<Select
									styles={userTheme == 'dark' ? dropdownStyleDark : {}}
									options={Currencylist}
									onChange={(event) => { setCurrencyCode(event.value); }}
									value={Currencylist.filter(option => option.value == CurrencyCode).length != 0 && Currencylist.filter(option => option.value == CurrencyCode)[0]}
									placeholder="--Choose a Currency--"
								/>
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="fs-5 fw-bold mb-2">Industry:</label>
								<Select
									styles={userTheme == 'dark' ? dropdownStyleDark : {}}
									options={Industrylist}
									onChange={(event) => {
										setIndustry(event.value)
									}}
									value={Industrylist.filter(option => option.value == Industry).length != 0 && Industrylist.filter(option => option.value == Industry)[0]}
									placeholder="--Select Industry--"
								/>
							</div>
						</div>
						{/* <label className="form-check form-check-custom form-check-solid align-items-start">
							<input className="form-check-input me-3" type="checkbox" name="email-preferences[]" value={Skip_set_up} onChange={(e) => {
										setSkip_set_up(e.target.checked);
									}}/>
							<span className="form-check-label d-flex flex-column align-items-start">
								<span className="fw-bolder fs-5 mb-0">Skip set up</span>
								{Skip_set_up &&<span className="text-muted fs-6">Your default data will not save for this organization.</span>}
							</span>
						</label> */}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button type="button" onClick={(e) => { setOrgModalShow(false); }} className="btn btn-secondary">Cancel</button>
					{write && <button onClick={(e) => { submitOrganization(e) }} type="submit" id="kt_modal_new_address_submit" className="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
						<span className="indicator-label">Save</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
					</button>}
				</Modal.Footer>
			</Modal>
		</>);

};

export default ShowOrganizations;
