
import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';

import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

import $ from 'jquery';
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const ConfigureItemGroup = (props) => {

    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [state, setstate] = useState(2);
    const [pageError, setPageError] = useState(null);
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("");

    const [itemGroupName, setItemGroupName] = useState("")
    const [itemGroupDescription, setItemGroupDescription] = useState("")
    const [attributeDetails, setAttributeDetails] = useState([{ attribute: null, options: [] }]);
    const [attributeOptions, setAttributeOptions] = useState([]);
    const [itemDetails, setItemDetails] = useState([{ item_id: null, item_name: null }])
    const [ItemApiState, setItemApiState] = useState(false)
    const [itemList, setItemList] = useState([])
    const [menuBlockScroll, setMenuBlockScroll] = useState(false);


    const [organization_list, setorganization_list] = useState([]);

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);

    var page_name = "configure-item-group";
    let count = 2;

    const loaderstyle = { display: "block", marginLeft: "auto", marginRight: "auto", marginTop: "auto", marginBottom: "auto", };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res) => {

                    if (res.data.length > 0) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }

                })
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    break;
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            timer.current = process.env.REACT_APP_JWT_EXPIRY;
                        }
                        else {
                            timer.current = res.data.audience_count;
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id != "" && validateJWT();

    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);

    async function getItems(search_value) {
        setItemApiState(true);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setItemList(body.data);
            setItemApiState(false);
        }
        else {
            throw body.message;
        }
    }
    async function getItemGroupDetails(igid) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-item-group-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "item_group_id": igid, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === 'success') {
            setItemGroupName(body.data.item_group_name);
            setItemGroupDescription(body.data.item_group_description);
            setAttributeDetails(body.data.attribute_details);
            setItemDetails(body.data.item_details);
        } else {
            throw body.message;
        }
    }
    useEffect(() => {
        if (((read === 1) || (write === 1)) && (user.email && visibility)) {
            let p1 = getItems();
            let p2 = props.match.params.id ? getItemGroupDetails(props.match.params.id) : Promise.resolve();

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1);
                    console.log('all APIs done');
                })
                .catch((err) => {
                    setstate(0);
                    setPageError(err);
                    console.log(err);
                });
        }
    }, [user, visibility, read, write]);

    const configureItemGroupSubmit = () => {
        if (itemGroupName.trim() === "") {
            swal("Oops!", "Please enter an item group name.", "warning");
            return;
        }
        if (attributeDetails.length === 0) {
            swal("Oops!", "Please add at least one attribute.", "warning");
            return;
        }
        let hasAttributes = true;
        attributeDetails.forEach(obj => {
            if (obj.attribute === null) {
                hasAttributes = false;
            }
        });
        if (!hasAttributes) {
            swal("Oops!", "Please add at least one attribute.", "warning");
            return;
        }

        let hasEmptyOptions = false;
        attributeDetails.forEach(attribute => {
            if (attribute.options.length === 0) {
                hasEmptyOptions = true;
            }
        });
        if (hasEmptyOptions) {
            swal("Oops!", "Please add at least one option for each attribute.", "warning");
            return;
        }

        let hasItemWithValues = true;
        itemDetails.forEach(item => {
            if (item.item_id === null || item.item_name === null) {
                hasItemWithValues = false;
            } else {
                let hasItemOptions = true;
                attributeDetails.forEach(obj => {
                    if (!(obj.attribute in item) || item[obj.attribute] === "") {
                        hasItemOptions = false;
                    }
                });
                if (!hasItemOptions) {
                    hasItemWithValues = false;
                }
            }
        });
        if (!hasItemWithValues) {
            swal("Oops!", "Please add an option for each attribute in each item.", "warning");
            return;
        }
        if (itemDetails.length === 0) {
            swal("Oops!", "Please add values for all items with an attribute.", "warning");
            return;
        }

        const object = {};
        object.item_group_name = itemGroupName
        object.item_group_description = itemGroupDescription
        object.item_group_id = props.match.params.id ? props.match.params.id : null;
        object.attribute_details = attributeDetails
        object.item_details = itemDetails
        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object);
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/configure-item-group`, object, headers)
            .then((res) => {
                setMainSubmitButtonState(false);
                if (res.data.operation === "success") {
                    swal("Great!", "Grouped items configured successfully!", "success").then(() => { window.location.reload() });
                } else {
                    swal("Oops!", res.data.message, "error");
                }
            })
            .catch(function (error) {
                console.log(error);
                swal("Oops!", "Something went wrong!", "error");
            }
            );
    }


    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Configure Item Group
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state == 1 ?
                            <>
                                <div className="d-flex flex-column gap-7">

                                    <div className="card card-flush py-4">
                                        <div className="card-header">
                                            <div className="card-title"><h2>Details</h2></div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="mb-10 fv-row">
                                                <label className="required form-label">Item Group Name</label>
                                                <input type="text" className="form-control mb-2" placeholder="Item Group name" value={itemGroupName} onChange={(e) => { setItemGroupName(e.target.value) }} />
                                                <div className="text-muted fs-7">An item name is required and recommended to be unique.</div>
                                            </div>
                                            <div className="mb-10 fv-row">
                                                <label className="required form-label">Item Description</label>
                                                <textarea className="form-control mb-2" rows="3" placeholder="Item Group Description" value={itemGroupDescription} onChange={(e) => { setItemGroupDescription(e.target.value) }} />
                                                <div className="text-muted fs-7">The description of an item should specify all the behaviors it can perform and the tasks it can handle.</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-flush py-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h2>Attributes</h2>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="container p-0">
                                                {
                                                    attributeDetails.map((obj, i) => {
                                                        return (
                                                            <div key={i} className="row gx-0 my-2">
                                                                <div className="col px-3">
                                                                    <label className="required form-label">Attribute</label>
                                                                    <CreatableSelect
                                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                        options={attributeOptions.map(x => { return { label: x, value: x } })}
                                                                        value={obj.attribute == null ? null : { label: obj.attribute, value: obj.attribute }}
                                                                        onChange={(val) => {
                                                                            if (val.hasOwnProperty("__isNew__") && val["__isNew__"] == true) {
                                                                                setAttributeOptions([...attributeOptions, val.value])
                                                                            }
                                                                            let t = attributeDetails.map(x => { return { ...x } })
                                                                            let t1 = itemDetails.map(x => {
                                                                                if (x.hasOwnProperty(t[i].attribute)) {
                                                                                    delete x[t[i].attribute]
                                                                                }
                                                                                return x;
                                                                            })
                                                                            setItemDetails(t1);

                                                                            t[i].attribute = val.value
                                                                            t[i].options = []
                                                                            setAttributeDetails(t);
                                                                        }}
                                                                        placeholder="-Enter the attributes-"
                                                                    />
                                                                    <div className="text-muted fs-7">Set the product Attribute.</div>
                                                                </div>
                                                                <div className="col-md-6 px-3">
                                                                    <label className="required form-label">Options</label>
                                                                    <ReactTagInput
                                                                        tags={obj.attribute ? obj.options : []}
                                                                        onChange={(tags) => {
                                                                            let t = attributeDetails.map(x => { return { ...x } })

                                                                            let t1 = itemDetails.map(x => {
                                                                                if (x.hasOwnProperty(t[i].attribute)) {
                                                                                    delete x[t[i].attribute]
                                                                                }
                                                                                return x;
                                                                            })
                                                                            setItemDetails(t1);

                                                                            t[i].options = tags;
                                                                            setAttributeDetails(t);
                                                                        }}
                                                                        placeholder="Enter the options"
                                                                    />
                                                                    <div className="text-muted fs-7">Set the product Options and press Enter.</div>
                                                                </div>
                                                                <div className="col-md-1 px-3">
                                                                    <button className="btn btn-outline-danger text-hover-danger rounded-pill mt-6" onClick={() => {
                                                                        let t = attributeDetails.map(x => { return { ...x } })

                                                                        let t1 = itemDetails.map(x => {
                                                                            if (x.hasOwnProperty(t[i].attribute)) {
                                                                                delete x[t[i].attribute]
                                                                            }
                                                                            return x;
                                                                        })
                                                                        setItemDetails(t1);

                                                                        t.splice(i, 1);
                                                                        setAttributeDetails(t);
                                                                    }}>
                                                                        <i className="bi bi-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {attributeDetails.length < 5 && <button className="btn btn-primary btn-sm mt-3" onClick={() => { setAttributeDetails([...attributeDetails, { attribute: null, options: [] }]) }}>
                                                    <i className="bi bi-arrow-down p-0"></i> Add New row
                                                </button>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-flush py-4">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <h2>Grouped Items</h2>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="container p-0">
                                                {
                                                    itemDetails.map((obj, i) => {
                                                        return (
                                                            <div key={i} className="row gx-0 my-2" >
                                                                <div className="col px-3">
                                                                    <label className="required form-label">Item</label>
                                                                    <Select
                                                                        options={itemList.map(x => { return { label: x.product_name, value: x.pid } })}
                                                                        value={obj.item_id == null ? null : { label: obj.item_name, value: obj.item_id }}
                                                                        onChange={(val) => {
                                                                            let t = itemDetails.map(x => { return { ...x } })
                                                                            t[i].item_id = val.value
                                                                            t[i].item_name = val.label
                                                                            setItemDetails(t);
                                                                        }}
                                                                        onInputChange={(val) => { (val.length > 1) && getItems(val); }}
                                                                        onMenuClose={() => { getItems(); }}
                                                                        isLoading={ItemApiState}
                                                                        onMenuOpen={() => { setMenuBlockScroll(true); }}
                                                                        menuPosition='fixed'
                                                                        menuShouldBlockScroll={menuBlockScroll}
                                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                    />
                                                                </div>
                                                                {
                                                                    attributeDetails.map((obj2, j) => {
                                                                        return (
                                                                            <div key={j} className="col px-3">
                                                                                <label className="required form-label">{obj2.attribute}</label>
                                                                                <Select
                                                                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                    options={obj2.options.map(x => { return { label: x, value: x } })}
                                                                                    value={obj.hasOwnProperty(obj2.attribute) ? { label: obj[obj2.attribute], value: obj[obj2.attribute] } : null}
                                                                                    onChange={(val) => {
                                                                                        let t = itemDetails.map(x => { return { ...x } })
                                                                                        t[i][obj2.attribute] = val.value
                                                                                        setItemDetails(t);
                                                                                    }}
                                                                                    placeholder="Select an option"
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <div className="col-md-1 px-3">
                                                                    <button className="btn btn-outline-danger text-hover-danger rounded-pill mt-6" onClick={() => {
                                                                        let t = itemDetails.map(x => { return { ...x } })
                                                                        t.splice(i, 1);
                                                                        setItemDetails(t);
                                                                    }}><i className="bi bi-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <button className="btn btn-primary mt-4 btn-sm" onClick={() => { setItemDetails([...itemDetails, { item_id: null, item_name: null }]) }}>
                                                    <i className="bi bi-arrow-down p-0"></i>Add New Item
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-end'>
                                    {
                                        write == 1 &&
                                        (
                                            <button
                                                data-kt-indicator={mainSubmitButtonState ? 'on' : 'off'}
                                                disabled={mainSubmitButtonState}
                                                onClick={(e) => {
                                                    configureItemGroupSubmit();
                                                }}
                                                className="btn btn-primary btn-sm"
                                            >
                                                <span className="indicator-label">Save Changes</span>
                                                <span className="indicator-progress">
                                                    Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        )
                                    }
                                </div>
                            </>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <div className="card">
                                    <div className="card-body">
                                        <div className="notice d-flex bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <h4 className="text-gray-800 fw-bolder">{pageError}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>


            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} timer_ref={timer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
        </>);

};

export default ConfigureItemGroup;
