import React from 'react'

// only add the succeding status keyword for the entity in the proper array. 
// for example: if the original status is PURCHASE_ORDER_FINALIZED add "_FINALIZED" in the proper list, 
// if it already exists DO NOT ADD AGAIN
// and use the StatusBadge component like this: <StatusBadge status={obj.add_status} entity={"PURCHASE_ORDER"} />

const StatusBadge = ({ status, entity, className = "" }) => {

    const successList = ["_APPROVED","_APPROVED_PAID", "_FINALIZED", "_INVOICED", "_WAREHOUSE", "_APPROVAL_MAIL_SENT", "_CONFIRMED", "_PROCESSED", "_DISPATCHED", "Activated", "Active", "Activate", "Success"]
    const warningList = ["_PENDING_APPROVAL", "_DRAFT", "_PROCESSING_PARTIAL", "_APPROVAL_MAIL_SKIPPED", "Pending"]
    const dangerList = ["_CANCELLED", "_PROCESSING_INIT", "Deactivated", "Deactive", "Deactivate"]
    const primaryList = ["_APPROVED"]

    status = status.replace(entity, "")
 
    let c
    if (successList.find(x => status === x)) {
        c = "light-success"
    }
    else if (warningList.find(x => status === x)) {
        c = "light-warning"
    }
    else if (dangerList.find(x => status === x)) {
        c = "light-danger"
    }
    else if (primaryList.find(x => status.includes(x))) {
        c = "light-primary"
    }
    else {
        c = "secondary"
    }

    return (
        <span className={`${className} badge badge-${c}`} >
            {status.replace("_","").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
        </span>
    )
}

export default StatusBadge
