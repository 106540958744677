import React, { useState, useEffect, useMemo } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { Pagination } from "antd";

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import { useTheme } from "./theme-provider.js"

import tds_section_arr from './common_arr/tds-section-arr.js';
import tcs_section_arr from './common_arr/tcs-section-arr.js';
import PageError from "../common_component/PageError.js";
import StatusBadge from "../common_component/StatusBadge.js";

export const TaxList = () => {
    const [TDSPayableAccountSelectionList, setTDSPayableAccountSelectionList] = useState([]);
    const [TDSReceivableAccountSelectionList, setTDSReceivableAccountSelectionList] = useState([]);
    const [TCSPayableAccountSelectionList, setTCSPayableAccountSelectionList] = useState([]);
    const [TCSReceivableAccountSelectionList, setTCSReceivableAccountSelectionList] = useState([]);
    const [state, setstate] = useState(2);

    const { theme, dropdownStyleDark } = useTheme()

    const [taxList, setTaxList] = useState([]);

    const [tax_id, settax_id] = useState("");
    const [TaxName, setTaxName] = useState("");
    const [TaxType, setTaxType] = useState("");
    const [TaxCategory, setTaxCategory] = useState("");
    const [Percentage, setPercentage] = useState(0);

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [TDSTypelist, setTDSTypelist] = useState([]);
    const [TCSTypelist, setTCSTypelist] = useState([]);

    const [TDSAcountDetails, setTDSAcountDetails] = useState({ "TDS_payable_account": "", "TDS_receivable_account": "" });
    const [TCSAcountDetails, setTCSAcountDetails] = useState({ "TCS_payable_account": "", "TCS_receivable_account": "" });

    const [RegularTaxModalShow, setRegularTaxModalShow] = useState(false);
    const [TDSModalShow, setTDSModalShow] = useState(false);
    const [TCSModalShow, setTCSModalShow] = useState(false);

    var page_name = "tax-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        setTDSTypelist(tds_section_arr.tds_section_arr.map(o => { return { label: o.section_name + "(" + o.section_no + ")", value: o.id } }));
        setTCSTypelist(tcs_section_arr.tcs_section_arr.map(o => { return { label: o.section_name + "(" + o.section_no + ")", value: o.id } }));
        //console.log(tds_section_arr.tds_section_arr.map(o => { return { label: o.section_name + "(" + o.section_no + ")", value: o.id } }));
        //console.log(tcs_section_arr.tcs_section_arr.map(o => { return { label: o.section_name + "(" + o.section_no + ")", value: o.id } }));
    }, []);

    async function getTaxList() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setTaxList(body.data);
        }
        else {
            throw "tax list api error"
        }
    }
    async function selectiveaccounthead() {
        let arr = [
            { "category_name": "Assets", "headArray": ["TCS Receivable", "TDS Receivable", "Goods In Transit", "Advance Tax", "Prepaid Expenses"] },
            { "category_name": "Liability", "headArray": ["TDS Payable", "TCS Payable"] }
        ]
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/selective-account-head`, { method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "headRequest": arr, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        //console.log(body.data)

        if (body.operation === "success") {
            let temp_dropdown = [];

            body.data.map((obj) => {
                let temp_obj = {};
                temp_obj.label = obj.category_name;
                let temp_dropdown1 = [];
                obj.headArray.map((obj1) => {
                    let temp_obj1 = {};
                    temp_obj1.label = obj1.name;
                    temp_obj1.value = obj1.hid;
                    if (obj1.name === "TDS Payable") {
                        arr.push(temp_obj1);
                        temp_obj.options = arr;
                        setTDSPayableAccountSelectionList(temp_obj);
                    }
                    else if (obj1.name === "TCS Payable") {
                        arr.push(temp_obj1);
                        temp_obj.options = arr;
                        setTCSPayableAccountSelectionList(temp_obj);
                    }
                    else if (obj1.name === "Goods In Transit" || obj1.name === "Advance Tax" || obj1.name === "Prepaid Expenses") {
                        arr.push(temp_obj1);
                        temp_obj.options = arr;
                        setTDSReceivableAccountSelectionList(temp_obj);
                    }
                    // console.log(temp_obj)

                    temp_dropdown1.push(temp_obj1);
                })
                temp_obj.options = temp_dropdown1;

                temp_dropdown.push(temp_obj);

            })

            //console.log(temp_dropdown)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getTaxList()
            // let p2 = selectiveaccounthead()

            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    const updateTax = (id) => {
        for (let i = 0; i < taxList.length; i++) {
            if (id == taxList[i].tax_id) {
                settax_id(taxList[i].tax_id)
                setPercentage(parseInt(taxList[i].tax_percentage))
                setTaxName(taxList[i].tax_name);
                setTaxType(taxList[i].tax_type);
                if (taxList[i].category === "TDS") {
                    setTDSModalShow(true);
                }
                else if (taxList[i].category === "TCS") {
                    setTCSModalShow(true);
                }
                else {
                    setRegularTaxModalShow(true);
                }
            }
        }
    }

    const submitTax = () => {
        settax_id("")
        setPercentage(0)
        setTaxName("");
    }

    const changeStatusTax = (id, status) => {
        var values = { "id": id, "status": status, "page_name": page_name, "organization_id": organization_id };
        setstate(2);
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-tax-details`, values, headers).then((res) => {
            setMainSubmitButtonState(false)

            if (res.data.operation == "success") {
                getTaxList()
                setstate(1);
                swal("Great!", "Status changed successfully!", "success")
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


    const submitTaxDetails = () => {

        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (TaxName == "") {
            swal("Warning!", "Tax Name cannot be empty", "warning");
            return false;
        }
        else if (!TaxName.match(nexp)) {
            swal("Warning!", "Tax Name cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            var oname = taxList.filter((item) => { return item.tax_id !== tax_id })
            for (var i = 0; i < oname.length; ++i) {
                if (oname[i].tax_name == TaxName) {
                    swal("Oops!", "Tax Name already exists, try different one!", "error");
                    return false;
                }
                else if (oname[i].tax_percentage == Percentage && oname[i].tax_type == TaxType) {
                    swal("Oops!", "Tax Percentage with this tax type already exists, try different one!", "error");
                    return false;
                }
            }
        }
        if (Percentage == "") {
            swal("Warning!", "Percentage cannot be empty", "warning");
            return false;
        }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        let Account_info = {};
        if (TaxCategory === "TDS") {
            Account_info = TDSAcountDetails;
        }
        else if (TaxCategory === "TcS") {
            Account_info = TCSAcountDetails;
        }
        if (tax_id != "") {
            var values = { "id": tax_id, "tax_name": TaxName, "Account_info": Account_info, "category": TaxCategory, "tax_type": TaxType, "Percentage": Percentage, "page_name": page_name, "organization_id": organization_id };
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-tax`, values, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data.operation == "success") {
                    getTaxList()
                    swal("Great!", "Tax details updated successfully!", "success")
                    setRegularTaxModalShow(false)
                    setTDSModalShow(false)
                    setTCSModalShow(false)
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else {
            var values = { "tax_name": TaxName, "category": TaxCategory, "tax_type": TaxType, "Percentage": Percentage, "page_name": page_name, "organization_id": organization_id };
            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-tax`, values, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data.operation == "success") {
                    getTaxList()
                    swal("Great!", "Tax details save successfully!", "success")
                    setRegularTaxModalShow(false)
                    setTDSModalShow(false)
                    setTCSModalShow(false)
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
    }

    const filteredList = useMemo(() => {
        if (searchValue) {
            return taxList.filter((x) => {
                let r = new RegExp(searchValue, "gi")
                if (r.test(x.tax_name) ||
                    r.test(x.tax_percentage) ||
                    r.test(x.category) ||
                    r.test(x.tax_status)
                ) {
                    return x
                }
            })
        }
        else {
            return taxList
        }
    }, [taxList, searchValue])

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Tax List

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => { submitTax(); setRegularTaxModalShow(true); setTaxCategory("Regular"); }}>Add Regular Tax</button>
                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => { submitTax(); setTDSModalShow(true); setTaxCategory("TDS"); }}>Add TDS</button>
                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => { submitTax(); setTCSModalShow(true); setTaxCategory("TCS"); }}>Add TCS</button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className='mb-5'>
                                                <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                            </div>

                                            <div className="mb-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    showQuickJumper={true}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                />
                                            </div>
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Tax Name</th>
                                                            <th className="min-w-150px">Percentage</th>
                                                            <th className="min-w-150px">Tax Type</th>
                                                            <th className="min-w-150px">Category</th>
                                                            <th className="min-w-150px">Status</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredList.length > 0 ?
                                                                filteredList.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((obj) => {
                                                                    return (
                                                                        <tr key={obj.tax_id}>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.tax_name}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.tax_percentage}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-light-info">
                                                                                    {
                                                                                        obj.tax_type ?
                                                                                            obj.category === "TDS" ?
                                                                                                TDSTypelist.find(option => option.value == obj.tax_type)?.label ?? ""
                                                                                                :
                                                                                                obj.category === "TCS" ?
                                                                                                    TCSTypelist.find(option => option.value == obj.tax_type)?.label ?? ""
                                                                                                    :
                                                                                                    obj.tax_type
                                                                                            :
                                                                                            "NA"
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-light-primary">{obj.category}</span>
                                                                            </td>
                                                                            <td>
                                                                                <StatusBadge status={obj.tax_status} entity={""} />
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <div className="dropdown" style={{ position: "unset" }}>
                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="fas fa-bars px-0"></i>
                                                                                    </button>
                                                                                    {
                                                                                        write &&
                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                            <li className="py-1 cursor-pointer dropdown-item" onClick={() => { updateTax(obj.tax_id); }}><span>Edit details</span></li>
                                                                                            <li className="py-1 cursor-pointer dropdown-item"
                                                                                                onClick={() => {
                                                                                                    swal({
                                                                                                        title: "Are you sure?",
                                                                                                        text: "Are you sure you want to change Status?",
                                                                                                        icon: "warning",
                                                                                                        buttons: true,
                                                                                                        dangerMode: true,
                                                                                                    })
                                                                                                        .then((willDelete) => {
                                                                                                            if (willDelete) {
                                                                                                                changeStatusTax(obj.tax_id, obj.tax_status)
                                                                                                            }
                                                                                                        });
                                                                                                }}
                                                                                            >
                                                                                                <span>Change Status</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={6} className="text-center">
                                                                        <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="mt-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <PageError />
                        }
                    </div>
                </div>
            </div>

            <Modal show={TCSModalShow} onHide={() => { setTCSModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{tax_id === "" ? <>Add</> : <>Edit</>} TCS</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setTCSModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input id="tax_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Percentage</label>
                                <input id='Percentage' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Percentage.toString()} onChange={(e) => { setPercentage(e.target.value == "" ? 0 : parseInt(e.target.value)) }} readOnly={write ? false : true} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TCSTypelist}
                                    onChange={(event) => {
                                        setTaxType(event.value)
                                    }}
                                    value={TCSTypelist.filter(option => option.value == TaxType).length != 0 && TCSTypelist.filter(option => option.value == TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Paybale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TCSPayableAccountSelectionList}
                                    onChange={(val) => {
                                        //console.log(val);
                                        TCSAcountDetails.TCS_payable_account = val;
                                        //setTDS_payable_account(val);
                                    }}
                                    value={TCSAcountDetails.TCS_payable_account}
                                    placeholder="--Select Account--"
                                />
                                <span className="fw-semibold ps-2 fs-6"></span>

                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Receivabale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TCSReceivableAccountSelectionList}
                                    onChange={(val) => {
                                        //console.log(val); 
                                        TCSAcountDetails.TCS_receivable_account = val;
                                        //setTDS_receivable_account(val);
                                    }}
                                    value={TCSAcountDetails.TCS_receivable_account}
                                    placeholder="--Select Account--"
                                />
                                <span className="fw-semibold ps-2 fs-6"></span>

                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => { setTCSModalShow(false) }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {write && <form >
                        <button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id === "" ? <>Save</> : <>Update</>}</span>
                            <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </form>}
                </Modal.Footer>
            </Modal>

            <Modal show={TDSModalShow} onHide={() => { setTDSModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{tax_id === "" ? <>Add</> : <>Edit</>} TDS</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setTDSModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input id="tax_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Percentage</label>
                                <input id='Percentage' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Percentage.toString()} onChange={(e) => { setPercentage(e.target.value == "" ? 0 : parseInt(e.target.value)) }} readOnly={write ? false : true} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TDSTypelist}
                                    onChange={(event) => {
                                        setTaxType(event.value)
                                    }}
                                    value={TDSTypelist.filter(option => option.value == TaxType).length != 0 && TDSTypelist.filter(option => option.value == TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Paybale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TDSPayableAccountSelectionList}
                                    onChange={(val) => {
                                        //console.log(val);
                                        TDSAcountDetails.TDS_payable_account = val;
                                        //setTDS_payable_account(val);
                                    }}
                                    value={TDSAcountDetails.TDS_payable_account}
                                    placeholder="--Select Account--"
                                />
                                <span className="fw-semibold ps-2 fs-6"></span>

                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Receivabale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TDSReceivableAccountSelectionList}
                                    onChange={(val) => {
                                        TDSAcountDetails.TDS_receivable_account = val
                                    }}
                                    value={TDSAcountDetails.TDS_receivable_account}
                                    placeholder="--Select Account--"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => { setTDSModalShow(false) }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {
                        write &&
                        <button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id === "" ? "Save" : "Update"}</span>
                            <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={RegularTaxModalShow} onHide={() => { setRegularTaxModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{tax_id === "" ? "Add" : "Edit"} Regular Tax</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setRegularTaxModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input type="text" className="form-control form-control-solid" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Percentage</label>
                                <input type="number" className="form-control form-control-solid" value={Percentage.toString()} onChange={(e) => { setPercentage(e.target.value == "" ? 0 : parseInt(e.target.value)) }} readOnly={write ? false : true} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    options={["CGST", "SGST", "IGST", "UTGST"].map(x => { return { label: x, value: x } })}
                                    onChange={(event) => { setTaxType(event.value) }}
                                    value={["CGST", "SGST", "IGST", "UTGST"].map(x => { return { label: x, value: x } }).filter(option => option.value == TaxType).length != 0 && ["CGST", "SGST", "IGST", "UTGST"].map(x => { return { label: x, value: x } }).filter(option => option.value == TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => { setRegularTaxModalShow(false) }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {
                        write &&
                        <button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id === "" ? "Save" : "Update"}</span>
                            <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>);

};

export default TaxList;
