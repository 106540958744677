
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../includes/react-quill-custom-stylesheet.css"
import { useHistory } from 'react-router-dom'
import Select from 'react-select';
// import CreatableSelect from 'react-select/creatable';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

const PurchaseOrderApprovalEmail = (props) => {
	const [state, setstate] = useState(2);
	const history = useHistory()

	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null);

	const [mainSubject, setMainSubject] = useState("Purchase Order is waiting for approval")
	const [contents, setContents] = useState("<p>Dear {{user}},<br>The overview of purchase order {{purchase_order_number}} is available below.</p><p>Purchase Order : #{{purchase_order_number}}</p><p>Order Date: {{order_date}}<br>Amount: {{currency_symbol}} {{order_amount}}</p><p>please verify the details before approving the purchase order.</p><p>{{approve_link}}</p>")

	const [submitButtonState, setSubmitButtonState] = useState(false);


	var page_name = "purchase-order-approval-email";

	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	const quill_rte_modules = {
		toolbar: [
			[{ 'font': [] }, { 'size': [] }],
			['bold', 'italic', 'underline', 'strike'],
			[{ 'color': [] }, { 'background': [] }],
			[{ 'script': 'super' }, { 'script': 'sub' }],
			[{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
			['direction', { 'align': [] }],
			['link', 'image', 'video', 'formula'],
			['clean']
		],
	}
	const quill_rte_formats = ["header", "font", "size", "bold", "italic", "underline", "align", "strike", "script", "blockquote", "background", "list", "bullet", "indent", "link", "image", "color", "code-block"]


	async function getorderdetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-purchase-order-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "purchase_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body.data)

		if (body.operation === "success") {
			setPurchaseOrderDetails(body.data[0]);
		}
	}

	useEffect(() => {
		if ((read || write) && (user.email && visibility)) {

			let p1 = getorderdetails()

			Promise.all([p1])
				.then(() => {
					setstate(1)
				})
				.catch((err) => {
					setstate(3)
					console.log(err)
					swal("Oops!", err, "error").then(() => { window.location.href = "/create-purchase-order" })
				})
		}

	}, [user, visibility, read, write])

	const processMailBody = (body) => {
		body = body.replaceAll("{{purchase_order_number}}", purchaseOrderDetails.purchase_order_number)
		body = body.replaceAll("{{order_date}}", moment(purchaseOrderDetails.order_date).format("ll"))
		body = body.replaceAll("{{currency_symbol}}", purchaseOrderDetails.order_currency.currency_symbol)
		body = body.replaceAll("{{order_amount}}", purchaseOrderDetails.grand_total)
		setContents(body)
	}

	const sendOrderEmail = () => {
		if (!purchaseOrderDetails.purchase_order_id) {
			swal("Warning!", "Purchase Order Id missing", "warning").then(() => { history.go(0) });
			return
		}

		let object = {};

		object.purchase_order_id = purchaseOrderDetails.purchase_order_id
		object.subject = mainSubject
		object.content = contents

		object.organization_id = organization_id;
		object.page_name = page_name;

		// console.log(object);
		setSubmitButtonState(false)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-order-approval-email`, object, headers).then((res) => {
			setSubmitButtonState(true)

			if (res.data.operation === "success") {
				swal("Great!", res.data.message, "success").then(() => { history.push("/purchase-order-list") })
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
							<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Email Approval
								<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
							</h1>
							<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
								<li className="breadcrumb-item text-muted"><span className="text-muted">Home</span></li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">Purchase Order</li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">Purchase Order Email Approval</li>
							</ul>
						</div>
					</div>
				</div>

				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state == 1 ?
							<>
								<div className="d-flex flex-column gap-7 gap-lg-10">

									<div className="card card-flush py-6 flex-row-fluid">
										<div className="card-header">
											<div className="card-title">
												<h2>Purchase Order Details (#{purchaseOrderDetails.purchase_order_number})</h2>
											</div>
										</div>
										<div className="card-body">
											<div className={`border m-3 `}>
												<div className='m-10 '>
													<div className="row align-items-center mt-2 fs-6">
														<div className="text-muted me-2 fs-7 col-1">Send to:</div>
														<div className='col-8'>
															<Select
																isMulti
																value={purchaseOrderDetails ? purchaseOrderDetails.sales_manager.map(x => ({ label: x.sales_manager_email, value: x.sales_manager_email })) : []}
																isDisabled
															/>
														</div>
													</div>
													<div className="row align-items-center mt-2 fs-6 mb-3">
														<div className="text-muted me-2 fs-7 col-1">Subject: </div>
														<div className='col-8'>
															<input type="text" className="form-control" value={mainSubject} onChange={(e) => { setMainSubject(e.target.value) }} />
														</div>
													</div>

													<div>
														<ReactQuill
															theme="snow"
															modules={quill_rte_modules}
															formats={quill_rte_formats}
															value={contents}
															onChange={processMailBody}
														/>
													</div>

													{
														write &&
														<div className='d-flex my-5'>
															<button className='btn btn-light-primary mx-2' onClick={() => { sendOrderEmail() }} data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}>
																<span className="indicator-label"><i className="lar la-paper-plane" style={{ fontSize: "24px" }}></i>Send</span>
																<span className="indicator-progress">Please wait...
																	<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
																</span>
															</button>
														</div>
													}

												</div>
											</div>
										</div>
									</div>

								</div>

							</>
							: state == 2 ?
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
								</div>
								:
								<div className="card modal-body px-5 px-xl-20">
									<div className="flex-lg-row-fluid ">
										<div className="card card-flush my-5">

											<div className="card-body">
												<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
													<div className="d-flex flex-stack flex-grow-1">
														<div className="fw-bold">
															<h4 className="text-gray-800 fw-bolder">This Purchase Order is not available for Approval</h4>
															<div className="fs-6 text-gray-600"></div>
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
						}
					</div>
				</div>
			</div>
		</>);

};

export default PurchaseOrderApprovalEmail;
