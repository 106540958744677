import React, { useState, useEffect, useRef } from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom'
import moment from 'moment';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';
import Select from 'react-select';

import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import formatNumber from '../common_component/formatNumber.js';
import { ReportWidgetWrapper, ReportWidgetItem } from '../common_component/ReportWidgets.js';

function ManufacturerBalancesReport() {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()
    const [baseCurrency, setBaseCurrency] = useState(null)

    const [manufacturerBalancesReportList, setManufacturerBalancesReportList] = useState([])
    const [manufacturerBalancesReportApiState, setManufacturerBalancesReportApiState] = useState(false)

    const daterange_picker_ref = useRef()
    const apiController = useRef(new AbortController())

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [lastDate, setLastDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [mode, setMode] = useState({ label: "Include All Payments", value: "Include All Payments" })

    const [detailsModalShow, setDetailsModalShow] = useState(false)
    const [detailsModalData, setDetailsModalData] = useState([])

    var page_name = "manufacturer-balances-report";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getManufacturerBalancesReport(cp, rpp, search_v, ld, mode) {
        try {
            if (manufacturerBalancesReportApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setManufacturerBalancesReportApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-balances-report`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "last_date": ld, "mode": mode, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation !== "success") throw "get Manufacturer Balances Report api error"

            setManufacturerBalancesReportList(body.data.list)
            setListCount(body.data.count)

            setManufacturerBalancesReportApiState(false)
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBaseCurrency() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        // console.log(body)
        if (body.length > 0) {
            setBaseCurrency(body[0])
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getManufacturerBalancesReport(currentPage, rowsPerPage, searchValue, lastDate, mode.value)
            let p2 = getBaseCurrency()

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getManufacturerBalancesReport(currentPage, rowsPerPage, searchValue, lastDate, mode.value)
        }
    }, [currentPage, rowsPerPage, searchValue, lastDate, mode])

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Manufacturer Balances Report</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <DatePickerComponent
                            ref={daterange_picker_ref}
                            allowEdit={false}
                            showClearButton={false}
                            focus={() => { daterange_picker_ref.current.show() }}
                            change={(e) => {
                                setLastDate(moment(e.value).format("YYYY-MM-DD"));
                            }}
                            value={new Date(lastDate)}
                        />
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0">
                                    <ReportWidgetWrapper>
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-clipboard-data-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Total BILLs"}
                                            value={formatNumber(manufacturerBalancesReportList.reduce((p, o) => p + o.bills_list.length, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"BILLs Net Sum"}
                                            value={baseCurrency.currency_symbol + formatNumber(manufacturerBalancesReportList.reduce((p, o) => p + o.bills_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(manufacturerBalancesReportList.reduce((p, o) => p + o.bills_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"BILL Payments Net Sum"}
                                            value={baseCurrency.currency_symbol + formatNumber(manufacturerBalancesReportList.reduce((p, o) => p + o.bills_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(manufacturerBalancesReportList.reduce((p, o) => p + o.bills_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Balances"}
                                            value={baseCurrency.currency_symbol + formatNumber(manufacturerBalancesReportList.reduce((p, o) => p + o.bills_net_total, 0) - manufacturerBalancesReportList.reduce((p, o) => p + o.bills_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(manufacturerBalancesReportList.reduce((p, o) => p + o.bills_net_total, 0) - manufacturerBalancesReportList.reduce((p, o) => p + o.bills_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                    </ReportWidgetWrapper>
                                </div>

                                <div className="card-body py-3">
                                    <div className='row align-items-center mb-5'>
                                        <div className='col-md-8 my-1'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className='col-md-4 my-1'>
                                            <Select
                                                options={["Include All Payments", "Include Payments before Date only"].map(x => { return { label: x, value: x } })}
                                                onChange={(val) => { setMode(val) }}
                                                value={mode}
                                                placeholder="Select Type..."
                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                        {
                                            manufacturerBalancesReportApiState ?
                                                <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                :
                                                manufacturerBalancesReportList.length > 0 ?
                                                    <>
                                                        <div className="mb-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>

                                                        <div className='overflow-auto w-100 flex-grow-1'>
                                                            <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                <thead>
                                                                    <tr className="fw-bold text-muted">
                                                                        <th className="min-w-125px">Manufacturer Name</th>
                                                                        <th className="min-w-125px">No. of BILLs</th>
                                                                        <th className="min-w-125px">Net Total of all BILLs</th>
                                                                        <th className="min-w-125px">Net Total of all BILL Payments</th>
                                                                        <th className="min-w-125px">Balance</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        manufacturerBalancesReportList.map((obj, indx3) => {
                                                                            return (
                                                                                <tr key={obj._id}>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.manufacturer_name}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.bills_list.length} <i className="fa-solid fa-eye fs-2 p-0 text-hover-primary cursor-pointer" onClick={() => { setDetailsModalShow(true); setDetailsModalData(obj.bills_list); }}> </i></span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol}&nbsp;{formatNumber(obj.bills_net_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol}&nbsp;{formatNumber(obj.bills_payment_net_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol}&nbsp;{formatNumber(obj.bills_net_total - obj.bills_payment_net_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="mt-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>

            <Modal show={detailsModalShow} onHide={() => { setDetailsModalShow(false); setDetailsModalData([]); }} centered>
                <Modal.Header>
                    <Modal.Title>Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDetailsModalShow(false); setDetailsModalData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Bill Number</th>
                                    <th scope="col">Bill Grand Total</th>
                                    <th scope="col">Bill Payment Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    detailsModalData.map((obj, indx) => (
                                        <tr key={indx}>
                                            <td className='d-flex align-items-center'>
                                                <span className="bullet bg-primary mx-2"></span>
                                                <Link to={`/view-bill/${obj.bill_id}`}><span className='fw-bold text-gray-600 text-hover-primary'>{obj.bill_number}</span></Link>
                                            </td>
                                            <td className='fs-5'>
                                                {baseCurrency.currency_symbol}&nbsp;{formatNumber(obj.bill_grand_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            </td>
                                            <td className='fs-5'>
                                                {baseCurrency.currency_symbol}&nbsp;{formatNumber(obj.bill_payment_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default ManufacturerBalancesReport;