import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

export const AddProfile = () => {
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [state, setstate] = useState(2);

    const [Role, setRole] = useState("");
    const [UserRoleList, setUserRoleList] = useState([]);
    const [itemPriceFields, setItemPriceFields] = useState([]);
    const [itemPannelSettings, setItemPannelSettings] = useState([]);
    const [loadState, setloadState] = useState(1);
    const [defaultitemPriceFields, setdefaultItemPriceFields] = useState([]);
    const [navtype, setnavtype] = useState("Item Stock settings");

    const [negativeStock, setNegativeStock] = useState(false)
    const [stockAdditionWarehouseProcessing, setStockAdditionWarehouseProcessing] = useState(false)
    const [itemStockButtonState, setItemStockButtonState] = useState(false)

    const [profiles, setProfiles] = useState([]);
    const [profile, setProfile] = useState({ permissions: [] });
    const [newProfile, setNewProfile] = useState({ permissions: [] });
    // const [profile_name, setProfile_name] = useState("");
    // const [profile_description, setProfile_description] = useState("");
    const [profileId, setProfileId] = useState({});
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [buttonState, setButtonState] = useState(1)

    var page_name = "organization-settings";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    async function getUserRoledetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.log_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.user_role;
                temp_obj.value = obj.user_role_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setUserRoleList(temp_dropdown);
    }

    async function getPannelSettings() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-price-panel-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        //console.log(body.data[0].price_field_settings)
        setItemPannelSettings(keyValueToObjectArray(body.data));
    }

    async function getDefaultSettings() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-default-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data[0].price_field_settings)
        setdefaultItemPriceFields(JSON.parse(body.data[0].price_field_settings).fields);
    }

    async function getCustomizeSettings(val) {
        setItemPriceFields([]);
        setloadState(2);
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customize-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "role_id": val, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.data.length > 0) {
            setItemPriceFields(body.data[0].settings);
        }
        else {
            setItemPriceFields(defaultitemPriceFields);
        }
        setloadState(1);
    }

    async function getItemStockSettings() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-item-stock-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setNegativeStock(body.data.negative_item_stock_state);
            setStockAdditionWarehouseProcessing(body.data.stock_addition_warehouse_processing_state)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getUserRoledetails();
            let p2 = getDefaultSettings();
            let p3 = getItemStockSettings();
            let p4 = getPannelSettings();
            Promise.all([p1, p2, p3, p4])
                .then(() => {
                    setstate(1);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])

    // Convert a JSON object with key-value pairs to an array of objects with `key` and `value` properties.
    function keyValueToObjectArray(obj) {
        return Object.keys(obj).map(key => ({ key, value: obj[key] }));
    }

    // Convert an array of objects with `key` and `value` properties to a JSON object with key-value pairs.
    function objectArrayToKeyValue(array) {
        return array.reduce((acc, obj) => {
            acc[obj.key] = obj.value;
            return acc;
        }, {});
    }


    const handleProfile = (event) => {
        setProfileId(event.value);
        // console.log(profileId)
        setProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == event.value).user_profile_actions))
        setNewProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == event.value).user_profile_actions))
    }

    const processTitle = (title) => {
        return title.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    }

    const SaveSettings = (e) => {
        e.preventDefault();
        var object = {};
        object.role_id = Role;
        object.settings = itemPriceFields
        object.page_name = page_name;
        object.organization_id = organization_id
        object.service = process.env.REACT_APP_module_name
        let flag = 1;
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (object.role_id == "") {
            swal("Warning!", "Need to select Role", "warning");
            return false;
        }

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        if (flag === 1) {
            setButtonState(0)
            axios.post(`${process.env.REACT_APP_SERVER_URL}/save-customize-settings`, object, headers).then((res) => {
                // console.log(res.data);
                // console.log(res.data.message);
                // console.log(res.data.operation);
                if (res.data.message == "success" && res.data.operation == "insert") {
                    setButtonState(1)
                    swal("Great!", "Settings added successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else if (res.data.message == "success" && res.data.operation == "update") {
                    setButtonState(1)
                    swal("Great!", "Settings updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    setButtonState(1)
                    swal("Oops!", res.data.message, "error");
                }
            }).catch(function (error) {
                console.log(error)
            });
        }
    }

    const saveItemSettings = (e) => {

        var object = {};
        object.negative_item_stock_state = negativeStock;
        object.stock_addition_warehouse_processing_state = stockAdditionWarehouseProcessing;
        object.page_name = page_name;
        object.organization_id = organization_id;

        // console.log(object);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        setItemStockButtonState(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-item-stock-settings`, object, headers).then((res) => {
            if (res.data.operation == "success") {
                setItemStockButtonState(false)
                swal("Great!", "Settings configured successfully!", "success").then((value) => { window.location.reload() });
            }
            else {
                setItemStockButtonState(false)
                swal("Oops!", res.data.message, "error");
            }
        }).catch(function (error) {
            console.log(error)
        });
    }

    const saveItemPannelSettings = (e) => {

        var object = {};
        object.page_name = page_name;
        object.organization_id = organization_id;
        object.price_pannel_settings = objectArrayToKeyValue(itemPannelSettings);

        // console.log(object);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        setItemStockButtonState(true)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-product-price-panel-settings`, object, headers).then((res) => {
            if (res.data.operation == "success") {
                setItemStockButtonState(false)
                swal("Great!", "Settings configured successfully!", "success").then((value) => { window.location.reload() });
            }
            else {
                setItemStockButtonState(false)
                swal("Oops!", res.data.message, "error");
            }
        }).catch(function (error) {
            console.log(error)
        });
    }

    function capitalizeFirstLetterOfEachWord(string) {
        return string.replace(/(^\w|\s\w)/g, match => match.toUpperCase());
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">

                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">

                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Organization Settings
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        {
                            state == 1 ?
                                <div className="card">
                                    <div className="card-header">
                                        <div className="d-flex flex-stack flex-wrap p-5">
                                            <ul className="nav nav-pills fs-4">
                                                {/* <li className="nav-item">
                                                    <div className={`nav-link ${navtype === "Price Pannel settings" && "active"} cursor-pointer`} onClick={(e) => { setnavtype("Price Pannel settings"); }}>Price Pannel settings</div>
                                                </li> */}
                                                {/* <li className="nav-item">
                                                    <div className={`nav-link ${navtype === "Price settings" && "active"} cursor-pointer`} onClick={(e) => { setnavtype("Price settings"); }}>Price settings</div>
                                                </li> */}
                                                <li className="nav-item">
                                                    <div className={`nav-link ${navtype === "Item Stock settings" && "active"} cursor-pointer`} onClick={(e) => { setnavtype("Item Stock settings"); }}>Item Stock settings</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            navtype === "Price settings" ?
                                                <div >
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className="fs-5 fw-bold mb-2">Select Role</label>
                                                            <Select
                                                                styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                menuPosition={'fixed'}
                                                                menuShouldBlockScroll={true}
                                                                options={UserRoleList.filter(option => option.label !== "Admin")}
                                                                onChange={(event) => { setRole(event.value); getCustomizeSettings(event.value) }}
                                                                value={UserRoleList.filter(option => option.value == Role).length != 0 && UserRoleList.filter(option => option.value == Role)[0]}
                                                                placeholder="--Choose a Role--"
                                                            />
                                                            <span className="form-text text-muted">Select a already avaiable profile for reference</span>

                                                        </div>
                                                    </div>
                                                    {
                                                        itemPriceFields.length > 0 ?
                                                            <div className="row mt-10">
                                                                <div className="col-12 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2">Field Permissions</label>
                                                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                                        <div className={`card border border-border-2 rounded shadow m-3`} style={{ minHeight: "200px", minWidth: "300px" }} >
                                                                            <div className='card-header align-items-center' style={{ minHeight: "40px", padding: "10px" }}>
                                                                                Additional Fields
                                                                            </div>
                                                                            <div className=' p-5' >
                                                                                <div className='d-flex justify-content-between flex-column mb-5'>
                                                                                    {itemPriceFields.filter(option => option.default == false).map((item, index2) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.field_name)}>
                                                                                                    <span>{processTitle(item.field_name)}</span>
                                                                                                    <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                                        <input className="form-check-input w-40px h-20px" type="checkbox" checked={(item.read) && item.write} onChange={(e) => {
                                                                                                            let arr = [...itemPriceFields];
                                                                                                            // if(e.target.checked)
                                                                                                            // {
                                                                                                            for (let i = 0; i < arr.length; i++) {
                                                                                                                if (arr[i] == item) {
                                                                                                                    arr[i].read = e.target.checked ? 1 : 0;
                                                                                                                    arr[i].write = e.target.checked ? 1 : 0;
                                                                                                                }
                                                                                                            }
                                                                                                            //}
                                                                                                            setItemPriceFields(arr);
                                                                                                        }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`card border border-border-2 rounded shadow m-3`} style={{ minHeight: "200px", minWidth: "300px" }} >
                                                                            <div className='card-header align-items-center' style={{ minHeight: "40px", padding: "10px" }}>
                                                                                Default Fields
                                                                            </div>
                                                                            <div className=' p-5' >
                                                                                <div className='d-flex justify-content-between flex-column mb-5'>

                                                                                    {itemPriceFields.filter(option => option.default == true).map((item, index2) => {
                                                                                        return (
                                                                                            <>
                                                                                                <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.field_name)}>
                                                                                                    <span>{processTitle(item.field_name)}</span>
                                                                                                    <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                                        <input className="form-check-input w-40px h-20px" type="checkbox" checked={(item.read) && item.write} disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button className='btn btn-primary mx-2 text-end' onClick={(e) => SaveSettings(e)} data-kt-indicator={buttonState ? "off" : "on"} disabled={write && buttonState ? false : true} >
                                                                        <span className="indicator-label">Submit</span>
                                                                        <span className="indicator-progress">Please wait...
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            :
                                                            loadState == 1 ?
                                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6 m-10">
                                                                    <div className="d-flex flex-stack flex-grow-1">
                                                                        <div className="fw-bold">
                                                                            <h4 className="text-gray-800 fw-bolder">Need to select the role to view the Item price field Permission.</h4>
                                                                            <div className="fs-6 text-gray-600"> </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                loadState == 2 &&
                                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    }
                                                </div>
                                                : navtype === "Price Pannel settings" ?
                                                    <div>
                                                        {itemPannelSettings.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <div className="row mb-5">
                                                                        <div className="col-md-10 fv-row">
                                                                            <label className="fs-5 fw-bold mb-2">{capitalizeFirstLetterOfEachWord(item.key)}</label>
                                                                        </div>
                                                                        <div className="col-md-2 fv-row">
                                                                            <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                <input className="form-check-input w-40px h-20px" type="checkbox" checked={item.value}
                                                                                    onChange={(e) => {
                                                                                        let arr = [...itemPannelSettings];
                                                                                        arr[index].value = e.target.checked;
                                                                                        setItemPannelSettings(arr);
                                                                                    }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })}
                                                        <button className='btn btn-primary btn-sm mx-2 text-end' onClick={(e) => saveItemPannelSettings(e)} data-kt-indicator={itemStockButtonState ? "on" : "off"} disabled={(write) && itemStockButtonState ? true : false}>
                                                            <span className="indicator-label">Save </span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                    : navtype === "Item Stock settings" ?
                                                        <div>
                                                            <div className="row mb-5">
                                                                <div className="col-md-10 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2"> Do you want to allow negative stock for order/invoice?</label>
                                                                </div>
                                                                <div className="col-md-2 fv-row">
                                                                    <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                        <input className="form-check-input w-40px h-20px" type="checkbox" checked={negativeStock} onChange={(e) => { setNegativeStock(e.target.checked) }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-5">
                                                                <div className="col-md-10 fv-row">
                                                                    <label className="fs-5 fw-bold mb-2"> Do you want to allow stock addition in warehouse processing?</label>
                                                                </div>
                                                                <div className="col-md-2 fv-row">
                                                                    <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                        <input className="form-check-input w-40px h-20px" type="checkbox" checked={stockAdditionWarehouseProcessing} onChange={(e) => { setStockAdditionWarehouseProcessing(e.target.checked) }} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        : null
                                        }
                                    </div>
                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <form >
                                            {
                                                write &&
                                                <>
                                                    {navtype == "Item Stock settings" &&
                                                        <button className='btn btn-primary btn-sm my-2 text-end' onClick={(e) => saveItemSettings(e)} data-kt-indicator={itemStockButtonState ? "on" : "off"} disabled={(write) && itemStockButtonState ? true : false}>
                                                            <span className="indicator-label">Save Settings</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    }

                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                                :
                                state == 2 ?
                                    <div className="card">
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    </div>
                                    :
                                    <div className="card">
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6 m-10">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                    <div className="fs-6 text-gray-600"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }

                    </div>
                </div>

            </div>
        </>
    )
}

export default AddProfile;