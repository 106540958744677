import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Redirect, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { Navbar, Nav, Container } from 'react-bootstrap';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from '../common_component/PageError.js';

export const ViewBill = (props) => {
	const history = useHistory()

	const [billDetails, setBillDetails] = useState([]);
	const [billPaymentDetails, setBillPaymentDetails] = useState([]);
	const [receiveGoodsRecords, setReceiveGoodsRecords] = useState([]);
	const [currencySymbol, setCurrencySymbol] = useState("")
	const [decimalPlace, setDecimalPlace] = useState(3)

	const [approvalChainPopup, setApprovalChainPopup] = useState(false)

	const [approveFinalButtonShow, setApproveFinalButtonShow] = useState(false)
	const [approveButtonShow, setApproveButtonShow] = useState(false)
	const [cancelButtonShow, setCancelButtonShow] = useState(false)

	const [userRole, setUserRole] = useState("")
	const [submitButtonState, setSubmitButtonState] = useState(false);
	const [state, setstate] = useState(2);


	var page_name = "view-bill";

	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		async function getBillDetails() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-bill-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "bill_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			if (body.operation === "success") {
				setBillDetails(body.data);
				setReceiveGoodsRecords(body.data.associated_gr_list.map(x => { return { ...x, showReceiveGoodsDetails: false } }))
				setCurrencySymbol(body.data.bill_currency.currency_symbol)
				setDecimalPlace(parseInt(body.data.bill_currency.decimal_place))
				setBillPaymentDetails(body.data.payment_logs);

				const response = await fetch(
					`${process.env.REACT_APP_SERVER_URL}/individual-user-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "unique_id": "", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
				);
				const t_body = await response.json();

				let user_role = t_body.data[0].user_role
				setUserRole(user_role)

				if (user_role === "Admin") {
					setApproveFinalButtonShow(true)
					setCancelButtonShow(true)
				}

				let t1 = body.data.approval_info
				let t2 = body.data.add_status
				let t3 = body.data.approval_level

				if (t2 === "BILL_PENDING_APPROVAL") {
					if (t1.type === "multilevel_approval") {

						let tl = t1.value.find(x => x.level === (t3 == null ? 1 : t3 + 1)).user_value
						if (tl.includes(user.email)) {
							setApproveButtonShow(true)
						}

						let tl2 = t1.value.map(x => x.user_value).flat(1)
						if (tl2.includes(user.email)) {
							setCancelButtonShow(true)
						}
					}
					else if (t1.type === "custom_approval") {
						let ty = t1.value[0]
						if (ty.user_type === "Group User") {
							if (ty.user_value.includes(user_role)) {
								setApproveButtonShow(true)
								setCancelButtonShow(true)
							}
						}
						else if (ty.user_type === "Individual User") {
							if (ty.user_value.includes(user.email)) {
								setApproveButtonShow(true)
								setCancelButtonShow(true)
							}
						}
					}
				}

				setstate(1);
			}
			else {
				setstate(3);
			}
		}

		if ((read || write) && (user.email && visibility)) {
			getBillDetails();
		}
	}, [user, visibility, read, write])

	const approveBillSubmit = (final_flag, cancel_flag, cancel_msg) => {

		var object = {};

		object.final_flag = final_flag
		object.cancel_flag = cancel_flag
		object.cancel_msg = cancel_msg
		object.bill_id = props.match.params.id
		object.user_email = user.email
		object.user_role = userRole

		object.organization_id = organization_id
		object.page_name = page_name;

		// console.log(object);
		setSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-bill`, object, headers).then((res) => {
			setSubmitButtonState(false)
			if (res.data.operation === "success") {
				swal("Great!", "Bill reviewed successfully!", "success").then(() => { history.push("/bill-list") })
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
							<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Bill
								<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
							</h1>
							<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
								<li className="breadcrumb-item text-muted"><span className="text-muted">Home</span></li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">Bill List</li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">View Bill</li>
							</ul>
						</div>

						{
							state === 1 &&
							<Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
								<Container>
									<Navbar.Toggle aria-controls="responsive-navbar-nav" />
									<Navbar.Collapse className='position-relative'>
										<Nav className="align-items-end end-0 gap-3 navbar-nav p-5 position-absolute">
											{
												approveFinalButtonShow && billDetails.add_status === "BILL_PENDING_APPROVAL" &&
												<Nav.Item>
													<button className='btn btn-light-success btn-hover-scale border border-success' title='Final Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal("Alert!", "Are you sure you want to proceed?", {
																buttons: {
																	nothing: { text: "Cancel", className: "btn btn-secondary" },
																	edit: { text: "Modify", className: "btn btn-warning" },
																	approve: { text: "Approve", className: "btn btn-danger" },
																}
															})
																.then((value) => {
																	switch (value) {
																		case "edit": history.push(`/edit-bill/${props.match.params.id}`); break;
																		case "approve": approveBillSubmit(true, false, ""); break;
																	}
																});
														}}
													>
														<span className="indicator-label"><i className="bi bi-shield-check p-0 fs-1"></i></span>
														<span className="indicator-progress">Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
														</span>
													</button>
												</Nav.Item>
											}
											{
												approveButtonShow && billDetails.add_status === "BILL_PENDING_APPROVAL" &&
												<Nav.Item>
													<button className='btn btn-light-success btn-hover-scale border border-success' title='Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal("Alert!", "Are you sure you want to proceed?", {
																buttons: {
																	nothing: { text: "Cancel", className: "btn btn-secondary" },
																	edit: { text: "Modify", className: "btn btn-warning" },
																	approve: { text: "Approve", className: "btn btn-danger" },
																}
															})
																.then((value) => {
																	switch (value) {
																		case "edit": history.push(`/edit-bill/${props.match.params.id}`); break;
																		case "approve": approveBillSubmit(false, false, ""); break;
																	}
																});
														}}
													>
														<span className="indicator-label"><i className="bi bi-check-circle p-0 fs-1"></i></span>
														<span className="indicator-progress">Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
														</span>
													</button>
												</Nav.Item>
											}
											{
												cancelButtonShow && billDetails.add_status === "BILL_PENDING_APPROVAL" &&
												<Nav.Item>
													<button className='btn btn-light-danger btn-hover-scale border border-danger' title='Cancel' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal({
																title: "Alert!",
																text: "Please type a reason before cancelling",
																icon: "warning",
																content: "input",
																buttons: true,
																dangerMode: true,
															})
																.then((msg) => {
																	if (msg === "") {
																		swal("Alert!", "Message cannot be empty", "warning")
																	} else if (msg) {
																		approveBillSubmit(false, true, msg);
																	}
																});
														}}
													>
														<span className="indicator-label"><i className="bi bi-x-circle p-0 fs-1"></i></span>
														<span className="indicator-progress">Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
														</span>
													</button>
												</Nav.Item>
											}
											{
												(billDetails.add_status === "BILL_PENDING_APPROVAL" || billDetails.add_status === "BILL_DRAFT") &&
												<Nav.Item>
													<button className='btn btn-light-warning btn-hover-scale border border-warning' title='Edit' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal("Alert!", "Are you sure you want to proceed?", {
																buttons: {
																	nothing: { text: "Cancel", className: "btn btn-secondary" },
																	edit: { text: "Modify", className: "btn btn-warning" }
																}
															})
																.then((value) => {
																	switch (value) {
																		case "edit": history.push(`/edit-bill/${props.match.params.id}`); break;
																	}
																});
														}}
													>
														<i className="bi bi-pencil-square fs-1 p-0"></i>
													</button>
												</Nav.Item>
											}
										</Nav>
									</Navbar.Collapse>
								</Container>
							</Navbar>
						}

					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state === 1 ?
							<>
								<div className="d-flex flex-column gap-7 gap-lg-10">
									<div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
										<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#bill_summary" aria-selected="true" role="tab">Bill Summary</a>
											</li>
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#bill_history" aria-selected="false" role="tab" tabIndex="-1">Bill History</a>
											</li>
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#associated_gr" aria-selected="false" role="tab" tabIndex="-1">Associated GR</a>
											</li>
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#payment_history" aria-selected="false" role="tab" tabIndex="-1">Payment History</a>
											</li>
										</ul>
									</div>
									<div className="tab-content">
										<div className="tab-pane fade active show" id="bill_summary" role="tab-panel">
											<div className="d-flex flex-column gap-7 gap-lg-10">
												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card flex-row-fluid">
														<div className="card-header">
															<div className="card-title">
																<h2>Bill Details (#{billDetails.bill_number})</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Status
																				</div>
																			</td>
																			<td className="fw-bold text-end">
																				<div style={{ position: "relative", display: "inline-block" }} >
																					<button className={`py-2 me-1 rounded btn btn-sm btn-${billDetails.add_status === "BILL_PENDING_APPROVAL" ? "warning" : "secondary"}`} onClick={() => { setApprovalChainPopup(true); }} onBlur={() => { setApprovalChainPopup(false) }}>
																						{billDetails.add_status.replace("BILL", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
																					</button>
																					<div style={{ position: "absolute", right: "0px", top: "0px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#FFFFFF", color: "black", padding: "1rem", width: "25rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: approvalChainPopup ? "1" : "0", visibility: approvalChainPopup ? "visible" : "hidden" }}>
																						{
																							billDetails.approval_info.type === "multilevel_approval" ?
																								<>
																									<div className='text-center border-bottom fs-4'>Multilevel Approval</div>
																									<div>
																										{
																											billDetails.approval_info.value.map((obj, i) => {
																												return (
																													<div key={i} className='mx-auto mt-2 gap-5' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px", position: "relative", marginBottom: "30px" }}>
																														<div style={{ width: "30px", height: "30px", border: "5px #ffc700 solid", borderRadius: "10px" }}></div>
																														<div className='flex-grow-1 text-start'>
																															<div className='fs-4'>Level {obj.level}</div>
																															{
																																obj.user_value.map((val, j) => {
																																	return <div key={j} className="fs-7"><span className='bullet bg-primary me-2'></span>{val}</div>
																																})
																															}
																														</div>
																														<div style={{ position: "absolute", width: "2px", height: "30px", backgroundColor: "darkgray", top: "100%", left: "24px" }}></div>
																													</div>
																												)
																											})
																										}

																										<div className='mx-auto my-2 gap-20' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px" }}>
																											<i className="fa-solid fa-star fs-1" style={{ color: "#ff8100" }} ></i>
																											<div className='fs-4'>Approved</div>
																										</div>
																									</div>
																								</>
																								:
																								billDetails.approval_info.type === "custom_approval" &&
																								<>
																									<div className='text-center border-bottom fs-4'>Custom Approval</div>
																									<div>
																										<div className='mx-auto mt-2 gap-5' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px", position: "relative", marginBottom: "30px" }}>
																											<div style={{ width: "30px", height: "30px", border: "5px #ffc700 solid", borderRadius: "10px" }}></div>
																											<div className='flex-grow-1 text-start'>
																												<div className='fs-4'>{billDetails.approval_info.value[0].user_type}</div>
																												{
																													billDetails.approval_info.value[0].user_value.map((val, i) => {
																														return <div key={i} className="fs-7"><span className='bullet bg-primary me-2'></span>{val}</div>
																													})
																												}
																											</div>
																											<div style={{ position: "absolute", width: "2px", height: "30px", backgroundColor: "darkgray", top: "100%", left: "24px" }}></div>
																										</div>
																										<div className='mx-auto my-2 gap-20' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px" }}>
																											<i className="fa-solid fa-star fs-1" style={{ color: "#ff8100" }} ></i>
																											<div className='fs-4'>Approved</div>
																										</div>
																									</div>
																								</>
																						}
																					</div>
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Associated GR(s)
																				</div>
																			</td>
																			<td className="fw-bold text-end">
																				{
																					billDetails.goods_received_id.map((x, i) => {
																						return <span key={i} className="badge badge-info mx-1 rounded">{x.gr_number}</span>
																					})
																				}
																			</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Bill Date
																				</div>
																			</td>
																			<td className="fw-bold text-end">{moment(billDetails.bill_date).format("DD-MM-YYYY")}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Due Date
																				</div>
																			</td>
																			<td className="fw-bold text-end">{moment(billDetails.due_date).format("DD-MM-YYYY")}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>

												</div>

												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>User Details</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																							<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																						</svg>
																					</span>User Type
																				</div>
																			</td>
																			<td className="fw-bold text-end">{billDetails.user_type}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path>
																							<path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path>
																							<rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect>
																						</svg>
																					</span>User Name
																				</div>
																			</td>
																			<td className="fw-bold text-end">{billDetails.doc_user_name}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																							<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																						</svg>
																					</span>User Email
																				</div>
																			</td>
																			<td className="fw-bold text-end">{billDetails.doc_user_email}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>Other Details</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Branch
																				</div>
																			</td>
																			<td className="fw-bold text-end">{billDetails.branch_name}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="currentColor"></path>
																							<path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="currentColor"></path>
																						</svg>
																					</span>Source Of Supply
																				</div>
																			</td>
																			<td className="fw-bold text-end">{billDetails.source_of_supply}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21.6 11.2L19.3 8.89998V5.59993C19.3 4.99993 18.9 4.59993 18.3 4.59993H14.9L12.6 2.3C12.2 1.9 11.6 1.9 11.2 2.3L8.9 4.59993H5.6C5 4.59993 4.6 4.99993 4.6 5.59993V8.89998L2.3 11.2C1.9 11.6 1.9 12.1999 2.3 12.5999L4.6 14.9V18.2C4.6 18.8 5 19.2 5.6 19.2H8.9L11.2 21.5C11.6 21.9 12.2 21.9 12.6 21.5L14.9 19.2H18.2C18.8 19.2 19.2 18.8 19.2 18.2V14.9L21.5 12.5999C22 12.1999 22 11.6 21.6 11.2Z" fill="currentColor"></path>
																							<path d="M11.3 9.40002C11.3 10.2 11.1 10.9 10.7 11.3C10.3 11.7 9.8 11.9 9.2 11.9C8.8 11.9 8.40001 11.8 8.10001 11.6C7.80001 11.4 7.50001 11.2 7.40001 10.8C7.20001 10.4 7.10001 10 7.10001 9.40002C7.10001 8.80002 7.20001 8.4 7.30001 8C7.40001 7.6 7.7 7.29998 8 7.09998C8.3 6.89998 8.7 6.80005 9.2 6.80005C9.5 6.80005 9.80001 6.9 10.1 7C10.4 7.1 10.6 7.3 10.8 7.5C11 7.7 11.1 8.00005 11.2 8.30005C11.3 8.60005 11.3 9.00002 11.3 9.40002ZM10.1 9.40002C10.1 8.80002 10 8.39998 9.90001 8.09998C9.80001 7.79998 9.6 7.70007 9.2 7.70007C9 7.70007 8.8 7.80002 8.7 7.90002C8.6 8.00002 8.50001 8.2 8.40001 8.5C8.40001 8.7 8.30001 9.10002 8.30001 9.40002C8.30001 9.80002 8.30001 10.1 8.40001 10.4C8.40001 10.6 8.5 10.8 8.7 11C8.8 11.1 9 11.2001 9.2 11.2001C9.5 11.2001 9.70001 11.1 9.90001 10.8C10 10.4 10.1 10 10.1 9.40002ZM14.9 7.80005L9.40001 16.7001C9.30001 16.9001 9.10001 17.1 8.90001 17.1C8.80001 17.1 8.70001 17.1 8.60001 17C8.50001 16.9 8.40001 16.8001 8.40001 16.7001C8.40001 16.6001 8.4 16.5 8.5 16.4L14 7.5C14.1 7.3 14.2 7.19998 14.3 7.09998C14.4 6.99998 14.5 7 14.6 7C14.7 7 14.8 6.99998 14.9 7.09998C15 7.19998 15 7.30002 15 7.40002C15.2 7.30002 15.1 7.50005 14.9 7.80005ZM16.6 14.2001C16.6 15.0001 16.4 15.7 16 16.1C15.6 16.5 15.1 16.7001 14.5 16.7001C14.1 16.7001 13.7 16.6 13.4 16.4C13.1 16.2 12.8 16 12.7 15.6C12.5 15.2 12.4 14.8001 12.4 14.2001C12.4 13.3001 12.6 12.7 12.9 12.3C13.2 11.9 13.7 11.7001 14.5 11.7001C14.8 11.7001 15.1 11.8 15.4 11.9C15.7 12 15.9 12.2 16.1 12.4C16.3 12.6 16.4 12.9001 16.5 13.2001C16.6 13.4001 16.6 13.8001 16.6 14.2001ZM15.4 14.1C15.4 13.5 15.3 13.1 15.2 12.9C15.1 12.6 14.9 12.5 14.5 12.5C14.3 12.5 14.1 12.6001 14 12.7001C13.9 12.8001 13.8 13.0001 13.7 13.2001C13.6 13.4001 13.6 13.8 13.6 14.1C13.6 14.7 13.7 15.1 13.8 15.4C13.9 15.7 14.1 15.8 14.5 15.8C14.8 15.8 15 15.7 15.2 15.4C15.3 15.2 15.4 14.7 15.4 14.1Z" fill="currentColor"></path>
																						</svg>
																					</span>Currency
																				</div>
																			</td>
																			<td className="fw-bold text-end">{billDetails.bill_currency.currency_code}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>

												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card card-flush flex-equal">
														<div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
															<img src="/assets/media/icons/duotune/ecommerce/ecm001.svg" className="w-125px" alt="Location Icon" />
														</div>
														<div className="card-header">
															<div className="card-title">
																<h2>Billing Address</h2>
															</div>
														</div>
														<div className="card-body py-3">
															<div className="address-info">
																{
																	billDetails.billing_address.address_line1 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {billDetails.billing_address.address_line1}
																	</div>
																}
																{
																	billDetails.billing_address.address_line2 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {billDetails.billing_address.address_line2}
																	</div>
																}
																<div className="location">
																	<i className="fas fa-map-marker-alt"></i> {billDetails.billing_address.city}, {billDetails.billing_address.state}
																</div>
																<div className="pincode">
																	<i className="fas fa-thumbtack"></i> {billDetails.billing_address.pincode}
																</div>
																<div className="country">
																	<i className="fas fa-globe"></i> {billDetails.billing_address.country}
																</div>
															</div>
														</div>
													</div>
													<div className="card card-flush flex-equal">
														<div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
															<img src="/assets/media/icons/duotune/ecommerce/ecm006.svg" className="w-125px" alt="Location Icon" />
														</div>
														<div className="card-header">
															<div className="card-title">
																<h2>Shipping Address</h2>
															</div>
														</div>
														<div className="card-body py-3">
															<div className="address-info">
																{
																	billDetails.shipping_address.address_line1 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {billDetails.shipping_address.address_line1}
																	</div>
																}
																{
																	billDetails.shipping_address.address_line2 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {billDetails.shipping_address.address_line2}
																	</div>
																}
																<div className="location">
																	<i className="fas fa-map-marker-alt"></i> {billDetails.shipping_address.city}, {billDetails.shipping_address.state}
																</div>
																<div className="pincode">
																	<i className="fas fa-thumbtack"></i> {billDetails.shipping_address.pincode}
																</div>
																<div className="country">
																	<i className="fas fa-globe"></i> {billDetails.shipping_address.country}
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="card">
													<div className="card-header align-items-center py-5 gap-2 gap-md-5">
														<div className="card-title">
															<h2>Bill #{billDetails.bill_number}</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="d-flex justify-content-between flex-column">
															<div className="d-flex justify-content-between flex-column">
																<div className="table-responsive border-bottom mb-9">
																	<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																		<thead>
																			<tr className="border-bottom fs-6 fw-bold text-muted">
																				<th className="min-w-100px">Products</th>
																				<th className="min-w-70px">Rate<br />(Tax Exclusive)</th>
																				<th className="min-w-70px">Rate<br />(Tax Inclusive)</th>
																				<th className="min-w-80px">QTY</th>
																				<th className="min-w-70px">Discount</th>
																				<th className="min-w-70px">Total Amt.<br />Excl.</th>
																				<th className="min-w-70px">Tax Percentage</th>
																				<th className="min-w-70px">Tax Amount</th>
																				<th className="min-w-100px text-end pb-2">Total</th>
																			</tr>
																		</thead>
																		<tbody className="fw-semibold text-gray-600">
																			{
																				billDetails.item_array.map((obj, index) => {
																					return (
																						<tr key={index}>
																							<td className="fw-bold">
																								<span>{obj.item_name}</span>
																								{obj.tax_code !== "" && <span className="badge badge-light-primary me-2">{obj.tax_code_type}: {obj.tax_code}</span>}
																							</td>
																							<td>{currencySymbol} {Math.round(obj.rate * decimalPlace) / decimalPlace}</td>
																							<td>{currencySymbol} {Math.round((obj.rate + (obj.rate * obj.tax_group_percentage / 100)) * decimalPlace) / decimalPlace}</td>
																							<td>{obj.quantity}</td>
																							<td>
																								<span>{currencySymbol} {obj.discount_type === "amount" ? obj.discount_unit : Math.round((obj.rate * obj.quantity * Number(obj.discount_unit) / 100) * decimalPlace) / decimalPlace}</span>
																								{obj.discount_type !== "amount" && <span className="badge badge-light-primary me-2">{obj.discount_type !== "amount" && obj.discount_unit} %</span>}
																							</td>
																							<td>{currencySymbol} {Math.round(((obj.quantity * obj.rate) - (obj.discount_type === "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace}</td>
																							{
																								obj.taxable === 1 ?
																									<td>
																										<div className="ms-5">
																											<div>{obj.tax_group_name} {obj.tax_group_percentage}%</div>
																											<div className="mb-8">
																												{
																													obj.tax_group_details.map((Obj1, index1) => {
																														return (
																															<span key={index1} className="badge badge-light-primary me-2">{Obj1.tax_name} ({Obj1.tax_percentage}%)</span>
																														)
																													})
																												}
																											</div>
																										</div>
																									</td>
																									: <td>---</td>
																							}
																							<td>{currencySymbol} {Math.round((obj.taxable === 0 ? 0 : ((obj.quantity * obj.rate) - (obj.discount_type === "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100)) * decimalPlace) / decimalPlace}</td>
																							<td className="text-end">{currencySymbol} {Math.round((((obj.quantity * obj.rate) - (obj.discount_type === "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) + (obj.taxable === 0 ? 0 : ((obj.quantity * obj.rate) - (obj.discount_type === "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace}</td>
																						</tr>
																					)
																				})
																			}
																		</tbody>
																	</table>
																</div>
																<div className="d-flex flex-wrap justify-content-between">
																	<div className="d-flex flex-column mw-md-300px">
																		<div className="fw-semibold fs-5 mb-3 text-dark">Tax Calculation</div>
																		{
																			billDetails.item_array.reduce((p, o) => {
																				if (o.tax_group_id == null) {
																					return p
																				}
																				else {
																					let decimal = Math.pow(10, parseInt(billDetails.bill_currency.decimal_place));
																					let total_taxable_amount = Math.round(((o.quantity * o.rate) * (o.tax_group_percentage / 100)) * decimal) / decimal;
																					let total_tax = o.tax_group_details == null ? 0 : o.tax_group_details.reduce((p2, o2) => { return p2 + Number(o2.tax_percentage) }, 0);

																					o.tax_group_details.forEach((obj1) => {
																						if (p.find(x => x.tax_id === obj1.tax_id)) {
																							let t = p.find(x => x.tax_id === obj1.tax_id);
																							t.tax_amount = t.tax_amount + Math.round((Number(total_taxable_amount) * Number(obj1.tax_percentage) / Number(total_tax)) * decimal) / decimal;
																						}
																						else {
																							let json = {};
																							json.tax_id = obj1.tax_id;
																							json.tax_name = obj1.tax_name;
																							json.tax_percentage = obj1.tax_percentage;
																							json.tax_amount = Math.round((Number(total_taxable_amount) * Number(obj1.tax_percentage) / Number(total_tax)) * decimal) / decimal;
																							p.push(json);
																						}
																					})

																					return p
																				}
																			}, []).map((obj, index) => {
																				return (
																					<div key={index} className="d-flex text-gray-800 mb-3 fs-6">
																						<div className="fw-semibold pe-5">{obj.tax_name} ({obj.tax_percentage}%):</div>
																						<div className="text-end">{billDetails.bill_currency.currency_symbol} {obj.tax_amount}</div>
																					</div>
																				)
																			})
																		}
																	</div>

																	<div className="d-flex justify-content-end">
																		<div className="mw-300px">
																			<div className="d-flex flex-stack mb-3">
																				<div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
																				<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol} {Math.round(billDetails.item_array.reduce((p, o) => { return p + (((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.taxable === 0 ? 0 : ((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (o.tax_group_percentage / 100))) }, 0) * decimalPlace) / decimalPlace}</div>
																			</div>
																			{
																				billDetails.tax_category !== null &&
																				<div className="d-flex flex-stack mb-3">
																					<div className="fw-semibold pe-10 text-gray-600 fs-7">Tax Percentage {`${billDetails.tax_name} - ${billDetails.tax_percentage}%`}</div>
																					<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol} {Math.round((billDetails.tax_category === "TDS" ? billDetails.item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : Math.round(billDetails.item_array.reduce((p, o) => { return p + (((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.taxable === 0 ? 0 : ((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (o.tax_group_percentage / 100))) }, 0) * decimalPlace) / decimalPlace) * (billDetails.tax_id == null ? 0 : parseFloat(billDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace}</div>
																				</div>
																			}
																			<div className="d-flex flex-stack mb-3">
																				<div className="fw-semibold pe-10 text-gray-600 fs-7">{billDetails.adjustment.text}</div>
																				<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol} {billDetails.adjustment.value}</div>
																			</div>
																			<div className="d-flex flex-stack">
																				<div className="fw-semibold pe-10 text-gray-600 fs-7">Total</div>
																				<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol} {billDetails.grand_total}</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="card">
													<div className="card-header">
														<div className="card-title">
															<h2>Additional Information</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="table-responsive">
															<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																<tbody className="fw-semibold text-gray-600">
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Term
																			</div>
																		</td>
																		<td className="fw-bold text-end">{billDetails.term == null ? "No term period added" : (billDetails.term.term_name + "---" + billDetails.term.term_days + " days")}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Internal Notes
																			</div>
																		</td>
																		<td className="fw-bold text-end">{billDetails.internal_notes !== "" ? billDetails.internal_notes : "No notes provided"}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Terms and Conditions
																			</div>
																		</td>
																		<td className="fw-bold text-end">{billDetails.terms_and_conditions !== "" ? billDetails.terms_and_conditions : "No terms and conditions provided"}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>

											</div>
										</div>

										<div className="tab-pane fade" id="bill_history" role="tab-panel">
											<div className="d-flex flex-column gap-7 gap-lg-10">
												<div className="card">
													<div className="card-header">
														<div className="card-title">
															<h2>Bill History</h2>
														</div>
													</div>

													<div className="card-body py-4">
														{billDetails.bills_status_logs.length > 0 &&
															<div className="table-responsive">
																<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																	<thead>
																		<tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
																			<th className="min-w-100px">Date Added</th>
																			<th className="min-w-175px">Comment</th>
																			<th className="min-w-70px">Bill Status</th>
																		</tr>
																	</thead>
																	<tbody className="fw-semibold text-gray-600">
																		{billDetails.bills_status_logs.map((obj, index) => {

																			return (
																				<tr key={index}>
																					<td>{moment(obj.timestamp).format("ll")}</td>
																					<td>{obj.operation_message}</td>
																					<td>
																						<StatusBadge status={obj.bill_status} entity={"BILL"} />
																					</td>
																				</tr>
																			)
																		})}
																	</tbody>
																</table>
															</div>}
													</div>
												</div>
											</div>
										</div>

										<div className="tab-pane fade" id="associated_gr" role="tab-panel">
											<div className='row gx-0 my-10'>
												{
													receiveGoodsRecords.map((obj, i) => {
														return (
															<div className="col-md-6 mb-6 px-2" key={i}>
																<div className="card bg-white">
																	<div className="card-body">
																		<div className="d-flex align-items-center justify-content-between mb-8">
																			<div>
																				<span className="fs-2 fw-bold me-5">{obj.gr_number}</span>
																				<span className="fs-2 me-2">STATUS:</span>
																				<span className='fs-2' style={{ color: (obj.add_status === "GOODS_RECEIVED_FINALIZED") ? "green" : ((obj.add_status === "GOODS_RECEIVED_PENDING_APPROVAL") || (obj.add_status === "GOODS_RECEIVED_DRAFT")) ? "orange" : (obj.add_status === "GOODS_RECEIVED_CANCELLED") ? "red" : "red" }} >
																					{obj.add_status.replace("GOODS_RECEIVED", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
																				</span>
																			</div>

																			<button className="btn btn-sm btn-primary px-2 py-1"
																				onClick={() => {
																					let t = receiveGoodsRecords.map((x) => { return { ...x } })
																					t[i].showReceiveGoodsDetails = !t[i].showReceiveGoodsDetails
																					setReceiveGoodsRecords(t)
																				}}
																			>
																				{obj.showReceiveGoodsDetails ? <svg height="28" viewBox="0 -960 960 960" width="28"><path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" /></svg> : <svg height="28" viewBox="0 -960 960 960" width="28"><path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" /></svg>}
																			</button>
																		</div>
																		{
																			obj.showReceiveGoodsDetails &&
																			<>
																				<div className="d-flex justify-content-between align-items-center mb-3">
																					<span className="fw-bold fs-5 text-primary">User</span>
																					<span className="fs-5 fw-semibold">{obj.user_type} - {obj.doc_user_name}</span>
																				</div>
																				<div className="d-flex justify-content-between align-items-center mb-3">
																					<span className="fw-bold fs-5 text-primary">Branch</span>
																					<span className="fs-5 fw-semibold">{obj.branch_name}</span>
																				</div>
																				<div className="d-flex justify-content-between align-items-center mb-3">
																					<span className="fw-bold fs-5 text-primary">Warehouse</span>
																					<span className="fs-5 fw-semibold">{obj.warehouse_name}</span>
																				</div>
																				<div className="d-flex justify-content-between align-items-center mb-3">
																					<span className="fw-bold fs-5 text-primary">Created On</span>
																					<span className="fs-5 fw-semibold">{moment(obj.add_date_time).format("lll")}</span>
																				</div>
																				<div className="mb-3">
																					<span className="fw-bold fs-5 text-primary">Received Items</span>
																					<div className='mx-10'>
																						{
																							obj.item_array.map((x, i) => {
																								return (
																									<div key={i} className="fs-5 fw-semibold d-flex justify-content-between">
																										<span>{x.item_name}</span>
																										<span>{x.received_qty} units</span>
																									</div>
																								)
																							})
																						}
																					</div>
																				</div>
																			</>
																		}

																	</div>
																</div>
															</div>

														)
													})
												}
											</div>
										</div>
										<div className="tab-pane fade" id="payment_history" role="tab-panel">
											<div className="d-flex flex-column gap-7 gap-lg-10">
												<div className="card card-flush py-4 flex-row-fluid">
													<div className="card-header">
														<div className="card-title">
															<h2>Payment History</h2>
														</div>
													</div>
													<div className="card-body pt-0">
														{
															billPaymentDetails.length > 0 &&
															<div className="table-responsive">
																<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																	<thead>
																		<tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
																		<th className="min-w-70px">Ref No.</th>
																			<th className="min-w-175px">Payment Amount</th>
																			<th className="min-w-70px">Added By</th>
																			<th className="min-w-100px">Timestamp</th>
																			<th className="min-w-100px">Status</th>
																		</tr>
																	</thead>
																	<tbody className="fw-semibold text-gray-600">
																		{
																			billPaymentDetails.map((Obj, index) => {
																				return (
																					<tr key={index}>
																						<td>{Obj.bill_id}</td>
																						<td>{currencySymbol}{Obj.payment_amount}</td>
																						<td><span className="badge badge-secondary">{Obj.user_id}</span></td>
																						<td>{moment(Obj.add_date_time).format("lll")}</td>
																						<td>
																							<div className={`badge badge-${Obj.log_status === "Cancelled"
																								? "danger"
																								: Obj.log_status === "Paid"
																									? "success"
																								: Obj.log_status === "Pending"
																									? "warning"
																									: "secondary"}
																									`}>
																								{Obj.log_status}
																							</div>
																						</td>
																					</tr>
																				)
																			})
																		}
																	</tbody>
																</table>
															</div>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

							</>
							: state === 2 ?
								<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
								:
								<PageError />
						}
					</div>
				</div>
			</div>
		</>);

};

export default ViewBill;
