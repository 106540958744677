
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from '@sweetalert/with-react'
import moment from 'moment';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Select, { components } from 'react-select';
import { Modal } from 'react-bootstrap';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';
import { barConfig, pieConfig } from '../common_component/nivoGraphConfiguration.js';

export const EditGoodsReceive = (props) => {

    const { theme, dropdownStyleDark } = useTheme()
    const history = useHistory()

    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(null);
    const [receiveGoodsRecords, setReceiveGoodsRecords] = useState([]);

    const [creditorList, setCreditorList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [manufacturerList, setManufacturerList] = useState([])
    const [branchList, setBranchlist] = useState([])
    const [transactionSeriesList, setTransactionSeriesList] = useState([])
    const [warehouseList, setWarehouseList] = useState([])
    const [itemList, setItemList] = useState([])
    const [purchaseOrderList, setPurchaseOrderList] = useState([])

    const [userListApiState, setUserListApiState] = useState(false);
    const [itemApiState, setItemApiState] = useState(false);
    const [purchaseOrderListApiState, setPurchaseOrderListApiState] = useState(false)
    const [purchaseOrderDetailsApiState, setPurchaseOrderDetailsApiState] = useState(false)

    const itemListApiController = useRef(new AbortController())
    const userListApiController = useRef(new AbortController())

    const [goodsReceivedDetails, setGoodsReceivedDetails] = useState(null)


    const [userType, setUserType] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedTransactionSeries, setSelectedTransactionSeries] = useState(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [goodsReceivedNumber, setGoodsReceivedNumber] = useState("")
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null)

    const [goodsReceiveItemArray, setGoodsReceiveItemArray] = useState([{ item_id: null, item_name: null, item_stock_type: null, received_qty: 0, item_data: null }])

    const [submitButtonState, setSubmitButtonState] = useState(false)


    const [modalRefProductId, setModalRefProductId] = useState(null)
    const [saveButtonState, setSaveButtonState] = useState(false)

    const [addSerialModalShow, setAddSerialModalShow] = useState(false);
    const [selectedCamera, setSelectedCamera] = useState("user");
    const [serialInput, setSerialInput] = useState("")
    const [serialArray, setSerialArray] = useState([])

    const [addBatchModalShow, setAddBatchModalShow] = useState(false)
    const [batchArray, setBatchArray] = useState([])

    const [approvalInfo, setApprovalInfo] = useState(null);

    const [state, setstate] = useState(2);
    const [failedPageMessage, setFailedPageMessage] = useState("Something went wrong. Please reload this page.")

    var page_name = "edit-goods-receive";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getCreditorBySearch(search_value) {
        try {
            if (userListApiState) {
                userListApiController.current.abort()
                userListApiController.current = new AbortController()
            }
            setUserListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-creditor-by-search`, { signal: userListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            setUserListApiState(false);
            if (body.operation === "success") {
                setCreditorList(body.data.list.filter(x => x.add_status == "Active"))
            }
            else {
                throw "creditor list api error"
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getVendorBySearch(search_value) {
        try {
            if (userListApiState) {
                userListApiController.current.abort()
                userListApiController.current = new AbortController()
            }
            setUserListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-vendor-by-search`, { signal: userListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            setUserListApiState(false);
            if (body.operation === "success") {
                setVendorList(body.data.list.filter(x => x.add_status == "Active"))
            }
            else {
                throw "vendor list api error"
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getManufacturerBySearch(search_value) {
        try {
            if (userListApiState) {
                userListApiController.current.abort()
                userListApiController.current = new AbortController()
            }
            setUserListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-by-search`, { signal: userListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            setUserListApiState(false);
            if (body.operation === "success") {
                setManufacturerList(body.data.list.filter(x => x.add_status == "Active"))
            }
            else {
                throw "manufacturer list api error"
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setBranchlist(body.data);
            let t = body.data.find(x => x.default_branch == "yes")
            setSelectedBranch({ label: t.branch_name, value: t.branch_id })
        }
        else {
            throw "branch list api error"
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    async function getSubSeriesData(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-series-data-by-name`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_series_name": "goods_received", "transaction_series_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setGoodsReceivedNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
        }
        else {
            swal({
                title: "Oops",
                text: body.message,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Go to transaction series"
                    }
                }
            }).then((val) => {
                if (val) {
                    window.open("/transaction-series")
                }
            })

            console.log("get sub series data api error")
        }
    }

    async function getItems(search_value) {
        try {
            if (itemApiState) {
                itemListApiController.current.abort()
                itemListApiController.current = new AbortController()
            }

            setItemApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-product-by-search`, { signal: itemListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "type": "Purchase", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)
            if (body.operation === "success") {
                setItemList(body.data);
                setItemApiState(false);
            }
        }
        catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getConfirmedPurchaseOrderList(supplier_id) {
        setPurchaseOrderListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-confirmed-purchase-orders-by-supplier`, { method: 'POST', body: JSON.stringify({ "email": user.email, "supplier_id": supplier_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);

        if (body.operation === "success") {
            setPurchaseOrderList(body.data)
            setPurchaseOrderListApiState(false)
        }
    }

    async function getPurchaseOrderDetails(poid) {
        setPurchaseOrderDetailsApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-purchase-order-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "purchase_order_id": poid, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);

        if (body.operation === "success") {
            setPurchaseOrderDetails(body.data[0]);
            setPurchaseOrderDetailsApiState(false)
        }
    }

    async function getReceiveGoodsRecords(poid) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-goods-received-list-by-po`, { method: 'POST', body: JSON.stringify({ "email": user.email, "purchase_order_id": poid, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);

        if (body.operation === "success") {
            setReceiveGoodsRecords(body.data.map(x => { return { ...x, showReceiveGoodsDetails: false } }))
        }
    }

    async function getProductStockData(pid) {
        setSaveButtonState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-stock-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "product_id": pid, "search_val": "", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setSaveButtonState(false)
            return body.data.search_results
        }
    }

    async function getGoodsReceivedDetails(signal) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-goods-received-details`, { signal, method: 'POST', body: JSON.stringify({ "email": user.email, "gr_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if ((body.operation == "success")) {
            if (body.data.add_status === "GOODS_RECEIVED_PENDING_APPROVAL" || body.data.add_status === "GOODS_RECEIVED_DRAFT") {
                if (body.data.associated_bill === null) {
                    setGoodsReceivedDetails(body.data)
                } else {
                    throw (<span><Link to={`/view-goods-receive/${body.data.gr_id}`}>{body.data.gr_number}</Link> has already been used to make <Link to={`/view-bill/${body.data.associated_bill.bill_id}`} className="cursor-pointer">{body.data.associated_bill.bill_number}</Link>. So cannot be edited.</span>)
                }
            } else throw (<span><Link to={`/view-goods-receive/${body.data.gr_id}`}>{body.data.gr_number}</Link> is not allowed to edit</span>)
        }
        else {
            throw body.message
        }
    }

    async function getApprovalDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-specific-approval`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "key": "goods_receive_approval", "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        });
        const body = await response.json();
        if (body.operation === "success") {
            setApprovalInfo(body.data);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            const controller = new AbortController();
            const signal = controller.signal;

            let p1 = getBranchDetails()
            let p2 = getTransactionSeriesList()
            let p3 = getWarehouses()
            let p4 = getItems(null)
            let p5 = getGoodsReceivedDetails(signal)
            let p6 = getApprovalDetails()

            Promise.all([p1, p2, p3, p4, p5, p6])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    if (errx.name !== 'AbortError') {
                        setstate(3)
                        setFailedPageMessage(errx)
                        console.log(errx)
                    }
                })

            return () => {
                controller.abort();
            };
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if ((goodsReceivedDetails != null) && (transactionSeriesList.length > 0)) {
            setUserType({ label: goodsReceivedDetails.user_type, value: goodsReceivedDetails.user_type })
            setSelectedUser({ label: goodsReceivedDetails.doc_user_name, value: goodsReceivedDetails.doc_user_id, email: goodsReceivedDetails.doc_user_email })
            // getConfirmedPurchaseOrderList(goodsReceivedDetails.doc_user_id)
            setSelectedBranch({ label: goodsReceivedDetails.branch_name, value: goodsReceivedDetails.branch_id })
            setSelectedWarehouse({ label: goodsReceivedDetails.warehouse_name, value: goodsReceivedDetails.warehouse_id })
            setSelectedTransactionSeries({ label: transactionSeriesList.find(x => x.transaction_series_id === goodsReceivedDetails.transaction_series_id).series_name, value: goodsReceivedDetails.transaction_series_id })
            setGoodsReceivedNumber(goodsReceivedDetails.gr_number)
            setGoodsReceiveItemArray(goodsReceivedDetails.item_array)
            if (goodsReceivedDetails.purchase_order_id) {
                setSelectedPurchaseOrder({ label: goodsReceivedDetails.purchase_order_number, value: goodsReceivedDetails.purchase_order_id })
                getPurchaseOrderDetails(goodsReceivedDetails.purchase_order_id)
                getReceiveGoodsRecords(goodsReceivedDetails.purchase_order_id)
            }
        }

    }, [goodsReceivedDetails, transactionSeriesList])

    const validateReceivedGoods = () => {
        if (userType == null) {
            swal("Oops!", "Select an User Type", "warning")
            return false
        }
        if (selectedUser == null) {
            swal("Oops!", "Select a Supplier", "warning")
            return false
        }
        if (selectedBranch == null) {
            swal("Oops!", "Select a Branch", "warning")
            return false
        }
        if (selectedTransactionSeries == null) {
            swal("Oops!", "Select a Transaction Series", "warning")
            return false
        }
        if (selectedWarehouse == null) {
            swal("Oops!", "Select a Warehouse", "warning")
            return false
        }

        if (goodsReceiveItemArray.find(x => x.item_id == null) || goodsReceiveItemArray.length == 0) {
            swal("Oops!", "Choose a product before submitting", "warning")
            return false
        }
        if (goodsReceiveItemArray.find(x => x.received_qty <= 0)) {
            swal("Oops!", "Received Quantity has to be greater than 0", "warning")
            return false
        }

        for (let i = 0; i < goodsReceiveItemArray.length; i++) {
            if (goodsReceiveItemArray[i].item_stock_type == "Track Serial Number") {
                if ((goodsReceiveItemArray[i].item_data == null) || (goodsReceiveItemArray[i].item_data.length != goodsReceiveItemArray[i].received_qty)) {
                    swal("Oops!", "Serial Number count mismatch", "warning")
                    return false
                }
            }
            else if (goodsReceiveItemArray[i].item_stock_type == "Track Batches") {
                if ((goodsReceiveItemArray[i].item_data == null) || (goodsReceiveItemArray[i].item_data.reduce((p, o) => p + o.batch_quantity, 0) != goodsReceiveItemArray[i].received_qty)) {
                    swal("Oops!", "Batch quantity total mismatch", "warning")
                    return false
                }

                if (goodsReceiveItemArray[i].item_data.find(x => x.batch_reference == "")) {
                    swal("Oops!", "Batch Reference required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.manufacturer == "")) {
                    swal("Oops!", "Manufacturer required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.manufactured_date == "")) {
                    swal("Oops!", "Manufactured Date required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.expiry_date == "")) {
                    swal("Oops!", "Expiry Date required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => new Date(x.expiry_date) < new Date(x.manufactured_date))) {
                    swal("Oops!", "Expiry Date must be greater than manufactured date", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.batch_quantity <= 0)) {
                    swal("Oops!", "Batch Quantity should be greater than 0", "warning")
                    return false
                }
            }
        }

        return true
    }

    const updateReceiveGoodsEntry = (val) => {
        var object = {}

        object.gr_id = props.match.params.id

        // object.user_type = userType.value
        // object.doc_user_id = selectedUser.value
        // object.doc_user_name = selectedUser.label
        // object.doc_user_email = selectedUser.email

        // object.purchase_order_id = selectedPurchaseOrder == null ? null : selectedPurchaseOrder.value
        // object.purchase_order_number = selectedPurchaseOrder == null ? null : selectedPurchaseOrder.label

        object.item_array = goodsReceiveItemArray

        object.save_flag = val
        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-goods-receive-entry`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Goods Received Entry updated successfully!`, "success").then(() => { history.go(0) });
            }
            else if (res.data.operation === "duplicate") {
                const alreadyPresent = {}

                res.data.data.forEach(x => {
                    let stock_type = x.reason.item_stock_type === "Track Serial Number" ? "serial" : "batch"
                    x.reason.info.forEach(y => {
                        const key = `${y.warehouse_id},${y.branch_id},${stock_type}`;
                        if (!alreadyPresent[key]) {
                            alreadyPresent[key] = [];
                        }
                        alreadyPresent[key].push(x.reason.item_stock_type === "Track Serial Number" ? y.serialName : y.Batch_Reference);
                    })
                })

                swal({
                    title: "Oops!",
                    text: "Following Duplicate Stock Data cannot be added 👇",
                    icon: "warning",
                    content: (
                        <div>
                            {
                                res.data.data.map((x, i) => (
                                    x.reason.item_stock_type === "Track Serial Number" ?
                                        <div key={i} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                            <p className="fw-bold d-flex align-items-center">For Serial Numbers</p>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                obj[0].split(',')[2] === "serial" &&
                                                <div key={index}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")}</p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div key={i} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                            <p className="fw-bold d-flex align-items-center">For Batches</p>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                obj[0].split(',')[2] === "batch" &&
                                                <div key={index}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")}</p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            ))}
                                        </div>
                                ))
                            }
                        </div>
                    )
                })
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleAddSerialModalClose = () => {
        setModalRefProductId(null)

        setAddSerialModalShow(false);
        setSelectedCamera("user");
        setSerialInput("")
        setSerialArray([])
    }

    const handleAddBatchModalClose = () => {
        setModalRefProductId(null)

        setAddBatchModalShow(false)
        setBatchArray([])
    }


    const poDetailsBarConfig = {
        ...barConfig,

        keys: [
            'received',
            'total'
        ],
        indexBy: "item_name",
        margin: { top: 10, right: 0, bottom: 80, left: 50 },
        padding: 0.3,
        groupMode: "grouped",
        colors: ({ id }) => id === "received" ? "#40916c" : "#f7b538",
        theme: {
            text: { fontSize: 17 },
            axis: {
                legend: {
                    text: { fontSize: 20 },
                },
                ticks: {
                    text: { fontSize: 13 },
                },
            }
        },
        defs: [
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ],
        fill: [
            { match: { id: 'total' }, id: 'lines' },
            { match: { id: 'received' }, id: 'dots' }
        ],
        borderColor: {
            from: 'color',
            modifiers: [['darker', 1.6]]
        },
        axisBottom: {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'items',
            legendPosition: 'middle',
            legendOffset: 42,
            truncateTickAt: 0
        },
        axisLeft: {
            tickSize: 0,
            tickPadding: 2,
            tickRotation: 0,
            legend: 'quantity',
            legendPosition: 'middle',
            legendOffset: -40,
            truncateTickAt: 0
        }
    };

    const poDetailsPieConfig = {
        ...pieConfig,

        margin: { top: 10, right: 40, bottom: 40, left: 0 },
        colors: { datum: "data.color" },
        valueFormat: (value) => value + "%",
        enableArcLinkLabels: false,
        // defs: [
        //     {
        //         id: 'dots',
        //         type: 'patternDots',
        //         background: 'inherit',
        //         color: 'rgba(255, 255, 255, 0.3)',
        //         size: 4,
        //         padding: 1,
        //         stagger: true
        //     },
        //     {
        //         id: 'lines',
        //         type: 'patternLines',
        //         background: 'inherit',
        //         color: 'rgba(255, 255, 255, 0.3)',
        //         rotation: -45,
        //         lineWidth: 6,
        //         spacing: 10
        //     }
        // ],
        // fill: [
        //     { match: { id: 'Received' }, id: 'dots' },
        //     { match: { id: 'Remaining' }, id: 'lines' }
        // ],
        legends: [
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 40,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [{ on: 'hover', style: { itemTextColor: '#000' } }]
            }
        ],
        theme: { legends: { text: { fontSize: 15 } }, labels: { text: { fontSize: 15, fontWeight: "bold" } } },
    };

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Edit Goods Recieved Entry
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                <li className="breadcrumb-item text-muted"><span className="text-muted">Home</span></li>
                                <li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
                                <li className="breadcrumb-item text-muted">Purchase Order</li>
                                <li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
                                <li className="breadcrumb-item text-muted">Edit Goods Recieve Entry</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            ((!approvalInfo || approvalInfo.type === "not_configure") && state === 1) &&
                            <>
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                    </svg>
                                    </span>
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <div className="fs-6 text-gray-700 ">Good Recieve Approval Part Not Configured yet. Configure this, From <Link to={"/approvals"}>Approval Page</Link>.</div>
                                        </div>
                                    </div>
                                </div><br />
                            </>
                        }
                        <div className="card">
                            <div className="card-body">
                                {state == 1 ?
                                    <>
                                        <div className="row gx-0 ">
                                            <div className="col-md-6 px-1 mb-4">
                                                <label className="required fs-5 fw-bold mb-2">User Type</label>
                                                <Select
                                                    options={["VENDOR", "MANUFACTURER"].map(x => { return { label: x, value: x } })}
                                                    onChange={(val) => { setUserType(val); setSelectedUser(null); setSelectedPurchaseOrder(null) }}
                                                    value={userType}
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    isDisabled
                                                    placeholder="Choose User Type"
                                                />
                                            </div>
                                            <div className="col-md-6 px-1 mb-4">
                                                <label className="required fs-5 fw-bold mb-2">{userType == null ? "User" : userType.value.toLowerCase().replace(/(\b[a-z](?!\s))/g, letter => letter.toUpperCase())} Name</label>
                                                <Select
                                                    options={
                                                        userType == null ? [] :
                                                            userType.value == "CREDITOR" ? creditorList.map(x => { return { label: x.creditor_display_name, value: x.cid, email: x.email } }) :
                                                                userType.value == "VENDOR" ? vendorList.map(x => { return { label: x.vendor_display_name, value: x.cid, email: x.email } }) :
                                                                    userType.value == "MANUFACTURER" ? manufacturerList.map(x => { return { label: x.manufacturer_display_name, value: x.cid, email: x.email } }) :
                                                                        null
                                                    }
                                                    onChange={(val) => { setSelectedUser(val); setPurchaseOrderList([]); setSelectedPurchaseOrder(null); getConfirmedPurchaseOrderList(val.value) }}
                                                    value={selectedUser}
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    onInputChange={(val) => {
                                                        if (userType.value == "CREDITOR") {
                                                            getCreditorBySearch(val)
                                                        }
                                                        else if (userType.value == "VENDOR") {
                                                            getVendorBySearch(val)
                                                        }
                                                        else if (userType.value == "MANUFACTURER") {
                                                            getManufacturerBySearch(val)
                                                        }
                                                    }}
                                                    isLoading={userListApiState}
                                                    isDisabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row gx-0 ">
                                            <div className="col-md-6 px-1 mb-4">
                                                <label className="required fs-5 fw-bold mb-2">Branch:</label>
                                                <Select
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    options={branchList.map((x) => { return { label: x.branch_name, value: x.branch_id } })}
                                                    onChange={(val) => {
                                                        setSelectedBranch(val);
                                                        setSelectedTransactionSeries(null)
                                                    }}
                                                    value={selectedBranch}
                                                    isDisabled
                                                    placeholder="Select Branch"
                                                />
                                            </div>
                                            <div className="col-md-6 px-1 mb-4">
                                                <label className="required fs-5 fw-bold mb-2">Transaction Series:</label>
                                                <Select
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    options={selectedBranch != null && transactionSeriesList.filter(x => branchList.find(a => a.branch_id == selectedBranch.value).transaction_series_id.split(",").includes(x.transaction_series_id)).map(x => { return { label: x.series_name, value: x.transaction_series_id } })}
                                                    onChange={(val) => { setSelectedTransactionSeries(val); getSubSeriesData(val.value) }}
                                                    value={selectedTransactionSeries}
                                                    isDisabled
                                                    placeholder="Select Transaction Series"
                                                />
                                            </div>
                                        </div>

                                        <div className="row gx-0">
                                            <div className="col-md-6 px-1 mb-4">
                                                <label className="fs-5 fw-bold mb-2 required">Warehouse</label>
                                                <Select
                                                    options={warehouseList.map(x => { return { label: x.name, value: x.wid } })}
                                                    onChange={(val) => { setSelectedWarehouse(val); }}
                                                    value={selectedWarehouse}
                                                    placeholder="Select Warehouse..."
                                                    isDisabled
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                />
                                            </div>
                                            <div className="col-md-6 px-1 mb-4">
                                                <label className="fs-5 fw-bold mb-2">Goods Received Number</label>
                                                <input type="text" className="form-control form-control-solid" value={goodsReceivedNumber} readOnly disabled />
                                            </div>
                                        </div>

                                        <div className="row gx-0">
                                            <div className="col-md-6 px-1 mb-4">
                                                <label className="fs-5 fw-bold mb-2">Purchase Order</label>
                                                <Select
                                                    options={purchaseOrderList.map(x => { return { label: x.purchase_order_number, value: x.purchase_order_id } })}
                                                    onChange={(val) => {
                                                        setSelectedPurchaseOrder(val);
                                                        setGoodsReceiveItemArray([]);
                                                        setPurchaseOrderDetails(null);
                                                        setReceiveGoodsRecords([]);
                                                        if (val) {
                                                            getPurchaseOrderDetails(val.value);
                                                            getReceiveGoodsRecords(val.value);
                                                        }
                                                    }}
                                                    value={selectedPurchaseOrder}
                                                    isLoading={purchaseOrderListApiState}
                                                    isDisabled
                                                    isClearable
                                                    placeholder="Select Purchase Order..."
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                />
                                            </div>
                                        </div>

                                        {
                                            purchaseOrderDetails !== null &&
                                            <>
                                                <div className='row gx-0'>
                                                    <div className='col-md-4'>
                                                        <div style={{ height: "300px" }}>
                                                            <ResponsivePie
                                                                data={
                                                                    [
                                                                        { id: "Received", label: "Received", color: "#40916c", value: Math.round(100 / purchaseOrderDetails.item_array.reduce((p, o) => p + o.quantity, 0) * (receiveGoodsRecords.reduce((p, o) => p + o.item_array.reduce((p2, o2) => p2 + o2.received_qty, 0), 0)) * 100) / 100 },
                                                                        { id: "Remaining", label: "Remaining", color: "#bf4342", value: Math.round(100 / purchaseOrderDetails.item_array.reduce((p, o) => p + o.quantity, 0) * (purchaseOrderDetails.item_array.reduce((p, o) => p + o.quantity, 0) - receiveGoodsRecords.reduce((p, o) => p + o.item_array.reduce((p2, o2) => p2 + o2.received_qty, 0), 0)) * 100) / 100 },
                                                                    ]
                                                                }

                                                                {...poDetailsPieConfig}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-8 overflow-auto'>
                                                        <div style={{ height: "300px", width: "1000px" }}>
                                                            <ResponsiveBar
                                                                data={purchaseOrderDetails.item_array.map((obj) => {
                                                                    let t = {}
                                                                    t.item_name = obj.item_name
                                                                    t.total = obj.quantity
                                                                    t.received = receiveGoodsRecords.reduce((p, o) => {
                                                                        let t2 = o.item_array.find(x => x.item_id == obj.item_id)
                                                                        return p + (t2 === undefined ? 0 : t2.received_qty)
                                                                    }, 0)

                                                                    return t
                                                                })}

                                                                {...poDetailsBarConfig}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row gx-0 my-10'>
                                                    {
                                                        receiveGoodsRecords.length === 0 ? <div className='fs-1 p-10 text-center text-gray-600'>The goods for this Purchase Order are yet to be delivered.</div> :
                                                            receiveGoodsRecords.map((obj, i) => {
                                                                return (
                                                                    <div className="col-md-6 mb-6 px-1" key={i}>
                                                                        <div className="card bg-light-secondary">
                                                                            <div className="card-body">
                                                                                <div className="d-flex align-items-center justify-content-between mb-8">
                                                                                    <div>
                                                                                        <span className="fs-2 fw-bold me-5">{obj.gr_number}</span>
                                                                                        <span className="fs-2 me-2">STATUS:</span>
                                                                                        <span className='fs-2' style={{ color: (obj.add_status === "GOODS_RECEIVED_FINALIZED") ? "green" : ((obj.add_status === "GOODS_RECEIVED_PENDING_APPROVAL") || (obj.add_status === "GOODS_RECEIVED_DRAFT")) ? "orange" : (obj.add_status === "GOODS_RECEIVED_CANCELLED") ? "red" : "red" }} >
                                                                                            {obj.add_status.replace("GOODS_RECEIVED", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                                                                        </span>
                                                                                    </div>

                                                                                    <button className="btn btn-sm btn-primary px-2 py-1"
                                                                                        onClick={() => {
                                                                                            let t = receiveGoodsRecords.map((x) => { return { ...x } })
                                                                                            t[i].showReceiveGoodsDetails = !t[i].showReceiveGoodsDetails
                                                                                            setReceiveGoodsRecords(t)
                                                                                        }}
                                                                                    >
                                                                                        {obj.showReceiveGoodsDetails ? <svg height="28" viewBox="0 -960 960 960" width="28"><path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" /></svg> : <svg height="28" viewBox="0 -960 960 960" width="28"><path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" /></svg>}
                                                                                    </button>
                                                                                </div>
                                                                                {
                                                                                    obj.showReceiveGoodsDetails &&
                                                                                    <>
                                                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                                                            <span className="fw-bold fs-5 text-primary">User</span>
                                                                                            <span className="fs-5 fw-semibold">{obj.user_type} - {obj.doc_user_name}</span>
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                                                            <span className="fw-bold fs-5 text-primary">Branch</span>
                                                                                            <span className="fs-5 fw-semibold">{obj.branch_name}</span>
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                                                            <span className="fw-bold fs-5 text-primary">Warehouse</span>
                                                                                            <span className="fs-5 fw-semibold">{obj.warehouse_name}</span>
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                                                            <span className="fw-bold fs-5 text-primary">Created On</span>
                                                                                            <span className="fs-5 fw-semibold">{moment(obj.add_date_time).format("lll")}</span>
                                                                                        </div>
                                                                                        <div className="mb-3">
                                                                                            <div className="fw-bold fs-5 text-primary mb-2">Received Items</div>
                                                                                            <div>
                                                                                                {
                                                                                                    obj.item_array.map((x, i) => {
                                                                                                        return (
                                                                                                            <div key={i} className="fs-5 fw-semibold d-flex justify-content-between">
                                                                                                                <span>{x.item_name}</span>
                                                                                                                <span>{x.received_qty} units</span>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                    }
                                                </div>
                                            </>
                                        }

                                        <div className='mt-5 mb-20'>
                                            <label className="fs-5 fw-bold mb-2">Goods Received Items</label>
                                            {
                                                purchaseOrderDetailsApiState ? <div className='text-center'><div className="spinner-border text-primary" style={{ width: "5rem", height: "5rem" }}></div></div> :
                                                    <div className='' style={{ overflowX: "auto" }}>
                                                        <div className='container px-0' style={{ width: "80rem" }}>

                                                            <div className="row mb-5 gx-0">
                                                                <div className="col-5"><p className="fs-5 text-center">Item</p></div>
                                                                <div className="col-2"><p className="fs-5 text-center">Received Qty</p></div>
                                                                <div className="col-4"><p className="fs-5 text-center">Additional data</p></div>
                                                                <div className="col-1"><p className="fs-5 text-center"></p></div>
                                                            </div>
                                                            {
                                                                goodsReceiveItemArray.length == 0 ? <div className='fs-2 text-center text-grey-600'>No Items Added</div> :
                                                                    goodsReceiveItemArray.map((obj, indx) => {
                                                                        return (
                                                                            <div key={indx} className="row py-2 gx-0 align-items-baseline">
                                                                                <div className="col-5 px-0">
                                                                                    {
                                                                                        selectedPurchaseOrder ?
                                                                                            <Select
                                                                                                options={purchaseOrderDetails.item_array.map(x => { return { label: x.item_name, value: x.item_id } })}
                                                                                                onChange={(val) => {
                                                                                                    if (goodsReceiveItemArray.find(x => x.item_id === val.value)) {
                                                                                                        swal("Oops!", "Duplicate items cannot be added", "warning")
                                                                                                        return
                                                                                                    }

                                                                                                    let m = (purchaseOrderDetails.item_array.find(item => item.item_id === val.value).quantity) - (receiveGoodsRecords.reduce((acc, record) => {
                                                                                                        let temp = record.item_array.find(item => item.item_id === val.value)
                                                                                                        if (temp !== undefined) {
                                                                                                            acc = acc + temp.received_qty
                                                                                                        }
                                                                                                        return acc
                                                                                                    }, 0))

                                                                                                    if (m === 0) {
                                                                                                        swal("Alert!", "All quantities for this product is settled in other associated GRs ", "warning")
                                                                                                        return
                                                                                                    }

                                                                                                    let pd = purchaseOrderDetails.item_array.find(x => x.item_id === val.value);

                                                                                                    let temp = goodsReceiveItemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].item_id = val.value
                                                                                                    temp[indx].item_name = val.label
                                                                                                    temp[indx].item_stock_type = pd.item_stock_type
                                                                                                    temp[indx].received_qty = 0
                                                                                                    temp[indx].item_data = null
                                                                                                    setGoodsReceiveItemArray(temp)
                                                                                                }}
                                                                                                value={obj.item_id == null ? null : { label: obj.item_name, value: obj.item_id }}
                                                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                                placeholder="Select Product..."
                                                                                                menuPosition={'fixed'}
                                                                                                menuShouldBlockScroll={true}
                                                                                            />
                                                                                            :
                                                                                            <Select
                                                                                                options={itemList.map(x => { return { label: x.product_name, value: x.pid } })}
                                                                                                onChange={(val) => {
                                                                                                    if (goodsReceiveItemArray.find(x => x.item_id === val.value)) {
                                                                                                        swal("Oops!", "Duplicate items cannot be added", "warning")
                                                                                                        return
                                                                                                    }

                                                                                                    let pd = itemList.find(x => x.pid == val.value)

                                                                                                    let temp = goodsReceiveItemArray.map(a => { return { ...a } });
                                                                                                    temp[indx].item_id = val.value
                                                                                                    temp[indx].item_name = val.label
                                                                                                    temp[indx].item_stock_type = pd.tracking_inventory_type
                                                                                                    temp[indx].received_qty = 0
                                                                                                    temp[indx].item_data = null
                                                                                                    setGoodsReceiveItemArray(temp)
                                                                                                }}
                                                                                                value={obj.item_id == null ? null : { label: obj.item_name, value: obj.item_id }}
                                                                                                onInputChange={(val) => { (val.length > 2) && getItems(val); }}
                                                                                                isLoading={itemApiState}
                                                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                                placeholder="Select Product..."
                                                                                                menuPosition={'fixed'}
                                                                                                menuShouldBlockScroll={true}
                                                                                            />
                                                                                    }
                                                                                </div>
                                                                                <div className="col-2 px-1 text-center">
                                                                                    <input type="number" className="form-control form-control-solid px-2" disabled={obj.item_id === null} value={obj.received_qty.toString()} onChange={(e) => {
                                                                                        if (e.target.value < 0) {
                                                                                            swal("Alert!", "Negative unit not allowed", "warning")
                                                                                            return
                                                                                        }

                                                                                        if (selectedPurchaseOrder) {
                                                                                            let m = (purchaseOrderDetails.item_array.find(item => item.item_id === obj.item_id).quantity) - (receiveGoodsRecords.reduce((acc, record) => {
                                                                                                let temp = record.item_array.find(item => item.item_id === obj.item_id)
                                                                                                if (temp !== undefined) {
                                                                                                    acc = acc + temp.received_qty
                                                                                                }
                                                                                                return acc
                                                                                            }, 0))

                                                                                            if (parseInt(e.target.value) > m) {
                                                                                                swal("Alert!", `Maximum quantity allowed for ${obj.item_name} - ${m} units`, "warning")
                                                                                                return
                                                                                            }
                                                                                        }

                                                                                        let temp = goodsReceiveItemArray.map(a => { return { ...a } })
                                                                                        temp[indx].received_qty = e.target.value == "" ? 0 : parseInt(e.target.value)
                                                                                        setGoodsReceiveItemArray(temp)
                                                                                    }} />
                                                                                </div>
                                                                                <div className="col-4 px-1 text-center">
                                                                                    {
                                                                                        obj.item_stock_type == "Track Serial Number" ?
                                                                                            <>
                                                                                                {
                                                                                                    obj.item_data == null ?
                                                                                                        <button className='btn btn-primary' onClick={() => { setAddSerialModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setSerialArray(obj.item_data); }} >Add New Serial Numbers</button> :
                                                                                                        <>
                                                                                                            <span className='fs-4 mx-4'>{obj.item_data.length} Serials Added</span>
                                                                                                            <button className='btn btn-light-success px-4' onClick={() => { setAddSerialModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setSerialArray(obj.item_data); }} >
                                                                                                                <i className="fa-solid fa-pencil fs-2 p-0"></i>
                                                                                                            </button>
                                                                                                        </>
                                                                                                }
                                                                                            </>
                                                                                            : obj.item_stock_type == "Track Batches" ?
                                                                                                <>
                                                                                                    {
                                                                                                        obj.item_data == null ?
                                                                                                            <button className='btn btn-primary' onClick={() => { setAddBatchModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setBatchArray(obj.item_data); }} >Add New Batches</button> :
                                                                                                            <>
                                                                                                                <span className='fs-4 mx-4'>{obj.item_data.length} Batches with {obj.item_data.reduce((p, o) => p + o.batch_quantity, 0)} Quantity Added</span>
                                                                                                                <button className='btn btn-light-success px-4' onClick={() => { setAddBatchModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setBatchArray(obj.item_data); }} >
                                                                                                                    <i className="fa-solid fa-pencil fs-2 p-0"></i>
                                                                                                                </button>
                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                                : <div className='text-gray-600 fs-4 text-center'>No data required for this item</div>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-1 px-1 text-center">
                                                                                    <button className='btn btn-link btn-sm text-hover-danger' style={{ flex: "1" }} onClick={() => {
                                                                                        let temp = goodsReceiveItemArray.map(a => { return { ...a } })
                                                                                        temp.splice(indx, 1)
                                                                                        setGoodsReceiveItemArray(temp)
                                                                                    }}>✖</button>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                            }
                                                            <button className="btn btn-sm btn-primary" onClick={() => { setGoodsReceiveItemArray([...goodsReceiveItemArray, { item_id: null, item_name: null, item_stock_type: null, received_qty: 0, item_data: null }]) }}> Add +</button>
                                                        </div>
                                                    </div>
                                            }
                                        </div>

                                        {
                                            write  &&
                                            <button type="button" className="btn btn-lg btn-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateReceivedGoods() && updateReceiveGoodsEntry(false) }}>
                                                <span className="indicator-label">Save as Draft</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        }

                                        {
                                            write  &&
                                            <button type="button" className="btn btn-lg btn-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateReceivedGoods() && updateReceiveGoodsEntry(true) }}>
                                                <span className="indicator-label">Save</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        }

                                    </>
                                    : state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <PageError message={failedPageMessage}>
                                            <Link to={`/goods-received-list`} className="btn btn-sm btn-primary me-3 rounded cursor-pointer">Go to Goods Received List</Link>
                                        </PageError>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={addSerialModalShow} onHide={() => { handleAddSerialModalClose() }} centered>
                <Modal.Header>
                    <Modal.Title>Add Serial Numbers</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddSerialModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>
                        <div className='d-flex align-items-center gap-3'>
                            <BarcodeScannerComponent
                                width={350}
                                height={220}
                                onUpdate={(err, result) => {
                                    if (!result)
                                        return

                                    let tag = result.text;
                                    console.log("tag", tag, "serialArray", serialArray)

                                    if (!serialArray.includes(tag)) {
                                        setSerialArray([...serialArray, tag]);
                                    }
                                }}
                                videoConstraints={{
                                    width: 1280,
                                    height: 720,
                                    facingMode: selectedCamera == "user" ? selectedCamera : { exact: selectedCamera }
                                }}
                            />
                            <button className="btn btn-secondary rounded-pill" onClick={() => { selectedCamera == "user" ? setSelectedCamera("environment") : setSelectedCamera("user") }}>
                                <svg version="1.1" width="32" height="32" viewBox="0 0 1000 1000">
                                    <g transform="translate(0.000000,380.000000) scale(0.100000,-0.100000)">
                                        <path d="M3257.7,2389.6c-99.6-51.7-227.8-197.2-390.6-444.2c-225.9-340.8-55.5-300.6-1294.3-306.3l-1062.6-5.7l-103.4-57.4c-59.4-32.5-135.9-97.6-178.1-149.3c-139.8-178.1-132.1,19.1-126.4-3049.9l5.7-2755.1l40.2-88.1c88.1-189.6,247-308.3,461.4-342.7c149.3-24.9,8630.9-24.9,8780.3,0c214.4,34.5,373.3,153.2,461.4,342.7l40.2,88.1l5.7,2755.1c5.7,3069.1,13.4,2871.9-126.3,3049.9c-42.1,51.7-118.7,116.8-178,149.3l-103.4,57.4l-1062.6,5.7c-1238.7,5.8-1068.3-34.5-1294.3,306.3c-166.6,250.8-291,394.4-394.4,446.1c-68.9,34.5-135.9,36.4-1742.3,36.4C3403.2,2426,3320.9,2424.1,3257.7,2389.6z M6621.7,1983.7c30.6-34.5,128.3-168.5,214.4-296.8c162.7-243.1,268-354.2,377.2-400.1c51.7-21.1,254.6-26.8,1102.8-26.8c999.4,0,1041.5-1.9,1100.9-38.3c34.5-21.1,68.9-61.3,76.6-90c9.6-28.7,15.3-1259.8,15.3-2734c0-2552.1-1.9-2684.3-34.5-2734c-19.1-30.6-55.5-61.3-82.3-70.8c-65.1-24.9-8719-24.9-8784.1,0c-26.8,9.6-63.2,40.2-82.3,70.8c-32.5,49.8-34.5,181.9-34.5,2734c0,1474.2,5.7,2705.3,15.3,2734c7.7,28.7,42.1,68.9,76.6,90c59.4,36.4,101.5,38.3,1100.9,38.3c848.2,0,1051.1,5.7,1102.8,26.8c109.1,45.9,214.4,157,377.2,400.1c86.2,128.3,183.8,262.3,214.4,296.8l59.4,61.3H5000h1562.3L6621.7,1983.7z"></path>
                                        <path d="M4779.8,461.6c-254.6-30.6-538-126.4-758.2-256.6C3870.4,117,3832.1,71,3832.1-24.7c0-91.9,59.4-164.7,145.5-181.9c55.5-9.6,99.5,3.8,281.4,93.8c292.9,145.5,381,168.5,673.9,180c210.6,7.7,275.7,3.8,421.2-30.6c492.1-118.7,896-459.5,1089.4-917.1c47.9-113,88.1-262.3,116.8-434.6l11.5-76.6l-149.3,145.5c-178.1,174.2-227.8,208.7-296.8,208.7c-124.4,0-225.9-113-199.1-222.1c9.6-36.4,151.3-193.4,428.9-472.9L6771-2148l388.7,386.7c214.4,214.4,402.1,411.6,417.4,440.4c42.1,82.3,32.5,157-28.7,224c-45.9,47.9-70.8,59.4-135.9,59.4c-78.5,0-91.9-9.6-268-181.9l-185.7-183.8l-11.5,124.5c-46,482.5-325.5,997.5-716.1,1313.4C5830.9,358.2,5270,522.9,4779.8,461.6z"></path><path d="M2811.6-1052.8c-365.7-367.6-415.5-425-415.5-478.6c0-88.1,36.4-151.3,105.3-185.7c34.5-15.3,72.8-28.7,86.2-28.7c53.6,0,155.1,72.8,298.7,214.4l155.1,153.2l11.5-227.8c17.2-323.6,90-570.5,250.8-853.9c275.7-482.5,752.4-830.9,1296.2-943.9c218.3-47.9,585.9-47.9,800.3-1.9c225.9,47.9,465.2,149.3,664.3,279.5c229.8,151.2,298.7,225.9,302.5,327.4c1.9,68.9-7.7,88.1-63.2,137.9c-91.9,82.3-174.2,68.9-342.7-61.3c-622.2-480.6-1510.6-425-2067.8,130.2c-281.4,279.6-461.4,708.4-463.3,1093.3v124.4l178.1-174.2c101.5-99.6,201-181.9,231.7-189.5c122.5-30.6,264.2,111,233.6,233.6c-9.6,36.4-153.2,195.3-419.3,463.3c-222.1,222.1-409.7,405.9-415.5,405.9C3232.8-635.4,3041.4-823.1,2811.6-1052.8z"></path>
                                    </g>
                                </svg>
                            </button>
                        </div>

                        <div className='d-flex gap-2'>
                            <input type="text" className="form-control form-control-solid px-2 flex-grow-1" value={serialInput} onChange={(e) => { setSerialInput(e.target.value.trim()) }}
                                onKeyDown={(e) => {
                                    if ((e.key === "Enter") && (serialInput != "")) {
                                        if (serialArray.includes(e.target.value)) {
                                            swal("Oops!", "Duplicate Serial cannot be selected", "warning")
                                            return
                                        }

                                        setSerialArray([...serialArray, e.target.value.trim()])
                                        setSerialInput("")
                                    }
                                }}
                            />
                            <button className='btn btn-primary'
                                onClick={(e) => {
                                    if (serialArray.includes(serialInput)) {
                                        swal("Oops!", "Duplicate Serial cannot be selected", "warning")
                                        return
                                    }

                                    setSerialArray([...serialArray, serialInput])
                                    setSerialInput("")
                                }}
                            >+&nbsp;Add</button>
                        </div>

                        <label className='fs-5 fw-bold pt-5 pb-1'>Added Serials</label>
                        {
                            serialArray.length === 0 ? <div>No serials added</div> :
                                <div className="d-flex align-items-center gap-2 gap-lg-3 flex-wrap">
                                    {
                                        serialArray.map((obj, index) => {
                                            return (
                                                <div key={index} className="d-flex gap-1 align-items-center bg-secondary px-3 rounded">
                                                    <span className="fs-3 fw-bold">{obj}</span>
                                                    <button className="btn px-2 text-hover-danger"
                                                        onClick={(e) => {
                                                            let t = [...serialArray]
                                                            t.splice(index, 1)
                                                            setSerialArray(t)
                                                        }}
                                                    ><i className="fa fa-trash fs-2 p-0" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddSerialModalClose() }}>Cancel</button>
                    <button className="btn btn-success rounded" data-kt-indicator={saveButtonState ? "on" : "off"} disabled={saveButtonState}
                        onClick={async () => {
                            if (serialArray.length !== goodsReceiveItemArray.find(x => x.item_id === modalRefProductId).received_qty) {
                                swal("Oops!", "Serial Number count mismatch", "warning")
                                return false
                            }

                            const allSerials = await getProductStockData(modalRefProductId)

                            const availableSerials = allSerials.filter(x => x.available_flag)

                            const alreadyPresent = {}

                            serialArray.forEach(x => {
                                const match = availableSerials.find(y => y.serialName === x)
                                if (match) {
                                    const key = `${match.warehouse_id},${match.branch_id}`;
                                    if (!alreadyPresent[key]) {
                                        alreadyPresent[key] = [];
                                    }
                                    alreadyPresent[key].push(x);
                                }
                            })
                            if (Object.entries(alreadyPresent).length > 0) {
                                swal({
                                    title: "Oops!",
                                    text: "Following Serial Numbers cannot be added 👇",
                                    icon: "warning",
                                    content: (
                                        <div>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                <div key={index} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")}</p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            )
                                            )}
                                        </div>
                                    )
                                })
                            } else {
                                const allHistorySerials = allSerials.filter(x => !x.available_flag && !x.committed_flag)

                                const previouslyAddedSerials = []

                                serialArray.forEach(x => {
                                    if (allHistorySerials.find(y => y.serialName === x)) {
                                        previouslyAddedSerials.push(x);
                                    }
                                })

                                if (previouslyAddedSerials.length > 0) {
                                    let willProceed = await swal({
                                        title: "Are you sure?",
                                        text: `${previouslyAddedSerials.join(", ")} were previously added. Would you still want to proceed with them?`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                    })
                                    if (!willProceed) return
                                }

                                let t = goodsReceiveItemArray.map(x => { return { ...x } })
                                let t2 = t.find(x => x.item_id === modalRefProductId)
                                t2.item_data = serialArray.length === 0 ? null : serialArray
                                setGoodsReceiveItemArray(t)

                                handleAddSerialModalClose()
                            }
                        }}
                    >
                        <span className="indicator-label">Save</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </Modal.Footer>
            </Modal >

            <Modal show={addBatchModalShow} onHide={() => { handleAddBatchModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add Batches</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddBatchModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>
                        {
                            batchArray.length === 0 ? <div className='fs-3 text-gray-600 text-center'>No batches added</div> :
                                batchArray.map((obj, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className="row gx-0">
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Batch Reference</label>
                                                    <input type="text" className="form-control mb-2" placeholder="Enter Batch Reference" value={obj.batch_reference}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].batch_reference = e.target.value.trim()
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Manufacturer</label>
                                                    <input type="text" className="form-control mb-2" placeholder="Enter Manufacturer" value={obj.manufacturer}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].manufacturer = e.target.value.trim()
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Manufactured Date</label>
                                                    <input type="date" className="form-control" value={obj.manufactured_date}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].manufactured_date = e.target.value
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row gx-0 mb-3">
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Expiry Date</label>
                                                    <input type="date" className="form-control" value={obj.expiry_date}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].expiry_date = e.target.value
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Batch Quantity</label>
                                                    <input type="number" min="0" className="form-control mb-2" value={obj.batch_quantity.toString()}
                                                        onChange={(e) => {
                                                            if (parseInt(e.target.value) < 0) {
                                                                swal("Oops!", "Quantity must be greater than 0", "warning")
                                                                return
                                                            }

                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].batch_quantity = e.target.value === "" ? 0 : parseInt(e.target.value)
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <button className='btn btn-danger rounded-pill mt-8' onClick={() => {
                                                        let t = batchArray.map(a => { return { ...a } })
                                                        t.splice(i, 1)
                                                        setBatchArray(t)
                                                    }}>✖</button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                        }
                        <button className='btn btn-primary' onClick={() => { setBatchArray([...batchArray, { batch_reference: "", manufacturer: "", manufactured_date: "", expiry_date: "", batch_quantity: 0 }]) }}>+ Add</button>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddBatchModalClose() }}>Cancel</button>
                    <button className="btn btn-success rounded" data-kt-indicator={saveButtonState ? "on" : "off"} disabled={saveButtonState}
                        onClick={async () => {
                            for (let i = 0; i < batchArray.length; i++) {
                                if (batchArray[i].batch_reference == "") {
                                    swal("Oops!", `Batch Reference required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].manufacturer == "") {
                                    swal("Oops!", `Manufacturer required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].manufactured_date == "") {
                                    swal("Oops!", `Manufactured Date required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].expiry_date == "") {
                                    swal("Oops!", `Expiry Date required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (new Date(batchArray[i].expiry_date) < new Date(batchArray[i].manufactured_date)) {
                                    swal("Oops!", `Expiry Date must be greater than manufactured date for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].batch_quantity <= 0) {
                                    swal("Oops!", `Batch Quantity should be greater than 0 for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                            }

                            if (batchArray.reduce((p, o) => p + o.batch_quantity, 0) !== goodsReceiveItemArray.find(x => x.item_id === modalRefProductId).received_qty) {
                                swal("Oops!", "Batch quantity total mismatch", "warning")
                                return false
                            }

                            let batches = await getProductStockData(modalRefProductId)

                            const alreadyPresent = {}

                            batchArray.forEach(x => {
                                const match = batches.find(y => y.Batch_Reference === x.batch_reference)
                                if (match) {
                                    const key = `${match.warehouse_id},${match.branch_id}`;
                                    if (!alreadyPresent[key]) {
                                        alreadyPresent[key] = [];
                                    }
                                    alreadyPresent[key].push(x.batch_reference);
                                }
                            })
                            if (Object.entries(alreadyPresent).length > 0) {
                                swal({
                                    title: "Oops!",
                                    text: "Following Batches cannot be added 👇",
                                    icon: "warning",
                                    content: (
                                        <div>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                <div key={index} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")} </p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            )
                                            )}
                                        </div>
                                    )
                                })
                            } else {
                                let t = goodsReceiveItemArray.map(x => { return { ...x } })
                                let t2 = t.find(x => x.item_id === modalRefProductId)
                                t2.item_data = batchArray.length === 0 ? null : batchArray
                                setGoodsReceiveItemArray(t)

                                handleAddBatchModalClose()
                            }
                        }}
                    >
                        <span className="indicator-label">Save</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </Modal.Footer>
            </Modal>
        </>);

};

export default EditGoodsReceive;
