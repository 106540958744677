import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { Pagination } from 'antd';

import formatNumber from '../common_component/formatNumber.js';
import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import { ReportWidgetWrapper, ReportWidgetItem } from '../common_component/ReportWidgets.js';

function InventorySummary() {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()
    const [baseCurrency, setBaseCurrency] = useState(null)

    const [inventorySummaryReportList, setInventorySummaryReport] = useState([])

    const [branchList, setBranchlist] = useState([])
    const [selectedBranch, setSelectedBranch] = useState({ label: "All Branch", value: "All Branch" });
    const [warehouseList, setWarehouseList] = useState([])
    const [selectedWarehouse, setSelectedWarehouse] = useState({ label: "All Warehouse", value: "All Warehouse" });

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)

    const [inventorySummaryReportApiState, setInventorySummaryReportApiState] = useState(false)

    const apiController = useRef(new AbortController())

    var page_name = "inventory-summary";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setBranchlist(body.data);
            // let t = body.data.find(x => x.default_branch == "yes")
            // setSelectedBranch({ label: t.branch_name, value: t.branch_id })
        }
        else {
            throw "branch list api error"
        }
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getInventorySummaryReport(cp, rpp, search_v, sb, sw) {
        try {
            if (inventorySummaryReportApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setInventorySummaryReportApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-inventory-summary-report`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "branch": sb, "warehouse": sw, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation !== "success") throw "get inventory summary Report api error"

            setInventorySummaryReport(body.data.list)
            setListCount(body.data.count)

            setInventorySummaryReportApiState(false)
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBaseCurrency() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        // console.log(body)
        if (body.length > 0) {
            setBaseCurrency(body[0])
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getInventorySummaryReport(currentPage, rowsPerPage, searchValue, selectedBranch.value, selectedWarehouse.value)
            let p2 = getBranchDetails()
            let p3 = getWarehouses()
            let p4 = getBaseCurrency()

            Promise.all([p1, p2, p3, p4])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getInventorySummaryReport(currentPage, rowsPerPage, searchValue, selectedBranch.value, selectedWarehouse.value)
        }
    }, [currentPage, rowsPerPage, searchValue, selectedBranch.value, selectedWarehouse.value])

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Inventory Summary Report</h1>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <Select
                                            options={[{ label: "All Branch", value: "All Branch" }, ...branchList.map((x) => { return { label: x.branch_name, value: x.branch_id } })]}
                                            onChange={(val) => { setSelectedBranch(val); }}
                                            value={selectedBranch}
                                            placeholder="Select..."
                                            styles={theme === 'dark' ? dropdownStyleDark : {}}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Select
                                            options={[{ label: "All Warehouse", value: "All Warehouse" }, ...warehouseList.map(x => { return { label: x.name, value: x.wid } })]}
                                            onChange={(val) => { setSelectedWarehouse(val); }}
                                            value={selectedWarehouse}
                                            placeholder="Select..."
                                            styles={theme === 'dark' ? dropdownStyleDark : {}}
                                        />
                                    </div>
                                </div>

                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-header border-0">
                                        <ReportWidgetWrapper>
                                            <ReportWidgetItem
                                                icon={<i className="bi bi-clipboard-data-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                                label={"Total Available Stock"}
                                                value={formatNumber(inventorySummaryReportList.reduce((p, o) => p + o.totalCountInStock, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            />
                                            <ReportWidgetItem
                                                icon={<i className="bi bi-shop-window display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                                label={"Total Committed Stock"}
                                                value={formatNumber(inventorySummaryReportList.reduce((p, o) => p + o.totalCommittedStock, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            />
                                            <ReportWidgetItem
                                                icon={<i className="bi bi-clipboard-data-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                                label={"Total Remaining Stock"}
                                                value={formatNumber(inventorySummaryReportList.reduce((p, o) => p + o.totalStock, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            />
                                        </ReportWidgetWrapper>
                                    </div>

                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                inventorySummaryReportApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    inventorySummaryReportList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-120px">Item Name</th>
                                                                            <th className="min-w-120px">Item Stock Type</th>
                                                                            <th className="min-w-120px">Available Stock</th>
                                                                            <th className="min-w-120px">Committed Stock</th>
                                                                            <th className="min-w-120px">Remaining Stock</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            inventorySummaryReportList.map((obj, indx3) => {
                                                                                return (
                                                                                    <tr key={indx3}>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.product_name}</span></td>
                                                                                        <td><span className="badge badge-light-info">{obj.item_stock_type === "Track Serial Number" ? "Serial" : obj.item_stock_type === "Track Batches" ? "Batch" : obj.item_stock_type === "None" ? "Quantity" : "--"}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.totalCountInStock}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.totalCommittedStock}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.totalStock}</span></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>
        </>
    );
}

export default InventorySummary;