import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';
import { Modal } from "react-bootstrap";
import { Pagination } from 'antd';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'

import { useTheme } from "./theme-provider.js"
import { useAuthData } from "./auth-provider.js"
import { setCookie, getCookie } from '../common_component/cookie.js';
import TableColumnHead from '../common_component/TableColumnHead.js';
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from '../common_component/PageError.js';

const CustomerWarehouseProcessingList = () => {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()
    const [timezone_change, set_timezone_change] = useState("+00:00");

    const apiController = useRef(new AbortController())

    const [warehouseProcessingList, setWarehouseProcessingList] = useState([])
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [warehouseProcessingListApiState, setWarehouseProcessingListApiState] = useState(false)

    const [branchesList, setBranchesList] = useState([])
    const [warehouseList, setWarehouseList] = useState([])

    const [data, setData] = useState([]);

    const [itemsModalShow, setItemsModalShow] = useState(false)
    const [itemsData, setItemsData] = useState([])

    const daterange_picker_ref = useRef()


    var page_name = "customer-warehouse-processing-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getWarehouseProcessingList(cp, rpp, sort_c, sort_v, search_v, sd, ed) {
        try {
            if (warehouseProcessingListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setWarehouseProcessingListApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-customer-warehouse-processing-list`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            if (body.operation == "success") {
                setWarehouseProcessingList(body.data.list);
                setListCount(body.data.count)

                setWarehouseProcessingListApiState(false)
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBranches() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)
        if (body.operation == "success") {
            setBranchesList(body.data)
        }
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    const timezone = body.data[0].timezone_value;
                    set_timezone_change(timezone);
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getWarehouseProcessingList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate);
            let p2 = getBranches();
            let p3 = getWarehouses();
            let p4 = getTimezoneInformation();

            Promise.all([p1, p2, p3, p4])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    console.log(errx)
                    setstate(3)
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getWarehouseProcessingList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate)
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate])

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Customer Warehouse Processing List</h1>
                </div>
                <div>

                    {
                        state === 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <DateRangePickerComponent
                                            ref={daterange_picker_ref}
                                            allowEdit={false}
                                            showClearButton={false}
                                            focus={() => { daterange_picker_ref.current.show() }}
                                            delayUpdate={true}
                                            presets={[
                                                { label: 'Today', start: new Date(), end: new Date() },
                                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                            ]}
                                            change={(e) => {
                                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                            }}
                                            startDate={new Date(startDate)}
                                            endDate={new Date(endDate)}
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                </div>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                warehouseProcessingListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    warehouseProcessingList.length > 0 && warehouseList.length > 0 && branchesList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>
                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'customer_invoice_number'} label={'Invoice Number'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px">Branch-Warehouse</th>
                                                                            <th className="min-w-150px">Invoice Ref</th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'customer_name'} label={'Customer Name'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px">Items</th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'add_date_time'} label={'Added Date'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px">Status</th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            warehouseProcessingList.map((obj, indx) => {

                                                                                let id = obj.warehouse_processing_id;
                                                                                return (
                                                                                    <tr key={id}>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.customer_invoice_number}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="badge badge-light-info">{branchesList.find(x => x.branch_id == obj.branch_id).branch_name + " - " + warehouseList.find(x => x.wid == obj.warehouse_id).name}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.reference_number ? obj.reference_number : "NA"}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.customer_name}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <button className='btn btn-sm btn-info rounded' onClick={() => { setItemsModalShow(true); setItemsData(obj.item_array) }}>View</button>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.add_date_time).utcOffset(timezone_change).format('ll')}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <StatusBadge status={obj.add_status} entity={"CUSTOMER_WAREHOUSE"} />
                                                                                        </td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                    <i className="fas fa-bars px-0"></i>
                                                                                                </button>
                                                                                                {
                                                                                                    write &&
                                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                        {(obj.add_status === "CUSTOMER_WAREHOUSE_PROCESSING_INIT" || obj.add_status === "CUSTOMER_WAREHOUSE_PROCESSING_PARTIAL") && <li className="py-1"><Link className="dropdown-item" to={`/customer-warehouse-processing/${id}`}>Process</Link></li>}
                                                                                                        {(obj.add_status === "CUSTOMER_WAREHOUSE_PROCESSED" || obj.add_status === "CUSTOMER_WAREHOUSE_PROCESSING_PARTIAL") && <li className="py-1"><Link className="dropdown-item" to={`/create-customer-delivery-challan/${id}`}>Create Delivery Challan</Link></li>}
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                state === 3 ?
                                    <PageError>
                                        <div className="notice bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <h4 className="text-gray-800 fw-bolder">Create a new Customer Invoice.</h4>
                                            <div className='mt-10 text-center'>
                                                <Link className='btn btn-success rounded' to={`/create-customer-invoice`}>Create Invoice</Link>
                                            </div>
                                        </div>
                                    </PageError>
                                    :
                                    <PageError>
                                        <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                            <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                            <div className='mt-10 text-center'>
                                                <button className='btn btn-success rounded' onClick={() => { window.location.reload() }}>Reload the page</button>
                                            </div>
                                        </div>
                                    </PageError>
                    }
                </div>
            </div>

            <Modal show={itemsModalShow} onHide={() => { setItemsModalShow(false); setItemsData([]); }} centered>
                <Modal.Header className='py-2'>
                    <Modal.Title>Items</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setItemsModalShow(false); setItemsData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='table-responsive'>
                            <table className='table table-striped'>
                                <thead className='bg-light-info fs-4 fw-bold text-primary'>
                                    <tr>
                                        <th scope='col'>Item Name</th>
                                        <th scope='col'>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        itemsData.map((obj, indx) => {
                                            return (
                                                <tr key={indx}>
                                                    <td className='fw-bold'>
                                                        <span className="bullet bg-primary me-2 rounded-circle" style={{ width: '10px', height: '10px', display: 'inline-block' }}></span>
                                                        {obj.item_name}
                                                    </td>
                                                    <td>{obj.quantity}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default CustomerWarehouseProcessingList;
