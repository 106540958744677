import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';
import Select from 'react-select';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom'

import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import formatNumber from '../common_component/formatNumber.js';
import { ReportWidgetWrapper, ReportWidgetItem } from '../common_component/ReportWidgets.js';

function CustomerBalancesReport() {

    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()
    const [baseCurrency, setBaseCurrency] = useState(null)

    const [customerBalancesReportList, setCustomerBalancesReportList] = useState([])
    const [customerBalancesReportApiState, setCustomerBalancesReportApiState] = useState(false)

    const daterange_picker_ref = useRef()
    const [invoicesData, setInvoicesData] = useState([])
    const apiController = useRef(new AbortController())

    const [detailsModalShow, setDetailsModalShow] = useState(false)
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [lastDate, setLastDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [mode, setMode] = useState({ label: "Include All Payments", value: "Include All Payments" })

    var page_name = "customer-balances-report";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getCustomerBalancesReport(cp, rpp, search_v, ld, mode) {
        try {
            if (customerBalancesReportApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setCustomerBalancesReportApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-customer-balances-report`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "last_date": ld, "mode": mode, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation !== "success") throw "get Customer Balances Report api error"

            setCustomerBalancesReportList(body.data.list)
            setListCount(body.data.count)

            setCustomerBalancesReportApiState(false)
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBaseCurrency() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        // console.log(body)
        if (body.length > 0) {
            setBaseCurrency(body[0])
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getCustomerBalancesReport(currentPage, rowsPerPage, searchValue, lastDate, mode.value)
            let p2 = getBaseCurrency()

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getCustomerBalancesReport(currentPage, rowsPerPage, searchValue, lastDate, mode.value)
        }
    }, [currentPage, rowsPerPage, searchValue, lastDate, mode])

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Customer Balances Report</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <DatePickerComponent
                            ref={daterange_picker_ref}
                            allowEdit={false}
                            showClearButton={false}
                            focus={() => { daterange_picker_ref.current.show() }}
                            change={(e) => {
                                setLastDate(moment(e.value).format("YYYY-MM-DD"));
                            }}
                            value={new Date(lastDate)}
                        />
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0">
                                    <ReportWidgetWrapper>
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Invoices Net Sum"}
                                            value={baseCurrency.currency_symbol + formatNumber(customerBalancesReportList.reduce((p, o) => p + o.invoices_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(customerBalancesReportList.reduce((p, o) => p + o.invoices_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Invoice Payments Net Sum"}
                                            value={baseCurrency.currency_symbol + formatNumber(customerBalancesReportList.reduce((p, o) => p + o.invoices_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(customerBalancesReportList.reduce((p, o) => p + o.invoices_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Balances"}
                                            value={baseCurrency.currency_symbol + formatNumber(customerBalancesReportList.reduce((p, o) => p + o.invoices_net_total, 0) - customerBalancesReportList.reduce((p, o) => p + o.invoices_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(customerBalancesReportList.reduce((p, o) => p + o.invoices_net_total, 0) - customerBalancesReportList.reduce((p, o) => p + o.invoices_payment_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                    </ReportWidgetWrapper>
                                </div>

                                <div className="card-body py-3">
                                    <div className='row align-items-center mb-5'>
                                        <div className='col-md-8 my-1'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className='col-md-4 my-1'>
                                            <Select
                                                options={["Include All Payments", "Include Payments before Date only"].map(x => { return { label: x, value: x } })}
                                                onChange={(val) => { setMode(val) }}
                                                value={mode}
                                                placeholder="Select Type..."
                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                        {
                                            customerBalancesReportApiState ?
                                                <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                :
                                                customerBalancesReportList.length > 0 ?
                                                    <>
                                                        <div className="mb-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>

                                                        <div className='overflow-auto w-100 flex-grow-1'>
                                                            <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                <thead>
                                                                    <tr className="fw-bold text-muted">
                                                                        <th className="min-w-120px">Customer Name</th>
                                                                        <th className="min-w-120px">Invoice Count</th>
                                                                        <th className="min-w-120px">Net Total of all Invoices</th>
                                                                        <th className="min-w-120px">Net Total of all Invoice Payments</th>
                                                                        <th className="min-w-120px">Balance</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        customerBalancesReportList.map((obj, indx3) => {
                                                                            return (
                                                                                <tr key={obj._id}>
                                                                                    <td><span className="text-dark fw-bold mb-1 fs-6 gap-2 d-flex align-items-center"><span>{obj.customer_name}</span>{obj.invoice_list.length > 0 && <i className="fa-solid fa-eye fs-2 p-0 text-hover-primary cursor-pointer" onClick={() => { setDetailsModalShow(true); setInvoicesData(obj.invoice_list) }}> </i>}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.invoice_list.length}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol + formatNumber(obj.invoices_net_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol + formatNumber(obj.invoices_payment_net_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol + formatNumber(obj.invoices_net_total - obj.invoices_payment_net_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="mt-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>

            <Modal show={detailsModalShow} onHide={() => { setDetailsModalShow(false); setInvoicesData([]); }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Invoice Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDetailsModalShow(false); setInvoicesData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    <th key={0} scope="col">Invoice</th>
                                    <th key={1} scope="col">Invoice Amount</th>
                                    <th key={2} scope="col">Received Amount</th>
                                    <th key={3} scope="col">Due Amount</th>
                                    <th key={4} scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoicesData.map((obj, indx) => (
                                        <tr key={indx}>
                                            <td className='d-flex align-items-center'>
                                                <span className="bullet bg-primary mx-2"></span>
                                                <span className='fw-bold'>{obj.customer_invoice_number}</span>
                                            </td>
                                            <td className='fs-5'>
                                                {baseCurrency.currency_symbol + formatNumber(obj.grand_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            </td>
                                            <td className='fs-5'>
                                                {baseCurrency.currency_symbol + formatNumber(obj.invoice_payment_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            </td>
                                            <td className='fs-5'>
                                                {baseCurrency.currency_symbol + formatNumber((obj.grand_total - obj.invoice_payment_total), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            </td>
                                            <td className='text-end'>
                                                <div className="dropdown">
                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-bars px-0"></i>
                                                    </button>
                                                    {write &&
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li className="py-1"><Link to={`/view-customer-invoice/${obj.customer_invoice_id}`} className="dropdown-item cursor-pointer">View details</Link></li>
                                                            <li className="py-1"><Link to={`/new-payment-received/invoice/${obj.customer_invoice_id}`} className="dropdown-item cursor-pointer">Receive Amount</Link></li>
                                                        </ul>

                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default CustomerBalancesReport;