import React, { useState, useEffect, useRef } from 'react';
// import swal from '@sweetalert/with-react'
import { Link, Redirect, useHistory } from 'react-router-dom'
import moment from 'moment';
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { Pagination } from 'antd';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
// import { Drawer } from 'antd';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import approveButtonShow from '../common_component/listPageApprovalButtonShow.js';
import StatusBadge from "../common_component/StatusBadge.js";
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';
import TableColumnHead from "../common_component/TableColumnHead.js";
import flow_img from '../flow_images/Customer_order_flow.jpg';

const statusTypeArray = ["GOODS_RECEIVED_FINALIZED", "GOODS_RECEIVED_PENDING_APPROVAL", "GOODS_RECEIVED_CANCELLED", "GOODS_RECEIVED_DRAFT"]

function GoodsReceivedList() {

    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    // const history = useHistory()


    const daterange_picker_ref = useRef()
    const [statusType, setStatusType] = useState({ label: "All", value: "All" });

    const [goodsReceivedList, setGoodsReceivedList] = useState([])

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [timezone_change, set_timezone_change] = useState("+00:00");
    const [dateFormat, setDateFormat] = useState(moment.utc().format("YYYY-MM-DD"))
    const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [goodsReceivedListApiState, setGoodsReceivedListApiState] = useState(false)

    const [itemDetailsModalShow, setItemDetailsModalShow] = useState(false)
    const [itemArrayIndex, setItemArrayIndex] = useState(null)

    const [flowModalShow, setFlowModalShow] = useState(false)

    var page_name = "goods-received-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getGoodsReceivedList(signal, cp, rpp, sort_c, sort_v, search_v, sd, ed, st) {
        setGoodsReceivedListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-goods-received-list`, { signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "start_date": sd, "end_date": ed, "status_type": st, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation !== "success") throw "get goods received list api error"
        setGoodsReceivedList(body.data.list)
        setListCount(body.data.count)

        setGoodsReceivedListApiState(false)
    }

    async function getbasecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.length > 0) {
            setDateFormat(body[0].date_format);
        } else {
            setDateFormat("ll")
        }
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    const timezone = body.data[0].timezone_value;
                    set_timezone_change(timezone);
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            const controller = new AbortController();
            const signal = controller.signal;

            let p1 = getTimezoneInformation()
            let p2 = getbasecurrency()
            let p3 = getGoodsReceivedList(signal, currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate, statusType.value)

            Promise.all([p1, p2, p3])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    if (err.name !== 'AbortError') {
                        setstate(3)
                        console.log(err)
                    }
                })

            return () => {
                controller.abort();
            };
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            const controller = new AbortController();
            const signal = controller.signal;

            getGoodsReceivedList(signal, currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate, statusType.value)

            return () => {
                controller.abort();
            };
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate, statusType.value])

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Goods Received List</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        {/* <button className="btn p-0 mx-3 btn-hover-scale">{true ? <i className="bi bi-pin-fill fs-1 px-0"></i> : <i className="bi bi-pin fs-1 px-0"></i>}</button> */}
                        <Link to={`/create-goods-receive`} className="btn btn-sm btn-primary me-3 rounded btn-hover-scale">
                            <span className="d-md-none">
                                <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                    <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff"></path>
                                </svg>
                            </span>
                            <span className="d-none d-md-inline">Create Goods Receive</span>
                        </Link>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <DateRangePickerComponent
                                            ref={daterange_picker_ref}
                                            allowEdit={false}
                                            showClearButton={false}
                                            focus={() => { daterange_picker_ref.current.show() }}
                                            delayUpdate={true}
                                            presets={[
                                                { label: 'Today', start: new Date(), end: new Date() },
                                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                            ]}
                                            change={(e) => {
                                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                            }}
                                            startDate={new Date(startDate)}
                                            endDate={new Date(endDate)}
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Select
                                            options={[{ label: "All", value: "All" }, ...statusTypeArray.map(x => { return { label: x.replace("GOODS_RECEIVED", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()), value: x } })]}
                                            onChange={(event) => { setStatusType(event); }}
                                            value={statusType}
                                            placeholder="Select Type..."
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                        />
                                    </div>
                                </div>

                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                goodsReceivedListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    goodsReceivedList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'gr_number'} label={'Goods Received Number'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px">Purchase Order Number</th>
                                                                            <th className="min-w-150px">Supplier Details</th>
                                                                            <th className="min-w-150px">Items</th>
                                                                            <th className="min-w-150px">Warehouse</th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'add_date_time'} label={'Date'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'add_status'} label={'Status'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            goodsReceivedList.map((obj, indx) => {
                                                                                return (
                                                                                    <tr key={obj.gr_id}>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.gr_number}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.purchase_order_number ? <span className="d-flex align-items-center gap-2"><span>{obj.purchase_order_number}</span><Link to={`/view-purchase-order/${obj.purchase_order_id}`}><i className="fa-solid fa-eye fs-2 p-0 text-hover-primary"></i></Link></span> : "N/A"}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.user_type} - {obj.doc_user_name}</span></td>
                                                                                        <td><button className="btn btn-primary fs-8 px-3 py-2" onClick={() => { setItemDetailsModalShow(true); setItemArrayIndex(indx) }}>View</button></td>
                                                                                        <td><span className="badge badge-light-info">{obj.branch_name} - {obj.warehouse_name}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.add_date_time).utcOffset(timezone_change).format(dateFormat)}</span></td>
                                                                                        <td><StatusBadge status={obj.add_status} entity={"GOODS_RECEIVED"} /></td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                    <i className="fas fa-bars px-0"></i>
                                                                                                </button>
                                                                                                {
                                                                                                    write &&
                                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                        <li className="py-1"><Link to={`/view-goods-receive/${obj.gr_id}`} className="dropdown-item">{approveButtonShow("GOODS_RECEIVED_PENDING_APPROVAL", user_role, user.email, obj) ? "View/Approve" : "View"}</Link></li>
                                                                                                        {(obj.add_status !== "GOODS_RECEIVED_FINALIZED" && obj.add_status !== "GOODS_RECEIVED_CANCELLED") && <li className="py-1"><Link to={`/edit-goods-receive/${obj.gr_id}`} className="dropdown-item">Edit details</Link></li>}
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>

            </div>

            <Modal show={itemDetailsModalShow} onHide={() => { setItemDetailsModalShow(false); }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Items</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setItemDetailsModalShow(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="d-flex border-bottom">
                            <span className="col-lg-3 fw-bold text-primary fs-2 d-block mb-1">Item Name</span>
                            <span className="col-lg-3 fw-bold text-primary fs-2 d-block mb-1">Quantity</span>
                            <span className="col-lg-6 fw-bold text-primary fs-2 d-block mb-1">Item Data</span>
                        </div>
                        {
                            itemDetailsModalShow && goodsReceivedList[itemArrayIndex].item_array.map((obj, indx) => (
                                <div className="d-flex py-3 border-bottom" key={indx}>
                                    <div className="col-lg-3 d-flex flex-column">
                                        <span className="fs-3">{obj.item_name}</span>
                                        {
                                            obj.item_stock_type !== null &&
                                            <div>
                                                <span className={`badge ${obj.item_stock_type === "Track Serial Number" ? "badge-light-success" : obj.item_stock_type === "Track Batches" ? "badge-light-info" : "badge-light-primary"}`}>{obj.item_stock_type === "Track Serial Number" ? "Serial" : obj.item_stock_type === "Track Batches" ? "Batches" : "None"}</span>
                                            </div>
                                        }
                                    </div>
                                    <span className="col-lg-3 fs-3">{obj.received_qty}</span>
                                    <span className="col-lg-6 fs-3 d-flex flex-wrap gap-2">
                                        {
                                            obj.item_stock_type !== "Track Serial Number" && obj.item_stock_type !== "Track Batches" ? <span>N/A</span> :
                                                obj.item_data.map((obj2, indx2) => (
                                                    <div key={indx2}>
                                                        <span className={`badge ${obj.item_stock_type === "Track Serial Number" ? "badge-light-success" : "badge-light-info"} fs-5`}>
                                                            {
                                                                obj.item_stock_type === "Track Serial Number" ? obj2 :
                                                                    obj.item_stock_type === "Track Batches" && obj2.batch_reference + " - " + obj2.batch_quantity
                                                            }
                                                        </span>
                                                    </div>
                                                ))
                                        }
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setItemDetailsModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={flowModalShow} onHide={() => { setFlowModalShow(false) }} dialogClassName="h-modal-80">
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-gray-500'>Order Flow Cycle</span>
                    </Modal.Title>

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFlowModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <img src={flow_img} className='w-100' alt="Not yet provided" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setFlowModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default GoodsReceivedList;