import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';

import currency_arr from '../includes/common_arr/currency-arr.js';
import { useTheme } from "../includes/theme-provider.js"
import { getCookie } from '../common_component/cookie.js';


//normally the following props are required for add new currency modal:   currencyModalShow, setCurrencyModalShow, pageName, apiCallback, modalCloseCallback
export default function CurrencyModal({ currencyModalShow, setCurrencyModalShow, pageName, apiCallback, modalCloseCallback, updateMode = false, currencyData = null, disabledFields = [] }) {

	const { theme, dropdownStyleDark } = useTheme()

	const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null)
	const [currencySymbol, setCurrencySymbol] = useState("")
	const [selectedCurrencyDecimalPlace, setSelectedCurrencyDecimalPlace] = useState(null)
	const [selectedCurrencyFormat, setSelectedCurrencyFormat] = useState({ label: "12,34,567.89", value: "12,34,567.89" })

	const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)


	useEffect(() => {
		if (updateMode && currencyData) {
			setSelectedCurrencyCode({ label: currencyData.currency_code, value: currencyData.currency_code })
			setCurrencySymbol(currencyData.currency_symbol)
			setSelectedCurrencyDecimalPlace({ label: currencyData.decimal_place, value: currencyData.decimal_place })
			setSelectedCurrencyFormat({ label: currencyData.format, value: currencyData.format })
		}
	}, [updateMode, currencyData])


	const currencySubmit = () => {

		if (selectedCurrencyCode === null) {
			swal("Oops!", "Currency Code cannot be empty", "error");
			return
		}
		if (currencySymbol === "") {
			swal("Oops!", "Currency Symbol cannot be empty", "error");
			return
		}
		if (selectedCurrencyDecimalPlace === null) {
			swal("Oops!", "Currency Decimal Place cannot be empty", "error");
			return
		}
		if (selectedCurrencyFormat === null) {
			swal("Oops!", "Currency Format cannot be empty", "error");
			return
		}

		var object = {};

		object.currency_code = selectedCurrencyCode.value
		object.currency_symbol = currencySymbol
		object.decimal_place = selectedCurrencyDecimalPlace.value
		object.format = selectedCurrencyFormat.value

		object.organization_id = getCookie("organization_id");
		object.page_name = pageName;

		if (updateMode) {
			object.id = currencyData.currency_id
		}

		// console.log(object);
		setCurrencySubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		let url = updateMode ? "update-currency" : "add-currency"

		axios.post(`${process.env.REACT_APP_SERVER_URL}/${url}`, object, headers).then((res) => {
			setCurrencySubmitButtonState(false)
			if (res.data.operation === "success") {
				swal("Great!", updateMode ? "Currency updated successfully!" : "Currency added successfully!", "success")
				handleCurrencyModalClose()

				apiCallback()
			}
			else {
				swal("Oops!", res.data.message, "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}

	const handleCurrencyModalClose = () => {
		setCurrencyModalShow(false)
		setSelectedCurrencyCode(null)
		setCurrencySymbol("")
		setSelectedCurrencyDecimalPlace(null)
		setSelectedCurrencyFormat(null)

		modalCloseCallback()
	}

	return (
		<Modal show={currencyModalShow} onHide={() => { handleCurrencyModalClose() }} size="lg" centered>
			<Modal.Header>
				<Modal.Title>{updateMode ? "Update Currency" : "New Currency"}</Modal.Title>
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleCurrencyModalClose() }}>
					<span className="svg-icon svg-icon-1">
						<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
							<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
								<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
								<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
							</g>
						</svg>
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='container'>
					<div className="row mb-4">
						<div className='col-12'>
							<label className="fs-5 fw-bold mb-2 required">Currency Code</label>
							<Select
								options={Object.keys(currency_arr.currency_arr[0]).map(x => { return { label: x, value: x } })}
								onChange={(val) => { setSelectedCurrencyCode(val); setCurrencySymbol(currency_arr.currency_arr[0][val.value].currency_symbol); }}
								value={selectedCurrencyCode}
								placeholder="--Choose a Currency--"
								isDisabled={disabledFields.includes("currency_code")}
								styles={theme == 'dark' ? dropdownStyleDark : {}}
							/>
						</div>
					</div>
					<div className="row mb-4">
						<div className='col-12'>
							<label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
							<input type="text" className="form-control form-control-solid" disabled={disabledFields.includes("currency_symbol")} value={currencySymbol} onChange={(e) => { setCurrencySymbol(e.target.value) }} />
						</div>
					</div>
					<div className="row mb-4">
						<div className='col-12'>
							<label className="fs-5 fw-bold mb-2 required">Decimal Places</label>
							<Select
								options={[0, 2, 3].map(x => { return { label: x, value: x } })}
								onChange={(val) => { setSelectedCurrencyDecimalPlace(val) }}
								value={selectedCurrencyDecimalPlace}
								placeholder="--Choose Decimal Places--"
								isDisabled={disabledFields.includes("decimal_places")}
								styles={theme == 'dark' ? dropdownStyleDark : {}}
							/>
						</div>
					</div>
					<div className="row mb-4">
						<div className='col-12'>
							<label className="fs-6 fw-bold mb-2 required">Format</label>
							<Select
								options={["12,34,567.89", "1,234,567.89", "1.234.567,89", "1 234 567,89"].map(x => { return { label: x, value: x } })}
								onChange={(e) => { setSelectedCurrencyFormat(e) }}
								value={selectedCurrencyFormat}
								placeholder="--Choose a Format--"
								isDisabled={disabledFields.includes("format")}
								styles={theme == 'dark' ? dropdownStyleDark : {}}
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState ? "on" : "off"} disabled={currencySubmitButtonState} onClick={() => { currencySubmit() }}>
					<span className="indicator-label">Save and Continue</span>
					<span className="indicator-progress">Please wait...
						<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
					</span>
				</button>
				<button className="btn btn-danger rounded" onClick={() => { handleCurrencyModalClose() }}>Close</button>
			</Modal.Footer>
		</Modal>
	);
};
