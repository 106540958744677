import React, { useState, useEffect,useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';
import { Modal } from 'react-bootstrap';

import tds_section_arr from './common_arr/tds-section-arr.js';
import tcs_section_arr from './common_arr/tcs-section-arr.js';

export const AllTaxList = () => {
    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    let [state_org, setstate_org] = useState(2);
	let [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    let [TaxName, setTaxName] = useState("");
    let [TaxType, setTaxType] = useState("");
    let [TaxCategory, setTaxCategory] = useState("");
    let [Percentage, setPercentage] = useState("");
    let [tax_id, settax_id] = useState("");
    const [status, setstatus] = useState("");
    let [redirect_to_login, setRedirect_to_login] = useState(2);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [userlist, setuserList] = useState([])
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [TaxTypelist, setTaxTypelist] = useState([{ label: "CGST", value: "CGST" }, { label: "SGST", value: "SGST" },{ label: "IGST", value: "IGST" },{ label: "UTGST", value: "UTGST" },{ label: "Cess", value: "Cess" }])
    const [Status, setStatus] = useState("All");
    const [StatusList, setStatusList] = useState([{ label: "All", value: "All" },{ label: "Regular", value: "Regular" },{ label: "TDS", value: "TDS" },{ label: "TCS", value: "TCS" }]);
    const [TDSTypelist, setTDSTypelist] = useState([]);
    const [TCSTypelist, setTCSTypelist] = useState([]);

    const [TDSAcountDetails, setTDSAcountDetails] = useState({"TDS_payable_account":"","TDS_receivable_account":""});
    const [TCSAcountDetails, setTCSAcountDetails] = useState({"TCS_payable_account":"","TCS_receivable_account":""});

    const [RegularTaxModalShow, setRegularTaxModalShow] = useState(false);
	const [TDSModalShow, setTDSModalShow] = useState(false);
	const [TCSModalShow, setTCSModalShow] = useState(false);
    const [StatusModalShow, setStatusModalShow] = useState(false);

    var page_name = "tax-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Merchant',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "tax_name",
            label: "Tax Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Percentage",
            label: "Percentage",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "tax_type",
            label: "Tax Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Category",
            label: "Category",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true); 
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                    object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
						}
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1,true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }

        organization_id!="" &&  validateJWT();
    }, [organization_id])

    useEffect(() => {
		var obj_array = [];
        var tdsParsedArray = tds_section_arr.tds_section_arr;
        var tcsParsedArray = tcs_section_arr.tcs_section_arr;
        for (let k = 0; k < tdsParsedArray.length; k++) {
            var obj2 = {};
            obj2.label = tdsParsedArray[k].section_name+"("+tdsParsedArray[k].section_no+")";
            obj2.value = tdsParsedArray[k].id;
            obj_array.push(obj2);
        }
        setTDSTypelist(obj_array);

        var obj_array1 = [];
        for (let j = 0; j < tcsParsedArray.length; j++) {
            var obj2 = {};
            obj2.label = tcsParsedArray[j].section_name+"("+tcsParsedArray[j].section_no+")";
            obj2.value = tcsParsedArray[j].id;
            obj_array1.push(obj2);
        }
        setTCSTypelist(obj_array1);
	}, []);

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);


    useEffect(() => {
        async function gettaxdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/tax-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation=="success") {
                setstate(1);
                setEditModal(body.data);
            }
            else {
                setstate(0);
            }

            setloading(true);
        }
        if (read || write) {
            user.email && visibility && gettaxdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.tax_name = obj.tax_name;
                    if(obj.category==="TDS")
                    {
                        temp_obj.tax_type = TDSTypelist.filter(option => option.value == obj.tax_type)[0].label;
                    }
                    else if(obj.category==="TCS")
                    {
                        temp_obj.tax_type = TCSTypelist.filter(option => option.value == obj.tax_type)[0].label;
                    }
                    else
                    {
                        temp_obj.tax_type = obj.tax_type;
                    }
                    
                    temp_obj.Category = obj.category;
                    temp_obj.Percentage = obj.tax_percentage;
                    temp_obj.status = obj.tax_status;
                    temp_obj.tax_id = obj.tax_id;
                    var id = obj.tax_id;
                    temp_obj.actions =
                        <div className="dropdown">
                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li className="py-1"><a onClick={(e) => { updateTax(id); }} className="dropdown-item">Edit details</a></li>
                                        <li className="py-1"><a onClick={(e) => { updateStatus(id); }} className="dropdown-item" href="#">Delete</a></li>
                                        {/* <li><a href="#" onClick={(e) => { updateTax(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Add/Assign Agents</a></li> */}
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();
    }, [editmodal])

    const getTaxList= (id) => {
        /*if (editmodal.length > 0) {
            var temp_data = [];
            let arr=editmodal;
            
            editmodal.map((obj) => {
                var temp_obj = {};
                    if(JSON.parse(obj.accounts).length>1)
                    {
                        temp_obj.category ="Itemized";
                    }
                    else
                    {
                        //console.log(JSON.parse(obj.accounts[0]).account.label)
                        temp_obj.category =JSON.parse(obj.accounts)[0].account.label;
                    }
                    temp_obj.expense_date = moment(obj.expense_date).format("ll");
                    if(obj.merchant_id!="")
                    {
                        temp_obj.Merchant =MerchantList.filter(option => option.value == obj.merchant_id)[0].label;
                    }
                    else
                    {
                        temp_obj.Merchant ="---";
                    }
                    if(obj.report_id!=="")
                    {
                        temp_obj.Report = Reportlist.filter(option => option.value == obj.report_id)[0].label;
                    }
                    else
                    {
                        temp_obj.Report ="---";
                    }
                    if(obj.flag==true)
                    {
                        temp_obj.duplicacy = <a href="#" onClick={(e) => { duplicateExpense(id); e.stopPropagation() }} data-bs-toggle="modal" data-bs-target="#duplicateExpense" className="dropdown-item">Duplicate</a>;
                    }
                    temp_obj.amount = obj.currency_symbol+obj.amount;
                    if(obj.report_id=="")
                    {
                        temp_obj.status ="UNREPORTED";
                    }
                    else if(Reportlist1.filter(option => option.value == obj.report_id)[0].add_status=="Submitted")
                    {
                        temp_obj.status ="AWAITING APPROVAL";
                    }
                    else if(Reportlist1.filter(option => option.value == obj.report_id)[0].add_status=="Approved")
                    {
                        temp_obj.status ="APPROVED";
                    }
                    else
                    {
                        temp_obj.status ="UNSUBMITTED";
                    }
                    //temp_obj.status = obj.add_status;
                    temp_obj.expense_id = obj.expense_id;
                    var id = obj.expense_id;
                    temp_obj.actions =
                        <div className="dropdown">
                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write?false:true}>
                                Edit
                            </button>
                            {
                                write ?
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a href="#" onClick={(e) => { splitExpense(id) }} data-bs-toggle="modal" data-bs-target="#SplitExpense" className="dropdown-item">Split Expense</a></li>
                                         <li><a href="#" onClick={(e) => { cloneExpense(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Clone</a></li>
                                        <li><a href="#" onClick={(e) => { updateExpense(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                        {obj.add_status="Active" &&<li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" className="dropdown-item" href="#">Delete</a></li>}
                                        <li><a onClick={(e) => { updateattachments(id) }} data-bs-toggle="modal" data-bs-target="#EditAttachments" className="dropdown-item" href="#">Attach Receipts</a></li>
                                    </ul>
                                    :
                                    null
                            }

                        </div>

                    temp_data.push(temp_obj);
            })
            var lookup = {};
            var items = temp_data;
            var result = [];
            result.push({label:"All",value:"All"});
            for (var item, i = 0; item = items[i++];) {
            var name = item.status;
                if (!(name in lookup)) {
                    lookup[name] = 1;
                    result.push({label:name,value:name});
                }
            }
            if(id!="All")
            {
                arr=temp_data.filter(option => option.status == id);
            }
            else
            {
                arr=temp_data;
            }
            setData(arr);
        }
        else {
            setData([]);
        }*/
    }

    const updateTax = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].tax_id) {
                settax_id(editmodal[i].tax_id)
                setPercentage(editmodal[i].tax_percentage)
                setTaxName(editmodal[i].tax_name);
                setTaxType(editmodal[i].tax_type);
                if(editmodal[i].category==="TDS")
                {
                    setTDSModalShow(true);
                }
                else if(editmodal[i].category==="TCS")
                {
                    setTCSModalShow(true);
                }
                else
                {
                    setRegularTaxModalShow(true);
                }
            }
        }
    }
    const submitTax = () => {
        
        settax_id("")
        setPercentage("")
        setTaxName("");
           
    }
    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].tax_id) {
                settax_id(editmodal[i].tax_id)
                setstatus(editmodal[i].status);
                setStatusModalShow(true);
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": tax_id, "status": status, "page_name": page_name,"organization_id":organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-tax-details`, values, headers).then((res) => {
            setMainSubmitButtonState(false) 
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Tax details deleted successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


    const submitTaxDetails = (e) => {
        e.preventDefault();
        //var id = document.getElementById("id").value;
        
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (TaxName == "") {
            swal("Warning!", "Tax Name cannot be empty", "warning");
            return false;
        }
        else if (!TaxName.match(nexp)) {
            swal("Warning!", "Tax Name cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            var oname = editmodal.filter((item) => { return item.tax_id !== tax_id })
            for (var i = 0; i < oname.length; ++i) {
                if (oname[i].tax_name == TaxName) {
                    swal("Oops!", "Tax Name already exists, try different one!", "error");
                    return false;
                }
            }
        }
        if (Percentage == "") {
            swal("Warning!", "Percentage cannot be empty", "warning");
            return false;
        }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

    if(tax_id!="")
    {
        var values = { "id": tax_id, "tax_name": TaxName,"category":TaxCategory,"tax_type":TaxType,"Percentage": Percentage, "page_name": page_name,"organization_id":organization_id };
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-tax`, values, headers).then((res) => {
            setMainSubmitButtonState(false) 
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Tax details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    else
    {
        var values = { "tax_name": TaxName,"category":TaxCategory,"tax_type":TaxType,"Percentage": Percentage, "page_name": page_name,"organization_id":organization_id };
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-tax`, values, headers).then((res) => {
            setMainSubmitButtonState(false) 
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Tax details save successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    }
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Tax List
                            
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <div className="m-0">
                                <a href="#" className="btn btn-sm fw-bold btn-primary" onClick={(e) => { submitTax(); setRegularTaxModalShow(true); setTaxCategory("Regular"); settax_id("");}}  data-bs-toggle="modal" data-bs-target="#EditModal">Add Regular Tax</a>
                            </div>			
                            <div className="m-0">
                                <a href="#" className="btn btn-sm fw-bold btn-primary" onClick={(e) => { submitTax(); setTDSModalShow(true); setTaxCategory("TDS"); settax_id("");}}  data-bs-toggle="modal" data-bs-target="#EditModal">Add TDS</a>
                            </div>
                            <a href="#" className="btn btn-sm fw-bold btn-primary" onClick={(e) => { submitTax(); setTCSModalShow(true); setTaxCategory("TCS"); settax_id("");}}  data-bs-toggle="modal" data-bs-target="#EditModal">Add TCS</a>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="card">
                        <div className="card-body pt-6">
                            {
                                data.length > 0 ?
                                    <>
                                    <div className="row mb-5">
                                        <div className="col-md-4 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Select Category:</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                name="category"
                                                id="category"
                                                className="form-control"
                                                options={StatusList}
                                                onChange={(event) => { 
                                                    setStatus(event.value)
                                                    getTaxList(event.value)
                                                }}
                                                value={StatusList.filter(option => option.value == Status).length != 0 && StatusList.filter(option => option.value == Status)[0]}
                                                placeholder="--Choose a Type--"
                                            />
                                        </div>
                                    </div>
                                    <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                        <MUIDataTable
                                            title={"Tax List"}
                                            data={data}
                                            columns={columns}
                                            options={options}
                                        />
                                    </MuiThemeProvider>
                                    </>
                                    :
                                    <>
                                        {
                                            state == 2 ?
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                :
                                                <>
                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5">Tax List</div>

                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                    <div className="fs-6 text-gray-600"> </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </>
                                        }
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Modal show={StatusModalShow} onHide={()=>{setStatusModalShow()}} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Delete Tax Details</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setStatusModalShow(false)}}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
                        <h3>Are you sure you want to delete this tax details?</h3>
					</div>
				</Modal.Body>
				<Modal.Footer> 
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write==1 &&<form >
						<button type="button" onClick={(e) => { submitStatus(e); }} className="btn btn-danger"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Delete</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>
            <Modal show={TCSModalShow} onHide={()=>{setTCSModalShow()}} size="lg" centered>
				<Modal.Header>
					<Modal.Title>{tax_id==="" ?<>Add</>:<>Edit</>} TCS</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setTCSModalShow(false)}}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input id="tax_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Percentage</label>
                            <input id='Percentage' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Percentage} onChange={(e) => { setPercentage(e.target.value) }} readOnly={write?false:true}/>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                    name="category"
                                    id="category"
                                    options={TCSTypelist}
                                    onChange={(event) => { 
                                        setTaxType(event.value)
                                        }}
                                    value={TCSTypelist.filter(option => option.value == TaxType).length != 0 && TCSTypelist.filter(option => option.value ==TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
					</div>
				</Modal.Body>
				<Modal.Footer> 
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write==1 &&<form >
						<button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id==="" ?<>Save</>:<>Update</>}</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>
            <Modal show={TDSModalShow} onHide={()=>{setTDSModalShow()}} size="lg" centered>
				<Modal.Header>
					<Modal.Title>{tax_id==="" ?<>Add</>:<>Edit</>} TDS</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setTDSModalShow(false)}}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input id="tax_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Percentage</label>
                            <input id='Percentage' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Percentage} onChange={(e) => { setPercentage(e.target.value) }} readOnly={write?false:true}/>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                    name="category"
                                    id="category"
                                    options={TDSTypelist}
                                    onChange={(event) => { 
                                        setTaxType(event.value)
                                        }}
                                    value={TDSTypelist.filter(option => option.value == TaxType).length != 0 && TDSTypelist.filter(option => option.value ==TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
					</div>
				</Modal.Body>
				<Modal.Footer> 
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write==1 &&<form >
						<button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id==="" ?<>Save</>:<>Update</>}</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>
            <Modal show={RegularTaxModalShow} onHide={()=>{setRegularTaxModalShow()}} size="lg" centered>
				<Modal.Header>
					<Modal.Title>{tax_id==="" ?<>Add</>:<>Edit</>}Regular Tax</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setRegularTaxModalShow(false)}}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input id="tax_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                            <label className="required fs-5 fw-bold mb-2">Percentage</label>
                            <input id='Percentage' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Percentage} onChange={(e) => { setPercentage(e.target.value) }} readOnly={write?false:true}/>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                    name="category"
                                    id="category"
                                    options={TaxTypelist}
                                    onChange={(event) => { 
                                        setTaxType(event.value)
                                        }}
                                    value={TaxTypelist.filter(option => option.value == TaxType).length != 0 && TaxTypelist.filter(option => option.value ==TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
					</div>
				</Modal.Body>
				<Modal.Footer> 
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write==1 &&<form >
						<button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id==="" ?<>Save</>:<>Update</>}</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
        </>);

};

export default AllTaxList;
