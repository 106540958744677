import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { getCookie, setCookie } from '../common_component/cookie.js';
import { useHistory } from 'react-router-dom'
import Timer_modal from '../common_component/timer_modal.js';
import Organization_modal from '../common_component/organization_modal.js';

const initialState = {
    isAuthenticated: false,
    user: null,
    organizationId: "",
    organizationName: "",
    organizationRole: "",
    organizationList: [],
    rolePermissionDetails: null,
    actionPermissionDetails: [],
}

const AuthContext = createContext(initialState)

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [organizationId, setOrganizationId] = useState("");
    const [organizationName, setOrganizationName] = useState("");
    const [organizationRole, setOrganizationRole] = useState("");
    const [organizationList, setOrganizationList] = useState([]);
    const [rolePermissionDetails, setRolePermissionDetails] = useState(null);
    const [actionPermissionDetails, setActionPermissionDetails] = useState([]);
    const [state_org, setstate_org] = useState(2);

    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);

    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);

    const history = useHistory()

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);
        return () => { clearInterval(timer_interval_ref) };
    }, []);

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganizationId(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = "dashboard";
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if(res.data.operation === "success"){
                        if (res.data.length == 1) {
                            setCookie('organization_id', res.data[0].organization_id, 1, true);
                            setOrganizationId(res.data[0].organization_id);
                        }
                        else {
                            setOrganizationList(res.data);
                        }
                    }
                    else {
                        history.push("/login");
                    }
                })
            }
        }
        else {
            history.push("/login");
        }
    }, [])

    useEffect(() => {
        const checkAuth = async () => {
            if (getCookie("access_token") !== "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {
                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object);
                        object.organization_id = organizationId;
                        axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res2) => {
                            let response = res2.data
                            if (response.operation == "success") {
                                setOrganizationRole(response.user_role);
                                setOrganizationName(response.organization_name);
                                var token = response.audience_license;
                                if (response.organization_flag == 0) {
                                    setstate_org(3);
                                    setOrganizationModalShow(true);
                                }
                                else {
                                    setRolePermissionDetails(response.user_access.access);
                                    setActionPermissionDetails(response.user_actions_permission.permissions);
                                }
                                if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                                    setCookie('access_token', response.audience_license, 1, true)
                                    timer.current = process.env.REACT_APP_JWT_EXPIRY;
                                }
                                else {
                                    timer.current = response.audience_count;
                                }
                            }
                            else {
                                history.push("/login");
                            }
                        })
                    }
                    else {
                        history.push("/login");
                    }
                })
            }
            else {
                history.push("/login");
            }
        };

        organizationId !== "" && checkAuth(); // Call the checkAuth function on component mount

    }, [organizationId]);

    const value = {
        isAuthenticated,
        user,
        rolePermissionDetails,
        actionPermissionDetails,
        organizationId,
        organizationName,
        organizationRole,
        organizationList

    };

    return (
        <AuthContext.Provider value={value}>
            {children}
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organizationId} timer_ref={timer} />
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organizationName} organization_list={organizationList} organization_id={organizationId} state_org={state_org} />
        </AuthContext.Provider>
    )
};

export const useAuthData = (page_name) => {
    const context = useContext(AuthContext)
    const history = useHistory()
    if (context === undefined)
        throw new Error("useAuthData must be used within a AuthProvider")

    const [read, setRead] = useState(false);
    const [write, setWrite] = useState(false);
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        if (context.rolePermissionDetails) {
            let { read, write, visibility } = checkPagePermission(page_name, context.rolePermissionDetails);
            setRead(read);
            setWrite(write);
            setVisibility(visibility);
            setCookie("lastpage", window.location.href, 1, false)
            if (!read && !write) {
                history.push("/unauthorized");
            }
        }
    }, [context.rolePermissionDetails])

    return { ...context, read, write, visibility, organization_id: context.organizationId, user_role: context.organizationRole,organization_name:context.organizationName }

}

const checkPagePermission = (pageName, permissions) => {
    let pagePerms = { visibility: false, read: false, write: false };
    let find_flag = 0;
    for (let i = 0; i < permissions.length; i++) {
        //permissions--i--my view/admin view
        if (find_flag == 1) break;
        if (permissions[i].visibility == 1) {
            let component = permissions[i].inside_components;
            for (let j = 0; j < component.length; j++) {
                //component--j--dashboard/Sales/expense
                if (find_flag == 1) break;
                if (component[j].hasOwnProperty(pageName)) {
                    //add-expense
                    if (component[j][pageName] == 1) {
                        find_flag = 1;
                        pagePerms.visibility = true;
                        pagePerms.read = true;
                        pagePerms.write = true;
                        break;
                    }
                }
                else if (component[j].hasOwnProperty("key")) {
                    if (component[j].visibility == 1) {
                        let sub_inside_components = component[j].inside_components
                        for (let k = 0; k < sub_inside_components.length; k++) {
                            //customer_order/customer_invoice/recieve_payment
                            if (find_flag == 1) break;
                            //console.log(sub_inside_components[k])
                            if (sub_inside_components[k].hasOwnProperty(pageName)) {
                                if (sub_inside_components[k][pageName] == 1) {
                                    find_flag = 1;
                                    pagePerms.visibility = true;
                                    pagePerms.read = true;
                                    pagePerms.write = true;
                                    break;

                                }
                            }
                            else if (sub_inside_components[k].hasOwnProperty("key")) {
                                if (component[j].visibility == 1) {
                                    let sub_sub_inside_components = sub_inside_components[k].inside_components;
                                    for (let l = 0; l < sub_sub_inside_components.length; l++) {
                                        ///new-payment-received/create-customer-order/create-customer-invoice
                                        if (find_flag == 1) break;
                                        if (sub_sub_inside_components[l].hasOwnProperty(pageName)) {
                                            if (sub_sub_inside_components[l][pageName] == 1) {
                                                find_flag = 1;
                                                pagePerms.visibility = true;
                                                pagePerms.read = true;
                                                pagePerms.write = true;
                                                break;

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return {
        read: pagePerms.read ?? false, // Default to false for missing permissions
        write: pagePerms.write ?? false,
        visibility: pagePerms.visibility ?? false,
    };
}
