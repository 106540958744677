import React, { useState, useEffect, useMemo } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Pagination } from "antd";

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from "../common_component/PageError.js";
import StatusBadge from "../common_component/StatusBadge.js";

export const TaxGroupList = () => {
    const [state, setstate] = useState(2);

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)

    const [taxGroupList, setTaxGroupList] = useState([]);
    const [tax_list, settax_list] = useState([]);
    const [total_tax_list, settotal_tax_list] = useState([]);

    const [TaxGroupName, setTaxGroupName] = useState("");
    const [tax_group_id, settax_group_id] = useState("");
    const [tax_group_type, settax_group_type] = useState("INTRASTATE");
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    var page_name = "tax-group-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getRegularTaxList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-regular-tax`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            let arr = [];
            for (let i = 0; i < body.data.length; i++) {
                if (Number(body.data[i].tax_percentage) !== 0 && (body.data[i].tax_type == "CGST" || body.data[i].tax_type == "SGST")) {
                    arr.push(body.data[i]);
                }
            }
            settax_list(arr);
            settotal_tax_list(body.data);
        }
        else {
            throw "regular tax list api error"
        }
    }
    async function getTaxGroupList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setTaxGroupList(body.data);
        }
        else {
            throw "tax group list api error"
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getRegularTaxList();
            let p2 = getTaxGroupList();

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    console.log(errx)
                    setstate(3)
                })
        }
    }, [user, visibility, read, write])

    function contains(a, obj) {
        for (var i = 0; i < a.length; i++) {
            if (a[i] === obj) {
                return true;
            }
        }
        return false;
    }

    const updateTaxgroup = (id) => {
        for (let i = 0; i < taxGroupList.length; i++) {
            if (id == taxGroupList[i].tax_group_id) {
                handleInputChange2(taxGroupList[i].tax_group_type, taxGroupList[i].tax_id);
                settax_group_id(taxGroupList[i].tax_group_id)
                setTaxGroupName(taxGroupList[i].tax_group_name);
                if (taxGroupList[i].tax_group_type === "INTRASTATE") {
                    settax_group_type("INTRASTATE");
                }
                else if (taxGroupList[i].tax_group_type === "INTRAUNION") {
                    settax_group_type("INTRAUNION");
                }
                else {
                    settax_group_type("INTERSTATE");
                }
            }
        }
    }
    const submitTaxGroup = () => {

        settax_group_id("")
        setTaxGroupName("");
        settax_group_type("INTRASTATE");
        const values1 = [...tax_list];

        for (let i = 0; i < values1.length; i++) {
            values1[i].check = false;

        }
        settax_list(values1)

    }

    const changeStatusTaxGroup = (id, status) => {
        let values = { "id": id, "page_name": page_name, "status": status, "organization_id": organization_id };
        setstate(2);
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-tax-group-details`, values, headers).then((res) => {
            setMainSubmitButtonState(false);
            setstate(1);
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success")
                getTaxGroupList()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitTaxGroupDetails = (e) => {
        e.preventDefault();
        var newArray = tax_list.filter(function (el) {
            return el.check == true
        }
        );
        let str = newArray.map(x => x.tax_id).join(",")
        let total_percentage = newArray.reduce((p, x) => { return p += parseFloat(x.tax_percentage) }, 0)
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (TaxGroupName == "") {
            swal("Warning!", "Tax Group Name cannot be empty", "warning");
            return false;
        }
        else if (!TaxGroupName.match(nexp)) {
            swal("Warning!", "Tax Group Name cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            var oname = taxGroupList.filter((item) => { return item.tax_group_id !== tax_group_id })
            for (var i = 0; i < oname.length; ++i) {
                //console.log(oname);
                if (oname[i].tax_group_name == TaxGroupName) {
                    swal("Oops!", "Tax Group Name already exists, try different one!", "error");
                    return false;
                }
                else {
                    if (JSON.stringify([oname[i].tax_id.split(",")].sort((a, b) => a - b)) == JSON.stringify([str.split(",")].sort((a, b) => a - b)) && oname[i].tax_group_type == tax_group_type) {
                        swal("Oops!", "Tax Group pattern already exists, try different one!", "error");
                        return false;
                    }
                }
                //}
            }
        }
        setMainSubmitButtonState(true)


        if (newArray.length == 0) {
            swal("Warning!", "Please select tax for tax group", "warning");
            return false;
        }

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        if (tax_group_id != "") {
            var values = { "organization_id": organization_id, "tax_group_type": tax_group_type, "id": tax_group_id, "Tax_Group_Name": TaxGroupName, "tax_list": str, "tax_group_percentage": total_percentage, "page_name": page_name };
            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-tax-group`, values, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Tax group details updated successfully!", "success").then(() => { window.location.reload() })
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else {
            var values = { "organization_id": organization_id, "tax_group_type": tax_group_type, "Tax_Group_Name": TaxGroupName, "tax_list": str, "tax_group_percentage": total_percentage, "page_name": page_name };
            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-tax-group`, values, headers).then((res) => {
                setMainSubmitButtonState(false)
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Tax group details updated successfully!", "success").then(() => { window.location.reload() })
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
    }
    const handleInputChange2 = (type, tax_id) => {
        let arr = [];
        const values1 = [...total_tax_list];
        for (let i = 0; i < values1.length; i++) {
            values1[i].check = false;

        }
        for (let i = 0; i < values1.length; i++) {
            if (type === "INTRASTATE" && Number(values1[i].tax_percentage) !== 0 && (values1[i].tax_type == "CGST" || values1[i].tax_type == "SGST")) {
                arr.push(values1[i]);
            }
            else if (type === "INTRAUNION" && Number(values1[i].tax_percentage) !== 0 && (values1[i].tax_type == "CGST" || values1[i].tax_type == "UTGST")) {
                arr.push(values1[i]);
            }
            else if (type === "INTERSTATE" && (values1[i].tax_type == "IGST")) {
                arr.push(values1[i]);
            }
        }
        let tax = tax_id.split(",")

        for (let i = 0; i < arr.length; i++) {
            contains(tax, arr[i].tax_id)
            if (contains(tax, arr[i].tax_id)) {
                arr[i].check = true;
            }
            else {
                arr[i].check = false;
            }
        }
        settax_list(arr);
        settax_group_type(type);
    }

    const handleInputChange1 = (type) => {
        let arr = [];
        const values1 = [...total_tax_list];
        for (let i = 0; i < values1.length; i++) {
            values1[i].check = false;

        }
        for (let i = 0; i < values1.length; i++) {
            if (type === "INTRASTATE" && Number(values1[i].tax_percentage) !== 0 && (values1[i].tax_type == "CGST" || values1[i].tax_type == "SGST")) {
                arr.push(values1[i]);
            }
            else if (type === "INTRAUNION" && Number(values1[i].tax_percentage) !== 0 && (values1[i].tax_type == "CGST" || values1[i].tax_type == "UTGST")) {
                arr.push(values1[i]);
            }
            else if (type === "INTERSTATE" && (values1[i].tax_type == "IGST")) {
                arr.push(values1[i]);
            }
        }
        settax_list(arr);
        settax_group_type(type);
    }
    const handleInputChange = (index, event, tax_id) => {
        const values1 = [...tax_list];
        if (values1.filter(option => option.check == true).length == 1) {
            if (values1.filter(option => option.check == true)[0].tax_type === values1[index].tax_type) {
                swal("Oops!", "You can't create group with same type tax!", "error");
                values1[index].check = false;
            }
            else {
                values1[index].check = event.target.checked;
            }
        }
        else {
            values1[index].check = event.target.checked;
        }

        settax_list(values1)
    };

    const filteredList = useMemo(() => {
        if (searchValue) {
            return taxGroupList.filter((x) => {
                let r = new RegExp(searchValue, "gi")
                if (r.test(x.tax_group_name) ||
                    r.test(x.tax_group_type) ||
                    r.test(x.tax_group_percentage) ||
                    r.test(x.tax_group_status)
                ) {
                    return x
                }
            })
        }
        else {
            return taxGroupList
        }
    }, [taxGroupList, searchValue])

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Tax Group List</h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3"><div className="m-0"><a onClick={(e) => { submitTaxGroup() }} data-bs-toggle="modal" data-bs-target="#EditModal" className="btn btn-sm btn-primary me-3 rounded"><span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor"></rect><rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect></svg></span>New Tax Group</a></div></div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className='mb-5'>
                                                <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                            </div>

                                            <div className="mb-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    showQuickJumper={true}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                />
                                            </div>
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Tax Group Name</th>
                                                            <th className="min-w-150px">Tax Group Type</th>
                                                            <th className="min-w-150px">Tax Group Percentage</th>
                                                            <th className="min-w-150px">Status</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredList.length > 0 ?
                                                                filteredList.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((obj) => {
                                                                    return (
                                                                        <tr key={obj.tax_group_id}>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.tax_group_name}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-secondary me-auto rounded">{obj.tax_group_type}</span>
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.tax_group_percentage}</span>
                                                                            </td>
                                                                            <td>
                                                                                <StatusBadge status={obj.tax_group_status} entity={""} />
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <div className="dropdown" style={{ position: "unset" }}>
                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="fas fa-bars px-0"></i>
                                                                                    </button>
                                                                                    {
                                                                                        write &&
                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                            {obj.tax_group_percentage != "0" && <li className="py-1 cursor-pointer"><span onClick={(e) => { updateTaxgroup(obj.tax_group_id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</span></li>}
                                                                                            <li className="py-1 cursor-pointer">
                                                                                                <span className="dropdown-item"
                                                                                                    onClick={(e) => {
                                                                                                        swal({
                                                                                                            title: "Are you sure?",
                                                                                                            text: "Are you sure you want to change status?",
                                                                                                            icon: "warning",
                                                                                                            buttons: true,
                                                                                                            dangerMode: true,
                                                                                                        })
                                                                                                            .then((willDelete) => {
                                                                                                                if (willDelete) {
                                                                                                                    changeStatusTaxGroup(obj.tax_group_id, obj.tax_group_status)
                                                                                                                }
                                                                                                            });
                                                                                                    }}
                                                                                                >Change Status</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={5} className="text-center">
                                                                        <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="mt-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <PageError />
                        }
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>{tax_group_id == "" ? "Submit" : "Update"} Tax Group</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                    <div className="row mb-6">
                                        <label className="required fs-5 fw-bold mb-2">Tax Group Type</label>
                                        <div className="col-lg-8 fv-row fv-plugins-icon-container">
                                            <div className="d-flex align-items-center mt-3">
                                                <label className="form-check form-check-custom form-check-inline form-check-solid me-5">
                                                    <input className="form-check-input" checked={tax_group_type === "INTRASTATE"} onChange={(event) => { handleInputChange1("INTRASTATE"); }} type="checkbox" />
                                                    <span className="fw-semibold ps-2 fs-6">SGST-CGST</span>
                                                </label>
                                                <label className="form-check form-check-custom form-check-inline form-check-solid me-5">
                                                    <input className="form-check-input" checked={tax_group_type === "INTRAUNION"} onChange={(event) => { handleInputChange1("INTRAUNION"); }} type="checkbox" />
                                                    <span className="fw-semibold ps-2 fs-6">UTGST-CGST</span>
                                                </label>
                                                <label className="form-check form-check-custom form-check-inline form-check-solid">
                                                    <input className="form-check-input" checked={tax_group_type === "INTERSTATE"} onChange={(event) => { handleInputChange1("INTERSTATE"); }} type="checkbox" />
                                                    <span className="fw-semibold ps-2 fs-6">IGST</span>
                                                </label>
                                            </div>
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Tax Group Name</label>
                                            <input id="tax_group_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxGroupName} onChange={(e) => { setTaxGroupName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Tax List</label>
                                            <div className="table-responsive">

                                                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                                    <thead>
                                                        <tr className="fw-bolder text-muted">
                                                            <th className="min-w-140px"></th>
                                                            <th className="min-w-140px">Tax</th>
                                                            <th className="min-w-120px">Percentage</th>
                                                            <th className="min-w-120px">Type</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody >
                                                        {
                                                            tax_list.length > 0 && tax_list.map((obj, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>
                                                                                <div className="form-check form-check-sm form-check-custom form-check-solid">
                                                                                    {tax_group_type === "INTERSTATE" ?
                                                                                        <input className="form-check-input widget-9-check" type="checkbox" value={obj.check} checked={obj.check} disabled={(tax_list.filter(option => option.check == true).length > 0 && obj.check === false)} onChange={(event) => { handleInputChange(index, event, obj.tax_id); }} />
                                                                                        :
                                                                                        <input className="form-check-input widget-9-check" type="checkbox" value={obj.check} checked={obj.check} disabled={(tax_list.filter(option => option.check == true).length > 1 && obj.check === false)} onChange={(event) => { handleInputChange(index, event, obj.tax_id); }} />
                                                                                    }
                                                                                </div>
                                                                            </td>

                                                                            <td>
                                                                                <p className="text-dark fw-bolder text-hover-primary d-block fs-6">{obj.tax_name}</p>
                                                                            </td>
                                                                            <td>
                                                                                <span className="text-muted me-2 fs-7 fw-bold">{obj.tax_percentage}%</span>
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <div className="d-flex flex-column w-100 me-2">
                                                                                    <div className="d-flex flex-stack mb-2">
                                                                                        <span className="text-muted me-2 fs-7 fw-bold">{obj.tax_type}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer flex-center">
                                {
                                    write && <button onClick={(e) => { submitTaxGroupDetails(e) }} type="submit" id="kt_modal_new_address_submit" className="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
                                        <span className="indicator-label">{tax_group_id == "" ? "Submit" : "Update"}</span>
                                        <span className="indicator-progress">Please wait...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaxGroupList;
