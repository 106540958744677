import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { Pagination } from 'antd';
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

export const ListDepartment = () => {
    const [state, setstate] = useState(2);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [deptName, setDeptName] = useState("");
    const [deptId, setDeptId] = useState("");
    const [departmentCode, setDepartmentCode] = useState("");
    const [departmentHead, setDepartmentHead] = useState("");
    const [deptDesc, setDeptDesc] = useState("");
    const [status, setstatus] = useState("");

    const [departmentModalShow, setDepartmentModalShow] = useState(false);
    const [StatusModalShow, setStatusModalShow] = useState(false);
    const apiController = useRef(new AbortController())

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [departmentList, setDepartmentList] = useState([]);
    const [departmentListApiState, setDepartmentListApiState] = useState(false)

    const [userlist, setuserList] = useState([])
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    var page_name = "department-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    async function getUserList() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.log_status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.user_id;
                temp_obj.value = obj.user_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setuserList(temp_dropdown);
    }

    async function getDepartmentDetails(page_number, Size, search_val) {
        try {
            var object = {};
            object.email = user.email;
            if (departmentListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setDepartmentListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id, "current_page": page_number, "rows_per_page": Size, "search_value": search_val, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setDepartmentList(body.data.list);
            setListCount(body.data.count);
            setDepartmentListApiState(false);
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getDepartmentDetails(currentPage, rowsPerPage, searchValue)
            let p2 = getUserList()

            Promise.all([p1,p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getDepartmentDetails(currentPage, rowsPerPage, searchValue)
        }
    }, [currentPage, rowsPerPage, searchValue])

    const submitDepartment = (id) => {
        setDepartmentCode("");
        setDepartmentHead("");
        setDeptDesc("");
        setDeptName("");
        setDeptId("");
        setDepartmentModalShow(true);
    }

    const updateDepartment = (obj) => {
        setDepartmentCode(obj.department_code)
        setDepartmentHead(obj.department_head)
        setDeptDesc(obj.department_description);
        setDeptName(obj.department_name);
        setDeptId(obj.department_id);
        setDepartmentModalShow(true);
    }
    const updateStatus = (obj) => {
        setstatus(obj.status);
    }

    const submitStatus = (e) => {
        e.preventDefault();
        var values = { "id": deptId, "status": status, "page_name": page_name, "organization_id": organization_id };

        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-department`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    setStatusModalShow(false);
                    getDepartmentDetails(currentPage, rowsPerPage, searchValue);
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


    const submitDepartmentDetails = (e) => {
        e.preventDefault();

        if (deptName == "") {
            swal("Warning!", "Department Name cannot be empty", "warning");
            return false;
        }

        if (departmentCode == "") {
            swal("Warning!", "Department code cannot be empty", "warning");
            return false;
        }

        if (departmentHead == "") {
            swal("Warning!", "Department head cannot be empty", "warning");
            return false;
        }

        var values = { "id": deptId, "department_name": deptName, "department_description": deptDesc, "department_head": departmentHead, "department_code": departmentCode, "page_name": page_name, "organization_id": organization_id };

        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        const api = deptId!==""?"update-department":"add-department";

        axios.post(`${process.env.REACT_APP_SERVER_URL}/${api}`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Department details saved successfully!", "success").then((value) => {
                    getDepartmentDetails(currentPage, rowsPerPage, searchValue);
                    setDeptName("");
                    setDepartmentCode("");
                    setDepartmentHead("");
                    setDeptDesc("");
                    setDeptId("");
                    setDepartmentModalShow(false);
                });
            }
            else if (res.data.operation == "same_department_name") {
                swal("Oops!", "Same Department Name Already Exist!", "error")
            }
            else if (res.data.operation == "same_department_code") {
                swal("Oops!", "Same Department Code Already Exist!", "error")
            }               
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }
    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Department List</h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <a onClick={(e) => { submitDepartment()}} className="btn btn-sm btn-primary btn-hover-scale"><i className="bi bi-plus-circle" />New Department</a>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className="mb-5"><input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' /></div>
                                            <div className="mb-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={listCount}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 min-w-1000px" style={{ minHeight: "200px", minWidth: "1000px" }}>
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-80px">Department Name</th>
                                                            <th className="min-w-75px">Department Code</th>
                                                            <th className="min-w-75px">Department Head</th>
                                                            <th className="min-w-75px">Description</th>
                                                            <th className="min-w-120px">Status</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            departmentListApiState ?
                                                                <tr>
                                                                    <td colSpan={7} className="text-center pt-10">
                                                                        <div className="spinner-border text-primary"></div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                departmentList.length > 0 ?
                                                                    departmentList.map((obj3, indx3) => {
                                                                        return (
                                                                            <tr key={indx3}>
                                                                                <td>
                                                                                    <span className="text-dark fw-bold d-block mb-1 fs-6">{obj3.department_name}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-dark fw-bold d-block mb-1 fs-6">{obj3.department_code}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="badge badge-secondary">{obj3.department_head}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className="text-dark fw-bold d-block mb-1 fs-6">{obj3.department_description}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <span className={obj3.status === "Active" ? "badge badge-success" : "badge badge-danger"}>
                                                                                        {obj3.status}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="text-end">
                                                                                    <div className="dropdown">
                                                                                        <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                            <i className="fas fa-bars px-0"></i>
                                                                                        </button>
                                                                                        {write &&
                                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                <li className="py-1"><a onClick={(e) => { updateDepartment(obj3) }} className="dropdown-item cursor-pointer">Edit details</a></li>
                                                                                                <li className="py-1"><a onClick={(e) => { updateStatus(obj3) }} className="dropdown-item cursor-pointer">Change status</a></li>
                                                                                            </ul>

                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={7} className="text-center">
                                                                            <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                        </td>
                                                                    </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="mt-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={listCount}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                state == 2 ?
                                    <div className="card">
                                        <div className="card-body pt-6">
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        </div>
                                    </div>
                                    :
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-bold">
                                                        <h4 className="text-gray-800 fw-bolder">Something went wrong. Please reload this page.</h4>
                                                        <div className="fs-6 text-gray-600"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
            <Modal show={StatusModalShow} onHide={() => { setStatusModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Change Status</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setStatusModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                            <h3>Are you sure about changing status?</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setStatusModalShow(false) }} className="btn btn-secondary">Cancel</button>
                    {write && <form >
                        <button type="button" onClick={(e) => { submitStatus(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{status == "Active" ? <>Deactive</> : <>Active</>}</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                    </form>}
                </Modal.Footer>
            </Modal>
            <Modal show={departmentModalShow} onHide={() => { setDepartmentModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{deptId != "" ? <>Update</> : <>Add</>} Category</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDepartmentModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Department Name</label>
                                <input id="department_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={deptName} onChange={(e) => { setDeptName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Department code</label>
                                <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={departmentCode} onChange={(e) => { setDepartmentCode(e.target.value) }} readOnly={write ? false : true} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Department head</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    className="form-control form-control-solid"
                                    options={userlist}
                                    onChange={(event) => { setDepartmentHead(event.value) }}
                                    value={userlist.filter(option => option.value == departmentHead).length != 0 && userlist.filter(option => option.value == departmentHead)[0]}
                                    placeholder="--Choose a Department Head--"
                                />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Description</label><textarea id="department_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={deptDesc} onChange={(e) => { setDeptDesc(e.target.value) }}></textarea></div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setDepartmentModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {write && <form >
                        <button type="button" onClick={(e) => { submitDepartmentDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{deptId != "" ? <>Update</> : <>Save</>}</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                    </form>}
                </Modal.Footer>
            </Modal>
        </>);

};

export default ListDepartment;
