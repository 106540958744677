import React, {  useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha"
import { Link, useHistory } from 'react-router-dom'
import { setCookie, getCookie } from '../common_component/cookie.js';


function Signup() {

	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [captchaToken, setCaptchaToken] = useState("");

	const [firstName, setFirstName] = useState("")
	const [secondName, setSecondName] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("91");
	const [termsChecker, setTermsChecker] = useState(false);

	const [email, setEmail] = useState("")

	const [password, setPassword] = useState("")
	const [repeatPassword, setRepeatPassword] = useState("")
	const [passwordRating, setPasswordRating] = useState(0)
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const history = useHistory()

	const passwordChecker = (password) => {

		let a = 0
		if (password.length >= 8) {
			a += 25
		}
		if (/[a-z][A-Z]/.test(password)) {
			a += 25
		}
		if (/[0-9]/.test(password)) {
			a += 25;
		}
		if (/[^a-zA-Z0-9]/.test(password)) {
			a += 25;
		}
		console.log(a);
		return a;
	}

	const validateRegister = () => {

		if (firstName === "") {
			swal("Warning!", "First Name cannot be a empty", "warning");
			return false;
		}

		const namePattern = /^[A-Za-z]+$/;

		if (!namePattern.test(firstName)) {
			swal("Warning!", "Please enter a valid First name.", "warning");
			return false;
		}
		if (secondName === "") {
			swal("Warning!", "Last Name cannot be a empty", "warning");
			return false;
		}

		if (!namePattern.test(secondName)) {
			swal("Warning!", "Please enter a valid Last name.", "warning");
			return false;
		}

		if (phoneNumber === "") {
			swal("Warning!", "Please enter a valid phone number.", "warning");
			return false;
		}

		const phonePattern = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

		if (!phonePattern.test(phoneNumber)) {
			swal("Warning!", "Please enter a valid phone number in the format XXXXX-XXXXX.", "warning");
			return false;
		}

		if (email === "") {
			swal("Warning!", "Please enter a valid email.", "warning");
			return false;
		}

		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailPattern.test(email)) {
			swal("Warning!", "Please enter a valid email.", "warning");
			return false;
		}

		if (password === "") {
			swal("Warning!", "Password cannot be empty", "warning");
			return false;
		}

		if (password.length < 8) {
			swal("Warning!", "Password must have atleast 8 characters.", "warning");
			return false;
		}

		if (repeatPassword === "") {
			swal("Warning!", "Please confirm your password by re-typing.", "warning");
			return false;
		}

		if (repeatPassword !== password) {
			swal("Warning!", "Confirm and match both the passwords.", "warning");
			setRepeatPassword("")
			return false;
		}

		if (!termsChecker) {
			swal("Warning!", "Please check our terms.", "warning");
			return false;
		}

		if (captchaToken === "") {
			swal("Warning!", "Please Verify the captcha", "warning");
			return false;
		}

		return true
	}

	const register = () => {

		var object = {};
		object.fname = firstName;
		object.lname = secondName;
		object.email = email;
		object.password = password;
		object.cpassword = repeatPassword;
		object.service = "account";
		object.token = captchaToken;

		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*"
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/register`, object, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data.operation == "success") {
				swal("Great!", "Registration Successful ", "success").then((val) => {
					setCookie("access_token", "", -1, true);
					setCookie("organization_id", "", -1, true);
					if (res.data.quick_set_up == 0) {
						var object = {};
						var headers = {
							headers: {
								"Content-Type": "application/json",
								"token": res.data.token,
								"Accept": "*/*"
							}
						}
						axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res1) => {
							if (res1.data.length > 0) {
								setCookie('organization_id', res1.data[0].organization_id, 1, true);
								setCookie('access_token', res.data.token, 1, true);
								onsubmit();
							}
						})
					}
					else {
						setMainSubmitButtonState(false)
						setCookie('access_token', res.data.token, 1, true);
						window.location.href = "/quick-set-up";
					}
				})
			}
			else {
				swal("Oops!", res.data.message, "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});

		const onsubmit = () => {
			setMainSubmitButtonState(false);
			if (getCookie("lastpage") != "") {
				window.location.href = getCookie("lastpage");
			}
			else {
				window.location.href = "/dashboard"
			}
		}
	}

	// 			else if (res.data.operation == "success") {
	// 		swal("Great!", "Registration Successful ", "success", {
	// 			buttons: {
	// 				continue: {
	// 					text: "OK",
	// 					value: "continue",
	// 				},
	// 			}
	// 		}).then((result) => {
	// 			switch (result) {
	// 				case "continue":
	// 					window.location = `/login`;
	// 					break;
	// 				default:
	// 					window.location = `/login`;
	// 					break;
	// 			}
	// 		});
	// 	}
	// 	else {
	// 		if (res.data.message != "danger") {
	// 			swal("Oops!", res.data.message, "error").then((res) => {
	// 			});
	// 		}
	// 		else {
	// 			swal("Oops!", "something went wrong", "error").then((res) => {
	// 			});
	// 		}
	// 	}
	// })
	// 			.catch (function (error) {
	// 	swal("Oops!", "something went wrong", "error")
	// 		.then((res) => {
	// 			window.location.reload();
	// 		});
	// });
	// 	}


	return (
		<>
			<div className="d-flex flex-column flex-root" id="kt_app_root">
				<div className="d-flex flex-column flex-lg-row flex-column-fluid">
					<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10">
						<div className="d-flex flex-center flex-column flex-lg-row-fluid">

							<div className="w-lg-500px p-10">
								<div className="text-center mb-11">
									<h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
								</div>
								<div className="row fv-row mb-7">
									<div className="col-xl-6 my-1">
										<input className="form-control bg-transparent" type="text" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
									</div>
									<div className="col-xl-6 my-1">
										<input className="form-control bg-transparent" type="text" placeholder="Last Name" value={secondName} onChange={(e) => setSecondName(e.target.value)} />
									</div>
								</div>

								<div className="fv-row mb-8 d-flex justify-content-center align-items-center">
									<PhoneInput
										inputExtraProps={{

											required: true,
											autoFocus: true,
										}}
										country={"in"}
										value={phoneNumber}
										onChange={(val) => { setPhoneNumber(val) }}
										style={{ width: "min-content" }}
									/>
								</div>


								<div className="fv-row mb-8">
									<input className="form-control bg-transparent" type="text" placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value); }} />
								</div>

								<div className="fv-row mb-8">
									<div className="mb-1">
										<div className="position-relative mb-3">
											<input className="form-control bg-transparent" type={isPasswordVisible ? "text" : "password"} placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value); setPasswordRating(passwordChecker(e.target.value)) }} />
											<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 cursor-pointer" onClick={() => { setIsPasswordVisible(!isPasswordVisible) }}  >
												<i className={`bi fs-2 ${isPasswordVisible ? "bi-eye" : "bi-eye-slash"}`}></i>
											</span>
										</div>

										<div className="d-flex align-items-center mb-3">
											{
												(() => {
													let a = []
													if (passwordRating === 100) {
														a = ["#00774cd9", "#00774cd9", "#00774cd9", "#00774cd9"]
													}
													else if (passwordRating === 75) {
														a = ["#ffc700", "#ffc700", "#ffc700", "#959595d9"]
													}
													else if (passwordRating === 50) {
														a = ["#f27a00d9", "#f27a00d9", "#959595d9", "#959595d9"]
													}
													else if (passwordRating === 25) {
														a = ["#b60000bf", "#959595d9", "#959595d9", "#959595d9"]
													}
													else {
														a = ["#959595d9", "#959595d9", "#959595d9", "#959595d9"]
													}
													return <>
														<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[0] }}></div>
														<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[1] }}></div>
														<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[2] }}></div>
														<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[3] }}></div>
													</>
												})()
											}
										</div>
									</div>
									{passwordRating < 100 && (
										<div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
									)}
								</div>

								<div className="fv-row mb-8">
									<input className="form-control bg-transparent mb-2" placeholder="Confirm Password" value={repeatPassword} onChange={(e) => { setRepeatPassword(e.target.value) }} />
									{
										((repeatPassword !== "") && (password !== "") && (repeatPassword !== password)) ?
											<div className="text-danger">Password Mismatch.</div>
											:
											((repeatPassword !== "") && (password !== "") && (repeatPassword === password)) ?
												<div className="text-success">Password Match.</div>
												:
												<div className="text-muted">Confirm Password.</div>

									}
								</div>

								<div className="fv-row mb-8">
									<label className="form-check form-check-inline">
										<input className="form-check-input" type="checkbox" onChange={(e) => { setTermsChecker(e.target.checked) }} value={termsChecker} />
										<span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">I Accept the
											<a href="https://www.spoterp.com/terms.html" className="ms-1 link-primary">Terms</a>
										</span>
									</label>
								</div>

								<div className="d-flex justify-content-center align-items-center mb-10">
									<div className="d-grid gap-2">
										<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={(val) => { setCaptchaToken(val) }} className="btn btn-lg" size="compact" />
										<button className="btn btn-primary btn-hover-scale" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={() => { validateRegister() && register() }} >
											<span className="indicator-label">Sign up</span>
											<span className="indicator-progress">Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										</button>
									</div>
								</div>

								<div className="text-gray-500 text-center fw-semibold fs-6">Already have an Account?
									<Link to={`/login`} className="link-primary fw-semibold ms-2">Sign in</Link>
								</div>
							</div>
						</div>

						{/* <div className="d-flex flex-center flex-wrap px-5">
							<div className="d-flex fw-semibold text-primary fs-base">
								<Link to={"https://www.spoterp.com/terms"} className="ms-1 link-primary">Terms</Link>
								<Link to={"https://www.spoterp.com/pricing-plans"} className="ms-1 link-primary">Pricing Plans</Link>
								<Link to={"https://www.spoterp.com/contact"} className="ms-1 link-primary">Contact Us</Link>
							</div>
						</div> */}
					</div>

					<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(assets/media/misc/auth-bg.png)" }}>
						<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
							<a href="https://spoterp.com" target="_blank" rel="noopener noreferrer" className="mb-12">
								<img alt="Logo" src={`${process.env.REACT_APP_LOGO}`} className="h-75px" />
							</a>
							<img className="mx-auto w-275px w-md-50 w-xl-600px mb-10 mb-lg-20" src="https://cdnstatic.adoerp.com/static/spot-erp-login-screen.png" alt="" />
							<h1 className="text-white fs-2qx fw-bolder text-center mb-7">SpotERP - Ultimate Business Management Solution!</h1>
							<div className="text-white fs-base text-center">Ready to streamline your business operations like never before?
								<br />Join thousands of businesses that are maximizing their <a href="https://www.spoterp.com/erp-software-features.html" className="opacity-75-hover text-warning fw-bold me-1">productivity</a> with <a href="https://www.spoterp.com" className="opacity-75-hover text-warning fw-bold me-1">SpotERP</a>.
								<br />Signing up is quick and easy – it only takes a minute!
							</div>
						</div>
					</div>

				</div>

			</div >
		</>
	);
}
export default Signup;