import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"

import $ from 'jquery';
function VerificationAccount(props) {
	//console.log(props);
	let modal = 2;
	let [RedirectState, setRedirectState] = useState(2);

	let [captchaToken, setCaptchaToken] = useState("");
	const [value, setValue] = useState();
	const [user_id, setuser_id] = useState();
	const [verification_status, setverification_status] = useState();
	const [verification_date_time, setverification_date_time] = useState("");
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

	function onChange(value) {
		setCaptchaToken(value)
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	useEffect(() => {
		async function getverificationmailstatus() {
            var object = {};
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/check-verification-status-id`, { method: 'POST', body: JSON.stringify({"id":props.match.params.id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
			console.log(body)
			if(body.length>0)
			{
				setverification_status(body[0].verification_flag);
				setuser_id(body[0].user_id);
				setverification_date_time(body[0].log_date_time);
			}
        }
        getverificationmailstatus();        
	}, [])

	const verify_email = (e) => {

		e.preventDefault();
		var object = {};
		object.id=props.match.params.id;
		setMainSubmitButtonState(true)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/verify-email`, object).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data.operation == "success") {
				swal("Great!", "Your email is successfully verified. ", "success", {
					buttons: {
						continue: {
							text: "OK",
							value: "continue",
						},
					}
				}).then((result) => {
					//console.log(result);
					switch (result) {
						case "continue":
							window.location = `/login`;
							break;
						default:
							window.location = `/login`;
							break;

					}
				});
			}
			else {
				if (res.data.message != "danger") {
					swal("Oops!", res.data.message, "error").then((res) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "something went wrong", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
	}

	


	return (
		<>
			{
				(RedirectState == 1) ? ((getCookie("lastpage") != "") ? window.location.href = getCookie("lastpage") : window.location.href = "/"
				)
					:
					null
			}
			<div className="d-flex flex-column flex-root" id="kt_app_root">
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{"background-image":"url(/assets/media/auth/bg5.jpg)"}}>
					<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
						<a href="../../demo1/dist/index.html" className="mb-12">
							<img alt="Logo" src="https://cdnstatic.adocrm.com/static/ado-crm-logo.png" className="h-75px" />
						</a>
						<div class="d-flex flex-column flex-center text-center p-10">
							<div class="card card-flush w-md-650px py-5">
								<div class="card-body py-15 py-lg-20">
									
									{/*<div class="mb-7">
										<a href="/metronic8/demo1/../demo1/index.html" class="">
											<img alt="Logo" src="/assets/media/logos/custom-2.svg" class="h-40px"/>
										</a>
									</div>
									
									<h1 class="fw-bolder text-gray-900 mb-5">Welcome to Metronic</h1>*/}
									
									{verification_status=="not verified" &&
							<div className="pt-lg-10 mb-10">
								<h1 className="fw-bolder fs-2qx text-gray-800 mb-7">Verify Your Email</h1>
								<div className="fs-3 fw-bold text-muted mb-10">We have sent an email to
								<a href="#" className="link-primary fw-bolder"> {user_id}</a>
								<br />pelase follow a link to verify your email.</div>
								
								<div className="text-center mb-10">
									<a className="btn btn-lg btn-primary fw-bolder" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => { verify_email(e) }}>Verify Your Email</a>
								</div>
								
								<div className="fs-5">
									<span className="fw-bold text-gray-700">Did’t receive an email ? you can resend verification mail from your <a href="/dashboard">dashboard</a> page.</span>
									
								</div>
							</div>
							}	
								</div>
							</div>
						</div>
						<h1 className="text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
						
						<div className="text-white fs-base text-center">In this kind of post,
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
						<br />and provides some background information about
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
						<br />work following this is a transcript of the interview.</div>
			{/*<div id="kt_body" className="auth-bg">
				<div className="d-flex flex-column flex-root">
					<div className="d-flex flex-column flex-column-fluid">
						<div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-15">
							<a href="../../demo1/dist/index.html" className="mb-10 pt-lg-10">
								<img alt="Logo" src="assets/media/logos/logo-1.svg" className="h-40px mb-5" />
							</a>
							
							{verification_status=="not verified" &&
							<div className="pt-lg-10 mb-10">
								<h1 className="fw-bolder fs-2qx text-gray-800 mb-7">Verify Your Email</h1>
								<div className="fs-3 fw-bold text-muted mb-10">We have sent an email to
								<a href="#" className="link-primary fw-bolder"> {user_id}</a>
								<br />pelase follow a link to verify your email.</div>
								
								<div className="text-center mb-10">
									<a className="btn btn-lg btn-primary fw-bolder" onClick={(e) => { verify_email(e) }}>Verify Your Email</a>
								</div>
								
								<div className="fs-5">
									<span className="fw-bold text-gray-700">Did’t receive an email ? you can resend verification mail from your <a href="/dashboard">dashboard</a> page.</span>
									
								</div>
							</div>
							}
							<div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px" style={{"background-image": "url(assets/media/illustrations/sketchy-1/17.png"}}></div>
						</div>
						<div className="d-flex flex-center flex-column-auto p-10">
							<div className="d-flex align-items-center fw-bold fs-6">
								<a href="https://keenthemes.com" className="text-muted text-hover-primary px-2">About</a>
								<a href="mailto:support@keenthemes.com" className="text-muted text-hover-primary px-2">Contact</a>
								<a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2">Contact Us</a>
							</div>
						</div>
					</div>
				</div>
			</div>*/}
			</div>
					</div>
				</div>
			</div>
			{/*<div className="bg-dark header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" style={{ "--kt-toolbar-height": "55px", "--kt-toolbar-height-tablet-and-mobile": "55px" }} >
				<div className="d-flex flex-column flex-root">
					<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ "background-size1": "100% 50%", "background-image": "url(assets/media/misc/outdoor.png)" }}>
						<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
							<a href="/login" className="mb-12">
								<img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-45px" />
							</a>
							<div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
								<form className="form w-100" novalidate="novalidate" id="kt_sign_up_form" onSubmit={(e) => { verify_email(e) }}>

									<div className="mb-10 fv-row" data-kt-password-meter="true">
										<div className="mb-1">
											<label className="form-label fw-bolder text-dark fs-6">New Password</label>
											<div className="position-relative mb-3">
												<input className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="password" id="password" autocomplete="off" />
												<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
													<i className="bi bi-eye-slash fs-2"></i>
													<i className="bi bi-eye fs-2 d-none"></i>
												</span>
											</div>
										</div>
										<div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
									</div>
									<div className="fv-row mb-5">
										<label className="form-label fw-bolder text-dark fs-6">Confirm Password</label>
										<input className="form-control form-control-lg form-control-solid" type="password" id="cpassword" placeholder="" name="confirm-password" autocomplete="off" />
									</div>
									<div className="fv-row mb-10">
										<label className="form-check form-check-custom form-check-solid">
											<input className="form-check-input" id="agreement" type="checkbox" name="toc" value="1" />
											<span className="form-check-label fw-bold text-gray-700 fs-6">I Agree &amp;
												<a className="ms-1 link-primary">Terms and conditions</a>.</span>
										</label>
									</div>
									<div className="text-center">
										
										<button type="button" id="kt_sign_up_submit" className="btn btn-lg btn-primary rounded" onClick={(e) => { verify_email(e) }}>
											<span className="indicator-label">Submit</span>
											<span className="indicator-progress">Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="d-flex flex-center flex-column-auto p-10">
							<div className="d-flex align-items-center fw-bold fs-6">

							</div>
						</div>
					</div>
				</div>
			</div>*/}
		</>
	);
}
export default VerificationAccount;