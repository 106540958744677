import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveBar } from '@nivo/bar'
import { barConfig, pieConfig } from '../common_component/nivoGraphConfiguration.js';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import formatNumber from '../common_component/formatNumber.js';
import { ReportWidgetWrapper, ReportWidgetItem } from '../common_component/ReportWidgets.js';

function ExpenseSummary() {

    const [state, setstate] = useState(2);
    const [expenseReportList, setExpenseReportList] = useState([])
    const [filteredCategoryWiseExpenseList, setFilteredCategoryWiseExpenseList] = useState([])
    const [categoryWiseExpenseList, setCategoryWiseExpenseList] = useState([])
    const [expenseReportApiState, setExpenseReportApiState] = useState(false)

    const [barData, setBarData] = useState([]);

    const daterange_picker_ref = useRef()
    const apiController = useRef(new AbortController())

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(30, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [baseCurrency, setBaseCurrency] = useState(null)
    const isSameMonth = new Date(startDate).getFullYear() === new Date(endDate).getFullYear() && new Date(startDate).getMonth() === new Date(endDate).getMonth();

    var page_name = "expense-summary";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }


    async function getExpenseReport(sd, ed) {
        try {
            if (expenseReportApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setExpenseReportApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-expense-summary-report`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation !== "success") throw "get Expense Report api error"
            barGraphSetup(body.summary);
            setExpenseReportList(body.summary)
            setCategoryWiseExpenseList(body.totalExpenses)
            setListCount(body.totalExpenses.length)
            setExpenseReportApiState(false)
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBaseCurrency() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        // console.log(body)
        if (body.length > 0) {
            setBaseCurrency(body[0])
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getExpenseReport(startDate, endDate)
            let p2 = getBaseCurrency()

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getExpenseReport(startDate, endDate)
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (categoryWiseExpenseList.length > 0) {
            let expense_details = [...categoryWiseExpenseList];
            let start = (currentPage - 1) * rowsPerPage
            let end = start + rowsPerPage
            if (searchValue) {
                expense_details = expense_details.filter(x =>
                    new RegExp(searchValue, "i").test(x.category) ||
                    new RegExp(searchValue, "i").test(x.totalAmount)
                )
            }
            setFilteredCategoryWiseExpenseList(expense_details.slice(start, end));
        }
    }, [categoryWiseExpenseList, currentPage, rowsPerPage, searchValue])

    function groupByDayOrMonthYear(data) {
        const groupedData = data.reduce((groups, item) => {
            const key = item.day ? item.day : `${item.month}-${item.year}`; // Format key using 'YYYY-MM' for month and year if desired

            groups[key] = groups[key] || { "Vendor": 0, "Organization": 0, "Employee": 0, "Manufacturer": 0, "Customer": 0 }; // Initialize group object if not present
            groups[key][item.expenseFor == "Vendor" ? 'Vendor' : item.expenseFor == "Employee" ? 'Employee' : item.expenseFor == "Manufacturer" ? 'Manufacturer' : item.expenseFor == "Own Expense" ? 'Organization' : 'Customer'] = item.totalAmount; // Add totalAmount for each expense category

            return groups;
        }, {});
        //console.log(groupedData);
        const arrayOfObjects = Object.entries(groupedData).map(([day, value]) => ({
            key: (day), // Ensure day is a number (if needed)
            ...value, // Spread the remaining properties from value
        }));
        return arrayOfObjects;
    }

    const barGraphSetup = (data) => {
        let groupedData = [...groupByDayOrMonthYear(data)];

        const formattedStartDate = isSameMonth ? moment(startDate).format('MMM') : null;
        groupedData.forEach((obj) => {
            const keyParts = obj.key.split('-');
            let formattedKey = obj.key;

            // Check for year in the key (scenario 2)
            if (keyParts.length === 2 && keyParts[1].length === 4) {
                formattedKey = moment(keyParts[0], 'M').format('MMM') + ',' + moment(keyParts[1]).format('YY');
            } else if (formattedStartDate) { // Scenario 1: use start date for formatting
                formattedKey = keyParts[0] + ' ' + formattedStartDate;
            }
            obj.key = formattedKey
        })
        console.log(groupedData);
        setBarData(groupedData);
    }

    const customBarConfig = {
        ...barConfig,
        keys: ["Customer", "Employee", "Manufacturer", "Oraganization", "Vendor"],
        margin: { ...barConfig.margin, bottom: 80 }, // Adjust margin
        indexBy: "key",
        colors: { scheme: 'pastel1' }, // Override color scheme
    };

    const customPieConfig = {
        ...pieConfig,
        colors: { scheme: 'pastel1' }, // Override color scheme
    };

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">All Expense Summary</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <div className="m-0">
                            <DateRangePickerComponent
                                ref={daterange_picker_ref}
                                allowEdit={false}
                                showClearButton={false}
                                focus={() => { daterange_picker_ref.current.show() }}
                                delayUpdate={true}
                                presets={[
                                    { label: 'Today', start: new Date(), end: new Date() },
                                    { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                    { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                    { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                ]}
                                change={(e) => {
                                    setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                    setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                }}
                                startDate={new Date(startDate)}
                                endDate={new Date(endDate)}
                                max={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-header border-0 pt-5">
                                        <ReportWidgetWrapper>
                                            <ReportWidgetItem
                                                icon={<i className="bi bi-clipboard-data-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                                label={"Total Expenses"}
                                                value={baseCurrency.currency_symbol + formatNumber(filteredCategoryWiseExpenseList.reduce((p, o) => p + o.totalAmount, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                                hoverValue={baseCurrency.currency_symbol + formatNumber(filteredCategoryWiseExpenseList.reduce((p, o) => p + o.totalAmount, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            />
                                        </ReportWidgetWrapper>
                                    </div>

                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                expenseReportApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    filteredCategoryWiseExpenseList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-120px">Category Name</th>
                                                                            <th className="min-w-120px">Expense Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            filteredCategoryWiseExpenseList.map((obj, indx3) => {
                                                                                return (
                                                                                    <tr key={indx3}>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.category}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{baseCurrency.currency_symbol + formatNumber(obj.totalAmount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {!expenseReportApiState &&
                                    <div className="row gy-5 g-xl-10">
                                        <div className="col-xl-8 mb-5 mb-xl-10">
                                            <div className="card card-flush h-xl-100">
                                                <div className="card-header pt-7">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bold text-gray-800">{isSameMonth ? <>Date</> : <>Month</>} Wise Expenses ({moment(startDate).format("ll")} - {moment(endDate).format("ll")})</span>
                                                    </h3>
                                                </div>
                                                <div className="card-body overflow-auto">
                                                    <div style={{ width: "775px", height: "400px" }}>
                                                        <ResponsiveBar
                                                            data={barData}
                                                            {...customBarConfig}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 mb-5 mb-xl-10">
                                            <div className="card card-flush h-xl-100">
                                                <div className="card-header pt-7">
                                                    <h3 className="card-title align-items-start flex-column">
                                                        <span className="card-label fw-bold text-gray-800">Top Expenses</span>
                                                    </h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className='d-flex align-items-center justify-content-center' style={{ height: "500px" }}>
                                                        <ResponsivePie
                                                            data={
                                                                categoryWiseExpenseList.reduce((p, o, i, arr) => {
                                                                    p.push({
                                                                        "id": o.category,
                                                                        "label": o.category,
                                                                        "value": o.totalAmount
                                                                    })
                                                                    return p
                                                                }, []).filter(x => x.value !== 0).toSorted((a, b) => b.value - a.value).slice(0, 7)
                                                            }
                                                            {...customPieConfig}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>
        </>
    );
}

export default ExpenseSummary;