import React, { useState, useEffect, useRef, useMemo } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Pagination } from "antd";

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import CurrencyModal from '../common_component/CurrencyModal.js';
import PageError from '../common_component/PageError.js';
import StatusBadge from "../common_component/StatusBadge.js";

export const CurrencyList = () => {
    const [state, setstate] = useState(2);
    const [currencyList, setCurrencyList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [currencyUpdateMode, setCurrencyUpdateMode] = useState(false)
    const [currencyData, setCurrencyData] = useState(null)
    const [disabledFields, setDisabledFields] = useState([])

    var page_name = "currency-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getCurrencyList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setstate(1);
            setCurrencyList(body.data);
        }
        else {
            setstate(0);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            getCurrencyList();
        }
    }, [user, visibility, read, write])

    const updateStatus = (currency_id, status) => {
        let values = { "id": currency_id, "status": status, "page_name": page_name, "organization_id": organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-currency`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                getCurrencyList()

                swal("Great!", "Currency details deleted successfully!", "success")
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const filteredList = useMemo(() => {
        if (searchValue) {
            return currencyList.filter((x) => {
                let r = new RegExp(searchValue, "gi")
                if (r.test(x.currency_code) ||
                    r.test(x.currency_symbol) 
                ) {
                    return x
                }
            })
        }
        else {
            return currencyList
        }
    }, [currencyList, searchValue])

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Currency List</h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            {write && <button className="btn btn-sm btn-primary btn-hover-scale" onClick={() => { setCurrencyModalShow(true); }}>New Currency</button>}
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className='mb-5'>
                                                <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                            </div>

                                            <div className="mb-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    showQuickJumper={true}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                />
                                            </div>
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Currency Name</th>
                                                            <th className="min-w-150px">Symbol</th>
                                                            <th className="min-w-150px">Status</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            filteredList.length > 0 ?
                                                                filteredList.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage).map((obj) => {
                                                                    return (
                                                                        <tr key={obj.currency_id}>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary me-5 mb-1 fs-6">{obj.currency_code}</span>
                                                                                {obj.default_currency === 1 && <span className='badge badge-primary'>DEFAULT</span>}
                                                                            </td>
                                                                            <td>
                                                                                <span className="badge badge-light-info">{obj.currency_symbol}</span>
                                                                            </td>
                                                                            <td>
                                                                                <StatusBadge status={obj.status} entity={""} />
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <div className="dropdown" style={{ position: "unset" }}>
                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="fas fa-bars px-0"></i>
                                                                                    </button>
                                                                                    {
                                                                                        write &&
                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                            <li><span className="dropdown-item cursor-pointer" onClick={() => {
                                                                                                if (obj.default_currency === 1) {
                                                                                                    setDisabledFields(["currency_code"]);
                                                                                                }
                                                                                                setCurrencyData(obj);
                                                                                                setCurrencyUpdateMode(true);
                                                                                                setCurrencyModalShow(true);
                                                                                            }} >Edit details</span></li>
                                                                                            <li><span className="dropdown-item cursor-pointer" onClick={() => {
                                                                                                swal({
                                                                                                    title: `Are you sure? Current Status: ${obj.status}`,
                                                                                                    icon: "warning",
                                                                                                    buttons: true,
                                                                                                    dangerMode: true,
                                                                                                })
                                                                                                    .then((val) => {
                                                                                                        if (val) {
                                                                                                            updateStatus(obj.currency_id, obj.status === "Active" ? "Deactivated" : "Active")
                                                                                                        }
                                                                                                    })
                                                                                            }} >Change Status</span></li>
                                                                                        </ul>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={4} className="text-center">
                                                                        <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="mt-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={filteredList.length}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <PageError />
                        }
                    </div>
                </div>
            </div>

            <CurrencyModal
                currencyModalShow={currencyModalShow}
                setCurrencyModalShow={setCurrencyModalShow}
                pageName={page_name}
                apiCallback={() => { getCurrencyList(); }}
                modalCloseCallback={() => { setCurrencyUpdateMode(false); setCurrencyData(null); setDisabledFields([]); }}
                updateMode={currencyUpdateMode}
                currencyData={currencyData}
                disabledFields={disabledFields}
            />
        </>
    )
};

export default CurrencyList;
