import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';
import { Modal } from "react-bootstrap";

// import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import StatusBadge from '../common_component/StatusBadge.js';
import flow_img from '../flow_images/Customer_order_flow.jpg';

function SalesReturnList() {

    const [state, setstate] = useState(2);
    const apiController = useRef(new AbortController())

    const daterange_picker_ref = useRef()
    const [salesReturnList, setSalesReturnList] = useState([])
    const [salesReturnListApiState, setSalesReturnListApiState] = useState(false)

    // const { theme, dropdownStyleDark } = useTheme()

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(30, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))

    const [itemDetailsModalShow, setItemDetailsModalShow] = useState(false)
    const [itemsData, setItemsData] = useState([])

    const [flowModalShow, setFlowModalShow] = useState(false)

    var page_name = "sales-return-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getSalesReturnList(cp, rpp, search_v, sd, ed) {
        try {
            if (salesReturnListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setSalesReturnListApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-sales-return-list`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation !== "success") throw "get Sales Report api error"

            setSalesReturnList(body.data.list)
            setListCount(body.data.count)

            setSalesReturnListApiState(false)
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getSalesReturnList(currentPage, rowsPerPage, searchValue, startDate, endDate)
            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getSalesReturnList(currentPage, rowsPerPage, searchValue, startDate, endDate)
        }
    }, [currentPage, rowsPerPage, searchValue, startDate, endDate])

    return (
        <>
            <div className="">
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Sales Return List</h1>
                    <div className="d-flex align-items-center gap-lg-10">
                        {/* <button className="btn p-0 mx-3 btn-hover-scale">{true ? <i className="bi bi-pin-fill fs-1 px-0"></i> : <i className="bi bi-pin fs-1 px-0"></i>}</button> */}
                        <Link to={`/create-sales-return`} className="btn btn-sm btn-primary me-3 rounded btn-hover-scale">
                            <span className="d-md-none">
                                <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                    <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff"></path>
                                </svg>
                            </span>
                            <span className="d-none d-md-inline">Create Sales Return</span>
                        </Link>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <DateRangePickerComponent
                                            ref={daterange_picker_ref}
                                            allowEdit={false}
                                            showClearButton={false}
                                            focus={() => { daterange_picker_ref.current.show() }}
                                            delayUpdate={true}
                                            presets={[
                                                { label: 'Today', start: new Date(), end: new Date() },
                                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                            ]}
                                            change={(e) => {
                                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                            }}
                                            startDate={new Date(startDate)}
                                            endDate={new Date(endDate)}
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                </div>

                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                salesReturnListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    salesReturnList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px">Invoice Number</th>
                                                                            <th className="min-w-150px">Return Number</th>
                                                                            <th className="min-w-150px">Return Item Count</th>
                                                                            <th className="min-w-150px">Item Details</th>
                                                                            <th className="min-w-150px">Date Added</th>
                                                                            <th className="min-w-150px">Status</th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            salesReturnList.map((obj, indx3) => {
                                                                                return (
                                                                                    <tr key={indx3}>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.customer_invoice_number}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.sales_return_number}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.item_array.length}</span></td>
                                                                                        <td><button className="text-dark fw-bold d-block btn btn-light-success btn-sm fs-5" onClick={() => { setItemDetailsModalShow(true); setItemsData(obj.item_array) }}>view</button></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{moment(obj.add_date_time).format("ll")}</span></td>
                                                                                        <td><StatusBadge status={obj.add_status} entity={"SALES_RETURN"} /></td>
                                                                                        <td className='sticky-column'>
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                                                                                                {
                                                                                                    write &&
                                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                        <li className="py-1"><Link className="dropdown-item" to={`/view-sales-return-details/${obj.sales_return_id}`}>View Details</Link></li>
                                                                                                        {(obj.add_status === "SALES_RETURN_PENDING_APPROVAL" || obj.add_status === "SALES_RETURN_DRAFT") && <li className="py-1"><Link className="dropdown-item" to={`/edit-sales-return/${obj.sales_return_id}`}>Edit</Link></li>}
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div >

            <Modal show={itemDetailsModalShow} onHide={() => { setItemDetailsModalShow(false); setItemsData([]); }} dialogClassName="h-modal-60" centered>
                <Modal.Header className='py-1'>
                    <Modal.Title>Item Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setItemDetailsModalShow(false); setItemsData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <table className="table table-bordered table-striped">
                            <thead className='bg-light-info fs-4 fw-bold text-primary'>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Item Type</th>
                                    <th>Related Challans</th>
                                    <th>Total Return Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemsData.map((obj, indx) => (
                                        <tr key={indx}>
                                            <td><span className='fw-bold'>{obj.item_name}</span></td>
                                            <td><span className='fw-bold'>{obj.item_stock_type}</span></td>
                                            <td><span className='fw-bold'>{obj.challan_info.length}</span></td>
                                            <td><span className='fw-bold'>{obj.challan_info.reduce((p, o) => p + o.returned_quantity, 0)}</span></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>
                </Modal.Body>
            </Modal >

            <Modal show={flowModalShow} onHide={() => { setFlowModalShow(false) }} dialogClassName="h-modal-80">
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-gray-500'>Order Flow Cycle</span>
                    </Modal.Title>

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFlowModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <img src={flow_img} className='w-100' alt="Not yet provided" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setFlowModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SalesReturnList;