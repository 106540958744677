import React, { useState, useEffect, useRef } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import axios from 'axios';

import { getCookie } from '../src/common_component/cookie.js';

import Dashboard from './includes/dashboard.js';
import AdminDashboard from './includes/admin-dashboard.js';
import Signin from './includes/sign-in.js';
import Signup from './includes/sign-up.js';
import QuickSetUp from './includes/quick-set-up.js';
import BankReport from './includes/bank-report.js';
import Reports from './includes/reports.js';

import ForgotPassword from "./includes/forgot-password.js";
import FirstStepForgotPassword from "./includes/first-step-forgot-password.js";

import VerificationAccount from "./includes/verification-account.js";

import CreateAccount from "./includes/create-account.js";
import FirstStepInvitation from "./includes/first-step-invitation.js";

import NotFound from "./includes/NotFound.js";
import UnAuthorized from "./includes/unAuthorized.js";

import AsideNavbar from "./includes/aside-navbar.js";
import Header from "./includes/header.js";
import Footer from "./includes/footer.js";

import GstSettings from "./includes/gst-settings.js";
import Approvals from "./includes/approvals.js";
import Settings from "./includes/settings.js";

import NotificationSettings from "./includes/notification_settings.js";

import TaxList from "./includes/tax-list.js";
import TaxGroupList from "./includes/tax-group-list.js";

import AccountCategory from "./includes/account-category.js";
import AccountHead from "./includes/account-head.js";

import SetUpPaymentConfiguration from './includes/set-up-payment-configuration.js';

import AddUser from "./includes/add-user";
import ShowUsers from "./includes/show-users.js";

import OrganizationProfile from './includes/organization-profile.js';
import OrganizationSettings from './includes/organization-settings.js';
import AccountOverview from './includes/account-overview.js';

import Roles from "./includes/add-roles.js";
import ShowRoles from "./includes/show-roles.js";
import UpdateRoles from "./includes/update-roles.js";

import AddProfile from "./includes/add-profile.js";
import ShowProfiles from "./includes/show-profiles.js";
import UpdateProfiles from "./includes/update-profile.js";

import CurrencyList from "./includes/currency-list.js";
import ShowOrganizations from "./includes/show-organizations.js";
import AllTaxList from "./includes/all-tax-list.js";

import ShowCategory from "./includes/category-list.js";
import ShowSubCategory from "./includes/subcategory-list.js";

import AddItem from "./includes/add-item.js";
import EditItem from "./includes/edit-item.js";
import ItemList from "./includes/item-list.js";
import ItemPriceList from "./includes/item-price-list.js";

import ItemImages from "./includes/item-images.js";
import ItemPriceInformation from "./includes/item-price-information.js";
import ItemStockApproval from "./includes/item-stock-approval.js";
import ItemStockInformation from "./includes/item-stock-information.js";
import EditItemStockInformation from "./includes/edit-item-stock-information.js";
import BulkUpdateItemPriceInformation from "./includes/bulk-update-item-price-information.js";

import CreatePaymentReceived from "./includes/create-payments-received.js";
import ReceivedPaymentList from "./includes/payment-received-list.js";
import EditPaymentReceived from "./includes/edit-payment-received.js";

import CreateCreditNote from "./includes/create-credit-note.js";
import CreditNoteList from "./includes/credit-note-list.js";
import EditCreditNote from "./includes/edit-credit-note.js";

import CreateCustomerOrder from "./includes/create-customer-order.js";
import ViewCustomerOrder from "./includes/view-customer-order.js";
import EditCustomerOrder from "./includes/edit-customer-order.js";
import CustomerOrderList from "./includes/customer-order-list.js";

import CreateCustomerInvoice from "./includes/create-customer-invoice.js";
import ViewCustomerInvoice from "./includes/view-customer-invoice.js";
import EditCustomerInvoice from "./includes/edit-customer-invoice.js";
import CustomerInvoiceList from "./includes/customer-invoice-list.js"

import CreateRecurringInvoice from "./includes/create-customer-recurring-invoice.js";
import ViewCustomerRecurringInvoice from "./includes/view-customer-recurring-invoice.js";
import CustomerRecurringInvoiceList from "./includes/customer-recurring-invoice-list.js";

import CustomerWarehouseProcessingList from "./includes/customer-warehouse-processing-list.js";
import CustomerWarehouseProcessing from "./includes/customer-warehouse-processing.js";

import CustomerDeliveryChallanList from "./includes/customer-delivery-challan-list.js";
import CustomerDeliveryChallan from "./includes/create-customer-delivery-challan.js";

import CreateDealerEwayBill from "./includes/create-dealer-e-way-bill.js";
import DealerEwayBillList from "./includes/dealer-e-way-bill-list.js";

import MakePayment from "./includes/make-payment.js";
import PaymentList from "./includes/payment-list.js";
import EditPaymentDetails from "./includes/edit-payment-details.js";

import Banking from "./includes/banking.js";
import OpeningBalance from "./includes/opening-balance.js";
import AccountReport from "./includes/account-report.js";

import Warehouse from './includes/warehouse.js';

import Customer from "./includes/add-customer.js";
import ShowCustomer from "./includes/show-customer.js";
import EditCustomerDetails from "./includes/edit-customer-details.js";
import CustomerAddressDetails from "./includes/customer-address-details.js";

import Branches from "./includes/branches.js";
import TransactionSeries from "./includes/transaction-series.js";
import Brands from "./includes/brands.js";

import AddManufacturer from "./includes/add-manufacturer.js";
import UpdateManufacturer from "./includes/update-manufacturer.js";
import ListManufacturer from "./includes/list-manufacturer.js";
import ManufacturerAddressDetails from "./includes/manufacturer-address-details.js";

import AddVendor from "./includes/add-vendor.js";
import UpdateVendor from "./includes/update-vendor.js";
import ListVendor from "./includes/list-vendor.js";
import VendorAddressDetails from "./includes/vendor-address-details.js";

import ListDepartment from "./includes/department-list.js";

import SalesManager from "./includes/sales-manager.js";

import AddExpenseDetails from "./includes/add-expense.js";
import ExpenseList from "./includes/expense-list.js";
import RecurringExpenseList from "./includes/recurring-expense-list.js";
import EditExpenseDetails from "./includes/edit-expense-details.js";

import ExpenseSummary from "./includes/expense-summary.js";
import OrganizationExpenseReport from "./includes/organization-expense-report.js";
import CustomerExpenseReport from "./includes/customer-expense-report.js";
import VendorExpenseReport from "./includes/vendor-expense-report.js";
import ManufacturerExpenseReport from "./includes/manufacturer-expense-report.js";
import EmployeeExpenseReport from "./includes/employee-expense-report.js";
import PaymentReceivedReport from "./includes/payment-received-report.js";
import PaymentsMade from "./includes/payments-made.js";
import CreditNoteReport from "./includes/credit-note-report.js";

import ConfigureItemGroup from "./includes/configure-item-group.js";
import ItemGroupList from "./includes/item-group-list.js";
import ConfigureItemCollection from "./includes/configure-item-collection.js";
import ItemCollectionList from "./includes/item-collection-list.js";

import CreatePurchaseOrder from "./includes/create-purchase-order.js";
import EditPurchaseOrder from "./includes/edit-purchase-order.js";
import ViewPurchaseOrder from "./includes/view-purchase-order.js";
import PurchaseOrderList from "./includes/purchase-order-list.js";
import PurchaseOrderApprovalEmail from "./includes/purchase-order-approval-email.js";
import PoExternalConfirmation from "./includes/po-external-confirmation-preview.js";

import CreateGoodsReceive from "./includes/create-goods-receive.js";
import ViewGoodsReceive from "./includes/view-goods-receive.js";
import EditGoodsReceive from "./includes/edit-goods-receive.js";
import GoodsReceivedList from "./includes/goods-received-list.js";

import CreateBill from "./includes/create-bill.js";
import ViewBill from "./includes/view-bill.js";
import EditBill from "./includes/edit-bill.js";
import BillList from "./includes/bill-list.js";

import CreateSalesReturn from "./includes/create-sales-return.js";
import SalesReturnList from "./includes/sales-return-list.js";
import ViewSalesReturnDetails from "./includes/view-sales-return-details.js";
import EditSalesReturn from "./includes/edit-sales-return.js";

import ItemSalesReport from "./includes/item-sales-report.js";
import ItemGrReport from "./includes/item-gr-report.js";
import ItemBillReport from "./includes/item-bill-report.js";
import CustomerSalesReport from "./includes/customer-sales-report.js";
import PurchaseReport from "./includes/purchase-report.js";
import BillReport from "./includes/bill-report.js";
import CustomerBalancesReport from "./includes/customer-balances-report.js";
import VendorBalancesReport from "./includes/vendor-balances-report.js";
import ManufacturerBalancesReport from "./includes/manufacturer-balances-report.js";
import TimeToGetPaidReport from "./includes/time-to-get-paid-report.js";
import InventorySummary from "./includes/inventory-summary.js";
import CommittedStockDetails from "./includes/committed-stock-details.js";
import DayBookReport from "./includes/day-book-report.js";

import { AuthProvider, useAuthData } from "./includes/auth-provider"
import { ManagedModal, ModalProvider } from "./providers/modal-provider.js";


export default function BasePage() {

	return (
		<>
			<Switch>
				<Route exact path={"/login"} component={Signin} />
				<Route exact path={"/register"} component={Signup} />
				<Route exact path={"/quick-set-up"} component={QuickSetUp} />
				<Route exact path={"/quick-set-up/:id"} component={QuickSetUp} />
				<Route exact path={"/verification-account/:id"} component={VerificationAccount} />
				<Route exact path={"/forgot-password/:id"} component={ForgotPassword} />
				<Route exact path={"/forgot-password-email"} component={FirstStepForgotPassword} />
				<Route exact path={"/create-account/:id"} component={CreateAccount} />
				<Route exact path={"/first-step-invitation"} component={FirstStepInvitation} />
				<Route exact from="/not-found" component={NotFound} />
				<Route exact path={"/unauthorized"} component={UnAuthorized} />
				<Route exact path={"/po-external-confirmation-preview/:id"} render={(props) => <PoExternalConfirmation {...props} />} />

				<AuthProvider>
					<ModalProvider>
						<Route component={DefaultContainer} />
						<ManagedModal />
					</ModalProvider>
				</AuthProvider>
			</Switch>
		</>
	);
}

const DefaultContainer = () => {
	const [asideNavbarExpandMobile, setAsideNavbarExpandMobile] = useState(false);
	const asideNavbarRef = useRef();
	const [organizationId, setOrganizationId] = useState(null)

	const [checkinSettings, setCheckinSettings] = useState(null)
	const [userRole, setUserRole] = useState(null)
	// const [geolocationPermission, setGeolocationPermission] = useState(false)

	const [userTracking, setUserTracking] = useState(false)

	const siRef = useRef()

	const { user } = useAuthData("dashboard")


	useEffect(() => {
		setOrganizationId(getCookie("organization_id"))
	}, [])

	async function getCheckinSettings() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-checkin-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": "dashboard", "organization_id": organizationId }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body)

		if (body.operation === "success") {
			setCheckinSettings(body.data)
		}
	}

	async function getUserRole() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/individual-user-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "unique_id": "", "page_name": "dashboard", "organization_id": organizationId }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body)

		if (body.operation === "success") {
			setUserRole(body.data[0].user_role)
		}
	}

	useEffect(() => {
		if ((user != null) && (organizationId != null)) {
			getCheckinSettings()
			getUserRole()
		}
	}, [user, organizationId])


	useEffect(() => {
		if ((checkinSettings != null) && (userRole != null)) {

			let flag = false

			if ((checkinSettings.user_tracking.tracking_type === "INDIVIDUAL") && (checkinSettings.user_tracking.tracking_value.includes(user.email))) {
				flag = true
			}
			else if ((checkinSettings.user_tracking.tracking_type === "GROUP") && (checkinSettings.user_tracking.tracking_value.includes(userRole))) {
				flag = true
			}

			if (flag) {
				navigator.permissions.query({ name: "geolocation" }).then((result) => {
					if (result.state === "prompt") {
						navigator.geolocation.getCurrentPosition(() => {
							setUserTracking(true)
						})
					}
					else if (result.state === "granted") {
						setUserTracking(true)
					}
				})
			}
		}
	}, [checkinSettings, userRole])

	useEffect(() => {
		if ((user != null) && (organizationId != null) && (userTracking)) {
			siRef.current = setInterval(() => {
				// console.log(siRef.current)
				navigator.geolocation.getCurrentPosition((val) => {

					let object = {}
					object.coordinates = {
						accuracy: val.coords.accuracy,
						altitude: val.coords.altitude,
						altitudeAccuracy: val.coords.altitudeAccuracy,
						heading: val.coords.heading,
						latitude: val.coords.latitude,
						longitude: val.coords.longitude,
						speed: val.coords.speed
					}
					object.page_name = "dashboard"
					object.organization_id = organizationId

					// console.log(object);

					var headers = {
						headers: {
							"Content-Type": "application/json",
							"Accept": "*/*",
							"token": getCookie("access_token")
						}
					}
					axios.post(`${process.env.REACT_APP_SERVER_URL}/save-user-location`, object, headers).then((res) => {
						if (res.data.operation !== "success") {
							console.log(res.data.message);
						}
					})
						.catch(function (error) {
							console.log("Save User Location error: ", error)
						});
				}, (e) => {
					// console.log(e)
				}, { enableHighAccuracy: true })
			}, process.env.REACT_APP_USER_TRACKING_INTERVAL * 1000)
		}

		return () => { clearInterval(siRef.current) }
	}, [user, organizationId, userTracking])


	return (
		<>
			<div className="d-flex position-relative" style={{ maxHeight: "100vh", height: "100vh" }}>

				<AsideNavbar asideNavbarExpandMobile={asideNavbarExpandMobile} setAsideNavbarExpandMobile={setAsideNavbarExpandMobile} asideNavbarRef={asideNavbarRef} />
				<div className="flex-column flex-row-fluid">
					<Header setAsideNavbarExpandMobile={setAsideNavbarExpandMobile} asideNavbarRef={asideNavbarRef} />
					<div className="flex-column flex-row-fluid p-5" style={{ maxHeight: "80%", height: "80%", overflowY: "auto" }}>
						<Switch>
							<Route exact path={"/dashboard"} component={Dashboard} />
							<Route exact path={"/admin-dashboard"} component={AdminDashboard} />

							<Route exact path={"/gst-settings"} component={GstSettings} />
							<Route exact path={"/settings"} component={Settings} />

							<Route exact path={"/notification-settings"} component={NotificationSettings} />

							<Route exact path={"/approvals"} component={Approvals} />
							<Route exact path={"/bank-report"} component={BankReport} />
							<Route exact path={"/reports"} component={Reports} />

							<Route exact path={"/tax-list"} component={TaxList} />
							<Route exact path={"/tax-group-list"} component={TaxGroupList} />
							<Route exact path={"/warehouse"} component={Warehouse} />
							<Route exact path={"/account-category"} component={AccountCategory} />
							<Route exact path={"/account-head"} component={AccountHead} />

							<Route exact from="/organization-profile" component={OrganizationProfile} />
							<Route exact from="/organization-settings" component={OrganizationSettings} />
							<Route exact from="/account-overview" component={AccountOverview} />

							<Route exact path={"/make-payment"} component={MakePayment} />
							<Route exact path={"/payment-list"} component={PaymentList} />
							<Route exact path={"/edit-payment-details/:id"} component={EditPaymentDetails} />

							<Route exact path={"/banking"} component={Banking} />
							<Route exact path={"/opening-balance"} component={OpeningBalance} />
							<Route exact path={"/opening-balance/:id"} component={OpeningBalance} />
							<Route exact path={"/account-report"} component={AccountReport} />

							<Route exact path={"/expense-list"} component={ExpenseList} />
							<Route exact path={"/recurring-expense-list"} component={RecurringExpenseList} />
							<Route exact path={"/add-expense"} component={AddExpenseDetails} />
							<Route exact path={"/edit-expense-details/:id"} component={EditExpenseDetails} />

							<Route exact path={"/expense-summary"} component={ExpenseSummary} />
							<Route exact path={"/customer-expense-report"} component={CustomerExpenseReport} />
							<Route exact path={"/organization-expense-report"} component={OrganizationExpenseReport} />
							<Route exact path={"/vendor-expense-report"} component={VendorExpenseReport} />
							<Route exact path={"/manufacturer-expense-report"} component={ManufacturerExpenseReport} />
							<Route exact path={"/employee-expense-report"} component={EmployeeExpenseReport} />
							<Route exact path={"/payment-received-report"} component={PaymentReceivedReport} />
							<Route exact path={"/payments-made"} component={PaymentsMade} />
							<Route exact path={"/credit-note-report"} component={CreditNoteReport} />

							<Route exact path={"/set-up-payment-configuration"} component={SetUpPaymentConfiguration} />

							<Route exact path={"/department-list"} component={ListDepartment} />

							<Route exact path={"/branches"} component={Branches} />
							<Route exact path={"/transaction-series"} component={TransactionSeries} />
							<Route exact path={"/brands"} component={Brands} />

							<Route exact path={"/new-payment-received"} component={CreatePaymentReceived} />
							<Route exact path={"/new-payment-received/:type/:id"} component={CreatePaymentReceived} />
							<Route exact path={"/payment-received-list"} component={ReceivedPaymentList} />
							<Route exact path={"/edit-payment-received/:id"} component={EditPaymentReceived} />

							<Route exact path={"/create-credit-note"} component={CreateCreditNote} />
							<Route exact path={"/create-credit-note/:type/:id"} component={CreateCreditNote} />
							<Route exact path={"/credit-note-list"} component={CreditNoteList} />
							<Route exact path={"/edit-credit-note/:id"} component={EditCreditNote} />

							<Route exact path={"/create-customer-order"} component={CreateCustomerOrder} />
							<Route exact path={"/create-customer-order/:id"} render={(props) => <CreateCustomerOrder {...props} />} />
							<Route exact path={"/view-customer-order/:id"} render={(props) => <ViewCustomerOrder {...props} />} />
							<Route exact path={"/edit-customer-order/:id"} render={(props) => <EditCustomerOrder {...props} />} />
							<Route exact path={"/customer-order-list"} render={(props) => <CustomerOrderList {...props} />} />

							<Route exact path={"/create-customer-invoice"} component={CreateCustomerInvoice} />
							<Route exact path={"/view-customer-invoice/:id"} render={(props) => <ViewCustomerInvoice {...props} />} />
							<Route exact path={"/edit-customer-invoice/:id"} render={(props) => <EditCustomerInvoice {...props} />} />
							<Route exact path={"/customer-invoice-list"} component={CustomerInvoiceList} />

							<Route exact path={"/create-customer-recurring-invoice"} component={CreateRecurringInvoice} />
							<Route exact path={"/view-customer-recurring-invoice/:id"} render={(props) => <ViewCustomerRecurringInvoice {...props} />} />
							<Route exact path={"/customer-recurring-invoice-list"} component={CustomerRecurringInvoiceList} />

							<Route exact path={"/customer-warehouse-processing-list"} component={CustomerWarehouseProcessingList} />
							<Route exact path={"/customer-warehouse-processing/:id"} render={(props) => <CustomerWarehouseProcessing {...props} />} />

							<Route exact path={"/customer-delivery-challan-list"} component={CustomerDeliveryChallanList} />
							<Route exact path={"/create-customer-delivery-challan/:id"} render={(props) => <CustomerDeliveryChallan {...props} />} />

							<Route exact path={"/create-sales-return"} component={CreateSalesReturn} />
							<Route exact path={"/sales-return-list"} component={SalesReturnList} />
							<Route exact path={"/view-sales-return-details/:id"} render={(props) => <ViewSalesReturnDetails {...props} />} />
							<Route exact path={"/edit-sales-return/:id"} render={(props) => <EditSalesReturn {...props} />} />

							<Route exact path={"/create-dealer-e-way-bill"} component={CreateDealerEwayBill} />
							<Route exact path={"/dealer-e-way-bill-list"} component={DealerEwayBillList} />


							<Route exact from="/add-user" component={AddUser} />
							<Route exact from="/show-user" component={ShowUsers} />

							<Route exact from="/show-organizations" component={ShowOrganizations} />
							<Route exact from="/all-tax-list" component={AllTaxList} />

							<Route exact from="/add-roles" component={Roles} />
							<Route exact from="/roles" component={ShowRoles} />
							<Route exact from="/update-roles/:id" component={UpdateRoles} />

							<Route exact from="/add-profile" component={AddProfile} />
							<Route exact from="/profiles" component={ShowProfiles} />
							<Route exact from="/update-profile/:id" component={UpdateProfiles} />

							<Route exact path={"/currency-list"} component={CurrencyList} />

							<Route exact from="/category-list" component={ShowCategory} />
							<Route exact from="/subcategory-list" component={ShowSubCategory} />

							<Route exact from="/add-item" component={AddItem} />
							<Route exact from="/item-list" component={ItemList} />
							<Route exact from="/item-price-list" component={ItemPriceList} />
							<Route exact from="/edit-item/:id" component={EditItem} />
							<Route exact from="/item-images/:id" component={ItemImages} />
							<Route exact from="/item-price-information/:id" component={ItemPriceInformation} />
							<Route exact from="/bulk-update-item-price-information" component={BulkUpdateItemPriceInformation} />
							<Route exact from="/item-stock-information/:id" component={ItemStockInformation} />
							<Route exact from="/edit-item-stock-information/:id" component={EditItemStockInformation} />
							<Route exact from="/configure-item-group" component={ConfigureItemGroup} />
							<Route exact from={"/configure-item-group/:id"} render={(props) => <ConfigureItemGroup {...props} />} />
							<Route exact from="/item-group-list" component={ItemGroupList} />
							<Route exact from="/configure-item-collection" component={ConfigureItemCollection} />
							<Route exact from={"/configure-item-collection/:id"} render={(props) => <ConfigureItemCollection {...props} />} />
							<Route exact from="/item-collection-list" component={ItemCollectionList} />

							<Route exact from="/item-stock-approval" component={ItemStockApproval} />

							<Route exact from="/add-manufacturer" component={AddManufacturer} />
							<Route exact from="/list-manufacturer" component={ListManufacturer} />
							<Route exact from={"/update-manufacturer/:id"} render={(props) => <UpdateManufacturer {...props} />} />
							<Route exact from={"/manufacturer-address-details/:id"} render={(props) => <ManufacturerAddressDetails {...props} />} />

							<Route exact from="/add-vendor" component={AddVendor} />
							<Route exact from="/list-vendor" component={ListVendor} />
							<Route exact from={"/update-vendor/:id"} render={(props) => <UpdateVendor {...props} />} />
							<Route exact from={"/vendor-address-details/:id"} render={(props) => <VendorAddressDetails {...props} />} />

							<Route exact from="/add-customer" component={Customer} />
							<Route exact from="/customer-list" component={ShowCustomer} />
							<Route exact from={"/edit-customer-details/:id"} render={(props) => <EditCustomerDetails {...props} />} />
							<Route exact from={"/customer-address-details/:id"} render={(props) => <CustomerAddressDetails {...props} />} />

							<Route exact from="/sales-manager" component={SalesManager} />

							<Route exact from={"/create-purchase-order"} component={CreatePurchaseOrder} />
							<Route exact from={"/edit-purchase-order/:id"} render={(props) => <EditPurchaseOrder {...props} />} />
							<Route exact from={"/view-purchase-order/:id"} render={(props) => <ViewPurchaseOrder {...props} />} />
							<Route exact from="/purchase-order-list" component={PurchaseOrderList} />
							<Route exact from={"/purchase-order-approval-email/:id"} render={(props) => <PurchaseOrderApprovalEmail {...props} />} />

							<Route exact from="/create-goods-receive" component={CreateGoodsReceive} />
							<Route exact from={"/edit-goods-receive/:id"} render={(props) => <EditGoodsReceive {...props} />} />
							<Route exact from={"/view-goods-receive/:id"} render={(props) => <ViewGoodsReceive {...props} />} />
							<Route exact from="/goods-received-list" component={GoodsReceivedList} />

							<Route exact from="/create-bill" component={CreateBill} />
							<Route exact from={"/edit-bill/:id"} render={(props) => <EditBill {...props} />} />
							<Route exact from={"/view-bill/:id"} render={(props) => <ViewBill {...props} />} />
							<Route exact from="/bill-list" component={BillList} />

							<Route exact from="/customer-sales-report" component={CustomerSalesReport} />
							<Route exact from="/item-sales-report" component={ItemSalesReport} />
							<Route exact from="/item-gr-report" component={ItemGrReport} />
							<Route exact from="/item-bill-report" component={ItemBillReport} />
							<Route exact from="/purchase-report" component={PurchaseReport} />
							<Route exact from="/bill-report" component={BillReport} />
							<Route exact from="/customer-balances-report" component={CustomerBalancesReport} />
							<Route exact from="/vendor-balances-report" component={VendorBalancesReport} />
							<Route exact from="/manufacturer-balances-report" component={ManufacturerBalancesReport} />
							<Route exact from="/time-to-get-paid-report" component={TimeToGetPaidReport} />
							<Route exact from="/inventory-summary" component={InventorySummary} />
							<Route exact from="/committed-stock-details" component={CommittedStockDetails} />
							<Route exact from="/day-book-report" component={DayBookReport} />

							<Route exact from="/" component={Dashboard} />

							<Route from="*" ><Redirect to="/not-found" /> </Route>
						</Switch>
					</div>
					<Footer />
				</div>

			</div>
		</>
	)
}
