import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

function AddUser(props) {
    let [state, setstate] = useState(2);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [userlist, setuserList] = useState([])
    const [userlist1, setuserList1] = useState([])

    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Employee_id, setEmployee_id] = useState("");
    const [Gender, setGender] = useState("");
    const [Designation, setDesignation] = useState("");
    const [Skip_mail, setSkip_mail] = useState(false);
    const [Dob_date, setDob_date] = useState("");
    const [Joining_date, setJoining_date] = useState("");
    let [Department, setDepartment] = useState("");
    let [Submit_to, setSubmit_to] = useState("");
    const [DepartmentList, setDepartmentList] = useState([]);
    let [Role, setRole] = useState("");
    const [UserRoleList, setUserRoleList] = useState([]);
    let [Profile, setProfile] = useState("");
    const [UserProfileList, setUserProfileList] = useState([]);
    let [Policy, setPolicy] = useState("");
    const [PolicyList, setPolicyList] = useState([]);
    const [GenderList, setGenderList] = useState([{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }])
    const [editmodal, setEditModal] = useState([]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    const [roleName, setRoleName] = useState("")
    const [profileName, setProfileName] = useState("")

    var page_name = "add-user";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    async function getuserdetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            let temp = [];
            temp.push({ value: "user_all", label: "All User" });
            for (let i = 0; i < body.length; i++) {
                temp.push({ value: body[i].user_id, label: body[i].user_id });
            }
            setuserList1(temp);
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.name;
                    temp_obj.value = obj.user_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setuserList(temp_dropdown);
        }

    }

    async function getdeparmentdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-department-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.department_name;
                temp_obj.value = obj.department_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setDepartmentList(temp_dropdown);
    }

    async function getUserRoledetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.log_status === "Active" && obj.user_role !== "Sales Manager") {
                var temp_obj = {};
                temp_obj.label = obj.user_role;
                temp_obj.value = obj.user_role_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setUserRoleList(temp_dropdown);
    }


    async function getUserProfiledetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.log_status === "Active" && obj.user_profile !== "Sales Manager") {
                var temp_obj = {};
                temp_obj.label = obj.user_profile;
                temp_obj.value = obj.user_profile_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setUserProfileList(temp_dropdown);
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getuserdetails();
            let p2 = getdeparmentdetails();
            let p3 = getUserRoledetails();
            let p4 = getUserProfiledetails();
            Promise.all([p1, p2, p3, p4])
                .then(() => {
                    setstate(1);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])


    const submitUser = (e) => {
        e.preventDefault();
        var object = {};
        object.organization_id = organization_id;
        object.name = Name;
        object.email_id = Email;
        object.mobile = Mobile;
        object.Employee_id = Employee_id;
        object.Submit_to = Submit_to;
        object.role = Role;
        object.profile = Profile;
        object.service = "Distributor";
        object.Dob_date = Dob_date;
        object.department = Department;
        object.Joining_date = Joining_date;
        object.Gender = Gender;
        object.Policy = Policy;
        object.Skip_mail = Skip_mail;
        object.Designation = Designation;
        object.page_name = page_name;

        object.roleName = roleName;
        object.profileName = profileName;

        if (object.name == "") {
            swal("Warning!", "Name cannot be a empty", "warning");
            return false;
        }
        var exp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (object.email_id == "") {
            //console.log("7");
            swal("Warning!", "Please provide an email", "warning");
            return false;
        }
        else if (!object.email_id.match(exp)) {
            //console.log("8");
            swal("Warning!", "Please provide a valid email", "warning");
            return false;
        }
        for (var i = 0; i < userlist1.length; ++i) {
            if (userlist1[i].label == object.email_id) {
                swal("Oops!", "This email id already connected to your organization, try different one!", "error");
                return false;
            }
        }
        if (object.role == "") {
            swal("Warning!", "Role field cannot be a empty", "warning");
            return false;
        }
        if (object.profile == "") {
            swal("Warning!", "Profile field cannot be a empty", "warning");
            return false;
        }
        /*if (object.Policy == "") {
            swal("Warning!", "Policy field cannot be a empty", "warning");
            return false;
        }*/
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-user`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "user added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "same_user_name") {
                swal("Oops!", "Use a different User name!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        }).catch(function (error) {
            console.log(error)
        });

    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">New User
                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state == 1 ?
                            <>
                                <div className="card">
                                    <form className="form">
                                        <div className="card-body border-top p-9">
                                            <div className=" py-10 px-lg-17">

                                                <div className="row mb-5">
                                                    <div className="col-md-6 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Name</label>
                                                        <input id="department_name" type="text" className="form-control mb-2" placeholder="" name="department-name" value={Name} onChange={(e) => { setName(e.target.value) }} readOnly={write ? false : true} />
                                                    </div>

                                                    <div className="col-md-6 fv-row">
                                                        <label className=" fs-5 fw-bold mb-2">Department Name</label>
                                                        <Select
                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                            name="category"
                                                            id="category"
                                                            options={DepartmentList}
                                                            onChange={(event) => { setDepartment(event.value) }}
                                                            value={DepartmentList.filter(option => option.value == Department).length != 0 && DepartmentList.filter(option => option.value == Department)[0]}
                                                            placeholder="--Choose a Department--"
                                                        />
                                                    </div>
                                                    {/*<div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Employee ID</label>
                                                            <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Employee_id} onChange={(e) => { setEmployee_id(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>*/}

                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-6 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Email Address</label>
                                                        <input id='category_code' type="text" className="form-control mb-2" placeholder="" name="Department-code" value={Email} onChange={(e) => { setEmail(e.target.value) }} readOnly={write ? false : true} />
                                                    </div>
                                                    <div className="col-md-6 fv-row">
                                                        <label className=" fs-5 fw-bold mb-2">Mobile</label>
                                                        <input id='category_code' type="text" className="form-control mb-2" placeholder="" name="Department-code" value={Mobile} onChange={(e) => { setMobile(e.target.value) }} readOnly={write ? false : true} />
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Role</label>
                                                        <Select
                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                            name="category"
                                                            id="category"
                                                            options={UserRoleList}
                                                            onChange={(event) => { setRole(event.value); setRoleName(event.label) }}
                                                            value={UserRoleList.filter(option => option.value == Role).length != 0 && UserRoleList.filter(option => option.value == Role)[0]}
                                                            placeholder="--Choose a Role--"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Profile</label>
                                                        <Select
                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                            name="category"
                                                            id="category"
                                                            options={UserProfileList}
                                                            onChange={(event) => { setProfile(event.value); setProfileName(event.label) }}
                                                            value={UserProfileList.filter(option => option.value == Profile).length != 0 && UserProfileList.filter(option => option.value == Profile)[0]}
                                                            placeholder="--Choose a Profile--"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-6 fv-row">
                                                        <label className=" fs-5 fw-bold mb-2">Gender</label>
                                                        <Select
                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                            name="category"
                                                            id="category"
                                                            options={GenderList}
                                                            onChange={(event) => { setGender(event.value) }}
                                                            value={GenderList.filter(option => option.value == Gender).length != 0 && GenderList.filter(option => option.value == Gender)[0]}
                                                            placeholder="--Select--"
                                                        />
                                                    </div>
                                                    <div className="col-md-6 fv-row">
                                                        <label className=" fs-5 fw-bold mb-2">Submits To</label>
                                                        <Select
                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                            name="category"
                                                            id="category"
                                                            options={userlist}
                                                            onChange={(event) => { setSubmit_to(event.value) }}
                                                            value={userlist.filter(option => option.value == Submit_to).length != 0 && userlist.filter(option => option.value == Submit_to)[0]}
                                                            placeholder="--Select--"
                                                        />
                                                    </div>
                                                </div>


                                                {/*<div className="col-md-12 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Policy</label>
                                                        <Select
                                                            name="category"
                                                            id="category"
                                                            className="form-control form-control-solid"
                                                            options={PolicyList}
                                                            onChange={(event) => { setPolicy(event.value) }}
                                                            value={PolicyList.filter(option => option.value == Policy).length != 0 && PolicyList.filter(option => option.value == Policy)[0]}
                                                            placeholder="--Select--"
                                                        />
                                                    </div>*/}
                                                <div className="row mb-5">
                                                    <div className="col-md-6 fv-row">
                                                        <label className=" fs-5 fw-bold mb-2">Date of birth</label>
                                                        <input type="date" name="Dob_date" className="form-control" id="Dob_date" placeholder="Sales Start Date" value={Dob_date}
                                                            onChange={(e) => {
                                                                setDob_date(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 fv-row">
                                                        <label className=" fs-5 fw-bold mb-2">Date of Joining</label>
                                                        <input type="date" name="Joining_date" className="form-control" id="Joining_date" placeholder="Sales Start Date" value={Joining_date}
                                                            onChange={(e) => {
                                                                setJoining_date(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">

                                                    {/*<div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Designation</label>
                                                            <Select
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                name="category"
                                                                id="category"
                                                                className="form-control"
                                                                options={UserRoleList}
                                                                onChange={(event) => { setDesignation(event.value) }}
                                                                value={UserRoleList.filter(option => option.value == Designation).length != 0 && UserRoleList.filter(option => option.value == Designation)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                        </div>*/}
                                                    <label class="form-check form-check-custom form-check-solid align-items-start">
                                                        <input class="form-check-input me-3" type="checkbox" name="email-preferences[]" value={Skip_mail} onChange={(e) => {
                                                            setSkip_mail(e.target.checked);
                                                        }} />
                                                        <span class="form-check-label d-flex flex-column align-items-start">
                                                            <span class="fw-bolder fs-5 mb-0">Skip invitation email</span>
                                                            {Skip_mail && <span class="text-muted fs-6">You can invite them later from Admin View - User Management.</span>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                {write &&
                                                    <button type="submit"
                                                        className="btn btn-primary rounded"
                                                        onClick={(e) => { submitUser(e) }}
                                                        data-kt-indicator={mainSubmitButtonState ? "on" : "off"}
                                                        disabled={mainSubmitButtonState}
                                                    >
                                                        <span className="indicator-label">Submit</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </button>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                            :
                            <>
                                {state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                <div className="card-header"></div>
                                                <div className="card-body pt-0">
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default AddUser;