import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment';
import { Modal } from "react-bootstrap";
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import StatusBadge from "../common_component/StatusBadge.js";
import flow_img from '../flow_images/Customer_order_flow.jpg';

const CustomerRecurringInvoiceList = () => {

    const [state, setstate] = useState(2);
    const history = useHistory()

    const [invoiceList, setInvoiceList] = useState([])
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [invoiceListApiState, setInvoiceListApiState] = useState(false);

    const [frequencyModalShow, setFrequencyModalShow] = useState(false)
    const [frequencyDetails, setFrequencyDetails] = useState(null)

    const [timezone_change, set_timezone_change] = useState("+00:00");
    const [branchesList, setBranchesList] = useState([])


    const daterange_picker_ref = useRef()


    const [flowModalShow, setFlowModalShow] = useState(false)

    var page_name = "customer-recurring-invoice-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };


    async function getBasicInvoicesList(cp, rpp, sort_c, sort_v, search_v, sd, ed) {
        setInvoiceListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-recurring-invoice-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setInvoiceList(body.data.list);
            setListCount(body.data.count)
            setInvoiceListApiState(false)
        }
        else {
            throw "basic invoice list api error"
        }
    }
    async function getBranches() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)
        if (body.operation == "success") {
            setBranchesList(body.data)
        }
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    const timezone = body.data[0].timezone_value;
                    set_timezone_change(timezone);
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    useEffect(() => {
        if (((read === 1) || (write)) && (user.email && visibility)) {
            let p1 = getBasicInvoicesList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate);
            let p2 = getBranches();
            let p3 = getTimezoneInformation();

            Promise.all([p1, p2, p3])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    console.log(errx)
                    setstate(3)
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getBasicInvoicesList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate)
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate])


    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Recurring Invoice list</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        {/* <button className="btn p-0 mx-3 btn-hover-scale">{true ? <i className="bi bi-pin-fill fs-1 px-0"></i> : <i className="bi bi-pin fs-1 px-0"></i>}</button> */}
                        <Link to={`/create-customer-recurring-invoice`} className="btn btn-sm btn-primary me-3 rounded btn-hover-scale">
                            <span className="d-md-none">
                                <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                    <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff">
                                    </path>
                                </svg>
                            </span>
                            <span className="d-none d-md-inline">Create Recurring Inv.</span>
                        </Link>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <DateRangePickerComponent
                                            ref={daterange_picker_ref}
                                            allowEdit={false}
                                            showClearButton={false}
                                            focus={() => { daterange_picker_ref.current.show() }}
                                            delayUpdate={true}
                                            presets={[
                                                { label: 'Today', start: new Date(), end: new Date() },
                                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                            ]}
                                            change={(e) => {
                                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                            }}
                                            startDate={new Date(startDate)}
                                            endDate={new Date(endDate)}
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                </div>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                invoiceListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    invoiceList.length > 0 && branchesList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px">Recurring No.</th>
                                                                            <th className="min-w-150px">Profile Name</th>
                                                                            <th className="min-w-150px">Customer Name</th>
                                                                            <th className="min-w-150px">Frequency</th>
                                                                            <th className="min-w-150px">Start Date</th>
                                                                            <th className="min-w-150px">End Date</th>
                                                                            <th className="min-w-150px">Add Status</th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            invoiceList.map((obj, indx) => {

                                                                                let id = obj.recurring_invoice_id;
                                                                                return (
                                                                                    <tr key={obj.recurring_invoice_id}>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.recurring_invoice_number}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.reference_number ? obj.reference_number : "NA"}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.customer_invoice_details.customer_name}</span></td>
                                                                                        <td><button className='btn btn-sm btn-info rounded' onClick={() => { setFrequencyModalShow(true); setFrequencyDetails(obj.frequency_details) }}>View</button></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.start_date).utcOffset(timezone_change).format("DD-MM-YYYY")}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.end_date).utcOffset(timezone_change).format("DD-MM-YYYY")}</span></td>

                                                                                        <td><StatusBadge status={obj.recurring_invoice_status} entity={""} /></td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                    <i className="fas fa-bars px-0"></i>
                                                                                                </button>
                                                                                                {
                                                                                                    write &&
                                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                        <li className="py-1"><Link className="dropdown-item" to={`/view-customer-recurring-invoice/${id}`}>View Details</Link></li>
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                state == 3 ?
                                    <PageError>
                                        <div className="notice bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <h4 className="text-gray-800 fw-bolder">Create a new Recurring Invoice. All added Invoices can be viewed from here</h4>
                                            <div className='mt-10 text-center'>
                                                <Link className='btn btn-success rounded' to={`/create-customer-recurring-invoice`}>Create Invoice</Link>
                                            </div>
                                        </div>
                                    </PageError>
                                    :
                                    <PageError>
                                        <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                            <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                            <div className='mt-10 text-center'>
                                                <button className='btn btn-success rounded' onClick={() => { history.go(0) }}>Reload the page</button>
                                            </div>
                                        </div>
                                    </PageError>
                    }
                </div>
            </div>

            <Modal show={frequencyModalShow} onHide={() => { setFrequencyModalShow(false); setFrequencyDetails(null); }} centered>
                <Modal.Header className='py-2'>
                    <Modal.Title>Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFrequencyModalShow(false); setFrequencyDetails(null); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {console.log(frequencyDetails)}
                    <div className='text-center'>
                        <div className='table-responsive'>
                            <table className='table table-striped'>
                                <thead className='bg-light-info fs-4 fw-bold text-primary'>
                                    <tr>
                                        <th scope='col'>Time Period</th>
                                        <th scope='col'>Frequency</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='table-active'>{frequencyDetails && frequencyDetails.time_period}</td>
                                        <td className='table-active'>{frequencyDetails && frequencyDetails.frequency}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={flowModalShow} onHide={() => { setFlowModalShow(false) }} dialogClassName="h-modal-80">
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-gray-500'>Order Flow Cycle</span>
                    </Modal.Title>

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFlowModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <img src={flow_img} className='w-100' alt="Not yet provided" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setFlowModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal >

        </>
    );
};

export default CustomerRecurringInvoiceList;
