
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'
import Select from 'react-select';
import { Tooltip } from 'antd';

import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';

export const ItemPriceInformation = (props) => {

    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()
    const history = useHistory()

    const [dbPercentage, setDbPercentage] = useState(0);
    const [DBP_disc_amount, setDBP_disc_amount] = useState(0);
    const [DBP_flat_disc, setDBP_flat_disc] = useState(0);
    const [DBP, setDBP] = useState(0);
    const [DBP_Basic, setDBP_Basic] = useState(0);
    const [cdPercentage, setCdPercentage] = useState(0);
    const [cdAmount, setCdAmount] = useState(0);
    const [schemePercentage, setSchemePercentage] = useState(0);
    const [flatScheme, setFlatScheme] = useState(0);
    const [schemeAmount, setSchemeAmount] = useState(0);
    const [Net_dbp, setNet_dbp] = useState(0);
    const [Mop_Price, setMop_Price] = useState(0);
    const [minimalmopPrice, setMinimalmopPrice] = useState(0);
    const [purchaseDiscount, setPurchaseDiscount] = useState(0);
    const [purchaseDiscountFlat, setPurchaseDiscountFlat] = useState(0);
    const [purchaseDiscountAmount, setPurchaseDiscountAmount] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);

    const [PurchasepercentType, setPurchasepercentType] = useState({ label: "MRP", value: "MRP" });
    const [DBPpercentType, setDBPpercentType] = useState({ label: "DP", value: "DP" });
    const [CdpercentType, setCdpercentType] = useState({ label: "DBP", value: "DBP" });
    const [SchemepercentType, setSchemepercentType] = useState({ label: "DBP", value: "DBP" });

    const [HsnModalShow, setHsnModalShow] = useState(false);
    const [organization_list, setorganization_list] = useState([]);
    const [errMsgArr, setErrMsgArr] = useState([]);
    const [productname, setproductname] = useState("");

    const [TaxSelectionList, setTaxSelectionList] = useState([{ label: "Taxable", value: "Taxable" }, { label: "Non Taxable", value: "Non Taxable" }, { label: "Out of Scope", value: "Out of Scope" }, { label: "Non-GST Supply", value: "Non-GST Supply" }]);
    const [AccountSelectionList, setAccountSelectionList] = useState([]);
    const [Default_tax_preference, setDefault_tax_preference] = useState({ "Inter_State_Tax": "", "Intra_State_Tax": "" });
    const [tax_group_percentage, settax_group_percentage] = useState("");

    const [TaxPreference, setTaxPreference] = useState("");
    const [description, setDescription] = useState("");
    const [Selling_Account, setSelling_Account] = useState("");
    const [mrpPrice, setMrpPrice] = useState("");

    const [customerSellingPrice, setCustomerSellingPrice] = useState(0);
    const [SpDiscountAmount, setSpDiscountAmount] = useState(0);
    const [SpDiscountPercentage, setSpDiscountPercentage] = useState(0);
    const [customerInvoicePrice, setCustomerInvoicePrice] = useState(0);

    const [ESP, setESP] = useState(0);
    const [ESpDiscountAmount, setESpDiscountAmount] = useState(0);
    const [ESpDiscountPercentage, setESpDiscountPercentage] = useState(0);
    const [SDP, setSDP] = useState(0);

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [TaxGrouplist, setTaxGrouplist] = useState([]);
    const [Tax_methods, setTax_methods] = useState("TAX_Inclusive");

    const [calculation_flag, setcalculation_flag] = useState(0);
    const [price_info, setprice_info] = useState([]);
    const [DealerPrice, setDealerPrice] = useState("");
    const [basicDealerPrice, setBasicDealerPrice] = useState(0);
    const [dealerInvoicePrice, setDealerInvoicePrice] = useState("");
    const [AmountDiscount, setAmountDiscount] = useState("");
    const [PercentDiscount, setPercentDiscount] = useState("");

    const [navtype, setnavtype] = useState("General");
    const [panelSettings, setPanelSettings] = useState(null);

    const [Currency_symbol, setCurrency_symbol] = useState("");
    const [Decimal_place, setDecimal_place] = useState("");

    var page_name = "item-price-information";
    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };


    async function getbasecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.length > 0) {
            setCurrency_symbol(body[0].currency_symbol);
            if (body[0].decimal_place != "") {
                setDecimal_place(parseInt(body[0].decimal_place));
            }
            else {
                setDecimal_place(3);
            }
        }
    }

    async function getpanelsettings() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-price-panel-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setPanelSettings(body.data);
        }
    }

    async function getproductname() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getproductname`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "pid": props.match.params.id, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        }
        );
        const body = await response.json();
        if (body.operation === "success" && body.data.length > 0) {
            //setcompressedformat(body[0].compressedformat);
            setproductname(body.data[0].product_name);
        }
        else {
            setstate(3);
        }
    }

    async function gettaxgroupdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if ((body.operation == "success") && (body.data.length > 0)) {
            setTaxGrouplist(body.data);
        }
    }

    async function getUserRole() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-role`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            let user_details = body.data[0].user_details;
            //setUser_role(body.data[0].user_role);
            if (body.data[0].user_role === "DSO") {
                //setSelectedDsoUser({ label: user_details.name + " - " + user_details.user_id, value: user_details.user_id });
            }
        }
        else {
            throw "role list error"
        }
    }

    async function getItemPriceInforrmation() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-price-info`, { method: 'POST', body: JSON.stringify({ "id": props.match.params.id, "flag": "information", "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        if (body.operation === "success") {
            if (body.price_details.length > 0) {
                setcalculation_flag(1);
                setprice_info(body.price_details);
            }
            else {
                setcalculation_flag(2);
            }
            const tax_info = Object.keys(body.tax_details)
            if (tax_info.length !== 0) {
                setTax_methods(body.tax_details.tax_calculation_method);
                //console.log(body.tax_details.tax_preference)
                setTaxPreference(body.tax_details.tax_preference);
                if (body.tax_details.tax_preference == "Taxable") {
                    setDefault_tax_preference({ "Inter_State_Tax": body.tax_details.tax_details.Inter_State_Tax, "Intra_State_Tax": body.tax_details.tax_details.Intra_State_Tax })
                }
            }
            ///
            // }
        }
    }

    async function selectiveaccounthead() {
        let arr = [
            { "category_name": "Income", "headArray": ["General Income", "Other Charges", "Sales", "Shipping Charge"] },
        ]
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/selective-account-head`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "headRequest": arr, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        }
        );
        const body = await response.json();
        //console.log(body)
        if (body.operation === "success") {
            let temp_dropdown = [];
            // console.log(body.data);
            body.data.map((obj) => {
                var temp_obj = {};
                temp_obj.label = obj.category_name;
                let temp_dropdown1 = [];
                obj.headArray.map((obj1) => {
                    var temp_obj1 = {};
                    temp_obj1.label = obj1.name;
                    temp_obj1.value = obj1.hid;
                    if (obj1.name === "Sales") {
                        //console.log( obj1.name)
                        setSelling_Account({ label: obj1.name, value: obj1.hid });
                    }
                    temp_dropdown1.push(temp_obj1);
                })
                temp_obj.options = temp_dropdown1;
                temp_dropdown.push(temp_obj);
            })
            setAccountSelectionList(temp_dropdown);
        }
    }

    useEffect(() => {
        if ((Decimal_place !== "" && price_info.length > 0)) {
            setCustomerSellingPrice((price_info.filter(option => option.key == "customer_selling_price").length > 0 && price_info.filter(option => option.key == "customer_selling_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "customer_selling_price")[0].value), Decimal_place) : "");
            setSpDiscountAmount((price_info.filter(option => option.key == "sp_discount_amount").length > 0 && price_info.filter(option => option.key == "sp_discount_amount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "sp_discount_amount")[0].value), Decimal_place) : "");
            setSpDiscountPercentage((price_info.filter(option => option.key == "sp_discount_percentage").length > 0 && price_info.filter(option => option.key == "sp_discount_percentage")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "sp_discount_percentage")[0].value), Decimal_place) : "");
            setCustomerInvoicePrice((price_info.filter(option => option.key == "customer_invoice_price").length > 0 && price_info.filter(option => option.key == "customer_invoice_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "customer_invoice_price")[0].value), Decimal_place) : "");

            setESP((price_info.filter(option => option.key == "ecom_selling_price").length > 0 && price_info.filter(option => option.key == "ecom_selling_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "ecom_selling_price")[0].value), Decimal_place) : "");
            setESpDiscountAmount((price_info.filter(option => option.key == "esp_discount_amount").length > 0 && price_info.filter(option => option.key == "esp_discount_amount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "esp_discount_amount")[0].value), Decimal_place) : "");
            setESpDiscountPercentage((price_info.filter(option => option.key == "esp_discount_percentage").length > 0 && price_info.filter(option => option.key == "esp_discount_percentage")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "esp_discount_percentage")[0].value), Decimal_place) : "");
            setSDP((price_info.filter(option => option.key == "special_deal_price").length > 0 && price_info.filter(option => option.key == "special_deal_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "special_deal_price")[0].value), Decimal_place) : "");

            setSelling_Account((price_info.filter(option => option.key == "selling_account").length > 0 && price_info.filter(option => option.key == "selling_account")[0].value !== null) ? price_info.filter(option => option.key == "selling_account")[0].value : "");
            setMrpPrice((price_info.filter(option => option.key == "mrp_price").length > 0 && price_info.filter(option => option.key == "mrp_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "mrp_price")[0].value), Decimal_place) : "");
            setDealerInvoicePrice((price_info.filter(option => option.key == "invoice_price").length > 0 && price_info.filter(option => option.key == "invoice_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "invoice_price")[0].value), Decimal_place) : "");
            setDealerPrice((price_info.filter(option => option.key == "dealer_price").length > 0 && price_info.filter(option => option.key == "dealer_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "dealer_price")[0].value), Decimal_place) : "");
            setDescription((price_info.filter(option => option.key == "description").length > 0 && price_info.filter(option => option.key == "description")[0].value !== null) ? price_info.filter(option => option.key == "description")[0].value : "");
            setAmountDiscount((price_info.filter(option => option.key == "amount_discount").length > 0 && price_info.filter(option => option.key == "amount_discount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "amount_discount")[0].value), Decimal_place) : "");
            setPercentDiscount((price_info.filter(option => option.key == "percent_discount").length > 0 && price_info.filter(option => option.key == "percent_discount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "percent_discount")[0].value), Decimal_place) : "");

            setDbPercentage((price_info.filter(option => option.key == "db_percentage").length > 0 && price_info.filter(option => option.key == "db_percentage")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "db_percentage")[0].value), Decimal_place) : "");
            setDBP_disc_amount((price_info.filter(option => option.key == "dbp_disc_amount").length > 0 && price_info.filter(option => option.key == "dbp_disc_amount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "dbp_disc_amount")[0].value), Decimal_place) : "");
            setDBP_flat_disc((price_info.filter(option => option.key == "dbp_flat_disc").length > 0 && price_info.filter(option => option.key == "dbp_flat_disc")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "dbp_flat_disc")[0].value), Decimal_place) : "");
            setDBP((price_info.filter(option => option.key == "dbp").length > 0 && price_info.filter(option => option.key == "dbp")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "dbp")[0].value), Decimal_place) : "");
            setDBP_Basic((price_info.filter(option => option.key == "dbp_basic").length > 0 && price_info.filter(option => option.key == "dbp_basic")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "dbp_basic")[0].value), Decimal_place) : "");
            setCdPercentage((price_info.filter(option => option.key == "cd_percentage").length > 0 && price_info.filter(option => option.key == "cd_percentage")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "cd_percentage")[0].value), Decimal_place) : "");
            setCdAmount((price_info.filter(option => option.key == "cd_amount").length > 0 && price_info.filter(option => option.key == "cd_amount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "cd_amount")[0].value), Decimal_place) : "");
            setSchemePercentage((price_info.filter(option => option.key == "scheme_percentage").length > 0 && price_info.filter(option => option.key == "scheme_percentage")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "scheme_percentage")[0].value), Decimal_place) : "");
            setSchemeAmount((price_info.filter(option => option.key == "scheme_amount").length > 0 && price_info.filter(option => option.key == "scheme_amount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "scheme_amount")[0].value), Decimal_place) : "");
            setFlatScheme((price_info.filter(option => option.key == "flat_scheme").length > 0 && price_info.filter(option => option.key == "flat_scheme")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "flat_scheme")[0].value), Decimal_place) : "");

            setNet_dbp((price_info.filter(option => option.key == "net_dbp").length > 0 && price_info.filter(option => option.key == "net_dbp")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "net_dbp")[0].value), Decimal_place) : "");
            setMop_Price((price_info.filter(option => option.key == "mop_price").length > 0 && price_info.filter(option => option.key == "mop_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "mop_price")[0].value), Decimal_place) : "");
            setMinimalmopPrice((price_info.filter(option => option.key == "minimal_mop_price").length > 0 && price_info.filter(option => option.key == "minimal_mop_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "minimal_mop_price")[0].value), Decimal_place) : "");
            setPurchaseDiscount((price_info.filter(option => option.key == "purchase_discount_percentage").length > 0 && price_info.filter(option => option.key == "purchase_discount_percentage")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "purchase_discount_percentage")[0].value), Decimal_place) : "");
            setPurchaseDiscountFlat((price_info.filter(option => option.key == "purchase_discount_flat").length > 0 && price_info.filter(option => option.key == "purchase_discount_flat")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "purchase_discount_flat")[0].value), Decimal_place) : "");
            setPurchaseDiscountAmount((price_info.filter(option => option.key == "purchase_discount_amount").length > 0 && price_info.filter(option => option.key == "purchase_discount_amount")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "purchase_discount_amount")[0].value), Decimal_place) : "");
            setPurchasePrice((price_info.filter(option => option.key == "purchase_price").length > 0 && price_info.filter(option => option.key == "purchase_price")[0].value !== null) ? trimDecimalPlaces(Number(price_info.filter(option => option.key == "purchase_price")[0].value), Decimal_place) : "");
            if ((price_info.filter(option => option.key == "purchase_percent_type").length > 0 && price_info.filter(option => option.key == "purchase_percent_type")[0].value !== null)) {
                setPurchasepercentType({ label: price_info.filter(option => option.key == "purchase_percent_type")[0].value, value: price_info.filter(option => option.key == "purchase_percent_type")[0].value });
            }
            if ((price_info.filter(option => option.key == "dbp_percent_type").length > 0 && price_info.filter(option => option.key == "dbp_percent_type")[0].value !== null)) {
                setDBPpercentType({ label: price_info.filter(option => option.key == "dbp_percent_type")[0].value, value: price_info.filter(option => option.key == "dbp_percent_type")[0].value });
            }
            if ((price_info.filter(option => option.key == "cd_percent_type").length > 0 && price_info.filter(option => option.key == "cd_percent_type")[0].value !== null)) {
                setCdpercentType({ label: price_info.filter(option => option.key == "cd_percent_type")[0].value, value: price_info.filter(option => option.key == "cd_percent_type")[0].value });
            }
            if ((price_info.filter(option => option.key == "scheme_percent_type").length > 0 && price_info.filter(option => option.key == "scheme_percent_type")[0].value !== null)) {
                setSchemepercentType({ label: price_info.filter(option => option.key == "scheme_percent_type")[0].value, value: price_info.filter(option => option.key == "scheme_percent_type")[0].value });
            }
        }
    }, [Decimal_place, price_info])

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getproductname();
            Promise.all([p1])
                .then(() => {
                    //setstate(1);
                    //setcalculation_flag(2);
                    console.log("product available")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (productname !== "") {
            let p1 = gettaxgroupdetails();
            let p2 = getItemPriceInforrmation();
            let p3 = selectiveaccounthead();
            let p4 = getbasecurrency();
            let p5 = getUserRole();
            let p6 = getpanelsettings();
            Promise.all([p1, p2, p3, p4, p5, p6])
                .then(() => {
                    setstate(1);
                    //setcalculation_flag(2);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(0);
                    console.log(err)
                })
        }
    }, [productname])

    const DyanamicValidationCheck = (field, value) => {
        let err = [...errMsgArr]
        if (navtype == "Dealer") {
            if (mrpPrice < DealerPrice) {
                err.push({ msg: "Dealer Price can't be greater than mrp price" });
            }
        }
        else if (navtype == "Customer") {
            if (mrpPrice < ESP) {
                err.push({ msg: "ESP can't be greater than mrp price" });
            }
        }
        else if (navtype == "Ecommerce") {
            if (mrpPrice < customerSellingPrice) {
                err.push({ msg: "Customer Selling Price can't be greater than mrp price" });
            }
        } console.log("err", err);
        setErrMsgArr(err);
    }

    useEffect(() => {
        if (errMsgArr.length > 0) {
            ///
            swal("Oops!", errMsgArr[0].msg, "error");
        }
    }, [errMsgArr])

    const DyanamicDicountCalculation = (usertype, total_amount, discount_amount, discount_percentage, discounted_total_amount, Onchangeparams) => {
        let totalAmount = 0;
        let discountAmount = 0;
        let discountPercentage = 0;
        let discountedTotalAmount = 0;
        // console.log("total_amount",total_amount);
        // console.log("discounted_total_amount",discounted_total_amount);
        console.log(total_amount);
        console.log(discounted_total_amount);
        // if(total_amount>=discounted_total_amount)
        // {
        if (Onchangeparams == "total_amount") {
            totalAmount = total_amount;
            discountAmount = discount_amount;
            discountPercentage = totalAmount !== 0 ? (discountAmount * 100) / totalAmount : 0;
            discountedTotalAmount = totalAmount - discountAmount;
        }
        else if (Onchangeparams == "discount_amount") {
            totalAmount = total_amount;
            discountAmount = discount_amount;
            discountPercentage = totalAmount !== 0 ? (discountAmount * 100) / totalAmount : 0;
            discountedTotalAmount = totalAmount - discountAmount;
        }
        else if (Onchangeparams == "discount_percentage") {
            totalAmount = total_amount;
            discountPercentage = discount_percentage;
            discountAmount = totalAmount * discountPercentage / 100;
            discountedTotalAmount = totalAmount - discountAmount;
        }

        else if (Onchangeparams == "discounted_total_amount") {
            totalAmount = total_amount;
            discountedTotalAmount = discounted_total_amount;
            discountAmount = totalAmount - discountedTotalAmount;
            discountPercentage = discountAmount / totalAmount * 100;
        }

        // if(discountAmount>=totalAmount)
        // {
        //     swal("Oops!", "Discount amount can't be greater than total amount!", "error");
        // }
        // else
        // {
        if (usertype === "Ecommerce") {

            setESP(trimDecimalPlaces(totalAmount, Decimal_place));
            setESpDiscountAmount(trimDecimalPlaces(discountAmount, Decimal_place));
            setESpDiscountPercentage(trimDecimalPlaces(discountPercentage, Decimal_place));
            setSDP(trimDecimalPlaces(discountedTotalAmount, Decimal_place));
        }
        else if (usertype === "Dealer") {
            setDealerPrice(trimDecimalPlaces(totalAmount, Decimal_place));
            setAmountDiscount(trimDecimalPlaces(discountAmount, Decimal_place));
            setPercentDiscount(trimDecimalPlaces(discountPercentage, Decimal_place));
            setDealerInvoicePrice(trimDecimalPlaces(discountedTotalAmount, Decimal_place));
        }
        else {
            setCustomerSellingPrice(trimDecimalPlaces(totalAmount, Decimal_place));
            setSpDiscountAmount(trimDecimalPlaces(discountAmount, Decimal_place));
            setSpDiscountPercentage(trimDecimalPlaces(discountPercentage, Decimal_place));
            setCustomerInvoicePrice(trimDecimalPlaces(discountedTotalAmount, Decimal_place));
        }
        //}
        // }
        // else
        // {
        //     swal("Oops!", "Discounted total amount can't be greater than total amount!", "error");
        // }


    }


    const DealerdealerInvoicePrice = (val1) => {
        let def = 0;
        if (AmountDiscount !== "") {
            def = val1 - AmountDiscount;
        }
        else {
            def = val1;
        }

        setDealerInvoicePrice(def);
    }

    const trimDecimalPlaces = (amt, decimal_place) => {
        return Math.round(amt * Math.pow(10, decimal_place)) / Math.pow(10, decimal_place)
    }

    const readOnlyStyles = {
        backgroundColor: "lightgray",
        color: "gray",
        fontSize: "12px",
        border: "1px dotted gray",
        cursor: "text"
    }

    useEffect(() => {
        if (TaxGrouplist.length > 0 && (Default_tax_preference.Intra_State_Tax != "" && Default_tax_preference.Intra_State_Tax != undefined)) {
            //console.log(TaxGrouplist);
            //console.log(Default_tax_preference);
            let percentage = TaxGrouplist.find(x => x.tax_group_id == Default_tax_preference.Intra_State_Tax).tax_group_percentage;
            //console.log(percentage);
            settax_group_percentage(Number(percentage));
        }
        else {
            settax_group_percentage(1);
        }
    }, [TaxGrouplist, Default_tax_preference])

    useEffect(() => {
        if (tax_group_percentage !== "" && Decimal_place != "" && DealerPrice !== "" && TaxPreference === "Taxable" && Default_tax_preference.Intra_State_Tax != "") {
            if (Tax_methods === "TAX_Exclusive") {
                setBasicDealerPrice(DealerPrice);
            }
            else if (Tax_methods === "TAX_Inclusive") {
                let percentage = tax_group_percentage;
                //console.log(percentage);
                //console.log(trimDecimalPlaces((DealerPrice / (1 + percentage)),Decimal_place));
                setBasicDealerPrice(trimDecimalPlaces((DealerPrice / (1 + (percentage / 100))), Decimal_place));
            }
        }
        else {
            setBasicDealerPrice(0);
        }
    }, [tax_group_percentage, TaxPreference, Tax_methods, PurchasepercentType, DealerPrice, Default_tax_preference])

    useEffect(() => {
        if (Decimal_place != "" && calculation_flag === 2 && tax_group_percentage !== "") {
            let gst_percentage = 1;
            if (tax_group_percentage != 1) {
                gst_percentage = 1 + (tax_group_percentage / 100);
            }

            let dbp_price = DBPpercentType.value == "DP" ? DealerPrice : DBPpercentType.value == "MRP" ? mrpPrice : purchasePrice;
            let dbpdiscamnt = (Number(dbp_price) * Number(dbPercentage)) / 100; ///dbp_disc_amount
            let dbp_amnt = Number(dbp_price) - dbpdiscamnt - Number(DBP_flat_disc); ///dbp
            let dbp_basic = trimDecimalPlaces(((dbp_amnt) / gst_percentage), Decimal_place); ///dbp_basic

            let cd_price = CdpercentType.value == "DBP Basic" ? dbp_basic : trimDecimalPlaces(dbp_amnt, Decimal_place);
            let camount = ((Number(cd_price) / gst_percentage) * Number(cdPercentage)) / 100; ///cd_amount
            setCdAmount(trimDecimalPlaces(camount, Decimal_place)); ///cd_amount

            let scheme_price = SchemepercentType.value == "DBP Basic" ? dbp_basic : SchemepercentType.value == "DBP" ? trimDecimalPlaces(dbp_amnt, Decimal_place) : DealerPrice;
            let samount = ((Number(scheme_price) / gst_percentage) * Number(schemePercentage)) / 100; ///scheme_amount
            setSchemeAmount(trimDecimalPlaces(samount, Decimal_place)); ///scheme_amount

            let purchase_price = PurchasepercentType.value == "DP" ? DealerPrice : PurchasepercentType.value == "MRP" ? mrpPrice : trimDecimalPlaces(basicDealerPrice, Decimal_place);
            let purc_disc_amnt = (Number(purchase_price) * Number(purchaseDiscount)) / 100; ///purchase_discount_amount
            setPurchaseDiscountAmount(trimDecimalPlaces(purc_disc_amnt, Decimal_place));
            setPurchasePrice(trimDecimalPlaces((purchase_price - purc_disc_amnt - purchaseDiscountFlat), Decimal_place)); ///purchase_price

            setDBP_disc_amount(trimDecimalPlaces(dbpdiscamnt, Decimal_place));
            setDBP(trimDecimalPlaces(dbp_amnt, Decimal_place));
            setDBP_Basic(dbp_basic);

            setNet_dbp(trimDecimalPlaces((Number(dbp_amnt) - trimDecimalPlaces(camount, Decimal_place) - trimDecimalPlaces(samount, Decimal_place) - Number(flatScheme)), Decimal_place)); ///net_dbp
        }
    }, [tax_group_percentage, calculation_flag, Decimal_place, DealerPrice, mrpPrice, purchasePrice, DBPpercentType, dbPercentage, DBP_flat_disc, CdpercentType, cdPercentage, SchemepercentType, schemePercentage, flatScheme, purchaseDiscountFlat, PurchasepercentType, purchaseDiscount, basicDealerPrice])


    const validatePrice = () => {
        ///dealer part
        if (mrpPrice < DealerPrice) {
            swal("Warning!", "Dealer Price can't be greater than mrp price", "warning");
            return false;
        }
        if (Math.sign(Number(dealerInvoicePrice)) === -1) {
            swal("Warning!", "Dealer Invoice Price Can't be negative", "warning");
            return false;
        }
        if (Number(dbPercentage) > 100) {
            swal("Warning!", "DBP Percentage Can't be greater than 100", "warning");
            return false;
        }
        if (Number(cdPercentage) > 100) {
            swal("Warning!", "CD Percentage Can't be greater than 100", "warning");
            return false;
        }
        if (Number(schemePercentage) > 100) {
            swal("Warning!", "Scheme Percentage amount Can't be greater than 100", "warning");
            return false;
        }
        if (Number(purchaseDiscount) > 100) {
            swal("Warning!", "Purchase discount Can't be greater than 100", "warning");
            return false;
        }
        if (Math.sign(Number(DBP_disc_amount)) === -1) {
            swal("Warning!", "Purchase Price Can't be negative", "warning");
            return false;
        }
        if (Math.sign(Number(DBP)) === -1) {
            swal("Warning!", "DBP Can't be negative", "warning");
            return false;
        }
        if (Math.sign(Number(DBP_Basic)) === -1) {
            swal("Warning!", "DBP Basic Can't be negative", "warning");
            return false;
        }
        if (Math.sign(Number(cdAmount)) === -1) {
            swal("Warning!", "CD Amount Can't be negative", "warning");
            return false;
        }
        if (Math.sign(Number(schemeAmount)) === -1) {
            swal("Warning!", "Scheme Amount Can't be negative", "warning");
            return false;
        }
        if (Math.sign(Number(Net_dbp)) === -1) {
            swal("Warning!", "Net DBP Can't be negative", "warning");
            return false;
        }

        ///general part
        if (Math.sign(Number(purchaseDiscountAmount)) === -1) {
            swal("Warning!", "Purchase Discount Can't be negative", "warning");
            return false;
        }
        if (Math.sign(Number(purchasePrice)) === -1) {
            swal("Warning!", "Purchase Price Can't be negative", "warning");
            return false;
        }

        ///customer part 
        if (Math.sign(Number(customerInvoicePrice)) === -1) {
            swal("Warning!", "Customer Invoice Price Can't be negative", "warning");
            return false;
        }
        if (mrpPrice < customerSellingPrice) {
            swal("Warning!", "Customer Selling Price can't be greater than mrp price", "warning");
            return false;
        }


        //ecommerce part
        if (Math.sign(Number(SDP)) === -1) {
            swal("Warning!", "SDP Can't be negative", "warning");
            return false;
        }
        if (mrpPrice < ESP) {
            swal("Warning!", "ESP can't be greater than mrp price", "warning");
            return false;
        }
        return true;
    }

    const submitItemPriceDetails = (e) => {
        e.preventDefault();
        var object = {};
        if (Selling_Account == "") {
            swal("Warning!", "Need to select Selling account", "warning");
            return false;
        }
        if (!validatePrice()) {
            return false;
        }
        if (TaxPreference == "Taxable") {
            if ((Default_tax_preference.Inter_State_Tax == "") || (Default_tax_preference.Intra_State_Tax == "")) {
                swal("Warning!", "Enter Tax Details before submitting", "warning");
                return false;
            }
            if ((TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Inter_State_Tax).tax_group_percentage !== TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Intra_State_Tax).tax_group_percentage)) {
                swal("Warning!", "Both Inter State and Intra State tax percentages must be equal", "warning");
                return false;
            }
        }
        object.page_name = page_name;
        object.id = props.match.params.id;

        object.Selling_Account = Selling_Account;
        object.mrpPrice = mrpPrice;
        object.purchaseDiscount = purchaseDiscount;
        object.purchaseDiscountFlat = purchaseDiscountFlat;
        object.purchaseDiscountAmount = purchaseDiscountAmount;
        object.purchasePrice = purchasePrice;
        object.PurchasepercentType = PurchasepercentType.value;
        object.description = description;

        object.dealerInvoicePrice = dealerInvoicePrice;
        object.DealerPrice = DealerPrice;
        object.AmountDiscount = AmountDiscount;
        object.PercentDiscount = PercentDiscount;
        object.dbPercentage = dbPercentage;
        object.DBP_disc_amount = DBP_disc_amount;
        object.DBP_flat_disc = DBP_flat_disc;
        object.DBP = DBP;
        object.DBP_Basic = DBP_Basic;
        object.cdPercentage = cdPercentage;
        object.cdAmount = cdAmount;
        object.schemePercentage = schemePercentage;
        object.schemeAmount = schemeAmount;
        object.flatScheme = flatScheme;
        object.schemeAmount = schemeAmount;
        object.Net_dbp = Net_dbp;
        object.Mop_Price = Mop_Price;
        object.minimalmopPrice = minimalmopPrice;
        object.SchemepercentType = SchemepercentType.value;
        object.CdpercentType = CdpercentType.value;
        object.DBPpercentType = DBPpercentType.value;

        object.customerSellingPrice = customerSellingPrice;
        object.SpDiscountAmount = SpDiscountAmount;
        object.SpDiscountPercentage = SpDiscountPercentage;
        object.customerInvoicePrice = customerInvoicePrice;

        object.ESP = ESP;
        object.ESpDiscountAmount = ESpDiscountAmount;
        object.ESpDiscountPercentage = ESpDiscountPercentage;
        object.SDP = SDP;

        object.Tax_Calculation_Method = Tax_methods;
        object.TaxPreference = TaxPreference;
        object.Default_tax_preference = JSON.stringify(Default_tax_preference);

        object.flag = "additional";
        object.organization_id = organization_id;

        //var values = { "page_name": page_name, "id": props.match.params.id, "Tax_Calculation_Method": Tax_methods, "TaxPreference": TaxPreference, "Selling_Account": Selling_Account, "mrpPrice": mrpPrice, "dealerInvoicePrice": dealerInvoicePrice, "DealerPrice": DealerPrice, "description": description, "AmountDiscount": AmountDiscount, "PercentDiscount": PercentDiscount, "Default_tax_preference": Default_tax_preference, "organization_id": organization_id };
        //var values = {"page_name":page_name,"ItemName":ItemName,"Unit":Unit,"Sku":SKU,"Tax_methods":Tax_methods,"Manufacturer":Manufacturer,"SubCategory":SubCategory,"Category":Category,"Brand":Brand,"Hsn_code":Hsn_code,"Sac_code":Sac_code,"Tracking_inventory":Tracking_inventory,"Shipping_info":JSON.stringify(Shipping_info),"Specification_info":JSON.stringify(Specification_info),"TaxPreference":TaxPreference,"Default_tax_preference":JSON.stringify(Default_tax_preference),"organization_id":organization_id};
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-product-price-information`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Item price details saved successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.message == "no_modification") {
                swal("Oops!", "Need to modify something for updation!", "warning");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }



    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Item Price Information</h1>
                </div>
                <div>
                    {
                        state == 1 ?
                            <>
                                <div className="form d-flex flex-column flex-lg-row">
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                        {panelSettings !== null &&
                                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                                                <li className="nav-item">
                                                    <a className={`nav-link text-active-primary pb-4 ${navtype === "General" && "active"} cursor-pointer`} onClick={(e) => { setnavtype("General"); }}>General</a>
                                                </li>
                                                {panelSettings.customer &&
                                                    <li className="nav-item">
                                                        <a className={`nav-link text-active-primary pb-4 ${navtype === "Customer" && "active"} cursor-pointer`} onClick={(e) => { setnavtype("Customer"); }}>Customer Price</a>
                                                    </li>}
                                                {panelSettings.ecommerce &&
                                                    <li className="nav-item">
                                                        <a className={`nav-link text-active-primary pb-4 ${navtype === "Ecommerce" && "active"} cursor-pointer`} onClick={(e) => { setnavtype("Ecommerce"); }}>Ecommerce Price</a>
                                                    </li>}
                                                <li className="nav-item">
                                                    <a className={`nav-link text-active-primary pb-4 ${navtype === "Tax" && "active"} cursor-pointer`} onClick={(e) => { setnavtype("Tax"); }}>Tax Details</a>
                                                </li>
                                            </ul>
                                        }
                                        <div className="tab-content">
                                            {navtype == "General" &&
                                                <div className={`tab-pane fade ${navtype === "General" && "show active"}`} role="tab-panel">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>{productname}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">MRP Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={mrpPrice.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            setMrpPrice(e.target.value == "" ? 0 : parseFloat(e.target.value));
                                                                            DyanamicDicountCalculation("Customer", e.target.value == "" ? 0 : parseFloat(e.target.value), SpDiscountAmount, SpDiscountPercentage, customerInvoicePrice, "total_amount");
                                                                            DyanamicDicountCalculation("Ecommerce", e.target.value == "" ? 0 : parseFloat(e.target.value), ESpDiscountAmount, ESpDiscountPercentage, SDP, "total_amount");
                                                                            DyanamicDicountCalculation("Dealer", e.target.value == "" ? 0 : parseFloat(e.target.value), AmountDiscount, PercentDiscount, dealerInvoicePrice, "total_amount");
                                                                            //setMrpPrice(e.target.value == "" ? 0 : parseFloat(e.target.value));
                                                                            // setCustomerSellingPrice(e.target.value == "" ? 0 : parseFloat(e.target.value)); 
                                                                            // setCustomerInvoicePrice(e.target.value == "" ? 0 : parseFloat(e.target.value));
                                                                            // setSDP(e.target.value == "" ? 0 : parseFloat(e.target.value));
                                                                            // setESP(e.target.value == "" ? 0 : parseFloat(e.target.value));  
                                                                        }} />
                                                                    <div className="text-muted fs-7">Set the MRP Price.</div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="required form-label">Account</label>
                                                                    <Select
                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                        options={AccountSelectionList}
                                                                        onChange={(value) => { setSelling_Account(value); }}
                                                                        value={Selling_Account}
                                                                        placeholder="--Select Account--"
                                                                    />
                                                                    <div className="text-muted fs-7">Enter the Account.</div>
                                                                </div>
                                                            </div><br />
                                                            <div className="mb-10 fv-row">
                                                                <label className="form-label">Description</label>
                                                                <textarea className="form-control" placeholder="" name="description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
                                                                <div className="text-muted fs-7"></div>
                                                            </div>
                                                            <label className="fs-5 fw-bold mb-2">Purchase Price Details</label>
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Purchase Discount (%)</label>
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <div className="" style={{ flex: "2" }}>
                                                                            <Select
                                                                                options={[{ label: "MRP", value: "MRP" }]}
                                                                                onChange={(val) => { setPurchasepercentType(val) }}
                                                                                value={PurchasepercentType}
                                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                            />
                                                                        </div>
                                                                        <div className="" style={{ flex: "2" }}>
                                                                            <input type="number" min="0" max="100" className="form-control mb-2" placeholder="" value={purchaseDiscount.toString()} onChange={(e) => {
                                                                                //FillReadOnlyField(e.target.value,"purchasepercentage");
                                                                                if (parseFloat(e.target.value) > 100) {
                                                                                    swal("Oops!", "Purchase Discount Percentage can't be greater than 100!", "error");
                                                                                }
                                                                                else {
                                                                                    setcalculation_flag(2);
                                                                                    setPurchaseDiscount(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                                }
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Purchase Discount Flat ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={purchaseDiscountFlat.toString()} onChange={(e) => { setcalculation_flag(2); setPurchaseDiscountFlat(e.target.value == "" ? 0 : parseFloat(e.target.value)); }} />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Purchase Discount Amount ({Currency_symbol})
                                                                        {/* <i className="fas fa-exclamation-circle ms-2 fs-4" data-toggle="tooltip" title="Purchase Discount Amount = Dealer Price * Purchase Discount"></i> */}
                                                                    </label>
                                                                    <Tooltip title={`Purchase Discount Amount = ${PurchasepercentType.value} * Purchase Discount`}>
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" style={readOnlyStyles} value={purchaseDiscountAmount.toString()} onChange={(e) => { setPurchaseDiscountAmount(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} readOnly />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Purchase Price ({Currency_symbol})
                                                                        {/* <i className="fas fa-exclamation-circle ms-2 fs-4" data-toggle="tooltip" title="Purchase Price = Dealer Price-Purchase Discount Amount"></i> */}
                                                                    </label>
                                                                    <Tooltip title={`Purchase Price = ${PurchasepercentType.value}-Purchase Discount Amount-Purchase Discount Flat`}>
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" style={readOnlyStyles} value={purchasePrice.toString()} onChange={(e) => { setPurchasePrice(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} readOnly />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {navtype == "Dealer" &&
                                                <div className={`tab-pane fade ${navtype === "Dealer" && "show active"}`} role="tab-panel">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>{productname}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Dealer Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={DealerPrice.toString()} onChange={(e) => {
                                                                        setcalculation_flag(2);
                                                                        DyanamicDicountCalculation("Dealer", e.target.value == "" ? 0 : parseFloat(e.target.value), AmountDiscount, PercentDiscount, dealerInvoicePrice, "total_amount");
                                                                    }}
                                                                    //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7">Set the Dealer Price.</div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Dealer Price Discount (In Amount) ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={AmountDiscount.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Dealer", DealerPrice, e.target.value == "" ? 0 : parseFloat(e.target.value), PercentDiscount, dealerInvoicePrice, "discount_amount");
                                                                        }}
                                                                    //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Dealer Price Discount (In Percentage)</label>
                                                                    <input type="number" min="0" max="100" className="form-control mb-2" placeholder="" value={PercentDiscount.toString()}
                                                                        onChange={(e) => {
                                                                            if (parseFloat(e.target.value) > 100) {
                                                                                swal("Oops!", "Dealer Price Discount Percentage can't be greater than 100!", "error");
                                                                            }
                                                                            else {
                                                                                setcalculation_flag(2);
                                                                                DyanamicDicountCalculation("Dealer", DealerPrice, AmountDiscount, e.target.value == "" ? 0 : parseFloat(e.target.value), dealerInvoicePrice, "discount_percentage");
                                                                            }
                                                                        }}
                                                                    //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Dealer Invoice Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={dealerInvoicePrice.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Dealer", DealerPrice, AmountDiscount, PercentDiscount, e.target.value == "" ? 0 : parseFloat(e.target.value), "discounted_total_amount");

                                                                        }}
                                                                    //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7">Set the Invoice Price.</div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">DBP %</label>
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <div className="" style={{ flex: "2" }}>
                                                                            <Select
                                                                                options={[{ label: "DP", value: "DP" }, { label: "MRP", value: "MRP" }, { label: "Purchase Price", value: "Purchase Price" }]}
                                                                                onChange={(val) => {
                                                                                    setcalculation_flag(2);
                                                                                    setDBPpercentType(val);
                                                                                }}
                                                                                value={DBPpercentType}
                                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                            />
                                                                        </div>
                                                                        <div className="" style={{ flex: "1" }}>
                                                                            <input type="number" min="0" max="100" className="form-control" placeholder="" value={dbPercentage.toString()} onChange={(e) => {
                                                                                if (parseFloat(e.target.value) > 100) {
                                                                                    swal("Oops!", "DBP % can't be greater than 100!", "error");
                                                                                }
                                                                                else {
                                                                                    setcalculation_flag(2);
                                                                                    setDbPercentage(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                                }
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">DBP Flat Discount ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={DBP_flat_disc.toString()} onChange={(e) => { setcalculation_flag(2); setDBP_flat_disc(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} />
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">DBP Disc Amount ({Currency_symbol})</label>
                                                                    <Tooltip title={`DBP Disc Amount = ${DBPpercentType.value} * DBP%`}>
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    {/* <a data-toggle="tooltip" title="DBP Disc Amount = Dealer Price * DBP%"><i className="fas fa-exclamation-circle ms-2 fs-4"></i></a></label> */}
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" style={readOnlyStyles} value={DBP_disc_amount.toString()} onChange={(e) => { setDBP_disc_amount(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} readOnly />
                                                                    {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">DBP ({Currency_symbol})
                                                                        {/* <a data-toggle="tooltip" data-placement="top" title="This is a tooltip" data-trigger="click touch"><i className="fas fa-exclamation-circle ms-2 fs-4" data-toggle="tooltip" title="DBP = Dealer Price - DBP Disc Amount"></i></a> */}
                                                                        {/**/}</label>
                                                                    <Tooltip title={`DBP = ${DBPpercentType.value} - DBP Disc Amount - DBP Flat Discount`}>
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={DBP.toString()} onChange={(e) => {
                                                                        setDBP(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                    }} style={readOnlyStyles} readOnly />
                                                                    {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">DBP Basic ({Currency_symbol})
                                                                        {/* <i className="fas fa-exclamation-circle ms-2 fs-4" data-toggle="tooltip" title="DBP Basic = Dealer Price - DBP Disc Amount"></i> */}
                                                                    </label>
                                                                    <Tooltip title={`DBP Basic = DBP/${1 + (tax_group_percentage / 100)}`}>
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" style={readOnlyStyles} value={DBP_Basic.toString()} onChange={(e) => { setDBP_Basic(e.target.value == "" ? 0 : parseFloat(e.target.value)); }} readOnly />
                                                                    {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">CD%</label>
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <div className="" style={{ flex: "2" }}>
                                                                            <Select
                                                                                options={[{ label: "DBP Basic", value: "DBP Basic" }, { label: "DBP", value: "DBP" }]}
                                                                                onChange={(val) => {
                                                                                    setCdpercentType(val);
                                                                                }}
                                                                                value={CdpercentType}
                                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                            />
                                                                        </div>
                                                                        <div className="" style={{ flex: "1" }}>
                                                                            <input type="number" min="0" max="100" className="form-control mb-2" placeholder="" value={cdPercentage.toString()} onChange={(e) => {
                                                                                //FillReadOnlyField(e.target.value,"cdpercentage");
                                                                                if (parseFloat(e.target.value) > 100) {
                                                                                    swal("Oops!", "CD% can't be greater than 100!", "error");
                                                                                }
                                                                                else {
                                                                                    setcalculation_flag(2);
                                                                                    setCdPercentage(e.target.value == "" ? 0 : parseFloat(e.target.value));
                                                                                }
                                                                            }} />
                                                                        </div>
                                                                        {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                    </div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">CD Amount ({Currency_symbol})
                                                                        {/* <i className="fas fa-exclamation-circle ms-2 fs-4" data-toggle="tooltip" title="CD Amount = (DBP / 1.18) * CD%"></i> */}
                                                                    </label>
                                                                    <Tooltip title={`CD Amount = (${CdpercentType.value} / ${1 + (tax_group_percentage / 100)}) * CD%`}>
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" style={readOnlyStyles} value={cdAmount.toString()} onChange={(e) => { setCdAmount(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} readOnly />
                                                                    {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">SCHEME%</label>
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <div className="" style={{ flex: "2" }}>
                                                                            <Select
                                                                                options={[{ label: "DBP Basic", value: "DBP Basic" }, { label: "DBP", value: "DBP" }, { label: "DP", value: "DP" }]}
                                                                                onChange={(val) => {
                                                                                    setSchemepercentType(val)
                                                                                }}
                                                                                value={SchemepercentType}
                                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                            />
                                                                        </div>
                                                                        <div className="" style={{ flex: "1" }}>
                                                                            <input type="number" min="0" max="100" className="form-control mb-2" placeholder="" value={schemePercentage.toString()}
                                                                                onChange={(e) => {
                                                                                    if (parseFloat(e.target.value) > 100) {
                                                                                        swal("Oops!", "SCHEME % can't be greater than 100!", "error");
                                                                                    }
                                                                                    else {
                                                                                        setcalculation_flag(2);
                                                                                        setSchemePercentage(e.target.value == "" ? 0 : parseFloat(e.target.value));
                                                                                    }
                                                                                }} />
                                                                        </div>
                                                                        {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                    </div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Flat Scheme ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={flatScheme.toString()} onChange={(e) => {
                                                                        setcalculation_flag(2);
                                                                        setNet_dbp(trimDecimalPlaces((Number(DBP) - Number(schemeAmount) - Number(cdAmount) - Number(e.target.value)), Decimal_place))
                                                                        setFlatScheme(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                    }} />
                                                                    {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Scheme Amount ({Currency_symbol})
                                                                        {/* <i className="fas fa-exclamation-circle ms-2 fs-4" data-toggle="tooltip" title="Scheme Amount = (DBP / 1.18) * Scheme%"></i> */}
                                                                    </label>
                                                                    <Tooltip title={`Scheme Amount = (${SchemepercentType.value} / ${1 + (tax_group_percentage / 100)}) * Scheme%`}>
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" style={readOnlyStyles} value={schemeAmount.toString()} onChange={(e) => { setSchemeAmount(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} readOnly />
                                                                    {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Net DBP ({Currency_symbol})
                                                                        {/* <i className="fas fa-exclamation-circle ms-2 fs-4" data-toggle="tooltip" title="Net DBP = DBP-CD Amount-Flat Scheme-Scheme Amount"></i> */}
                                                                    </label>
                                                                    <Tooltip title="Net DBP = DBP-CD Amount-Flat Scheme-Scheme Amount">
                                                                        <i className="fa fa-info-circle mx-1 fs-4 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i>
                                                                    </Tooltip>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" style={readOnlyStyles} value={Net_dbp.toString()} onChange={(e) => { setNet_dbp(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} readOnly />
                                                                    {/* <div className="text-muted fs-7">Set the Minimal MOP Price.</div> */}
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">MOP Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={Mop_Price.toString()} onChange={(e) => { setMop_Price(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} />
                                                                    {/*<div className="text-muted fs-7">Set the MOP Price.</div>*/}
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Minimal MOP Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={minimalmopPrice.toString()} onChange={(e) => { setMinimalmopPrice(e.target.value == "" ? 0 : parseFloat(e.target.value)) }} />
                                                                    {/* <div className="text-muted fs-7">Set the Minimal MOP Price.</div> */}
                                                                </div>
                                                            </div>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {navtype == "Ecommerce" &&
                                                <div className={`tab-pane fade ${navtype === "Ecommerce" && "show active"}`} role="tab-panel">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>{productname}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">MRP ({Currency_symbol})</label>
                                                                    <input type="number" className="form-control mb-2" placeholder="" value={mrpPrice.toString()}
                                                                        style={readOnlyStyles} readOnly />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">E-commerce Selling Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={ESP.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Ecommerce", e.target.value == "" ? 0 : parseFloat(e.target.value), ESpDiscountAmount, ESpDiscountPercentage, SDP, "total_amount");
                                                                            //setESP(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                        }} //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Additional Discount (In Amount) ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={ESpDiscountAmount.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Ecommerce", ESP, e.target.value == "" ? 0 : parseFloat(e.target.value), ESpDiscountPercentage, SDP, "discount_amount");
                                                                            //setESpDiscountAmount(e.target.value == "" ? 0 : parseFloat(e.target.value)) 
                                                                        }} //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Additional Discount (In Percentage)</label>
                                                                    <input type="number" min="0" max="100" className="form-control mb-2" placeholder="" value={ESpDiscountPercentage.toString()}
                                                                        onChange={(e) => {
                                                                            if (parseFloat(e.target.value) > 100) {
                                                                                swal("Oops!", "E-commerce Additional Discount Percentage can't be greater than 100!", "error");
                                                                            }
                                                                            else {
                                                                                setcalculation_flag(2);
                                                                                DyanamicDicountCalculation("Ecommerce", ESP, ESpDiscountAmount, e.target.value == "" ? 0 : parseFloat(e.target.value), SDP, "discount_percentage");
                                                                                //setESpDiscountPercentage(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                            }
                                                                        }} //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Special Deal Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={SDP.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Ecommerce", ESP, ESpDiscountAmount, ESpDiscountPercentage, e.target.value == "" ? 0 : parseFloat(e.target.value), "discounted_total_amount");
                                                                            //setSDP(e.target.value == "" ? 0 : parseFloat(e.target.value)) 
                                                                        }} //onBlur={(e) => {DyanamicValidationCheck()}}
                                                                    />
                                                                    <div className="text-muted fs-7">Set the Invoice Price.</div>
                                                                </div>
                                                            </div><br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {navtype == "Customer" &&
                                                <div className={`tab-pane fade ${navtype === "Customer" && "show active"}`} role="tab-panel">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>{productname}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">MRP ({Currency_symbol})</label>
                                                                    <input type="number" className="form-control mb-2" placeholder="" value={mrpPrice.toString()}
                                                                        style={readOnlyStyles} readOnly />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Customer Selling Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={customerSellingPrice.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Customer", e.target.value == "" ? 0 : parseFloat(e.target.value), SpDiscountAmount, SpDiscountPercentage, customerInvoicePrice, "total_amount");
                                                                            //setCustomerSellingPrice(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                        }} />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                            </div><br />
                                                            <div className="d-flex flex-wrap gap-5">
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Additional Discount (In Amount) ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={SpDiscountAmount.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Customer", customerSellingPrice, e.target.value == "" ? 0 : parseFloat(e.target.value), SpDiscountPercentage, customerInvoicePrice, "discount_amount");
                                                                            //setSpDiscountAmount(e.target.value == "" ? 0 : parseFloat(e.target.value)) 
                                                                        }} />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Additional Discount (In Percentage)</label>
                                                                    <input type="number" min="0" max="100" className="form-control mb-2" placeholder="" value={SpDiscountPercentage.toString()}
                                                                        onChange={(e) => {
                                                                            if (parseFloat(e.target.value) > 100) {
                                                                                swal("Oops!", "Customer Additional Discount Percentage can't be greater than 100!", "error");
                                                                            }
                                                                            else {
                                                                                setcalculation_flag(2);
                                                                                DyanamicDicountCalculation("Customer", customerSellingPrice, SpDiscountAmount, e.target.value == "" ? 0 : parseFloat(e.target.value), customerInvoicePrice, "discount_percentage");
                                                                                //setSpDiscountPercentage(e.target.value == "" ? 0 : parseFloat(e.target.value))
                                                                            }
                                                                        }} />
                                                                    <div className="text-muted fs-7"></div>
                                                                </div>
                                                                <div className="fv-row w-100 flex-md-root">
                                                                    <label className="form-label">Customer Invoice Price ({Currency_symbol})</label>
                                                                    <input type="number" min="0" className="form-control mb-2" placeholder="" value={customerInvoicePrice.toString()}
                                                                        onChange={(e) => {
                                                                            setcalculation_flag(2);
                                                                            DyanamicDicountCalculation("Customer", customerSellingPrice, SpDiscountAmount, SpDiscountPercentage, e.target.value == "" ? 0 : parseFloat(e.target.value), "discounted_total_amount");
                                                                            //setCustomerInvoicePrice(e.target.value == "" ? 0 : parseFloat(e.target.value)) 
                                                                        }} />
                                                                    <div className="text-muted fs-7">Set the Invoice Price.</div>
                                                                </div>
                                                            </div><br />
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {navtype == "Tax" &&
                                                <div className={`tab-pane fade ${navtype === "Tax" && "show active"}`} role="tab-panel">
                                                    <div className="card card-flush py-4">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>{productname}</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label className="required form-label">Tax Preference</label>
                                                                    <Select
                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                        name="category"
                                                                        id="category"
                                                                        options={TaxSelectionList}
                                                                        onChange={(event) => {
                                                                            setTaxPreference(event.value);
                                                                        }}
                                                                        value={TaxSelectionList.filter(option => option.value == TaxPreference).length != 0 && TaxSelectionList.filter(option => option.value == TaxPreference)[0]}
                                                                        placeholder="--Select Tax Preference--"
                                                                    />
                                                                    <div className="text-muted fs-7">Enter the Tax Preference.</div>
                                                                </div>
                                                                {
                                                                    TaxPreference === "Taxable" &&
                                                                    <div className="col-md-6">
                                                                        <label className="fs-6 fw-semibold mb-2">Tax Calculation Method</label>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className={`rounded mx-2 px-6 py-2 ${Tax_methods == "TAX_Inclusive" && "bg-primary"}`} onClick={() => { setTax_methods("TAX_Inclusive"); }}>TAX Inclusive</div>
                                                                            <div className={`rounded mx-2 px-6 py-2 ${Tax_methods == "TAX_Exclusive" && "bg-primary"}`} onClick={() => { setTax_methods("TAX_Exclusive"); }}>TAX Exclusive</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {
                                                                TaxPreference === "Taxable" &&
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="form-label required">Intra State Tax Rate</label>
                                                                        <Select
                                                                            styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                            options={TaxGrouplist.filter(x => x.tax_group_type === "INTRASTATE").map(x => { return { label: `(${x.tax_group_percentage}%) ${x.tax_group_name}`, value: x.tax_group_id } })}
                                                                            onChange={(event) => { setDefault_tax_preference({ ...Default_tax_preference, Intra_State_Tax: event.value }) }}
                                                                            value={Default_tax_preference.Intra_State_Tax !== "" && { label: `(${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Intra_State_Tax).tax_group_percentage}%) ${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Intra_State_Tax).tax_group_name}`, value: Default_tax_preference.Intra_State_Tax }}
                                                                            placeholder="--Select Intrastate tax--"
                                                                        />
                                                                        <div className="text-muted fs-7">Set the Intra State Tax Rate.</div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="form-label required">Inter State Tax Rate</label>
                                                                        <Select
                                                                            styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                                            options={TaxGrouplist.filter(x => x.tax_group_type === "INTERSTATE").map(x => { return { label: `(${x.tax_group_percentage}%) ${x.tax_group_name}`, value: x.tax_group_id } })}
                                                                            onChange={(event) => { setDefault_tax_preference({ ...Default_tax_preference, Inter_State_Tax: event.value }) }}
                                                                            value={Default_tax_preference.Inter_State_Tax !== "" && { label: `(${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Inter_State_Tax).tax_group_percentage}%) ${TaxGrouplist.find(x => x.tax_group_id === Default_tax_preference.Inter_State_Tax).tax_group_name}`, value: Default_tax_preference.Inter_State_Tax }}
                                                                            placeholder="--Select Interstate tax--"
                                                                        />
                                                                        <div className="text-muted fs-7">Set the Inter State Tax Rate.</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10 mt-4">
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={(e) => { submitItemPriceDetails(e) }} className="btn btn-primary">
                                            <span className="indicator-label">Save Changes</span>
                                            <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        </button>
                                    </div>
                                </div>
                            </>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                state == 3 ?
                                    <PageError message='No such Product' />
                                    :
                                    <PageError>
                                        <button className='btn btn-success rounded' onClick={() => { history.go(0) }}>Reload the page</button>
                                    </PageError>
                    }
                </div>
            </div>
            
            <Modal show={HsnModalShow} onHide={() => { setHsnModalShow() }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Find</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setHsnModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="form-group row">

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                </Modal.Footer>
            </Modal>
        </>);

};

export default ItemPriceInformation;