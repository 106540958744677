import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";
import Select from 'react-select';

import country_arr from './common_arr/countries-arr.js';
import s_a from './states-arr.js';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';

function Branches(props) {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    const [branchesList, setBranchesList] = useState([])
    const [GSTINlist, setGSTINlist] = useState([])
    const [tseriesList, setTseriesList] = useState([])

    const [addBranchModalShow, setAddBranchModalShow] = useState(false)
    const [branchName, setBranchName] = useState("")
    const [branchAddressStreet1, setBranchAddressStreet1] = useState("")
    const [branchAddressStreet2, setBranchAddressStreet2] = useState("")
    const [selectedBranchAddressCountry, setSelectedBranchAddressCountry] = useState(null)
    const [selectedBranchAddressState, setSelectedBranchAddressState] = useState(null)
    const [branchAddressCity, setBranchAddressCity] = useState("")
    const [branchAddressZipcode, setBranchAddressZipcode] = useState("")
    const [branchAddressPhone, setBranchAddressPhone] = useState("")
    const [branchAddressWebsite, setBranchAddressWebsite] = useState("")
    const [branchAddressFax, setBranchAddressFax] = useState("")
    const [selectedGstinId, setSelectedGstinId] = useState(null)
    const [selectedTseriesId, setSelectedTseriesId] = useState([])
    const [selectedDefaultTseriesId, setSelectedDefaultTseriesId] = useState(null)
    const [branchSubmitButtonState, setBranchSubmitButtonState] = useState(false)

    const [editBranchModalShow, setEditBranchModalShow] = useState(false)
    const [branchEditId, setBranchEditId] = useState("")
    const [branchEditName, setBranchEditName] = useState("")
    const [branchEditAddressStreet1, setBranchEditAddressStreet1] = useState("")
    const [branchEditAddressStreet2, setBranchEditAddressStreet2] = useState("")
    const [selectedBranchEditAddressCountry, setSelectedBranchEditAddressCountry] = useState(null)
    const [selectedBranchEditAddressState, setSelectedBranchEditAddressState] = useState("")
    const [branchEditAddressCity, setBranchEditAddressCity] = useState("")
    const [branchEditAddressZipcode, setBranchEditAddressZipcode] = useState("")
    const [branchEditAddressPhone, setBranchEditAddressPhone] = useState("")
    const [branchEditAddressWebsite, setBranchEditAddressWebsite] = useState("")
    const [branchEditAddressFax, setBranchEditAddressFax] = useState("")
    const [selectedGstinIdEdit, setSelectedGstinIdEdit] = useState(null)
    const [selectedTseriesIdEdit, setSelectedTseriesIdEdit] = useState([])
    const [selectedDefaultTseriesIdEdit, setSelectedDefaultTseriesIdEdit] = useState(null)
    const [editBranchSubmitButtonState, setEditBranchSubmitButtonState] = useState(false)

    var page_name = "branches";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getBranches() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.data.length != 0) {
            setBranchesList(body.data)
        }
    }
    async function getGSTINdetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gst-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.data.length != 0) {
            setGSTINlist(body.data)
        }
    }
    async function getTransactionSeries() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.data.length != 0) {
            setTseriesList(body.data)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getBranches()
            let p2 = getGSTINdetails()
            let p3 = getTransactionSeries()

            Promise.all([p1, p2, p3])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    const newBranchSubmit = () => {

        if (branchName == null) {
            swal("Oops!", "Branch Name cannot be empty", "error");
            return
        }
        if (selectedGstinId == null) {
            swal("Oops!", "GSTIN cannot be empty", "error");
            return
        }
        if (selectedTseriesId.length < 1) {
            swal("Oops!", "Select at least 1 transaction series", "error");
            return
        }
        if (selectedDefaultTseriesId == null) {
            swal("Oops!", "Select a default transaction series", "error");
            return
        }

        let tar = selectedTseriesId.map(x => {
            let tp = tseriesList.find(a => a.transaction_series_id == x.value)
            return JSON.parse(tp.series_data).map(x => x.prefix_value)
        }).flat()

        let temp = new Set(tar)
        if (temp.size != tar.length) {
            swal("Oops!", "Each transaction series must have unique prefixes", "error");
            return
        }

        if (selectedBranchAddressCountry == null) {
            swal("Oops!", "Country cannot be empty", "error");
            return
        }
        if (selectedBranchAddressState == null) {
            swal("Oops!", "State cannot be empty", "error");
            return
        }

        var object = {};

        object.gstin_id = selectedGstinId.value
        object.transaction_series_id = selectedTseriesId.map(x => x.value).join(",")
        object.default_transaction_series_id = selectedDefaultTseriesId.value
        object.branch_name = branchName
        object.branch_address_street1 = branchAddressStreet1
        object.branch_address_street2 = branchAddressStreet2
        object.branch_address_country = selectedBranchAddressCountry.value
        object.branch_address_state = selectedBranchAddressState.value
        object.branch_address_city = branchAddressCity
        object.branch_address_zipcode = branchAddressZipcode
        object.branch_address_phone = branchAddressPhone
        object.branch_address_website = branchAddressWebsite
        object.branch_address_fax = branchAddressFax

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setBranchSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-branch`, object, headers).then((res) => {
            setBranchSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Branch added successfully!", "success")

                handleAddBranchModalClose()
                getBranches()
            }
            else if (res.data.operation == "duplicate") {
                swal("Oops!", "This Branch already exists!", "error");
            }
            else if (res.data.operation == "invalid") {
                swal("Oops!", "Each transaction series must have unique prefixes", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleAddBranchModalClose = () => {
        setAddBranchModalShow(false)
        setBranchName("")
        setBranchAddressStreet1("")
        setBranchAddressStreet2("")
        setSelectedBranchAddressCountry(null)
        setSelectedBranchAddressState(null)
        setBranchAddressCity("")
        setBranchAddressZipcode("")
        setBranchAddressPhone("")
        setBranchAddressWebsite("")
        setBranchAddressFax("")
        setSelectedGstinId(null)
        setSelectedTseriesId([])
        setSelectedDefaultTseriesId(null)
    }

    const editBranchInit = (bid) => {

        let tb = branchesList.find(x => x.branch_id == bid)

        setEditBranchModalShow(true)
        setBranchEditId(tb.branch_id)
        setBranchEditName(tb.branch_name)
        setBranchEditAddressStreet1(tb.branch_address_street1)
        setBranchEditAddressStreet2(tb.branch_address_street2)
        if (tb.branch_address_country != null) {
            setSelectedBranchEditAddressCountry({ label: tb.branch_address_country, value: tb.branch_address_country })
        }

        setSelectedBranchEditAddressState({ label: tb.branch_address_state, value: tb.branch_address_state })
        setBranchEditAddressCity(tb.branch_address_city)
        setBranchEditAddressZipcode(tb.branch_address_zipcode)
        setBranchEditAddressPhone(tb.branch_address_phone)
        setBranchEditAddressWebsite(tb.branch_address_website)
        setBranchEditAddressFax(tb.branch_address_fax)
        setSelectedGstinIdEdit({ label: tb.GstIn, value: tb.gstin_id })

        if (tb.transaction_series_id != "") {
            setSelectedTseriesIdEdit(tb.transaction_series_id.split(",").map(x => {
                return { label: tseriesList.find(a => a.transaction_series_id == x).series_name, value: x }
            }))
            setSelectedDefaultTseriesIdEdit({ label: tseriesList.find(a => a.transaction_series_id == tb.default_transaction_series).series_name, value: tb.default_transaction_series })
        }
    }

    const updateBranchSubmit = () => {

        if (branchEditName == null) {
            swal("Oops!", "Branch Name cannot be empty", "error");
            return
        }
        if (selectedGstinIdEdit == null) {
            swal("Oops!", "GSTIN cannot be empty", "error");
            return
        }
        if (selectedTseriesIdEdit.length < 1) {
            swal("Oops!", "Select at least 1 transaction series", "error");
            return
        }
        if (selectedDefaultTseriesIdEdit == null) {
            swal("Oops!", "Select a default transaction series", "error");
            return
        }

        let tar = selectedTseriesIdEdit.map(x => {
            let tp = tseriesList.find(a => a.transaction_series_id == x.value)
            return JSON.parse(tp.series_data).map(x => x.prefix_value)
        }).flat()

        let temp = new Set(tar)
        if (temp.size != tar.length) {
            swal("Oops!", "Each transaction series must have unique prefixes", "error");
            return
        }

        if (selectedBranchEditAddressCountry == null) {
            swal("Oops!", "Country cannot be empty", "error");
            return
        }
        if (selectedBranchEditAddressState == null) {
            swal("Oops!", "State cannot be empty", "error");
            return
        }

        var object = {};

        object.gstin_id = selectedGstinIdEdit.value
        object.transaction_series_id = selectedTseriesIdEdit.map(x => x.value).join(",")
        object.default_transaction_series_id = selectedDefaultTseriesIdEdit.value
        object.branch_id = branchEditId
        object.branch_name = branchEditName
        object.branch_address_street1 = branchEditAddressStreet1
        object.branch_address_street2 = branchEditAddressStreet2
        object.branch_address_country = selectedBranchEditAddressCountry.value
        object.branch_address_state = selectedBranchEditAddressState.value
        object.branch_address_city = branchEditAddressCity
        object.branch_address_zipcode = branchEditAddressZipcode
        object.branch_address_phone = branchEditAddressPhone
        object.branch_address_website = branchEditAddressWebsite
        object.branch_address_fax = branchEditAddressFax

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setEditBranchSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-branch`, object, headers).then((res) => {
            setEditBranchSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Branch updated successfully!", "success")

                handleEditBranchModalClose()
                getBranches()
            }
            else if (res.data.operation == "duplicate") {
                swal("Oops!", "This Branch already exists!", "error");
            }
            else if (res.data.operation == "invalid") {
                swal("Oops!", "Each transaction series must have unique prefixes", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleEditBranchModalClose = () => {
        setEditBranchModalShow(false)
        setBranchEditName("")
        setBranchEditAddressStreet1("")
        setBranchEditAddressStreet2("")
        setSelectedBranchEditAddressCountry(null)
        setSelectedBranchEditAddressState(null)
        setBranchEditAddressCity("")
        setBranchEditAddressZipcode("")
        setBranchEditAddressPhone("")
        setBranchEditAddressWebsite("")
        setBranchEditAddressFax("")
        setSelectedGstinIdEdit(null)
        setSelectedTseriesIdEdit([])
        setSelectedDefaultTseriesIdEdit(null)
    }

    const changeBranchStatus = (bid, status) => {

        var object = {};

        object.branch_id = bid
        object.status = status

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setBranchSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-branch-status`, object, headers).then((res) => {
            setBranchSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Branch status changed successfully!", "success")
                getBranches()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <>
            <div>
                <div className="d-flex flex-stack mb-5">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Branches</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <button className='btn btn-success rounded' data-kt-indicator={state != 1 ? "on" : "off"} disabled={state != 1} onClick={() => { setAddBranchModalShow(true) }}>
                            <span className="indicator-label">+ New Branch</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div>
                    {
                        state == 1 ?
                            <>
                                {
                                    branchesList.length == 0 ?
                                        <div className='d-flex flex-column align-items-center'>
                                            <svg width="164" height="145" viewBox="0 0 656 458.68644">
                                                <rect y="434.34322" width="656" height="2" fill="#3f3d56" />
                                                <g>
                                                    <path d="M471.97091,210.81398c-6.07329-36.41746-37.72841-64.16942-75.86422-64.16942H240.1493c-38.12098,0-69.76869,27.72972-75.86422,64.12498-.70358,4.16242-1.06653,8.44332-1.06653,12.80572v135.886c0,4.36236,.36295,8.63589,1.06653,12.79831,4.85126,28.99624,25.92996,52.49686,53.58563,60.84393,7.05095,2.13306,14.53143,3.28105,22.27859,3.28105h155.95739c7.74715,0,15.22764-1.14799,22.27859-3.28105,27.66309-8.35449,48.7492-31.86994,53.58563-60.88837,.69621-4.14759,1.05912-8.40629,1.05912-12.75387V223.57526c0-4.34758-.36291-8.61369-1.05912-12.76128Zm-62.66593,222.28952c-4.28831,.76284-8.69515,1.16282-13.19828,1.16282H240.1493c-4.50313,0-8.90997-.39998-13.19828-1.16282-35.01768-6.22884-61.60677-36.83226-61.60677-73.64224v-45.10525c0-127.45005,103.31244-165.58581,230.76245-165.58581,41.31315,0,74.80506,33.49195,74.80506,74.80506v135.886c-100.2906,13.42047-26.5891,67.4134-61.60677,73.64224Z" fill="#3f3d56" />
                                                    <polygon points="349.16197 249.18644 355.16197 288.18644 443.16197 276.18644 434.66197 230.61951 349.16197 249.18644" fill="#6c63ff" />
                                                    <rect x="381.84178" y="30.34219" width="36.3846" height="37.66125" fill="#2f2e41" />
                                                    <polygon points="385.16197 70.18644 394.16197 43.18644 411.70446 43.18644 412.62654 70.18644 385.16197 70.18644" fill="#ffb6b6" />
                                                    <polygon points="385.16197 70.18644 394.16197 43.18644 411.70446 43.18644 412.62654 70.18644 385.16197 70.18644" opacity=".1" />
                                                    <path d="M394.66197,310.68644l-1,104-1,8v11.48425l15,1.51575,1-23s16-45,12-80-2-25-2-25l-24,3Z" fill="#ffb6b6" />
                                                    <path d="M404.18408,318.85365l-36.90133,97.23832-1.97873,7.81567-4.17771,10.69742-14.52368-4.04476,7.43539-21.78796s1.46619-47.7373,17.92433-78.88421c16.45814-31.1469,10.9574-22.5596,10.9574-22.5596l21.26433,11.52511Z" fill="#ffb6b6" />
                                                    <path d="M385.16197,67.18644l-27,12,17.2396,89.01208-2.72386,127.75563-18,38s-3.01575,21.73228,27.98425,7.73228,66-18,66-18l-8.5-58.5-7.5-153.5,1-34-22-14-26.5,3.5Z" fill="#2f2e41" />
                                                    <path d="M370.12429,335.34322l-29.96232-50.15678,34.2396-116.98792-16.2396-89.01208,28.49044-12.19685s14.74916,14.36248,14.74916,26.20894-31.27728,242.1447-31.27728,242.1447Z" fill="#e6e6e6" />
                                                    <path d="M435.12429,325.34322l-27.19694-233.62812c-.34341-2.94999,.16014-5.93678,1.45177-8.6111l7.78284-16.11441,30.5,8.69685-12.2604,95.51208,32.7604,93.98792-33.03768,60.15678Z" fill="#e6e6e6" />
                                                    <path d="M410.66197,433.68644s-19-11-21-5-3,11-3,11c0,0-5,19,10,19s14-8.64173,14-8.64173v-16.35827Z" fill="#2f2e41" />
                                                    <path d="M344.53573,427.606s21.69978-3.33458,21.38009,2.98189c-.31969,6.31647-1.20709,11.33768-1.20709,11.33768,0,0-2.25433,19.51712-16.22662,14.06046-13.97229-5.45666-9.89714-13.14253-9.89714-13.14253l5.95076-15.2375Z" fill="#2f2e41" />
                                                    <circle cx="404.10297" cy="33.02147" r="24.85993" fill="#ffb6b6" />
                                                    <path d="M423.9647,10.86767c-1.15708-6.12936-7.44914-10.27514-13.66505-10.79501s-12.30453,1.82726-17.90227,4.57921c-3.79456,1.86548-7.53062,3.96811-10.60425,6.87182-3.07363,2.90371-5.46063,6.69692-6.01202,10.88913-.19508,1.48324-.16979,3.03289-.77693,4.40016-.75845,1.708-2.38655,2.86795-3.36916,4.4576-1.76228,2.85096-.95268,6.99858,1.75238,8.97753-3.40024,1.44912-6.89397,2.96069-9.48602,5.59563s-4.08879,6.70308-2.66645,10.11462c.50322,1.20699,1.3348,2.26349,1.76489,3.49843,.81669,2.34499,.03944,5.00909-1.40923,7.02585-1.44868,2.01676-3.49316,3.51228-5.50175,4.97226,5.16196,1.01177,10.43096,1.80015,15.66992,1.32811s10.49707-2.30805,14.29087-5.95176c3.7938-3.64371,5.88084-9.26437,4.51973-14.34539-1.04268-3.89231-3.95898-7.30301-3.95712-11.33256,.00143-3.09747,1.7431-5.89158,3.4249-8.49271,3.6729-5.68066,7.34579-11.36132,11.01869-17.04197,.66067-1.02183,1.35739-2.07924,2.40141-2.70425,1.77606-1.06326,4.07981-.59568,5.95226,.28683,1.87245,.88252,3.58303,2.14867,5.57941,2.69585,4.07452,1.11677,8.80105-1.44789,10.08575-5.47261" fill="#2f2e41" />
                                                    <path d="M409.2795,61.42524c-2.07158,2.0061-5.057,2.65225-7.82378,3.46516s-5.70979,2.09141-6.955,4.69243c-1.221,2.55043-.33461,5.78793,1.68692,7.76505s4.95817,2.80999,7.78554,2.77077c2.82737-.03922,5.58283-.86796,8.24175-1.8301,7.27053-2.63087,14.15664-6.32148,20.37314-10.919-4.02679-1.11411-6.66108-5.81614-5.50835-9.83205,.93769-3.26677,3.805-5.54528,5.75617-8.32809,3.35958-4.79151,3.91924-11.10753,2.80677-16.85277-1.11247-5.74524-3.73164-11.07097-6.32359-16.3176-.81935-1.65853-1.65806-3.34513-2.9362-4.68245-1.27815-1.33731-3.08783-2.29539-4.92776-2.10379-3.05336,.31795-5.00303,3.66989-5.02377,6.7397s1.32592,5.95491,2.34732,8.84988c1.05231,2.98259,1.7838,6.14409,1.50148,9.29425-.23659,2.63989-1.19668,5.21132-2.7481,7.36029-1.19808,1.65954-2.72478,3.05223-4.02749,4.63097-1.00715,1.22055-1.90009,2.60309-2.16486,4.16321-.48182,2.83914,1.18357,5.71186,.72713,8.55519-.48249,3.0056-3.64519,5.3067-6.65342,4.84085" fill="#2f2e41" />
                                                    <g>
                                                        <circle cx="333.24859" cy="323.64456" r="85" fill="#6c63ff" />
                                                        <g>
                                                            <path d="M384.17838,316.82297h-10.56668c-1.64378-9.68714-6.71681-18.46011-14.29229-24.71729-17.43427-14.39992-43.24109-11.94022-57.641,5.49411-.04913,.05563-.09645,.11283-.14168,.17151-1.15062,1.49144-.87427,3.63332,.61717,4.78401,1.49118,1.15069,3.63306,.87447,4.78394-.61697,6.25536-7.5788,15.72368-12.40167,26.31064-12.40167,16.20852,.00195,30.17897,11.40631,33.42571,27.2863h-9.31805c-.39879,.00013-.78459,.13993-1.09083,.39502-.72375,.6028-.82176,1.6781-.21915,2.40185l13.41126,16.09894c.06577,.0789,.13856,.15169,.21759,.21746,.72323,.60326,1.79872,.50583,2.40185-.21746l13.41126-16.09894c.25503-.30624,.39489-.69223,.39515-1.09083,.00026-.94187-.76301-1.70565-1.70487-1.70604Z" fill="#fff" />
                                                            <path d="M364.3433,344.73371c-1.49144-1.15062-3.63332-.87434-4.78394,.6171-4.96202,6.00781-11.83065,10.1363-19.46436,11.69923-18.46167,3.77988-36.4923-8.12213-40.27225-26.58393h9.31831c.94187-.00039,1.70513-.76418,1.70487-1.70604-.00026-.39853-.14012-.78452-.39515-1.09083l-13.41126-16.09888c-.60313-.72336-1.67862-.82079-2.40185-.21753-.07903,.06577-.15182,.13856-.21759,.21753l-13.41126,16.09888c-.60261,.72375-.5046,1.79911,.21915,2.40185,.30624,.25516,.69204,.39489,1.09083,.39502h10.56642c1.64404,9.68721,6.71681,18.46011,14.29255,24.71729,17.43427,14.39998,43.24109,11.94022,57.641-5.49404,.04913-.0557,.09619-.11296,.14142-.17164,1.15088-1.49144,.87453-3.63326-.61691-4.78401Z" fill="#fff" />
                                                        </g>
                                                    </g>
                                                    <path id="uuid-46f931a0-b53b-476c-b367-fb65f10d962b-151" d="M356.98147,237.19363c-1.0294,7.36621-5.66458,12.80599-10.35238,12.15011-4.6878-.65589-7.65226-7.15837-6.6215-14.52707,.37137-2.94914,1.4436-5.76647,3.127-8.21626l4.75577-31.15587,14.57296,2.54338-6.23553,30.44414c.94735,2.81843,1.2058,5.82279,.75369,8.76157Z" fill="#ffb6b6" />
                                                    <path d="M369.66197,77.68644s-15-5-17,13-4,40-4,40c0,0-9,21-5,32s11,3.33071,4,12.66535-6.02479,40.04724-6.02479,40.04724l22.52479-1.13386s12.5-82.57874,12.5-84.57874-7-52-7-52Z" fill="#e6e6e6" />
                                                    <g>
                                                        <path id="uuid-e7f43b88-b30d-4e93-bef0-000887236ddd-152" d="M467.16133,233.84999c.27882,7.43257-3.33017,13.60114-8.06033,13.7778-4.73016,.17667-8.78938-5.70491-9.06731-13.14017-.15176-2.96856,.4096-5.93029,1.63712-8.63741l-.78368-31.507,14.79315-.05261-.798,31.06591c1.4271,2.60853,2.2086,5.52095,2.27905,8.49347Z" fill="#ffb6b6" />
                                                        <path d="M444.0696,77.34877s15.08694-4.73121,16.76503,13.30165c1.67809,18.03286,3.28472,51.06508,3.28472,51.06508,0,0,8.62337,21.15744,4.42749,32.08422-4.19589,10.92678-11.05775,3.13365-4.22564,12.59187,6.83211,9.45822,4.37997,36.13126,4.37997,36.13126l-22.50094-1.53611s-10.09427-78.77166-10.05853-80.77134,7.92792-62.86664,7.92792-62.86664Z" fill="#e6e6e6" />
                                                    </g>
                                                </g>
                                            </svg>
                                            <div>No branches found.</div>
                                        </div>
                                        :
                                        <div className='row gx-0'>
                                            {
                                                branchesList.map(b => {
                                                    return (
                                                        <div key={b.branch_id} className='col-md-6 p-2'>
                                                            <div className="card h-100">
                                                                <div className="card-header mt-6">
                                                                    <div className="card-title flex-column">
                                                                        <h3 className="fw-bolder mb-1">{b.branch_name}</h3>
                                                                        {b.add_status == "Active" ? <span className="badge badge-light-success me-auto rounded">Active</span> : <span className="badge badge-light-danger me-auto rounded">Deactivated</span>}
                                                                    </div>
                                                                    <div className="card-toolbar">
                                                                        <div className="dropdown">
                                                                            {
                                                                                write &&
                                                                                <>
                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                        <li className="py-1"><span onClick={(e) => { editBranchInit(b.branch_id); }} className="dropdown-item">Edit Details</span></li>
                                                                                        {b.default_branch != "yes" && <li className="py-1"><span className="dropdown-item" href="#" onClick={() => { changeBranchStatus(b.branch_id, b.add_status == "Active" ? "Deactive" : "Active") }}>Change status</span></li>}
                                                                                    </ul>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body p-9">
                                                                    <div className="fs-6 d-flex justify-content-between my-3"><div className="fw-bold">GSTIN</div><div className="">{b.GstIn}</div></div>
                                                                    <div className="separator separator-dashed"></div>
                                                                    <div className="fs-6 d-flex justify-content-between my-3"><div className="fw-bold">Associated Series</div><div className="">{b.transaction_series_id == "" ? 0 : b.transaction_series_id.split(",").length}</div></div>
                                                                    <div className="separator separator-dashed"></div>
                                                                    <div className="fs-6 d-flex justify-content-between my-3"><div className="fw-bold">Address</div><div className="">{b.branch_address_state}, {b.branch_address_city}<br />{b.branch_address_street1}<br />{b.branch_address_street2}</div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>

            <Modal show={addBranchModalShow} onHide={() => { handleAddBranchModalClose() }} size={"xl"} centered>
                <Modal.Header>
                    <Modal.Title>Add Branch Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddBranchModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Branch Name</label>
                                <input type="text" className="form-control form-control-solid" value={branchName} onChange={(e) => { setBranchName(e.target.value) }} placeholder="Branch Name" />
                                <span className="form-text text-muted">Please enter Branch name.</span>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <label className="fs-5 fw-bold mb-2">Branch Address</label>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <input type="text" className="form-control form-control-solid" value={branchAddressStreet1} onChange={(e) => { setBranchAddressStreet1(e.target.value) }} placeholder="Street 1" />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <input type="text" className="form-control form-control-solid" value={branchAddressStreet2} onChange={(e) => { setBranchAddressStreet2(e.target.value) }} placeholder="Street 2" />
                                </div>
                            </div>
                            <div className="row mb-5 align-items-center">
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchAddressCity} onChange={(e) => { setBranchAddressCity(e.target.value) }} placeholder="City" />
                                </div>
                                <div className="col-sm-4">
                                    <Select
                                        options={country_arr.country_arr.slice(1, -1).map(x => { return { label: x, value: x } })}
                                        onChange={(val) => { setSelectedBranchAddressCountry(val); setSelectedBranchAddressState(null); }}
                                        value={selectedBranchAddressCountry}
                                        placeholder="Country"
                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Select
                                        options={selectedBranchAddressCountry == null ? [] : s_a.s_a.slice(1, -1)[country_arr.country_arr.findIndex(x => x == selectedBranchAddressCountry.value) - 1].split("|").map(x => { return { label: x, value: x } })}
                                        onChange={(val) => { setSelectedBranchAddressState(val) }}
                                        value={selectedBranchAddressState}
                                        placeholder="State"
                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchAddressZipcode} onChange={(e) => { setBranchAddressZipcode(e.target.value) }} placeholder="Zipcode" />
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchAddressFax} onChange={(e) => { setBranchAddressFax(e.target.value) }} placeholder="Fax" />
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchAddressPhone} onChange={(e) => { setBranchAddressPhone(e.target.value) }} placeholder="Phone" />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchAddressWebsite} onChange={(e) => { setBranchAddressWebsite(e.target.value) }} placeholder="Website" />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">GSTIN </label>
                                <Select
                                    options={GSTINlist.map(x => { return { label: x.GstIn, value: x.unique_id } })}
                                    onChange={(val) => { setSelectedGstinId(val) }}
                                    value={selectedGstinId}
                                    placeholder="Select GSTIN..."
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                                <span className="form-text text-muted">Please select a GSTIN</span>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Transaction Series</label>
                                <Select
                                    options={tseriesList.map(x => { return { label: x.series_name, value: x.transaction_series_id } })}
                                    onChange={(val) => { setSelectedTseriesId(val) }}
                                    value={selectedTseriesId}
                                    isMulti
                                    placeholder="Select Transaction Series..."
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                                <span className="form-text text-muted">Please select a Transaction Series</span>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Default Transaction Series</label>
                                <Select
                                    options={selectedTseriesId}
                                    onChange={(val) => { setSelectedDefaultTseriesId(val) }}
                                    value={selectedDefaultTseriesId}
                                    placeholder="Select Default Transaction Series..."
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                                <span className="form-text text-muted">Please select a Default Transaction Series</span>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddBranchModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={branchSubmitButtonState ? "on" : "off"} disabled={branchSubmitButtonState} onClick={() => { newBranchSubmit() }}>
                            <span className="indicator-label">+ Add</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={editBranchModalShow} onHide={() => { handleEditBranchModalClose() }} size={"xl"} centered>
                <Modal.Header>
                    <Modal.Title>Edit Branch Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleEditBranchModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>
                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Branch Name</label>
                                <input type="text" className="form-control form-control-solid" value={branchEditName} onChange={(e) => { setBranchEditName(e.target.value) }} placeholder="Branch Name" />
                                <span className="form-text text-muted">Please enter Branch name.</span>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <label className="fs-5 fw-bold mb-2">Branch Address</label>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <input type="text" className="form-control form-control-solid" value={branchEditAddressStreet1} onChange={(e) => { setBranchEditAddressStreet1(e.target.value) }} placeholder="Street 1" />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-12">
                                    <input type="text" className="form-control form-control-solid" value={branchEditAddressStreet2} onChange={(e) => { setBranchEditAddressStreet2(e.target.value) }} placeholder="Street 2" />
                                </div>
                            </div>
                            <div className="row mb-5 align-items-center">
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchEditAddressCity} onChange={(e) => { setBranchEditAddressCity(e.target.value) }} placeholder="City" />
                                </div>
                                <div className="col-sm-4">
                                    <Select
                                        options={country_arr.country_arr.slice(1, -1).map(x => { return { label: x, value: x } })}
                                        onChange={(val) => { setSelectedBranchEditAddressCountry(val); setSelectedBranchEditAddressState(null); }}
                                        value={selectedBranchEditAddressCountry}
                                        placeholder="Country"
                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <Select
                                        options={(selectedBranchEditAddressCountry == null) ? [] : s_a.s_a.slice(1, -1)[country_arr.country_arr.findIndex(x => x == selectedBranchEditAddressCountry.value) - 1].split("|").map(x => { return { label: x, value: x } })}
                                        onChange={(val) => { setSelectedBranchEditAddressState(val) }}
                                        value={selectedBranchEditAddressState}
                                        placeholder="State"
                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchEditAddressZipcode} onChange={(e) => { setBranchEditAddressZipcode(e.target.value) }} placeholder="Zipcode" />
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchEditAddressFax} onChange={(e) => { setBranchEditAddressFax(e.target.value) }} placeholder="Fax" />
                                </div>
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchEditAddressPhone} onChange={(e) => { setBranchEditAddressPhone(e.target.value) }} placeholder="Phone" />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-4">
                                    <input type="text" className="form-control form-control-solid" value={branchEditAddressWebsite} onChange={(e) => { setBranchEditAddressWebsite(e.target.value) }} placeholder="Website" />
                                </div>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">GSTIN </label>
                                <Select
                                    options={GSTINlist.map(x => { return { label: x.GstIn, value: x.unique_id } })}
                                    onChange={(val) => { setSelectedGstinIdEdit(val) }}
                                    value={selectedGstinIdEdit}
                                    placeholder="Select GSTIN..."
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                                <span className="form-text text-muted">Please select a GSTIN</span>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Transaction Series</label>
                                <Select
                                    options={tseriesList.map(x => { return { label: x.series_name, value: x.transaction_series_id } })}
                                    onChange={(val) => { setSelectedTseriesIdEdit(val) }}
                                    value={selectedTseriesIdEdit}
                                    isMulti
                                    placeholder="Select Transaction Series..."
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                                <span className="form-text text-muted">Please select a Transaction Series</span>
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Default Transaction Series</label>
                                <Select
                                    options={selectedTseriesIdEdit}
                                    onChange={(val) => { setSelectedDefaultTseriesIdEdit(val) }}
                                    value={selectedDefaultTseriesIdEdit}
                                    placeholder="Select Default Transaction Series..."
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                                <span className="form-text text-muted">Please select a Default Transaction Series</span>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleEditBranchModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={editBranchSubmitButtonState ? "on" : "off"} disabled={editBranchSubmitButtonState} onClick={() => { updateBranchSubmit() }}>
                            <span className="indicator-label">Save Changes</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default Branches;