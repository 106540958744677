import React from 'react';
import { Link } from 'react-router-dom'

function Footer() {

	return (
		<div className='bg-body container-fluid py-3 border-top border-primary d-flex align-items-center' style={{ position: 'sticky', bottom: 0, width: '-webkit-fill-available', maxHeight: "10%", height: "10%" }}>
			<div className="flex-grow-1 d-md-none d-flex justify-content-around gap-3">
				<Link className="btn btn-light-primary border border-primary fs-2 d-flex align-items-center justify-content-center" style={{ flex: 1 }} to={`/create-customer-invoice`}>
					<svg className='me-3' width="13" height="13" viewBox="0 0 11 11" fill="none">
						<path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="currentColor"></path>
					</svg>
					<span>Sales</span>
				</Link>
				{/* <button className="btn btn-primary px-3"><i className="bi bi-hand-index-thumb px-0 fs-2hx"></i></button> */}
				<Link className="btn btn-light-primary border border-primary fs-2 d-flex align-items-center justify-content-center" style={{ flex: 1 }} to={`/create-purchase-order`}>
					<svg className='me-3' width="13" height="13" viewBox="0 0 11 11" fill="none">
						<path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="currentColor"></path>
					</svg>
					<span>Purchase</span>
				</Link>
			</div>
			<div className="flex-grow-1 d-none d-md-flex flex-stack">
				<div className="text-dark">
					<span className="text-muted fw-semibold me-1">{new Date().getFullYear()}©</span>
					<a href="/" className="text-gray-800 text-hover-primary">ADOERP</a>
				</div>
				<ul className="menu menu-gray-600 menu-hover-primary fw-semibold">
					<li className="menu-item">
						<a href="/" className="menu-link px-2">About</a>
					</li>
					<li className="menu-item">
						<a href="/" className="menu-link px-2">Support</a>
					</li>
					<li className="menu-item">
						<a href="/" className="menu-link px-2">Purchase</a>
					</li>
				</ul>
			</div>

		</div>
	);
}
export default Footer;