import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from "react-router-dom"
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import { Modal } from "react-bootstrap";
import moment from 'moment';

import { getCookie } from '../common_component/cookie.js';
import { useTheme } from "./theme-provider.js"
import { useAuthData } from "./auth-provider.js"
import { Pagination } from 'antd';
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from '../common_component/PageError.js';

function SalesManager(props) {
    const [state, setstate] = useState(2)
    const { theme, dropdownStyleDark } = useTheme()
    const [failedPageMessage, setFailedPageMessage] = useState("Something went wrong. Please reload this page.")
    const [failedPageData, setFailedPageData] = useState(null)

    const [usersList, setUsersList] = useState([])
    const [usersListApiState, setUsersListApiState] = useState(false)

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)

    const [allUserList, setAllUserList] = useState([]);
    const [UserRoleList, setUserRoleList] = useState([]);
    const [UserProfileList, setUserProfileList] = useState([]);

    // const [creditorList, setCreditorList] = useState([])
    // const [creditorListAPIState, setCreditorListAPIState] = useState(false)
    const [vendorList, setVendorList] = useState([])
    const [vendorListAPIState, setVendorListAPIState] = useState(false)
    const [manufacturerList, setManufacturerList] = useState([])
    const [manufacturerListAPIState, setManufacturerListAPIState] = useState(false)


    const [addSalesManagerModalShow, setAddSalesManagerModalShow] = useState(false)

    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Gender, setGender] = useState("");
    const [Skip_mail, setSkip_mail] = useState(false);
    const [Dob_date, setDob_date] = useState("");
    const [Joining_date, setJoining_date] = useState("");
    const [Department, setDepartment] = useState("");
    const [Submit_to, setSubmit_to] = useState("");
    const [DepartmentList, setDepartmentList] = useState([]);
    const [Role, setRole] = useState(null);
    const [roleName, setRoleName] = useState("")
    const [Profile, setProfile] = useState(null);
    const [profileName, setProfileName] = useState("")
    const [addSelectedCreditors, setAddSelectedCreditors] = useState([])
    const [addSelectedVendors, setAddSelectedVendors] = useState([])
    const [addSelectedManufacturers, setAddSelectedManufacturers] = useState([])

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);


    const [status, setstatus] = useState("");
    const [Id, setId] = useState("");
    const [editStatusModalShow, setEditStatusModalShow] = useState(false)


    const [assignSuppliersModalShow, setAssignSuppliersModalShow] = useState(false)
    const [editSelectedCreditors, setEditSelectedCreditors] = useState([])
    const [editSelectedVendors, setEditSelectedVendors] = useState([])
    const [editSelectedManufacturers, setEditSelectedManufacturers] = useState([])
    const [salesManagerEmail, setSalesManagerEmail] = useState("")
    const [editRoleName, setEditRoleName] = useState("")

    const [existingUserEmailFlag, setExistingUserEmailFlag] = useState(false)

    const [managerSupplierAPIState, setManagerSupplierAPIState] = useState(false)

    const apiController = useRef(new AbortController())

    var page_name = "sales-manager";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getUserList(cp, rpp, search_v) {
        try {
            if (usersListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setUsersListApiState(true)

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-sales-manager-datatable`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setUsersListApiState(false);
            if (body.operation === "success") {
                setUsersList(body.data.list);
                setListCount(body.data.count);
                setUserRoleList([{ label: "Sales Manager", value: body.data.role_id }]);
                setRoleName("Sales Manager");
                setRole({ label: "Sales Manager", value: body.data.role_id });
                setUserProfileList([{ label: "Sales Manager", value: body.data.profile_id }]);
                setProfile({ label: "Sales Manager", value: body.data.profile_id });
                setProfileName("Sales Manager");
            }
            else {
                setFailedPageData(body.data)
                throw body.message
            }
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log(err)
            } else {
                throw err
            }
        }
    }

    async function getAllUserList() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        let temp_dropdown = body.filter(x => x.log_status === "Active").map((obj) => { return { label: obj.name + " - " + obj.user_id, value: obj.user_id } })
        setAllUserList(temp_dropdown)
    }

    async function getDeparmentDetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-department-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        var temp_dropdown = [];
        body.map((obj) => {
            if (obj.status === "Active") {
                var temp_obj = {};
                temp_obj.label = obj.department_name;
                temp_obj.value = obj.department_id;
                temp_dropdown.push(temp_obj);
            }
        })
        setDepartmentList(temp_dropdown);
    }

    // async function getCreditorBySearch(search_v) {
    //     setCreditorListAPIState(true)
    //     const response = await fetch(
    //         `${process.env.REACT_APP_SERVER_URL}/get-creditor-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_v, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //     );
    //     const body = await response.json();

    //     setCreditorList(body.data.list)
    //     setCreditorListAPIState(false)
    // }

    async function getVendorBySearch(search_v) {
        setVendorListAPIState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-vendor-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_v, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        setVendorList(body.data.list)
        setVendorListAPIState(false)
    }

    async function getManufacturerBySearch(search_v) {
        setManufacturerListAPIState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_v, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        setManufacturerList(body.data.list)
        setManufacturerListAPIState(false)
    }

    async function getManagerSuppliers(approver_email) {
        setManagerSupplierAPIState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-suppliers-of-sales-manager`, { method: 'POST', body: JSON.stringify({ "email": user.email, "approver_email": approver_email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        console.log(body.data)
        if (body.operation == "success") {
            if (body.data.hasOwnProperty("creditors")) {
                setEditSelectedCreditors(body.data.creditors)
            }
            if (body.data.hasOwnProperty("vendors")) {
                setEditSelectedVendors(body.data.vendors)
            }
            if (body.data.hasOwnProperty("manufacturers")) {
                setEditSelectedManufacturers(body.data.manufacturers)
            }
        }
        setManagerSupplierAPIState(false)
    }


    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getUserList(currentPage, rowsPerPage, searchValue);
            let p2 = getAllUserList();
            let p3 = getDeparmentDetails();
            //let p4 = getCreditorBySearch();
            let p5 = getVendorBySearch();
            let p6 = getManufacturerBySearch();

            Promise.all([p1, p2, p3, p5, p6])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    setFailedPageMessage(err)

                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getUserList(currentPage, rowsPerPage, searchValue)
        }
    }, [currentPage, rowsPerPage, searchValue])

    const handleEditModalShow = async (obj) => {
        let tempUser = obj;
        if (tempUser !== undefined) {
            setSalesManagerEmail(tempUser.user_id)
            setEditRoleName(tempUser.role)
            setAssignSuppliersModalShow(true)

            await getManagerSuppliers(tempUser.user_id)
        }
    }

    const submitAssignSuppliers = () => {

        let object = {};
        object.creditor_list = editSelectedCreditors
        object.vendor_list = editSelectedVendors
        object.manufacturer_list = editSelectedManufacturers
        object.approver_email = salesManagerEmail
        object.role_name = editRoleName

        object.organization_id = organization_id;
        object.page_name = page_name;

        if (object.approver_email == "") {
            swal("Warning!", "Email cannot be a empty", "warning");
            return false;
        }
        if ((!object.creditor_list.length) && (!object.vendor_list.length) && (!object.manufacturer_list.length)) {
            swal("Warning!", "Atleast one supplier(vendor, manufacturer) must be selected", "warning");
            return false;
        }

        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/assign-sales-manager-to-suppliers`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {

                swal("Great!", "Suppliers Assigned Successfully!", "success")
                    .then((value) => {
                        setAssignSuppliersModalShow(false)
                        handleEditModalClose();
                    });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }

    const handleEditModalClose = () => {
        setAssignSuppliersModalShow(false)

        setEditSelectedCreditors([])
        setEditSelectedVendors([])
        setEditSelectedManufacturers([])
        setSalesManagerEmail("")
        setEditRoleName("")
    }


    const updateStatus = (user) => {
        setId(user.unique_id)
        setstatus(user.log_status);
        setEditStatusModalShow(true)
    }

    const submitStatus = () => {
        var values = {
            "id": Id,
            "status": status,
            "organization_id": organization_id,
            "page_name": page_name
        };

        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-user`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            handleStatusModalClose()
            if (res.data && res.data.operation == "success") {
                getUserList(currentPage, rowsPerPage, searchValue);
                swal("Great!", "Status changed successfully!", "success")
            }
            else {
                swal("Oops!", "Something went wrong!", "error").then(() => { window.location.reload(); });
            }
        });

    }

    const handleStatusModalClose = async (id) => {
        setstatus("");
        setId("");
        setEditStatusModalShow(false)
    }


    const sendInvitation = (id) => {

        var values = { "id": id, "page_name": page_name, "organization_id": organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/send-invitation-mail`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Mail sent successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }

    const submitUser = (e) => {

        var object = {};
        object.organization_id = organization_id;
        object.name = Name;
        object.email_id = Email;
        object.mobile = Mobile;
        object.Employee_id = "";
        object.Submit_to = Submit_to;
        object.role = Role.value;
        object.profile = Profile.value;
        object.service = "Distributor";
        object.Dob_date = Dob_date;
        object.department = Department;
        object.Joining_date = Joining_date;
        object.Gender = Gender;
        object.Policy = "";
        object.Skip_mail = Skip_mail;
        object.Designation = "";
        object.page_name = page_name;

        object.roleName = roleName;
        object.profileName = profileName;

        object.creditor_list = addSelectedCreditors
        object.vendor_list = addSelectedVendors
        object.manufacturer_list = addSelectedManufacturers

        if (object.name == "") {
            swal("Warning!", "Name cannot be a empty", "warning");
            return false;
        }
        var exp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (object.email_id == "") {
            swal("Warning!", "Please provide an email", "warning");
            return false;
        }
        else if (!object.email_id.match(exp)) {
            swal("Warning!", "Please provide a valid email", "warning");
            return false;
        }

        for (var i = 0; i < allUserList.length; ++i) {
            if (allUserList[i].value == object.email_id) {
                swal("Oops!", "This email id already connected to your organization, try different one!", "error");
                return false;
            }
        }

        if (object.role == "") {
            swal("Warning!", "Role field cannot be a empty", "warning");
            return false;
        }
        if ((!object.creditor_list.length) && (!object.vendor_list.length) && (!object.manufacturer_list.length)) {
            swal("Warning!", "Atleast one supplier(vendor, manufacturer) must be selected", "warning");
            return false;
        }
        // if (existingUserEmailFlag) {
        //     swal("Warning!", "This user Email is already in use", "warning");
        //     return false;
        // }

        // console.log(object)
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-user`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            handleAddModalClose()
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Sales Manager added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "same_user_name") {
                swal("Oops!", "Use a different User name!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        }).catch(function (error) {
            console.log(error)
        });

    }

    const handleAddModalClose = () => {
        setAddSalesManagerModalShow(false)

        setName("")
        setEmail("")
        setMobile("")
        setGender("")
        setSkip_mail(false)
        setDob_date("")
        setJoining_date("")
        setDepartment("")
        setSubmit_to("")
        setDepartmentList([])
        // setRole(null)
        // setRoleName("")
        // setProfile(null)
        // setProfileName("")
        setAddSelectedCreditors([])
        setAddSelectedVendors([])
        setAddSelectedManufacturers([])
    }

    useEffect(() => {
        if (state == 1) {
            let flag = allUserList.find(x => x.value == Email.toLowerCase())
            if (flag != undefined) {
                setExistingUserEmailFlag(true)
            }
            else {
                setExistingUserEmailFlag(false)
            }
        }
    }, [Email])


    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Sales Manager</h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className="btn btn-primary" data-kt-indicator={state != 1 ? "on" : "off"} disabled={state != 1} onClick={() => { setAddSalesManagerModalShow(true) }}>
                                <span className="indicator-label">Add New</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className="mb-5"><input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' /></div>
                                            <div className="mb-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={listCount}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 min-w-1000px" style={{ minWidth: "1000px" }}>
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-80px">Name</th>
                                                            <th className="min-w-75px">User Id</th>
                                                            <th className="min-w-75px">Role</th>
                                                            <th className="min-w-120px">Profile</th>
                                                            <th className="min-w-120px">Timestamp</th>
                                                            <th className="min-w-60px">Status</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            usersListApiState ?
                                                                <tr>
                                                                    <td colSpan={7} className="text-center pt-10">
                                                                        <div className="spinner-border text-primary"></div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                usersList.length > 0 ?
                                                                    usersList.map((obj) => {
                                                                        return (
                                                                            <tr key={obj.unique_id}>
                                                                                <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.name}</span></td>
                                                                                <td><span className="badge badge-secondary">{obj.user_id}</span></td>
                                                                                <td><span className="badge badge-primary">{obj.role}</span></td>
                                                                                <td><span className="badge badge-primary">{obj.profile}</span></td>
                                                                                <td><span className="text-dark fw-bold d-block mb-1 fs-6">{moment(obj.log_date_time).format("lll")}</span></td>
                                                                                <td><StatusBadge status={obj.user_status} entity={""} /></td>
                                                                                <td className="text-end">
                                                                                    <div className="dropdown" style={{ position: "unset" }}>
                                                                                        {
                                                                                            write &&
                                                                                            <>
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                    <li><div className="dropdown-item cursor-pointer" onClick={(e) => { updateStatus(obj) }} >Change status</div></li>
                                                                                                    <li><div className="dropdown-item cursor-pointer" onClick={(e) => { handleEditModalShow(obj) }} >Assign Suppliers</div></li>
                                                                                                    {obj.message_flag == "later" && <li><div className="dropdown-item cursor-pointer" onClick={(e) => { sendInvitation(obj.unique_id) }} >Send Invitation</div></li>}
                                                                                                    {/* <li className="py-1"><span onClick={(e) => { editDetails(obj); }} className="dropdown-item cursor-pointer">Edit Details</span></li> */}
                                                                                                </ul>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={7} className="text-center">
                                                                            <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                        </td>
                                                                    </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="mt-5 d-flex justify-content-center">
                                                <Pagination
                                                    total={listCount}
                                                    onChange={(page, pageSize) => {
                                                        setCurrentPage(page);
                                                        setRowsPerPage(pageSize);
                                                    }}
                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                    pageSize={rowsPerPage}
                                                    current={currentPage}
                                                    showSizeChanger={true}
                                                    showQuickJumper={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        state === 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <PageError message={failedPageMessage}>
                                                <div>
                                                    {failedPageData.role && <Link to={`/roles`} className="btn btn-sm btn-primary rounded cursor-pointer mx-3">Go to Roles</Link>}
                                                    {failedPageData.profile && <Link to={`/profiles`} className="btn btn-sm btn-primary rounded cursor-pointer mx-3">Go to Profiles</Link>}
                                                </div>
                                            </PageError>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div >

            <Modal show={addSalesManagerModalShow} onHide={() => { handleAddModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add Sales Manager</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-5">
                            <div className="col-md-6 ">
                                <label className="required fs-5 fw-bold mb-2">Name</label>
                                <input type="text" className="form-control" value={Name} onChange={(e) => { setName(e.target.value) }} />
                            </div>
                            <div className="col-md-6 ">
                                <label className=" fs-5 fw-bold mb-2">Department Name</label>
                                <Select
                                    options={DepartmentList}
                                    onChange={(event) => { setDepartment(event.value) }}
                                    value={DepartmentList.filter(option => option.value == Department).length != 0 && DepartmentList.filter(option => option.value == Department)[0]}
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    placeholder="--Choose a Department--"
                                />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-md-6 ">
                                <label className="required fs-5 fw-bold mb-2">Email Address</label>
                                <input type="email" className="form-control" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
                                {existingUserEmailFlag && <strong className='text-danger'>This user Email is already in use</strong>}
                            </div>
                            <div className="col-md-6 ">
                                <label className=" fs-5 fw-bold mb-2">Mobile</label>
                                <input type="text" className="form-control" value={Mobile} onChange={(e) => { setMobile(e.target.value) }} />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-md-6 ">
                                <label className="required fs-5 fw-bold mb-2">Role</label>
                                <Select
                                    options={UserRoleList}
                                    value={Role}
                                    isDisabled
                                />
                            </div>
                            <div className="col-md-6 ">
                                <label className="required fs-5 fw-bold mb-2">Profile</label>
                                <Select
                                    options={UserProfileList}
                                    value={Profile}
                                    isDisabled
                                />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-md-6 ">
                                <label className=" fs-5 fw-bold mb-2">Gender</label>
                                <Select
                                    options={[{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }]}
                                    onChange={(event) => { setGender(event.value) }}
                                    value={[{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }].filter(option => option.value == Gender).length != 0 && [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }].filter(option => option.value == Gender)[0]}
                                    placeholder="--Select--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                            <div className="col-md-6 ">
                                <label className=" fs-5 fw-bold mb-2">Submits To</label>
                                <Select
                                    options={allUserList}
                                    onChange={(event) => { setSubmit_to(event.value) }}
                                    value={allUserList.filter(option => option.value == Submit_to).length != 0 && allUserList.find(option => option.value == Submit_to)}
                                    placeholder="--Select--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <div className="col-md-6 ">
                                <label className=" fs-5 fw-bold mb-2">Date of birth</label>
                                <input type="date" className="form-control" placeholder="Date of birth" value={Dob_date} onChange={(e) => { setDob_date(e.target.value); }} />
                            </div>
                            <div className="col-md-6 ">
                                <label className=" fs-5 fw-bold mb-2">Date of Joining</label>
                                <input type="date" className="form-control" placeholder="Date of joining" value={Joining_date} onChange={(e) => { setJoining_date(e.target.value); }} />
                            </div>
                        </div>

                        <div className="row mb-5">
                            <label className="form-check form-check-custom form-check-solid align-items-start">
                                <input className="form-check-input me-3" type="checkbox" checked={Skip_mail} onChange={(e) => { setSkip_mail(e.target.checked); }} />
                                <span className="form-check-label d-flex flex-column align-items-start">
                                    <span className="fw-bolder fs-5 mb-0">Skip invitation email</span>
                                    {Skip_mail && <span className="text-muted fs-6">You can invite them later from Admin View - User Management.</span>}
                                </span>
                            </label>
                        </div>

                        {/* <div className="row mb-5">
                            <div className="col">
                                <label className=" fs-5 fw-bold mb-2">Creditors</label>
                                <Select
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    isMulti
                                    options={creditorList.map(x => { return { label: x.creditor_display_name, value: x.cid } })}
                                    onChange={(val) => { setAddSelectedCreditors(val) }}
                                    value={addSelectedCreditors}
                                    placeholder="--Select Creditors--"
                                    onInputChange={(val) => {
                                        if (val.length > 3) {
                                            getCreditorBySearch(val)
                                        }
                                    }}
                                    onMenuClose={() => {
                                        getCreditorBySearch()
                                    }}
                                    isLoading={creditorListAPIState}
                                />
                            </div>
                        </div> */}
                        <div className="row mb-5">
                            <div className="col">
                                <label className=" fs-5 fw-bold mb-2">Vendors</label>
                                <Select
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    isMulti
                                    options={vendorList.map(x => { return { label: x.vendor_display_name, value: x.cid } })}
                                    onChange={(val) => { setAddSelectedVendors(val) }}
                                    value={addSelectedVendors}
                                    placeholder="--Select Vendors--"
                                    onInputChange={(val) => {
                                        if (val.length > 3) {
                                            getVendorBySearch(val)
                                        }
                                    }}
                                    onMenuClose={() => {
                                        getVendorBySearch()
                                    }}
                                    isLoading={vendorListAPIState}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col">
                                <label className=" fs-5 fw-bold mb-2">Manufacturers</label>
                                <Select
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    isMulti
                                    options={manufacturerList.map(x => { return { label: x.manufacturer_display_name, value: x.cid } })}
                                    onChange={(val) => { setAddSelectedManufacturers(val) }}
                                    value={addSelectedManufacturers}
                                    placeholder="--Select Manufacturers--"
                                    onInputChange={(val) => {
                                        if (val.length > 3) {
                                            getManufacturerBySearch(val)
                                        }
                                    }}
                                    onMenuClose={() => {
                                        getManufacturerBySearch()
                                    }}
                                    isLoading={manufacturerListAPIState}
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-info rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={(e) => { submitUser(e) }}>
                            <span className="indicator-label">Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={editStatusModalShow} onHide={() => { handleStatusModalClose() }} size="lg" centered>
                <Modal.Header><Modal.Title>Change Status</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            Current Status is : {(status == "Active" || status == "ACTIVE") ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                            <h3>Are you sure about changing status?</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { handleStatusModalClose() }}>Cancel</button>
                    {
                        write &&
                        <button type="button" className={`btn ${(status == "Active" || status == "ACTIVE") ? "btn-danger" : "btn-success"}`} onClick={(e) => { submitStatus(); }} data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}>
                            <span className="indicator-label">{(status == "Active" || status == "ACTIVE") ? "Deactivate" : "Activate"}</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={assignSuppliersModalShow} onHide={() => { handleEditModalClose() }} size="lg" centered>
                <Modal.Header><Modal.Title>Assign Suppliers</Modal.Title></Modal.Header>
                <Modal.Body>
                    <div className="mb-5">
                        {
                            managerSupplierAPIState ? <div className='d-flex justify-content-center'><div className="spinner-border text-primary"></div></div> :
                                <>
                                    {/* <div className='row'>
                                        <div className='col-12'>
                                            <label className=" fs-5 fw-bold mb-2">Creditors</label>
                                            <Select
                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                isMulti
                                                options={creditorList.map(x => { return { label: x.creditor_display_name, value: x.cid } })}
                                                onChange={(val) => { setEditSelectedCreditors(val) }}
                                                value={editSelectedCreditors}
                                                placeholder="--Select Creditors--"
                                                onInputChange={(val) => {
                                                    if (val.length > 3) {
                                                        getCreditorBySearch(val)
                                                    }
                                                }}
                                                onMenuClose={() => {
                                                    getCreditorBySearch()
                                                }}
                                                isLoading={creditorListAPIState}
                                            />
                                        </div>
                                    </div> */}
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className=" fs-5 fw-bold mb-2">Vendors</label>
                                            <Select
                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                isMulti
                                                options={vendorList.map(x => { return { label: x.vendor_display_name, value: x.cid } })}
                                                onChange={(val) => { setEditSelectedVendors(val) }}
                                                value={editSelectedVendors}
                                                placeholder="--Select Vendors--"
                                                onInputChange={(val) => {
                                                    if (val.length > 3) {
                                                        getVendorBySearch(val)
                                                    }
                                                }}
                                                onMenuClose={() => {
                                                    getVendorBySearch()
                                                }}
                                                isLoading={vendorListAPIState}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className=" fs-5 fw-bold mb-2">Manufacturers</label>
                                            <Select
                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                isMulti
                                                options={manufacturerList.map(x => { return { label: x.manufacturer_display_name, value: x.cid } })}
                                                onChange={(val) => { setEditSelectedManufacturers(val) }}
                                                value={editSelectedManufacturers}
                                                placeholder="--Select Manufacturers--"
                                                onInputChange={(val) => {
                                                    if (val.length > 3) {
                                                        getManufacturerBySearch(val)
                                                    }
                                                }}
                                                onMenuClose={() => {
                                                    getManufacturerBySearch()
                                                }}
                                                isLoading={manufacturerListAPIState}
                                            />
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleEditModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-info rounded" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={(e) => { submitAssignSuppliers() }}>
                            <span className="indicator-label">Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SalesManager;