const approveButtonShow = (status, user_role, user_email, obj) => {
    let t1 = obj.approval_info
    let t2 = obj.add_status
    let t3 = obj.approval_level

    if (t2 == status) {
        if (user_role === "Admin") return true

        if (t1.type == "multilevel_approval") {
            let tl = t1.value.find(x => x.level == (t3 == null ? 1 : t3 + 1)).user_value
            if (tl.includes(user_email)) {
                return true
            }
        } else if (t1.type == "custom_approval") {
            let ty = t1.value[0]
            if (ty.user_type == "Group User") {
                if (ty.user_value.includes(user_role)) {
                    return true
                }
            }
            else if (ty.user_type == "Individual User") {
                if (ty.user_value.includes(user_email)) {
                    return true
                }
            }
        }
    }
    return false
}
export default approveButtonShow