import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import { v1 as uuid } from 'uuid'
import Select, { components } from 'react-select';
import { Link, Redirect } from 'react-router-dom'

import s_a from './common_arr/states-arr.js';
import country_arr from './common_arr/countries-arr.js';
import { getCookie,setCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

export const EditExpenseDetails = (props) => {
    const [id, setId] = useState("");
    let [state, setstate] = useState(2);
    const [gstInListModalShow, setGstInListModalShow] = useState(false);
    const [ExpenseNumber, setExpenseNumber] = useState("");
    const [CalculationMode, setCalculationMode] = useState({ label: "Tax Exclusive", value: "Tax_Exclusive" });
    const [CalculationModeList, setCalculationModeList] = useState([{ label: "Tax Inclusive", value: "Tax_Inclusive" }, { label: "Tax Exclusive", value: "Tax_Exclusive" }]);
    const [GSTINNumber, setGSTINNumber] = useState("");
    const [sourceOfSupply, setSourceOfSupply] = useState(null)
    const [selectedPlaceOfSupply, setSelectedPlaceOfSupply] = useState(null);
    const [SelectedDestinationPlace, setSelectedDestinationPlace] = useState(null);
    const [SupplyStateOptions, setSupplyStateOptions] = useState([]);
    const [taxGroupList, setTaxGroupList] = useState([]);
    const [ExpenseTypeList, setExpenseTypeList] = useState([{ label: "Vendor", value: "Vendor" }, { label: "Customer", value: "Customer" }, { label: "Manufacturer", value: "Manufacturer" }]);

    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [BaseCurrency, setBaseCurrency] = useState(null);
    const [referenceNumber, setReferenceNumber] = useState("");
    const [gstTreatment, setgstTreatment] = useState(null);
    const [Expense_category, setExpense_category] = useState(false);
    const [ExpenseType, setExpenseType] = useState(null);
    const [ExpenseValue, setExpenseValue] = useState(null);
    const [PaidThroughAccount, setPaidThroughAccount] = useState(null)
    const [ExpenseValueList, setExpenseValueList] = useState([]);
    const [TotalExpenseValueList, setTotalExpenseValueList] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [Expense_status, setExpense_status] = useState("");
    const [selectedTransactionSeries, setSelectedTransactionSeries] = useState(null);
    const [branchList, setBranchlist] = useState([]);
    const [transactionSeriesList, setTransactionSeriesList] = useState([]);
    const [PaidThroughAccountlist, setPaidThroughAccountlist] = useState([]);
    const [decimalPlace, setDecimalPlace] = useState(3);

    const [AccountFlag, setAccountFlag] = useState("");

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [selectedGstin, setSelectedGstin] = useState(null)
    const [Expense_date, setExpense_date] = useState("");
    const [Expense_details, setExpense_details] = useState([]);
    let [timezone_change, set_timezone_change] = useState("+00:00");
    const [AccountSelectionList, setAccountSelectionList] = useState([]);
    const [ExpenseValueApiState, setExpenseValueApiState] = useState(false);
    const [AllAccountHeadList, setAllAccountHeadList] = useState([]);

    const [currencyList, setCurrencyList] = useState([]);
    const [currencyExchangeRates, setCurrencyExchangeRates] = useState([]);
    const [submitButtonState, setSubmitButtonState] = useState(false);

    const [productCodeModalShow, setProductCodeModalShow] = useState(false)
    const [productCodeItemId, setProductCodeItemId] = useState(null)
    const [productCodeItemType, setProductCodeItemType] = useState(null)
    const [productCodeSearch, setProductCodeSearch] = useState("")
    const [productCodeList, setProductCodeList] = useState([])
    const [productCodeListApiState, setProductCodeListApiState] = useState(false)

    const [subTotal, setSubTotal] = useState(0);
    const [taxTotal, setTaxTotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);

    const [upsertGstInModalShow, setUpsertGstInModalShow] = useState(false);
    const [upsertGstInId, setUpsertGstInId] = useState("");
    const [upsertGstInTreatmentList, setUpsertGstInTreatmentList] = useState([{ label: "Registered Business-Regular", value: "Registered Business-Regular", description: "A business that is registered under GST" }, { label: "Registered Business-Composition", value: "Registered Business-Composition", description: "A business that is registered under composition scheme in GST" }, { label: "Special Economic Zone", value: "Special Economic Zone", description: "Business that is located in a special economic zone" }, { label: "Deeemed Export", value: "Deeemed Export", description: "Supply of goods to an export oriented unit" }, { label: "SEZ Devloper", value: "SEZ Devloper", description: "An organization/person who owns atleast 26% of the equity in creating business units" }])
    const [upsertGstInTreatment, setUpsertGstInTreatment] = useState(null)
    const [upsertGstInNumber, setUpsertGstInNumber] = useState("");
    const [upsertGstInLegalName, setUpsertGstInLegalName] = useState("");
    const [upsertGstInTradeName, setUpsertGstInTradeName] = useState("");
    const [upsertGstInSelectedPlaceOfSupply, setUpsertGstInSelectedPlaceOfSupply] = useState(null)
    const [upsertGstInSubmitButtonState, setUpsertGstInSubmitButtonState] = useState(false);
    const [ExpenseGstinList, setExpenseGstinList] = useState([]);

    const [References, setReferences] = useState("");
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [tempexpenseAccounts, settempExpenseAccounts] = useState([]);
    const [expenseAccounts, setExpenseAccounts] = useState([])

    var page_name = "edit-expense-details";

    const { user, read, write, visibility, organization_id, user_role, actionPermissionDetails } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const trimDecimalPlaces = (amt, decimal_place) => {
        return Math.round(amt * Math.pow(10, decimal_place)) / Math.pow(10, decimal_place)
    }

    useEffect(() => {
        if (selectedCurrency !== null && currencyList.length > 0) {
            setDecimalPlace(currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place);
        }
    }, [selectedCurrency, currencyList])


    useEffect(() => {
        if ((Expense_details.length > 0 && ExpenseValueList.length > 0)) {
            setExpenseValue({ label: ExpenseValueList.find(x => x.value == Expense_details[0].expense_user_id).label, value: Expense_details[0].expense_user_id });
            setstate(1);
        }
    }, [Expense_details, ExpenseValueList])


    useEffect(() => {
        if ((selectedBranch != null) && (transactionSeriesList.length > 0)) {
            let tb = branchList.find(x => x.branch_id == selectedBranch.value);
            setSelectedTransactionSeries({ label: transactionSeriesList.find(x => x.transaction_series_id == tb.default_transaction_series).series_name, value: tb.default_transaction_series })
            getSubSeriesData(tb.default_transaction_series);
        }
    }, [selectedBranch, transactionSeriesList])

    async function getExpenseUser(search_value, key) {
        let api = "";
        if (key == "Customer") {
            api = "get-customer-by-search";
        }
        else if (key == "Dealer") {
            api = "get-dealer-by-search";
        }
        if (key == "Creditor") {
            api = "get-creditor-by-search";
        }
        if (key == "Vendor") {
            api = "get-vendor-by-search";
        }
        if (key == "Manufacturer") {
            api = "get-manufacturer-by-search";
        }
        setExpenseValueApiState(true);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/${api}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation === "success") {
            setTotalExpenseValueList(body.data.list);
            let value = body.data.list;
            {
                (key == "Customer") ?
                    value = value.map(x => { return { label: x.customer_display_name, value: x.cid } })
                    :
                    (key == "Dealer") ?
                        value = value.map(x => { return { label: x.dealer_display_name, value: x.cid } })
                        :
                        (key == "Creditor") ?
                            value = value.map(x => { return { label: x.creditor_display_name, value: x.cid } })
                            :
                            (key == "Vendor") ?
                                value = value.map(x => { return { label: x.vendor_display_name, value: x.cid } })
                                :
                                value = value.map(x => { return { label: x.manufacturer_display_name, value: x.cid } })
            }
            setExpenseValueList(value);
            setExpenseValueApiState(false);
        }
        else {
            throw "dealer list api error"
        }
    }

    async function getExpenseUserGstinDetails(val, key) {
        console.log("591", key)
        let api = "";
        if (key == "Customer") {
            api = "get-customer-tax-details";
        }
        else if (key == "Dealer") {
            api = "get-dealer-tax-details";
        }
        if (key == "Creditor") {
            api = "get-creditor-tax-details";
        }
        if (key == "Vendor") {
            api = "get-vendor-tax-details";
        }
        if (key == "Manufacturer") {
            api = "get-manufacturer-tax-details";
        }
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/${api}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            let t = body.data.find(x => x.flag == "Primary")
            if (t) {
                setSelectedGstin(t)
                setgstTreatment({ label: t.gst_treatment, value: t.gst_treatment });
                setGSTINNumber(t.GstIn);
                //setSelectedPlaceOfSupply({ label: t.place_of_supply, value: t.place_of_supply })
                setSelectedDestinationPlace({ label: t.place_of_supply, value: t.place_of_supply })
            }
            else {
                let temp = body.data
                temp.sort((a, b) => new Date(a.add_date_time) - new Date(b.add_date_time))
                let temp2 = temp[0]
                setSelectedGstin(temp2)
                setgstTreatment({ label: temp2.gst_treatment, value: temp2.gst_treatment });
                setGSTINNumber(temp2.GstIn);
                //setSelectedPlaceOfSupply({ label: temp2.place_of_supply, value: temp2.place_of_supply });
                setSelectedDestinationPlace({ label: temp2.place_of_supply, value: temp2.place_of_supply });
            }

            setExpenseGstinList(body.data);
        }
        else {
            throw "tax details api error"
        }
    }

    async function getbasecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            console.log(body);
            setSelectedCurrency({ label: body[0].currency_code, value: body[0].currency_id });
            setBaseCurrency({ label: body[0].currency_code, value: body[0].currency_id })
        }
    }

    async function getcurrencydetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length != 0) {
            setCurrencyList(body.data)
        }
    }

    async function getlivecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length != 0) {
            setCurrencyExchangeRates(body[0])
        }
    }

    async function getExpenseDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/expense-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            if (body.data[0].add_status == "EXPENSE_DRAFT" || body.data[0].add_status == "EXPENSE_PENDING_APPROVAL") {
                setSubTotal(trimDecimalPlaces(body.data[0].account_details.reduce((p, o) => { return p + Number(o.original_amount) }, 0), decimalPlace));
                setTaxTotal(trimDecimalPlaces(body.data[0].account_details.reduce((p, o) => { return p + Number(o.tax_amount) }, 0), decimalPlace));
                setGrandTotal(trimDecimalPlaces(body.data[0].account_details.reduce((p, o) => { return p + Number(o.total_amount) }, 0), decimalPlace))
                settempExpenseAccounts(body.data[0].account_details);
                setExpense_status(body.data[0].add_status);
                setSelectedBranch({ label: body.data[0].branch_name, value: body.data[0].branch_id });
                //setSelectedBranch({label:body.data[0].branch_name,value:body.data[0].branch_id});
                if (body.data[0].expense_type == "Customize Expense") {
                    setExpenseType({ label: body.data[0].expense_for, value: body.data[0].expense_for });
                    setExpense_category(true);
                }
                else {
                    setExpense_category(false);
                }
                //console.log("678",body.data[0]);
                setExpense_date(moment(body.data[0].expense_date).format('YYYY-MM-DD'));
                setSelectedTransactionSeries({ label: body.data[0].series_name, value: body.data[0].transaction_series_id });
                setSelectedCurrency({ label: body.data[0].currency.currency_code, value: body.data[0].currency.currency_id });
                setExpenseNumber(body.data[0].expense_number);

                //setPaidThroughAccount({label:body.data[0].paid_through_account_head,value:body.data[0].paid_through_account});
                setSelectedPlaceOfSupply({ label: body.data[0].source_of_supply, value: body.data[0].source_of_supply });
                setSelectedDestinationPlace({ label: body.data[0].destination_of_supply, value: body.data[0].destination_of_supply });
                setReferenceNumber(body.data[0].reference);
                if (body.data[0].calculation_method == "Tax_Exclusive") {
                    setCalculationMode({ label: "Tax Exclusive", value: "Tax_Exclusive" });
                }
                else {
                    setCalculationMode({ label: "Tax Exclusive", value: "Tax_Exclusive" });
                }
                setSelectedGstin(body.data[0].gstin_details);
                setExpense_details(body.data);
            }
            else {
                swal("Oops!", "This page can't be accesible after a specific status.", "error").then(() => { window.location.href = "/expense-list" })
            }
        }
        else {
            swal("Oops!", "No data available according to this id.", "error").then(() => { window.location.href = "/expense-list" })
        }
    }

    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setBranchlist(body.data);
            let temp = body.data.filter(option => option.default_branch == "yes")
            if (temp.length > 0) {
                //console.log(temp[0]);
                setSelectedBranch({ label: temp[0].branch_name, value: temp[0].branch_id });
                setSelectedPlaceOfSupply({ label: temp[0].branch_address_state, value: temp[0].branch_address_state });
                //console.log({ label: temp[0].branch_address_state, value: temp[0].branch_address_state });
                //getDefaultTransactionSeries(temp[0].branch_id);
            }
        }
        else {
            throw "branch list api error"
        }
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    let timezone_change = body.data[0].timezone_value;
                    set_timezone_change(timezone_change);
                    setExpense_date(moment().utcOffset(timezone_change).format('YYYY-MM-DD'));
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    async function selectiveaccounthead() {
        let arr = [
            { "category_name": "Assets", "headArray": [] },
            { "category_name": "Equity", "headArray": [] },
            { "category_name": "Expense", "headArray": [] },
            { "category_name": "Income", "headArray": [] },
            { "category_name": "Liability", "headArray": [] },
            { "category_name": "Bank", "headArray": [] }
        ]
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/selective-account-head`, { method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "headRequest": arr, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        //console.log(body.data)

        if (body.operation === "success") {
            let temp_dropdown_paid = [];
            let temp_dropdown = [];
            let temp_dropdown_all = [];
            body.data.map((obj) => {
                var temp_obj = {};
                temp_obj.label = obj.category_name;
                temp_obj.id = obj.cid;
                let temp_dropdown1 = [];
                obj.headArray.map((obj1) => {
                    var temp_obj1 = {};
                    temp_obj1.label = obj1.name;
                    temp_obj1.value = obj1.hid;
                    temp_dropdown1.push(temp_obj1);
                    temp_dropdown_all.push(temp_obj1);
                })
                temp_obj.options = temp_dropdown1;
                if (obj.category_name === "Assets" || obj.category_name === "Expense" || obj.category_name === "Liability") {
                    temp_dropdown.push(temp_obj);
                }
                else {
                    temp_dropdown_paid.push(temp_obj);
                }
            })
            setAccountSelectionList(temp_dropdown);
            setAllAccountHeadList(temp_dropdown_all);
            //setPaidThroughAccountlist(temp_dropdown_paid);
            // console.log(temp_dropdown)
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    async function getSubSeriesData(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-series-data-by-name`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_series_name": "expense_record", "transaction_series_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setExpenseNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
        }
        else {
            throw "get sub series data api error"
        }
    }

    async function getTaxGroupDetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if ((body.operation == "success") && (body.data.length > 0)) {
            setTaxGroupList(body.data);
        }
    }

    async function getuserdetails() {
        var object = {};
        object.email = user.email;
        setExpenseValueApiState(true);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.length > 0) {
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.name;
                    temp_obj.value = obj.user_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setExpenseValueList(temp_dropdown);
            setExpenseValueApiState(false);
        }
    }

    useEffect(() => {
        if (AllAccountHeadList.length > 0 && tempexpenseAccounts.length > 0 && Expense_details.length > 0) {
            let temp = [...tempexpenseAccounts];
            temp.map((obj) => {
                //setPaidThroughAccount({label:AllAccountHeadList.find(x=>x.value==Expense_details[0].paid_through_account).label,value:Expense_details[0].paid_through_account_head});
                obj.account_name = AllAccountHeadList.find(x => x.value == obj.account_id).label;
                obj.item_popover = false;
                obj.type_popover = false;
                obj.item_popover_input = "";
                obj.op_toggle = false;
            })
            setExpenseAccounts(temp);
        }
    }, [tempexpenseAccounts, AllAccountHeadList, Expense_details])

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p0 = getExpenseDetails()
            let p1 = selectiveaccounthead()
            let p2 = getcurrencydetails()
            let p3 = getTaxGroupDetails()
            let p4 = getbasecurrency()
            let p5 = getlivecurrency()
            Promise.all([p0, p1, p2, p3, p4, p5])
                .then(async () => {
                    //setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                    swal("Oops!", err, "error").then(() => { window.location.href = "/expense-list" })
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (Expense_details.length > 0) {
            if ((Expense_details[0].add_status == "EXPENSE_PENDING_APPROVAL" || Expense_details[0].add_status == "EXPENSE_DRAFT") && Expense_details[0].expense_type == "Customize Expense") {
                if (Expense_details[0].expense_for != "Employee") {
                    let p1 = getExpenseUser("", Expense_details[0].expense_for);
                    let p2 = getExpenseUserGstinDetails(Expense_details[0].expense_user_id, Expense_details[0].expense_for);
                    Promise.all([p1, p2])
                        .then(() => {
                            //setnavtype("Cheque");
                            //setstate(1);
                            console.log("Expense User api done")
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    let p1 = getuserdetails();
                    Promise.all([p1])
                        .then(() => {
                            //setnavtype("Cheque");
                            //setstate(1);
                            console.log("Expense User api done")
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            }
            else {
                setstate(1);
            }
        }
        else {
            //setstate(1);
        }
    }, [Expense_details])

    async function getProductCodeList(type, val) {
        setProductCodeListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-hsn-code`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_type": type === "Goods" ? "HSN" : "SAC", "search_key": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setProductCodeList(body.data)
            setProductCodeListApiState(false)
        }
        else {
            throw "get product code list api error"
        }
    }

    const newGstInSubmit = () => {

        var object = {};

        if (upsertGstInNumber === null) {
            swal("Warning!", "GSTIN cannot be empty", "warning");
            return
        }
        if (upsertGstInLegalName === null) {
            swal("Warning!", "Business Legal Name cannot be empty", "warning");
            return
        }
        if (upsertGstInSelectedPlaceOfSupply === null) {
            swal("Warning!", "Supply place cannot be empty", "warning");
            return
        }

        object.GstIn = upsertGstInNumber;
        let api = "";
        if (ExpenseType.value == "Customer") {
            object.customer_id = ExpenseValue.value;
            api = "save-customer-tax-details";
        }
        else if (ExpenseType.value == "Dealer") {
            object.dealer_id = ExpenseValue.value;
            api = "save-dealer-tax-details";
        }
        if (ExpenseType.value == "Creditor") {
            object.creditor_id = ExpenseValue.value;
            api = "save-creditor-tax-details";
        }
        if (ExpenseType.value == "Vendor") {
            object.vendor_id = ExpenseValue.value;
            api = "save-vendor-tax-details";
        }
        if (ExpenseType.value == "Manufacturer") {
            object.manufacturer_id = ExpenseValue.value;
            api = "save-manufacturer-tax-details";
        }

        object.gstTreatment = upsertGstInTreatment.value;
        object.place_of_supply = upsertGstInSelectedPlaceOfSupply.value;
        object.legal_name = upsertGstInLegalName;
        object.trade_name = upsertGstInTradeName;
        object.id = upsertGstInId;

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object)
        setUpsertGstInSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/${api}`, object, headers).then((res) => {
            setUpsertGstInSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Gstin details updated successfully!", "success");
                setUpsertGstInModalShow(false);
                getExpenseUserGstinDetails(ExpenseValue.value, ExpenseType.value);
                setUpsertGstInId("");
                setUpsertGstInTreatment(null)
                setUpsertGstInNumber("");
                setUpsertGstInLegalName("");
                setUpsertGstInTradeName("");
                setUpsertGstInSelectedPlaceOfSupply(null)
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    useEffect(() => {
        let state_arr = s_a.s_a[country_arr.country_arr.findIndex((obj) => obj == "India")].split("|");
        let obj_array = [];
        for (let i = 0; i < state_arr.length; i++) {
            obj_array.push({ value: state_arr[i], label: state_arr[i] });
        }
        setSupplyStateOptions(obj_array);
    }, []);

    const newExpenseSubmit = (val) => {
        var object = {};
        let org_c_code = currencyList.find(x => x.currency_id == BaseCurrency.value).currency_code

        let org_c_rate = (org_c_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + org_c_code]

        let exchange_rate = {
            organization: { base_curr: "USD", quote_curr: org_c_code, rate: org_c_rate }
        }

        let ic = currencyList.find(x => x.currency_id == selectedCurrency.value)
        object.currency = { currency_id: ic.currency_id, currency_symbol: ic.currency_symbol, currency_code: ic.currency_code, format: ic.format, decimal_place: ic.decimal_place, exchange_rate: exchange_rate }

        object.organization_id = organization_id;
        object.Expense_for = (Expense_category == true) ? ExpenseType.value : "";;
        object.expense_user_id = (Expense_category == true) ? ExpenseValue.value : "";
        object.branch_id = selectedBranch.value;
        object.transaction_series_id = selectedTransactionSeries.value;
        object.expense_number = ExpenseNumber;
        object.expense_date = new Date(Expense_date);
        object.paid_through_account = null;
        object.account_category = null;
        object.calculation_method = CalculationMode.value;
        object.invoice = referenceNumber.trim();
        object.source_of_supply = selectedPlaceOfSupply.value;
        object.destination_of_supply = SelectedDestinationPlace.value;
        object.gstin_details = selectedGstin;

        object.id = props.match.params.id;
        object.Account_details = expenseAccounts.map(x => { return { account_id: x.account_id, account_name: x.account_name, type: x.type, item_code: x.item_code, manual_input_amount: x.manual_input_amount, original_amount: x.original_amount, total_amount: x.total_amount, tax_amount: x.tax_amount, calculation_mode: CalculationMode.value, notes: x.notes, tax: x.tax } });
        object.page_name = page_name;

        object.save_flag = val
        object.organization_id = organization_id
        object.page_name = page_name;

        //console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-expense`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Expense details updated successfully!`, "success").then((value) => { window.location.reload(); });
            }
            else if (res.data.operation === "duplicate") {
                swal("Oops!", "This Reference Number already exists!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const validateExpense = () => {
        if (Expense_category == true) {
            if (ExpenseType == null) {
                swal("Oops!", "Expense Type cannot be empty", "warning");
                return false
            }
            if (ExpenseValue == null) {
                swal("Oops!", "Expense User Value cannot be empty", "warning");
                return false
            }
        }
        if (selectedBranch == null) {
            swal("Oops!", "Branch cannot be empty", "warning");
            return false
        }
        if (selectedTransactionSeries == null) {
            swal("Oops!", "Transaction Series cannot be empty", "warning");
            return false
        }
        if (ExpenseType !== null && ExpenseType.value != "Employee" && Expense_category == true) {
            if (selectedGstin == null) {
                swal("Oops!", "Gstin cannot be empty", "warning");
                return false
            }
        }
        // if (PaidThroughAccount == null) {
        //     swal("Oops!", "Paid Through Account cannot be empty", "warning");
        //     return false
        // }
        if (selectedPlaceOfSupply == null) {
            swal("Oops!", "Source of Supply cannot be empty", "warning");
            return false
        }
        if (SelectedDestinationPlace == null) {
            swal("Oops!", "Destination of Supply cannot be empty", "warning");
            return false
        }
        if (Expense_date == "") {
            swal("Oops!", "Expense date cannot be empty", "warning");
            return false
        }
        if (selectedCurrency == null) {
            swal("Oops!", "Currency cannot be empty", "warning");
            return false
        }

        let t = expenseAccounts.find(o => {
            if (o.account_id == null) {
                return o
            }
        })
        if (t != undefined) {
            swal("Oops!", "Account cannot be empty", "warning");
            return false
        }

        let t2 = expenseAccounts.find(o => {
            if (o.total_amount == null) {
                return o
            }
        })
        if (t2 != undefined) {
            swal("Oops!", "Amount cannot be empty", "warning");
            return false
        }
        return true
    }

    const Duplicate_account_check = (indx, val) => {
        let temp = expenseAccounts.map(x => { return { ...x } });
        let Duplicate = temp.find(s => s.account_id == val.value);
        if (typeof (Duplicate) !== "undefined") {
            swal("Warning!", "This Account already exist.Try different one.", "warning").then((value) => {
                temp[indx].account_id = "";
                temp[indx].account_name = "";
            });
        }
        else {
            temp[indx].account_id = val.value;
            temp[indx].account_name = val.label;
        }
        setExpenseAccounts(temp);
    }

    const Calculate_expense = (calculation_mode, expense_account) => {
        let temp = expense_account.map(a => { return { ...a } });
        temp.forEach((o) => {
            const tax_percentage = o.tax == null ? 0 : taxGroupList.find(x => x.tax_group_id == o.tax.value).tax_group_percentage;
            if (calculation_mode == "Tax_Inclusive") {
                o.original_amount = o.manual_input_amount / (1 + tax_percentage / 100);
                o.tax_amount = (o.tax == null ? 0 : ((o.manual_input_amount) - (o.manual_input_amount * (100 / (tax_percentage + 100)))));
            }
            else {
                o.original_amount = o.manual_input_amount;
                o.tax_amount = (o.tax == null ? 0 : (o.manual_input_amount) * (tax_percentage / 100));
            }
            o.total_amount = Number(o.original_amount) + Number(o.tax_amount);
            o.original_amount = trimDecimalPlaces(o.original_amount, decimalPlace);
            o.tax_amount = trimDecimalPlaces(o.tax_amount, decimalPlace);
            o.total_amount = trimDecimalPlaces(o.total_amount, decimalPlace);
        });
        return temp;
    }


    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Edit Expense Details

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <label className="form-check form-switch form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" onClick={(e) => { setExpense_category(e.target.checked); }} checked={Expense_category} disabled />
                            <span className="form-check-label fw-semibold text-muted">{Expense_category == true ? <>Customize Expense</> : <>Own Expense</>}</span>
                        </label>
                        {/* <span className="badge badge-light fw-bolder my-2">
                        {write==1&&<a id="button_create_alert" type="button" data-bs-toggle="modal" onClick={(e) => {setloading(true)}} data-bs-target="#EditExpenseDetails" className="btn btn-sm btn-primary me-3 rounded"><i className="la la-plus"></i>Edit Expense Details</a>}
                        {write==1&&<a id="button_create_alert" type="button" data-bs-toggle="modal" onClick={(e) => {setloading(true)}} data-bs-target="#BulkEditExpenseDetails" className="btn btn-sm btn-primary me-3 rounded"><i className="la la-plus"></i>Bulk Edit Expense Details</a>}
                        </span>     */}
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    state == 1 ?
                                        <>
                                            <div className="row mb-sm-5 gx-0 ">
                                                {Expense_category == true &&
                                                    <div className="col-md-4 px-1 mb-2">
                                                        <label className="required fs-5 fw-bold mb-2">Expense For</label>
                                                        <Select
                                                            options={ExpenseTypeList}
                                                            onChange={(val) => {
                                                                setExpenseType(val);
                                                                setExpenseValue(null);
                                                                setSelectedGstin(null);
                                                                getExpenseUser("", val.value);
                                                            }}
                                                            value={ExpenseType}
                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                            isDisabled={Expense_status == "EXPENSE_PENDING_APPROVAL"}
                                                        />
                                                    </div>}
                                                {(ExpenseType !== null && Expense_category == true) &&
                                                    <div className="col-md-4 px-1 mb-2">
                                                        <label className="required fs-5 fw-bold mb-2">{ExpenseType.value} Name</label>
                                                        <Select
                                                            options={ExpenseValueList}
                                                            onChange={(val) => {
                                                                setExpenseValue(val);
                                                                setSelectedGstin(null);
                                                                getExpenseUserGstinDetails(val.value, ExpenseType.value)
                                                            }}
                                                            value={ExpenseValue}
                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                            isDisabled={Expense_status == "EXPENSE_PENDING_APPROVAL"}
                                                            isLoading={ExpenseValueApiState}
                                                        />
                                                    </div>}
                                                <div className="col-md-4">
                                                    <label className="required fs-5 fw-bold mb-2">Expense Date</label>
                                                    <input type="date" name="Expense_date" className="form-control" id="Expense_date" placeholder="Sales Start Date" value={Expense_date}
                                                        onChange={(e) => {
                                                            setExpense_date(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {(ExpenseType !== null && ExpenseType.value != "Employee" && Expense_category == true) &&
                                                <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: ExpenseValue != null ? "1" : "0", visibility: ExpenseValue != null ? "visible" : "hidden", height: ExpenseValue != null ? "auto" : "0", marginBottom: ExpenseValue != null ? "1rem" : "0" }} >
                                                    <div className="col-12">
                                                        <div className="bg-light-primary border border-primary d-flex flex-column w-100 p-5 rounded">
                                                            <h5>GSTIN Details</h5>
                                                            <div className="d-flex flex-wrap align-items-center gap-1">
                                                                {
                                                                    selectedGstin != null ?
                                                                        <>
                                                                            <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                                <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.gst_treatment}</span>
                                                                                <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                            </div>
                                                                            <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                                <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.GstIn}</span>
                                                                                <div className="fw-semibold text-gray-400">GSTIN</div>
                                                                            </div>
                                                                        </> :
                                                                        <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                            <span className="fs-6 text-gray-700 fw-bold">No GSTIN selected</span>
                                                                        </div>
                                                                }

                                                                <div>
                                                                    <button onClick={(e) => { setGstInListModalShow(true); }} className="btn btn-sm px-3">
                                                                        <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                            <div className="row mb-sm-5 gx-0 ">
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Branch:</label>
                                                    <Select
                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                        options={branchList.map((x) => { return { label: x.branch_name, value: x.branch_id } })}
                                                        onChange={(val) => {
                                                            //swal("Alert!","Please verify applicable taxes for selected items","info")
                                                            setSelectedBranch(val);
                                                            setSelectedTransactionSeries(null)
                                                        }}
                                                        value={selectedBranch}
                                                        isDisabled={selectedBranch}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Transaction Series:</label>
                                                    <Select
                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                        options={selectedBranch != null && transactionSeriesList.filter(x => branchList.find(a => a.branch_id == selectedBranch.value).transaction_series_id.split(",").includes(x.transaction_series_id)).map(x => { return { label: x.series_name, value: x.transaction_series_id } })}
                                                        onChange={(val) => { setSelectedTransactionSeries(val); getSubSeriesData(val.value) }}
                                                        value={selectedTransactionSeries}
                                                        isDisabled={selectedTransactionSeries}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="required fs-5 fw-bold mb-2">Expense Number</label>
                                                    <input type="text" className="form-control form-control-solid" value={ExpenseNumber} readOnly />
                                                </div>
                                            </div>

                                            <div className="row mb-5">
                                                {/* <div className="col-md-6">
                                            <label className="required fs-5 fw-bold mb-2">Paid Through</label>
                                            <Select
                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                options={PaidThroughAccountlist}
                                                onChange={(event) => { setPaidThroughAccount(event); setAccountFlag("Paid")}}
                                                value={PaidThroughAccount}
                                            />
                                        </div> */}
                                                <div className="col-md-4">
                                                    <label className="required fs-5 fw-bold mb-2">Source Of Supply</label>
                                                    <Select
                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                        options={SupplyStateOptions}
                                                        onChange={(val) => { setSelectedPlaceOfSupply(val) }}
                                                        value={selectedPlaceOfSupply}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="required fs-5 fw-bold mb-2">Destination Of Supply</label>
                                                    <Select
                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                        options={SupplyStateOptions}
                                                        onChange={(val) => { setSelectedDestinationPlace(val) }}
                                                        value={SelectedDestinationPlace}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="fs-5 fw-bold mb-2">Currency</label>
                                                    <Select
                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                        options={currencyList.map(x => { return { label: x.currency_code, value: x.currency_id } })}
                                                        onChange={(val) => { setSelectedCurrency(val) }}
                                                        value={selectedCurrency}
                                                    //onChange={(event) => { setCurrency(event.value); load_currency_conversion_value(event.value);}}
                                                    //value={Currencylist.filter(option => option.value == Currency).length != 0 && Currencylist.filter(option => option.value == Currency)[0]}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-6">
                                                    <label className="required fs-5 fw-bold mb-2">Tax Type</label>
                                                    <Select
                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                        options={CalculationModeList}
                                                        onChange={(val) => {
                                                            setCalculationMode(val);
                                                            let temp = Calculate_expense(val.value, expenseAccounts);
                                                            setSubTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.original_amount) }, 0), decimalPlace));
                                                            setTaxTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.tax_amount) }, 0), decimalPlace));
                                                            setGrandTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.total_amount) }, 0), decimalPlace))
                                                            setExpenseAccounts(temp);
                                                        }}
                                                        value={CalculationMode}
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Reference</label>
                                                    <input id="Invoice" type="text" className="form-control form-control-solid" placeholder="" name="subcategory-name" onChange={(e) => { setReferences(e.target.value) }} />
                                                </div>
                                            </div>
                                            <hr />

                                            <div className='' style={{ overflowX: "auto" }}>
                                                <div className='container' style={{ width: "65rem" }}>
                                                    <div className="row mb-5 gx-0">
                                                        <div className=" col-3"><p className=" required fs-5">Expense Account</p></div>
                                                        <div className="col-3"><p className="fs-5">Notes</p></div>
                                                        <div className="col-3"><p className="fs-5">Tax</p></div>
                                                        <div className="col-2"><p className="required fs-5">Amount</p></div>
                                                        <div className="col"></div>
                                                    </div>
                                                    {
                                                        expenseAccounts.map((obj, indx, arr) => {
                                                            return (
                                                                <div key={indx} className="row mb-5">
                                                                    <div className="col-3 mb-1">
                                                                        <Select
                                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                            menuPosition={'fixed'}
                                                                            options={AccountSelectionList}
                                                                            onChange={(val) => {
                                                                                setAccountFlag("Normal")
                                                                                Duplicate_account_check(indx, val)
                                                                                //violation_check(indx,val,obj.notes,obj.amount) 
                                                                            }}
                                                                            value={{ label: obj.account_name, value: obj.account_id }}
                                                                        />
                                                                        {
                                                                            expenseAccounts[indx].account_id != null &&
                                                                            <div className='mt-1'>
                                                                                <div style={{ position: "relative", display: "inline-block" }} >
                                                                                    <span className={`text-hover-primary`} style={{ cursor: "pointer" }}
                                                                                        onClick={() => {
                                                                                            let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                            temp[indx].type_popover = true
                                                                                            setExpenseAccounts(temp)
                                                                                        }}
                                                                                    > {expenseAccounts[indx].type == "Goods" ? <>Goods</> : <>Service</>}<i className="fa fa-pencil ms-1" aria-hidden="true"></i>
                                                                                    </span>
                                                                                    <div style={{ position: "absolute", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "15rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: expenseAccounts[indx].type_popover ? "1" : "0", visibility: expenseAccounts[indx].type_popover ? "visible" : "hidden" }}>
                                                                                        <div className={`p-1 rounded ${expenseAccounts[indx].type == "Goods" && "bg-primary"}`}
                                                                                            onClick={() => {
                                                                                                let temp = expenseAccounts.map(a => { return { ...a } });
                                                                                                temp[indx].type = "Goods"
                                                                                                setExpenseAccounts(temp)
                                                                                            }}
                                                                                        >Goods</div>
                                                                                        <div className={`p-1 rounded ${expenseAccounts[indx].type == "Service" && "bg-primary"}`}
                                                                                            onClick={() => {
                                                                                                let temp = expenseAccounts.map(a => { return { ...a } });
                                                                                                temp[indx].type = "Service"
                                                                                                setExpenseAccounts(temp)
                                                                                            }}
                                                                                        >Service</div>
                                                                                        <div className='d-flex gap-1 mb-1'>
                                                                                            <button className='btn btn-sm btn-secondary px-2 py-1 mt-3'
                                                                                                onClick={() => {
                                                                                                    let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                                    temp[indx].type_popover = false
                                                                                                    setExpenseAccounts(temp)
                                                                                                }}
                                                                                            >Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div><br />
                                                                                <div style={{ position: "relative", display: "inline-block" }} >
                                                                                    <span className='text-hover-primary' style={{ cursor: "pointer" }}
                                                                                        onClick={() => {
                                                                                            let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                            temp[indx].item_popover = true
                                                                                            setExpenseAccounts(temp)
                                                                                        }}
                                                                                    >{expenseAccounts[indx].type == "Goods" ? "HSN Code :" : "SAC Code:"}{expenseAccounts[indx].item_code == "" ? "" : expenseAccounts[indx].item_code}<i className="fa fa-pencil ms-1" aria-hidden="true"></i>
                                                                                    </span>
                                                                                    <div style={{ position: "absolute", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: expenseAccounts[indx].item_popover ? "1" : "0", visibility: expenseAccounts[indx].item_popover ? "visible" : "hidden" }}>
                                                                                        <p className='mb-1'>{expenseAccounts[indx].type == "Goods" ? "HSN Code" : "SAC Code"}</p>
                                                                                        <div className='d-flex gap-1 mb-1'>
                                                                                            <input type="text" className="form-control form-control-solid px-2" value={expenseAccounts[indx].item_popover_input}
                                                                                                onChange={(e) => {
                                                                                                    let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                                    temp[indx].item_popover_input = e.target.value
                                                                                                    setExpenseAccounts(temp)
                                                                                                }}
                                                                                            />
                                                                                            <button className='btn btn-sm btn-secondary px-3' onClick={() => { setProductCodeModalShow(true); setProductCodeItemId(expenseAccounts[indx].account_id.value); setProductCodeItemType(expenseAccounts[indx].type); }}><i className="fa fa-search p-0" aria-hidden="true"></i></button>
                                                                                        </div>
                                                                                        <div className='d-flex gap-1 mb-1'>
                                                                                            <button className='btn btn-sm btn-success px-2 py-1'
                                                                                                onClick={async () => {
                                                                                                    let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                                    //await updateProductCode(temp[indx].item.value, temp[indx].item_type, temp[indx].item_popover_input)
                                                                                                    temp[indx].item_code = temp[indx].item_popover_input
                                                                                                    temp[indx].item_popover = false
                                                                                                    setExpenseAccounts(temp)
                                                                                                }}
                                                                                            >Save</button>
                                                                                            <button className='btn btn-sm btn-secondary px-2 py-1'
                                                                                                onClick={() => {
                                                                                                    let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                                    temp[indx].item_popover = false
                                                                                                    setExpenseAccounts(temp)
                                                                                                }}
                                                                                            >Close</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="col-3 mb-1">
                                                                        <textarea className="form-control form-control-solid" rows="1" value={obj.notes}
                                                                            onChange={(e) => {
                                                                                let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                temp[indx].notes = e.target.value
                                                                                setExpenseAccounts(temp)
                                                                                //violation_check(indx,obj.account,e.target.value,obj.amount) 
                                                                            }}
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="col-3 mb-1">
                                                                        <Select
                                                                            menuPosition={'fixed'}
                                                                            options={taxGroupList.filter(x => {
                                                                                if ((sourceOfSupply == null) || (selectedPlaceOfSupply == null)) {
                                                                                    return true
                                                                                }
                                                                                else {
                                                                                    if (sourceOfSupply != selectedPlaceOfSupply.value) {
                                                                                        return x.tax_group_type == "INTERSTATE"
                                                                                    }
                                                                                    else {
                                                                                        return x.tax_group_type != "INTERSTATE"
                                                                                    }
                                                                                }
                                                                            }).map(x => { return { label: `${x.tax_group_name} (${x.tax_group_percentage}%)`, value: x.tax_group_id, tax_group_percentage: x.tax_group_percentage } })}
                                                                            onChange={(val) => {
                                                                                let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                temp[indx].tax = val
                                                                                temp = Calculate_expense(CalculationMode.value, temp);
                                                                                setSubTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.original_amount) }, 0), decimalPlace));
                                                                                setTaxTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.tax_amount) }, 0), decimalPlace));
                                                                                setGrandTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.total_amount) }, 0), decimalPlace))
                                                                                setExpenseAccounts(temp);
                                                                            }}
                                                                            isClearable
                                                                            value={obj.tax}
                                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                        />
                                                                    </div>
                                                                    <div className="col-2 mb-1">
                                                                        <input type="number" className="form-control form-control-solid" value={obj.original_amount.toString()}
                                                                            onChange={(e) => {
                                                                                if (parseFloat(e.target.value) >= 0) {
                                                                                    let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                    temp[indx].total_amount = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                    temp[indx].original_amount = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                    temp[indx].manual_input_amount = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                    setExpenseAccounts(temp)
                                                                                }
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                let temp = Calculate_expense(CalculationMode.value, expenseAccounts);
                                                                                setSubTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.original_amount) }, 0), decimalPlace));
                                                                                setTaxTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.tax_amount) }, 0), decimalPlace));
                                                                                setGrandTotal(trimDecimalPlaces(temp.reduce((p, o) => { return p + Number(o.total_amount) }, 0), decimalPlace))
                                                                                setExpenseAccounts(temp);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        expenseAccounts.length > 0 &&
                                                                        <div className="col-1 position-relative">
                                                                            <button className='btn btn-sm btn-outline-info rounded-pill border border-info fw-bolder'
                                                                                onClick={() => {
                                                                                    //console.log(obj1.op_toggle);
                                                                                    //obj1.op_toggle = !obj1.op_toggle
                                                                                    let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                    temp[indx].op_toggle = !temp[indx].op_toggle
                                                                                    setExpenseAccounts(temp)
                                                                                }}
                                                                            >&#x2630;</button>
                                                                            {
                                                                                obj.op_toggle &&
                                                                                <>
                                                                                    <div className='position-absolute' style={{ top: "-40px", left: "-30px", width: "max-content" }}>
                                                                                        <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{ borderRadius: "50% 0 0 50%", padding: "6px 15px" }}
                                                                                            onClick={() => {
                                                                                                let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                                temp.splice(indx, 1)
                                                                                                setExpenseAccounts(temp)
                                                                                            }}
                                                                                        >&#10006;</button>
                                                                                        <button className='btn btn-sm btn-secondary fw-bolder border-top border-bottom border-info fs-3 rounded-0' style={{ padding: "4px 10px" }}
                                                                                            onClick={() => {
                                                                                                let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                                temp[indx].op_toggle = false
                                                                                                temp.splice(indx, 0, temp[indx])
                                                                                                setExpenseAccounts(temp)
                                                                                            }}
                                                                                        >&#10063;</button>
                                                                                        <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{ borderRadius: "0 50% 50% 0", padding: "6px 15px" }}
                                                                                            onClick={() => {
                                                                                                let temp = expenseAccounts.map(a => { return { ...a } })
                                                                                                if (indx == 0) {
                                                                                                    temp[indx].op_toggle = false
                                                                                                }
                                                                                                else {
                                                                                                    let t = expenseAccounts[indx]
                                                                                                    temp.splice(indx, 1)
                                                                                                    temp.splice(indx - 1, 0, t)
                                                                                                }
                                                                                                setExpenseAccounts(temp)
                                                                                            }}
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12" height="12" viewBox="0 0 512.000000 512.000000">
                                                                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                                                                    <path d="M2475 5090 c-82 -9 -181 -44 -253 -88 -73 -44 -2015 -1625 -2063 -1679 -54 -60 -99 -137 -127 -215 -34 -96 -42 -271 -18 -368 51 -204 209 -377 408 -447 106 -38 264 -44 371 -14 43 12 104 34 135 50 63 32 76 42 977 776 351 286 643 520 649 519 6 -1 342 -270 746 -600 840 -684 838 -683 915 -718 123 -56 276 -73 411 -46 221 44 421 238 479 465 19 76 19 231 0 314 -21 87 -84 204 -147 273 -51 54 -1958 1613 -2049 1675 -122 81 -284 120 -434 103z" />
                                                                                                    <path d="M2480 2870 c-64 -7 -165 -37 -225 -67 -52 -26 -115 -77 -1108 -886 -671 -547 -981 -806 -1012 -844 -54 -70 -101 -165 -120 -246 -19 -81 -19 -237 0 -312 56 -221 245 -408 465 -459 151 -36 329 -12 463 62 40 22 377 290 838 666 l770 628 42 -33 c23 -18 368 -299 767 -624 399 -325 745 -604 770 -621 101 -69 222 -106 355 -107 113 -1 177 14 285 65 160 76 290 237 335 413 18 69 20 221 4 297 -19 91 -91 227 -156 294 -56 57 -1935 1592 -2038 1665 -122 86 -277 125 -435 109z" />
                                                                                                </g>
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <div className="row mb-5">
                                                        <div className="col-3">
                                                            <a className='btn btn-primary rounded fs-7' onClick={() => { setExpenseAccounts([...expenseAccounts, { account: null, type: "Service", item_code: "", manual_input_amount: 0, original_amount: 0, tax_amount: 0, total_amount: 0, notes: "", calculation_mode: CalculationMode.value, tax: null, item_popover: false, type_popover: false, item_popover_input: "", op_toggle: false }]) }}> Add a row</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-3"></div>
                                                <div className='col-md-3 d-flex align-items-center'>
                                                    <p className='m-0 fs-5'>Sub Total</p>
                                                </div>
                                                <div className="col-md-3"></div>
                                                <div className='col-md-3 my-2'>
                                                    <input type="text" className="form-control form-control-solid" value={subTotal} readOnly />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-3"></div>
                                                <div className='col-md-3 d-flex align-items-center'>
                                                    <p className='m-0 fs-5'>Tax Added</p>
                                                </div>
                                                <div className="col-md-3"></div>
                                                <div className='col-md-3 my-2'>
                                                    <input type="text" className="form-control form-control-solid" value={taxTotal} readOnly />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-3"></div>
                                                <div className='col-md-3 mt-4 d-flex align-items-center'>
                                                    <h4 className='m-0'>Grand Total</h4>
                                                </div>
                                                <div className="col-md-3"></div>
                                                <div className='col-md-3 mt-4 d-flex align-items-center'>
                                                    <h3>{currencyList.length > 0 && selectedCurrency != null && currencyList.find(x => x.currency_id == selectedCurrency.value).currency_symbol} {grandTotal}</h3>
                                                </div>
                                            </div>
                                            <hr />
                                            <br />
                                            {
                                                (write  && Expense_status == "EXPENSE_DRAFT") &&
                                                <button type="button" className="btn btn-lg btn-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateExpense() && newExpenseSubmit(false) }}>
                                                    <span className="indicator-label">Save as Draft</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }

                                            {
                                                write  &&
                                                <button type="button" className="btn btn-lg btn-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateExpense() && newExpenseSubmit(true) }}>
                                                    <span className="indicator-label">Save</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                state == 2 ?
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                    :
                                                    <>
                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Edit Expense Details </div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                        <div className="fs-6 text-gray-600"> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={productCodeModalShow} onHide={() => { setProductCodeModalShow(false); setProductCodeItemId(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }} centered>
                <Modal.Header>
                    <Modal.Title>Find {productCodeItemType == "Goods" ? "HSN" : "SAC"} Code</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setProductCodeModalShow(false); setProductCodeItemId(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="container">

                            <label className="form-label">Search {productCodeItemType == "Goods" ? "HSN" : "SAC"} code for your item</label>
                            <div className="d-flex gap-1">
                                <input type="text" className="form-control" value={productCodeSearch} onChange={(e) => { setProductCodeSearch(e.target.value) }} />
                                <button className="btn btn-primary btn-sm rounded" onClick={(e) => { getProductCodeList(productCodeItemType, productCodeSearch) }} disabled={productCodeListApiState}>Search</button>
                            </div>

                            {
                                productCodeList.length > 0 && productCodeList.map((obj, index) => {
                                    return (
                                        <div key={index} className="d-flex align-items-center my-2"
                                            onClick={() => {
                                                setProductCodeModalShow(false);
                                                let temp = expenseAccounts.map(a => { return { ...a } })
                                                let ti = expenseAccounts.findIndex(x => x.account.value == productCodeItemId)
                                                temp[ti].item_popover_input = obj.codes
                                                setExpenseAccounts(temp)

                                                setProductCodeItemId(null);
                                                setProductCodeItemType(null);
                                                setProductCodeSearch("");
                                                setProductCodeList([]);
                                                setProductCodeListApiState(false);
                                            }}
                                        >
                                            <div className="symbol symbol-40px">
                                                <span className="symbol-label bg-light">
                                                    <span className="svg-icon svg-icon-2 svg-icon-primary">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <p className="fs-6 text-gray-800 text-hover-primary fw-semibold">{obj.code_description}</p>
                                                <span className="fs-7 text-muted fw-semibold">#{obj.codes}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={gstInListModalShow} onHide={() => { setGstInListModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title>GstIn List</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setGstInListModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#f4f6f9" }}>
                    <div className='container'>
                        {
                            ExpenseGstinList.length === 0 ?
                                <div className="overflow-auto pb-5">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
                                                <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                            <div className="mb-3 mb-md-0 fw-semibold">
                                                <h4 className="text-gray-900 fw-bold">No Data Available.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="d-flex flex-column gap-5">

                                        {
                                            ExpenseGstinList.sort((a, b) => a.add_date_time - b.add_date_time).map((obj, index) => {
                                                return (
                                                    <div key={index} className="position-relative fv-row w-100 flex-md-root" style={{ cursor: "pointer" }} onClick={() => { setSelectedGstin(obj); setSelectedPlaceOfSupply({ label: obj.place_of_supply, value: obj.place_of_supply }); setGstInListModalShow(false); }}>
                                                        <div className="card card-flush h-lg-100">
                                                            <div className="card-header mt-6">
                                                                <div className="card-title flex-column"><h3 className="fw-bolder mb-1">{obj.GstIn}</h3></div>
                                                                <div className="card-toolbar">
                                                                    {/* <div className="dropdown">
                                                                        {write  && <button className="btn btn-sm btn-secondary rounded" type="button" onClick={(e) => { handleUpsertGstInModalShowInit(obj) }} >Edit</button>}
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-wrap">
                                                                <div className="card-body p-9">
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.legal_name}</div>
                                                                        <div className="fw-semibold text-gray-400">Business Legal Name</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.gst_treatment}</div>
                                                                        <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.place_of_supply}</div>
                                                                        <div className="fw-semibold text-gray-400">Place of Supply</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            selectedGstin != null && selectedGstin.unique_id === obj.unique_id &&
                                                            <div className='position-absolute' style={{ top: "3rem", right: "7rem" }} >
                                                                <svg width="30" height="30" viewBox="0 0 64 64">
                                                                    <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {ExpenseGstinList.length === 0 &&
                        <button className='btn btn-success' onClick={(e) => { setUpsertGstInModalShow(true); }}><i className="las la-plus"></i>Add New</button>}
                    <button className="btn btn-danger rounded" onClick={() => { setGstInListModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={upsertGstInModalShow} onHide={() => { setUpsertGstInModalShow(false); setUpsertGstInId(""); setUpsertGstInTreatment(null); setUpsertGstInNumber(""); setUpsertGstInLegalName(""); setUpsertGstInTradeName(""); setUpsertGstInSelectedPlaceOfSupply(null); }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add GstIn Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setUpsertGstInModalShow(false); setUpsertGstInId(""); setUpsertGstInTreatment(null); setUpsertGstInNumber(""); setUpsertGstInLegalName(""); setUpsertGstInTradeName(""); setUpsertGstInSelectedPlaceOfSupply(null); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex flex-wrap gap-5 mb-2">
                            <div className="fv-row w-100 flex-md-root">
                                <label className="form-label">GST Treatment</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    options={upsertGstInTreatmentList}
                                    value={upsertGstInTreatment}
                                    onChange={(val) => { setUpsertGstInTreatment(val) }}
                                    components={{
                                        Option: (props) => {
                                            return (
                                                <components.Option  {...props}>
                                                    {props.children}
                                                    <span className="fw-semibold text-gray-400 d-block fs-8">{props.data.description}</span>
                                                </components.Option >
                                            )
                                        }
                                    }}
                                />
                            </div>

                        </div>
                        <div className="d-flex flex-wrap gap-5">

                            <div className="fv-row w-100 flex-md-root">
                                <label className="required form-label">GSTIN</label>
                                <input id="GSTIN" type="text" className="form-control mb-2" placeholder="" name="GSTIN" value={upsertGstInNumber} onChange={(e) => { setUpsertGstInNumber(e.target.value) }} />
                            </div>
                            <div className="fv-row w-100 flex-md-root">
                                <label className="required form-label">Business Legal Name</label>
                                <input id="Business_Legal_Name" type="text" className="form-control mb-2" placeholder="" name="Business_Legal_Name" value={upsertGstInLegalName} onChange={(e) => { setUpsertGstInLegalName(e.target.value) }} />
                            </div>

                        </div>
                        <div className="d-flex flex-wrap gap-5">
                            <div className="fv-row w-100 flex-md-root">
                                <label className="required form-label">Place Of Supply</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    options={SupplyStateOptions}
                                    onChange={(val) => { setUpsertGstInSelectedPlaceOfSupply(val) }}
                                    value={upsertGstInSelectedPlaceOfSupply}
                                    isClearable
                                />
                            </div>
                            <div className="fv-row w-100 flex-md-root">
                                <label className="form-label">Business Trade Name</label>
                                <input id="Business_Trade_Name" type="text" className="form-control mb-2" placeholder="" name="Business_Trade_Name" value={upsertGstInTradeName} onChange={(e) => { setUpsertGstInTradeName(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setUpsertGstInModalShow(false); setUpsertGstInId(""); setUpsertGstInTreatment(null); setUpsertGstInNumber(""); setUpsertGstInLegalName(""); setUpsertGstInTradeName(""); setUpsertGstInSelectedPlaceOfSupply(null); }}>Close</button>
                    {
                        write  &&
                        <button className="btn btn-info rounded" data-kt-indicator={upsertGstInSubmitButtonState ? "on" : "off"} disabled={upsertGstInSubmitButtonState} onClick={() => { newGstInSubmit() }}>
                            <span className="indicator-label">Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>);

};

export default EditExpenseDetails;
