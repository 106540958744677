import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from '@sweetalert/with-react'
import moment from 'moment';
import { Link, Redirect, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';
import { ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
// import { TbProgress } from "react-icons/tb";

import { useTheme } from "./theme-provider.js"
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';


export const CreateSalesReturn = (props) => {

    const [customerInvoiceList, setCustomerInvoiceList] = useState([])
    const [customerInvoiceListApiState, setCustomerInvoiceListApiState] = useState(false)

    const [branchList, setBranchlist] = useState([])
    const [warehouseList, setWarehouseList] = useState([])
    const [transactionSeriesList, setTransactionSeriesList] = useState([])
    const [salesReturnNumber, setSalesReturnNumber] = useState("")

    const [selectedCustomerInvoice, setSelectedCustomerInvoice] = useState(null)
    const [selectedCustomerInvoiceDocument, setSelectedCustomerInvoiceDocument] = useState(null)
    const [relatedSalesReturns, setRelatedSalesReturns] = useState([]) // for validation 

    const [mergedChallanItemList, setMergedChallanItemList] = useState([])

    const [salesReturnItemArray, setSalesReturnItemArray] = useState([{ item_id: null, item_name: null, item_stock_type: null, challan_info: null, delivered_quantity: 0 }])

    const [submitButtonState, setSubmitButtonState] = useState(false)

    const [timezone_change, set_timezone_change] = useState("+00:00");
    const [progressModalShow, setProgressModalShow] = useState(false)
    const [progressStatus, setProgressStatus] = useState([])
    const [flowApiState, setFlowApiState] = useState(false)

    const { theme, dropdownStyleDark } = useTheme()

    const history = useHistory()

    const [selectedItemRef, setSelectedItemRef] = useState(null)
    const [configureItemModalShow, setConfigureItemModalShow] = useState(false)

    const [approvalInfo, setApprovalInfo] = useState(null);

    const [state, setstate] = useState(2);


    var page_name = "create-sales-return";
    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getCustomerInvoiceList(val) {
        setCustomerInvoiceListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-invoice-by-search`, { method: 'POST', body: JSON.stringify({ search_value: val, "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        let b = body.data.filter(x => ((x.add_status === "CUSTOMER_INVOICE_FINALIZED") || (x.add_status === "CUSTOMER_INVOICE_WAREHOUSE")))
        setCustomerInvoiceList(b)
        setCustomerInvoiceListApiState(false)
    }

    const getProgressByStatus = async (customer_invoice_id) => {
        setFlowApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-order-invoice-timeline`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_invoice_id": customer_invoice_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setProgressStatus(body.data)
        }
        else {
            swal("Oops!", "Couldn't get order flow", "warning")
        }
        setFlowApiState(false)
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    const timezone = body.data[0].timezone_value;
                    set_timezone_change(timezone);
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    async function getApprovalDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-specific-approval`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "key": "sales_returns_approval", "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        });
        const body = await response.json();
        if (body.operation === "success") {
            setApprovalInfo(body.data);
        }
    }

    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation == "success") {
            setBranchlist(body.data);
        }
        else {
            throw "branch list api error"
        }
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    async function getSubSeriesData(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-series-data-by-name`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_series_name": "sales_returns", "transaction_series_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation == "success") {
            setSalesReturnNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
            return true
        }
        else {
            swal("Alert!", body.message, "warning")
            return false
        }
    }


    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getCustomerInvoiceList()
            let p2 = getTimezoneInformation()
            let p3 = getApprovalDetails()
            let p4 = getBranchDetails()
            let p5 = getWarehouses()
            let p6 = getTransactionSeriesList()

            Promise.all([p1, p2, p3, p4, p5, p6])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    console.log(errx)
                    setstate(3)
                })
        }
    }, [user, visibility, read, write])

    const processLineItemsAgainstChallans = (challans) => {

        let challan_merged_data = []
        for (const ch of challans) {
            ch.item_array.forEach(item => {

                let t = challan_merged_data.find(x => (x.item_id === item.item_id))
                if (t) {
                    t.challan_info.push({ dc_id: ch.delivery_challan_id, dc_number: ch.delivery_challan_number, unit: item.quantity, item_data: item.item_data })
                    t.delivered_quantity += item.quantity
                }
                else {

                    let tobj = {
                        item_id: item.item_id,
                        item_name: item.item_name,
                        item_stock_type: item.item_stock_type,
                        challan_info: [{ dc_id: ch.delivery_challan_id, dc_number: ch.delivery_challan_number, unit: item.quantity, item_data: item.item_data }],
                        delivered_quantity: item.quantity
                    }

                    challan_merged_data.push(tobj)
                }
            })
        }

        setMergedChallanItemList(challan_merged_data)
    }

    const mergeReturnedItems = (salesReturns) => {
        console.log("return", salesReturns);
        const mergedItems = [];

        salesReturns.forEach(salesReturn => {
            salesReturn.item_array.forEach(item => {
                let existingItem = mergedItems.find(mergedItem => mergedItem.item_id === item.item_id);

                if (existingItem) {
                    item.challan_info.forEach(challan => {
                        let existingChallan = existingItem.challan_info.find(mergedChallan => mergedChallan.dc_id === challan.dc_id);

                        if (existingChallan) {
                            existingChallan.returned_quantity += challan.returned_quantity;

                            if (challan.returned_item_data) {
                                if (!existingChallan.returned_item_data) {
                                    existingChallan.returned_item_data = []
                                }

                                if (item.item_stock_type === "Track Serial Number") {
                                    existingChallan.returned_item_data = [...existingChallan.returned_item_data, ...challan.returned_item_data]
                                }
                                else if (item.item_stock_type === "Track Batches") {
                                    challan.returned_item_data.forEach(batch => {
                                        let existingBatch = existingChallan.returned_item_data.find(mergedBatch => mergedBatch.uid === batch.uid);
                                        if (existingBatch) {
                                            existingBatch.unit += batch.unit
                                        }
                                        else {
                                            existingChallan.returned_item_data.push({ ...batch })
                                        }
                                    })
                                }
                            }
                        }
                        else {
                            existingItem.challan_info.push({ ...challan })
                        }
                    })
                }
                else {
                    mergedItems.push({ ...item })
                }
            });
        });

        console.log("merge", mergedItems);
        setRelatedSalesReturns(mergedItems)
    }



    const validateSalesReturn = () => {
        if (selectedCustomerInvoice === null) {
            swal("Oops!", "Please select an invoice to proceed", "warning")
            return false
        }
        if (selectedCustomerInvoice !== null && selectedCustomerInvoiceDocument.challans.length === 0) {
            swal("Oops!", "Select a valid invoice with challan", "warning")
            return false
        }

        if (salesReturnItemArray.find(x => x.item_id == null) || salesReturnItemArray.length === 0) {
            swal("Oops!", "Choose a product before submitting", "warning")
            return false
        }

        let t = salesReturnItemArray.find(o => {
            if (o.item_name === null) {
                return o
            }
        })
        if (t != undefined) {
            swal("Oops!", "Please choose an item before proceeding", "warning");
            return false
        }

        let t2 = salesReturnItemArray.find(item => {
            return item.challan_info.reduce((p, o) => p + o.returned_quantity, 0) === 0
        })
        if (t2) {
            swal("Oops!", "Configure all items before proceeding", "warning");
            return false
        }

        return true
    }

    const addSalesReturn = (val) => {
        var object = {}

        object.customer_invoice_id = selectedCustomerInvoice.value
        object.customer_invoice_number = selectedCustomerInvoice.label
        object.customer_id = selectedCustomerInvoiceDocument.customer_id
        object.customer_name = selectedCustomerInvoiceDocument.customer_name
        object.branch_id = selectedCustomerInvoiceDocument.branch_id
        object.warehouse_id = selectedCustomerInvoiceDocument.warehouse_id
        object.transaction_series_id = selectedCustomerInvoiceDocument.transaction_series_id

        let t = salesReturnItemArray.map(item => {
            return {
                item_id: item.item_id,
                item_name: item.item_name,
                item_stock_type: item.item_stock_type,
                challan_info: item.challan_info.filter(x => x.returned_quantity > 0).map(x => {
                    let t3 = { ...x }
                    delete t3.challan_expanded;
                    delete t3.item_data;
                    delete t3.unit;

                    return t3
                })
            }
        })

        object.item_array = t

        object.save_flag = val
        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-sales-return-details`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Details of sales return ${res.data.data.sales_return_number} added for Invoice: ${res.data.data.customer_invoice_number} successfully!`, "success").then(() => {
                    history.push(`/view-sales-return-details/${res.data.data.sales_return_id}`)
                });
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleConfigureItemModalClose = () => {
        setSelectedItemRef(null)
        setConfigureItemModalShow(false);

    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Create Sales Invoice Return
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            (!approvalInfo && state === 1) &&
                            <>
                                <div className="notice d-flex bg-light-warning rounded border border-warning shadow-sm p-6">
                                    <i className="bi bi-exclamation-circle fs-1 me-4 text-warning"></i>
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <div className="fs-6 text-gray-700 ">Sales Return Approval Part Not Configured yet. Configure this, From <Link to={"/approvals"}>Approval Page</Link>.</div>
                                        </div>
                                    </div>
                                </div><br />
                            </>
                        }
                        <div className="card">
                            <div className="card-body">
                                {
                                    state == 1 ?
                                        <>
                                            <div className="row gx-0 ">
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">Invoice Number</label>
                                                    <Select
                                                        options={customerInvoiceList.map(x => { return { label: x.customer_invoice_number, value: x.customer_invoice_id } })}
                                                        onChange={(val) => {
                                                            if (val) {
                                                                setSelectedCustomerInvoice(val);
                                                                setSelectedCustomerInvoiceDocument(customerInvoiceList.find(x => x.customer_invoice_id === val.value));
                                                                getSubSeriesData(customerInvoiceList.find(x => x.customer_invoice_id === val.value).transaction_series_id);

                                                                mergeReturnedItems(customerInvoiceList.find(x => x.customer_invoice_id === val.value).sales_returns);
                                                                processLineItemsAgainstChallans(customerInvoiceList.find(x => x.customer_invoice_id === val.value).challans);
                                                                setSalesReturnItemArray([{ item_id: null, item_name: null, item_stock_type: null, challan_info: null, delivered_quantity: 0 }])
                                                            } else {
                                                                setSelectedCustomerInvoice(null)
                                                                setSelectedCustomerInvoiceDocument(null)
                                                            }
                                                        }}
                                                        onInputChange={(val) => { getCustomerInvoiceList(val) }}
                                                        isLoading={customerInvoiceListApiState}
                                                        value={selectedCustomerInvoice}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        placeholder="Inv search"
                                                        isClearable
                                                    />
                                                </div>
                                                <div className="col-md-3 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2">Customer Name</label>
                                                    <input type="text" placeholder='select invoice' className="form-control form-control-solid" value={selectedCustomerInvoiceDocument === null ? "" : selectedCustomerInvoiceDocument.customer_name} readOnly disabled />
                                                </div>
                                                <div className="col-md-3 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2">Return Number</label>
                                                    <input type="text" placeholder='select invoice' className="form-control form-control-solid" value={salesReturnNumber} readOnly disabled />
                                                </div>
                                            </div>
                                            <div className="row gx-0 mt-3">
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 me-2">Transaction Series:</label>
                                                    <span className={`d-inline-block p-2 ${selectedCustomerInvoiceDocument === null ? "text-muted" : "text-primary bg-light rounded"}`}>
                                                        {selectedCustomerInvoiceDocument === null ? "N/A" : transactionSeriesList.find(x => x.transaction_series_id === selectedCustomerInvoiceDocument.transaction_series_id).series_name}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 me-2">Branch:</label>
                                                    <span className={`d-inline-block p-2 ${selectedCustomerInvoiceDocument === null ? "text-muted" : "text-primary bg-light rounded"}`}>
                                                        {selectedCustomerInvoiceDocument === null ? "N/A" : branchList.find(x => x.branch_id === selectedCustomerInvoiceDocument.branch_id).branch_name}
                                                    </span>
                                                </div>
                                                <div className="col-md-4 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 me-2">Warehouse:</label>
                                                    <span className={`d-inline-block p-2 ${selectedCustomerInvoiceDocument === null ? "text-muted" : "text-primary bg-light rounded"}`}>
                                                        {selectedCustomerInvoiceDocument === null ? "N/A" : warehouseList.find(x => x.wid === selectedCustomerInvoiceDocument.warehouse_id).name}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='container mt-15'>
                                                {
                                                    selectedCustomerInvoice === null ?
                                                        <div className="flex-lg-row-fluid">
                                                            <div className="card card-flush my-5 shadow-sm border-0">
                                                                <div className="card-body p-5">
                                                                    <div className="bg-light-secondary border p-13 rounded-3 position-relative">
                                                                        <div className="d-flex flex-stack flex-grow-1 align-items-center">
                                                                            <div className="fw-bold">
                                                                                <h4 className="text-gray-800 mb-3">
                                                                                    <i className="bi bi-file-earmark-text-fill display-6 me-2 text-primary"></i>
                                                                                    Choose an Invoice to Proceed
                                                                                </h4>
                                                                                <div className="fs-6 text-gray-600">Please select an invoice from the list above to continue with the process.</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="position-absolute top-50 end-0 translate-middle-y me-5">
                                                                            <i className="bi bi-arrow-up-circle-fill display-4 text-primary"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        (selectedCustomerInvoice !== null && mergedChallanItemList.length > 0) ?
                                                            <div className=''>
                                                                <label className="fs-5 fw-bold mb-10">Choose Items</label>

                                                                <div className='' style={{ overflowX: "auto" }}>
                                                                    <div className='container px-0' style={{ width: "80rem" }}>

                                                                        <div className="row mb-5 gx-0">
                                                                            <div className="col-4"><p className="fs-5 text-center">Product Name</p></div>
                                                                            <div className="col-2"><p className="fs-5 text-center">Delivered Qty</p></div>
                                                                            <div className="col-2"><p className="fs-5 text-center">Return Qty</p></div>
                                                                            <div className="col-2"><p className="fs-5 text-center">Configure</p></div>
                                                                            <div className="col-2"><p className="fs-5 text-center"></p></div>
                                                                        </div>
                                                                        {
                                                                            salesReturnItemArray.map((obj, indx) => {
                                                                                return (
                                                                                    <div key={indx} className="row py-2 align-items-center">
                                                                                        <div className="col-4 px-3">
                                                                                            <Select
                                                                                                options={mergedChallanItemList.map(x => { return { label: x.item_name, value: x.item_id } })}
                                                                                                onChange={(val) => {
                                                                                                    if (salesReturnItemArray.find(x => x.item_id === val.value)) {
                                                                                                        swal("Oops!", "Duplicate Items cannot be added", "warning")
                                                                                                        return
                                                                                                    }
                                                                                                    let pd = mergedChallanItemList.find(x => x.item_id === val.value);

                                                                                                    let temp = salesReturnItemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].item_id = val.value
                                                                                                    temp[indx].item_name = val.label
                                                                                                    temp[indx].item_stock_type = pd.item_stock_type
                                                                                                    temp[indx].challan_info = pd.challan_info.map((x) => { return { ...x, returned_quantity: 0, returned_item_data: null, challan_expanded: false } })
                                                                                                    temp[indx].delivered_quantity = pd.delivered_quantity
                                                                                                    setSalesReturnItemArray(temp)
                                                                                                }}
                                                                                                value={obj.item_id == null ? null : { label: obj.item_name, value: obj.item_id }}
                                                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                                placeholder="Select Product..."
                                                                                                menuPosition={'fixed'}
                                                                                                menuShouldBlockScroll={true}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-2 px-3 text-center">
                                                                                            <input type="number" className="form-control form-control-solid px-2" value={obj.delivered_quantity} readOnly />
                                                                                        </div>

                                                                                        <div className="col-2 px-3 text-center">
                                                                                            <input type="number" className="form-control form-control-solid px-2" value={obj.challan_info === null ? 0 : obj.challan_info.reduce((p, o) => { return p + o.returned_quantity }, 0)} readOnly />
                                                                                        </div>

                                                                                        <div className="col-2 px-3 text-center">
                                                                                            {
                                                                                                obj.item_stock_type === "None" ?
                                                                                                    <>
                                                                                                        <button className='btn btn-light-success px-4' onClick={() => { setConfigureItemModalShow(true); setSelectedItemRef(indx); }} >
                                                                                                            Quantity
                                                                                                        </button>
                                                                                                    </>
                                                                                                    :
                                                                                                    obj.item_stock_type === "Track Serial Number" ?
                                                                                                        <>
                                                                                                            <button className='btn btn-light-success px-4' onClick={() => { setConfigureItemModalShow(true); setSelectedItemRef(indx); }} >
                                                                                                                Serial
                                                                                                            </button>
                                                                                                        </>
                                                                                                        : obj.item_stock_type === "Track Batches" ?
                                                                                                            <>
                                                                                                                <button className='btn btn-light-success px-4' onClick={() => { setConfigureItemModalShow(true); setSelectedItemRef(indx); }} >
                                                                                                                    Batch
                                                                                                                </button>
                                                                                                            </>
                                                                                                            : <div className='text-gray-600 fs-4 text-center'>No data required for this item</div>
                                                                                            }
                                                                                        </div>

                                                                                        <div className="col-1 px-1 text-center">
                                                                                            <button className='btn btn-link btn-sm text-hover-danger fs-4' style={{ flex: "1" }} onClick={() => {
                                                                                                let temp = salesReturnItemArray.map(a => { return { ...a } })
                                                                                                temp.splice(indx, 1)
                                                                                                setSalesReturnItemArray(temp)
                                                                                            }}>✖</button>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        {
                                                                            salesReturnItemArray.length < mergedChallanItemList.length &&
                                                                            (
                                                                                <button className="btn btn-light-primary btn-sm p-2" onClick={() => { setSalesReturnItemArray([...salesReturnItemArray, { item_id: null, item_name: null, item_stock_type: null, challan_info: null, delivered_quantity: 0 }]) }}> <i className="bi bi-plus-circle"></i>Add</button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            :
                                                            <div className="flex-lg-row-fluid">
                                                                <div className="card card-flush my-5 shadow-sm border-0">
                                                                    <div className="card-body p-5">
                                                                        <div className="bg-light-secondary border p-13 rounded-3 position-relative">
                                                                            <div className="d-flex flex-wrap flex-stack flex-grow-1 align-items-center gap-3">
                                                                                <div className="fw-bold">
                                                                                    <h4 className="text-gray-800 fw-bolder mb-3">
                                                                                        <i className="bi bi-file-earmark-excel-fill display-6 me-3 text-danger"></i>
                                                                                        No Challan Created for This Invoice
                                                                                    </h4>
                                                                                    <div className="fs-6 text-gray-600">
                                                                                        There are no challans associated with this invoice. Please create a challan to proceed.
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center gap-1">
                                                                                    <span
                                                                                        className="me-3 d-flex align-items-center justify-content-center"
                                                                                        style={{
                                                                                            backgroundColor: "rgba(97, 97, 230, 0.75)",
                                                                                            width: "36px",
                                                                                            height: "36px",
                                                                                            borderRadius: "50%",
                                                                                            cursor: "pointer"
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            setProgressModalShow(true);
                                                                                            getProgressByStatus(selectedCustomerInvoice.value)
                                                                                        }}>
                                                                                        {/* <TbProgress /> */}
                                                                                        <i className="bi bi-clock text-white fs-3"></i>
                                                                                    </span>
                                                                                    <span>
                                                                                        <Link className="btn btn-sm btn-primary" to={`/view-customer-invoice/${selectedCustomerInvoice.value}`}>View Invoice</Link>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                }
                                            </div>

                                            <div className="d-flex justify-content-end mt-15 mx-3">
                                                {
                                                    write == 1 &&
                                                    <button type="button" className="btn btn-lg btn-light-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => {
                                                        validateSalesReturn() && addSalesReturn(false)
                                                    }}>
                                                        <span className="indicator-label">Save as Draft</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                }
                                                {
                                                    write == 1 &&
                                                    <button type="button" className="btn btn-lg btn-light-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => {
                                                        validateSalesReturn() && addSalesReturn(true)
                                                    }}>
                                                        <span className="indicator-label">Save</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                }
                                            </div>

                                        </>
                                        : state == 2 ?
                                            <div className="">
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            </div>
                                            :
                                            <PageError />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div >

            <Modal show={configureItemModalShow} onHide={() => { handleConfigureItemModalClose() }} dialogClassName="h-modal-90" centered>
                <Modal.Header>
                    <Modal.Title>
                        <span className='fs-5 text-gray-500'>
                            Configure
                        </span>
                        <span className="fs-2 ps-2 text-primary">
                            {selectedItemRef !== null && salesReturnItemArray[selectedItemRef].item_name}
                        </span>
                        <span className="fs-2 ps-2 text-gray-500">
                            Total Quantity: {selectedItemRef !== null && salesReturnItemArray[selectedItemRef].delivered_quantity}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-gray-200'>
                    <div className=''>
                        <div className='row gx-3'>
                            {
                                selectedItemRef !== null && salesReturnItemArray[selectedItemRef].challan_info.map((obj, i) => {
                                    return (
                                        <div className="col-md-4 my-3 px-2" key={i}>
                                            <div className="card shadow-sm border-0" style={{ borderRadius: '10px', transition: 'transform 0.3s' }}
                                                onMouseEnter={(e) => e.currentTarget.style.transform = 'translateY(-5px)'}
                                                onMouseLeave={(e) => e.currentTarget.style.transform = 'translateY(0)'}
                                            >
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                                        <div className="btn btn-primary">
                                                            {obj.dc_number} <span className="badge badge-light">{obj.unit}</span>
                                                        </div>
                                                        <button className="btn btn-sm" style={{ backgroundColor: "rgb(102 161 224)", color: 'white', border: 'none', padding: '0.25rem 0.5rem', borderRadius: '5px', transition: 'background-color 0.3s' }}
                                                            onClick={() => {
                                                                let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                t[selectedItemRef].challan_info[i].challan_expanded = !t[selectedItemRef].challan_info[i].challan_expanded
                                                                setSalesReturnItemArray(t)
                                                            }}
                                                        >
                                                            {
                                                                obj.challan_expanded ?
                                                                    <svg height="28" viewBox="0 -960 960 960" width="28">
                                                                        <path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" />
                                                                    </svg> :
                                                                    <svg height="28" viewBox="0 -960 960 960" width="28">
                                                                        <path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" />
                                                                    </svg>
                                                            }
                                                        </button>
                                                    </div>

                                                    {
                                                        selectedItemRef !== null && salesReturnItemArray[selectedItemRef].item_stock_type === "Track Serial Number" ?
                                                            obj.challan_expanded &&
                                                            <div className="mb-3">
                                                                <div className="fw-bold fs-6 text-primary mb-2">Associated Serials</div>
                                                                <div className="d-flex flex-wrap gap-2">
                                                                    {
                                                                        obj.item_data.map((serial) => {

                                                                            let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                            let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)
                                                                            let s = challan?.returned_item_data.find(x => x.uid === serial.uid)

                                                                            return (
                                                                                <div
                                                                                    key={serial.uid}
                                                                                    className={`badge ${s ? "badge-danger" : obj.returned_item_data !== null && obj.returned_item_data.find(z => z.uid === serial.uid) && "badge-primary rounded-pill"} text-dark shadow-sm fs-2 h-hover-scale cursor-pointer`}
                                                                                    style={{ padding: '0.5rem', borderRadius: '5px' }}
                                                                                    onClick={() => {
                                                                                        if (s) {
                                                                                            swal("Cannot be Selected!", "Sales Return has already been created for this serial", "warning")
                                                                                            return
                                                                                        }

                                                                                        let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                        let arr = t[selectedItemRef].challan_info[i].returned_item_data

                                                                                        if (arr === null) {
                                                                                            t[selectedItemRef].challan_info[i].returned_item_data = [serial]
                                                                                            t[selectedItemRef].challan_info[i].returned_quantity = 1
                                                                                        } else {
                                                                                            let fi = arr.findIndex(x => x.uid === serial.uid)
                                                                                            if (fi !== -1) {
                                                                                                if (arr.length === 1) {
                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data = null
                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity = 0
                                                                                                } else {
                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data.splice(fi, 1)
                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity -= 1
                                                                                                }
                                                                                            }
                                                                                            else {
                                                                                                t[selectedItemRef].challan_info[i].returned_item_data.push(serial)
                                                                                                t[selectedItemRef].challan_info[i].returned_quantity += 1
                                                                                            }
                                                                                        }
                                                                                        setSalesReturnItemArray(t)
                                                                                    }}
                                                                                >
                                                                                    {serial.serial_name}
                                                                                </div>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            selectedItemRef !== null && salesReturnItemArray[selectedItemRef].item_stock_type === "Track Batches" ?
                                                                obj.challan_expanded &&
                                                                <div className="mb-3">
                                                                    <div className="fw-bold fs-6 text-primary mb-2">Associated Batches</div>
                                                                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                                                                        {
                                                                            obj.item_data.map((batch) => {

                                                                                let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                                let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)
                                                                                let b = challan?.returned_item_data.find(x => x.uid === batch.uid)

                                                                                return (
                                                                                    <div key={batch.uid} className="border border-2 border-primary fs-2 p-1 rounded text-center">
                                                                                        <span className="badge fs-1 me-2" style={{ backgroundColor: "#2e8ee48a" }}>
                                                                                            {batch.batch_reference + " - " + batch.unit}
                                                                                        </span>
                                                                                        {
                                                                                            b && <span className="badge badge-danger rounded-pill fs-1">{b.unit}</span>
                                                                                        }
                                                                                        <div class="d-flex align-items-center gap-3">
                                                                                            <button className="btn btn-outline-danger btn-sm rounded-circle fs-1 px-5 py-1"
                                                                                                onClick={() => {
                                                                                                    let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                                    let arr = t[selectedItemRef].challan_info[i].returned_item_data

                                                                                                    if (arr !== null) {
                                                                                                        let fi = arr.findIndex(x => x.uid === batch.uid)
                                                                                                        if (fi !== -1) {
                                                                                                            if (arr[fi].unit === 1) {
                                                                                                                if (arr.length === 1) {
                                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data = null
                                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity = 0
                                                                                                                } else {
                                                                                                                    t[selectedItemRef].challan_info[i].returned_item_data.splice(fi, 1)
                                                                                                                    t[selectedItemRef].challan_info[i].returned_quantity -= 1
                                                                                                                }
                                                                                                            } else {
                                                                                                                t[selectedItemRef].challan_info[i].returned_item_data[fi].unit -= 1
                                                                                                                t[selectedItemRef].challan_info[i].returned_quantity -= 1
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    setSalesReturnItemArray(t)
                                                                                                }}>−</button>
                                                                                            <div class="">
                                                                                                {obj.returned_item_data !== null && obj.returned_item_data.find(x => x.uid === batch.uid)?.unit || 0}
                                                                                            </div>
                                                                                            <button
                                                                                                class="btn btn-outline-success btn-sm rounded-circle fs-1 px-5 py-1"
                                                                                                onClick={() => {
                                                                                                    let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                                    let arr = t[selectedItemRef].challan_info[i].returned_item_data

                                                                                                    let fi = arr ? arr.findIndex(x => x.uid === batch.uid) : -1

                                                                                                    let max = batch.unit - (b?.unit || 0)
                                                                                                    let cu = fi === -1 ? 0 : obj.returned_item_data[fi].unit
                                                                                                    if (cu >= max) {
                                                                                                        swal("Oops", `${b ? `For ${batch.batch_reference} out of ${batch.unit} units, ${b.unit} has been returned, Maximum ${max} unit is available for return` : "Maximum unit reached"}`, "warning")
                                                                                                        return
                                                                                                    }

                                                                                                    if (arr === null) {
                                                                                                        t[selectedItemRef].challan_info[i].returned_item_data = [{ ...batch, unit: 1 }]
                                                                                                        t[selectedItemRef].challan_info[i].returned_quantity = 1
                                                                                                    } else {
                                                                                                        if (fi !== -1) {

                                                                                                            t[selectedItemRef].challan_info[i].returned_item_data[fi].unit += 1
                                                                                                            t[selectedItemRef].challan_info[i].returned_quantity += 1
                                                                                                        }
                                                                                                        else {
                                                                                                            t[selectedItemRef].challan_info[i].returned_item_data.push({ ...batch, unit: 1 })
                                                                                                            t[selectedItemRef].challan_info[i].returned_quantity += 1
                                                                                                        }
                                                                                                    }
                                                                                                    setSalesReturnItemArray(t)
                                                                                                }}>+</button>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>

                                                                </div>
                                                                :
                                                                obj.challan_expanded &&
                                                                <div className="mb-3">
                                                                    <div className="fw-bold fs-6 text-primary mb-2">Choose Quantity</div>
                                                                    <div className="col-6 px-3 text-center d-flex gap-2">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control form-control-solid px-2"
                                                                            min={0}
                                                                            value={obj.returned_quantity.toString()}
                                                                            onChange={(e) => {
                                                                                let val = e.target.value === '' ? 0 : parseFloat(e.target.value)

                                                                                let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                                let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)

                                                                                if (val > obj.unit - challan?.returned_quantity || 0) {
                                                                                    swal("Oops", `${challan ? `Maximum ${obj.unit - challan.returned_quantity} unit is available for return` : "Maximum unit reached"}`, "warning")
                                                                                    return
                                                                                }

                                                                                let t = salesReturnItemArray.map((x) => ({ ...x }))
                                                                                t[selectedItemRef].challan_info[i].returned_quantity = val
                                                                                setSalesReturnItemArray(t)
                                                                            }}
                                                                        />
                                                                        {
                                                                            (() => {
                                                                                let item = relatedSalesReturns.find(x => x.item_id === salesReturnItemArray[selectedItemRef].item_id)
                                                                                let challan = item?.challan_info.find(x => x.dc_id === obj.dc_id)
                                                                                if (challan) {
                                                                                    return <span className="badge badge-danger fs-1">{challan.returned_quantity}</span>
                                                                                }
                                                                            })()
                                                                        }
                                                                    </div>
                                                                </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex-lg-row-fluid">
                        <div className="card-body">
                            <div className="fs-1 text-gray-600">
                                Return Quantity: {selectedItemRef !== null && salesReturnItemArray[selectedItemRef].challan_info.reduce((p, o) => { return p + o.returned_quantity }, 0)}
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-outline-info rounded" onClick={() => { handleConfigureItemModalClose() }}>Confirm</button>
                </Modal.Footer>
            </Modal >

            <Modal show={progressModalShow} onHide={() => { setProgressModalShow(false); setProgressStatus([]); }} centered>
                <Modal.Body>

                    {flowApiState === true && (
                        <div className="text-center">
                            <span>Loading...</span>
                            <svg width="50" height="15" viewBox="0 45 100 10">
                                <g transform="translate(20 50)"><circle cx="0" cy="0" r="6" fill="#e15b64"><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                <g transform="translate(40 50)"><circle cx="0" cy="0" r="6" fill="#f8b26a"><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                <g transform="translate(60 50)"><circle cx="0" cy="0" r="6" fill="#abbd81"><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                <g transform="translate(80 50)"><circle cx="0" cy="0" r="6" fill="#81a3bd"><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                            </svg>
                        </div>
                    )}

                    {flowApiState === false && (
                        <>

                            <div className="position-absolute top-0 end-0 p-5">
                                <div onClick={() => { setProgressModalShow(false); setProgressStatus([]) }} className="cursor-pointer">
                                    <i className="bi bi-x-circle fs-1 text-danger"></i>
                                </div>
                            </div>
                            <div className='container mt-6'>
                                {progressStatus.customer_delivery_challan_data &&
                                    progressStatus.customer_delivery_challan_data.length > 0 &&
                                    progressStatus.customer_delivery_challan_data.some(challan =>
                                        challan.customer_delivery_challan_status_logs &&
                                        challan.customer_delivery_challan_status_logs.some(obj => obj.delivery_challan_status === "DELIVERY_DISPATCHED")
                                    ) && (
                                        <div className="alert alert-success mb-4">
                                            <strong>Flow Completed:</strong> The delivery has been dispatched.
                                        </div>
                                    )
                                }
                                <Timeline>
                                    {!progressStatus.customer_invoice_data ||
                                        (progressStatus.customer_invoice_data &&
                                            !progressStatus.customer_invoice_data.customer_invoice_status_logs) ? (
                                        <Timeline.Item color="red" dot={<ClockCircleOutlined />}>
                                            <div className="d-flex flex-wrap">
                                                <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                    <p className="fw-bold text-muted mb-0">Invoice: Processing pending </p>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    ) : (
                                        progressStatus.customer_invoice_data.customer_invoice_status_logs.map((obj) => (
                                            <Timeline.Item key={obj._id} color="green">
                                                <div className="d-flex flex-wrap">
                                                    <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                        <p className={
                                                            `fw - bold text - ${(obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_PENDING_APPROVAL"
                                                                ? "success"
                                                                : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_FINALIZED"
                                                                    ? "success"
                                                                    : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_DRAFT"
                                                                        ? "success"
                                                                        : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_WAREHOUSE"
                                                                            ? "success"
                                                                            : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_CANCELLED" && "success"
                                                            } mb - 0`
                                                        }>
                                                            {
                                                                (obj.invoice_status || obj.customer_invoice_status)
                                                                    .replace(/CUSTOMER_INVOICE_/i, '')
                                                                    .replace(/_/g, ' ')
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            }
                                                        </p>
                                                        <p className="fw-mormal timeline-content text-muted mb-0">
                                                            {
                                                                obj.operation_message
                                                                    .replace(/Successfully/i, '')
                                                                    .replace(/_/g, ' ')
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-center ps-5">
                                                        <p className="fw-bold text-gray-800 fs-6 mb-0">Assigned Date</p>
                                                        <p className="fw-bold text-gray-800 fs-6">{moment(obj.timestamp).utcOffset(timezone_change).format("lll")}</p>
                                                    </div>
                                                </div>
                                            </Timeline.Item>
                                        ))
                                    )}

                                    {!progressStatus.customer_warehouse_processing_data ||
                                        (progressStatus.customer_warehouse_processing_data &&
                                            !progressStatus.customer_warehouse_processing_data.customer_warehouse_processing_status_logs) ? (
                                        <Timeline.Item color="red" dot={<ClockCircleOutlined />}>
                                            <div className="d-flex flex-wrap">
                                                <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                    <p className="fw-bold text-muted mb-0">Warehouse: Processing pending</p>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    ) : (
                                        progressStatus.customer_warehouse_processing_data.customer_warehouse_processing_status_logs.map((obj) => (
                                            <Timeline.Item key={obj._id} color="green">
                                                <div className="d-flex flex-wrap">
                                                    <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                        <p className={`fw - bold text - ${obj.warehouse_processing_status === "CUSTOMER_WAREHOUSE_PROCESSING_INIT" ? "success" : obj.warehouse_processing_status === "CUSTOMER_WAREHOUSE_PROCESSING_PARTIAL" ? "success" : obj.warehouse_processing_status === "CUSTOMER_WAREHOUSE_PROCESSED" && "success"} mb - 0`}>
                                                            {
                                                                obj.warehouse_processing_status
                                                                    .replace(/CUSTOMER_WAREHOUSE/i, '')
                                                                    .replace(/_/g, ' ')
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            }
                                                        </p>
                                                        <p className="fw-mormal timeline-content text-muted mb-0">
                                                            {
                                                                obj.operation_message
                                                                    .replace(/Successfully/i, '')
                                                                    .replace(/_/g, ' ')
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-center ps-5">
                                                        <p className="fw-bold text-gray-800 fs-6 mb-0">Assigned Date</p>
                                                        <p className="fw-bold text-gray-800 fs-6">{moment(obj.timestamp).utcOffset(timezone_change).format("lll")}</p>
                                                    </div>
                                                </div>
                                            </Timeline.Item>
                                        ))
                                    )}

                                    {!progressStatus.customer_delivery_challan_data ||
                                        (progressStatus.customer_delivery_challan_data &&
                                            progressStatus.customer_delivery_challan_data.length <= 0) ? (
                                        <Timeline.Item color="red" dot={<ClockCircleOutlined />}>
                                            <div className="d-flex flex-wrap">
                                                <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                    <p className="fw-bold text-muted mb-0">Chalan: Processing pending</p>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    ) : (
                                        progressStatus.customer_delivery_challan_data.map((challan) =>
                                            challan.customer_delivery_challan_status_logs &&
                                            challan.customer_delivery_challan_status_logs.map((obj) => (
                                                <Timeline.Item key={obj._id} color="green" dot={<CheckCircleOutlined />}>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                            <p className={`fw - bold text - ${obj.delivery_challan_status === "DELIVERY_DISPATCHED" && "success"} mb - 0`}>
                                                                {
                                                                    obj.delivery_challan_status
                                                                        .replace(/_/g, ' ')
                                                                        .toLowerCase()
                                                                        .split(' ')
                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ')
                                                                }
                                                            </p>
                                                            <p className="fw-mormal timeline-content text-muted mb-0">
                                                                {
                                                                    obj.operation_message
                                                                        .replace(/Successfully/i, '')
                                                                        .replace(/_/g, ' ')
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center ps-5">
                                                            <p className="fw-bold text-gray-800 fs-6 mb-0">Assigned Date</p>
                                                            <p className="fw-bold text-gray-800 fs-6">{moment(obj.timestamp).utcOffset(timezone_change).format("lll")}</p>
                                                        </div>
                                                    </div>
                                                </Timeline.Item>
                                            ))
                                        )
                                    )}
                                </Timeline>

                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal >

        </>
    );
};

export default CreateSalesReturn;