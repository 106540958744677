import { Tooltip } from "antd";

//parent report wrapper component
const ReportWidgetWrapper = ({ children }) => {
    return (
        <div className='overflow-auto'>
            <div className='m-5 h-widget'>
                {children}
            </div>
        </div>
    );
};

//child item component for per widget item
const ReportWidgetItem = ({ icon, label, value, hoverValue }) => {

    let md = <div className='border border-3 p-3 rounded shadow-sm fs-4 h-hover-scale'>
        <div>
            <span>{icon}</span>
            <span className="fw-bold fs-1">{value}</span>
        </div>
        <div>
            <span>{label}</span>
        </div>
    </div>

    if (hoverValue) { return <Tooltip title={<div className='fs-2'>{hoverValue}</div>} >{md}</Tooltip> }
    else { return md }
};

export { ReportWidgetWrapper, ReportWidgetItem }
