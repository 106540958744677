
var business_type_arr = new Array(
   {
      "category": "Retail",
      "subcategories": [
        "General Merchandise",
        "Specialty Stores",
        "Department Stores",
        "Discount Stores",
        "Luxury Goods"
      ]
    },
    {
      "category": "Wholesale",
      "subcategories": [
        "Distributors",
        "Suppliers",
        "Import/Export"
      ]
    },
    {
      "category": "Dropshipping",
      "subcategories": [
        "General Dropshipping",
        "Niche Dropshipping",
        "High-Ticket Dropshipping"
      ]
    },
    {
      "category": "Subscription Services",
      "subcategories": [
        "Box Subscriptions",
        "Digital Subscriptions",
        "Membership Sites"
      ]
    },
    {
      "category": "B2B (Business-to-Business)",
      "subcategories": [
        "Industrial Supplies",
        "Professional Services",
        "Office Supplies",
        "Bulk Food Supplies",
        "Medical Supplies"
      ]
    },
    {
      "category": "B2C (Business-to-Consumer)",
      "subcategories": [
        "Fashion and Apparel",
        "Electronics and Gadgets",
        "Home Goods and Decor",
        "Health and Beauty",
        "Food and Beverage",
        "Toys and Games",
        "Books and Media",
        "Outdoor and Adventure",
        "Automotive Parts and Accessories",
        "Gardening Supplies",
        "Fitness Equipment",
        "Baby Products",
        "Pet Supplies",
        "Art and Craft Supplies",
        "Musical Instruments"
      ]
    },
    {
      "category": "C2C (Consumer-to-Consumer)",
      "subcategories": [
        "Online Marketplaces",
        "Classified Ads"
      ]
    },
    {
      "category": "D2C (Direct-to-Consumer)",
      "subcategories": [
        "Brands selling directly to consumers",
        "Custom Apparel",
        "Personalized Products"
      ]
    },
    {
      "category": "Print on Demand",
      "subcategories": [
        "Custom Apparel",
        "Custom Merchandise",
        "Custom Home Decor"
      ]
    },
    {
      "category": "Digital Products",
      "subcategories": [
        "Ebooks",
        "Online Courses",
        "Software",
        "Music and Art",
        "Stock Photography",
        "Printables"
      ]
    },
    {
      "category": "Services",
      "subcategories": [
        "Freelance Services",
        "Consulting Services",
        "Digital Marketing Services",
        "Coaching and Mentoring",
        "Home Services"
      ]
    },
    {
      "category": "Affiliate Marketing",
      "subcategories": [
        "Product Review Sites",
        "Niche Blogs",
        "Coupon and Deal Sites"
      ]
    },
    {
      "category": "Social Commerce",
      "subcategories": [
        "Influencer-driven Stores",
        "Social Media-based Shops"
      ]
    },
    {
      "category": "Custom Products",
      "subcategories": [
        "Personalized Gifts",
        "Custom Jewelry",
        "Custom Furniture"
      ]
    },
    {
      "category": "Event Ticketing",
      "subcategories": [
        "Concerts and Shows",
        "Conferences and Seminars",
        "Sports Events"
      ]
    },
    {
      "category": "Rental Services",
      "subcategories": [
        "Equipment Rentals",
        "Clothing Rentals",
        "Party Supplies Rentals",
        "Vehicle Rentals",
        "Real Estate Rentals"
      ]
    },
    {
      "category": "Second-Hand Goods",
      "subcategories": [
        "Thrift Stores",
        "Vintage Shops",
        "Consignment Stores"
      ]
    },
    {
      "category": "Crowdfunding Pre-orders",
      "subcategories": [
        "Innovative Products",
        "Artistic Projects"
      ]
    },
    {
      "category": "Educational Products",
      "subcategories": [
        "Homeschooling Supplies",
        "Educational Toys",
        "School Supplies"
      ]
    },
    {
      "category": "Health and Wellness",
      "subcategories": [
        "Supplements",
        "Organic Products",
        "Fitness Programs"
      ]
    },
    {
      "category": "Eco-friendly Products",
      "subcategories": [
        "Sustainable Fashion",
        "Zero Waste Products",
        "Reusable Items"
      ]
    },
    {
      "category": "Niche Markets",
      "subcategories": [
        "Anime and Manga Merchandise",
        "Gothic and Alternative Fashion",
        "K-Pop Merchandise",
        "Board Games and Puzzles"
      ]
    },
    {
      "category": "Luxury and High-End Goods",
      "subcategories": [
        "Designer Fashion",
        "Fine Jewelry",
        "High-End Electronics"
      ]
    },
    {
      "category": "Seasonal Products",
      "subcategories": [
        "Holiday Decorations",
        "Seasonal Clothing",
        "Summer/Beach Products"
      ]
    },
    {
      "category": "Hobbies and Interests",
      "subcategories": [
        "Model Kits",
        "Collectibles",
        "DIY Craft Kits",
        "RC Cars and Drones"
      ]
    },
    {
      "category": "Travel and Adventure",
      "subcategories": [
        "Travel Gear",
        "Camping Equipment",
        "Adventure Tours"
      ]
    },
    {
      "category": "Home Improvement",
      "subcategories": [
        "DIY Tools",
        "Building Materials",
        "Smart Home Devices"
      ]
    },
    {
      "category": "Food and Beverage",
      "subcategories": [
        "Specialty Foods",
        "Wine and Spirits",
        "Meal Kits"
      ]
    },
    {
      "category": "Beauty and Personal Care",
      "subcategories": [
        "Skincare Products",
        "Haircare Products",
        "Makeup"
      ]
    },
    {
      "category": "Technology and Gadgets",
      "subcategories": [
        "Smart Devices",
        "Computer Accessories",
        "Home Office Equipment"
      ]
    },
    {
      "category": "Sports and Outdoors",
      "subcategories": [
        "Sports Apparel",
        "Outdoor Gear",
        "Fishing and Hunting Equipment"
      ]
    },
    {
      "category": "Local and Handmade Goods",
      "subcategories": [
        "Artisanal Foods",
        "Handmade Crafts",
        "Local Artisan Products"
      ]
    },
    {
      "category": "Religious and Spiritual Products",
      "subcategories": [
        "Religious Books",
        "Spiritual Jewelry",
        "Meditation Supplies"
      ]
    },
    {
      "category": "Specialty Diets",
      "subcategories": [
        "Gluten-Free Products",
        "Vegan Products",
        "Keto Products"
      ]
    },
    {
      "category": "Parenting and Kids",
      "subcategories": [
        "Parenting Resources",
        "Kids' Clothing",
        "Educational Resources"
      ]
    },
    {
      "category": "Automotive",
      "subcategories": [
        "Car Accessories",
        "Motorcycle Gear",
        "Car Care Products"
      ]
    },
    {
      "category": "Professional Services",
      "subcategories": [
        "Legal Services",
        "Financial Planning",
        "Tax Services"
      ]
    },
    {
      "category": "Art and Collectibles",
      "subcategories": [
        "Original Art",
        "Antiques",
        "Limited Edition Prints"
      ]
    },
    {
      "category": "Charity and Fundraising",
      "subcategories": [
        "Charity Merchandise",
        "Fundraising Campaigns"
      ]
    },
    {
      "category": "Gaming",
      "subcategories": [
        "Video Games",
        "Gaming Accessories",
        "eSports Merchandise"
      ]
    }
);

exports.business_type_arr = business_type_arr;