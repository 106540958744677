import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import "@pathofdev/react-tag-input/build/index.css";
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

export const ShowProfiles = () => {
    const [state, setstate] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)
    const [editmodal, setEditModal] = useState([]);

    var page_name = "profiles";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        async function getprofiledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": process.env.REACT_APP_module_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setEditModal(body);
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
        }
        if ((read || write) && organization_id !== "") {
            user.email && visibility && getprofiledetails();
        }

    }, [user, visibility, read, write, organization_id])

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Show Profiles
                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>

                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state === 1 ?
                            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
                                {editmodal.length > 0 && editmodal.map((obj, index) => {
                                    return (
                                        <>
                                            <div className="col-md-4">
                                                <div className="card card-flush h-md-100">
                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2>{obj.user_profile}</h2>
                                                        </div>
                                                        <div className="card-toolbar">
                                                            <div className="dropdown">
                                                                {
                                                                    (write) ?
                                                                        <>
                                                                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                {obj.default !== "true" && <li className="py-1"><Link to={`update-profile/${obj.user_profile_id}`} className="dropdown-item">Edit Details</Link></li>}
                                                                            </ul>
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="card-body pt-1">
                                                        <div className="fw-bold text-gray-600 mb-5">Total users with this Profile: {obj.user_count}</div>
                                                        <div className="d-flex flex-column text-gray-600">
                                                            {obj.profile_description !== "" &&
                                                                <div className='d-flex align-items-center py-2'>
                                                                    <span className='bullet bg-primary me-3'></span>
                                                                    <em>{obj.profile_description}</em>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <>
                                    <div className="card-body pt-0">
                                        <div className="text-gray-500 fw-bold fs-5 mb-5">Show Profiles</div>
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                    <div className="fs-6 text-gray-600"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>);

};

export default ShowProfiles;
