import React, { useState, useEffect, useRef } from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { Modal } from "react-bootstrap";
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';
import { ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { TbProgress } from "react-icons/tb";
import { Timeline } from 'antd';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

import flow_img from '../flow_images/Customer_order_flow.jpg';
import PageError from '../common_component/PageError.js';
import TableColumnHead from "../common_component/TableColumnHead.js";
import StatusBadge from "../common_component/StatusBadge.js";
import formatNumber from "../common_component/formatNumber.js";


const CustomerOrderList = () => {

    const [state, setstate] = useState(2);
    const [flowModalShow, setFlowModalShow] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))

    const [ordersList, setOrdersList] = useState([])
    const [listCount, setListCount] = useState(0)
    const [ordersListApiState, setOrdersListApiState] = useState(false)
    // const orderListApiController = useRef(new AbortController())

    const [branchesList, setBranchesList] = useState([])
    const [progressModalShow, setProgressModalShow] = useState(false)
    const [progressStatus, setProgressStatus] = useState([])
    const [flowApiState, setFlowApiState] = useState(false)

    const daterange_picker_ref = useRef()
    const [timezone_change, set_timezone_change] = useState("+00:00");

    var page_name = "customer-order-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getBasicOrdersList(cp, rpp, sort_c, sort_v, search_v, sd, ed) {
        setOrdersListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-basic-customer-orders-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setOrdersList(body.data.list);
            setListCount(body.data.count)
            setOrdersListApiState(false)
        }
        else {
            throw "basic orders list api error"
        }
    }

    async function getBranches() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setBranchesList(body.data)
        }
    }

    const getProgressByStatus = async (customer_order_id) => {
        setFlowApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-order-invoice-timeline`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_order_id": customer_order_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setProgressStatus(body.data)
        }
        else {
            swal("Oops!", "Couldn't get order flow", "warning")
        }
        setFlowApiState(false)
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    const timezone = body.data[0].timezone_value;
                    set_timezone_change(timezone);
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getBasicOrdersList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate);
            let p2 = getBranches();
            let p3 = getTimezoneInformation();

            Promise.all([p1, p2, p3])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    console.log(errx)
                    setstate(3)
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getBasicOrdersList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate)
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate]);

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Customer Orders</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <Link to={`/create-customer-order`} className="btn btn-sm btn-primary me-3 rounded btn-hover-scale">
                            <span className="d-md-none">
                                <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                    <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff">
                                    </path>
                                </svg>
                            </span>
                            <span className="d-none d-md-inline">Create Order</span>
                        </Link>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <DateRangePickerComponent
                                            ref={daterange_picker_ref}
                                            allowEdit={false}
                                            showClearButton={false}
                                            focus={() => { daterange_picker_ref.current.show() }}
                                            delayUpdate={true}
                                            presets={[
                                                { label: 'Today', start: new Date(), end: new Date() },
                                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                            ]}
                                            change={(e) => {
                                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                            }}
                                            startDate={new Date(startDate)}
                                            endDate={new Date(endDate)}
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                </div>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                ordersListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    ordersList.length > 0 && branchesList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'customer_order_number'} label={'Order Number'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px">Branch</th>
                                                                            <th className="min-w-150px">Reference Number</th>
                                                                            <th className="min-w-150px">Customer Name</th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'grand_total'} label={'Grand Total'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'order_date'} label={'Order Date'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'due_date'} label={'Due Date'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px">Additional User Email</th>
                                                                            <th className="min-w-150px">Progress</th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'add_status'} label={'Status'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-50px text-end sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            ordersList.map((obj, indx) => {
                                                                                return (
                                                                                    <tr key={obj.customer_order_id}>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.customer_order_number}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="badge badge-light-info">{branchesList.find(x => x.branch_id == obj.branch_id).branch_name}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.reference_number ? obj.reference_number : "NA"}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.customer_name}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.order_currency.currency_symbol}&nbsp;{formatNumber(obj.grand_total, { ...formatNumberOptions, currencyFormat: obj.order_currency.format, decimalPlaces: obj.order_currency.decimal_place })}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.order_date).utcOffset(timezone_change).format("DD-MM-YYYY")}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.due_date).utcOffset(timezone_change).format("DD-MM-YYYY")}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.additional_user_email ? obj.additional_user_email : "NA"}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <button
                                                                                                style={{
                                                                                                    backgroundColor: '#adb8f0',
                                                                                                    border: '1px solid #c0c0c0',
                                                                                                    borderRadius: '4px',
                                                                                                    padding: '5px 10px',
                                                                                                    cursor: 'pointer',
                                                                                                    transition: 'background-color 0.3s',
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    setProgressModalShow(true);
                                                                                                    getProgressByStatus(obj.customer_order_id)
                                                                                                }}>
                                                                                                <TbProgress />
                                                                                            </button>
                                                                                        </td>
                                                                                        <td>
                                                                                            <StatusBadge status={obj.add_status} entity={"CUSTOMER_ORDER"} />
                                                                                        </td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                    <i className="fas fa-bars px-0"></i>
                                                                                                </button>
                                                                                                {
                                                                                                    write &&
                                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                        <li className="py-1 dropdown-item px-0"><Link className="text-black d-block px-4" to={`/view-customer-order/${obj.customer_order_id}`}>View Order Details</Link></li>
                                                                                                        {(obj.add_status == "CUSTOMER_ORDER_DRAFT" || obj.add_status == "CUSTOMER_ORDER_PENDING_APPROVAL" || obj.add_status == "CUSTOMER_ORDER_FINALIZED") && <li className="py-1 dropdown-item px-0"><Link className="text-black d-block px-4" to={`/edit-customer-order/${obj.customer_order_id}`}>Edit Order Details</Link></li>}
                                                                                                        {(obj.add_status == "CUSTOMER_ORDER_PENDING_APPROVAL") && <li className="py-1 dropdown-item px-0"><Link className="text-black d-block px-4" to={`/view-customer-order/${obj.customer_order_id}`}>Approve Order</Link></li>}
                                                                                                        {(obj.add_status == "CUSTOMER_ORDER_FINALIZED") && <li className="py-1 dropdown-item px-0"><Link className="text-black d-block px-4" to={`/view-customer-order/${obj.customer_order_id}`}>Process Order to Invoice</Link></li>}
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                state == 3 ?
                                    <PageError>
                                        <Link className='btn btn-light-success rounded' to={`/create-customer-order`}>create sales order</Link>
                                    </PageError>
                                    :
                                    <PageError>
                                        <Link className='btn btn-light-success rounded' to={`/dashboard`}>Go to Dashboard</Link>
                                    </PageError>
                    }
                </div>
            </div >

            <Modal show={progressModalShow} onHide={() => { setProgressModalShow(false); setProgressStatus([]); }} centered>
                <Modal.Body>

                    {flowApiState === true && (
                        <div className="text-center">
                            <span>Loading...</span>
                            <svg width="50" height="15" viewBox="0 45 100 10">
                                <g transform="translate(20 50)"><circle cx="0" cy="0" r="6" fill="#e15b64"><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                <g transform="translate(40 50)"><circle cx="0" cy="0" r="6" fill="#f8b26a"><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                <g transform="translate(60 50)"><circle cx="0" cy="0" r="6" fill="#abbd81"><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                <g transform="translate(80 50)"><circle cx="0" cy="0" r="6" fill="#81a3bd"><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                            </svg>
                        </div>
                    )}

                    {flowApiState === false && (
                        <>
                            {/* <div className="bg-success me-1" style={{ width: "10px", height: "10px", display: "inline-block" }}></div><span className="me-5">Order</span>
                            <div className="bg-primary me-1" style={{ width: "10px", height: "10px", display: "inline-block" }}></div><span className="me-5">Invoice</span>
                            <div className="bg-warning me-1" style={{ width: "10px", height: "10px", display: "inline-block" }}></div><span className="me-5">Warehouse</span>
                            <div className="bg-danger me-1" style={{ width: "10px", height: "10px", display: "inline-block" }}></div><span className="me-5">Challan</span> */}

                            <div className="position-absolute top-0 end-0 p-5">
                                <div onClick={() => { setProgressModalShow(false); setProgressStatus([]) }} className="cursor-pointer">
                                    <i className="bi bi-x-circle fs-1 text-danger"></i>
                                </div>
                            </div>
                            <div className='container mt-6'>
                                {progressStatus.customer_delivery_challan_data &&
                                    progressStatus.customer_delivery_challan_data.length > 0 &&
                                    progressStatus.customer_delivery_challan_data.some(challan =>
                                        challan.customer_delivery_challan_status_logs &&
                                        challan.customer_delivery_challan_status_logs.some(obj => obj.delivery_challan_status === "DELIVERY_DISPATCHED")
                                    ) && (
                                        <div className="alert alert-success mb-4">
                                            <strong>Flow Completed:</strong> The delivery has been dispatched.
                                        </div>
                                    )
                                }
                                <Timeline>
                                    {progressStatus.customer_order_data &&
                                        progressStatus.customer_order_data.customer_order_status_logs &&
                                        progressStatus.customer_order_data.customer_order_status_logs.map((obj) => (
                                            <Timeline.Item key={obj._id} color="green">
                                                <div className="d-flex flex-wrap">
                                                    <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                        <p className={`fw-bold text-${obj.customer_order_status === "CUSTOMER_ORDER_PENDING_APPROVAL" ? "success" : obj.customer_order_status === "CUSTOMER_ORDER_FINALIZED" ? "success" : obj.customer_order_status === "CUSTOMER_ORDER_DRAFT" ? "success" : obj.customer_order_status === "CUSTOMER_ORDER_INVOICED" ? "success" : obj.customer_order_status === "CUSTOMER_ORDER_CANCELLED" && "success"} mb-0`}>
                                                            {
                                                                obj.customer_order_status
                                                                    .replace(/CUSTOMER_ORDER_/i, '')
                                                                    .replace(/_/g, ' ')
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            }
                                                        </p>
                                                        <p className="fw-mormal timeline-content text-muted mb-0">
                                                            {
                                                                obj.operation_message
                                                                    .replace(/Successfully/i, '')
                                                                    .replace(/_/g, ' ')
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-center ps-5">
                                                        <p className="fw-bold text-gray-800 fs-6 mb-0">Assigned Date</p>
                                                        <p className="fw-bold text-gray-800 fs-6">{moment(obj.timestamp).utcOffset(timezone_change).format("lll")}</p>
                                                    </div>
                                                </div>
                                            </Timeline.Item>
                                        ))}

                                    {!progressStatus.customer_invoice_data ||
                                        (progressStatus.customer_invoice_data &&
                                            !progressStatus.customer_invoice_data.customer_invoice_status_logs) ? (
                                        <Timeline.Item color="red" dot={<ClockCircleOutlined />}>
                                            <div className="d-flex flex-wrap">
                                                <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                    <p className="fw-bold text-muted mb-0">Invoice: Processing pending </p>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    ) : (
                                        progressStatus.customer_invoice_data.customer_invoice_status_logs.map((obj) => (
                                            <Timeline.Item key={obj._id} color="green">
                                                <div className="d-flex flex-wrap">
                                                    <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                        <p className={
                                                            `fw-bold text-${(obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_PENDING_APPROVAL"
                                                                ? "success"
                                                                : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_FINALIZED"
                                                                    ? "success"
                                                                    : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_DRAFT"
                                                                        ? "success"
                                                                        : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_WAREHOUSE"
                                                                            ? "success"
                                                                            : (obj.invoice_status || obj.customer_invoice_status) === "CUSTOMER_INVOICE_CANCELLED" && "success"
                                                            } mb-0`
                                                        }>
                                                            {
                                                                (obj.invoice_status || obj.customer_invoice_status)
                                                                    .replace(/CUSTOMER_INVOICE_/i, '')
                                                                    .replace(/_/g, ' ')
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            }
                                                        </p>
                                                        <p className="fw-mormal timeline-content text-muted mb-0">
                                                            {
                                                                obj.operation_message
                                                                    .replace(/Successfully/i, '')
                                                                    .replace(/_/g, ' ')
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-center ps-5">
                                                        <p className="fw-bold text-gray-800 fs-6 mb-0">Assigned Date</p>
                                                        <p className="fw-bold text-gray-800 fs-6">{moment(obj.timestamp).utcOffset(timezone_change).format("lll")}</p>
                                                    </div>
                                                </div>
                                            </Timeline.Item>
                                        ))
                                    )}

                                    {!progressStatus.customer_warehouse_processing_data ||
                                        (progressStatus.customer_warehouse_processing_data &&
                                            !progressStatus.customer_warehouse_processing_data.customer_warehouse_processing_status_logs) ? (
                                        <Timeline.Item color="red" dot={<ClockCircleOutlined />}>
                                            <div className="d-flex flex-wrap">
                                                <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                    <p className="fw-bold text-muted mb-0">Warehouse: Processing pending</p>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    ) : (
                                        progressStatus.customer_warehouse_processing_data.customer_warehouse_processing_status_logs.map((obj) => (
                                            <Timeline.Item key={obj._id} color="green">
                                                <div className="d-flex flex-wrap">
                                                    <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                        <p className={`fw-bold text-${obj.warehouse_processing_status === "CUSTOMER_WAREHOUSE_PROCESSING_INIT" ? "success" : obj.warehouse_processing_status === "CUSTOMER_WAREHOUSE_PROCESSING_PARTIAL" ? "success" : obj.warehouse_processing_status === "CUSTOMER_WAREHOUSE_PROCESSED" && "success"} mb-0`}>
                                                            {
                                                                obj.warehouse_processing_status
                                                                    .replace(/CUSTOMER_WAREHOUSE/i, '')
                                                                    .replace(/_/g, ' ')
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')
                                                            }
                                                        </p>
                                                        <p className="fw-mormal timeline-content text-muted mb-0">
                                                            {
                                                                obj.operation_message
                                                                    .replace(/Successfully/i, '')
                                                                    .replace(/_/g, ' ')
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="d-flex flex-column align-items-center ps-5">
                                                        <p className="fw-bold text-gray-800 fs-6 mb-0">Assigned Date</p>
                                                        <p className="fw-bold text-gray-800 fs-6">{moment(obj.timestamp).utcOffset(timezone_change).format("lll")}</p>
                                                    </div>
                                                </div>
                                            </Timeline.Item>
                                        ))
                                    )}

                                    {!progressStatus.customer_delivery_challan_data ||
                                        (progressStatus.customer_delivery_challan_data &&
                                            progressStatus.customer_delivery_challan_data.length <= 0) ? (
                                        <Timeline.Item color="red" dot={<ClockCircleOutlined />}>
                                            <div className="d-flex flex-wrap">
                                                <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                    <p className="fw-bold text-muted mb-0">Chalan: Processing pending</p>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    ) : (
                                        progressStatus.customer_delivery_challan_data.map((challan) =>
                                            challan.customer_delivery_challan_status_logs &&
                                            challan.customer_delivery_challan_status_logs.map((obj) => (
                                                <Timeline.Item key={obj._id} color="green" dot={<CheckCircleOutlined />}>
                                                    <div className="d-flex flex-wrap">
                                                        <div className="d-flex flex-column ps-3" style={{ maxWidth: "150px" }}>
                                                            <p className={`fw-bold text-${obj.delivery_challan_status === "DELIVERY_DISPATCHED" && "success"} mb-0`}>
                                                                {
                                                                    obj.delivery_challan_status
                                                                        .replace(/_/g, ' ')
                                                                        .toLowerCase()
                                                                        .split(' ')
                                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                        .join(' ')
                                                                }
                                                            </p>
                                                            <p className="fw-mormal timeline-content text-muted mb-0">
                                                                {
                                                                    obj.operation_message
                                                                        .replace(/Successfully/i, '')
                                                                        .replace(/_/g, ' ')
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="d-flex flex-column align-items-center ps-5">
                                                            <p className="fw-bold text-gray-800 fs-6 mb-0">Assigned Date</p>
                                                            <p className="fw-bold text-gray-800 fs-6">{moment(obj.timestamp).utcOffset(timezone_change).format("lll")}</p>
                                                        </div>
                                                    </div>
                                                </Timeline.Item>
                                            ))
                                        )
                                    )}
                                </Timeline>

                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal >

            <Modal show={flowModalShow} onHide={() => { setFlowModalShow(false) }} dialogClassName="h-modal-80">
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-gray-500'>Order Flow Cycle</span>
                    </Modal.Title>

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFlowModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <img src={flow_img} className='w-100' alt="Not yet provided" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setFlowModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal >

            {/* <Modal.Body>
                {flowApiState === 'loading' ? (
                    <div className="text-center">
                        <span>Loading...</span>
                        <svg width="50" height="15" viewBox="0 45 100 10">
                            <g transform="translate(20 50)"><circle cx="0" cy="0" r="6" fill="#e15b64"><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                            <g transform="translate(40 50)"><circle cx="0" cy="0" r="6" fill="#f8b26a"><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                            <g transform="translate(60 50)"><circle cx="0" cy="0" r="6" fill="#abbd81"><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                            <g transform="translate(80 50)"><circle cx="0" cy="0" r="6" fill="#81a3bd"><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                        </svg>
                    </div>
                ) : (
                    <>
                        <div className="position-absolute top-0 end-0 p-2">
                            <div onClick={() => { setProgressModalShow(false); setProgressStatus([]) }} className="cursor-pointer">
                                <i className="bi bi-x-circle fs-2 text-danger"></i>
                            </div>
                        </div>
                        <div className='container mt-2'>
                            <Timeline>
                                {
                                    progressStatus.customer_order_data &&
                                    progressStatus.customer_order_data.customer_order_status_logs &&
                                    progressStatus.customer_order_data.customer_order_status_logs.map((obj) => (
                                        <Timeline.Item key={obj._id} color="green">
                                            {
                                                obj.customer_order_status
                                                    .replace(/CUSTOMER_ORDER_/i, '')
                                                    .replace(/_/g, ' ')
                                                    .toLowerCase()
                                                    .split(' ')
                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                    .join(' ')
                                            }
                                        </Timeline.Item>
                                    ))
                                }
                                {
                                    progressStatus.customer_invoice_data &&
                                    progressStatus.customer_invoice_data.customer_invoice_status_logs &&
                                    progressStatus.customer_invoice_data.customer_invoice_status_logs.map((obj) => (
                                        <Timeline.Item key={obj._id} color="blue">
                                            {
                                                obj.invoice_status
                                                    .replace(/CUSTOMER_INVOICE_/i, '')
                                                    .replace(/_/g, ' ')
                                                    .toLowerCase()
                                                    .split(' ')
                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                    .join(' ')
                                            }
                                        </Timeline.Item>
                                    ))
                                }
                                {
                                    progressStatus.customer_warehouse_processing_data &&
                                    progressStatus.customer_warehouse_processing_data.customer_warehouse_processing_status_logs &&
                                    progressStatus.customer_warehouse_processing_data.customer_warehouse_processing_status_logs.map((obj) => (
                                        <Timeline.Item key={obj._id} color="orange">
                                            {
                                                obj.warehouse_processing_status
                                                    .replace(/CUSTOMER_WAREHOUSE_/i, '')
                                                    .replace(/_/g, ' ')
                                                    .toLowerCase()
                                                    .split(' ')
                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                    .join(' ')
                                            }
                                        </Timeline.Item>
                                    ))
                                }
                                {
                                    progressStatus.customer_delivery_challan_data &&
                                    progressStatus.customer_delivery_challan_data.length > 0 &&
                                    progressStatus.customer_delivery_challan_data.map((challan) =>
                                        challan.customer_delivery_challan_status_logs &&
                                        challan.customer_delivery_challan_status_logs.map((obj) => (
                                            <Timeline.Item key={obj._id} color="red">
                                                {
                                                    obj.delivery_challan_status
                                                        .replace(/_/g, ' ')
                                                        .toLowerCase()
                                                        .split(' ')
                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(' ')
                                                }
                                            </Timeline.Item>
                                        ))
                                    )
                                }
                            </Timeline>
                        </div>
                    </>
                )}
            </Modal.Body> */}
        </>
    )
};

export default CustomerOrderList;
