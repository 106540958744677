import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import { Modal } from 'react-bootstrap';
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';
import { Drawer, Button, Space } from 'antd';


export const ItemCollectionList = () => {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);


    const [user, setUser] = useState({});
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);

    const [state, setstate] = useState(2);
    const [open, setOpen] = useState(false);

    const [itemGroupFlag, setItemGroupFlag] = useState(2)
    const [itemCollectionName, setItemCollectionName] = useState("")
    const [Item_collection_id, setItem_collection_id] = useState("")
    const [itemCollectionDetails, setItemCollectionDetails] = useState(null)
    const [itemGroupCollectionList, setItemGroupCollectionList] = useState([])

    const [data, setData] = useState([]);
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [groupListApiState, setGroupListApiState] = useState(false);

    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);


    const [StatusModalShow, setStatusModalShow] = useState(false);
    const [productid, setproductid] = useState("");
    const [status, setstatus] = useState("");
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    var page_name = "item-collection-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const onRowClick = async (rowData, rowMeta) => {

        var object = {};
        object.email = user.email;
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-item-collection-details`,
            {
                method: 'POST',
                body: JSON.stringify({ "item_collection_id": rowData[0], "email": user.email, "page_name": page_name, "organization_id": organization_id, }), headers: { 'Content-type': 'application/json; charset=UTF-8', token: getCookie('access_token'), },
            }
        );
        const body = await response.json();
        if (body.operation === 'success') {
            setItemGroupFlag(1);
            setItemCollectionName(rowData[1]);
            setItem_collection_id(rowData[0]);
            setItemCollectionDetails(body.data);
        }
        else {
            setItemGroupFlag(2);
        }
        setOpen(true);
    };

    const onClose = () => {
        setItemGroupFlag(0);
        setItemCollectionName("");
        setItem_collection_id();
        setItemCollectionDetails(null);
        setOpen(false);
    };

    const options = {
        fixedHeader: false,
        selectableRows: "none",
        tableBodyHeight: "400",
        onRowClick: onRowClick,
        responsive: "standard",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "item_collection_id",
            label: "item_collection_id",
            options: {
                display: false,
                filter: true,
                sort: true
            }
        },
        {
            name: "item_collection_name",
            label: "Collection Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "item_details",
            label: "Collection Count",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "add_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <SetColor value={value} index={tableMeta.columnIndex} change={(event) => updateValue(event)} />
                ),
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;

                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => { window.location.href = "/login" });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }

                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            timer.current = process.env.REACT_APP_JWT_EXPIRY;
                        }
                        else {
                            timer.current = res.data.audience_count;
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {

            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    async function getItemGroupCollectionList(cp, rpp, sort_c, sort_v, search_v) {
        setGroupListApiState(true);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-item-collection-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id, "current_page": cp, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.data.list.length > 0) {
            setItemGroupCollectionList(body.data.list);
            setListCount(body.data.count);

        }
        setGroupListApiState(false);
    }

    useEffect(() => {
        if (((read == 1) || (write == 1)) && (user.email && visibility)) {
            let p1 = getItemGroupCollectionList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue);

            Promise.all([p1])
                .then(() => {
                    setstate(1);
                    console.log("all apis done");
                })
                .catch((err) => {
                    setstate(3);
                    console.log(err);
                });
        }
    }, [user, visibility, read, write]);

    useEffect(() => {
        if (state == 1) {
            getItemGroupCollectionList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue)
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue])

    useEffect(() => {

        var temp_data = [];
        itemGroupCollectionList.map((obj) => {
            var temp_obj = {};
            temp_obj.item_collection_name = obj.item_collection_name;
            // temp_obj.item_group_description = obj.item_group_description;
            temp_obj.add_status = obj.add_status;
            temp_obj.item_collection_id = obj.item_collection_id;
            temp_obj.id = obj.item_collecction_id;
            temp_obj.item_details = obj.item_details.length

            let id = obj.item_collection_id;

            temp_obj.actions = <div className="dropdown">
                <button onClick={(e) => { e.stopPropagation() }} className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                {
                    write &&
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li className="py-1"><Link to={`/configure-item-collection/${id}`} className="dropdown-item">Configure Item collection</Link></li>
                        <li className="py-1"><div className="dropdown-item" style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); updateStatus(id); setStatusModalShow(true); }} >Change status</div></li>
                    </ul>
                }
            </div>
            temp_data.push(temp_obj);
        })
        setData(temp_data);

    }, [itemGroupCollectionList])

    const updateStatus = (id) => {
        for (let i = 0; i < itemGroupCollectionList.length; i++) {
            if (id == itemGroupCollectionList[i].item_collection_id) {
                setproductid(id);
                setstatus(itemGroupCollectionList[i].add_status);
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": productid, "status": status, "page_name": page_name, "organization_id": organization_id };
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-item-collection`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    return (
        <>
            {
                (redirect === 1 || (read === 0 && write === 0)) && <Redirect to={{ pathname: "/unauthorized" }} />
            }
            {
                redirect_to_login === 1 && <Redirect to={{ pathname: "/login" }} />
            }

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Item Collection List
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <div className="m-0">
                                <Link to={`/configure-item-collection`} className="btn btn-sm btn-primary me-3 rounded">
                                    <span className="svg-icon svg-icon-2">
                                        <i class="bi bi-collection"></i>
                                    </span>
                                    Create new Collection</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1 ?
                                <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>
                                    <MUIDataTable
                                        title={
                                            <>
                                                <span className='me-20'>Item Collection List</span>
                                                {groupListApiState && <><span>Loading...</span>
                                                    <svg width="50" height="15" viewBox="0 45 100 10">
                                                        <g transform="translate(20 50)"><circle cx="0" cy="0" r="6" fill="#e15b64"><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                        <g transform="translate(40 50)"><circle cx="0" cy="0" r="6" fill="#f8b26a"><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                        <g transform="translate(60 50)"><circle cx="0" cy="0" r="6" fill="#abbd81"><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                        <g transform="translate(80 50)"><circle cx="0" cy="0" r="6" fill="#81a3bd"><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                    </svg>
                                                </>}
                                            </>
                                        }
                                        data={data}
                                        columns={columns}
                                        options={{
                                            ...options,
                                            download: false,
                                            filter: false,
                                            searchPlaceholder: 'Search Item Group',
                                            serverSide: true,
                                            count: listCount,
                                            onChangePage: (val) => { setCurrentPage(val) },
                                            onChangeRowsPerPage: (val) => { setCurrentPage(0); setRowsPerPage(val) },
                                            onColumnSortChange: (col, dir) => { setSortColumn(col); setSortValue(dir) },
                                            onSearchChange: (val) => { setCurrentPage(0); setSearchValue(val) },
                                        }}
                                    />
                                </MuiThemeProvider>
                                :
                                state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <div className="card-body pt-0">
                                        <div className="text-gray-500 fw-bold fs-5 mb-5">Item Collection List</div>
                                        <div className="notice d-flex bg-light-danger rounded border-danger border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                    <div className="fs-6 text-gray-600"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
            <Modal show={StatusModalShow} onHide={() => { setStatusModalShow() }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Change Status</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setStatusModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                            <h3>Are you sure about changing status?</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {write == 1 && <form >
                        <button type="button" onClick={(e) => { submitStatus(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Update</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                    </form>}
                </Modal.Footer>
            </Modal>

            <Drawer title={itemCollectionName} width={window.innerWidth > 1000 ? "50%" : window.innerWidth - 100} bodyStyle={{ padding: "1.5rem" }} onClose={onClose} open={open}
                closeIcon={
                    <a onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="38px" height="38px" > <path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /> <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /> <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /> </svg>
                    </a>
                }
                extra={
                    <Space>
                        <div className="dropdown">
                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false" >
                                <i className="fas fa-bars px-0"></i>
                            </button>
                            {write && (
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                    <li className="py-1"> <Link to={`/configure-item-collection/${Item_collection_id}`} className="dropdown-item"> Configure Item Collection </Link> </li>
                                </ul>
                            )}
                        </div>
                    </Space>
                }
            >
                {
                    itemGroupFlag === 1 ? itemCollectionDetails != null &&
                        <div className="card border-primary mb-3 ">
                            <div className="card-header cursor-pointer py-3">
                                <div className="card-title m-0">
                                    <h3 className="fw-bold m-0">Collection Details</h3>
                                </div>
                            </div>
                            <div className="card-body p-3">
                                <div className="row mb-2">
                                    <label className="col-lg-4 fw-semibold text-muted">Item Collection Name</label>
                                    <div className="col-lg-8">
                                        <span className="fw-bold fs-6 text-gray-800">{itemCollectionDetails.item_collection_name}</span>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <label className="col-lg-4 fw-semibold text-muted">Status</label>
                                    <div className="col-lg-8 d-flex align-items-center">
                                        <span className="badge badge-success">{itemCollectionDetails.add_status}</span>
                                    </div>
                                </div>
                                <div className="card-title mt-4 mb-2">
                                    <h5 className="fw-bold m-0">Items present</h5>
                                </div>
                                <div className="card bg-light mt-4 mb-2 p-3">
                                    {itemCollectionDetails.item_details.map((Item, index) => {
                                        const isPrimary = Item.primary === true;
                                        return (
                                            <div className="col-sm-6" key={index}>
                                                <div className="card mb-3">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{Item.item_name}</h5>
                                                        {isPrimary && (
                                                            <p className="btn btn-outline-primary btn-sm fw-bold mb-5">Primary Item</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        : itemGroupFlag === 2 ?
                            <div className="card">
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            </div>
                            : itemGroupFlag == 0 &&
                            <div className="card">
                                <div className="pt-10 pb-0">
                                    <h3 className="text-dark text-center fw-bolder">Authorization error</h3>
                                    <div className="text-center text-gray-600 fw-bold pt-1">
                                        There is some authorization error. You need to log in further.
                                    </div>
                                    <div className="text-center mt-5 mb-9"> <a onClick={(e) => { setRedirect_to_login(1); }}>Log In</a> </div>
                                </div>
                            </div>
                }
            </Drawer >

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} timer_ref={timer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
        </>);

};

export default ItemCollectionList;
