import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Drawer, Pagination, Space } from 'antd';

import { useTheme } from "./theme-provider.js"
import formatNumber from '../common_component/formatNumber.js';
import { ReportWidgetWrapper, ReportWidgetItem } from '../common_component/ReportWidgets.js';
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js";
import PageError from '../common_component/PageError.js';

function BankReport(props) {
    const [state, setState] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    const daterange_picker_ref = useRef();
    const apiController = useRef(new AbortController())

    const [bankReportList, setBankReportList] = useState([])
    const [bankReportApiState, setBankReportApiState] = useState(false);

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(30, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [baseCurrency, setBaseCurrency] = useState(null)

    const [reportTypeList, setReportTypeList] = useState(["Credit", "Debit"]);
    const [selectedBank, setSelectedBank] = useState({ label: "All", value: "All" });
    const [reportType, setReportType] = useState("All");
    const [bankList, setBankList] = useState([]);

    const [graphData, setGraphData] = useState([])
    const [Transaction_log_details, setTransaction_log_details] = useState(null);

    const [navtype, setnavtype] = useState("Overview");
    const [Dealer_name, setDealer_name] = useState("");
    const [open, setOpen] = useState(false);

    var page_name = "bank-report";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)


    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getBankReport(cp, rpp, search_v, sd, ed, selected_bank, report_type) {
        if (bankReportApiState) {
            apiController.current.abort()
            apiController.current = new AbortController()
        }
        setBankReportApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-bank-report`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "selected_bank": selected_bank, "report_type": report_type, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation !== "success") throw "get Bank Report api error"

        setBankReportList(body.data.list)
        setListCount(body.data.count)
        setBankReportApiState(false)
    }

    async function getbasecurrency() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            setBaseCurrency(body[0])
        }
    }

    async function selectiveaccounthead() {
        let arr = [
            { "category_name": "Bank", "headArray": [] }
        ]
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/selective-account-head`, { method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "headRequest": arr, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success" && body.data.length > 0) {
            setBankList(body.data[0].headArray.map((obj) => { return { label: obj.name, value: obj.hid } }))
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getBankReport(currentPage, rowsPerPage, searchValue, startDate, endDate, "All", reportType);
            let p2 = getbasecurrency();
            let p3 = selectiveaccounthead();
            Promise.all([p1, p2, p3])
                .then(() => {
                    setState(1);
                    console.log("all apis done")
                })
                .catch((err) => {
                    setState(0)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getBankReport(currentPage, rowsPerPage, searchValue, startDate, endDate, selectedBank.value, reportType)
        }
    }, [currentPage, rowsPerPage, searchValue, startDate, endDate, selectedBank, reportType])

    const onClose = () => {
        setOpen(false);
    }

    const First_graph_set_up = (data) => {
        let arr = [];
        for (let i = 0; i < data.length; i++) {
            let date = moment(data[i].log_date_time).format('D MMM');
            if (arr.find(x => x.date == date)) {
                let p = arr.find(x => x.date == date);
                if (data[i].deposit_amnt !== 0) {
                    p.Deposit += data[i].deposit_amnt;
                    p.Deposit = (Math.round(Number(p.Deposit) * 100) / 100);
                }
                else {
                    p.Withdrawl -= data[i].withdrawl_amnt;
                    p.Withdrawl = (Math.round(Number(p.Withdrawl) * 100) / 100);
                }
            }
            else {
                let obj = {};
                obj.date = moment(date).format("D MMM");
                if (data[i].deposit_amnt !== 0) {
                    obj.Deposit = data[i].deposit_amnt;
                    obj.cashcolor = "hsl(154, 70%, 50%)";
                }
                else {
                    obj.Withdrawl = -data[i].withdrawl_amnt;
                    obj.Chequecolor = "hsl(224, 70%, 50%)";
                }
                arr.push(obj)
            }
        }
        arr = All_data_set_up(arr);
        setGraphData(arr);
        return true;
    }

    const All_data_set_up = (data) => {
        let arr = [];
        let arr1 = [];

        arr.map((obj, index) => {
            let obj1 = {};
            if (data.filter(option => option.date === moment(obj).format('D MMM')).length > 0) {
                obj1 = data.filter(option => option.date === moment(obj).format('D MMM'))[0];
            }

            arr1.push(obj1);
        })
        return arr1;
    }

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Bank Report</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <div className="m-0">
                            <DateRangePickerComponent
                                ref={daterange_picker_ref}
                                allowEdit={false}
                                showClearButton={false}
                                focus={() => { daterange_picker_ref.current.show() }}
                                delayUpdate={true}
                                presets={[
                                    { label: 'Today', start: new Date(), end: new Date() },
                                    { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                    { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                    { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                ]}
                                change={(e) => {
                                    setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                    setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                }}
                                startDate={new Date(startDate)}
                                endDate={new Date(endDate)}
                                max={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {
                        state == 1 ?
                            <>
                                <div className="row mb-sm-5 gx-0">
                                    <div className="col-md-4 px-1 mb-2">
                                        <label className="required fs-5 fw-bold mb-2">Bank:</label>
                                        <Select
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            options={[{ label: "All", value: "All" }, ...bankList]}
                                            onChange={(val) => {
                                                setSelectedBank(val);
                                            }}
                                            value={selectedBank}
                                        />
                                    </div>
                                    <div className="col-md-4 px-1 mb-2">
                                        <label className="required fs-5 fw-bold mb-2">Type:</label>
                                        <Select
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            options={[{ label: "All", value: "All" }, ...reportTypeList.map((x) => { return { label: x, value: x } })]}
                                            onChange={(val) => {
                                                setReportType(val.value);
                                            }}
                                            value={{ label: reportType, value: reportType }}
                                        />
                                    </div>
                                </div>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-header border-0 pt-5">
                                        <ReportWidgetWrapper>
                                            <ReportWidgetItem
                                                icon={<i className="bi bi-clipboard-data-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                                label={"Total Credit"}
                                                value={baseCurrency.currency_symbol + formatNumber(bankReportList.reduce((p, o) => p + o.deposit_amnt, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                                hoverValue={baseCurrency.currency_symbol + formatNumber(bankReportList.reduce((p, o) => p + o.deposit_amnt, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            />
                                            <ReportWidgetItem
                                                icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                                label={"Total Debit"}
                                                value={baseCurrency.currency_symbol + formatNumber(bankReportList.reduce((p, o) => p + o.withdrawl_amnt, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                                hoverValue={baseCurrency.currency_symbol + formatNumber(bankReportList.reduce((p, o) => p + o.withdrawl_amnt, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                            />
                                        </ReportWidgetWrapper>
                                    </div>

                                    <div className="card-body py-3">
                                        <div className="mb-5">
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                bankReportApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    bankReportList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-140px">Date</th>
                                                                            <th className="min-w-160px">Account Head</th>
                                                                            <th className="min-w-120px">Ref User</th>
                                                                            <th className="min-w-120px">Operaion</th>
                                                                            {(reportType == "All" || reportType == "Credit") &&
                                                                                <th class="min-w-120px">Credit</th>}
                                                                            {(reportType == "All" || reportType == "Debit") &&
                                                                                <th class="min-w-120px">Debit</th>}
                                                                            <th className="min-w-120px">User</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            bankReportList.map((obj, indx3) => {
                                                                                return (
                                                                                    <tr key={obj._id}>
                                                                                        <td><span className="text-dark fw-bold mb-1 fs-6 gap-2 d-flex align-items-center"><span>{moment(obj.log_date_time).format('ll')}</span><i onClick={(e) => { setOpen(true); setTransaction_log_details(obj) }} className="fa-solid fa-eye fs-2 p-0 text-hover-primary cursor-pointer"></i></span></td>
                                                                                        <td><span className="badge fs-8" style={{ backgroundColor: "cornflowerblue" }}>{bankList.find(option => option.value == obj.acc_hid).label}</span></td>
                                                                                        <td>{obj.ref_user_role != "" ? <span className="badge badge-secondary">{obj.ref_user_role} - {obj.ref_user_name}</span> : <span className="badge badge-secondary">--</span>}</td>
                                                                                        <td><a className="cursor-pointer d-flex gap-2"><span className="badge badge-secondary">{obj.operation}</span><span className="badge badge-primary">{obj.ref_number}</span></a></td>
                                                                                        <td><span className="text-success fw-bold d-block mb-1 fs-6">{baseCurrency.currency_symbol + formatNumber(obj.deposit_amnt, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                        <td><span className="text-danger fw-bold d-block mb-1 fs-6">{baseCurrency.currency_symbol + formatNumber(obj.withdrawl_amnt, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                        <td><span className="badge badge-secondary">{obj.user_id}</span></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>

            <Drawer title={Dealer_name} width={window.innerWidth > 1200 ? 800 : 'auto'} bodyStyle={{ padding: "1.5rem" }} onClose={onClose} open={open} closeIcon={<a onClick={onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="38px" height="38px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z" /><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z" /><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z" /></svg>
            </a>}
                extra={
                    <Space>
                        {/* <Button onClick={onClose} type="primary">Close</Button> */}

                    </Space>
                }

            >
                <div className="card">
                    <div className="d-flex w-100 overflow-auto">
                        <ul className="nav nav-stretch nav-line-tabs fw-bold fs-6 p-0 p-lg-10 flex-nowrap flex-grow-1">
                            <li className="nav-item mx-lg-1">
                                <a className={`nav-link py-3 py-lg-6 text-active-primary ${navtype === "Overview" && "active"}`} onClick={(e) => { setnavtype("Overview"); }}>Overview</a>
                            </li>
                            <li className="nav-item mx-lg-1" role="presentation">
                                <a className={`nav-link py-3 py-lg-6 text-active-primary ${navtype === "Bank" && "active"}`} onClick={(e) => { setnavtype("Bank"); }}>Bank Details</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card">
                    {navtype === "Overview" ?
                        <>
                            {Transaction_log_details != null &&
                                <div className="card-body p-9">
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Transaction Date</label>
                                        <div className="col-lg-8">
                                            <span className="fw-bold fs-6 text-gray-800">{moment(Transaction_log_details.log_date_time).format("ll")}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Transaction By</label>
                                        <div className="col-lg-8 fv-row">
                                            <span className="fw-semibold text-gray-800 fs-6">{Transaction_log_details.user_id}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Account Head</label>
                                        <div className="col-lg-8 fv-row">
                                            <span className="badge badge-light-primary"></span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Account Category</label>
                                        <div className="col-lg-8 fv-row">
                                            <span className="fw-semibold text-gray-800 fs-6"></span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Total Credit</label>
                                        <div className="col-lg-8 fv-row">
                                            {Transaction_log_details.deposit_amnt !== 0 ? <span class="text-success">+{Transaction_log_details.deposit_amnt}</span> : <span>---</span>}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Total Debit</label>
                                        <div className="col-lg-8 fv-row">
                                            {Transaction_log_details.withdrawl_amnt !== 0 ? <span class="text-danger">+{Transaction_log_details.withdrawl_amnt}</span> : <span>---</span>}

                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Operation</label>
                                        <div className="col-lg-8 fv-row">
                                            <span class="badge badge-light-info fs-7 fw-bold">{Transaction_log_details.operation}</span>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label className="col-lg-4 fw-semibold text-muted">Status
                                        </label>
                                        <div className="col-lg-8 d-flex align-items-center">
                                            {Transaction_log_details.log_status === "PENDING_APPROVAL" ?
                                                <span className="badge badge-light-warning">Pending</span>
                                                :
                                                Transaction_log_details.log_status === "DRAFT" ?
                                                    <span className="badge badge-secondary">Draft</span>
                                                    :
                                                    Transaction_log_details.log_status === "DRAFT" ?
                                                        <span className="badge badge-secondary">Draft</span>
                                                        :
                                                        Transaction_log_details.log_status === "cancelled" ?
                                                            <span className="badge badge-danger">Cancelled</span>
                                                            :
                                                            <span className="badge badge-success">{Transaction_log_details.log_status}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </>

                        :
                        navtype === "Bank" &&
                        <>
                            <div className="card-header cursor-pointer py-0" style={{ minHeight: "45px" }}>
                                <div className="card-title m-0">
                                    Bank Details
                                </div>
                            </div>
                            <div className="card-body p-9">
                                <div className="row mb-3">
                                    <label className="col-lg-4 fw-semibold text-muted">account_name</label>
                                    <div className="col-lg-8">
                                        <span className="fw-bold fs-6 text-gray-800">{Transaction_log_details.bank_details[0].account_name}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-lg-4 fw-semibold text-muted">Account Number</label>
                                    <div className="col-lg-8 fv-row">
                                        <span className="fw-semibold text-gray-800 fs-6">{Transaction_log_details.bank_details[0].account_number}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-lg-4 fw-semibold text-muted">Account Code</label>
                                    <div className="col-lg-8 fv-row">
                                        <span className="badge badge-light-primary">{Transaction_log_details.bank_details[0].account_code}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-lg-4 fw-semibold text-muted">Available Balance</label>
                                    <div className="col-lg-8 fv-row">
                                        <span className="fw-semibold text-gray-800 fs-6">{Transaction_log_details.bank_details[0].available_balance}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-lg-4 fw-semibold text-muted">Account Type</label>
                                    <div className="col-lg-8 fv-row">
                                        <span className="fw-semibold text-gray-800 fs-6">{Transaction_log_details.bank_details[0].account_type}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-lg-4 fw-semibold text-muted">Bank Name</label>
                                    <div className="col-lg-8 fv-row">
                                        <span className="fw-semibold text-gray-800 fs-6">{Transaction_log_details.bank_details[0].bank_name}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className="col-lg-4 fw-semibold text-muted">Currency</label>
                                    <div className="col-lg-8 fv-row">
                                        <span className="fw-semibold text-gray-800 fs-6">{Transaction_log_details.bank_details[0].currency.label}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Drawer>
        </>
    );
}
export default BankReport;