import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

import tr_series_data from '../common_component/series_data.js';
import PageError from '../common_component/PageError.js';


function TransactionSeries(props) {

    const [state, setstate] = useState(2);

    const [transactionSeriesList, setTransactionSeriesList] = useState([])

    const [addTseriesModalShow, setAddTseriesModalShow] = useState(false)
    const [seriesName, setSeriesName] = useState("")
    const [seriesData, setSeriesData] = useState(tr_series_data)
    const [tseriesSubmitButtonState, setTseriesSubmitButtonState] = useState(false)

    const [editTseriesModalShow, setEditTseriesModalShow] = useState(false)
    const [seriesId, setSeriesId] = useState("")
    const [seriesNameEdit, setSeriesNameEdit] = useState("")
    const [seriesDataEdit, setSeriesDataEdit] = useState(tr_series_data)
    const [editTseriesSubmitButtonState, setEditTseriesSubmitButtonState] = useState(false)

    var page_name = "transaction-series";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getTransactionSeries() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.data.length > 0) {
            setTransactionSeriesList(body.data)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getTransactionSeries()

            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    const newTseriesSubmit = () => {

        if (seriesName === null) {
            swal("Oops!", "Series Name cannot be empty", "error");
            return
        }

        var object = {};

        object.series_name = seriesName

        let ft = seriesData.map(obj => {
            return { sub_series_name: obj.sub_series_name, prefix_value: obj.prefix_value, start_count: obj.start_count, last_count: obj.last_count }
        })

        object.series_data = ft

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setTseriesSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-transaction-series`, object, headers).then((res) => {
            setTseriesSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Transaction Series added successfully!", "success")

                handleAddTseriesModalClose()
                getTransactionSeries()
            }
            else if (res.data.operation === "duplicate") {
                swal("Oops!", "This Transaction series already exists!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleAddTseriesModalClose = () => {
        setAddTseriesModalShow(false)
        setSeriesName("")
        setSeriesData(tr_series_data)
    }

    const editTseriesInit = (id) => {

        let ts = transactionSeriesList.find(x => x.transaction_series_id === id)

        setEditTseriesModalShow(true)
        setSeriesId(id)
        setSeriesNameEdit(ts.series_name)

        let temp = JSON.parse(ts.series_data)

        temp = [...temp, ...tr_series_data.filter(x => !temp.map(a => a.sub_series_name).includes(x.sub_series_name)).map(x => { return { last_count: x.last_count, start_count: x.start_count, prefix_value: x.prefix_value, sub_series_name: x.sub_series_name } })]

        let t2 = temp.map(obj => {
            return { ...obj, "label": obj.sub_series_name.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }
        })

        setSeriesDataEdit(t2)
    }

    const updateTseriesSubmit = () => {

        if (seriesNameEdit === null) {
            swal("Oops!", "Series Name cannot be empty", "error");
            return
        }

        var object = {};

        object.transaction_series_id = seriesId
        object.series_name = seriesNameEdit

        let ft = seriesDataEdit.map(obj => {
            return { sub_series_name: obj.sub_series_name, prefix_value: obj.prefix_value, start_count: obj.start_count, last_count: obj.last_count }
        })

        object.series_data = ft

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setEditTseriesSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-transaction-series`, object, headers).then((res) => {
            setEditTseriesSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Transaction Series updated successfully!", "success")

                handleEditTseriesModalClose()
                getTransactionSeries()
            }
            else if (res.data.operation === "duplicate") {
                swal("Oops!", res.data.message, "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleEditTseriesModalClose = () => {
        setEditTseriesModalShow(false)
        setSeriesId("")
        setSeriesNameEdit("")
        setSeriesDataEdit(tr_series_data)
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0" >Transaction Series</h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className='btn btn-success rounded' data-kt-indicator={state !== 1 ? "on" : "off"} disabled={state !== 1} onClick={() => { setAddTseriesModalShow(true) }}>
                                <span className="indicator-label">+ New Transaction Series</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className='overflow-auto'>
                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }} >
                                                    <thead>
                                                        <tr className="fw-bold text-muted">
                                                            <th className="min-w-150px">Series Name</th>
                                                            {
                                                                tr_series_data.map(x => { return <th className="min-w-150px">{x.label}</th> })
                                                            }
                                                            <th className="min-w-150px">Associated Branches</th>
                                                            <th className="min-w-50px text-end">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            transactionSeriesList.length > 0 ?
                                                                transactionSeriesList.map((obj) => {
                                                                    let j_data = JSON.parse(obj.series_data)
                                                                    return (
                                                                        <tr key={obj.transaction_series_id}>
                                                                            <td>
                                                                                <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.series_name}</span>
                                                                            </td>
                                                                            {
                                                                                tr_series_data.map((a, i) => {
                                                                                    let t = j_data.find(x => x.sub_series_name === a.sub_series_name)
                                                                                    return (
                                                                                        <td key={i}>
                                                                                            <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{!t ? "not set" : t.prefix_value + "-" + t.last_count}</span>
                                                                                        </td>
                                                                                    )
                                                                                })
                                                                            }
                                                                            <td>
                                                                                <span className="badge badge-light-info">{obj.associated_branches}</span>
                                                                            </td>
                                                                            <td className="text-end">
                                                                                <div className="dropdown" style={{ position: "unset" }}>
                                                                                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i className="fas fa-bars px-0"></i>
                                                                                    </button>
                                                                                    {
                                                                                        write &&
                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                            <li className="cursor-pointer dropdown-item" onClick={() => { editTseriesInit(obj.transaction_series_id) }}><span>Configure</span></li>
                                                                                        </ul>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                <tr>
                                                                    <td colSpan={tr_series_data.length + 3} className="text-center">
                                                                        <h3 className="text-muted pt-6">No data to be shown</h3>
                                                                    </td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <PageError />
                        }
                    </div>
                </div>

            </div>

            <Modal show={addTseriesModalShow} onHide={() => { handleAddTseriesModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add Transaction Series</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddTseriesModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Transaction Series Name</label>
                                <input type="text" className="form-control form-control-solid" value={seriesName} onChange={(e) => { setSeriesName(e.target.value) }} placeholder="Series Name" />
                                <span className="form-text text-muted">Please enter Series name.</span>
                            </div>
                        </div>

                        {
                            seriesData.map((obj, i) => {
                                return (
                                    <div key={i} className="row mb-5">
                                        <div className='col-sm-2 my-auto'><label className="fs-5 fw-bold mb-2">{obj.label}</label></div>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control form-control-solid"
                                                value={obj.prefix_value}
                                                onChange={(e) => {
                                                    if (!(/^[A-Za-z]+$/).test(e.target.value)) return

                                                    let t = seriesData.map(x => { return { ...x } })
                                                    t.find(a => a.sub_series_name === obj.sub_series_name).prefix_value = e.target.value
                                                    setSeriesData(t)
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control form-control-solid"
                                                value={obj.start_count}
                                                onChange={(e) => {
                                                    if (isNaN(e.target.value)) return

                                                    let t = seriesData.map(x => { return { ...x } })
                                                    t.find(a => a.sub_series_name === obj.sub_series_name).start_count = e.target.value.trim()
                                                    setSeriesData(t)
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddTseriesModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={tseriesSubmitButtonState ? "on" : "off"} disabled={tseriesSubmitButtonState} onClick={() => { newTseriesSubmit() }}>
                            <span className="indicator-label">+ Add</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={editTseriesModalShow} onHide={() => { handleEditTseriesModalClose() }} size={"lg"} centered>
                <Modal.Header>
                    <Modal.Title>Edit Series</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleEditTseriesModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Transaction Series Name</label>
                                <input type="text" className="form-control form-control-solid" value={seriesNameEdit} onChange={(e) => { setSeriesNameEdit(e.target.value) }} placeholder="Series Name" />
                                <span className="form-text text-muted">Please enter Series name.</span>
                            </div>
                        </div>

                        {
                            seriesDataEdit.map((obj, i) => {
                                return (
                                    <div key={i} className="row mb-5">
                                        <div className='col-sm-2 my-auto'><label className="fs-5 fw-bold mb-2">{obj.label}</label></div>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control form-control-solid"
                                                value={obj.prefix_value}
                                                onChange={(e) => {
                                                    if (!(/^[A-Za-z]+$/).test(e.target.value)) return

                                                    let t = seriesDataEdit.map(x => { return { ...x } })
                                                    t.find(a => a.sub_series_name === obj.sub_series_name).prefix_value = e.target.value
                                                    setSeriesDataEdit(t)
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control form-control-solid"
                                                value={obj.start_count}
                                                onChange={(e) => {
                                                    if (isNaN(e.target.value)) return

                                                    let t = seriesDataEdit.map(x => { return { ...x } })
                                                    t.find(a => a.sub_series_name === obj.sub_series_name).start_count = e.target.value.trim()
                                                    setSeriesDataEdit(t)
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleEditTseriesModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={editTseriesSubmitButtonState ? "on" : "off"} disabled={editTseriesSubmitButtonState} onClick={() => { updateTseriesSubmit() }}>
                            <span className="indicator-label">Save Changes</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default TransactionSeries;