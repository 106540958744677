import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment';
import Select from 'react-select';
import { Pagination } from 'antd';
import { Modal } from "react-bootstrap";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars/index.js";
import './date-range.css'

import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import approveButtonShow from '../common_component/listPageApprovalButtonShow.js';
import StatusBadge from "../common_component/StatusBadge.js";
import PageError from '../common_component/PageError.js';
import TableColumnHead from "../common_component/TableColumnHead.js";
import formatNumber from "../common_component/formatNumber.js";
import flow_img from '../flow_images/Customer_order_flow.jpg';

const statusTypeArray = ["PURCHASE_ORDER_FINALIZED", "PURCHASE_ORDER_PENDING_APPROVAL", "PURCHASE_ORDER_CANCELLED", "PURCHASE_ORDER_DRAFT", "PURCHASE_ORDER_APPROVAL_MAIL_SENT", "PURCHASE_ORDER_CONFIRMED"]

const PurchaseOrderList = () => {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    const history = useHistory()

    const daterange_picker_ref = useRef()
    const [statusType, setStatusType] = useState({ label: "All", value: "All" });

    const [purchaseOrdersList, setPurchaseOrdersList] = useState([])
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [purchaseOrdersListApiState, setPurchaseOrdersListApiState] = useState(false)

    const [branchesList, setBranchesList] = useState([])

    const [flowModalShow, setFlowModalShow] = useState(false)

    var page_name = "purchase-order-list";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getBasicPurchaseOrdersList(signal, cp, rpp, sort_c, sort_v, search_v, sd, ed, st) {
        setPurchaseOrdersListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-basic-purchase-orders-list`, { signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "start_date": sd, "end_date": ed, "status_type": st, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setPurchaseOrdersList(body.data.list);
            setListCount(body.data.count)

            setPurchaseOrdersListApiState(false)
        }
        else {
            throw "basic purchase orders list api error"
        }
    }

    async function getBranches() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)
        if (body.operation == "success") {
            setBranchesList(body.data)
        }
    }

    const skipApprovalMailHandle = (purchase_order_id) => {
        let object = {};

        object.purchase_order_id = purchase_order_id

        object.organization_id = organization_id
        object.page_name = page_name;

        swal({
            title: "Are you sure?", text: "Approval mail will be skipped",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((confirm) => {
                if (confirm) {
                    var headers = {
                        responseType: 'arraybuffer',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "*/*",
                            "token": getCookie("access_token"),
                        }
                    }

                    axios.post(`${process.env.REACT_APP_SERVER_URL}/skip-po-approval-email`, object, headers).then((res) => {
                        if (res.data.operation === "danger") {
                            swal("Oops!", "Something went wrong!", "error");
                        }
                        else {
                            console.log(res)
                            swal("Great!", "Continue to Receive Goods", "success").then(() => { history.push("/create-goods-receive"); })
                        }
                    })
                        .catch(function (error) {
                            console.log(error)
                        });
                }
            });
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            const controller = new AbortController();
            const signal = controller.signal;

            let p1 = getBasicPurchaseOrdersList(signal, currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate, statusType.value);
            let p2 = getBranches();

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    if (errx.name !== 'AbortError') {
                        setstate(3)
                        console.log(errx)
                    }
                })

            return () => {
                controller.abort();
            };
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            const controller = new AbortController();
            const signal = controller.signal;

            getBasicPurchaseOrdersList(signal, currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate, statusType.value)

            return () => {
                controller.abort();
            };
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue, startDate, endDate, statusType.value])

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Purchase Orders</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        {/* <button className="btn p-0 mx-3 btn-hover-scale">{true ? <i className="bi bi-pin-fill fs-1 px-0"></i> : <i className="bi bi-pin fs-1 px-0"></i>}</button> */}
                        <Link to={`/create-purchase-order`} className="btn btn-sm btn-primary me-3 rounded btn-hover-scale">
                            <span className="d-md-none">
                                <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                    <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff"></path>
                                </svg>
                            </span>
                            <span className="d-none d-md-inline">Create Purchase Order</span>
                        </Link>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="row gx-0 gap-5 m-2">
                                    <div className='col-md-3'>
                                        <DateRangePickerComponent
                                            ref={daterange_picker_ref}
                                            allowEdit={false}
                                            showClearButton={false}
                                            focus={() => { daterange_picker_ref.current.show() }}
                                            delayUpdate={true}
                                            presets={[
                                                { label: 'Today', start: new Date(), end: new Date() },
                                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                                            ]}
                                            change={(e) => {
                                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                                            }}
                                            startDate={new Date(startDate)}
                                            endDate={new Date(endDate)}
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <Select
                                            options={[{ label: "All", value: "All" }, ...statusTypeArray.map(x => { return { label: x.replace("PURCHASE_ORDER", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()), value: x } })]}
                                            onChange={(event) => { setStatusType(event); }}
                                            value={statusType}
                                            placeholder="Select Type..."
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                        />
                                    </div>
                                </div>

                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className='mb-5'>
                                            <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                        </div>

                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                purchaseOrdersListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    purchaseOrdersList.length > 0 && branchesList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    showQuickJumper={true}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                />
                                                            </div>

                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'purchase_order_number'} label={'Purchase Order Number'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px">Branch</th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'reference_number'} label={'Reference Number'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'user_type'} label={'User Type'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'doc_user_name'} label={'User Name'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'grand_total'} label={'Grand Total'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'order_date'} label={'Order Date'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'due_date'} label={'Due Date'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-150px"><TableColumnHead columnKey={'add_status'} label={'Status'} {...{ sortColumn, setSortColumn, sortValue, setSortValue }} /></th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            purchaseOrdersList.map((obj) => {
                                                                                return (
                                                                                    <tr key={obj.purchase_order_id}>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.purchase_order_number}</span></td>
                                                                                        <td><span className="badge badge-secondary">{branchesList.find(x => x.branch_id == obj.branch_id).branch_name}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.reference_number}</span></td>
                                                                                        <td><span className="badge badge-light-info">{obj.user_type}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.doc_user_name}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.order_currency.currency_symbol}&nbsp;{formatNumber(obj.grand_total, { ...formatNumberOptions, currencyFormat: obj.order_currency.format, decimalPlaces: obj.order_currency.decimal_place })}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.order_date).format("ll")}</span></td>
                                                                                        <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{moment(obj.due_date).format("ll")}</span></td>
                                                                                        <td><StatusBadge status={obj.add_status} entity={"PURCHASE_ORDER"} /></td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                    <i className="fas fa-bars px-0"></i>
                                                                                                </button>
                                                                                                {
                                                                                                    write &&
                                                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                        <li className="py-1 dropdown-item px-0"><Link className="text-black d-block px-4" to={`/view-purchase-order/${obj.purchase_order_id}`}>{approveButtonShow("PURCHASE_ORDER_PENDING_APPROVAL", user_role, user.email, obj) ? "View/Approve" : "View"}</Link></li>
                                                                                                        {(obj.add_status == "PURCHASE_ORDER_DRAFT" || obj.add_status == "PURCHASE_ORDER_PENDING_APPROVAL" || obj.add_status == "PURCHASE_ORDER_FINALIZED") && <li className="py-1 dropdown-item px-0"><Link className="text-black d-block px-4" to={`/edit-purchase-order/${obj.purchase_order_id}`}>Edit Order Details</Link></li>}
                                                                                                        {(obj.add_status == "PURCHASE_ORDER_FINALIZED" && obj.sales_manager.length > 0) && <li className="py-1 dropdown-item px-0"><Link className="text-black d-block px-4" to={`/purchase-order-approval-email/${obj.purchase_order_id}`}>Send Email to Sales Managers</Link></li>}
                                                                                                        {(obj.add_status == "PURCHASE_ORDER_FINALIZED" || obj.add_status == "PURCHASE_ORDER_APPROVAL_MAIL_SENT") && <li className="py-1 dropdown-item px-0" onClick={(e) => { skipApprovalMailHandle(obj.purchase_order_id) }}><div className="text-black d-block px-4">{obj.sales_manager.length > 0 ? "SKIP" : "CONFIRM"} to Recieve Goods</div></li>}
                                                                                                    </ul>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError>
                                    <Link className='btn btn-success rounded' to={`/create-purchase-order`}>Create a Purchase Order</Link>
                                </PageError>
                    }

                </div>
            </div>

            <Modal show={flowModalShow} onHide={() => { setFlowModalShow(false) }} dialogClassName="h-modal-80">
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-gray-500'>Order Flow Cycle</span>
                    </Modal.Title>

                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setFlowModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <img src={flow_img} className='w-100' alt="Not yet provided" />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setFlowModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PurchaseOrderList;
