import React, { lazy, Suspense, useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

function FirstStepForgotPassword() {
	const [email, setEmail] = useState("")
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

	const register = (e) => {

		e.preventDefault();
		var object = {};
		object.email = email;

		var exp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (object.email == "") {
			swal("Warning!", "Please provide a email", "warning");
			return false;
		}
		else if (!object.email.match(exp)) {
			swal("Warning!", "Please provide a valid email", "warning");
			return false;
		}

		setMainSubmitButtonState(true);
		axios.post(`${process.env.REACT_APP_SERVER_URL}/check-user`, object).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data.operation == "success") {
				swal("", "A link has been sent to your email id.\n Please check your email.", "success").then((res) => {
					window.location.reload();
				});
			}
			else {
				if (res.data.message != "danger") {
					swal("Oops!", res.data.message, "error").then((res) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "This email is not registered us", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
	}

	return (
		<>
			<div className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat" style={{ "background-size": "100% 100%", "background-image": "url(assets/media/auth/bg4.jpg)" }}>
				<div className="d-flex flex-column flex-root" style={{ maxHeight: "100vh", height: "100vh" }}>
					<div className="d-flex flex-column flex-column-fluid flex-lg-row">
						<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
							<div className="d-flex flex-center flex-lg-start flex-column mb-7">
								{/* <a href="index.html" className="mb-7"> */}
								<img alt="Logo" src={`${process.env.REACT_APP_LOGO}`} />
								{/* </a> */}
								<h2 className="text-white fw-normal m-0">Ultimate Business Management Solution!</h2>
							</div>
						</div>
						<div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
							<div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
								<div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
									<form className="form w-100">
										<div className="text-center mb-10">
											<h1 className="text-gray-900 fw-bolder mb-3">Forgot Password ?</h1>
											<div className="text-gray-500 fw-semibold fs-6">Enter your email to reset your password.</div>
										</div>
										<div className="fv-row mb-8">
											<input type="text" placeholder="Email" name="email"  value={email} onChange={(e) => { setEmail(e.target.value); }} autocomplete="off" className="form-control bg-transparent" />
										</div>
										<div className="d-flex flex-wrap justify-content-center pb-lg-0">
											<button type="button" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={(e) => { register(e) }} className="btn btn-primary me-4">
												<span className="indicator-label">Submit</span>
												<span className="indicator-progress">Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
											<a href="/login" className="btn btn-light">Cancel</a>
										</div>
									</form>
								</div>
								<div className="d-flex flex-stack px-lg-10">
									<div className="d-flex fw-semibold text-primary fs-base gap-5">
										<a href="https://www.adoerp.com/terms" target="_blank">Terms</a>
										<a href="https://www.adoerp.com/pricing-plans" target="_blank">Plans</a>
										<a href="https://www.adoerp.com/contact" target="_blank">Contact Us</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
}
export default FirstStepForgotPassword;