import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';
import { Modal } from "react-bootstrap";

// import { useTheme } from "./theme-provider.js"
import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import formatNumber from '../common_component/formatNumber.js';
import { ReportWidgetWrapper, ReportWidgetItem } from '../common_component/ReportWidgets.js';

function ItemSalesReport() {
    const [state, setstate] = useState(2);
    const [baseCurrency, setBaseCurrency] = useState(null)
    const apiController = useRef(new AbortController())

    const daterange_picker_ref = useRef()
    const [itemSalesList, setItemSalesList] = useState([])
    const [itemSalesReportListApiState, setItemSalesReportListApiState] = useState(false)

    // const { theme, dropdownStyleDark } = useTheme()

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(100)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(30, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))

    const [detailsModalShow, setDetailsModalShow] = useState(false)
    const [itemsData, setItemsData] = useState([])
    const [itemsTitle, setItemsTitle] = useState([])

    var page_name = "item-sales-report";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getItemSalesReport(cp, rpp, search_v, sd, ed) {
        try {
            if (itemSalesReportListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setItemSalesReportListApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-item-sales-report`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation !== "success") throw "get Item Sales Report api error"

            setItemSalesList(body.data.list)
            setListCount(body.data.count)

            setItemSalesReportListApiState(false)
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBaseCurrency() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        // console.log(body)
        if (body.length > 0) {
            setBaseCurrency(body[0])
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getItemSalesReport(currentPage, rowsPerPage, searchValue, startDate, endDate)
            let p2 = getBaseCurrency()

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getItemSalesReport(currentPage, rowsPerPage, searchValue, startDate, endDate)
        }
    }, [currentPage, rowsPerPage, searchValue, startDate, endDate])

    // const trimDecimalPlaces = (amt, decimal_place) => {
    //     return Math.round(amt * Math.pow(10, decimal_place)) / Math.pow(10, decimal_place)
    // }

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Item Sales Report</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <DateRangePickerComponent
                            ref={daterange_picker_ref}
                            allowEdit={false}
                            showClearButton={false}
                            focus={() => { daterange_picker_ref.current.show() }}
                            delayUpdate={true}
                            presets={[
                                { label: 'Today', start: new Date(), end: new Date() },
                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                            ]}
                            change={(e) => {
                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                            }}
                            startDate={new Date(startDate)}
                            endDate={new Date(endDate)}
                            max={new Date().toISOString().split('T')[0]}
                        />
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0">
                                    <ReportWidgetWrapper>
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-box-seam display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Total Items."}
                                            value={formatNumber(listCount, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-file-earmark-text-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Total Related Orders"}
                                            value={formatNumber(itemSalesList.reduce((p, o) => p + o.sales_order_quantity, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-file-earmark-text-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Total Related Invoices"}
                                            value={formatNumber(itemSalesList.reduce((p, o) => p + o.sales_invoice_quantity, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                    </ReportWidgetWrapper>
                                </div>
                                <div className="card-body py-3">
                                    <div className='mb-5'>
                                        <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                    </div>

                                    <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                        {
                                            itemSalesReportListApiState ?
                                                <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                :
                                                itemSalesList.length > 0 ?
                                                    <>
                                                        <div className="mb-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>

                                                        <div className='overflow-auto w-100 flex-grow-1'>
                                                            <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                <thead>
                                                                    <tr className="fw-bold text-muted">
                                                                        <th className="min-w-120px">Item Name</th>
                                                                        <th className="w-200px">Item Stock Type</th>
                                                                        <th className="min-w-120px">Order Count</th>
                                                                        <th className="min-w-120px">Qty. in Order</th>
                                                                        <th className="min-w-120px">Invoice Count</th>
                                                                        <th className="min-w-120px">Qty. in Invoice</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        itemSalesList.map((obj, indx3) => {
                                                                            return (
                                                                                <tr key={obj._id}>
                                                                                    <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.item_name}</span></td>
                                                                                    <td><span className="badge fs-6" style={{ backgroundColor: "cornflowerblue" }}>{obj.item_stock_type === null ? "--" : obj.item_stock_type === "None" ? "Quantity" : obj.item_stock_type === "Track Serial Number" ? "Serial Type" : obj.item_stock_type === "Track Batches" ? "Batch Type" : "NA"}</span></td>
                                                                                    <td><span className="text-dark fw-bold mb-1 fs-6 gap-2 d-flex align-items-center"><span>{obj.sales_order_list.length}</span> {obj.sales_order_list.length > 0 && <i className="fa-solid fa-eye fs-2 p-0 text-hover-primary cursor-pointer" onClick={() => { setDetailsModalShow(true); setItemsTitle(["Order Number", "Order Quantity"]); setItemsData(obj.sales_order_list.map(x => ({ entity_number: x.customer_order_number, entity_qty: x.order_included_quantity }))) }}> </i>} </span> </td>
                                                                                    <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.sales_order_quantity}</span></td>
                                                                                    <td><span className="text-dark fw-bold mb-1 fs-6 gap-2 d-flex align-items-center"><span>{obj.sales_invoice_list.length}</span> {obj.sales_invoice_list.length > 0 && <i className="fa-solid fa-eye fs-2 p-0 text-hover-primary cursor-pointer" onClick={() => { setDetailsModalShow(true); setItemsTitle(["Invoice Number", "Invoice Quantity"]); setItemsData(obj.sales_invoice_list.map(x => ({ entity_number: x.customer_invoice_number, entity_qty: x.invoice_included_quantity }))) }}> </i>} </span> </td>
                                                                                    <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.sales_invoice_quantity}</span></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="mt-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>

            <Modal show={detailsModalShow} onHide={() => { setDetailsModalShow(false); setItemsData([]); }} centered>
                <Modal.Header>
                    <Modal.Title>Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDetailsModalShow(false); setItemsData([]); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <table className="table table-bordered table-striped">
                            <thead className="thead-dark">
                                <tr>
                                    {
                                        itemsTitle.map((x, i) => {
                                            return (
                                                <th key={i} scope="col">{x}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    itemsData.map((obj, indx) => (
                                        <tr key={indx}>
                                            <td className='d-flex align-items-center'>
                                                <span className="bullet bg-primary mx-2"></span>
                                                <span className='fw-bold'>{obj.entity_number}</span>
                                            </td>
                                            <td className='fs-5'>{obj.entity_qty}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ItemSalesReport;
