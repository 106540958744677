import React, { lazy, Suspense, useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import country_arr from './common_arr/countries-arr.js';
import s_a from './states-arr.js';
import currency_arr from './common_arr/currency-arr.js';
import Select, { components } from 'react-select';
import { Flex, Progress } from 'antd';
import moment from 'moment';
import { setCookie, getCookie } from '../common_component/cookie.js';
import { Link, useHistory } from 'react-router-dom';
import business_type_arr from './common_arr/business-type-arr.js';

function QuickSetUp(props) {

	const [user, setUser] = useState({});
	const [RedirectState, setRedirectState] = useState(2);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

	const [OrganizationName, setOrganizationName] = useState("");
	const [OrganizationId, setOrganizationId] = useState("");
	const [CurrencyCode, setCurrencyCode] = useState("INR");
	const [CurrencySymbol, setCurrencySymbol] = useState("Rs.");
	const [Currencylist, setCurrencylist] = useState([])
	const [Industrylist, setIndustrylist] = useState([{ label: "Agriculture", value: "Agriculture" }, { label: "Construction", value: "Construction" }, { label: "Education", value: "Education" }, { label: "Engineering", value: "Engineering" }, { label: "Entertainment", value: "Entertainment" }, { label: "Others", value: "Others" }])
	const [timezone, settimezone] = useState("New Delhi || +05:30");
	const [Industry, setIndustry] = useState("");
	const [organization_domain, setorganization_domain] = useState("");

	const [OrganizationDetails, setOrganizationDetails] = useState([]);
	const [PlanDetails, setPlanDetails] = useState([]);
	const [progress, setProgress] = useState(0);
	const [businessTypeList, setBusinessTypeList] = useState([]);
	const [businessType, setBusinessType] = useState(null);

	const [gstTreatmentlist, setgstTreatmentlist] = useState([{ label: "Registered Business-Regular", value: "Registered Business-Regular", description: "A business that is registered under GST" }, { label: "Unregistered Business", value: "Unregistered Business", description: "Business that has not been registered under GST" }])
	const [gstTreatment, setGstTreatment] = useState({ label: "Registered Business-Regular", value: "Registered Business-Regular", description: "A business that is registered under GST" })
	const [LegalName, setLegalName] = useState("");
	const [TradeName, setTradeName] = useState("");
	const [GstIN, setGstIN] = useState("");

	const [gstinSearchDetails, setGstinSearchDetails] = useState(null)
	const [gstinSearchDetailsApiState, setGstinSearchDetailsApiState] = useState(false)

	const [billingCity, setBillingCity] = useState("");
	const [billingPincode, setBillingPincode] = useState("");
	const [billingAddress1, setBillingAddress1] = useState("");
	const [billingCountryOptions, setBillingCountryOptions] = useState([]);
	const [billingStateOptions, setBillingStateOptions] = useState([]);
	const [billingSelectedCountry, setBillingSelectedCountry] = useState("India");
	const [billingSelectedState, setBillingSelectedState] = useState(null);
	const [SetupOrgDetails, setSetupOrgDetails] = useState([]);

	const [phoneNumber, setPhoneNumber] = useState("");

	const [navtype, setnavtype] = useState("Org Details");
	const [quick_set_flag, setquick_set_flag] = useState(2);
	const [content_flag, setcontent_flag] = useState("");
	const gstinRegex = /^[0-9A-Za-z]{15}$/;

	const [value, setValue] = useState();
	const history = useHistory()
	const [apiInProgress, setApiInProgress] = useState(false);

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	let dropdownStyleDark = {
		option: (provided, state) => ({
			...provided,
			color: '#dddddf',
			backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: "#2b2b40",
			borderColor: "#4c4c71"
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: "#dddddf",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#dddddf",
		}),
		menu: (provided, state) => ({
			...provided,
			backgroundColor: "#2b2b40",
		})
	}

	useEffect(() => {
		let id = setInterval(() => {
			setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
		}, 10)

		return () => {
			clearInterval(id)
		}
	}, [])

	useEffect(() => {
		if (getCookie("access_token") != "") {
			var object = {};
			object.token = getCookie("access_token");

			var headers = {
				headers: {
					"Content-Type": "application/json",
					"Accept": "*/*"
				}
			}
			axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

				if (res.data.operation == "success") {
					var user_object = {};
					//console.log(res.data)
					user_object.phone = res.data.phone;
					user_object.email = res.data.email;
					user_object.name = res.data.name;
					user_object.img = res.data.img;
					setUser(user_object)
				}
				else {
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
						window.location.href = "/login"
					});
				}
			})
				.catch(function (error) {
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
						window.location.href = "/login"
					});
				});
		}
	}, [])

	useEffect(() => {
		const business_type = business_type_arr.business_type_arr;
		let temp_category = [];
		business_type.map((main_obj) => {
			var temp_obj = {};
			temp_obj.label = main_obj.category;
			temp_obj.options = main_obj.subcategories.map((x) => { return { label: x, value: x } });
			temp_category.push(temp_obj);
		})
		setBusinessTypeList(temp_category);
		setBusinessType({label: "Suppliers", value: "Suppliers"});
	}, []);

	useEffect(() => {
		var obj_array = [];
		var jsonParsedArray = currency_arr.currency_arr[0]
		for (let key in jsonParsedArray) {
			var obj2 = {};
			if (jsonParsedArray.hasOwnProperty(key)) {
				obj2.label = jsonParsedArray[key].currency_name;
				obj2.value = key;
				obj2.symbol = jsonParsedArray[key].currency_symbol;
				obj_array.push(obj2);
			}
		}
		setCurrencylist(obj_array);
	}, []);

	useEffect(() => {
		let obj_array = []
		country_arr.country_arr.forEach((obj, index) => {
			if (index != 0) {
				let obj2 = {};
				obj2.label = obj;
				obj2.value = obj;

				obj_array.push(obj2);
			}
		})
		setBillingCountryOptions(obj_array);

	}, []);

	useEffect(() => {
		if (billingCountryOptions.length > 0) {
			//setBillingSelectedState("West Bengal");
			loadBillingStateOptions({ label: "India", value: "India" });
		}

	}, [billingCountryOptions]);


	useEffect(() => {
		if (getCookie("access_token") == "" && getCookie("organization_id") == "") {
			window.location.href = "/login";
		}
		if (props.match.params.id !== undefined) {
			user.email && getOrganizationSetupinformation(props.match.params.id);
		}
		else {
			user.email && getSetupinformation();
		}

	}, [user])

	async function getOrganizationSetupinformation(org_id) {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-organization-set-up-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": org_id, "flag": "basic" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success") {
			if (body.content_arr.length > 0) {
				setcontent_flag(1);
				setquick_set_flag(1);
				body.content_arr.forEach((obj, index) => {
					if (index == 0) {
						obj.check = true;
						dyanamic_org(obj.organization_id, body.content_arr);
					}
					else {
						obj.check = false;
					}
				})
				dyanamic_org(body.content_arr[0].organization_id, body.content_arr);
				setOrganizationDetails(body.content_arr);
			}
			else {
				///
			}
		}
	}

	async function getSetupinformation() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-set-up-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "service": "account" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success") {
			setcontent_flag(body.content_flag);
			setquick_set_flag(body.quick_set_up);
			if (body.content_flag === 1) {
				body.content_arr.forEach((obj, index) => {
					if (index == 0) {
						obj.check = true;
						dyanamic_org(obj.organization_id, body.content_arr);
					}
					else {
						obj.check = false;
					}
				})
				setOrganizationDetails(body.content_arr);
			}
			else {
				if (body.set_up_module_org.length > 0) {
					Skip_now(body.set_up_module_org[0])
				}
			}
		}
	}

	function loadBillingStateOptions(country) {
		let selectedCountryIndex = 0;
		billingCountryOptions.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})

		let state_arr = s_a.s_a[selectedCountryIndex + 1].split("|");
		let obj_array = [];
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		setBillingStateOptions(obj_array);
	}

	const Save_set_up_information = (e) => {

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		const tax_details = gstTreatment.value === "Registered Business-Regular" ? { GstIn: GstIN, legal_name: LegalName, trade_name: TradeName } : null;
		if (content_flag === 0 && props.match.params.id === undefined) {
			setMainSubmitButtonState(true)
			var values = { "OrganizationName": OrganizationName, "flag": "quick_set_up", "organization_id": props.match.params.id !== undefined ? props.match.params.id : "", "props_flag": props.match.params.id !== undefined ? true : false, "organizationDomain": organization_domain, "country": billingSelectedCountry, "business_type": businessType.value, "tax_details": tax_details, "state": billingSelectedState, "timezone": timezone, "CurrencyCode": CurrencyCode, "CurrencySymbol": CurrencySymbol, "Industry": Industry, "Phone": phoneNumber, "city": billingCity, "pincode": billingPincode, "address_line1": billingAddress1, "Name": user.name, "email": user.email };
			axios.post(`${process.env.REACT_APP_SERVER_URL}/save-set-up-information/${process.env.REACT_APP_QUICK_SET_UP_ID}`, values, headers).then((res) => {

				if (res.data && res.data.operation == "success") {
					//setMainSubmitButtonState(false)
					swal("Great!", "Organization details saved successfully!", "success").then((value) => {
						//console.log(res.data.organization_id)
						setCookie('organization_id', res.data.organization_id, 1, true);
						window.location.href = "/"
					});
				}
				else {
					setMainSubmitButtonState(false);
					check_err(res.data.message);
					//swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else {
			setMainSubmitButtonState(true)
			var values = { "email": user.email, "organization_id": OrganizationId };
			axios.post(`${process.env.REACT_APP_SERVER_URL}/save-existing-set-up-information`, values, headers).then((res) => {

				if (res.data && res.data.operation == "success") {
					//setMainSubmitButtonState(false);
					setApiInProgress(true);
					swal("Great!", "Organization details saved successfully!", "success").then((value) => {
						setCookie('organization_id', OrganizationId, 1, true);
						window.location.href = "/"
					});
				}
				else {
					setMainSubmitButtonState(false);
					check_err(res.data.message);
					//swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
	}

	const check_err = (err_key) => {
		if (err_key == "domain_exist") {
			swal("Warning!", "Same domain already exist.", "warning");
			return false;
		}
		if (err_key == "missing_org_name") {
			swal("Warning!", "Please provide a Organization name", "warning");
			return false;
		}
		if (err_key == "missing_org_domain") {
			swal("Warning!", "Please provide a Organization domain name", "warning");
			return false;
		}
		if (err_key == "missing_timezone") {
			swal("Warning!", "Please select a Timezone", "warning");
			return false;
		}
		if (err_key == "missing_phone") {
			swal("Warning!", "Phone cannot be empty", "warning");
			return false;
		}

		if (err_key == "missing_currency") {
			swal("Warning!", "Please select a Currency", "warning");
			return false;
		}

		if (err_key == "missing_city") {
			swal("Warning!", "City should be Non-empty and Valid.", "warning");
			return false;
		}

		if (err_key == "missing_country") {
			swal("Warning!", "Country should be Non-empty and Valid.", "warning");
			return false;
		}

		if (err_key == "missing_state") {
			swal("Warning!", "State should be Non-empty and Valid.", "warning");
			return false;
		}
		if (err_key == "missing_pincode") {
			swal("Warning!", "Pincode should be Non-empty and Valid.", "warning");
			return false;
		}

		if (err_key === "missing_gstin") {
			swal("Warning!", "GSTIN cannot be empty", "warning");
			return false;
		}
		if (err_key === "missing_legal_name") {
			swal("Warning!", "Business Legal Name cannot be empty", "warning");
			return false;
		}
		return true;
	}

	const check_validation = (part) => {
		if (part == "Org Details") {
			if (OrganizationName == "") {
				swal("Warning!", "Please provide a Organization name", "warning");
				return false;
			}
			if (organization_domain == "") {
				swal("Warning!", "Please provide a Organization domain name", "warning");
				return false;
			}
			if (timezone == "") {
				swal("Warning!", "Please select a Timezone", "warning");
				return false;
			}
			if (businessType == null) {
				swal("Warning!", "Please select a Business Type", "warning");
				return false;
			}

			if (phoneNumber == "") {
				swal("Warning!", "Phone cannot be empty", "warning");
				return false;
			}
			let regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/
			if (!regex1.test(phoneNumber)) {
				swal("Warning!", "Please provide valid Phone number", "warning");
				return false;
			}
			if (CurrencyCode == "") {
				swal("Warning!", "Please select a Currency", "warning");
				return false;
			}
		}
		if (part == "Address Details") {
			let pin_regex = /^\d{5,6}$/
			let billing_arr = [];

			if (billingCity.trim() === "") {
				billing_arr.push("City");
			}
			if (billingSelectedCountry === null) {
				billing_arr.push("Country");
			}
			if (billingSelectedState === null) {
				billing_arr.push("State");
			}
			if (billingPincode === "" || !pin_regex.test(billingPincode)) {
				billing_arr.push("PinCode");
			}
			if (billing_arr.length > 0) {
				swal("Warning!", billing_arr.toString() + " should be Non-empty and Valid.", "warning");
				return false;
			}
		}
		if (part == "GSTIN Details") {
			let tax_details = null;
			if (gstTreatment.value === "Registered Business-Regular") {
				if (GstIN === "") {
					swal("Warning!", "GSTIN cannot be empty", "warning");
					return false;
				}
				if (LegalName === "") {
					swal("Warning!", "Business Legal Name cannot be empty", "warning");
					return false;
				}
				tax_details = { GstIn: GstIN, legal_name: LegalName, trade_name: TradeName }
			}
		}
		return true;
	}

	const dyanamic_org = (org_id, Org_details_arr) => {
		let arr = Org_details_arr.filter(option => option.organization_id == org_id)[0];
		setOrganizationName(arr.organization_name);
		settimezone(arr.timezone + "(" + arr.timezone_value + ")");
		setPhoneNumber(arr.phone);
		setBillingCity(arr.city);
		setBillingPincode(arr.pincode);
		setBillingSelectedCountry(arr.country);
		setBillingSelectedState(arr.state);
		setBillingAddress1(arr.address_line1);
		setLegalName(arr.legal_name);
		setTradeName(arr.trade_name);
		setGstIN(arr.GstIn);
		setIndustry(arr.industry);
		setCurrencyCode(arr.currency_code);
		setOrganizationId(org_id);
		setorganization_domain(arr.organization_domain);
	}

	const reset_org = () => {
		setOrganizationName("");
		settimezone("");
		setPhoneNumber("");
		setBillingCity("");
		setBillingPincode("");
		setBillingSelectedCountry("India");
		setBillingSelectedState("West Bengal");
		setBillingAddress1("");
		setLegalName("");
		setTradeName("");
		setGstIN("");
		setIndustry("");
		setCurrencyCode("INR");
		setorganization_domain("");
	}

	const ScrollToTop = () => {
		$('html').scrollTop(0);
	}

	const Skip_now = (organization_id) => {
		setCookie("organization_id", "", -1, true);
		setCookie('organization_id', organization_id, 1, true);
		if (getCookie("lastpage") != "") {
			window.location.href = getCookie("lastpage");
		}
		else {
			window.location.href = "/dashboard"
		}
	}

	async function getGstinSearchDetails(gstin) {
		setGstinSearchDetailsApiState(true)
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-gstin-search-details-without-pagepermission`, { method: 'POST', body: JSON.stringify({ gstin: gstin, "email": user.email, "page_name": "", "organization_id": "" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.operation == "success") {
			setGstinSearchDetails(body.info[0])
			setLegalName(body.info[0].lgnm)
			setTradeName(body.info[0].tradeNam)
			//setPlaceSupply({ label: body.info[0].pradr.addr.stcd, value: body.info[0].pradr.addr.stcd })
		}
		else {
			swal("Oops!", body.message, "error");
		}
		setGstinSearchDetailsApiState(false)
	}

	useEffect(() => {
		if (mainSubmitButtonState) {
			const totalInitialTime = 7500 * 0.9; // Calculate 90% of initial time
			const startTime = new Date();

			const intervalId = setInterval(() => {
				const currentTime = new Date();
				const elapsedTime = currentTime - startTime;
				const progressValue = Math.min(Math.floor((elapsedTime / totalInitialTime) * 100), 90);
				setProgress(progressValue);
				if (apiInProgress) {
					setProgress(100);
				}
				if (elapsedTime >= totalInitialTime) {
					clearInterval(intervalId);
				}

			}, 100);
			return () => clearInterval(intervalId);
		}
	}, [mainSubmitButtonState, apiInProgress]);

	return (
		<>
			{
				(RedirectState == 1) ? ((getCookie("lastpage") != "") ? window.location.href = getCookie("lastpage") : window.location.href = "/"
				)
					:
					null
			}

			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Quick Set Up
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
						<div className="d-flex align-items-center gap-2 gap-lg-3">
							{SetupOrgDetails.length > 0 &&

								<a onClick={(e) => { Skip_now(SetupOrgDetails[0]) }} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
									Skip Now</a>
							}
							{(quick_set_flag == 1 && props.match.params.id === "") &&
								<label class="form-check form-switch form-check-custom form-check-solid w-200">
									<input class="form-check-input" type="checkbox"
										onChange={(e) => {
											if (e.target.checked) {
												reset_org();
												setcontent_flag(0);
											}
											else {
												dyanamic_org(OrganizationId, OrganizationDetails);
												setcontent_flag(1);
												if (navtype === "Address Details" || navtype === "GSTIN Details") {
													setnavtype("Org Details");
												}
											}
										}} checked={content_flag == 0} />
									<span class="form-check-label fw-semibold text-muted">
										Try New Organization
									</span>
								</label>}
							{quick_set_flag == 1 &&
								<a onClick={() => {
									setCookie("access_token", "", -1, true);
									setCookie("organization_id", "", -1, true);
									if (getCookie("access_token") == "" && getCookie("organization_id") == "") {
										history.push("/login")
									}
								}} className="btn btn-sm fs-4 btn-light-danger">
									<span className="btn-label"><i className="bi bi-box-arrow-left fs-1"></i>Sign Out</span>
								</a>
							}
						</div>
					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{quick_set_flag == 1 ?
							<>
								<div className="form d-flex flex-column flex-lg-row">
									<div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
										<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
											<li className="nav-item">
												<a className={`nav-link text-active-primary pb-4 ${navtype === "Org Details" && "active"}`} onClick={(e) => { check_validation(navtype) && setnavtype("Org Details") }} style={{ cursor: "pointer" }}>Organization Details</a>
											</li>
											{content_flag == 0 &&
												<>
													<li className="nav-item">
														<a className={`nav-link text-active-primary pb-4 ${navtype === "Address Details" && "active"}`} onClick={(e) => { check_validation(navtype) && setnavtype("Address Details") }} style={{ cursor: "pointer" }}>Address Details</a>
													</li>
													<li className="nav-item">
														<a className={`nav-link text-active-primary pb-4 ${navtype === "GSTIN Details" && "active"}`} onClick={(e) => { check_validation(navtype) && setnavtype("GSTIN Details") }} style={{ cursor: "pointer" }}>GSTIN Details</a>
													</li>
												</>
											}
											<li className="nav-item">
												<a className={`nav-link text-active-primary pb-4 ${navtype === "Advance" && "active"}`} onClick={(e) => { check_validation(navtype) && setnavtype("Advance") }} style={{ cursor: "pointer" }}>Advanced</a>
											</li>
										</ul>
										<div className="tab-content">
											{navtype == "Org Details" &&
												<div className={`tab-pane fade ${navtype === "Org Details" && "show active"}`} role="tab-panel">
													<div className="d-flex flex-column gap-7 gap-lg-10">
														<div className="card card-flush py-4">
															{
																content_flag == 0 &&
																<div className="card-header">
																	<div className="card-title">
																		<h2> Organization Details</h2>
																	</div>
																</div>
															}
															<div className="card-body pt-0">
																<div className="w-100">
																	<div className="fv-row">
																		{content_flag == 1 &&
																			<>
																				<label className="d-flex align-items-center fs-5 fw-semibold mb-4">
																					<span className="required">Select Organization</span>
																					<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Select your app category" data-bs-original-title="Select your app category" data-kt-initialized="1"></i>
																				</label>
																				<div>
																					{OrganizationDetails.map((obj1, indx) => {
																						return (
																							<div key={indx} className="d-flex flex-stack mb-5 cursor-pointer" onClick={(event) => {
																								let temp = OrganizationDetails.map(x => { return { ...x } });
																								temp.forEach(x => {
																									x.check = false
																								})
																								temp[indx].check = true

																								setOrganizationId(temp[indx].organization_id);
																								dyanamic_org(temp[indx].organization_id, temp);
																								setOrganizationDetails(temp);
																							}}>
																								<span className="d-flex align-items-center me-2">
																									<span className="symbol symbol-50px me-6">
																										<span className="symbol-label bg-light-primary">
																											<span className="svg-icon svg-icon-1 svg-icon-primary">
																												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																													<path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="currentColor"></path>
																													<path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="currentColor"></path>
																												</svg>
																											</span>
																										</span>
																									</span>
																									<span className="d-flex flex-column">
																										<span className="fw-bold fs-6 mb-2">{obj1.organization_name}</span>
																									</span>
																								</span>

																								<span className="form-check form-check-custom form-check-solid">
																									<input className="form-check-input" type="radio" checked={obj1.check} readOnly />
																								</span>
																							</div>

																						)
																					})}

																				</div>
																			</>}
																		<div className="row gx-0 mb-1" style={{ transition: "all 1s ease-in-out", opacity: "1", visibility: "visible", height: "auto", marginBottom: "1rem" }} >
																			{content_flag == 0 ?
																				<div className="col-12">
																					{/* <div className="bg-light-primary border border-primary d-flex flex-column w-100 p-5 rounded"> */}
																					<div className="card card-flush my-5 shadow-sm border-0">
																						<div className="card-body p-5">
																							<div className="bg-light-secondary border p-13 rounded-3">
																								<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
																									<span className="fw-bold fs-6">
																										{/* Organization Name : {OrganizationDetails.find((x) => x.organization_id === OrganizationId).organization_name} */}
																									</span>
																								</div>
																								<div className="row fv-row mb-7">
																									<div className="col-md-6">
																										<label className="form-label required">Organization Name</label>

																										<input type="text" className="form-control" value={OrganizationName}
																											onChange={(e) => {
																												setOrganizationName(e.target.value);
																											}}
																											disabled={props.match.params.id}
																											readOnly={props.match.params.id}
																											placeholder="example: Subway"
																										/>
																									</div>
																									<div className="col-md-6">
																										<label className="form-label required">Organization Domain Name</label>
																										<input className="form-control" type="text" placeholder="example: subway" value={organization_domain} onChange={(e) => { setorganization_domain(e.target.value) }} />
																									</div>
																								</div>

																								<div className="row mb-5">
																									<div className="col-md-6">
																										<label className="form-label required">Time Zone</label>
																										<select name="timezone" aria-label="Select a Timezone" id="timezone" /*disabled={props.match.params.id} readOnly={props.match.params.id}*/ data-placeholder="Select a timezone.." className="form-select" value={timezone} onChange={(e) => {
																											settimezone(e.target.value)
																										}}>
																											<option value="">Select a Timezone..</option>
																											<option data-bs-offset="-39600" value="International Date Line West || -11:00">(GMT-11:00) International Date Line West</option>
																											<option data-bs-offset="-39600" value="Midway Island || -11:00">(GMT-11:00) Midway Island</option>
																											<option data-bs-offset="-39600" value="Samoa || -11:00">(GMT-11:00) Samoa</option>
																											<option data-bs-offset="-36000" value="Hawaii || -11:00">(GMT-10:00) Hawaii</option>
																											<option data-bs-offset="-28800" value="Alaska || -11:00">(GMT-11:00) Alaska</option>
																											<option data-bs-offset="-25200" value="Pacific Time (US &amp; Canada) || -07:00">(GMT-07:00) Pacific Time (US &amp; Canada)</option>
																											<option data-bs-offset="-25200" value="Tijuana || -07:00">(GMT-07:00) Tijuana</option>
																											<option data-bs-offset="-25200" value="Arizona">(GMT-07:00) Arizona</option>
																											<option data-bs-offset="-21600" value="Mountain Time (US &amp; Canada) || -06:00">(GMT-06:00) Mountain Time (US &amp; Canada)</option>
																											<option data-bs-offset="-21600" value="Chihuahua || -06:00">(GMT-06:00) Chihuahua</option>
																											<option data-bs-offset="-21600" value="Mazatlan || -06:00">(GMT-06:00) Mazatlan</option>
																											<option data-bs-offset="-21600" value="Saskatchewan || -06:00">(GMT-06:00) Saskatchewan</option>
																											<option data-bs-offset="-21600" value="Central America ||-06:00">(GMT-06:00) Central America</option>
																											<option data-bs-offset="-18000" value="Central Time (US &amp; Canada) || -05:00">(GMT-05:00) Central Time (US &amp; Canada)</option>
																											<option data-bs-offset="-18000" value="Guadalajara || -05:00">(GMT-05:00) Guadalajara</option>
																											<option data-bs-offset="-18000" value="Mexico City || -05:00">(GMT-05:00) Mexico City</option>
																											<option data-bs-offset="-18000" value="Monterrey || -05:00">(GMT-05:00) Monterrey</option>
																											<option data-bs-offset="-18000" value="Bogota || -05:00">(GMT-05:00) Bogota</option>
																											<option data-bs-offset="-18000" value="Lima || -05:00">(GMT-05:00) Lima</option>
																											<option data-bs-offset="-18000" value="Quito || -05:00">(GMT-05:00) Quito</option>
																											<option data-bs-offset="-14400" value="Eastern Time (US &amp; Canada) || -04:00">(GMT-04:00) Eastern Time (US &amp; Canada)</option>
																											<option data-bs-offset="-14400" value="Indiana (East) || -04:00">(GMT-04:00) Indiana (East)</option>
																											<option data-bs-offset="-14400" value="Caracas || -04:00">(GMT-04:00) Caracas</option>
																											<option data-bs-offset="-14400" value="La Paz || -04:00">(GMT-04:00) La Paz</option>
																											<option data-bs-offset="-14400" value="Georgetown || -04:00">(GMT-04:00) Georgetown</option>
																											<option data-bs-offset="-10800" value="Atlantic Time (Canada) || -03:00">(GMT-03:00) Atlantic Time (Canada)</option>
																											<option data-bs-offset="-10800" value="Santiago || -03:00">(GMT-03:00) Santiago</option>
																											<option data-bs-offset="-10800" value="Brasilia || -03:00">(GMT-03:00) Brasilia</option>
																											<option data-bs-offset="-10800" value="Buenos Aires || -03:00">(GMT-03:00) Buenos Aires</option>
																											<option data-bs-offset="-9000" value="Newfoundland || -02:30">(GMT-02:30) Newfoundland</option>
																											<option data-bs-offset="-7200" value="Greenland || -02:00">(GMT-02:00) Greenland</option>
																											<option data-bs-offset="-7200" value="Mid-Atlantic || -02:00">(GMT-02:00) Mid-Atlantic</option>
																											<option data-bs-offset="-3600" value="Cape Verde Is. || -01:00">(GMT-01:00) Cape Verde Is.</option>
																											<option data-bs-offset="0" value="Azores || +00:00">(GMT) Azores</option>
																											<option data-bs-offset="0" value="Monrovia || +00:00">(GMT) Monrovia</option>
																											<option data-bs-offset="0" value="UTC || +00:00">(GMT) UTC</option>
																											<option data-bs-offset="3600" value="Dublin || +01:00">(GMT+01:00) Dublin</option>
																											<option data-bs-offset="3600" value="Edinburgh || +01:00">(GMT+01:00) Edinburgh</option>
																											<option data-bs-offset="3600" value="Lisbon || +01:00">(GMT+01:00) Lisbon</option>
																											<option data-bs-offset="3600" value="London || +01:00">(GMT+01:00) London</option>
																											<option data-bs-offset="3600" value="Casablanca || +01:00">(GMT+01:00) Casablanca</option>
																											<option data-bs-offset="3600" value="West Central Africa || +01:00">(GMT+01:00) West Central Africa</option>
																											<option data-bs-offset="7200" value="Belgrade || +02:00">(GMT+02:00) Belgrade</option>
																											<option data-bs-offset="7200" value="Bratislava || +02:00">(GMT+02:00) Bratislava</option>
																											<option data-bs-offset="7200" value="Budapest || +02:00">(GMT+02:00) Budapest</option>
																											<option data-bs-offset="7200" value="Ljubljana || +02:00">(GMT+02:00) Ljubljana</option>
																											<option data-bs-offset="7200" value="Prague || +02:00">(GMT+02:00) Prague</option>
																											<option data-bs-offset="7200" value="Sarajevo || +02:00">(GMT+02:00) Sarajevo</option>
																											<option data-bs-offset="7200" value="Skopje || +02:00">(GMT+02:00) Skopje</option>
																											<option data-bs-offset="7200" value="Warsaw || +02:00">(GMT+02:00) Warsaw</option>
																											<option data-bs-offset="7200" value="Zagreb || +02:00">(GMT+02:00) Zagreb</option>
																											<option data-bs-offset="7200" value="Brussels || +02:00">(GMT+02:00) Brussels</option>
																											<option data-bs-offset="7200" value="Copenhagen || +02:00">(GMT+02:00) Copenhagen</option>
																											<option data-bs-offset="7200" value="Madrid || +02:00">(GMT+02:00) Madrid</option>
																											<option data-bs-offset="7200" value="Paris || +02:00">(GMT+02:00) Paris</option>
																											<option data-bs-offset="7200" value="Amsterdam || +02:00">(GMT+02:00) Amsterdam</option>
																											<option data-bs-offset="7200" value="Berlin || +02:00">(GMT+02:00) Berlin</option>
																											<option data-bs-offset="7200" value="Bern || +02:00">(GMT+02:00) Bern</option>
																											<option data-bs-offset="7200" value="Rome || +02:00">(GMT+02:00) Rome</option>
																											<option data-bs-offset="7200" value="Stockholm || +02:00">(GMT+02:00) Stockholm</option>
																											<option data-bs-offset="7200" value="Vienna || +02:00">(GMT+02:00) Vienna</option>
																											<option data-bs-offset="7200" value="Cairo || +02:00">(GMT+02:00) Cairo</option>
																											<option data-bs-offset="7200" value="Harare || +02:00">(GMT+02:00) Harare</option>
																											<option data-bs-offset="7200" value="Pretoria || +02:00">(GMT+02:00) Pretoria</option>
																											<option data-bs-offset="10800" value="Bucharest || +03:00">(GMT+03:00) Bucharest</option>
																											<option data-bs-offset="10800" value="Helsinki || +03:00">(GMT+03:00) Helsinki</option>
																											<option data-bs-offset="10800" value="Kiev || +03:00">(GMT+03:00) Kiev</option>
																											<option data-bs-offset="10800" value="Kyiv || +03:00">(GMT+03:00) Kyiv</option>
																											<option data-bs-offset="10800" value="Riga || +03:00">(GMT+03:00) Riga</option>
																											<option data-bs-offset="10800" value="Sofia || +03:00">(GMT+03:00) Sofia</option>
																											<option data-bs-offset="10800" value="Tallinn || +03:00">(GMT+03:00) Tallinn</option>
																											<option data-bs-offset="10800" value="Vilnius || +03:00">(GMT+03:00) Vilnius</option>
																											<option data-bs-offset="10800" value="Athens || +03:00">(GMT+03:00) Athens</option>
																											<option data-bs-offset="10800" value="Istanbul || +03:00">(GMT+03:00) Istanbul</option>
																											<option data-bs-offset="10800" value="Minsk || +03:00">(GMT+03:00) Minsk</option>
																											<option data-bs-offset="10800" value="Jerusalem || +03:00">(GMT+03:00) Jerusalem</option>
																											<option data-bs-offset="10800" value="Moscow || +03:00">(GMT+03:00) Moscow</option>
																											<option data-bs-offset="10800" value="St. Petersburg || +03:00">(GMT+03:00) St. Petersburg</option>
																											<option data-bs-offset="10800" value="Volgograd || +03:00">(GMT+03:00) Volgograd</option>
																											<option data-bs-offset="10800" value="Kuwait || +03:00">(GMT+03:00) Kuwait</option>
																											<option data-bs-offset="10800" value="Riyadh || +03:00">(GMT+03:00) Riyadh</option>
																											<option data-bs-offset="10800" value="Nairobi || +03:00">(GMT+03:00) Nairobi</option>
																											<option data-bs-offset="10800" value="Baghdad || +03:00">(GMT+03:00) Baghdad</option>
																											<option data-bs-offset="14400" value="Abu Dhabi || +04:00">(GMT+04:00) Abu Dhabi</option>
																											<option data-bs-offset="14400" value="Muscat || +04:00">(GMT+04:00) Muscat</option>
																											<option data-bs-offset="14400" value="Baku || +04:00">(GMT+04:00) Baku</option>
																											<option data-bs-offset="14400" value="Tbilisi || +04:00">(GMT+04:00) Tbilisi</option>
																											<option data-bs-offset="14400" value="Yerevan || +04:00">(GMT+04:00) Yerevan</option>
																											<option data-bs-offset="16200" value="Tehran || +04:30">(GMT+04:30) Tehran</option>
																											<option data-bs-offset="16200" value="Kabul || +04:30">(GMT+04:30) Kabul</option>
																											<option data-bs-offset="18000" value="Ekaterinburg || +05:00">(GMT+05:00) Ekaterinburg</option>
																											<option data-bs-offset="18000" value="Islamabad || +05:00">(GMT+05:00) Islamabad</option>
																											<option data-bs-offset="18000" value="Karachi || +05:00">(GMT+05:00) Karachi</option>
																											<option data-bs-offset="18000" value="Tashkent || +05:00">(GMT+05:00) Tashkent</option>
																											<option data-bs-offset="19800" value="Chennai || +05:30">(GMT+05:30) Chennai</option>
																											<option data-bs-offset="19800" value="Kolkata || +05:30">(GMT+05:30) Kolkata</option>
																											<option data-bs-offset="19800" value="Mumbai || +05:30">(GMT+05:30) Mumbai</option>
																											<option data-bs-offset="19800" value="New Delhi || +05:30">(GMT+05:30) New Delhi</option>
																											<option data-bs-offset="19800" value="Sri Jayawardenepura || +05:30">(GMT+05:30) Sri Jayawardenepura</option>
																											<option data-bs-offset="20700" value="Kathmandu || +05:45">(GMT+05:45) Kathmandu</option>
																											<option data-bs-offset="21600" value="Astana || +06:00">(GMT+06:00) Astana</option>
																											<option data-bs-offset="21600" value="Dhaka || +06:00">(GMT+06:00) Dhaka</option>
																											<option data-bs-offset="21600" value="Almaty || +06:00">(GMT+06:00) Almaty</option>
																											<option data-bs-offset="21600" value="Urumqi || +06:00">(GMT+06:00) Urumqi</option>
																											<option data-bs-offset="23400" value="Rangoon || +06:30">(GMT+06:30) Rangoon</option>
																											<option data-bs-offset="25200" value="Novosibirsk || +07:00">(GMT+07:00) Novosibirsk</option>
																											<option data-bs-offset="25200" value="Bangkok || +07:00">(GMT+07:00) Bangkok</option>
																											<option data-bs-offset="25200" value="Hanoi || +07:00">(GMT+07:00) Hanoi</option>
																											<option data-bs-offset="25200" value="Jakarta || +07:00">(GMT+07:00) Jakarta</option>
																											<option data-bs-offset="25200" value="Krasnoyarsk || +07:00">(GMT+07:00) Krasnoyarsk</option>
																											<option data-bs-offset="28800" value="Beijing || +08:00">(GMT+08:00) Beijing</option>
																											<option data-bs-offset="28800" value="Chongqing || +08:00">(GMT+08:00) Chongqing</option>
																											<option data-bs-offset="28800" value="Hong Kong || +08:00">(GMT+08:00) Hong Kong</option>
																											<option data-bs-offset="28800" value="Kuala Lumpur || +08:00">(GMT+08:00) Kuala Lumpur</option>
																											<option data-bs-offset="28800" value="Singapore || +08:00">(GMT+08:00) Singapore</option>
																											<option data-bs-offset="28800" value="Taipei || +08:00">(GMT+08:00) Taipei</option>
																											<option data-bs-offset="28800" value="Perth || +08:00">(GMT+08:00) Perth</option>
																											<option data-bs-offset="28800" value="Irkutsk || +08:00">(GMT+08:00) Irkutsk</option>
																											<option data-bs-offset="28800" value="Ulaan Bataar || +08:00">(GMT+08:00) Ulaan Bataar</option>
																											<option data-bs-offset="32400" value="Seoul || +09:00">(GMT+09:00) Seoul</option>
																											<option data-bs-offset="32400" value="Osaka || +09:00">(GMT+09:00) Osaka</option>
																											<option data-bs-offset="32400" value="Sapporo || +09:00">(GMT+09:00) Sapporo</option>
																											<option data-bs-offset="32400" value="Tokyo || +09:00">(GMT+09:00) Tokyo</option>
																											<option data-bs-offset="32400" value="Yakutsk || +09:00">(GMT+09:00) Yakutsk</option>
																											<option data-bs-offset="34200" value="Darwin || +09:30">(GMT+09:30) Darwin</option>
																											<option data-bs-offset="34200" value="Adelaide || +09:30">(GMT+09:30) Adelaide</option>
																											<option data-bs-offset="36000" value="Canberra || +10:00">(GMT+10:00) Canberra</option>
																											<option data-bs-offset="36000" value="Melbourne || +10:00">(GMT+10:00) Melbourne</option>
																											<option data-bs-offset="36000" value="Sydney || +10:00">(GMT+10:00) Sydney</option>
																											<option data-bs-offset="36000" value="Brisbane || +10:00">(GMT+10:00) Brisbane</option>
																											<option data-bs-offset="36000" value="Hobart || +10:00">(GMT+10:00) Hobart</option>
																											<option data-bs-offset="36000" value="Vladivostok || +10:00">(GMT+10:00) Vladivostok</option>
																											<option data-bs-offset="36000" value="Guam || +10:00">(GMT+10:00) Guam</option>
																											<option data-bs-offset="36000" value="Port Moresby || +10:00">(GMT+10:00) Port Moresby</option>
																											<option data-bs-offset="36000" value="Solomon Is. || +10:00">(GMT+10:00) Solomon Is.</option>
																											<option data-bs-offset="39600" value="Magadan || +11:00">(GMT+11:00) Magadan</option>
																											<option data-bs-offset="39600" value="New Caledonia || +11:00">(GMT+11:00) New Caledonia</option>
																											<option data-bs-offset="43200" value="Fiji || +12:00">(GMT+12:00) Fiji</option>
																											<option data-bs-offset="43200" value="Kamchatka || +12:00">(GMT+12:00) Kamchatka</option>
																											<option data-bs-offset="43200" value="Marshall Is. || +12:00">(GMT+12:00) Marshall Is.</option>
																											<option data-bs-offset="43200" value="Auckland || +12:00">(GMT+12:00) Auckland</option>
																											<option data-bs-offset="43200" value="Wellington || +12:00">(GMT+12:00) Wellington</option>
																											<option data-bs-offset="46800" value="Nuku'alofa || +13:00">(GMT+13:00) Nuku'alofa</option>
																										</select>
																										<span id="timezone1" className="text-danger font-weight-bold"></span>
																									</div>
																									<div className="col-md-6">
																										<label className={`form-label required`}>Phone Number</label>
																										<input type="text" placeholder="Enter Phone Number" className="form-control" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} />
																									</div>
																								</div>
																								<div className="row mb-5">
																									<div className="col-md-6 fv-row">
																										<label className="form-label required">Currency:</label>
																										<Select
																											styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																											options={Currencylist}
																											onChange={(event) => { setCurrencyCode(event.value); setCurrencySymbol(event.symbol) }}
																											value={Currencylist.filter(option => option.value == CurrencyCode).length != 0 && Currencylist.filter(option => option.value == CurrencyCode)[0]}
																											placeholder="--Choose a Currency--"
																											isDisabled={props.match.params.id}

																										/>
																									</div>
																									<div className="col-md-6 fv-row">
																										<label className={`form-label required`}>Business Type:</label>
																										<Select
																											styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																											options={businessTypeList}
																											onChange={(val) => {
																												setBusinessType(val);

																											}}
																											value={businessType}
																										/>
																									</div>
																									{/* <div className="col-md-6 fv-row">
																										<label className={`form-label`}>Industry:</label>
																										<Select
																											styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																											options={Industrylist}
																											onChange={(event) => {
																												setIndustry(event.value)
																											}}
																											value={Industrylist.filter(option => option.value == Industry).length != 0 && Industrylist.filter(option => option.value == Industry)[0]}
																											placeholder="--Select Industry--"
																										/>
																									</div> */}
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																				:
																				<>
																					<div className="card card-flush my-5 shadow-sm border-0">
																						<div className="card-body p-5">
																							<div className="bg-light-secondary border p-13 rounded-3">
																								<div className="card-header cursor-pointer py-3">
																									<div className="card-title m-0">
																										<h3 className="fw-bold m-0">
																											Organization Details
																										</h3>
																									</div>
																								</div>
																								<div className="card-body p-3">
																									<div style={{ border: "1px solid #dee2e6", borderRadius: "0.5rem", padding: "10px", marginBottom: "20px" }}>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Organization Domain Name</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{organization_domain}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Timezone</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{timezone}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Phone Number</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{phoneNumber}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Currency</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{CurrencyCode}
																												</span>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>

																					<div className="card card-flush my-5 shadow-sm border-0">
																						<div className="card-body p-5">
																							<div className="bg-light-secondary border p-13 rounded-3">
																								<div className="card-header cursor-pointer py-3">
																									<div className="card-title m-0">
																										<h3 className="fw-bold m-0">
																											Address Details
																										</h3>
																									</div>
																								</div>
																								<div className="card-body p-3">
																									<div style={{ border: "1px solid #dee2e6", borderRadius: "0.5rem", padding: "10px", marginBottom: "20px" }}>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Country</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{billingSelectedCountry}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">State</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{billingSelectedState}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Address Line 1</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{billingAddress1}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">City</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{billingCity}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Pin Code</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{billingPincode}
																												</span>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="card card-flush my-5 shadow-sm border-0">
																						<div className="card-body p-5">
																							<div className="bg-light-secondary border p-13 rounded-3">
																								<div className="card-header cursor-pointer py-3">
																									<div className="card-title m-0">
																										<h3 className="fw-bold m-0">
																											Tax Details
																										</h3>
																									</div>
																								</div>
																								<div className="card-body p-3">
																									<div style={{ border: "1px solid #dee2e6", borderRadius: "0.5rem", padding: "10px", marginBottom: "20px" }}>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">GST Treatment</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{gstTreatment.value}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">GSTIN</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{GstIN}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Business Legal Name</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{LegalName}
																												</span>
																											</div>
																										</div>
																										<div className="row mb-2">
																											<label className="col-lg-4 fw-semibold text-muted">Business Trade Name</label>
																											<div className="col-lg-8">
																												<span className="fw-bold fs-6 text-gray-800">
																													{TradeName}
																												</span>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											}

											{navtype == "Address Details" &&
												<div className={`tab-pane fade ${navtype === "Address Details" && "show active"}`} role="tab-panel">
													<div className="d-flex flex-column gap-7 gap-lg-10">
														<div className="card card-flush py-4">
															<div className="card-header">
																<div className="card-title">
																	<h2> Address Details</h2>
																</div>
															</div>
															<div className="card-body pt-0">
																<div className="w-100">
																	<div className="fv-row">
																		<div className="row gx-0 mb-1" style={{ transition: "all 1s ease-in-out", opacity: "1", visibility: "visible", height: "auto", marginBottom: "1rem" }} >
																			{content_flag == 0 &&
																				<div className="col-12">
																					<div className="card card-flush my-5 shadow-sm border-0">
																						<div className="card-body p-5">
																							<div className="bg-light-secondary border p-13 rounded-3">
																								<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
																									<span className="fw-bold fs-6">
																										Organization Name : {OrganizationName}
																									</span>
																								</div>
																								<div className="row mb-5">
																									<div className="col-md-6">
																										<label className={`form-label required`}>Country</label>
																										<Select
																											styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																											options={billingCountryOptions}
																											value={{ label: billingSelectedCountry, value: billingSelectedCountry }}
																											onChange={(val) => { setBillingSelectedCountry(val.value); setBillingSelectedState(null); loadBillingStateOptions(val); }}
																											isDisabled={props.match.params.id}
																										/>
																									</div>

																									<div className="col-md-6">
																										<label className={`form-label required`}>State / Province / Region</label>
																										<Select
																											styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																											options={billingStateOptions}
																											value={{ label: billingSelectedState, value: billingSelectedState }}
																											onChange={(val) => { setBillingSelectedState(val.value) }}
																											isDisabled={props.match.params.id}
																										/>
																									</div>
																								</div>

																								<div className="row mb-5">
																									<div className="col-md-12">
																										<label className="form-label">Address Line 1</label>
																										<input type="text" placeholder="Enter Address Line 1" className="form-control" value={billingAddress1} onChange={(e) => { setBillingAddress1(e.target.value) }} />
																									</div>
																								</div>

																								<div className="row mb-5">
																									<div className="col-md-6">
																										<label className={`form-label required`}>Town/City</label>
																										<input type="text" className="form-control" placeholder="Enter Town/City" value={billingCity} onChange={(e) => { setBillingCity(e.target.value) }} />
																									</div>
																									<div className="col-md-6">
																										<label className={`form-label required`}>Pin Code</label>
																										<input type="text" className="form-control" placeholder="Enter Pin Code" value={billingPincode} onChange={(e) => { setBillingPincode(e.target.value) }} />
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											}

											{navtype == "GSTIN Details" &&
												<div className={`tab-pane fade ${navtype === "GSTIN Details" && "show active"}`} role="tab-panel">
													<div className="d-flex flex-column gap-7 gap-lg-10">
														<div className="card card-flush py-4">
															<div className="card-header">
																<div className="card-title">
																	<h2>Tax Details</h2>
																</div>
															</div>
															<div className="card-body pt-0">
																<div className="w-100">
																	<div className="fv-row">
																		<div className="row gx-0 mb-1" style={{ transition: "all 1s ease-in-out", opacity: "1", visibility: "visible", height: "auto", marginBottom: "1rem" }} >
																			{content_flag == 0 &&
																				<div className="col-12">
																					<div className="card card-flush my-5 shadow-sm border-0">
																						<div className="card-body p-5">
																							<div className="bg-light-secondary border p-13 rounded-3">
																								<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
																									<span className="fw-bold fs-6">
																										Organization Name : {OrganizationName}
																									</span>
																								</div>
																								<div className="d-flex flex-wrap gap-5 mb-2">
																									<div className="fv-row w-100 flex-md-root">
																										<label className="form-label">GST Treatment</label>
																										<Select
																											isDisabled
																											styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																											options={gstTreatmentlist}
																											value={gstTreatment}
																											onChange={(val) => { setGstTreatment(val) }}
																											components={{
																												Option: (props) => {
																													// console.log(props);
																													return (
																														<components.Option  {...props}>
																															{props.children}
																															<span className="fw-semibold text-gray-400 d-block fs-8">{props.data.description}</span>
																														</components.Option >
																													)
																												}
																											}}
																										/>
																									</div>
																									{gstTreatment.value === "Registered Business-Regular" &&
																										<div className="fv-row w-100 flex-md-root">
																											<label className={`form-label ${gstTreatment != null && "required"}`}>GSTIN</label>
																											<div className='d-flex gap-2 align-items-center'>
																												<input id="GSTIN" type="text" className="form-control mb-2" placeholder="" name="GSTIN" value={GstIN} onChange={(e) => { setGstIN(e.target.value) }} />
																												{
																													<button className="btn btn-sm btn-secondary" disabled={gstinSearchDetailsApiState}
																														onClick={() => {
																															if (!gstinRegex.test(GstIN)) {
																																swal("Warning!", "Invalid GSTIN format", "warning");
																																return
																															}
																															getGstinSearchDetails(GstIN)
																														}}
																													>
																														{
																															gstinSearchDetailsApiState === true ?
																																<span className="spinner-border spinner-border-sm align-middle"></span>
																																:
																																gstinSearchDetails === null ?
																																	<span className="text-dark">Verify</span> :
																																	<i className="bi bi-patch-check-fill text-success fs-2 p-0"></i>
																														}
																													</button>
																												}
																											</div>
																										</div>}
																								</div>
																								{gstTreatment.value === "Registered Business-Regular" &&
																									<div className="d-flex flex-wrap gap-5">
																										<div className="fv-row w-100 flex-md-root">
																											<label className={`form-label ${gstTreatment != null && "required"}`}>Business Legal Name</label>
																											<input id="Business_Legal_Name" type="text" className="form-control mb-2" placeholder="" name="Business_Legal_Name" value={LegalName} onChange={(e) => { setLegalName(e.target.value) }} />
																										</div>
																										<div className="fv-row w-100 flex-md-root">
																											<label className="form-label">Business Trade Name</label>
																											<input id="Business_Trade_Name" type="text" className="form-control mb-2" placeholder="" name="Business_Trade_Name" value={TradeName} onChange={(e) => { setTradeName(e.target.value) }} />
																										</div>
																									</div>
																								}
																							</div>
																						</div>
																					</div>
																				</div>
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											}

											{
												navtype == "Advance" &&
												<div className={`tab-pane fade ${navtype === "Advance" && "show active"}`} role="tab-panel">
													<div className="d-flex flex-column gap-7 gap-lg-10">
														<div className="card card-flush py-4 shadow-lg rounded">
															<div className="card-header">
																<div className="card-title">
																	<h2>Account Module</h2>
																</div>
															</div>
															<div className="card-body">
																<div
																	className="notice d-flex align-items-center justify-content-center py-10 border rounded shadow-lg"
																	style={{ backgroundColor: "#e0f7ef", borderColor: "#00ae73" }}
																>
																	<h4 className="text-gray-900 fw-bolder text-center m-0">
																		<span>
																			Set up Complete{" "}
																			<i className="bi bi-hand-thumbs-up-fill fs-4 text-success"></i>
																		</span>
																		<br />
																		<span className="fs-5 fw-normal">
																			Start your free trial for 30 days.
																			{/* Start your {PlanDetails.duration} {PlanDetails.unit} free trial */}
																		</span>
																		{mainSubmitButtonState &&
																			<Progress percent={progress} percentPosition={{ align: 'center', type: 'inner' }} size={[400, 20]} />}
																	</h4>
																</div>
															</div>
														</div>
													</div>

												</div>
											}
										</div>
										{navtype == "Org Details" ?
											<div className="d-flex justify-content-end">
												<button type="button" onClick={() => {
													if (check_validation(navtype)) {
														if (content_flag == 0) {
															setnavtype("Address Details");
														}
														else {
															setnavtype("Advance");
														}
														ScrollToTop();
													}
												}} className="btn btn-lg btn-primary">Next
													<span className="svg-icon svg-icon-4 ms-1 me-0">
														<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
															<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																<polygon points="0 0 24 0 24 24 0 24" />
																<rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
																<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
															</g>
														</svg>
													</span>
												</button>

											</div>
											:
											navtype == "Address Details" ?
												<div className="d-flex flex-stack pt-15">
													<div className="mr-2">
														<button type="button" onClick={() => { setnavtype("Org Details"); ScrollToTop(); }} className="btn btn-lg btn-primary me-3">
															<i className="ki-duotone ki-arrow-left fs-4 me-1"><span className="path1"></span><span className="path2"></span></i>Back
														</button>
													</div>
													<div className="d-flex justify-content-end">
														<button type="button"
															onClick={() => {
																if (check_validation(navtype)) {
																	setnavtype("GSTIN Details");
																	ScrollToTop();
																}
															}} className="btn btn-lg btn-primary">Next
															<span className="svg-icon svg-icon-4 ms-1 me-0">
																<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																		<polygon points="0 0 24 0 24 24 0 24" />
																		<rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
																		<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																	</g>
																</svg>
															</span>
														</button>
													</div>
												</div>
												:
												navtype == "GSTIN Details" ?
													<div className="d-flex flex-stack pt-15">
														<div className="mr-2">
															<button type="button" onClick={() => { setnavtype("Address Details"); ScrollToTop(); }} className="btn btn-lg btn-primary me-3">
																<i className="ki-duotone ki-arrow-left fs-4 me-1"><span className="path1"></span><span className="path2"></span></i>Back
															</button>
														</div>
														<div className="d-flex justify-content-end">
															<button type="button"
																onClick={() => {
																	if (check_validation(navtype)) {
																		setnavtype("Advance");
																		ScrollToTop();
																	}
																}} className="btn btn-lg btn-primary">Next
																<span className="svg-icon svg-icon-4 ms-1 me-0">
																	<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																			<polygon points="0 0 24 0 24 24 0 24" />
																			<rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
																			<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
																		</g>
																	</svg>
																</span>
															</button>
														</div>
													</div>
													:
													<div className="d-flex flex-stack pt-15">
														<div className="mr-2">
															<button type="button"
																onClick={() => {
																	if (content_flag == 0) {
																		setnavtype("GSTIN Details");
																	}
																	else {
																		setnavtype("Org Details");
																	}
																	ScrollToTop();
																}} className="btn btn-lg btn-primary me-3">
																<i className="ki-duotone ki-arrow-left fs-4 me-1"><span className="path1"></span><span className="path2"></span></i>Back
															</button>
														</div>
														<div className="d-flex justify-content-end">
															<button data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={() => { Save_set_up_information(); }} className="btn btn-primary">
																<span className="indicator-label">Save Changes</span>
																<span className="indicator-progress">Please wait...
																	<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
															</button>
														</div>
													</div>
										}
									</div>
								</div>
							</>
							:
							<>
								{
									quick_set_flag == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ "background-image": "url(assets/media/misc/auth-bg.png)" }}>

											<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">

												<a href="../../demo1/dist/index.html" className="mb-12">
													<img alt="Logo" src="assets/media/logos/custom-1.png" className="h-75px" />
												</a>

												<div className="d-flex flex-column flex-center text-center p-10">
													<div className="card card-flush w-md-650px py-5">
														<div className="card-body py-15 py-lg-20">

															<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed rounded-3 p-6">
																<div className="d-flex flex-stack flex-grow-1">
																	<div className="fw-bold">
																		<h4 className="text-gray-800 fw-bolder">Quick Set Up already Done.</h4>
																		<div className="fs-6 text-gray-600"> </div>
																	</div>

																</div>

															</div>

															<div className="mb-0">
																<img src="/assets/media/auth/welcome.png" className="mw-100 mh-300px theme-light-show" alt="" />
																<img src="/assets/media/auth/welcome-dark.png" className="mw-100 mh-300px theme-dark-show" alt="" />
															</div>

															<div className="mb-0">
																<a href="/dashboard" className="btn btn-sm btn-primary">Go To Dashboard Page</a>
															</div>

														</div>
													</div>
												</div>
												<h1 className="text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>

												<div className="text-white fs-base text-center">In this kind of post,
													<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
													<br />and provides some background information about
													<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
													<br />work following this is a transcript of the interview.</div>
											</div>
										</div>
								}
							</>
						}
					</div>
				</div>
			</div >
		</>
	);
}
export default QuickSetUp;