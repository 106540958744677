import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Tooltip } from 'antd';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from '../common_component/PageError.js';

export const ViewPurchaseOrder = (props) => {

	const [purchaseOrderDetails, setPurchaseOrderDetails] = useState([]);
	const [purchaseOrderHistoryDetails, setPurchaseOrderHistoryDetails] = useState([]);
	const [receiveGoodsRecords, setReceiveGoodsRecords] = useState([]);

	const [itemDetails, setItemDetails] = useState([]);
	const [taxDetails, setTaxDetails] = useState([]);
	const [billingAddress, setBillingAddress] = useState({});
	const [shippingAddress, setShippingAddress] = useState({});
	const [gstDetails, setGstDetails] = useState(null);
	const [currencyCode, setCurrencyCode] = useState("");
	const [currencySymbol, setCurrencySymbol] = useState("");

	const [adjustmentValue, setAdjustmentValue] = useState("");
	const [adjustmentText, setAdjustmentText] = useState("");

	const [subTotal, setSubTotal] = useState(0)

	const history = useHistory()

	const [state, setstate] = useState(2);
	const [decimalPlace, setDecimalPlace] = useState();
	const [userRole, setUserRole] = useState("")

	const [approvalChainPopup, setApprovalChainPopup] = useState(false)

	const [approveFinalButtonShow, setApproveFinalButtonShow] = useState(false)
	const [approveButtonShow, setApproveButtonShow] = useState(false)
	const [cancelButtonShow, setCancelButtonShow] = useState(false)

	const [submitButtonState, setSubmitButtonState] = useState(false);

	const timer = useRef(0)
	const [clock_timer, setClockTimer] = useState(0);

	var page_name = "view-purchase-order";

	const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	async function getPurchaseOrderDetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-purchase-order-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "purchase_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();

		if (body.operation === "success") {
			setPurchaseOrderDetails(body.data);

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/individual-user-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "unique_id": "", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const t_body = await response.json();

			let user_role = t_body.data[0].user_role
			setUserRole(t_body.data[0].user_role)

			if (user_role == "Admin") {
				setApproveFinalButtonShow(true)
				setCancelButtonShow(true)
			}

			let t1 = body.data[0].approval_info
			let t2 = body.data[0].add_status
			let t3 = body.data[0].approval_level
			let t4 = t1.value.length
			if (t2 == "PURCHASE_ORDER_PENDING_APPROVAL") {
				if (t1.type == "multilevel_approval") {

					let tl = t1.value.find(x => x.level == (t3 == null ? 1 : t3 + 1)).user_value
					if (tl.includes(user.email)) {
						setApproveButtonShow(true)
					}

					let tl2 = t1.value.map(x => x.user_value).flat(1)
					if (tl2.includes(user.email)) {
						setCancelButtonShow(true)
					}
				}
				else if (t1.type == "custom_approval") {
					let ty = t1.value[0]
					if (ty.user_type == "Group User") {
						if (ty.user_value.includes(user_role)) {
							setApproveButtonShow(true)
							setCancelButtonShow(true)
						}
					}
					else if (ty.user_type == "Individual User") {
						if (ty.user_value.includes(user.email)) {
							setApproveButtonShow(true)
							setCancelButtonShow(true)
						}
					}
				}
			}

			setCurrencyCode(body.data[0].order_currency.currency_code);
			let decimal = Math.pow(10, parseInt(body.data[0].order_currency.decimal_place));

			setDecimalPlace(decimal);
			setCurrencySymbol(body.data[0].order_currency.currency_symbol);
			setAdjustmentValue(body.data[0].adjustment.value);
			setAdjustmentText(body.data[0].adjustment.text);

			let temp = body.data[0].item_array;
			let arr = [];
			temp.forEach((obj, index) => {
				if (obj.taxable === 0) {
					obj.taxable_amount = 0;
				}
				else {
					obj.total_taxable_amount = Math.round(((obj.quantity * obj.rate) * (obj.tax_group_percentage / 100)) * decimal) / decimal;
					obj.taxable_amount = Number(obj.rate) * obj.tax_group_percentage / 100;
				}

				if (obj.tax_calculation_method === "TAX_Exclusive") {
					obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
					obj.TAX_Exclusive_Price = obj.rate;
				}
				else {
					obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
					obj.TAX_Exclusive_Price = obj.rate;
				}

				obj.total_tax = obj.tax_group_details == null ? 0 : obj.tax_group_details.reduce((p, o) => { return p + Number(o.tax_percentage) }, 0);
				if (obj.tax_group_details !== null) {
					obj.tax_group_details.forEach((obj1, index1) => {
						if (arr.find(x => x.tax_id === obj1.tax_id)) {
							let p = arr.find(x => x.tax_id === obj1.tax_id);
							p.tax_amount = p.tax_amount + Math.round((Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax)) * decimal) / decimal;
						}
						else {
							let json = {};
							json.tax_id = obj1.tax_id;
							json.tax_name = obj1.tax_name;
							json.tax_percentage = obj1.tax_percentage;
							json.tax_amount = Math.round((Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax)) * decimal) / decimal;
							arr.push(json);
						}
					});
				}
			})
			setItemDetails(temp);
			setTaxDetails(arr);
			setSubTotal(Math.round(body.data[0].item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) + (o.taxable === 0 ? 0 : (o.quantity * o.rate) * (o.tax_group_percentage / 100))) }, 0) * decimal) / decimal)
			setBillingAddress(body.data[0].billing_address);
			setShippingAddress(body.data[0].shipping_address);
			setGstDetails(body.data[0].gstin_details);
		}
	}
	async function getPurchaseOrderHistoryDetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-purchase-order-history-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "purchase_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body);
		if (body.operation === "success" && body.data.length > 0) {
			setPurchaseOrderHistoryDetails(body.data);
		}
	}

	async function getReceiveGoodsRecords() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-goods-received-list-by-po`, { method: 'POST', body: JSON.stringify({ "email": user.email, "purchase_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body);

		if (body.operation === "success") {
			setReceiveGoodsRecords(body.data.map(x => { return { ...x, showReceiveGoodsDetails: false } }))
		}
	}

	useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getPurchaseOrderDetails()
			let p2 = getPurchaseOrderHistoryDetails()
			let p3 = getReceiveGoodsRecords()

			Promise.all([p1, p2, p3])
				.then(() => {
					setstate(1);
					console.log("all apis done")
				})
				.catch((err) => {
					setstate(3);
					console.log(err)
				})
		}
	}, [user, visibility, read, write])

	const approvePurchaseOrderSubmit = (final_flag, cancel_flag, cancel_msg) => {

		var object = {};

		object.final_flag = final_flag
		object.cancel_flag = cancel_flag
		object.cancel_msg = cancel_msg
		object.purchase_order_id = props.match.params.id
		object.user_email = user.email
		object.user_role = userRole

		object.organization_id = organization_id
		object.page_name = page_name;

		// console.log(object);
		setSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-purchase-order`, object, headers).then((res) => {
			setSubmitButtonState(false)
			if (res.data.operation === "success") {
				swal("Great!", "Purchase Order reviewed successfully!", "success").then(() => { history.push("/purchase-order-list"); })
			}
			else {
				swal("Oops!", res.data.message, "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}

	return (
		<>
			<div className="d-flex flex-column flex-column-fluid">
				<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
					<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
						<div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
							<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Purchase Order
								<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
							</h1>
							<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
								<li className="breadcrumb-item text-muted"><span className="text-muted">Home</span></li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">Purchase Order List</li>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-muted">View Purchase Order</li>
							</ul>
						</div>

						{
							state === 1 &&
							<Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
								<Container>
									<Navbar.Toggle aria-controls="responsive-navbar-nav" />
									<Navbar.Collapse className='position-relative'>
										<Nav className="align-items-end end-0 gap-3 navbar-nav p-5 position-absolute">
											{
												approveFinalButtonShow && purchaseOrderDetails[0].add_status === "PURCHASE_ORDER_PENDING_APPROVAL" &&
												<Nav.Item>
													<button className='btn btn-light-success btn-hover-scale border border-success' title='Final Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal("Alert!", "Are you sure you want to proceed?", {
																buttons: {
																	nothing: { text: "Cancel", className: "btn btn-secondary" },
																	edit: { text: "Modify", className: "btn btn-warning" },
																	approve: { text: "Approve", className: "btn btn-danger" },
																}
															})
																.then((value) => {
																	switch (value) {
																		case "edit": history.push(`/edit-purchase-order/${props.match.params.id}`); break;
																		case "approve": approvePurchaseOrderSubmit(true, false, ""); break;
																	}
																});
														}}
													>
														<span className="indicator-label"><i className="bi bi-shield-check p-0 fs-1"></i></span>
														<span className="indicator-progress">Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
														</span>
													</button>
												</Nav.Item>
											}
											{
												approveButtonShow && purchaseOrderDetails[0].add_status === "PURCHASE_ORDER_PENDING_APPROVAL" &&
												<Nav.Item>
													<button className='btn btn-light-success btn-hover-scale border border-success' title='Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal("Alert!", "Are you sure you want to proceed?", {
																buttons: {
																	nothing: { text: "Cancel", className: "btn btn-secondary" },
																	edit: { text: "Modify", className: "btn btn-warning" },
																	approve: { text: "Approve", className: "btn btn-danger" },
																}
															})
																.then((value) => {
																	switch (value) {
																		case "edit": history.push(`/edit-purchase-order/${props.match.params.id}`); break;
																		case "approve": approvePurchaseOrderSubmit(false, false, ""); break;
																	}
																});
														}}
													>
														<span className="indicator-label"><i className="bi bi-check-circle p-0 fs-1"></i></span>
														<span className="indicator-progress">Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
														</span>
													</button>
												</Nav.Item>
											}
											{
												cancelButtonShow && purchaseOrderDetails[0].add_status === "PURCHASE_ORDER_PENDING_APPROVAL" &&
												<Nav.Item>
													<button className='btn btn-light-danger btn-hover-scale border border-danger' title='Cancel' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal({
																title: "Alert!",
																text: "Please type a reason before cancelling",
																icon: "warning",
																content: "input",
																buttons: true,
																dangerMode: true,
															})
																.then((msg) => {
																	if (msg === "") {
																		swal("Alert!", "Message cannot be empty", "warning")
																	} else if (msg) {
																		approvePurchaseOrderSubmit(false, true, msg);
																	}
																});
														}}
													>
														<span className="indicator-label"><i className="bi bi-x-circle p-0 fs-1"></i></span>
														<span className="indicator-progress">Please wait...
															<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
														</span>
													</button>
												</Nav.Item>
											}
											{
												(purchaseOrderDetails[0].add_status === "PURCHASE_ORDER_PENDING_APPROVAL" || purchaseOrderDetails[0].add_status === "PURCHASE_ORDER_DRAFT") &&
												<Nav.Item>
													<button className='btn btn-light-warning btn-hover-scale border border-warning' title='Edit' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
														onClick={() => {
															swal("Alert!", "Are you sure you want to proceed?", {
																buttons: {
																	nothing: { text: "Cancel", className: "btn btn-secondary" },
																	edit: { text: "Modify", className: "btn btn-warning" }
																}
															})
																.then((value) => {
																	switch (value) {
																		case "edit": history.push(`/edit-purchase-order/${props.match.params.id}`); break;
																	}
																});
														}}
													>
														<i className="bi bi-pencil-square fs-1 p-0"></i>
													</button>
												</Nav.Item>
											}
										</Nav>
									</Navbar.Collapse>
								</Container>
							</Navbar>
						}

					</div>
				</div>
				<div id="kt_app_content" className="app-content flex-column-fluid">
					<div id="kt_app_content_container" className="app-container container-xxl">
						{state === 1 ?
							<>
								<div className="d-flex flex-column gap-7 gap-lg-10">
									<div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
										<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#purchase_order_summary" aria-selected="true" role="tab">Purchase Order Summary</a>
											</li>
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#purchase_order_history" aria-selected="false" role="tab" tabIndex="-1">Purchase Order History</a>
											</li>
											<li className="nav-item" role="presentation">
												<a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#associated_gr" aria-selected="false" role="tab" tabIndex="-1">Associated GR</a>
											</li>
										</ul>
									</div>
									<div className="tab-content">
										<div className="tab-pane fade active show" id="purchase_order_summary" role="tab-panel">
											<div className="d-flex flex-column gap-7 gap-lg-10">
												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>Purchase Order Details (#{purchaseOrderDetails[0].purchase_order_number})</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Status
																				</div>
																			</td>
																			<td className="fw-bold text-end">
																				<div style={{ position: "relative", display: "inline-block" }} >
																					<button className={`py-2 me-1 rounded btn btn-sm btn-${purchaseOrderDetails[0].add_status === "PURCHASE_ORDER_PENDING_APPROVAL" ? "warning" : purchaseOrderDetails[0].add_status === "PURCHASE_ORDER_FINALIZED" ? "success" : "secondary"}`}
																						onClick={() => { setApprovalChainPopup(true); }}
																						onBlur={() => { setApprovalChainPopup(false) }}
																					>
																						{purchaseOrderDetails[0].add_status.replace("PURCHASE_ORDER", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
																					</button>
																					{
																						purchaseOrderDetails[0].approval_info.value.length > 0 &&
																						<div style={{ position: "absolute", right: "0px", top: "30px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#FFFFFF", color: "black", padding: "1rem", width: "25rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: approvalChainPopup ? "1" : "0", visibility: approvalChainPopup ? "visible" : "hidden" }}>
																							{
																								purchaseOrderDetails[0].approval_info.type == "multilevel_approval" ?
																									<>
																										<div className='text-center border-bottom fs-4'>Multilevel Approval</div>
																										<div>
																											{
																												purchaseOrderDetails[0].approval_info.value.map((obj, i) => {
																													return (
																														<div key={i} className='mx-auto mt-2 gap-5' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px", position: "relative", marginBottom: "30px" }}>
																															<div style={{ width: "30px", height: "30px", border: "5px #ffc700 solid", borderRadius: "10px" }}></div>
																															<div className='flex-grow-1 text-start'>
																																<div className='fs-4'>Level {obj.level}</div>
																																{
																																	obj.user_value.map((val, j) => {
																																		return <div key={j} className="fs-7"><span className='bullet bg-primary me-2'></span>{val}</div>
																																	})
																																}
																															</div>
																															<div style={{ position: "absolute", width: "2px", height: "30px", backgroundColor: "darkgray", top: "100%", left: "24px" }}></div>
																														</div>
																													)
																												})
																											}

																											<div className='mx-auto my-2 gap-20' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px" }}>
																												<i className="fa-solid fa-star fs-1" style={{ color: "#ff8100" }} ></i>
																												<div className='fs-4'>Approved</div>
																											</div>
																										</div>
																									</>
																									:
																									purchaseOrderDetails[0].approval_info.type == "custom_approval" &&
																									<>
																										<div className='text-center border-bottom fs-4'>Custom Approval</div>
																										<div>
																											<div className='mx-auto mt-2 gap-5' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px", position: "relative", marginBottom: "30px" }}>
																												<div style={{ width: "30px", height: "30px", border: "5px #ffc700 solid", borderRadius: "10px" }}></div>
																												<div className='flex-grow-1 text-start'>
																													<div className='fs-4'>{purchaseOrderDetails[0].approval_info.value[0].user_type}</div>
																													{
																														purchaseOrderDetails[0].approval_info.value[0].user_value.map((val, i) => {
																															return <div key={i} className="fs-7"><span className='bullet bg-primary me-2'></span>{val}</div>
																														})
																													}
																												</div>
																												<div style={{ position: "absolute", width: "2px", height: "30px", backgroundColor: "darkgray", top: "100%", left: "24px" }}></div>
																											</div>
																											<div className='mx-auto my-2 gap-20' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px" }}>
																												<i className="fa-solid fa-star fs-1" style={{ color: "#ff8100" }} ></i>
																												<div className='fs-4'>Approved</div>
																											</div>
																										</div>
																									</>
																							}
																						</div>
																					}
																				</div>
																			</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Order Date
																				</div>
																			</td>
																			<td className="fw-bold text-end">{moment(purchaseOrderDetails[0].order_date).format("ll")}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																							<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																							<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																						</svg>
																					</span>Reference Id
																				</div>
																			</td>
																			<td className="fw-bold text-end">{purchaseOrderDetails[0].reference_number !== "" ? purchaseOrderDetails[0].reference_number : "No reference number provided"}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Due Date
																				</div>
																			</td>
																			<td className="fw-bold text-end">{moment(purchaseOrderDetails[0].due_date).format("ll")}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>GSTIN Details</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																							<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																							<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																						</svg>
																					</span>GST Treatment
																				</div>
																			</td>
																			<td className="fw-bold text-end">{gstDetails !== null ? gstDetails.gst_treatment : "N/A"}</td>
																		</tr>
																		{
																			(gstDetails !== null && gstDetails.gst_treatment !== "Consumer" && gstDetails.gst_treatment !== "Unregistered Business") &&
																			<>
																				<tr>
																					<td className="text-muted">
																						<div className="d-flex align-items-center">
																							<span className="svg-icon svg-icon-2 me-2">
																								<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																									<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																									<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																								</svg>
																							</span>GSTIN
																						</div>
																					</td>
																					<td className="fw-bold text-end">{gstDetails !== null ? gstDetails.GstIn : "N/A"}</td>
																				</tr>
																				<tr>
																					<td className="text-muted">
																						<div className="d-flex align-items-center">
																							<span className="svg-icon svg-icon-2 me-2">
																								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																									<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																									<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																									<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																								</svg>
																							</span>Trade Name
																						</div>
																					</td>
																					<td className="fw-bold text-end">{gstDetails !== null ? (gstDetails.trade_name !== "" ? gstDetails.trade_name : "No given trade name") : "N/A"}</td>
																				</tr>
																				<tr>
																					<td className="text-muted">
																						<div className="d-flex align-items-center">
																							<span className="svg-icon svg-icon-2 me-2">
																								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																									<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																									<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																									<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																								</svg>
																							</span>Legal Name
																						</div>
																					</td>
																					<td className="fw-bold text-end">{gstDetails !== null ? gstDetails.legal_name : "N/A"}</td>
																				</tr>
																			</>
																		}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>


												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>Supplier Details</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																							<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																						</svg>
																					</span>Supplier Type
																				</div>
																			</td>
																			<td className="fw-bold text-end">{purchaseOrderDetails[0].user_type}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path>
																							<path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path>
																							<rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect>
																						</svg>
																					</span>Supplier Name
																				</div>
																			</td>
																			<td className="fw-bold text-end">{purchaseOrderDetails[0].doc_user_name}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																							<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																						</svg>
																					</span>Supplier Email
																				</div>
																			</td>
																			<td className="fw-bold text-end">{purchaseOrderDetails[0].doc_user_email !== "" ? purchaseOrderDetails[0].doc_user_email : "No Email-address added"}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
													<div className="card flex-equal">
														<div className="card-header">
															<div className="card-title">
																<h2>Associated Details</h2>
															</div>
														</div>
														<div className="card-body py-4">
															<div className="table-responsive">
																<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																	<tbody className="fw-semibold text-gray-600">
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Branch
																				</div>
																			</td>
																			<td className="fw-bold text-end">{purchaseOrderDetails[0].branch_name}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																							<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																						</svg>
																					</span>Warehouse
																				</div>
																			</td>
																			<td className="fw-bold text-end">{purchaseOrderDetails[0].warehouse_name}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="currentColor"></path>
																							<path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="currentColor"></path>
																						</svg>
																					</span>Source Of Supply
																				</div>
																			</td>
																			<td className="fw-bold text-end">{purchaseOrderDetails[0].source_of_supply}</td>
																		</tr>
																		<tr>
																			<td className="text-muted">
																				<div className="d-flex align-items-center">
																					<span className="svg-icon svg-icon-2 me-2">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M21.6 11.2L19.3 8.89998V5.59993C19.3 4.99993 18.9 4.59993 18.3 4.59993H14.9L12.6 2.3C12.2 1.9 11.6 1.9 11.2 2.3L8.9 4.59993H5.6C5 4.59993 4.6 4.99993 4.6 5.59993V8.89998L2.3 11.2C1.9 11.6 1.9 12.1999 2.3 12.5999L4.6 14.9V18.2C4.6 18.8 5 19.2 5.6 19.2H8.9L11.2 21.5C11.6 21.9 12.2 21.9 12.6 21.5L14.9 19.2H18.2C18.8 19.2 19.2 18.8 19.2 18.2V14.9L21.5 12.5999C22 12.1999 22 11.6 21.6 11.2Z" fill="currentColor"></path>
																							<path d="M11.3 9.40002C11.3 10.2 11.1 10.9 10.7 11.3C10.3 11.7 9.8 11.9 9.2 11.9C8.8 11.9 8.40001 11.8 8.10001 11.6C7.80001 11.4 7.50001 11.2 7.40001 10.8C7.20001 10.4 7.10001 10 7.10001 9.40002C7.10001 8.80002 7.20001 8.4 7.30001 8C7.40001 7.6 7.7 7.29998 8 7.09998C8.3 6.89998 8.7 6.80005 9.2 6.80005C9.5 6.80005 9.80001 6.9 10.1 7C10.4 7.1 10.6 7.3 10.8 7.5C11 7.7 11.1 8.00005 11.2 8.30005C11.3 8.60005 11.3 9.00002 11.3 9.40002ZM10.1 9.40002C10.1 8.80002 10 8.39998 9.90001 8.09998C9.80001 7.79998 9.6 7.70007 9.2 7.70007C9 7.70007 8.8 7.80002 8.7 7.90002C8.6 8.00002 8.50001 8.2 8.40001 8.5C8.40001 8.7 8.30001 9.10002 8.30001 9.40002C8.30001 9.80002 8.30001 10.1 8.40001 10.4C8.40001 10.6 8.5 10.8 8.7 11C8.8 11.1 9 11.2001 9.2 11.2001C9.5 11.2001 9.70001 11.1 9.90001 10.8C10 10.4 10.1 10 10.1 9.40002ZM14.9 7.80005L9.40001 16.7001C9.30001 16.9001 9.10001 17.1 8.90001 17.1C8.80001 17.1 8.70001 17.1 8.60001 17C8.50001 16.9 8.40001 16.8001 8.40001 16.7001C8.40001 16.6001 8.4 16.5 8.5 16.4L14 7.5C14.1 7.3 14.2 7.19998 14.3 7.09998C14.4 6.99998 14.5 7 14.6 7C14.7 7 14.8 6.99998 14.9 7.09998C15 7.19998 15 7.30002 15 7.40002C15.2 7.30002 15.1 7.50005 14.9 7.80005ZM16.6 14.2001C16.6 15.0001 16.4 15.7 16 16.1C15.6 16.5 15.1 16.7001 14.5 16.7001C14.1 16.7001 13.7 16.6 13.4 16.4C13.1 16.2 12.8 16 12.7 15.6C12.5 15.2 12.4 14.8001 12.4 14.2001C12.4 13.3001 12.6 12.7 12.9 12.3C13.2 11.9 13.7 11.7001 14.5 11.7001C14.8 11.7001 15.1 11.8 15.4 11.9C15.7 12 15.9 12.2 16.1 12.4C16.3 12.6 16.4 12.9001 16.5 13.2001C16.6 13.4001 16.6 13.8001 16.6 14.2001ZM15.4 14.1C15.4 13.5 15.3 13.1 15.2 12.9C15.1 12.6 14.9 12.5 14.5 12.5C14.3 12.5 14.1 12.6001 14 12.7001C13.9 12.8001 13.8 13.0001 13.7 13.2001C13.6 13.4001 13.6 13.8 13.6 14.1C13.6 14.7 13.7 15.1 13.8 15.4C13.9 15.7 14.1 15.8 14.5 15.8C14.8 15.8 15 15.7 15.2 15.4C15.3 15.2 15.4 14.7 15.4 14.1Z" fill="currentColor"></path>
																						</svg>
																					</span>Currency
																				</div>
																			</td>
																			<td className="fw-bold text-end">{currencyCode}</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
												<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
													<div className="card card-flush flex-equal">
														<div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
															<img src="/assets/media/icons/duotune/ecommerce/ecm001.svg" className="w-125px" alt="Location Icon" />
														</div>
														<div className="card-header">
															<div className="card-title">
																<h2>Billing Address</h2>
															</div>
														</div>
														<div className="card-body py-3">
															<div className="address-info">
																{
																	billingAddress.address_line1 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {billingAddress.address_line1}
																	</div>
																}
																{
																	billingAddress.address_line2 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {billingAddress.address_line2}
																	</div>
																}
																<div className="location">
																	<i className="fas fa-map-marker-alt"></i> {billingAddress.city}, {billingAddress.state}
																</div>
																<div className="pincode">
																	<i className="fas fa-thumbtack"></i> {billingAddress.pincode}
																</div>
																<div className="country">
																	<i className="fas fa-globe"></i> {billingAddress.country}
																</div>
															</div>
														</div>
													</div>
													<div className="card card-flush flex-equal">
														<div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
															<img src="/assets/media/icons/duotune/ecommerce/ecm006.svg" className="w-125px" alt="Location Icon" />
														</div>
														<div className="card-header">
															<div className="card-title">
																<h2>Shipping Address</h2>
															</div>
														</div>
														<div className="card-body py-3">
															<div className="address-info">
																{
																	shippingAddress.address_line1 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {shippingAddress.address_line1}
																	</div>
																}
																{
																	shippingAddress.address_line2 &&
																	<div className="address">
																		<i className="fa-solid fa-location-crosshairs"></i> {shippingAddress.address_line2}
																	</div>
																}
																<div className="location">
																	<i className="fas fa-map-marker-alt"></i> {shippingAddress.city}, {shippingAddress.state}
																</div>
																<div className="pincode">
																	<i className="fas fa-thumbtack"></i> {shippingAddress.pincode}
																</div>
																<div className="country">
																	<i className="fas fa-globe"></i> {shippingAddress.country}
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="card">
													<div className="card-header align-items-center py-5 gap-2 gap-md-5">
														<div className="card-title">
															<h2>Ordered Items</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="d-flex justify-content-between flex-column">
															<div className="table-responsive border-bottom mb-9">
																<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																	<thead>
																		<tr className="border-bottom fs-6 fw-bold text-gray-700">
																			<th className="min-w-100px">Products</th>
																			<th className="min-w-70px">Rate<br />(Tax Exclusive)</th>
																			<th className="min-w-70px">Rate<br />(Tax Inclusive)</th>
																			<th className="min-w-80px">QTY</th>
																			<th className="min-w-70px">Total Amt.<br />Excl.</th>
																			<th className="min-w-70px">Tax Percentage</th>
																			<th className="min-w-70px">Tax Amount</th>
																			<th className="min-w-100px text-end pb-2">Total</th>
																		</tr>
																	</thead>
																	<tbody className="fw-semibold text-gray-600">
																		{itemDetails.map((Obj, index) => {
																			return (
																				<tr key={index}>
																					<td>
																						<div className="fw-bold">
																							<div className="fw-bold">{Obj.item_name}</div>
																							{Obj.tax_code !== "" && <span className="badge badge-light-primary me-2">{Obj.tax_code_type}: {Obj.tax_code}</span>}
																						</div>
																					</td>
																					<td>{currencySymbol} {Math.round(Obj.TAX_Exclusive_Price * decimalPlace) / decimalPlace}</td>
																					<td>{currencySymbol} {Math.round(Obj.TAX_Inclusive_Price * decimalPlace) / decimalPlace}</td>
																					<td>{Obj.quantity}</td>
																					<td>{currencySymbol} {Math.round((Obj.quantity * Obj.rate) * decimalPlace) / decimalPlace}</td>
																					{Obj.taxable === 1 ? <td><div className="ms-5"><div>{Obj.tax_group_name} ({Obj.tax_group_percentage}%)</div><div className="mb-8">
																						{Obj.tax_group_details.map((Obj1, index1) => {
																							return (
																								<span key={index1} className="badge badge-light-primary me-2">{Obj1.tax_name} ({Obj1.tax_percentage}%)</span>
																							)
																						})}
																					</div></div></td> : <td>---</td>}
																					<td>{currencySymbol} {Math.round((Obj.taxable === 0 ? 0 : (Obj.quantity * Obj.rate) * (Obj.tax_group_percentage / 100)) * decimalPlace) / decimalPlace}</td>
																					<td className="text-end">{currencySymbol} {Math.round(((Obj.quantity * Obj.rate) + (Obj.taxable === 0 ? 0 : (Obj.quantity * Obj.rate) * (Obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace}</td>
																				</tr>
																			)
																		})}

																	</tbody>
																</table>
															</div>
															<div className="d-flex flex-wrap justify-content-between">
																<div className="d-flex flex-column mw-md-300px">
																	<div className="fw-semibold fs-5 mb-3 text-dark00">Tax Calculation</div>
																	{
																		taxDetails.map((obj, index) => {
																			return (
																				<div key={index} className="d-flex flex-stack text-gray-800 mb-3 fs-6">
																					<div className="fw-semibold pe-5">{obj.tax_name} ({obj.tax_percentage}%):</div>
																					<div className="text-end">{currencySymbol} {obj.tax_amount}</div>
																				</div>
																			)
																		})
																	}
																</div>

																<div className="d-flex justify-content-end">
																	<div className="mw-300px">
																		<div className="d-flex flex-stack mb-3">
																			<div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
																			<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {subTotal}</div>
																		</div>
																		<div className="d-flex flex-stack mb-3">
																			<div className="fw-semibold pe-10 text-gray-600 fs-7">{adjustmentText}</div>
																			<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol} {adjustmentValue}</div>
																		</div>
																		<div className="d-flex flex-stack">
																			<div className="fw-semibold pe-10 text-gray-600 fs-7">Total</div>
																			<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {purchaseOrderDetails[0].grand_total}</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="card">
													<div className="card-header">
														<div className="card-title">
															<h2>Additional Information</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="table-responsive">
															<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																<tbody className="fw-semibold text-gray-600">
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Term
																			</div>
																		</td>
																		<td className="fw-bold text-end">{purchaseOrderDetails[0].term == null ? "No term period provided" : (purchaseOrderDetails[0].term.term_name + "---" + purchaseOrderDetails[0].term.term_days + " days")}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Subject
																			</div>
																		</td>
																		<td className="fw-bold text-end">{purchaseOrderDetails[0].subject !== "" ? purchaseOrderDetails[0].subject : "No Subject added to view"}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Customer Notes
																			</div>
																		</td>
																		<td className="fw-bold text-end">{purchaseOrderDetails[0].customer_notes !== "" ? purchaseOrderDetails[0].customer_notes : "No Notes Provided"}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Terms and Conditions
																			</div>
																		</td>
																		<td className="fw-bold text-end">{purchaseOrderDetails[0].terms_and_conditions !== "" ? purchaseOrderDetails[0].terms_and_conditions : "No Terms and Conditions Added"}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Sales Managers
																			</div>
																		</td>
																		<td className="fw-bold text-end">
																			{
																				purchaseOrderDetails[0].sales_manager.length === 0 ? "No Sales Manager assigned" :
																					purchaseOrderDetails[0].sales_manager.map((x, i) => {
																						return (
																							<Tooltip key={i} title={<div className='fs-2'>{x.sales_manager_email}</div>} >
																								<span className='badge badge-info mx-2 fs-4'>{x.sales_manager_name}</span>
																							</Tooltip>
																						)
																					})
																			}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div className="tab-pane fade" id="purchase_order_history" role="tab-panel">
											<div className="d-flex flex-column gap-7 gap-lg-10">
												<div className="card">
													<div className="card-header">
														<div className="card-title">
															<h2>Purchase Order History</h2>
														</div>
													</div>

													<div className="card-body py-4">
														{purchaseOrderHistoryDetails.length > 0 &&
															<div className="table-responsive">
																<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																	<thead>
																		<tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
																			<th className="min-w-100px">Date Added</th>
																			<th className="min-w-175px">Comment</th>
																			<th className="min-w-70px">Purchase Order Status</th>
																		</tr>
																	</thead>
																	<tbody className="fw-semibold text-gray-600">
																		{purchaseOrderHistoryDetails.map((Obj, index) => {
																			return (
																				<tr key={index}>
																					<td>{moment(Obj.timestamp).format("ll")}</td>
																					<td>{Obj.operation_message}</td>
																					<td>
																						<StatusBadge status={Obj.purchase_order_status} entity={"PURCHASE_ORDER"} />
																					</td>
																				</tr>
																			)
																		})}
																	</tbody>
																</table>
															</div>}
													</div>
												</div>
											</div>
										</div>

										<div className="tab-pane fade" id="associated_gr" role="tab-panel">
											<div className='row gx-0 my-10'>
												{
													receiveGoodsRecords.length === 0 ? <div className='fs-1 p-20 text-center text-gray-600'>No Goods Received for this Purchase Order yet</div> :
														receiveGoodsRecords.map((obj, i) => {
															return (
																<div className="col-md-6 mb-6 px-2" key={i}>
																	<div className="card bg-white">
																		<div className="card-body">
																			<div className="d-flex align-items-center justify-content-between mb-8">
																				<div>
																					<span className="fs-2 fw-bold me-5">{obj.gr_number}</span>
																					<span className="fs-2 me-2">STATUS:</span>
																					<span className='fs-2' style={{ color: (obj.add_status === "GOODS_RECEIVED_FINALIZED") ? "green" : ((obj.add_status === "GOODS_RECEIVED_PENDING_APPROVAL") || (obj.add_status === "GOODS_RECEIVED_DRAFT")) ? "orange" : (obj.add_status === "GOODS_RECEIVED_CANCELLED") ? "red" : "red" }} >
																						{obj.add_status.replace("GOODS_RECEIVED", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
																					</span>
																				</div>

																				<button className="btn btn-sm btn-primary px-2 py-1"
																					onClick={() => {
																						let t = receiveGoodsRecords.map((x) => { return { ...x } })
																						t[i].showReceiveGoodsDetails = !t[i].showReceiveGoodsDetails
																						setReceiveGoodsRecords(t)
																					}}
																				>
																					{obj.showReceiveGoodsDetails ? <svg height="28" viewBox="0 -960 960 960" width="28"><path d="m283-345-43-43 240-240 240 239-43 43-197-197-197 198Z" /></svg> : <svg height="28" viewBox="0 -960 960 960" width="28"><path d="M480-345 240-585l43-43 197 198 197-197 43 43-240 239Z" /></svg>}
																				</button>
																			</div>
																			{
																				obj.showReceiveGoodsDetails &&
																				<>
																					<div className="d-flex justify-content-between align-items-center mb-3">
																						<span className="fw-bold fs-5 text-primary">User</span>
																						<span className="fs-5 fw-semibold">{obj.user_type} - {obj.doc_user_name}</span>
																					</div>
																					<div className="d-flex justify-content-between align-items-center mb-3">
																						<span className="fw-bold fs-5 text-primary">Branch</span>
																						<span className="fs-5 fw-semibold">{obj.branch_name}</span>
																					</div>
																					<div className="d-flex justify-content-between align-items-center mb-3">
																						<span className="fw-bold fs-5 text-primary">Warehouse</span>
																						<span className="fs-5 fw-semibold">{obj.warehouse_name}</span>
																					</div>
																					<div className="d-flex justify-content-between align-items-center mb-3">
																						<span className="fw-bold fs-5 text-primary">Created On</span>
																						<span className="fs-5 fw-semibold">{moment(obj.add_date_time).format("lll")}</span>
																					</div>
																					{
																						obj.associated_bill !== null &&
																						<div className="d-flex justify-content-between align-items-center mb-3">
																							<span className="fw-bold fs-5 text-primary">Associated Bill</span>
																							<span className="fs-5 fw-semibold text-primary cursor-pointer"><Link to={`/view-bill/${obj.associated_bill.bill_id}`}>{obj.associated_bill.bill_number}</Link></span>
																						</div>
																					}
																					<div className="mb-3">
																						<span className="fw-bold fs-5 text-primary">Received Items</span>
																						<div className='mx-10'>
																							{
																								obj.item_array.map((x, i) => {
																									return (
																										<div key={i} className="fs-5 fw-semibold d-flex justify-content-between">
																											<span>{x.item_name}</span>
																											<span>{x.received_qty} units</span>
																										</div>
																									)
																								})
																							}
																						</div>
																					</div>
																				</>
																			}

																		</div>
																	</div>
																</div>

															)
														})
												}
											</div>
										</div>
									</div>
								</div>

							</>
							: state === 2 ?
								<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
								:
								<PageError />
						}
					</div>
				</div>
			</div>
		</>);

};

export default ViewPurchaseOrder;
