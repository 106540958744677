import React, { useState, useEffect} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import Select, { components } from 'react-select';
import { Modal } from 'react-bootstrap';

import { getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import formatNumber from '../common_component/formatNumber.js';
import PageError from '../common_component/PageError.js';
import { useModal } from "../providers/modal-provider.js";

export const CustomerWarehouseProcessing = (props) => {
    const [state, setstate] = useState(2);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [warehouseProcessingDetails, setWarehouseProcessingDetails] = useState(null)
    const [warehouseItemArray, setWarehouseItemArray] = useState([])

    const [serialOptions, setSerialOptions] = useState([])
    const [batchOptions, setBatchOptions] = useState([])
    const [itemDataApiState, setItemDataApiState] = useState(false)

    const [itemCountArray, setItemCountArray] = useState([])
    const [itemCountApiState, setItemCountApiState] = useState(false)

    const [submitButtonState, setSubmitButtonState] = useState(false)

    const [availableCountModalShow, setAvailableCountModalShow] = useState(false)

    const [customerInvoiceDetails, setCustomerInvoiceDetails] = useState([]);
    const [deliveryChallanList, setDeliveryChallanList] = useState([])

    const [negativeStock, setNegativeStock] = useState(false)
    const [stockAdditionWarehouseProcessing, setStockAdditionWarehouseProcessing] = useState(false)

    const [Item_details, setItem_details] = useState([]);
    const [Tax_details, setTax_details] = useState([]);
    const [Billing_address, setBilling_address] = useState({});
    const [Shipping_address, setShipping_address] = useState({});
    const [GST_Details, setGST_Details] = useState({});

    const [invoiceCurrencyDetails, setInvoiceCurrencyDetails] = useState(null)

    const [subTotal, setSubTotal] = useState(0)

    const [Adjustment_value, setAdjustment_value] = useState("");
    const [Adjustment_text, setAdjustment_text] = useState("");

    
    var page_name = "customer-warehouse-processing";

    const { openModal, setModalView, setModalData } = useModal()
    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    async function getInvoiceDetails(customer_invoice_id) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-invoice-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_invoice_id": customer_invoice_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);

        if (body.operation === "success") {
            setCustomerInvoiceDetails(body.data[0]);

            setInvoiceCurrencyDetails(body.data[0].invoice_currency)

            setAdjustment_value(body.data[0].adjustment.value);
            setAdjustment_text(body.data[0].adjustment.text);

            let temp = body.data[0].item_array;
            let arr = [];
            temp.map((obj, index) => {
                if (obj.taxable == 0) {
                    obj.taxable_amount = 0;
                }
                else {
                    obj.total_taxable_amount = (((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100));
                    obj.taxable_amount = Number(obj.rate) * obj.tax_group_percentage / 100;
                }

                if (obj.tax_calculation_method == "TAX_Exclusive") {
                    obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
                    obj.TAX_Exclusive_Price = obj.rate;
                }
                else {
                    obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
                    obj.TAX_Exclusive_Price = obj.rate;
                }

                obj.total_tax = obj.tax_group_details == null ? 0 : obj.tax_group_details.reduce((p, o) => { return p + Number(o.tax_percentage) }, 0);
                if (obj.tax_group_details != null) {
                    obj.tax_group_details.map((obj1, index1) => {
                        if (arr.find(x => x.tax_id == obj1.tax_id)) {
                            let p = arr.find(x => x.tax_id == obj1.tax_id);
                            p.tax_amount = p.tax_amount + (Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax));
                        }
                        else {
                            let json = {};
                            json.tax_id = obj1.tax_id;
                            json.tax_name = obj1.tax_name;
                            json.tax_percentage = obj1.tax_percentage;
                            json.tax_amount = (Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax));
                            arr.push(json);
                        }
                    });
                }
            })
            setItem_details(temp);
            setTax_details(arr);
            setSubTotal(body.data[0].item_array.reduce((p, o) => { return (p + (((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.taxable === 0 ? 0 : ((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (o.tax_group_percentage / 100)))); }, 0));
            setBilling_address(body.data[0].billing_address);
            setShipping_address(body.data[0].shipping_address);
            setGST_Details(body.data[0].gstin_details);
        }
    }

    async function getWarehouseProcessingDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-warehouse-processing-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "warehouse_processing_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);
        if (body.operation === "success") {
            setWarehouseProcessingDetails(body.data);

            let t = body.data.item_array;
            // console.log(t);
            await Promise.all(
                t.map(async x => {
                    if (x.item_data) {
                        if (x.item_stock_type === "Track Batches") {
                            let p = await getProductBatchDataBySearch(body.data.branch_id, body.data.warehouse_id, x.item_id, "");
                            // console.log(p);
                            x.item_data = x.item_data.map(b => ({ ...b, available_count: p.search_results.find(x => x.uid == b.uid).available_count, committed_stock_count: p.search_results.find(x => x.uid == b.uid).committed_stock_count, batch_info_popover: false }));
                        }
                    }
                    return x;
                })
            )

            setWarehouseItemArray(t)
            return body.data.customer_invoice_id
        }
        else {
            console.log("Invalid Warehouse Processing")
            return null
        }
    }

    async function getProductSerialDataBySearch(branch_id, warehouse_id, product_id, search_val) {
        setItemDataApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-serial-data-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "product_id": product_id, "warehouse_id": warehouse_id, "branch_id": branch_id, "search_val": search_val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);
        if (body.operation === "success") {
            if (body.data.type == "Track Serial Number") {
                setSerialOptions(body.data.search_results)
            }
            setItemDataApiState(false)
        }
    }

    async function getProductBatchDataBySearch(branch_id, warehouse_id, product_id, search_val) {
        setItemDataApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-batch-data-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "product_id": product_id, "warehouse_id": warehouse_id, "branch_id": branch_id, "search_val": search_val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log("batch", body);
        if (body.operation === "success") {
            if (body.data.type == "Track Batches") {
                setBatchOptions(body.data.search_results)
                setItemDataApiState(false)
                return body.data
            }
        }
    }

    async function getProductStockCountBySearch(product_id) {
        setItemCountApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-stock-count-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "product_id": product_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);
        if (body.operation === "success") {
            setItemCountApiState(false)

            let t = body.data.filter(x => ((x.branch_id == warehouseProcessingDetails.branch_id) && (x.warehouse_id == warehouseProcessingDetails.warehouse_id)))
            t = t.map(o => { return { ...o, product_name: warehouseProcessingDetails.item_array.find(x => x.item_id == o.product_id).item_name } })
            setItemCountArray(t)
        }
    }

    async function getDeliveryChallanByWarehouseProcessing() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-challan-by-warehouse-processing`, { method: 'POST', body: JSON.stringify({ "email": user.email, "warehouse_processing_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);
        if (body.operation === "success") {
            setDeliveryChallanList(body.data);
        }
    }

    async function getItemStockSettings() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-item-stock-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setNegativeStock(body.data.negative_item_stock_state);
            setStockAdditionWarehouseProcessing(body.data.stock_addition_warehouse_processing_state)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            getWarehouseProcessingDetails()
                .then(async (t) => {
                    if (t != null) {

                        await getInvoiceDetails(t);
                        await getDeliveryChallanByWarehouseProcessing();
                        await getItemStockSettings();

                        setstate(1);
                    }
                    else {
                        setstate(3)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setstate(3)
                })
        }
    }, [user, visibility, read, write])

    const validateWarehouseProcessing = () => {
        let flag = true
        for (let i = 0; i < warehouseItemArray.length; i++) {
            if (warehouseItemArray[i].item_stock_type == "Track Serial Number") {
                if (warehouseItemArray[i].item_data == null) {
                    swal("Alert", "Serial selection not valid", "warning")
                    flag = false
                }
                else if (warehouseItemArray[i].item_data.length != warehouseItemArray[i].quantity) {
                    swal("Alert", "Serial selection not valid", "warning")
                    flag = false
                }
            }
            else if (warehouseItemArray[i].item_stock_type == "Track Batches") {
                if (warehouseItemArray[i].item_data == null) {
                    swal("Alert", "Batch selection or quatity not valid", "warning")
                    flag = false
                }
                else if ((warehouseItemArray[i].item_data.reduce((p, o) => p + o.unit, 0) != warehouseItemArray[i].quantity) || warehouseItemArray[i].item_data.find(x => x.unit <= 0) || warehouseItemArray[i].item_data.find(x => x.batch_reference == null) || warehouseItemArray[i].item_data.find(x => x.uid == null)) {
                    swal("Alert", "Batch selection or quatity not valid", "warning")
                    flag = false
                }
                else if (warehouseItemArray[i].item_data.map(x => x.uid).length != new Set(warehouseItemArray[i].item_data.map(x => x.uid)).size) {
                    swal("Alert", "Duplicate Batch selection found", "warning")
                    flag = false
                }
            }

            if (!flag) {
                break
            }
        }

        return flag
    }

    const updateWarehouseProcessingSubmit = (val) => {
        var object = {}

        object.item_array = warehouseItemArray

        object.item_array.forEach(item => {
            if (item.item_stock_type === "Track Batches") {
                if (item.item_data && Array.isArray(item.item_data)) {
                    item.item_data.forEach(data => {
                        delete data.available_count;
                        delete data.committed_stock_count;
                        delete data.batch_info_popover;
                    });
                }
            }
        });

        // object.item_array = updatedWarehouseItemArray
        object.save_flag = val
        object.warehouse_processing_id = props.match.params.id
        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-customer-warehouse-processing`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Warehouse updated successfully!`, "success").then((value) => { window.location.href = `/customer-warehouse-processing-list` });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    function showScannerAlert(id) {
        if (stockAdditionWarehouseProcessing) {
            setModalData({id, branch_id: warehouseProcessingDetails.branch_id, warehouse_id: warehouseProcessingDetails.warehouse_id, page_name})
            setModalView("SERIAL_SCANNER_MODAL")
            openModal()
        } else {
            swal("Sorry", "Stock addition permission is not given!", "warning");
            return;
        }
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Customer Warehouse Processing
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                                <li className="breadcrumb-item text-muted"><span className="text-muted">Home</span></li>
                                <li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
                                <li className="breadcrumb-item text-muted">Customer Warehouse Processing List</li>
                                <li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
                                <li className="breadcrumb-item text-muted">Customer Warehouse Processing</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state == 1 ?
                            <>
                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                    <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#processing_form" aria-selected="true" role="tab">Process Form</a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#invoice_summary" aria-selected="false" role="tab" tabIndex="-1">Invoice Summary</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content">

                                        <div className="tab-pane fade active show" id="processing_form" role="tab-panel">
                                            <div className="d-flex flex-column gap-7 gap-lg-10">

                                                <div className="card card-flush py-4 flex-row-fluid">
                                                    <div className="card-header justify-content-start gap-4">
                                                        <div className="card-title fs-3">Items Processing</div>
                                                        <button className='btn btn-success btn-sm align-self-center' onClick={() => { setAvailableCountModalShow(true); getProductStockCountBySearch(warehouseProcessingDetails.item_array.map(x => x.item_id)); }}>Current Item Stock</button>
                                                    </div>

                                                    <div className="card-body pt-0">
                                                        <div className='container'>
                                                            <div className="row mb-5 gx-0">
                                                                <div className="col-2 text-start"><span className="fs-5">Type</span></div>
                                                                <div className="col-3 text-center"><span className="fs-5">Item</span></div>
                                                                <div className="col-1 text-center"><span className="fs-5">Quantity</span></div>
                                                                <div className="col-6 text-center"><span className="fs-5">Item details</span></div>
                                                            </div>
                                                            {
                                                                warehouseItemArray.map((obj, indx, arr) => {
                                                                    return (
                                                                        <div key={indx} className="row py-2 gx-0 border-bottom align-items-center">
                                                                            <div className="col-md-2 text-start">
                                                                                <span className="badge badge-primary me-2">
                                                                                    {obj.item_type === "Goods" ? "Goods" : "Service"} - {obj.item_stock_type === "Track Batches" ? "Batches" : obj.item_stock_type === "Track Serial Number" ? "Serials" : "None"}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-md-3 text-center">
                                                                                <span className='fs-4'>{obj.item_name}</span>
                                                                            </div>
                                                                            <div className="col-md-1 text-center">
                                                                                <span className='fs-4'>{obj.quantity}</span>
                                                                            </div>
                                                                            <div className="col-md-6 text-center">
                                                                                {
                                                                                    obj.item_stock_type == "Track Serial Number" ?
                                                                                        <Select
                                                                                            options={serialOptions.map(x => { return { label: x.serialName, value: x.uid } })}
                                                                                            onChange={(val) => {
                                                                                                if (val.length > obj.quantity) {
                                                                                                    swal("Alert!", "Maximum quantity reached", "warning")
                                                                                                    return
                                                                                                }
                                                                                                if ((obj.item_data != null) && (obj.item_data.length > val.length)) {
                                                                                                    let t = obj.item_data.map(x => { return { ...x } })
                                                                                                    t = t.filter(x => !val.map(a => a.value).includes(x.uid))

                                                                                                    let tc = deliveryChallanList.map(dc => dc.item_array.find(it => it.item_id == obj.item_id)).filter(x => x).map(x => x.item_data).flat()
                                                                                                    // console.log("serial", tc);
                                                                                                    if (t.find(x => tc.map(a => a.uid).includes(x.uid))) {
                                                                                                        swal("This serial cannot be removed!", "Delivery Challan has already been created for this serial", "warning")
                                                                                                        return
                                                                                                    }
                                                                                                }
                                                                                                let temp = warehouseItemArray.map(a => { return { ...a } })
                                                                                                temp[indx].item_data = val.length == 0 ? null : val.map(x => { return { serial_name: x.label, uid: x.value } })
                                                                                                setWarehouseItemArray(temp)
                                                                                            }}
                                                                                            onInputChange={(val) => { val.trim() != "" && getProductSerialDataBySearch(warehouseProcessingDetails.branch_id, warehouseProcessingDetails.warehouse_id, obj.item_id, val) }}
                                                                                            isLoading={itemDataApiState}
                                                                                            value={obj.item_data == null ? [] : obj.item_data.map(x => { return { label: x.serial_name, value: x.uid } })}
                                                                                            isMulti
                                                                                            components={{
                                                                                                MenuList: (props) => {
                                                                                                    return (
                                                                                                        <components.MenuList  {...props}>
                                                                                                            {props.children}
                                                                                                            <div className='d-flex justify-content-center'>
                                                                                                                <button className='btn btn-success rounded btn-sm my-1' onClick={() => { showScannerAlert(obj.item_id) }} >
                                                                                                                    <span className="svg-icon svg-icon-1 mx-2">
                                                                                                                        <svg width="24" height="24" mi-view-box="0 0 1024 1024" viewBox="0 0 1024 1024">
                                                                                                                            <g>
                                                                                                                                <path className="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M128 384c23.564 0 42.667-19.103 42.667-42.667v0-170.667h170.667c23.564 0 42.667-19.103 42.667-42.667s-19.103-42.667-42.667-42.667v0h-170.667c-47.128 0-85.333 38.205-85.333 85.333v0 170.667c0 23.564 19.103 42.667 42.667 42.667v0z" mi-fill="inherit" mi-stroke="inherit" mi-strokewidth="inherit" mi-strokelinejoin="inherit" mi-strokelinecap="inherit" mi-strokemiterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M128 384c23.564 0 42.667-19.103 42.667-42.667v0-170.667h170.667c23.564 0 42.667-19.103 42.667-42.667s-19.103-42.667-42.667-42.667v0h-170.667c-47.128 0-85.333 38.205-85.333 85.333v0 170.667c0 23.564 19.103 42.667 42.667 42.667v0z" fill="inherit" stroke="inherit" strokeWidth="inherit" strokeLinejoin="inherit" strokeLinecap="inherit" strokeMiterlimit="inherit" opacity="1">
                                                                                                                                </path><path className="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M853.333 85.333h-170.667c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667v0h170.667v170.667c0 23.564 19.103 42.667 42.667 42.667s42.667-19.103 42.667-42.667v0-170.667c0-47.128-38.205-85.333-85.333-85.333v0z" mi-fill="inherit" mi-stroke="inherit" mi-strokewidth="inherit" mi-strokelinejoin="inherit" mi-strokelinecap="inherit" mi-strokemiterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M853.333 85.333h-170.667c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667v0h170.667v170.667c0 23.564 19.103 42.667 42.667 42.667s42.667-19.103 42.667-42.667v0-170.667c0-47.128-38.205-85.333-85.333-85.333v0z" fill="inherit" stroke="inherit" strokeWidth="inherit" strokeLinejoin="inherit" strokeLinecap="inherit" strokeMiterlimit="inherit" opacity="1">
                                                                                                                                </path><path className="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M896 640c-23.564 0-42.667 19.103-42.667 42.667v0 170.667h-170.667c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667v0h170.667c47.128 0 85.333-38.205 85.333-85.333v0-170.667c0-23.564-19.103-42.667-42.667-42.667v0z" mi-fill="inherit" mi-stroke="inherit" mi-strokewidth="inherit" mi-strokelinejoin="inherit" mi-strokelinecap="inherit" mi-strokemiterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M896 640c-23.564 0-42.667 19.103-42.667 42.667v0 170.667h-170.667c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667v0h170.667c47.128 0 85.333-38.205 85.333-85.333v0-170.667c0-23.564-19.103-42.667-42.667-42.667v0z" fill="inherit" stroke="inherit" strokeWidth="inherit" strokeLinejoin="inherit" strokeLinecap="inherit" strokeMiterlimit="inherit" opacity="1">
                                                                                                                                </path><path className="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M341.333 853.333h-170.667v-170.667c0-23.564-19.103-42.667-42.667-42.667s-42.667 19.103-42.667 42.667v0 170.667c0 47.128 38.205 85.333 85.333 85.333v0h170.667c23.564 0 42.667-19.103 42.667-42.667s-19.103-42.667-42.667-42.667v0z" mi-fill="inherit" mi-stroke="inherit" mi-strokewidth="inherit" mi-strokelinejoin="inherit" mi-strokelinecap="inherit" mi-strokemiterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M341.333 853.333h-170.667v-170.667c0-23.564-19.103-42.667-42.667-42.667s-42.667 19.103-42.667 42.667v0 170.667c0 47.128 38.205 85.333 85.333 85.333v0h170.667c23.564 0 42.667-19.103 42.667-42.667s-19.103-42.667-42.667-42.667v0z" fill="inherit" stroke="inherit" strokeWidth="inherit" strokeLinejoin="inherit" strokeLinecap="inherit" strokeMiterlimit="inherit" opacity="1">
                                                                                                                                </path><path className="cursor-pointer " ng-repeat="path in icon.paths track by tempIds[$index]" mi-d="M896 469.333h-768c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667v0h768c23.564 0 42.667-19.103 42.667-42.667s-19.103-42.667-42.667-42.667v0z" mi-fill="inherit" mi-stroke="inherit" mi-strokewidth="inherit" mi-strokelinejoin="inherit" mi-strokelinecap="inherit" mi-strokemiterlimit="inherit" mi-opacity="1" ng-click="selectPath($index)" mi-absorb-clicks="" d="M896 469.333h-768c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667v0h768c23.564 0 42.667-19.103 42.667-42.667s-19.103-42.667-42.667-42.667v0z" fill="inherit" stroke="inherit" strokeWidth="inherit" strokeLinejoin="inherit" strokeLinecap="inherit" strokeMiterlimit="inherit" opacity="1">
                                                                                                                                </path>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </span>
                                                                                                                    Scan
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </components.MenuList >
                                                                                                    )
                                                                                                }
                                                                                            }}
                                                                                            // styles={userTheme == 'dark' ? dropdownStyleDark : {}}

                                                                                            styles={
                                                                                                {
                                                                                                    ...{
                                                                                                        multiValue: (provided, state) => {
                                                                                                            if (
                                                                                                                deliveryChallanList.map(dc => dc.item_array
                                                                                                                    .find(it => it.item_id == obj.item_id))
                                                                                                                    .filter(x => x)
                                                                                                                    .map(x => x.item_data).flat()
                                                                                                                    .some(serial => serial.uid === state.data.value)) {
                                                                                                                return { ...provided, color: 'red', backgroundColor: 'hsl(0deg 46.33% 79.75%)' };
                                                                                                            }
                                                                                                            return provided;
                                                                                                        },
                                                                                                    },
                                                                                                    ...(userTheme === 'dark' ? dropdownStyleDark : {})
                                                                                                }
                                                                                            }
                                                                                            placeholder="Search for Serial Numbers..."
                                                                                        />
                                                                                        : obj.item_stock_type == "Track Batches" ?
                                                                                            <div>
                                                                                                {
                                                                                                    obj.item_data == null ?
                                                                                                        <button
                                                                                                            className='btn btn-outline-primary btn-sm'
                                                                                                            onClick={() => {
                                                                                                                let temp = warehouseItemArray.map(a => ({ ...a }));
                                                                                                                temp[indx].item_data = [{ batch_reference: null, uid: null, unit: 0, available_count: 0, committed_stock_count: 0, batch_info_popover: false }];
                                                                                                                setWarehouseItemArray(temp);
                                                                                                            }}><i className="bi bi-plus-circle"></i> Add Batches to Proceed
                                                                                                        </button>
                                                                                                        :
                                                                                                        obj.item_data.map((b, i) => {

                                                                                                            return (
                                                                                                                <div className='d-flex align-items-center gap-1 my-1'>
                                                                                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                                                                                        <button
                                                                                                                            className='btn btn-link p-0'
                                                                                                                            onClick={() => {
                                                                                                                                let temp = warehouseItemArray.map(a => ({ ...a }));
                                                                                                                                temp[indx].item_data[i].batch_info_popover = true;
                                                                                                                                setWarehouseItemArray(temp);
                                                                                                                            }}
                                                                                                                            onBlur={() => {
                                                                                                                                let temp = warehouseItemArray.map(a => ({ ...a }));
                                                                                                                                temp[indx].item_data[i].batch_info_popover = false;
                                                                                                                                setWarehouseItemArray(temp);
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <i className="fa fa-info-circle mx-1 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                                                                                                        </button>
                                                                                                                        <div style={{
                                                                                                                            position: "absolute", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1,
                                                                                                                            opacity: warehouseItemArray[indx].item_data[i].batch_info_popover ? "1" : "0",
                                                                                                                            visibility: warehouseItemArray[indx].item_data[i].batch_info_popover ? "visible" : "hidden"
                                                                                                                        }}>
                                                                                                                            {
                                                                                                                                b.uid === null ?
                                                                                                                                    <span>Select a Product to view stock Data</span> :
                                                                                                                                    <>
                                                                                                                                        <div>
                                                                                                                                            <span className="bullet bg-info me-3"></span>
                                                                                                                                            <span>Available stock: {b.available_count}</span>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <span className="bullet bg-info me-3"></span>
                                                                                                                                            <span>Committed Stock: {b.committed_stock_count}</span>
                                                                                                                                        </div>
                                                                                                                                    </>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div style={{ flex: "5" }}>
                                                                                                                        <Select
                                                                                                                            options={batchOptions.map(x => { return { label: x.Batch_Reference, value: x.uid } })}
                                                                                                                            onChange={(val) => {
                                                                                                                                if (obj.item_data.find(x => x.uid == val.value) != undefined) {
                                                                                                                                    swal("Alert!", "Duplicate batch cannot be selected", "warning")
                                                                                                                                    return
                                                                                                                                }

                                                                                                                                let tb = deliveryChallanList.map(dc => dc.item_array.find(it => it.item_id == obj.item_id)).filter(x => x).map(x => x.item_data).flat()

                                                                                                                                if (tb.find(x => x.uid === b.uid)) {
                                                                                                                                    swal("This batch cannot be changed", "Delivery challan has already been created for this batch", "warning");
                                                                                                                                    return
                                                                                                                                }

                                                                                                                                let temp = warehouseItemArray.map(a => { return { ...a } })
                                                                                                                                temp[indx].item_data[i] = { batch_reference: val.label, uid: val.value, unit: 0, available_count: batchOptions.find(x => x.uid == val.value).available_count, committed_stock_count: batchOptions.find(x => x.uid == val.value).committed_stock_count }
                                                                                                                                setWarehouseItemArray(temp)
                                                                                                                            }}
                                                                                                                            onInputChange={(val) => { val.trim() != "" && getProductBatchDataBySearch(warehouseProcessingDetails.branch_id, warehouseProcessingDetails.warehouse_id, obj.item_id, val) }}
                                                                                                                            isLoading={itemDataApiState}
                                                                                                                            value={(b.uid === null) ? null : { label: b.batch_reference, value: b.uid }}
                                                                                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                                                            placeholder="Select Batches..."
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                    <div style={{ flex: "2" }}>
                                                                                                                        <input type="number" className="form-control form-control-solid px-2" value={b.unit.toString()}
                                                                                                                            disabled={!b.uid}
                                                                                                                            onChange={(e) => {

                                                                                                                                if (e.target.value < 0) {
                                                                                                                                    swal("Alert!", "Negative unit not allowed", "warning")
                                                                                                                                    return
                                                                                                                                }

                                                                                                                                let avail = warehouseItemArray[indx].item_data[i].available_count;
                                                                                                                                let tb = deliveryChallanList.map(dc => dc.item_array.find(it => it.item_id == obj.item_id)).filter(x => x).map(x => x.item_data).flat();

                                                                                                                                let mergedTB = Object.values(
                                                                                                                                    tb.reduce((acc, curr) => {
                                                                                                                                        const { uid, batch_reference, unit } = curr;
                                                                                                                                        acc[uid] = acc[uid] || { uid, batch_reference, unit: 0 };
                                                                                                                                        acc[uid].unit += unit;
                                                                                                                                        return acc;
                                                                                                                                    }, {})
                                                                                                                                );

                                                                                                                                const batchFound = mergedTB.find(x => x.uid === b.uid);

                                                                                                                                const totalAvailable = (batchFound ? batchFound.unit : 0) + b.committed_stock_count + b.available_count;
                                                                                                                                if (e.target.value > totalAvailable) {
                                                                                                                                    swal("Alert!", `Maximum ${totalAvailable} units available for this batch`, "warning");
                                                                                                                                    return;
                                                                                                                                }

                                                                                                                                if (batchFound && e.target.value < batchFound.unit) {
                                                                                                                                    swal("Cannot be reduced", `Delivery Challan dispacthed with ${batchFound.unit} units`, "warning");
                                                                                                                                    return;
                                                                                                                                }


                                                                                                                                if (obj.item_data.reduce((p, o, ix) => p + (ix == i ? parseInt(e.target.value) : o.unit), 0) > obj.quantity) {
                                                                                                                                    swal("Alert!", "Maximum quantity reached", "warning")
                                                                                                                                    return
                                                                                                                                }

                                                                                                                                let temp = warehouseItemArray.map(a => { return { ...a } })
                                                                                                                                temp[indx].item_data[i] = { ...temp[indx].item_data[i], unit: e.target.value == "" ? 0 : parseInt(e.target.value) }
                                                                                                                                setWarehouseItemArray(temp)
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <button className='btn btn-link btn-sm text-hover-danger' style={{ flex: "1" }} onClick={() => {
                                                                                                                        if (obj.item_stock_type === "Track Batches" && obj.item_data !== null) {
                                                                                                                            let tc = deliveryChallanList.map(dc => dc.item_array.find(it => it.item_id === obj.item_id)).filter(x => x).map(x => x.item_data).flat();
                                                                                                                            if (tc.map(a => a.uid).includes(obj.item_data[i].uid)) {
                                                                                                                                swal("This batch cannot be removed!", "Delivery Challan has already been created for this batch", "warning");
                                                                                                                                return;
                                                                                                                            }
                                                                                                                        }

                                                                                                                        let temp = warehouseItemArray.map(a => ({ ...a }));
                                                                                                                        temp[indx].item_data.splice(i, 1);
                                                                                                                        temp[indx].item_data = temp[indx].item_data.length === 0 ? null : temp[indx].item_data;
                                                                                                                        setWarehouseItemArray(temp);
                                                                                                                    }}>✖</button>

                                                                                                                    {
                                                                                                                        i == obj.item_data.length - 1 ?
                                                                                                                            <button className='btn btn-link btn-sm text-hover-primary' style={{ flex: "1" }} onClick={() => {
                                                                                                                                let temp = warehouseItemArray.map(a => { return { ...a } })
                                                                                                                                temp[indx].item_data = [...temp[indx].item_data, { batch_reference: null, uid: null, unit: 0, available_count: 0, committed_stock_count: 0, batch_info_popover: false }]
                                                                                                                                setWarehouseItemArray(temp)
                                                                                                                            }}><i className="bi bi-plus-circle px-0 text-dark "></i></button>
                                                                                                                            :
                                                                                                                            <div style={{ flex: "1" }}> </div>

                                                                                                                    }

                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                }
                                                                                            </div>
                                                                                            : <div className='text-gray-600 fs-4 text-center'>No data required for this item</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='card-footer'>
                                                        {
                                                            write &&
                                                            <button type="button" className="btn btn-lg btn-primary m-1 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { updateWarehouseProcessingSubmit(false) }}>
                                                                <span className="indicator-label">Save</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        }

                                                        {
                                                            write &&
                                                            <button type="button" className="btn btn-lg btn-info m-1 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateWarehouseProcessing() && updateWarehouseProcessingSubmit(true) }}>
                                                                <span className="indicator-label">Complete Processing</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="invoice_summary" role="tab-panel">
                                            <div className="d-flex flex-column gap-7 gap-lg-10">
                                                <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                                    <div className="card flex-equal">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Invoice Details (#{customerInvoiceDetails.customer_invoice_number})</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-4">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Status
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">
                                                                                <div style={{ position: "relative", display: "inline-block" }} >
                                                                                    <button className={
                                                                                        `py-2 me-1 rounded btn btn-sm btn-${customerInvoiceDetails.add_status === "CUSTOMER_INVOICE_PENDING_APPROVAL"
                                                                                            ? "warning"
                                                                                            : customerInvoiceDetails.add_status === "CUSTOMER_INVOICE_FINALIZED"
                                                                                                ? "success"
                                                                                                : "secondary"
                                                                                        }`
                                                                                    }>
                                                                                        {customerInvoiceDetails.add_status.replace("CUSTOMER_INVOICE", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Invoice Date
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{moment(customerInvoiceDetails.order_date).format("ll")}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                            <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                            <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Reference Id
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.reference_number !== "" ? customerInvoiceDetails.reference_number : "No reference number provided"}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Due Date
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{moment(customerInvoiceDetails.due_date).format("ll")}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card flex-equal">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>GSTIN Details</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-4">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                            <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                            <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>GST Treatment
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{GST_Details.gst_treatment}</td>
                                                                        </tr>
                                                                        {
                                                                            (GST_Details.gst_treatment !== "Consumer" && GST_Details.gst_treatment !== "Unregistered Business") &&
                                                                            <>
                                                                                <tr>
                                                                                    <td className="text-muted">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="svg-icon svg-icon-2 me-2">
                                                                                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                                    <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                                </svg>
                                                                                            </span>GSTIN
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="fw-bold text-end">{GST_Details.GstIn}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-muted">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="svg-icon svg-icon-2 me-2">
                                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                                    <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                                    <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                                </svg>
                                                                                            </span>Trade Name
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="fw-bold text-end">{GST_Details.trade_name !== "" ? GST_Details.trade_name : "No given trade name"}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="text-muted">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="svg-icon svg-icon-2 me-2">
                                                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
                                                                                                    <path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
                                                                                                    <path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
                                                                                                </svg>
                                                                                            </span>Legal Name
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="fw-bold text-end">{GST_Details.legal_name}</td>
                                                                                </tr>
                                                                            </>
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                                    <div className="card flex-equal">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Customer Details</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-4">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path>
                                                                                            <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path>
                                                                                            <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect>
                                                                                        </svg>
                                                                                    </span>Customer Name
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.customer_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                                                                                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Customer Email
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.customer_email !== "" ? customerInvoiceDetails.customer_email : "No Email-address added"}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
                                                                                            <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Additional User Email
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.additional_user_email ? customerInvoiceDetails.additional_user_email : "No Additional user added"}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card flex-equal">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Associated Details</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-4">
                                                            <div className="table-responsive">
                                                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Branch
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.branch_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                            <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Warehouse
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="currentColor"></path>
                                                                                            <path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Place Of Supply
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{customerInvoiceDetails.place_of_supply}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-muted">
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="svg-icon svg-icon-2 me-2">
                                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.3" d="M21.6 11.2L19.3 8.89998V5.59993C19.3 4.99993 18.9 4.59993 18.3 4.59993H14.9L12.6 2.3C12.2 1.9 11.6 1.9 11.2 2.3L8.9 4.59993H5.6C5 4.59993 4.6 4.99993 4.6 5.59993V8.89998L2.3 11.2C1.9 11.6 1.9 12.1999 2.3 12.5999L4.6 14.9V18.2C4.6 18.8 5 19.2 5.6 19.2H8.9L11.2 21.5C11.6 21.9 12.2 21.9 12.6 21.5L14.9 19.2H18.2C18.8 19.2 19.2 18.8 19.2 18.2V14.9L21.5 12.5999C22 12.1999 22 11.6 21.6 11.2Z" fill="currentColor"></path>
                                                                                            <path d="M11.3 9.40002C11.3 10.2 11.1 10.9 10.7 11.3C10.3 11.7 9.8 11.9 9.2 11.9C8.8 11.9 8.40001 11.8 8.10001 11.6C7.80001 11.4 7.50001 11.2 7.40001 10.8C7.20001 10.4 7.10001 10 7.10001 9.40002C7.10001 8.80002 7.20001 8.4 7.30001 8C7.40001 7.6 7.7 7.29998 8 7.09998C8.3 6.89998 8.7 6.80005 9.2 6.80005C9.5 6.80005 9.80001 6.9 10.1 7C10.4 7.1 10.6 7.3 10.8 7.5C11 7.7 11.1 8.00005 11.2 8.30005C11.3 8.60005 11.3 9.00002 11.3 9.40002ZM10.1 9.40002C10.1 8.80002 10 8.39998 9.90001 8.09998C9.80001 7.79998 9.6 7.70007 9.2 7.70007C9 7.70007 8.8 7.80002 8.7 7.90002C8.6 8.00002 8.50001 8.2 8.40001 8.5C8.40001 8.7 8.30001 9.10002 8.30001 9.40002C8.30001 9.80002 8.30001 10.1 8.40001 10.4C8.40001 10.6 8.5 10.8 8.7 11C8.8 11.1 9 11.2001 9.2 11.2001C9.5 11.2001 9.70001 11.1 9.90001 10.8C10 10.4 10.1 10 10.1 9.40002ZM14.9 7.80005L9.40001 16.7001C9.30001 16.9001 9.10001 17.1 8.90001 17.1C8.80001 17.1 8.70001 17.1 8.60001 17C8.50001 16.9 8.40001 16.8001 8.40001 16.7001C8.40001 16.6001 8.4 16.5 8.5 16.4L14 7.5C14.1 7.3 14.2 7.19998 14.3 7.09998C14.4 6.99998 14.5 7 14.6 7C14.7 7 14.8 6.99998 14.9 7.09998C15 7.19998 15 7.30002 15 7.40002C15.2 7.30002 15.1 7.50005 14.9 7.80005ZM16.6 14.2001C16.6 15.0001 16.4 15.7 16 16.1C15.6 16.5 15.1 16.7001 14.5 16.7001C14.1 16.7001 13.7 16.6 13.4 16.4C13.1 16.2 12.8 16 12.7 15.6C12.5 15.2 12.4 14.8001 12.4 14.2001C12.4 13.3001 12.6 12.7 12.9 12.3C13.2 11.9 13.7 11.7001 14.5 11.7001C14.8 11.7001 15.1 11.8 15.4 11.9C15.7 12 15.9 12.2 16.1 12.4C16.3 12.6 16.4 12.9001 16.5 13.2001C16.6 13.4001 16.6 13.8001 16.6 14.2001ZM15.4 14.1C15.4 13.5 15.3 13.1 15.2 12.9C15.1 12.6 14.9 12.5 14.5 12.5C14.3 12.5 14.1 12.6001 14 12.7001C13.9 12.8001 13.8 13.0001 13.7 13.2001C13.6 13.4001 13.6 13.8 13.6 14.1C13.6 14.7 13.7 15.1 13.8 15.4C13.9 15.7 14.1 15.8 14.5 15.8C14.8 15.8 15 15.7 15.2 15.4C15.3 15.2 15.4 14.7 15.4 14.1Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </span>Currency
                                                                                </div>
                                                                            </td>
                                                                            <td className="fw-bold text-end">{invoiceCurrencyDetails.currency_code}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                                                    <div className="card card-flush flex-equal">
                                                        <div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
                                                            <img src="/assets/media/icons/duotune/ecommerce/ecm001.svg" className="w-125px" alt="Location Icon" />
                                                        </div>
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Billing Address</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-3">
                                                            <div className="address-info">
                                                                {
                                                                    Billing_address.address_line1 &&
                                                                    <div className="address">
                                                                        <i className="fa-solid fa-location-crosshairs"></i> {Billing_address.address_line1}
                                                                    </div>
                                                                }
                                                                {
                                                                    Billing_address.address_line2 &&
                                                                    <div className="address">
                                                                        <i className="fa-solid fa-location-crosshairs"></i> {Billing_address.address_line2}
                                                                    </div>
                                                                }
                                                                <div className="location">
                                                                    <i className="fas fa-map-marker-alt"></i> {Billing_address.city}, {Billing_address.state}
                                                                </div>
                                                                <div className="pincode">
                                                                    <i className="fas fa-thumbtack"></i> {Billing_address.pincode}
                                                                </div>
                                                                <div className="country">
                                                                    <i className="fas fa-globe"></i> {Billing_address.country}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card card-flush flex-equal">
                                                        <div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
                                                            <img src="/assets/media/icons/duotune/ecommerce/ecm006.svg" className="w-125px" alt="Location Icon" />
                                                        </div>
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Shipping Address</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body py-3">
                                                            <div className="address-info">
                                                                {
                                                                    Shipping_address.address_line1 &&
                                                                    <div className="address">
                                                                        <i className="fa-solid fa-location-crosshairs"></i> {Shipping_address.address_line1}
                                                                    </div>
                                                                }
                                                                {
                                                                    Shipping_address.address_line2 &&
                                                                    <div className="address">
                                                                        <i className="fa-solid fa-location-crosshairs"></i> {Shipping_address.address_line2}
                                                                    </div>
                                                                }
                                                                <div className="location">
                                                                    <i className="fas fa-map-marker-alt"></i> {Shipping_address.city}, {Shipping_address.state}
                                                                </div>
                                                                <div className="pincode">
                                                                    <i className="fas fa-thumbtack"></i> {Shipping_address.pincode}
                                                                </div>
                                                                <div className="country">
                                                                    <i className="fas fa-globe"></i> {Shipping_address.country}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card">
                                                    <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                                        <div className="card-title">
                                                            <h2>Invocied Items</h2>
                                                        </div>
                                                    </div>
                                                    <div className="card-body py-4">
                                                        <div className="d-flex justify-content-between flex-column">
                                                            <div className="table-responsive border-bottom mb-9">
                                                                <table className="table align-middle table-row-dashed text-nowrap fs-6 gy-5 mb-0">
                                                                    <thead>
                                                                        <tr className="border-bottom fs-6 fw-bold text-gray-700">
                                                                            <th className="min-w-150px">Products</th>
                                                                            <th className="min-w-150px">Rate<br />(Tax Exclusive)</th>
                                                                            <th className="min-w-150px">Rate<br />(Tax Inclusive)</th>
                                                                            <th className="min-w-150px">QTY</th>
                                                                            <th className="min-w-150px">Discount</th>
                                                                            <th className="min-w-150px">Total Amt.<br />Excl.</th>
                                                                            <th className="min-w-150px">Tax Percentage</th>
                                                                            <th className="min-w-150px">Tax Amount</th>
                                                                            <th className="min-w-150px text-end pb-2">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="fw-semibold text-gray-600">
                                                                        {Item_details.map((Obj, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <div className="fw-bold">
                                                                                            <div className="fw-bold">{Obj.item_name}</div>
                                                                                            {Obj.tax_code !== "" && <span className="badge badge-light-primary me-2">{Obj.tax_code_type}: {Obj.tax_code}</span>}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{invoiceCurrencyDetails.currency_symbol} {formatNumber(Obj.TAX_Exclusive_Price, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</td>
                                                                                    <td>{invoiceCurrencyDetails.currency_symbol} {formatNumber(Obj.TAX_Inclusive_Price, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</td>
                                                                                    <td>{Obj.quantity}</td>
                                                                                    <td>
                                                                                        <div className="fw-bold">
                                                                                            {
                                                                                                (() => {
                                                                                                    let da = Obj.discount_type === "amount" ? Obj.discount_unit : Obj.rate * Obj.quantity * Obj.discount_unit / 100
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div className="fw-bold">
                                                                                                                {invoiceCurrencyDetails.currency_symbol} {formatNumber(da, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}
                                                                                                            </div>
                                                                                                            {Obj.discount_type !== "amount" && <span className="badge badge-light-primary me-2">{Obj.discount_unit} %</span>}
                                                                                                        </>
                                                                                                    )
                                                                                                })()
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{invoiceCurrencyDetails.currency_symbol} {formatNumber(((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)), { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</td>
                                                                                    {Obj.taxable === 1 ? <td><div className="ms-5"><div>{Obj.tax_group_name} ({Obj.tax_group_percentage}%)</div><div className="mb-8">
                                                                                        {Obj.tax_group_details.map((Obj1, index1) => {
                                                                                            return (
                                                                                                <span key={index1} className="badge badge-light-primary me-2">{Obj1.tax_name} ({Obj1.tax_percentage}%)</span>
                                                                                            )
                                                                                        })}
                                                                                    </div></div></td> : <td>---</td>}
                                                                                    <td>{invoiceCurrencyDetails.currency_symbol} {formatNumber((Obj.taxable == 0 ? 0 : ((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * (Obj.tax_group_percentage / 100)), { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</td>
                                                                                    <td className="text-end">{invoiceCurrencyDetails.currency_symbol} {formatNumber((((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) + (Obj.taxable == 0 ? 0 : ((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * (Obj.tax_group_percentage / 100))), { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</td>
                                                                                </tr>
                                                                            )
                                                                        })}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="d-flex flex-wrap justify-content-between">
                                                                <div className="d-flex flex-grow-1 flex-lg-grow-0 flex-column mw-md-300px">
                                                                    <div className="fw-semibold mb-3 text-dark00">Tax Calculation</div>
                                                                    {Tax_details.map((Obj, index) => {
                                                                        return (
                                                                            <div key={index} className="d-flex flex-stack text-gray-800 mb-3">
                                                                                <div className="fw-semibold pe-5">{Obj.tax_name} ({Obj.tax_percentage}%):</div>
                                                                                <div className="text-end">{invoiceCurrencyDetails.currency_symbol} {formatNumber(Obj.tax_amount, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>

                                                                <div className="flex-grow-1 flex-lg-grow-0 fs-5 min-w-200px">

                                                                    <div className="mb-3 d-flex justify-content-between">
                                                                        <span className="fw-semibold text-gray-600">Subtotal:</span>
                                                                        <span className="fw-bold text-gray-800">{invoiceCurrencyDetails.currency_symbol} {formatNumber(subTotal, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</span>
                                                                    </div>
                                                                    <div className="mb-3 d-flex justify-content-between">
                                                                        <span className="fw-semibold text-gray-600">Tax Percentage {customerInvoiceDetails.tax_percentage !== null && <>({customerInvoiceDetails.tax_name}-{customerInvoiceDetails.tax_percentage}%)</>}</span>
                                                                        <span className="fw-bold text-gray-800">{invoiceCurrencyDetails.currency_symbol} {formatNumber((customerInvoiceDetails.tax_category == "TDS" ? Item_details.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerInvoiceDetails.tax_id == null ? 0 : parseFloat(customerInvoiceDetails.tax_percentage)) / 100, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</span>
                                                                    </div>
                                                                    <div className="mb-3 d-flex justify-content-between">
                                                                        <span className="fw-semibold text-gray-600">{Adjustment_text}</span>
                                                                        <span className="fw-bold text-gray-800">{invoiceCurrencyDetails.currency_symbol} {formatNumber(Adjustment_value, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</span>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between">
                                                                        <span className="fw-semibold text-gray-600">Total</span>
                                                                        <span className="fw-bold text-gray-800">{invoiceCurrencyDetails.currency_symbol} {formatNumber(customerInvoiceDetails.grand_total, { ...formatNumberOptions, currencyFormat: invoiceCurrencyDetails.format, decimalPlaces: invoiceCurrencyDetails.decimal_place })}</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card">
                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2>Additional Information</h2>
                                                        </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                        <div className="table-responsive">
                                                            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                                                <tbody className="fw-semibold text-gray-600">
                                                                    <tr>
                                                                        <td className="text-muted">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                        <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>Term
                                                                            </div>
                                                                        </td>
                                                                        <td className="fw-bold text-end">{customerInvoiceDetails.term == null ? "No term period provided" : (customerInvoiceDetails.term.term_name + "---" + customerInvoiceDetails.term.term_days + " days")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-muted">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                        <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>Subject
                                                                            </div>
                                                                        </td>
                                                                        <td className="fw-bold text-end">{customerInvoiceDetails.subject !== "" ? customerInvoiceDetails.subject : "No subject added"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-muted">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                        <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>Customer Notes
                                                                            </div>
                                                                        </td>
                                                                        <td className="fw-bold text-end">{customerInvoiceDetails.customer_notes !== "" ? customerInvoiceDetails.customer_notes : "No notes added"}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="text-muted">
                                                                            <div className="d-flex align-items-center">
                                                                                <span className="svg-icon svg-icon-2 me-2">
                                                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
                                                                                        <path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
                                                                                    </svg>
                                                                                </span>Terms and Conditions
                                                                            </div>
                                                                        </td>
                                                                        <td className="fw-bold text-end">{customerInvoiceDetails.terms_and_conditions !== "" ? customerInvoiceDetails.terms_and_conditions : "No terms or conditions provided"}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </>
                            : state == 2 ?
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                </div>
                                :
                                <div className="card modal-body px-5 px-xl-20">
                                    <div className="flex-lg-row-fluid ">
                                        <div className="card card-flush my-5">

                                            <div className="card-body">
                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">This Warehouse Processing Entry is not valid</h4>
                                                            <div className="fs-6 text-gray-600"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <Modal show={availableCountModalShow} onHide={() => { setAvailableCountModalShow(false); }} centered>
                <Modal.Header>
                    <Modal.Title>Items Stock</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAvailableCountModalShow(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        {itemCountApiState ? (
                            <div className='text-center'>
                                <div className="spinner-border text-primary w-40px h-40px"></div>
                            </div>
                        ) : (
                            <>
                                {
                                    itemCountArray.length === 0 ?
                                        (
                                            <div className='text-center'>
                                                <p className="alert alert-warning mt-3 p-3">
                                                    <strong>This product does not have any stock data</strong>
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                                <div className='row'>
                                                    <div className='col-4 fs-6 fw-bold'>Product</div>
                                                    <div className='col-4 fs-6 fw-bold text-center'>Available Quantity</div>
                                                    <div className='col-4 fs-6 fw-bold text-center'>Committed Quantity</div>
                                                </div>
                                                {itemCountArray.map((x, i) => (
                                                    <div key={i} className='row'>
                                                        <div className='col-4'><span className='bullet bg-success me-3'></span>{x.product_name}</div>
                                                        <div className='col-4 text-center'>{x.available_count}</div>
                                                        <div className='col-4 text-center'>{x.committed_count}</div>
                                                    </div>
                                                ))}
                                            </>
                                        )
                                }
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success rounded" disabled={itemCountApiState} onClick={() => { getProductStockCountBySearch(warehouseProcessingDetails.item_array.map(x => x.item_id)) }}>Refresh</button>
                    <button className="btn btn-danger rounded" onClick={() => { setAvailableCountModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>
        </>);

};

export default CustomerWarehouseProcessing;
