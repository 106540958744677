import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import moment from 'moment';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import './date-range.css'
import { Pagination } from 'antd';
// import { useTheme } from "./theme-provider.js"

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import formatNumber from '../common_component/formatNumber.js';
import { ReportWidgetWrapper, ReportWidgetItem } from '../common_component/ReportWidgets.js';

function BillReport() {

    const [state, setstate] = useState(2);
    // const { theme, dropdownStyleDark } = useTheme()
    const [baseCurrency, setBaseCurrency] = useState(null)

    const [billBySupplierReportList, setBillBySupplierReportList] = useState([])
    const [billBySupplierReportApiState, setBillBySupplierReportApiState] = useState(false)

    const daterange_picker_ref = useRef()
    const apiController = useRef(new AbortController())

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)
    const [startDate, setStartDate] = useState(moment.utc().subtract(30, "days").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))

    var page_name = "bill-report";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let formatNumberOptions = {
        removeTrailingZeros: true
    }

    async function getBillBySupplierReport(cp, rpp, search_v, sd, ed) {
        try {
            if (billBySupplierReportApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setBillBySupplierReportApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-bill-by-supplier-report`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "start_date": sd, "end_date": ed, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation !== "success") throw "get Bill by Supplier Report api error"

            setBillBySupplierReportList(body.data.list)
            setListCount(body.data.count)

            setBillBySupplierReportApiState(false)
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getBaseCurrency() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        // console.log(body)
        if (body.length > 0) {
            setBaseCurrency(body[0])
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getBillBySupplierReport(currentPage, rowsPerPage, searchValue, startDate, endDate)
            let p2 = getBaseCurrency()

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state === 1) {
            getBillBySupplierReport(currentPage, rowsPerPage, searchValue, startDate, endDate)
        }
    }, [currentPage, rowsPerPage, searchValue, startDate, endDate])

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Bill by Suppliers Report</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <DateRangePickerComponent
                            ref={daterange_picker_ref}
                            allowEdit={false}
                            showClearButton={false}
                            focus={() => { daterange_picker_ref.current.show() }}
                            delayUpdate={true}
                            presets={[
                                { label: 'Today', start: new Date(), end: new Date() },
                                { label: 'Last 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() },
                                { label: 'Last 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date() },
                                { label: 'Last 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date() }
                            ]}
                            change={(e) => {
                                setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
                                setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
                            }}
                            startDate={new Date(startDate)}
                            endDate={new Date(endDate)}
                            max={new Date().toISOString().split('T')[0]}
                        />
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0">
                                    <ReportWidgetWrapper>
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-clipboard-data-fill display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"Total BILLs"}
                                            value={formatNumber(billBySupplierReportList.reduce((p, o) => p + o.bill_count, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"BILLs Net Sum"}
                                            value={baseCurrency.currency_symbol + formatNumber(billBySupplierReportList.reduce((p, o) => p + o.bill_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(billBySupplierReportList.reduce((p, o) => p + o.bill_net_total, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                        <ReportWidgetItem
                                            icon={<i className="bi bi-cash-stack display-6 me-2" style={{ color: "rgb(97 97 230 / 75%)" }}></i>}
                                            label={"BILLs Net Sum (with tax)"}
                                            value={baseCurrency.currency_symbol + formatNumber(billBySupplierReportList.reduce((p, o) => p + o.bill_net_total_with_tax, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place, shorten: true, shortenFormat: '1K, 1L, 1Cr' })}
                                            hoverValue={baseCurrency.currency_symbol + formatNumber(billBySupplierReportList.reduce((p, o) => p + o.bill_net_total_with_tax, 0), { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}
                                        />
                                    </ReportWidgetWrapper>
                                </div>
                                <div className="card-body py-3">
                                    <div className='mb-5'>
                                        <input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' />
                                    </div>

                                    <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                        {
                                            billBySupplierReportApiState ?
                                                <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                :
                                                billBySupplierReportList.length > 0 ?
                                                    <>
                                                        <div className="mb-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>

                                                        <div className='overflow-auto w-100 flex-grow-1'>
                                                            <table className="table table-row-bordered text-nowrap table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                <thead>
                                                                    <tr className="fw-bold text-muted">
                                                                        <th className="min-w-120px">Supplier Name</th>
                                                                        <th className="min-w-120px">Supplier Type</th>
                                                                        <th className="min-w-120px">No. of BILLs</th>
                                                                        <th className="min-w-120px">Net Total of all BILLs</th>
                                                                        <th className="min-w-120px">Net Total of all BILLs (with tax)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        billBySupplierReportList.map((obj) => {
                                                                            return (
                                                                                <tr key={obj._id}>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.supplier_name}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.supplier_type}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{obj.bill_count}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol}&nbsp;{formatNumber(obj.bill_net_total, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                    <td><span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">{baseCurrency.currency_symbol}&nbsp;{formatNumber(obj.bill_net_total_with_tax, { ...formatNumberOptions, currencyFormat: baseCurrency.format, decimalPlaces: baseCurrency.decimal_place })}</span></td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="mt-5 d-flex justify-content-center">
                                                            <Pagination
                                                                total={listCount}
                                                                onChange={(page, pageSize) => {
                                                                    setCurrentPage(page);
                                                                    setRowsPerPage(pageSize);
                                                                }}
                                                                showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                pageSize={rowsPerPage}
                                                                current={currentPage}
                                                                showSizeChanger={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="text-muted fs-2 my-auto text-center">No data to be shown {/* <br /> <br />
                                                            <button className='btn fs-3 btn-light-primary' onClick={() => { setFlowModalShow(true) }}>
                                                                Need Help <i className="bi bi-question-circle fs-1"></i>
                                                            </button> */}
                                                    </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError />
                    }
                </div>
            </div>
        </>
    );
}

export default BillReport;