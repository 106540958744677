import { createContext, useContext, useState } from "react"
import SerialScannerModal from "../common_component/SerialScannerModal"

const initialState = {
    displayModal: false,
    modalView: null,
    modalData: null,
}

const ModalContext = createContext(initialState)

export function ModalProvider({ children }) {
    const [displayModal, setDisplayModal] = useState(false)
    const [modalView, setModalView] = useState(null)
    const [modalData, setModalData] = useState(null)


    const openModal = () => { setDisplayModal(true) }
    const closeModal = () => { setDisplayModal(false) }

    const value = {
        displayModal,
        modalView,
        modalData,
        openModal,
        closeModal,
        setModalView,
        setModalData
    };

    return (
        <ModalContext.Provider value={value}>
            {children}
        </ModalContext.Provider>
    )
}

export const useModal = () => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error(`useModal must be used within a ModalProvider`);
    }
    return context;
};

export const ManagedModal = () => {
    const { modalView } = useModal();

    return (
        <>
            {modalView === "SERIAL_SCANNER_MODAL" && <SerialScannerModal />} {/* This modal is used to add serials for an item directly into the db after verification of duplicate serials against all serials of all items */}
        </>
    );
};