import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import "react-month-picker/css/month-picker.css";
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"

export const NotificationSettings = () => {
    const [notificationSettings, setNotificationSettings] = useState([]);

    const [selectedUserTypes, setSelectedUserTypes] = useState([]);
    const [state, setstate] = useState(2);
    const [navtype, setnavtype] = useState("sales_order");
    const [organization_list, setorganization_list] = useState([]);
    const [Configured, setConfigured] = useState([]);
    const [Userlist, setUserlist] = useState([]);
    const [RoleList, setRoleList] = useState([]);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    var page_name = "notification-settings";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const sendOptionList = [
        { label: "To", value: "To" },
        { label: "CC", value: "CC" },
        { label: "BCC", value: "BCC" }
    ]

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    async function getuserdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.length > 0) {
            var temp_dropdown = [];
            body.forEach((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.name;
                    temp_obj.value = obj.user_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setUserlist(temp_dropdown);
        }
    }

    async function getroledetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.length > 0) {
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.user_role;
                    //temp_obj.value = obj.user_role_id;
                    temp_obj.value = obj.user_role;
                    temp_dropdown.push(temp_obj);
                }
            })
            setRoleList(temp_dropdown);
        }
    }

    async function getNotificationSettings() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-notification-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();


        if (body.operation === "success" && body.data.length > 0) {
            let settings = body.data;
            console.log(settings);
            setNotificationSettings(settings);
        }
        else {
            //setstate(1);
        }
    }


    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getNotificationSettings()
            let p2 = getuserdetails()
            let p3 = getroledetails()
            Promise.all([p1, p2, p3])
                .then(() => {
                    setstate(1);
                    console.log("all api done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])

    function getLabelForCategory(categoryKey) {
        switch (categoryKey) {
            case "dealer_order":
            case "dealer_invoice":
                return "dealer";
            case "sales_order":
            case "sales_invoice":
                return "customer";
            case "purchase_order":
                return "supplier";
            case "collection":
                return "dealer";
            case "recieved_payment":
                return "end user";
            case "bill_payment":
                return "supplier";
            default:
                return capitalizeWords(categoryKey);
        }
    }

    const submitNotificationSetings = (e) => {
        e.preventDefault();

        const missingValuesRows = notificationSettings.filter((setting) => {
            return setting.additional_user.some((user) => {
                return user.user_type !== "skip" && user.user_value.length === 0;
            });
        });

        if (missingValuesRows.length > 0) {
            swal("Oops!", "Please provide values for the selected user types.", "error");
            return;
        }

        const missingSendTypeRows = notificationSettings.filter((setting) => {
            return setting.additional_user.some((user) => {
                return user.user_type !== "skip" && !user.user_send_type;
            });
        });

        if (missingSendTypeRows.length > 0) {
            swal("Oops!", "Please select a send type for the selected user types.", "error");
            return;
        }

        if (notificationSettings.some((setting) => setting.additional_user.length === 0)) {
            swal("Oops!", "There are no values provided for the new row.", "error");
            return;
        }

        var object = {
            "page_name": page_name,
            "organization_id": organization_id,
            notification_settings: notificationSettings
        };

        // console.log(object);
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/configure-notification-settings`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "notification details saved successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }

    function capitalizeWords(str) {
        return str
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Notification Settings
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            Configured.length > 0 &&
                            <>
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                    </svg>
                                    </span>
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <div className="fs-6 text-gray-700 ">{Configured.toString()} Part Not Configured yet. Configure this, Click
                                                <span className="fw-bold text-primary fs-4">Save Changes button</span>
                                            </div>
                                        </div>
                                    </div>
                                </div><br />
                            </>
                        }
                        {state == 1 ?
                            <>
                                <div className="card  pt-0 pb-15 px-3 px-xl-10">
                                    <br />
                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card mt-5 mt-xxl-8">
                                            <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
                                                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
                                                    {
                                                        notificationSettings
                                                            .filter(obj => !["dealer_order", "dealer_invoice", "collection"].includes(obj.category)) // for booksapp dealer parts and collection is not needed for further improvision need to discuss.
                                                            .map((obj, indx) => {
                                                                const isSelected = navtype === obj.category;
                                                                const tabStyle = { cursor: "pointer", padding: "0.25rem 0.75rem", backgroundColor: isSelected ? "#007bff" : "transparent", color: isSelected ? "#fff" : "#000", borderRadius: "10px", marginRight: "3px" };
                                                                return (
                                                                    <li className="nav-item" key={indx}>
                                                                        <a className="nav-link text-active-primary" style={tabStyle} onClick={(e) => { setnavtype(obj.category); }}>
                                                                            {capitalizeWords(obj.category)}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-xxl-stretch mb-5 mb-xl-10">
                                        <div className="card-body py-3">
                                            <div className="tab-content">
                                                {notificationSettings.map((obj1, indx, arr) => {
                                                    return (
                                                        <>
                                                            {navtype === obj1.category &&
                                                                <div className="tab-pane fade show active">
                                                                    <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                                                        <div className="card-header">
                                                                            <div className="card-title m-0">
                                                                                <h3 className="fw-bolder m-0">{capitalizeWords(obj1.category)} Preferences</h3>
                                                                            </div>
                                                                            <span className="badge badge-light fw-bolder my-2">

                                                                            </span>
                                                                        </div>
                                                                        <div className="card-body p-9">
                                                                            <div className="row mb-5">
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 fw-bold mb-2">Send email to created by: </label>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 mb-3"
                                                                                        onClick={() => {
                                                                                            let t = notificationSettings.map(x => { return { ...x } })
                                                                                            t[indx].send_email_to_creator = false
                                                                                            t[indx].send_email_to_creator_type = null
                                                                                            setNotificationSettings(t);
                                                                                        }}> No
                                                                                        <input type="radio" className="form-check-input ms-5" checked={!obj1.send_email_to_creator} readOnly />
                                                                                    </label>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 mb-3"
                                                                                        onClick={() => {
                                                                                            let t = notificationSettings.map(x => { return { ...x } })
                                                                                            t[indx].send_email_to_creator = true
                                                                                            t[indx].send_email_to_creator_type = "To"
                                                                                            setNotificationSettings(t);
                                                                                        }}>Yes
                                                                                        <input type="radio" className="form-check-input ms-5" checked={obj1.send_email_to_creator} readOnly />
                                                                                    </label>
                                                                                </div>

                                                                                {obj1.send_email_to_creator && (
                                                                                    <div className="col-md-3">
                                                                                        <label className="required fs-5 fw-bold mb-2">Send Option Type</label>
                                                                                        <Select
                                                                                            styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                                                                            options={sendOptionList}
                                                                                            value={obj1.send_email_to_creator_type === null ? null : { label: obj1.send_email_to_creator_type, value: obj1.send_email_to_creator_type }}
                                                                                            onChange={(val) => {
                                                                                                let t = notificationSettings.map(x => { return { ...x } })
                                                                                                t[indx].send_email_to_creator_type = val.value
                                                                                                setNotificationSettings(t);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            <div className="row mb-5">
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 fw-bold mb-2">
                                                                                        Send email to {capitalizeWords(getLabelForCategory(obj1.category))}:
                                                                                    </label>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 mb-3"
                                                                                        onClick={() => {
                                                                                            let t = notificationSettings.map(x => { return { ...x } })
                                                                                            t[indx].send_email_to_category = false
                                                                                            t[indx].send_email_to_category_type = null
                                                                                            setNotificationSettings(t);
                                                                                        }}> No
                                                                                        <input type="radio" className="form-check-input ms-5" checked={!obj1.send_email_to_category} readOnly />
                                                                                    </label>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 mb-3"
                                                                                        onClick={() => {
                                                                                            let t = notificationSettings.map(x => { return { ...x } })
                                                                                            t[indx].send_email_to_category = true
                                                                                            t[indx].send_email_to_category_type = "To"
                                                                                            setNotificationSettings(t);
                                                                                        }}>Yes
                                                                                        <input type="radio" className="form-check-input ms-5" checked={obj1.send_email_to_category} readOnly />
                                                                                    </label>
                                                                                </div>

                                                                                {obj1.send_email_to_category && (
                                                                                    <div className="col-md-3">
                                                                                        <label className="required fs-5 fw-bold mb-2">Send Option Type</label>
                                                                                        <Select
                                                                                            styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                                                                            options={sendOptionList}
                                                                                            value={obj1.send_email_to_category_type === null ? null : { label: obj1.send_email_to_category_type, value: obj1.send_email_to_category_type }}
                                                                                            onChange={(val) => {
                                                                                                let t = notificationSettings.map(x => { return { ...x } })
                                                                                                t[indx].send_email_to_category_type = val.value
                                                                                                setNotificationSettings(t);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                            <div className="row mb-5">
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 fw-bold mb-2">
                                                                                        Select Operation(s) for {capitalizeWords(getLabelForCategory(obj1.category))}:
                                                                                    </label>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 mb-3">
                                                                                        <button
                                                                                            className={`btn ${notificationSettings[indx].operation_type.includes("create") ? 'btn-primary' : 'btn-secondary'}`}
                                                                                            onClick={() => {
                                                                                                let t = [...notificationSettings];
                                                                                                if (t[indx].operation_type.includes("create")) {
                                                                                                    t[indx].operation_type = t[indx].operation_type.filter(op => op !== "create");
                                                                                                } else {
                                                                                                    t[indx].operation_type.push("create");
                                                                                                }
                                                                                                setNotificationSettings(t);
                                                                                            }}
                                                                                        >
                                                                                            Create
                                                                                        </button>
                                                                                    </label>
                                                                                </div>
                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 mb-3">
                                                                                        <button
                                                                                            className={`btn ${notificationSettings[indx].operation_type.includes("update") ? 'btn-primary' : 'btn-secondary'}`}
                                                                                            onClick={() => {
                                                                                                let t = [...notificationSettings];
                                                                                                if (t[indx].operation_type.includes("update")) {
                                                                                                    t[indx].operation_type = t[indx].operation_type.filter(op => op !== "update");
                                                                                                } else {
                                                                                                    t[indx].operation_type.push("update");
                                                                                                }
                                                                                                setNotificationSettings(t);
                                                                                            }}
                                                                                        >Update
                                                                                        </button>
                                                                                    </label>
                                                                                </div>

                                                                                <div className='col-md-3'>
                                                                                    <label className="fs-5 mb-3">
                                                                                        <button
                                                                                            className={`btn ${notificationSettings[indx].operation_type.includes("approve") ? 'btn-primary' : 'btn-secondary'}`}

                                                                                            onClick={() => {
                                                                                                let t = [...notificationSettings];
                                                                                                if (t[indx].operation_type.includes("approve")) {
                                                                                                    t[indx].operation_type = t[indx].operation_type.filter(op => op !== "approve");
                                                                                                } else {
                                                                                                    t[indx].operation_type.push("approve");
                                                                                                }
                                                                                                setNotificationSettings(t);
                                                                                            }}
                                                                                        >Approve
                                                                                        </button>
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                                                <div className="fv-row">
                                                                                    <span className="d-flex align-items-center me-2">
                                                                                        <span className="symbol symbol-50px me-6">
                                                                                            <span className="symbol-label bg-light-danger">
                                                                                                <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                                                                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                                                                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                                                                                    </svg>
                                                                                                </span>
                                                                                            </span>
                                                                                        </span>
                                                                                        <span className="d-flex flex-column">
                                                                                            <h4>Add additional user for notification: </h4>
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                            </div><br />

                                                                            {
                                                                                obj1.additional_user.map((x, i) => {
                                                                                    // console.log(x);
                                                                                    return (
                                                                                        <div className='row'>
                                                                                            <div className="col-md-4">
                                                                                                <label className="required form-label fs-6 fw-bolder text-gray-700 mb-3">User Type</label>
                                                                                                <div className="mb-5">
                                                                                                    <Select
                                                                                                        styles={userTheme === "dark" ? dropdownStyleDark : {}}
                                                                                                        options={["skip", "individual_user", "group_user"]
                                                                                                            .map((option) => ({
                                                                                                                label: option.replaceAll("_", " ").replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
                                                                                                                value: option,
                                                                                                            }))
                                                                                                            .filter((option) => {
                                                                                                                if (option.value === "individual_user") {
                                                                                                                    return !obj1.additional_user.some((prev) => prev.user_type === "individual_user");
                                                                                                                }
                                                                                                                if (option.value === "group_user") {
                                                                                                                    return !obj1.additional_user.some((prev) => prev.user_type === "group_user");
                                                                                                                }
                                                                                                                return true;
                                                                                                            })}
                                                                                                        onChange={(val) => {
                                                                                                            let t = notificationSettings.map((x) => ({ ...x }));
                                                                                                            t[indx].additional_user[i].user_type = val.value;
                                                                                                            t[indx].additional_user[i].user_value = [];
                                                                                                            t[indx].additional_user[i].user_send_type = null;
                                                                                                            setNotificationSettings(t);
                                                                                                        }}
                                                                                                        value={{ label: x.user_type.replaceAll("_", " ").replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()), value: x.user_type }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {
                                                                                                x.user_type !== "skip" &&
                                                                                                <div className="col-md-4">
                                                                                                    <label className="required form-label fs-6 fw-bolder text-gray-700 mb-3">Value</label>
                                                                                                    <div className="mb-5">
                                                                                                        <Select
                                                                                                            styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                                            isMulti
                                                                                                            options={x.user_type === "individual_user" ? Userlist : RoleList}
                                                                                                            onChange={(val) => {
                                                                                                                let t = notificationSettings.map(x => { return { ...x } })
                                                                                                                t[indx].additional_user[i].user_value = val.map(x => x.value)

                                                                                                                setSelectedUserTypes([...selectedUserTypes, "skip"]);
                                                                                                                setNotificationSettings(t);
                                                                                                            }}
                                                                                                            value={x.user_value.map(y => { return { label: x.user_type === "individual_user" ? Userlist.find(z => z.value === y).label : y, value: y } })}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                            {
                                                                                                x.user_type !== "skip" && (
                                                                                                    <div className="col-md-3">
                                                                                                        <label className="required fs-5 fw-bold mb-2">Send Option Type</label>
                                                                                                        <Select
                                                                                                            styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                                                                                            options={sendOptionList}
                                                                                                            value={x.user_send_type === null ? null : { label: x.user_send_type, value: x.user_send_type }}
                                                                                                            onChange={(val) => {
                                                                                                                let t = notificationSettings.map(x => { return { ...x } })
                                                                                                                t[indx].additional_user[i].user_send_type = val.value
                                                                                                                setNotificationSettings(t);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            {
                                                                                                obj1.additional_user.length > 1 &&
                                                                                                <div className="col-md-1">
                                                                                                    <button className="btn btn-outline-danger text-hover-danger rounded-pill mt-6" onClick={() => {
                                                                                                        let t = notificationSettings.map(x => { return { ...x } })
                                                                                                        t[indx].additional_user.splice(i, 1);
                                                                                                        setNotificationSettings(t);
                                                                                                    }}><i className="bi bi-trash"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }


                                                                            {
                                                                                obj1.additional_user.length < 2 &&
                                                                                <button type="button" className="btn btn-sm font-weight-bolder btn-light-primary" onClick={(x) => {
                                                                                    let t = notificationSettings.map(x => { return { ...x } })
                                                                                    t[indx].additional_user.push({
                                                                                        user_type: "skip",
                                                                                        user_value: [],
                                                                                        user_send_type: null,
                                                                                    })
                                                                                    setNotificationSettings(t);
                                                                                }}>
                                                                                    <i className="la la-plus"></i>new row</button>
                                                                            }

                                                                        </div>

                                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                                            <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" onClick={(e) => { submitNotificationSetings(e) }} data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState}><span className="indicator-label">Save Changes</span>
                                                                                <span className="indicator-progress">Please wait...
                                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                <br />
                                {state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2 className="fw-bolder">Notification Settings</h2>

                                                    </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                    <div className="d-flex align-items-center p-3 mb-2">
                                                    </div>
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available</h4>
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <br />
                            </div>
                        }
                    </div>
                </div>
            </div >
        </>);

};

export default NotificationSettings;
