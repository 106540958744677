import formatNumber from "./formatNumber";

let formatNumberOptions = {
	decimalPlaces: 2,
	removeTrailingZeros: true,
	shorten: true,
	shortenFormat: '1K, 1L, 1Cr'
};

export const barConfig = {
	// Common bar chart configurations (margins, colors, etc.)
	margin: { top: 30, right: 0, bottom: 80, left: 40 },
	padding: 0.5,
	borderRadius: 4,
	borderWidth: 1,
	valueScale: { type: 'linear' },
	indexScale: { type: 'band', round: true },
	colors: { scheme: 'nivo' },
	borderColor: {
		from: 'color',
		modifiers: [
			[
				'opacity',
				1
			]
		]
	},
	axisBottom: {
		tickSize: 1,
		tickPadding: 4,
		tickRotation: -45
	},
	axisLeft: {
		tickSize: 1,
		tickPadding: 4,
		tickRotation: 0,
		format: (value) => formatNumber(value, formatNumberOptions)
	},
	labelSkipWidth: 12,
	labelSkipHeight: 12,
	labelTextColor: {
		from: 'color',
		modifiers: [
			[
				'darker',
				1.6
			]
		]
	},
	legends: [
		{
			dataFrom: 'keys',
			anchor: 'bottom',
			direction: 'row',
			justify: false,
			translateX: 0,
			translateY: 80,
			itemWidth: 120,
			itemHeight: 20,
			itemsSpacing: 4,
			symbolSize: 20,
			itemDirection: 'left-to-right',
			effects: [
				{
					on: 'hover',
					style: {
						itemOpacity: 1
					}
				}
			]
		}
	],
	valueFormat: (value) => formatNumber(value, formatNumberOptions),
	theme: { text: { fontSize: 13, fontWeight: "bold" } },
	indexBy: 'key', // Customize based on data structure
	keys: ['value'], // Customize based on data structure
	// ... other bar chart configurations
};

export const lineConfig = {
	// Common line chart configurations (margins, colors, etc.)
	margin: { top: 50, right: 110, bottom: 50, left: 60 },
	xScale: { type: 'point' },
	yScale: {
		type: 'linear',
		min: 'auto',
		max: 'auto',
		stacked: true,
		reverse: false
	},
	axisLeft: {
		format: (value) => formatNumber(value, formatNumberOptions)
	},
	pointSize: 10,
	pointColor: { theme: 'background' },
	pointBorderWidth: 2,
	pointBorderColor: { from: 'serieColor' },
	pointLabel: "data.yFormatted",
	pointLabelYOffset: -12,
	enableTouchCrosshair: true,
	useMesh: true,
};

export const pieConfig = {
	// Common pie chart configurations (margin, colors, etc.)
	valueFormat: (value) => formatNumber(value, formatNumberOptions),
	margin: { top: -180, right: 20, bottom: 0, left: 20 },
	innerRadius: 0.5,
	padAngle: 0.7,
	cornerRadius: 3,
	activeOuterRadiusOffset: 8,
	borderWidth: 1,
	borderColor: {
		from: 'color',
		modifiers: [
			[
				'darker',
				0.2
			]
		]
	},
	enableArcLinkLabels: false,
	legends: [
		{
			anchor: 'bottom-left',
			direction: 'column',
			justify: false,
			translateX: 0,
			translateY: 0,
			itemWidth: 100,
			itemHeight: 20,
			itemsSpacing: 5,
			symbolSize: 20,
			itemDirection: 'left-to-right'
		}
	],
	theme: { text: { fontSize: 13, fontWeight: "bold" } },
	dataRadialLabel: d => d.value,
	dataRadialValue: d => d.value,
	colors: { // Define default color scheme
		scheme: 'nivo',
	},
	// ... other pie chart configurations
};
