import React from 'react'

const TableColumnHead = ({ columnKey, label, sortColumn, setSortColumn, sortValue, setSortValue }) => {
    return (
        <div
            className="d-flex align-items-center justify-content-between cursor-pointer text-hover-primary"
            onClick={() => {
                if (sortColumn === columnKey) {
                    if (sortValue === "asc") {
                        setSortValue("desc")
                    } else {
                        setSortValue("asc")
                    }
                } else {
                    setSortColumn(columnKey)
                    setSortValue("asc")
                }
            }}
        >
            {label}
            {
                sortColumn === columnKey &&
                <div className="d-flex gap-1">
                    {
                        sortValue === "asc" ?
                            <i className="bi bi-arrow-up-square text-primary fs-2"></i> :
                            <i className="bi bi-arrow-down-square text-primary fs-2"></i>
                    }
                    <span className="text-gray-600 text-hover-danger" onClick={(e) => { e.stopPropagation(); setSortColumn(null); setSortValue(null); }}>✖</span>
                </div>
            }
        </div>
    )
}

export default TableColumnHead
