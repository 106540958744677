import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from "react-bootstrap";
import { Pagination } from 'antd';
import { getCookie } from '../common_component/cookie.js';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { useAuthData } from "./auth-provider.js"
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from "../common_component/PageError.js";
import { useHistory } from "react-router-dom";

function Brands() {
    const [state, setstate] = useState(2);

    const history = useHistory()

    const [brandsList, setBrandsList] = useState([])
    const [brandsListApiState, setBrandsListApiState] = useState(false)

    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchValue, setSearchValue] = useState(null)


    const [brandId, setbrandId] = useState("");
    const [status, setstatus] = useState("");
    const [StatusModalShow, setStatusModalShow] = useState(false);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [image_location, setimage_location] = useState("");
    const [image_key, setimage_key] = useState("");

    const [addBrandModalShow, setAddBrandModalShow] = useState(false)
    const [brandName, setBrandName] = useState("")
    const [brandDescription, setBrandDescription] = useState("")
    const [brandImageFile, setBrandImageFile] = useState(null)
    const [brandSubmitButtonState, setBrandSubmitButtonState] = useState(false)

    const [editBrandModalShow, setEditBrandModalShow] = useState(false)
    const [brandIdEdit, setBrandIdEdit] = useState("")
    const [brandNameEdit, setBrandNameEdit] = useState("")
    const [brandDescriptionEdit, setBrandDescriptionEdit] = useState("")
    const [brandImageFileEdit, setBrandImageFileEdit] = useState(null)
    const [editBrandSubmitButtonState, setEditBrandSubmitButtonState] = useState(false)

    const [dropzoneModalShow, setDropzoneModalShow] = useState(false);
    const [ImageModalShow, setImageModalShow] = useState(false);
    const [Imagesrc, setImagesrc] = useState(false);
    const apiController = useRef(new AbortController())

    var page_name = "brands";
    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)
    const MAX_SIZE = 4194304;

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(name)
        return (

            <div className="preview-box">
                <img src={previewUrl} style={{ maxHeight: "80px", maxWidth: "80px", borderRadius: "4px", marginRight: "10px" }} />
                <span title={name} className="name">{name.length >= 10 ? name.slice(0, 10) + "...." : name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" onClick={remove} >
                    <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                        <g>
                            <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                        </g>
                    </svg>
                </span>}
                {
                    status === "done" &&
                    <span className="dzu-previewButton remove" onClick={remove} >
                        <svg version="1.1" width="16" height="16" viewBox="0 0 122.88 122.88">
                            <g>
                                <path className="st0" d="M1.63,97.99l36.55-36.55L1.63,24.89c-2.17-2.17-2.17-5.73,0-7.9L16.99,1.63c2.17-2.17,5.73-2.17,7.9,0 l36.55,36.55L97.99,1.63c2.17-2.17,5.73-2.17,7.9,0l15.36,15.36c2.17,2.17,2.17,5.73,0,7.9L84.7,61.44l36.55,36.55 c2.17,2.17,2.17,5.73,0,7.9l-15.36,15.36c-2.17,2.17-5.73,2.17-7.9,0L61.44,84.7l-36.55,36.55c-2.17,2.17-5.73,2.17-7.9,0 L1.63,105.89C-0.54,103.72-0.54,100.16,1.63,97.99L1.63,97.99z" />
                            </g>
                        </svg>
                    </span>
                }
            </div>
        )
    }

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    async function getBrands(cp, rpp, search_v) {
        try {
            if (brandsListApiState) {
                apiController.current.abort()
                apiController.current = new AbortController()
            }
            setBrandsListApiState(true)
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-brands`, { signal: apiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp - 1, "rows_per_page": rpp, "search_value": search_v, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            if (body.operation === "success") {
                setBrandsList(body.data.list)
                setListCount(body.data.count)
            }
            else {
                throw "brand list api error"
            }

            setBrandsListApiState(false);
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getBrands(currentPage, rowsPerPage, searchValue)

            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getBrands(currentPage, rowsPerPage, searchValue)
        }
    }, [currentPage, rowsPerPage, searchValue])

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('organization_id', organization_id);
        body.append('brand_id', brandId);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        body.append('type', "brand");
        setMainSubmitButtonState(false);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }

        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-common-image`, headers, body }

    }

    const handleChangeStatus = ({ xhr }) => {
        setMainSubmitButtonState(true);
        if (xhr) {
            xhr.onreadystatechange = () => {
                console.log(xhr);
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    console.log(result);
                    if (result && result.operation === "success") {
                        setimage_location(result.message.Location);
                        setimage_key(result.message.Key);
                        setMainSubmitButtonState(false);
                    }

                    else {
                        swal("Warning!", result.operation, "warning");
                        setMainSubmitButtonState(false);
                    }
                }
            }
        }
        else {
            setMainSubmitButtonState(false);
        }
    }

    const updateStatus = (id, status) => {
        setbrandId(id);
        setstatus(status);
        setStatusModalShow(true);
    }

    const newBrandSubmit = () => {

        if (brandName == "") {
            swal("Oops!", "Brand Name cannot be empty", "error");
            return
        }

        var object = {};

        object.brand_name = brandName
        object.brand_description = brandDescription

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setBrandSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-brand`, object, headers).then((res) => {
            setBrandSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Brand added successfully!", "success")

                handleAddBrandModalClose()
                getBrands(currentPage, rowsPerPage, searchValue)
            }
            else if (res.data.operation == "duplicate") {
                swal("Oops!", "This Brand already exists!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const newBrandImageSubmit = () => {

        var object = {};

        object.image_location = image_location
        object.image_key = image_key
        object.brand_id = brandId

        object.organization_id = organization_id;
        object.page_name = page_name;

        //console.log(object);
        setBrandSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-brand-image`, object, headers).then((res) => {
            setBrandSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Brand image saved successfully!", "success")

                setDropzoneModalShow(false);
                getBrands(currentPage, rowsPerPage, searchValue)
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleAddBrandModalClose = () => {
        setAddBrandModalShow(false)

        setBrandName("")
        setBrandDescription("")
        setBrandImageFile(null)
    }

    const editBrandInit = (obj) => {
        setEditBrandModalShow(true)
        setBrandIdEdit(obj.brand_id)
        setBrandNameEdit(obj.brand_name)
        setBrandDescriptionEdit(obj.brand_description)
    }

    const updateBrandSubmit = () => {

        if (brandNameEdit == null) {
            swal("Oops!", "Brand Name cannot be empty", "error");
            return
        }

        var object = {};

        object.brand_id = brandIdEdit
        object.brand_name = brandNameEdit
        object.brand_description = brandDescriptionEdit

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setEditBrandSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-brand`, object, headers).then((res) => {
            setEditBrandSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Brand updated successfully!", "success")

                handleEditBrandModalClose()
                getBrands(currentPage, rowsPerPage, searchValue)
            }
            else if (res.data.operation == "duplicate") {
                swal("Oops!", "This Brand already exists!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleEditBrandModalClose = () => {
        setEditBrandModalShow(false)

        setBrandIdEdit("")
        setBrandNameEdit("")
        setBrandDescriptionEdit("")
        setBrandImageFileEdit(null)
    }

    const changeBrandStatus = (id, status) => {
        var object = {};
        setMainSubmitButtonState(true)
        object.brand_id = id
        object.status = status

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-brand-status`, object, headers).then((res) => {
            setMainSubmitButtonState(false);
            setStatusModalShow(false);
            if (res.data.operation === "success") {
                swal("Great!", "Brand status changed successfully!", "success")
                getBrands(currentPage, rowsPerPage, searchValue)
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }

        })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <>
            <div>
                <div className="d-flex flex-stack">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Brands</h1>
                    <div className="d-flex align-items-center gap-2 gap-lg-3">
                        <button className='btn btn-success rounded' data-kt-indicator={state != 1 ? "on" : "off"} disabled={state != 1} onClick={() => { setAddBrandModalShow(true) }}>
                            <span className="indicator-label">
                                <span className="d-md-none">
                                    <svg width="15" height="15" viewBox="0 0 11 11" fill="none">
                                        <path d="M10.8756 6.56H6.50763V10.856H4.49163V6.56H0.123625V4.664H4.49163V0.344H6.50763V4.664H10.8756V6.56Z" fill="#fff">
                                        </path>
                                    </svg>
                                </span>
                                <span className="d-none d-md-inline">New Brand</span>
                            </span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div>
                    {
                        state === 1 ?
                            <>
                                <div className="card mb-5 mb-xl-8">
                                    <div className="card-body py-3">
                                        <div className="mb-5"><input type="text" className='form-control fw-bold border border-primary' value={searchValue || ""} onChange={(e) => { setSearchValue(e.target.value) }} placeholder='Search...' /></div>
                                        <div className="d-flex flex-column align-items-center" style={{ minHeight: "50vh" }}>
                                            {
                                                brandsListApiState ?
                                                    <div className="spinner-border text-primary w-75px h-75px my-auto"></div>
                                                    :
                                                    brandsList.length > 0 ?
                                                        <>
                                                            <div className="mb-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                            <div className='overflow-auto w-100 flex-grow-1'>
                                                                <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" style={{ minWidth: "1000px" }}>
                                                                    <thead>
                                                                        <tr className="fw-bold text-muted">
                                                                            <th className="min-w-150px text-nowrap">Brand Name</th>
                                                                            <th className="min-w-150px">Brand Description</th>
                                                                            <th className="min-w-150px">Status</th>
                                                                            <th className="min-w-50px sticky-column" style={{ backdropFilter: 'blur(10px)' }}>Actions</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            brandsList.map((obj) => {
                                                                                return (
                                                                                    <tr key={obj.brand_id}>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.brand_name}</span></td>
                                                                                        <td><span className="text-dark fw-bold d-block mb-1 fs-6">{obj.brand_description}</span></td>
                                                                                        <td><StatusBadge status={obj.add_status} entity={""} /></td>
                                                                                        <td className="sticky-column">
                                                                                            <div className="dropdown">
                                                                                                {
                                                                                                    write &&
                                                                                                    <>
                                                                                                        <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                                                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                            <li className="py-1"><span onClick={(e) => { editBrandInit(obj); }} className="dropdown-item cursor-pointer">Edit Details</span></li>
                                                                                                            <li className="py-1"><span onClick={(e) => { setImagesrc(obj.image_location); setbrandId(obj.brand_id); setDropzoneModalShow(true); }} className="dropdown-item cursor-pointer">Upload Image</span></li>
                                                                                                            <li className="py-1"><span onClick={(e) => { updateStatus(obj.brand_id, obj.add_status) }} className={`dropdown-item cursor-pointer ${obj.add_status == "Active" ? "text-danger" : "text-success"}`}>{obj.add_status == "Active" ? "Deactivate" : "Activate"}</span></li>
                                                                                                        </ul>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            
                                                            <div className="mt-5 d-flex justify-content-center">
                                                                <Pagination
                                                                    total={listCount}
                                                                    onChange={(page, pageSize) => {
                                                                        setCurrentPage(page);
                                                                        setRowsPerPage(pageSize);
                                                                    }}
                                                                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                                                                    pageSize={rowsPerPage}
                                                                    current={currentPage}
                                                                    showSizeChanger={true}
                                                                    showQuickJumper={true}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-muted fs-2 my-auto text-center">No data to be shown</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            state === 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <PageError>
                                    <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                        <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                        <div className='mt-10 text-center'>
                                            <button className='btn btn-success rounded' onClick={() => { history.go(0) }}>Reload the page</button>
                                        </div>
                                    </div>
                                </PageError>
                    }
                </div>
            </div>

            <Modal show={ImageModalShow} onHide={() => { setImageModalShow() }} size="md" centered>
                <Modal.Header>
                    <Modal.Title>Image Preview</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setImageModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <img src={Imagesrc} style={{ height: "300px", padding: '25px', marginTop: '0' }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={(e) => { setImageModalShow(false) }}>Cancel</button>
                </Modal.Footer>
            </Modal>

            <Modal show={dropzoneModalShow} onHide={() => { setDropzoneModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title>Upload Brand Image</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setDropzoneModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>
                        <div className="card card-flush flex-row-fluid p-6 pb-5 mw-100">
                            <div className="card-body text-center">
                                {Imagesrc !== null &&
                                    <img src={Imagesrc} className="rounded-3 mb-4 w-150px h-150px w-xxl-200px h-xxl-200px" alt="" />}
                                {/* <div className="mb-2">             
                                    <div className="text-center">                         
                                        <span className="fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 fs-xl-1">T-Bone Stake</span>           

                                        <span className="text-gray-500 fw-semibold d-block fs-6 mt-n1">16 mins to cook</span>              
                                    </div>
                                </div>             
                                <span className="text-success text-end fw-bold fs-1">$16.50$</span>            */}
                            </div>
                        </div>
                        <Dropzone
                            getUploadParams={getUploadParams}
                            maxSize={MAX_SIZE}
                            onChangeStatus={(event) => {
                                handleChangeStatus(event);
                            }}
                            maxFiles={1}
                            multiple={false}
                            styles={{
                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5', justifyContent: "center" },
                                inputLabelWithFiles: { margin: '20px 3%' }
                            }}
                            PreviewComponent={Preview}
                            accept="image/*"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setDropzoneModalShow(false) }}>Close</button>
                    {
                        (write && (image_key !== "" && image_location !== "")) &&
                        <button className="btn btn-success rounded" data-kt-indicator={brandSubmitButtonState ? "on" : "off"} disabled={brandSubmitButtonState} onClick={() => { newBrandImageSubmit() }}>
                            <span className="indicator-label">+ Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={addBrandModalShow} onHide={() => { handleAddBrandModalClose() }} centered>
                <Modal.Header>
                    <Modal.Title>Add Brand</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddBrandModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Brand Name</label>
                                <input type="text" className="form-control form-control-solid" value={brandName} onChange={(e) => { setBrandName(e.target.value) }} placeholder="Brand Name" />
                                <span className="form-text text-muted">Please enter Brand name.</span>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="fs-5 fw-bold mb-2">Brand Description</label>
                                <textarea className="form-control form-control-solid" rows="5" value={brandDescription} onChange={(e) => { setBrandDescription(e.target.value) }} placeholder="Brand Description" />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddBrandModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={brandSubmitButtonState ? "on" : "off"} disabled={brandSubmitButtonState} onClick={() => { newBrandSubmit() }}>
                            <span className="indicator-label">+ Add</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={editBrandModalShow} onHide={() => { handleEditBrandModalClose() }} centered>
                <Modal.Header>
                    <Modal.Title>Edit Brand</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleEditBrandModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>

                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Brand Name</label>
                                <input type="text" className="form-control form-control-solid" value={brandNameEdit} onChange={(e) => { setBrandNameEdit(e.target.value) }} placeholder="Brand Name" />
                                <span className="form-text text-muted">Please enter Brand name.</span>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12">
                                <label className="required fs-5 fw-bold mb-2">Brand Description</label>
                                <textarea className="form-control form-control-solid" rows="5" value={brandDescriptionEdit} onChange={(e) => { setBrandDescriptionEdit(e.target.value) }} placeholder="Brand Description" />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleEditBrandModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={editBrandSubmitButtonState ? "on" : "off"} disabled={editBrandSubmitButtonState} onClick={() => { updateBrandSubmit() }}>
                            <span className="indicator-label">Save Changes</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
            <Modal show={StatusModalShow} onHide={() => { setStatusModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Change Status</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setStatusModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row">
                        <div className="col-lg-12">
                            Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                            <h3>Are you sure about changing status?</h3>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={(e) => { setStatusModalShow(true); }} className="btn btn-secondary">Cancel</button>
                    {write && <form >
                        <button type="button" onClick={(e) => { changeBrandStatus(brandId, (status == "Active" ? "Deactive" : "Active")); }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Update</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
                    </form>}
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default Brands;