import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Select, { components } from 'react-select';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Dropzone from 'react-dropzone'

import country_arr from './common_arr/countries-arr.js';
import s_a from './common_arr/states-arr.js';
import currency_arr from './common_arr/currency-arr.js';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import { useTheme } from "./theme-provider.js"
import PageError from '../common_component/PageError.js';


function CreateBill() {
    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()
    const history = useHistory()

    const [creditorList, setCreditorList] = useState([])
    const [vendorList, setVendorList] = useState([])
    const [manufacturerList, setManufacturerList] = useState([])
    const [branchList, setBranchlist] = useState([])
    const [transactionSeriesList, setTransactionSeriesList] = useState([])
    const [termsList, setTermsList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [currencyExchangeRates, setCurrencyExchangeRates] = useState([])
    const [purchaseOrderList, setPurchaseOrderList] = useState([])
    const [goodsReceivedList, setGoodsReceivedList] = useState([])
    const [warehouseList, setWarehouseList] = useState([])
    const [taxGroupList, setTaxGroupList] = useState([]);
    const [taxList, setTaxList] = useState([])
    const [itemList, setItemList] = useState([])
    const [addressList, setAddressList] = useState([]);
    const [gstinList, setGstinList] = useState([]);

    const [userListApiState, setUserListApiState] = useState(false);
    const [purchaseOrderListApiState, setPurchaseOrderListApiState] = useState(false)
    const [goodsReceivedListApiState, setGoodsReceivedListApiState] = useState(false)
    const [addressApiState, setAddressApiState] = useState(false)
    const [gstApiState, setGstApiState] = useState(false)
    const [itemApiState, setItemApiState] = useState(false);

    const itemListApiController = useRef(new AbortController())
    const userListApiController = useRef(new AbortController())


    const [userType, setUserType] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [billDate, setBillDate] = useState(moment.utc().format('YYYY-MM-DD'))
    const [dueDate, setDueDate] = useState(moment.utc().add(5, "days").format('YYYY-MM-DD'))
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null)
    const [selectedGoodsReceivedEntries, setSelectedGoodsReceivedEntries] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [selectedTransactionSeries, setSelectedTransactionSeries] = useState(null)
    const [billNumber, setBillNumber] = useState("")

    const [selectedBillingAddress, setSelectedBillingAddress] = useState(null)
    const [selectedShippingAddress, setSelectedShippingAddress] = useState(null)
    const [selectedGstin, setSelectedGstin] = useState(null)

    const [organizationDetails, setOrganizationDetails] = useState(null)
    const [SupplyStateOptions, setSupplyStateOptions] = useState([])
    const [selectedSourceOfSupply, setSelectedSourceOfSupply] = useState(null)

    const [adjustment, setAdjustment] = useState({ text: "Adjustment", display: "0", value: 0 })
    const [selectedTaxCategory, setSelectedTaxCategory] = useState(null)
    const [selectedTax, setSelectedTax] = useState(null)
    const [internalNotes, setInternalNotes] = useState("")
    const [termsAndConditions, setTermsAndConditions] = useState("")
    const [uploadedDocuments, setUploadedDocuments] = useState([])

    const [subTotal, setSubTotal] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)

    const [itemArray, setItemArray] = useState([])

    const [submitButtonState, setSubmitButtonState] = useState(false);



    const [goodsReceiveModalShow, setGoodsReceiveModalShow] = useState(false)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [goodsReceivedNumber, setGoodsReceivedNumber] = useState("")
    const [goodsReceiveItemArray, setGoodsReceiveItemArray] = useState([])
    const [goodsReceiveSubmitButtonState, setGoodsReceiveSubmitButtonState] = useState(false)

    const [modalRefProductId, setModalRefProductId] = useState(null)
    const [saveButtonState, setSaveButtonState] = useState(false)
    const [addSerialModalShow, setAddSerialModalShow] = useState(false);
    const [selectedCamera, setSelectedCamera] = useState("user");
    const [serialInput, setSerialInput] = useState("")
    const [serialArray, setSerialArray] = useState([])
    const [addBatchModalShow, setAddBatchModalShow] = useState(false)
    const [batchArray, setBatchArray] = useState([])


    const [productCodeModalShow, setProductCodeModalShow] = useState(false)
    const [productCodeItemIndex, setProductCodeItemIndex] = useState(null)
    const [productCodeItemType, setProductCodeItemType] = useState(null)
    const [productCodeSearch, setProductCodeSearch] = useState("")
    const [productCodeList, setProductCodeList] = useState([])
    const [productCodeListApiState, setProductCodeListApiState] = useState(false)

    const [billLineItemsProcessingState, setBillLineItemsProcessingState] = useState(false)

    const [gstInListModalShow, setGstInListModalShow] = useState(false);

    const [AddressModalShow, setAddressModalShow] = useState(false);
    const [AddressType, setAddressType] = useState("");

    const [termsModalShow, setTermsModalShow] = useState(false)
    const [termsPanelList, setTermsPanelList] = useState([])
    const [termsSubmitButtonState, setTermsSubmitButtonState] = useState(false);

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null)
    const [currencySymbol, setCurrencySymbol] = useState("")
    const [selectedCurrencyDecimalPlace, setSelectedCurrencyDecimalPlace] = useState(null)
    const [selectedCurrencyFormat, setSelectedCurrencyFormat] = useState(null)
    const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)

    const [approvalInfo, setApprovalInfo] = useState(null);

    const unionTerritoryList = [
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Delhi",
        "Jammu and Kashmir",
        "Ladakh",
        "Lakshadweep",
        "Puducherry"
    ]

    var page_name = "create-bill";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        let state_arr = s_a.s_a[country_arr.country_arr.findIndex((obj) => obj == "India")].split("|");
        let obj_array = [];
        for (let i = 0; i < state_arr.length; i++) {
            obj_array.push({ value: state_arr[i], label: state_arr[i] });
        }
        setSupplyStateOptions(obj_array);
    }, []);

    useEffect(() => {
        setSubTotal(trimDecimalPlaces(itemArray.reduce((p, o) => { return p + (((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.tax == null ? 0 : ((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (o.tax.tax_group_full_details.tax_group_percentage / 100))) }, 0), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place))
    }, [itemArray, selectedCurrency])

    useEffect(() => {
        setGrandTotal(trimDecimalPlaces(subTotal + adjustment.value + ((selectedTaxCategory == "TDS" ? (-1 * itemArray.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0)) : subTotal) * (selectedTax == null ? 0 : parseFloat(selectedTax.tax_full_details.tax_percentage)) / 100), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place))
    }, [subTotal, adjustment, selectedTaxCategory, selectedTax, selectedCurrency])



    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setBranchlist(body.data);
            let t = body.data.find(x => x.default_branch == "yes")
            setSelectedBranch({ label: t.branch_name, value: t.branch_id })
        }
        else {
            throw "branch list api error"
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    async function getSubSeriesData(val, type) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-series-data-by-name`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_series_name": type, "transaction_series_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            if (type === "bills") {
                setBillNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
            }
            else if (type === "goods_received") {
                setGoodsReceivedNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
            }
        }
        else {
            swal({
                title: "Oops",
                text: body.message,
                icon: "warning",
                buttons: {
                    confirm: {
                        text: "Go to transaction series"
                    }
                }
            }).then((val) => {
                if (val) {
                    window.open("/transaction-series")
                }
            })

            console.log(type, "get sub series data api error")
        }
    }

    async function getItems(search_value) {
        try {
            if (itemApiState) {
                itemListApiController.current.abort()
                itemListApiController.current = new AbortController()
            }
            setItemApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-product-by-search`, { signal: itemListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "type": "Purchase", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)
            if (body.operation === "success") {
                setItemList(body.data);
                setItemApiState(false);
                return body.data
            }
        }
        catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getTaxGroupDetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if ((body.operation == "success") && (body.data.length > 0)) {
            setTaxGroupList(body.data);
        }
    }

    async function getTdsTcsTaxes() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setTaxList(body.data);
        }
        else {
            throw "tds tcs tax get api error"
        }
    }

    async function getTerms() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-invoice-terms`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setTermsList(body)
        }
    }

    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body.data)
        }
    }

    async function getCurrencyExchangeRates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyExchangeRates(body[0])
        }
    }

    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x => x.organization_id == getCookie("organization_id")))
        }
    }

    async function updateProductCode(id, type, val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/update-hsnsac-code-of-product`, { method: 'POST', body: JSON.stringify({ "email": user.email, product_id: id, product_type: type, product_code: val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation != "success") {
            throw "update product code api error"
        }
    }

    async function getProductCodeList(type, val) {
        setProductCodeListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-hsn-code`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_type": type === "Goods" ? "HSN" : "SAC", "search_key": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setProductCodeList(body.data)
            setProductCodeListApiState(false)
        }
        else {
            throw "get product code list api error"
        }
    }

    async function getCreditorBySearch(search_value) {
        try {
            if (userListApiState) {
                userListApiController.current.abort()
                userListApiController.current = new AbortController()
            }
            setUserListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-creditor-by-search`, { signal: userListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            setUserListApiState(false);
            if (body.operation === "success") {
                setCreditorList(body.data.list.filter(x => x.add_status == "Active"))
            }
            else {
                throw "creditor list api error"
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getVendorBySearch(search_value) {
        try {
            if (userListApiState) {
                userListApiController.current.abort()
                userListApiController.current = new AbortController()
            }
            setUserListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-vendor-by-search`, { signal: userListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            setUserListApiState(false);
            if (body.operation === "success") {
                setVendorList(body.data.list.filter(x => x.add_status == "Active"))
            }
            else {
                throw "vendor list api error"
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getManufacturerBySearch(search_value) {
        try {
            if (userListApiState) {
                userListApiController.current.abort()
                userListApiController.current = new AbortController()
            }
            setUserListApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-by-search`, { signal: userListApiController.current.signal, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            setUserListApiState(false);
            if (body.operation === "success") {
                setManufacturerList(body.data.list.filter(x => x.add_status == "Active"))
            }
            else {
                throw "manufacturer list api error"
            }
        } catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getCreditorAddressDetails(val) {
        setAddressApiState(true)

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-creditor-addresses`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setAddressList(body.data[0].address_details)
            body.data[0].address_details.find(x => x.address_type == "billing") && setSelectedBillingAddress(body.data[0].address_details.find(x => x.address_type == "billing" && x.default_address))
            body.data[0].address_details.find(x => x.address_type == "shipping") && setSelectedShippingAddress(body.data[0].address_details.find(x => x.address_type == "shipping" && x.default_address))
        }
        else {
            throw "creditor address details api error"
        }
        setAddressApiState(false)
    }

    async function getVendorAddressDetails(val) {
        setAddressApiState(true)

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-vendor-addresses`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setAddressList(body.data[0].address_details)
            body.data[0].address_details.find(x => x.address_type == "billing") && setSelectedBillingAddress(body.data[0].address_details.find(x => x.address_type == "billing"))
            body.data[0].address_details.find(x => x.address_type == "shipping") && setSelectedShippingAddress(body.data[0].address_details.find(x => x.address_type == "shipping"))
        }
        else {
            throw "vendor address details api error"
        }
        setAddressApiState(false)
    }

    async function getManufacturerAddressDetails(val) {
        setAddressApiState(true)

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-addresses`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setAddressList(body.data[0].address_details)
            body.data[0].address_details.find(x => x.address_type == "billing") && setSelectedBillingAddress(body.data[0].address_details.find(x => x.address_type == "billing" && x.default_address))
            body.data[0].address_details.find(x => x.address_type == "shipping") && setSelectedShippingAddress(body.data[0].address_details.find(x => x.address_type == "shipping" && x.default_address))
        }
        else {
            throw "manufacturer address details api error"
        }
        setAddressApiState(false)
    }

    async function getCreditorGstinDetails(val) {
        setGstApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-creditor-tax-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            if (body.data.length > 0) {
                let t = body.data.find(x => x.flag == "Primary")
                if (t) {
                    setSelectedGstin(t)
                    setSelectedSourceOfSupply({ label: t.place_of_supply, value: t.place_of_supply })
                }
                else {
                    let temp = body.data
                    temp.sort((a, b) => new Date(a.add_date_time) - new Date(b.add_date_time))
                    let temp2 = temp[0]
                    setSelectedGstin(temp2)
                    setSelectedSourceOfSupply({ label: temp2.place_of_supply, value: temp2.place_of_supply })
                }
            }

            setGstinList(body.data);
        }
        else {
            throw "creditor tax details api error"
        }
        setGstApiState(false)
    }

    async function getVendorGstinDetails(val) {
        setGstApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-vendor-tax-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            if (body.data.length > 0) {
                let t = body.data.find(x => x.flag == "Primary")
                if (t) {
                    setSelectedGstin(t)
                    setSelectedSourceOfSupply({ label: t.place_of_supply, value: t.place_of_supply })
                }
                else {
                    let temp = body.data
                    temp.sort((a, b) => new Date(a.add_date_time) - new Date(b.add_date_time))
                    let temp2 = temp[0]
                    setSelectedGstin(temp2)
                    setSelectedSourceOfSupply({ label: temp2.place_of_supply, value: temp2.place_of_supply })
                }
            }

            setGstinList(body.data);
        }
        else {
            throw "vendor tax details api error"
        }
        setGstApiState(false)
    }

    async function getManufacturerGstinDetails(val) {
        setGstApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-manufacturer-tax-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            if (body.data.length > 0) {
                let t = body.data.find(x => x.flag == "Primary")
                if (t) {
                    setSelectedGstin(t)
                    setSelectedSourceOfSupply({ label: t.place_of_supply, value: t.place_of_supply })
                }
                else {
                    let temp = body.data
                    temp.sort((a, b) => new Date(a.add_date_time) - new Date(b.add_date_time))
                    let temp2 = temp[0]
                    setSelectedGstin(temp2)
                    setSelectedSourceOfSupply({ label: temp2.place_of_supply, value: temp2.place_of_supply })
                }

                setGstinList(body.data);
            }
        }
        else {
            throw "manufacturer tax details api error"
        }
        setGstApiState(false)
    }

    async function getConfirmedPurchaseOrderList(supplier_id) {
        setPurchaseOrderListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-confirmed-purchase-orders-by-supplier`, { method: 'POST', body: JSON.stringify({ "email": user.email, "supplier_id": supplier_id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);

        if (body.operation === "success") {
            setPurchaseOrderList(body.data)
            setPurchaseOrderListApiState(false)
        }
    }

    async function getGoodsReceiveBySearch(supplier_id, purchase_order_id, search_val) {
        setGoodsReceivedListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gr-by-search`, { method: 'POST', body: JSON.stringify({ "email": user.email, "supplier_id": supplier_id, "purchase_order_id": purchase_order_id, "search_value": search_val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);

        setGoodsReceivedListApiState(false)
        if (body.operation === "success") {
            setGoodsReceivedList(body.data)
        }
        else {
            throw "get goods received list api error"
        }
    }

    async function getProductStockData(pid) {
        setSaveButtonState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-stock-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "product_id": pid, "search_val": "", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.operation === "success") {
            setSaveButtonState(false)
            return body.data.search_results
        }
    }

    async function getApprovalDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-specific-approval`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "key": "bill_approval", "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        });
        const body = await response.json();
        if (body.operation === "success") {
            setApprovalInfo(body.data);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getBranchDetails()
            let p2 = getTransactionSeriesList()
            let p3 = getWarehouses()
            let p4 = getTaxGroupDetails()
            let p5 = getTdsTcsTaxes()
            let p6 = getCurrencies()
            let p7 = getCurrencyExchangeRates()
            let p8 = getOrganizationDetails()
            let p9 = getItems(null)
            let p10 = getTerms()
            let p11 = getApprovalDetails()

            Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11])
                .then(async () => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if ((selectedBranch != null) && (transactionSeriesList.length > 0)) {
            let tb = branchList.find(x => x.branch_id == selectedBranch.value)
            setSelectedTransactionSeries({ label: transactionSeriesList.find(x => x.transaction_series_id == tb.default_transaction_series).series_name, value: tb.default_transaction_series })
            setBillNumber("")
            getSubSeriesData(tb.default_transaction_series, "bills")
        }
    }, [selectedBranch, transactionSeriesList])

    useEffect(() => {
        if ((organizationDetails != null) && (currencyList.length > 0)) {
            let t = currencyList.find(x => x.currency_id == organizationDetails.currency)
            setSelectedCurrency({ label: t.currency_code, value: t.currency_id, currency_details: t })
        }
    }, [organizationDetails, currencyList])


    const processBillLineItems = async () => {
        if ((selectedSourceOfSupply !== null) && (warehouseList.length > 0)) {
            setBillLineItemsProcessingState(true)

            let prev_data = itemArray.map(x => { return { ...x } })
            let new_data = []

            for (const gr of selectedGoodsReceivedEntries) {
                let w_state = warehouseList.find(x => x.wid === gr.gr_data.warehouse_id).state
                let s_state = selectedSourceOfSupply.value
                let tax_group_type
                if (w_state === s_state) {
                    if (unionTerritoryList.includes(w_state) && unionTerritoryList.includes(s_state)) {
                        tax_group_type = "INTRAUNION"
                    }
                    else {
                        tax_group_type = "INTRASTATE"
                    }
                }
                else {
                    tax_group_type = "INTERSTATE"
                }
                // console.log(tax_group_type, gr.label)

                await Promise.all(gr.gr_data.item_array.map(async item => {
                    let pd = prev_data.find(x => x.item_id === item.item_id);
                    if (!pd) {
                        pd = await getItems(item.item_id)
                        pd = pd[0]
                    }
                    else {
                        pd = pd.item
                    }

                    let t = new_data.find(x => ((x.item_id === item.item_id) && (x.tax_group_type === tax_group_type)))
                    if (t) {
                        t.gr_info.push({ gr_number: gr.gr_data.gr_number, unit: item.received_qty })
                        t.quantity += item.received_qty
                    }
                    else {
                        let pre_defined_rate = 0, taxable = false, tax = null, rate = 0

                        let g_rate = pd.price_information === null ? 0 : trimDecimalPlaces((parseFloat(pd.price_information.purchase_price)), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)
                        pre_defined_rate = g_rate

                        if (pd.tax_information[0].tax_preference == "Taxable") {
                            taxable = true
                        }
                        else {
                            taxable = false
                            tax = null
                        }

                        let tp = pd.tax_information[0].tax_details
                        if (pd.tax_information[0].tax_preference == "Taxable" && tp) {
                            let applied_tax
                            if (tax_group_type === "INTERSTATE") {
                                applied_tax = tp.Inter_State_Tax_Percentage
                                tax = { label: `(${tp.Inter_State_Tax_Percentage}%) ${tp.Inter_State_Tax_Name}`, value: tp.Inter_State_Tax, tax_group_full_details: taxGroupList.find(x => x.tax_group_id === tp.Inter_State_Tax) }
                            }
                            else if (tax_group_type === "INTRASTATE") {
                                applied_tax = tp.Intra_State_Tax_Percentage
                                tax = { label: `(${tp.Intra_State_Tax_Percentage}%) ${tp.Intra_State_Tax_Name}`, value: tp.Intra_State_Tax, tax_group_full_details: taxGroupList.find(x => x.tax_group_id === tp.Intra_State_Tax) }
                            }

                            if (pd.tax_information[0].tax_calculation_method === "TAX_Inclusive") {
                                rate = trimDecimalPlaces((g_rate / (1 + (applied_tax / 100))), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)
                            }
                            else if (pd.tax_information[0].tax_calculation_method === "TAX_Exclusive") {
                                rate = trimDecimalPlaces((g_rate), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)
                            }
                        }
                        else {
                            rate = trimDecimalPlaces((g_rate), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)
                        }

                        let tobj = {
                            item_id: pd.pid,
                            item_name: pd.product_name,
                            item: pd,
                            item_type: pd.product_type,
                            item_code: pd.product_type == "Goods" ? pd.hsn_code : pd.sac_code,
                            item_popover: false,
                            item_popover_input: "",

                            item_info_popover: false,
                            gr_info: [{ gr_number: gr.gr_data.gr_number, unit: item.received_qty }],

                            quantity: item.received_qty,
                            quantity_unit: pd.usage_unit,

                            pre_defined_rate: pre_defined_rate,
                            rate: rate,
                            rate_popover: false,

                            discount_unit: 0,
                            discount_type: { label: "amount", value: "amount" },

                            taxable: taxable,
                            tax_calculation_method: pd.tax_information[0].tax_calculation_method,
                            tax_group_type: tax_group_type,
                            tax: tax
                        }

                        new_data.push(tobj)
                    }
                }))
            }

            // console.log("new data", new_data)
            setItemArray(new_data)

            setBillLineItemsProcessingState(false)
        }
    }

    useEffect(() => {
        processBillLineItems()
    }, [selectedSourceOfSupply, selectedGoodsReceivedEntries, warehouseList])

    // const convertCurrencyAmount = (p_curr, amt) => {
    //     let pc_code = currencyList.find(x => x.currency_id == p_curr).currency_code

    //     let c_rate = (selectedCurrency.label == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + selectedCurrency.label]
    //     let c2_rate = (pc_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + pc_code]

    //     return Math.round(amt * (c_rate / c2_rate) * 100) / 100
    // }

    const trimDecimalPlaces = (amt, decimal_place) => {
        return Math.round(amt * Math.pow(10, decimal_place)) / Math.pow(10, decimal_place)
    }

    const validateBill = () => {
        if (userType == null) {
            swal("Oops!", "Select user type", "warning");
            return false
        }
        if (selectedUser == null) {
            swal("Oops!", "Select a " + userType, "warning");
            return false
        }
        if (billDate > dueDate) { //check more
            swal("Oops!", "Due Date must be greater than Bill Date", "warning");
            return false
        }
        if (selectedCurrency == null) {
            swal("Oops!", "Currency cannot be empty", "warning");
            return false
        }
        if (selectedGoodsReceivedEntries.length === 0) {
            swal("Oops!", "Select at least 1 Goods Receive entry", "warning");
            return false
        }
        if (selectedBranch == null) {
            swal("Oops!", "Branch cannot be empty", "warning");
            return false
        }
        if (selectedTransactionSeries == null) {
            swal("Oops!", "Transaction Series cannot be empty", "warning");
            return false
        }
        if (selectedBillingAddress == null) {
            swal("Oops!", "Billing Address cannot be empty", "warning");
            return false
        }
        if (selectedShippingAddress == null) {
            swal("Oops!", "Shipping Address cannot be empty", "warning");
            return false
        }
        if (selectedGstin == null) {
            swal("Oops!", "Gstin cannot be empty", "warning");
            return false
        }
        if (selectedSourceOfSupply == null) {
            swal("Oops!", "Source of Supply cannot be empty", "warning");
            return false
        }
        if ((selectedTaxCategory != null) && (selectedTax == null)) {
            swal("Oops!", "Select TDS or TCS tax", "warning");
            return false
        }

        let itemValidation = itemArray.find(o => o.item_id === null);
        let rateValidation = itemArray.find(o => o.rate === 0);
        let taxValidation = itemArray.find(o => ((o.taxable === true) && (o.tax === null)));

        if (itemValidation !== undefined) {
            swal("Oops!", "Invoice item cannot be empty", "warning");
            return false;
        }
        if (rateValidation !== undefined) {
            swal("Oops!", "Rate cannot be 0", "warning");
            return false;
        }
        if (taxValidation !== undefined) {
            swal("Oops!", "Select a tax for the taxable item", "warning");
            return false;
        }

        return true
    }

    const billEntrySubmit = (val) => {
        const object = new FormData()
        object.append("user_type", userType.value)
        object.append("doc_user_id", selectedUser.value)
        object.append("doc_user_name", selectedUser.label)
        object.append("doc_user_email", selectedUser.email)

        object.append("bill_date", billDate)
        object.append("due_date", dueDate)
        object.append("term", (selectedTerm == null) ? null : JSON.stringify({ term_name: selectedTerm.label, term_days: selectedTerm.value }))

        object.append("branch_id", selectedBranch.value)
        object.append("transaction_series_id", selectedTransactionSeries.value)

        object.append("billing_address", JSON.stringify(selectedBillingAddress))
        object.append("shipping_address", JSON.stringify(selectedShippingAddress))
        object.append("gstin", JSON.stringify(selectedGstin))
        object.append("gstin_number", selectedGstin.GstIn)

        object.append("source_of_supply", selectedSourceOfSupply.value)

        let org_c_code = currencyList.find(x => x.currency_id == organizationDetails.currency).currency_code

        let org_c_rate = (org_c_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + org_c_code]
        let bill_c_rate = (selectedCurrency.label == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + selectedCurrency.label]

        let exchange_rate = {
            organization: { base_curr: "USD", quote_curr: org_c_code, rate: org_c_rate },
            bill: { base_curr: "USD", quote_curr: selectedCurrency.label, rate: bill_c_rate },
        }

        let ic = selectedCurrency.currency_details
        object.append("bill_currency", JSON.stringify({ currency_id: ic.currency_id, currency_symbol: ic.currency_symbol, currency_code: ic.currency_code, format: ic.format, decimal_place: ic.decimal_place, exchange_rate: exchange_rate }))

        object.append("goods_received_id", JSON.stringify(selectedGoodsReceivedEntries.map(x => ({ gr_id: x.value, gr_number: x.label }))))

        let temp = []
        itemArray.forEach((obj) => {
            let tobj = {}
            tobj.item_id = obj.item_id
            tobj.item_name = obj.item_name
            tobj.item_type = obj.item_type
            tobj.tax_code_type = obj.item_type == "Goods" ? "HSN" : "SAC"
            tobj.tax_code = obj.item_code
            tobj.gr_info = obj.gr_info
            tobj.quantity = obj.quantity
            tobj.quantity_unit = obj.quantity_unit
            tobj.rate = obj.rate
            tobj.discount_unit = obj.discount_unit
            tobj.discount_type = obj.discount_type.value
            tobj.taxable = obj.taxable ? 1 : 0
            tobj.tax_calculation_method = obj.tax_calculation_method
            tobj.tax_group_type = obj.tax_group_type
            tobj.tax_group_id = (obj.tax == null) ? null : obj.tax.value
            tobj.tax_group_name = (obj.tax == null) ? null : obj.tax.tax_group_full_details.tax_group_name
            tobj.tax_group_percentage = (obj.tax == null) ? null : obj.tax.tax_group_full_details.tax_group_percentage
            tobj.tax_group_details = (obj.tax == null) ? null : obj.tax.tax_group_full_details.tax_group_details

            temp.push(tobj)
        })
        object.append("item_array", JSON.stringify(temp))

        let t = { text: adjustment.text, value: adjustment.value }
        object.append("adjustment", JSON.stringify(t))

        object.append("tax_category", selectedTaxCategory == null ? "" : selectedTaxCategory)
        object.append("tax_id", selectedTax == null ? "" : selectedTax.value)
        object.append("tax_name", selectedTax == null ? "" : selectedTax.tax_full_details.tax_name)
        object.append("tax_percentage", selectedTax == null ? "" : selectedTax.tax_full_details.tax_percentage)

        object.append("grand_total", grandTotal)

        object.append("internal_notes", internalNotes)
        object.append("terms_and_conditions", termsAndConditions)

        uploadedDocuments.forEach(obj => {
            object.append("data_files", obj)
        })

        object.append("save_flag", JSON.stringify(val))
        object.append("organization_id", organization_id)
        object.append("page_name", page_name)

        // for (const pair of object.entries()) {
        //     console.log(`${pair[0]}, ${pair[1]}`);
        // }

        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-bill`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Bill created as ${res.data.data.saved_ref} and saved successfully!`, "success").then(() => { history.go(0) });
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const newTermsSubmit = () => {
        let f1 = true
        termsPanelList.forEach(obj => {
            if ((obj.term_name.trim() == "") || (obj.no_of_days == "")) {
                f1 = false
            }
        })
        if (f1 == false) {
            swal("Oops!", "Term Name or No of Days cannot be empty", "warning");
            return
        }

        let temp = termsPanelList.map(obj => { return obj.term_name.trim() })
        if (temp.length != [... new Set(temp)].length) {
            swal("Oops!", "Term Name cannot be a duplicate", "warning");
            return
        }

        var object = {};

        object.terms_panel_list = termsPanelList

        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setTermsSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-invoice-terms`, object, headers).then((res) => {
            setTermsSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Terms added successfully!", "success")
                handleNewTermsModalClose()
                getTerms()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleNewTermsModalClose = () => {
        setTermsModalShow(false)
        setTermsPanelList([])
    }

    const newCurrencySubmit = () => {

        if (selectedCurrencyCode == null) {
            swal("Oops!", "Currency Code cannot be empty", "error");
            return
        }
        else if (currencySymbol == "") {
            swal("Oops!", "Currency Symbol cannot be empty", "error");
            return
        }

        var object = {};

        object.currency_code = selectedCurrencyCode.value
        object.currency_symbol = currencySymbol
        object.decimal_place = selectedCurrencyDecimalPlace == null ? null : selectedCurrencyDecimalPlace.value
        object.format = selectedCurrencyFormat == null ? null : selectedCurrencyFormat.value

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setCurrencySubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, object, headers).then((res) => {
            setCurrencySubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Currency added successfully!", "success")
                handleNewCurrencyModalClose()
                getCurrencies()
            }
            else if (res.data.operation === "same_currency_code") {
                swal("Oops!", "This Currency code is already used!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleNewCurrencyModalClose = () => {
        setCurrencyModalShow(false)
        setSelectedCurrencyCode(null)
        setCurrencySymbol("")
        setSelectedCurrencyDecimalPlace(null)
        setSelectedCurrencyFormat(null)
    }

    const validateReceivedGoods = () => {
        if (userType == null) {
            swal("Oops!", "Select an User Type", "warning")
            return false
        }
        if (selectedUser == null) {
            swal("Oops!", "Select a Supplier", "warning")
            return false
        }
        if (selectedBranch == null) {
            swal("Oops!", "Select a Branch", "warning")
            return false
        }
        if (selectedTransactionSeries == null) {
            swal("Oops!", "Select a Transaction Series", "warning")
            return false
        }
        if (selectedWarehouse == null) {
            swal("Oops!", "Select a Warehouse", "warning")
            return false
        }

        if (goodsReceiveItemArray.find(x => x.item_id == null) || goodsReceiveItemArray.length == 0) {
            swal("Oops!", "Choose a product before submitting", "warning")
            return false
        }
        if (goodsReceiveItemArray.find(x => x.received_qty <= 0)) {
            swal("Oops!", "Received Quantity has to be greater than 0", "warning")
            return false
        }

        for (let i = 0; i < goodsReceiveItemArray.length; i++) {
            if (goodsReceiveItemArray[i].item_stock_type == "Track Serial Number") {
                if ((goodsReceiveItemArray[i].item_data == null) || (goodsReceiveItemArray[i].item_data.length != goodsReceiveItemArray[i].received_qty)) {
                    swal("Oops!", "Serial Number count mismatch", "warning")
                    return false
                }
            }
            else if (goodsReceiveItemArray[i].item_stock_type == "Track Batches") {
                if ((goodsReceiveItemArray[i].item_data == null) || (goodsReceiveItemArray[i].item_data.reduce((p, o) => p + o.batch_quantity, 0) != goodsReceiveItemArray[i].received_qty)) {
                    swal("Oops!", "Batch quantity total mismatch", "warning")
                    return false
                }

                if (goodsReceiveItemArray[i].item_data.find(x => x.batch_reference == "")) {
                    swal("Oops!", "Batch Reference required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.manufacturer == "")) {
                    swal("Oops!", "Manufacturer required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.manufactured_date == "")) {
                    swal("Oops!", "Manufactured Date required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.expiry_date == "")) {
                    swal("Oops!", "Expiry Date required", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => new Date(x.expiry_date) < new Date(x.manufactured_date))) {
                    swal("Oops!", "Expiry Date must be greater than manufactured date", "warning")
                    return false
                }
                if (goodsReceiveItemArray[i].item_data.find(x => x.batch_quantity <= 0)) {
                    swal("Oops!", "Batch Quantity should be greater than 0", "warning")
                    return false
                }
            }
        }

        return true
    }

    const instantGoodsReceiveSubmit = () => {
        var object = {}

        object.user_type = userType.value
        object.doc_user_id = selectedUser.value
        object.doc_user_name = selectedUser.label
        object.doc_user_email = selectedUser.email

        object.branch_id = selectedBranch.value
        object.transaction_series_id = selectedTransactionSeries.value
        object.warehouse_id = selectedWarehouse.value

        object.purchase_order_id = null
        object.purchase_order_number = null

        object.item_array = goodsReceiveItemArray

        object.save_flag = true
        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setGoodsReceiveSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-goods-receive-entry`, object, headers).then((res) => {
            setGoodsReceiveSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Goods Received Entry created as ${res.data.data.saved_ref} and saved successfully!`, "success").then(() => {
                    handleGoodsReceiveModalClose();

                    let t = selectedGoodsReceivedEntries.map((x) => { return { ...x } })
                    t.push({ label: res.data.data.saved_ref, value: res.data.data.gr_id, gr_data: res.data.data.gr_data })
                    setSelectedGoodsReceivedEntries(t)
                });
            }
            else if (res.data.operation === "duplicate") {
                const alreadyPresent = {}

                res.data.data.forEach(x => {
                    let stock_type = x.reason.item_stock_type === "Track Serial Number" ? "serial" : "batch"
                    x.reason.info.forEach(y => {
                        const key = `${y.warehouse_id},${y.branch_id},${stock_type}`;
                        if (!alreadyPresent[key]) {
                            alreadyPresent[key] = [];
                        }
                        alreadyPresent[key].push(x.reason.item_stock_type === "Track Serial Number" ? y.serialName : y.Batch_Reference);
                    })
                })

                swal({
                    title: "Oops!",
                    text: "Following Duplicate Stock Data cannot be added 👇",
                    icon: "warning",
                    content: (
                        <div>
                            {
                                res.data.data.map((x, i) => (
                                    x.reason.item_stock_type === "Track Serial Number" ?
                                        <div key={i} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                            <p className="fw-bold d-flex align-items-center">For Serial Numbers</p>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                obj[0].split(',')[2] === "serial" &&
                                                <div key={index}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")}</p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div key={i} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                            <p className="fw-bold d-flex align-items-center">For Batches</p>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                obj[0].split(',')[2] === "batch" &&
                                                <div key={index}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")}</p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            ))}
                                        </div>
                                ))
                            }
                        </div>
                    )
                })
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleGoodsReceiveModalClose = () => {
        setGoodsReceiveModalShow(false)

        setSelectedWarehouse(null)
        setGoodsReceivedNumber("")
        setGoodsReceiveItemArray([])
    }

    const handleAddSerialModalClose = () => {
        setModalRefProductId(null)

        setAddSerialModalShow(false);
        setSelectedCamera("user");
        setSerialInput("")
        setSerialArray([])
    }

    const handleAddBatchModalClose = () => {
        setModalRefProductId(null)

        setAddBatchModalShow(false)
        setBatchArray([])
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Create Bill</h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className='btn btn-success rounded' data-kt-indicator={state != 1 ? "on" : "off"} disabled={state != 1}
                                onClick={() => {
                                    if (selectedTransactionSeries === null) {
                                        swal("Oops!", "Please select a transaction series before proceeding", "warning");
                                        return
                                    }
                                    setGoodsReceiveModalShow(true);
                                    setGoodsReceivedNumber("");
                                    getSubSeriesData(selectedTransactionSeries.value, "goods_received")
                                }}
                            >
                                <span className="indicator-label">+ Additional Items</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            ((!approvalInfo || approvalInfo.type === "not_configure") && state === 1) &&
                            <>
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                        <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                        <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                    </svg>
                                    </span>
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <div className="fs-6 text-gray-700 ">Bill Approval Part Not Configured yet. Configure this, From <Link to={"/approvals"}>Approval Page</Link>.</div>
                                        </div>
                                    </div>
                                </div><br />
                            </>
                        }
                        <div className="card">
                            <div className="card-body">
                                {
                                    state == 1 ?
                                        <>
                                            <div className="row mb-5 gx-0">
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Billing For</label>
                                                    <Select
                                                        options={["VENDOR", "MANUFACTURER"].map(x => { return { label: x, value: x } })}
                                                        onChange={(val) => {
                                                            setUserType(val);
                                                            setSelectedUser(null);
                                                            setPurchaseOrderList([]);
                                                            setSelectedPurchaseOrder(null);
                                                            setGoodsReceivedList([]);
                                                            setSelectedGoodsReceivedEntries([]);

                                                            if (val.value == "CREDITOR") {
                                                                getCreditorBySearch()
                                                            }
                                                            else if (val.value == "VENDOR") {
                                                                getVendorBySearch()
                                                            }
                                                            else if (val.value == "MANUFACTURER") {
                                                                getManufacturerBySearch()
                                                            }
                                                        }}
                                                        value={userType}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        placeholder="Select User type..."
                                                    />
                                                </div>
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">{userType == null ? "User" : userType.value.toLowerCase().replace(/(\b[a-z](?!\s))/g, letter => letter.toUpperCase())} Name</label>
                                                    <Select
                                                        options={
                                                            userType == null ? [] :
                                                                userType.value == "CREDITOR" ? creditorList.map(x => { return { label: x.creditor_display_name, value: x.cid, email: x.email } }) :
                                                                    userType.value == "VENDOR" ? vendorList.map(x => { return { label: x.vendor_display_name, value: x.cid, email: x.email } }) :
                                                                        userType.value == "MANUFACTURER" ? manufacturerList.map(x => { return { label: x.manufacturer_display_name, value: x.cid, email: x.email } }) :
                                                                            null

                                                        }
                                                        onChange={(val) => {

                                                            setSelectedUser(val);
                                                            setSelectedBillingAddress(null);
                                                            setSelectedShippingAddress(null);
                                                            setAddressList([])
                                                            setSelectedGstin(null);
                                                            setGstinList([]);
                                                            setSelectedSourceOfSupply(null);
                                                            setPurchaseOrderList([]);
                                                            setSelectedPurchaseOrder(null);
                                                            setGoodsReceivedList([])
                                                            setSelectedGoodsReceivedEntries([]);

                                                            getConfirmedPurchaseOrderList(val.value)
                                                            getGoodsReceiveBySearch(val.value, null, null)

                                                            if (userType.value == "CREDITOR") {
                                                                getCreditorAddressDetails(val.value)
                                                                getCreditorGstinDetails(val.value)
                                                            }
                                                            else if (userType.value == "VENDOR") {
                                                                getVendorAddressDetails(val.value)
                                                                getVendorGstinDetails(val.value)
                                                            }
                                                            else if (userType.value == "MANUFACTURER") {
                                                                getManufacturerAddressDetails(val.value)
                                                                getManufacturerGstinDetails(val.value)
                                                            }
                                                        }}
                                                        value={selectedUser}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        onInputChange={(val) => {
                                                            if (userType.value == "CREDITOR") {
                                                                getCreditorBySearch(val)
                                                            }
                                                            else if (userType.value == "VENDOR") {
                                                                getVendorBySearch(val)
                                                            }
                                                            else if (userType.value == "MANUFACTURER") {
                                                                getManufacturerBySearch(val)
                                                            }
                                                        }}
                                                        isLoading={userListApiState}
                                                        isDisabled={userType == null}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: !addressApiState && selectedUser != null ? "1" : "0", visibility: !addressApiState && selectedUser != null ? "visible" : "hidden", height: !addressApiState && selectedUser != null ? "auto" : "0" }} >

                                                <div className="col-md-6 px-1 mb-4">
                                                    <div className="card card-dashed p-6 flex-row">
                                                        <div className="d-flex flex-column flex-grow-1">
                                                            <div className="d-flex align-items-center fs-5 fw-bold mb-5">Billing Address</div>
                                                            {
                                                                selectedBillingAddress != null ?
                                                                    <>
                                                                        {(selectedBillingAddress.address_line1 != "" && selectedBillingAddress.address_line2 != "") && <div className="fs-6 fw-semibold text-gray-600">{`${selectedBillingAddress.address_line1} ${selectedBillingAddress.address_line2}`}</div>}
                                                                        <div className="fs-6 fw-semibold text-gray-600">{`${selectedBillingAddress.city != null ? selectedBillingAddress.city : ""} ${selectedBillingAddress.state != null ? selectedBillingAddress.state : ""}, ${selectedBillingAddress.pincode != null ? selectedBillingAddress.pincode : ""} ${selectedBillingAddress.country != null ? selectedBillingAddress.country : ""}`}</div>
                                                                    </>
                                                                    :
                                                                    <div className='text-hover-primary'>Select an address</div>
                                                            }
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <button onClick={(e) => { setAddressModalShow(true); setAddressType("billing") }} className="btn btn-sm px-3">
                                                                <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 px-1 mb-4">
                                                    <div className="card card-dashed p-6 flex-row">
                                                        <div className="d-flex flex-column flex-grow-1">
                                                            <div className="d-flex align-items-center fs-5 fw-bold mb-5">Shipping Address</div>
                                                            {
                                                                selectedShippingAddress != null ?
                                                                    <>
                                                                        {(selectedShippingAddress.address_line1 != "" && selectedShippingAddress.address_line2 != "") && <div className="fs-6 fw-semibold text-gray-600">{`${selectedShippingAddress.address_line1} ${selectedShippingAddress.address_line2}`}</div>}
                                                                        <div className="fs-6 fw-semibold text-gray-600">{`${selectedShippingAddress.city != null ? selectedShippingAddress.city : ""} ${selectedShippingAddress.state != null ? selectedShippingAddress.state : ""}, ${selectedShippingAddress.pincode != null ? selectedShippingAddress.pincode : ""} ${selectedShippingAddress.country != null ? selectedShippingAddress.country : ""}`}</div>
                                                                    </>
                                                                    :
                                                                    <div className='text-hover-primary'>Select an address</div>
                                                            }
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <button onClick={(e) => { setAddressModalShow(true); setAddressType("shipping") }} className="btn btn-sm px-3">
                                                                <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: !gstApiState && selectedUser != null ? "1" : "0", visibility: !gstApiState && selectedUser != null ? "visible" : "hidden", height: !gstApiState && selectedUser != null ? "auto" : "0" }} >
                                                <div className="col-12 px-1 mb-4">
                                                    <div className="bg-light-primary border border-primary d-flex flex-column w-100 p-5 rounded">
                                                        <h5>GSTIN Details</h5>

                                                        <div className="d-flex flex-wrap align-items-center gap-1">
                                                            {
                                                                selectedGstin != null ?
                                                                    <>
                                                                        <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                            <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.gst_treatment}</span>
                                                                            <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                        </div>
                                                                        <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                            <span className="fs-6 text-gray-700 fw-bold">{selectedGstin.GstIn}</span>
                                                                            <div className="fw-semibold text-gray-400">GSTIN</div>
                                                                        </div>
                                                                    </> :
                                                                    <div className="border border-gray-300 border-dashed rounded min-w-100px py-2 px-4 mx-3 flex-grow-1">
                                                                        <span className="fs-6 text-gray-700 fw-bold">No GSTIN selected</span>
                                                                    </div>
                                                            }

                                                            <div>
                                                                <button onClick={(e) => { setGstInListModalShow(true); }} className="btn btn-sm px-3">
                                                                    <i className="fa-solid fa-pencil fs-2 text-hover-success"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-5 gx-0 ">
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Bill Date</label>
                                                    <input type="date" className="form-control form-control-solid" value={billDate} onChange={(e) => { setBillDate(e.target.value); setSelectedTerm(null); }} />
                                                </div>
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 required">Due Date</label>
                                                    <input type="date" className="form-control form-control-solid" value={dueDate} onChange={(e) => { setDueDate(e.target.value); setSelectedTerm(null); }} />
                                                </div>
                                            </div>

                                            <div className="row mb-5 gx-0 ">
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2">Terms</label>
                                                    <Select
                                                        options={termsList.map(x => { return { label: x.term_name, value: x.no_of_days } })}
                                                        onChange={(val) => { setSelectedTerm(val); setDueDate(moment.utc(billDate).add(val.value, "days").format('YYYY-MM-DD')); }}
                                                        value={selectedTerm}
                                                        components={{
                                                            MenuList: (props) => {
                                                                return (
                                                                    <components.MenuList  {...props}>
                                                                        {props.children}
                                                                        <div className='d-flex justify-content-center'>
                                                                            <button className='btn btn-success rounded btn-sm my-1'
                                                                                onClick={() => {
                                                                                    setTermsModalShow(true)
                                                                                    setTermsPanelList(termsList.map(x => { return { ...x } }))
                                                                                }}
                                                                            >&#10011; Configure Terms</button>
                                                                        </div>
                                                                    </components.MenuList >
                                                                )
                                                            }
                                                        }}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    />
                                                </div>
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 required">Currency</label>
                                                    <Select
                                                        options={currencyList.map(x => { return { label: x.currency_code, value: x.currency_id, currency_details: x } })}
                                                        onChange={(val) => { setSelectedCurrency(val) }}
                                                        value={selectedCurrency}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-5 gx-0">
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2">Purchase Order</label>
                                                    <Select
                                                        options={purchaseOrderList.map(x => { return { label: x.purchase_order_number, value: x.purchase_order_id } })}
                                                        onChange={(val) => {
                                                            setSelectedPurchaseOrder(val);
                                                            setGoodsReceivedList([]);
                                                            if (val) {
                                                                getGoodsReceiveBySearch(selectedUser.value, val.value, null);
                                                            }
                                                        }}
                                                        value={selectedPurchaseOrder}
                                                        isClearable
                                                        isDisabled={!selectedUser}
                                                        isLoading={purchaseOrderListApiState}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        placeholder="Select Purchase Order..."
                                                    />
                                                </div>
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="fs-5 fw-bold mb-2 required">Goods Receive Records</label>
                                                    <Select
                                                        isMulti
                                                        options={goodsReceivedList.map(x => { return { label: x.gr_number, value: x.gr_id, gr_data: x } })}
                                                        onChange={(val) => { setSelectedGoodsReceivedEntries(val) }}
                                                        isClearable
                                                        isDisabled={!selectedUser || billLineItemsProcessingState}
                                                        isLoading={goodsReceivedListApiState}
                                                        value={selectedGoodsReceivedEntries}
                                                        onInputChange={(val) => {
                                                            if (val.length > 2) {
                                                                getGoodsReceiveBySearch(selectedUser.value, selectedPurchaseOrder ? selectedPurchaseOrder.value : null, val);
                                                            }
                                                        }}
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        placeholder="Select Received Goods entries..."
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-sm-5 gx-0">
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Branch:</label>
                                                    <Select
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        options={branchList.map((x) => { return { label: x.branch_name, value: x.branch_id } })}
                                                        onChange={(val) => {
                                                            swal("Alert!", "Please verify applicable taxes for selected items", "info")
                                                            setSelectedBranch(val);
                                                            setSelectedTransactionSeries(null)
                                                        }}
                                                        value={selectedBranch}
                                                        placeholder="Select Branch"
                                                    />
                                                </div>
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Transaction Series:</label>
                                                    <Select
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        options={selectedBranch != null && transactionSeriesList.filter(x => branchList.find(a => a.branch_id == selectedBranch.value).transaction_series_id.split(",").includes(x.transaction_series_id)).map(x => { return { label: x.series_name, value: x.transaction_series_id } })}
                                                        onChange={(val) => { setSelectedTransactionSeries(val); setBillNumber(""); getSubSeriesData(val.value, "bills") }}
                                                        value={selectedTransactionSeries}
                                                        placeholder="Select Transaction Series"
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-sm-5 gx-0">
                                                <div className="col-md-6 px-1 mb-2">
                                                    <label className="required fs-5 fw-bold mb-2">Bill Number</label>
                                                    <input type="text" className="form-control form-control-solid" value={billNumber} readOnly />
                                                </div>
                                                <div className="col-md-6 px-1 mb-4">
                                                    <label className="required fs-5 fw-bold mb-2">Source Of Supply</label>
                                                    <Select
                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                        options={SupplyStateOptions}
                                                        onChange={(val) => { setSelectedSourceOfSupply(val) }}
                                                        value={selectedSourceOfSupply}
                                                    />
                                                </div>
                                            </div>

                                            <h2>Bill Line Items</h2>
                                            {
                                                billLineItemsProcessingState ? <div className='text-center'><div className="spinner-border text-primary" style={{ width: "5rem", height: "5rem" }}></div></div> :
                                                    <div className='' style={{ overflowX: "auto" }} >
                                                        <div className='container px-3' style={{ width: "150rem" }}>
                                                            <div className="row mb-5 gx-0">
                                                                <div className="col-3"><p className="fs-5 text-center">Item</p></div>
                                                                <div className="col-1"><p className="fs-5 text-center">Rate</p></div>
                                                                <div className="col-1"><p className="fs-5 text-center">Quantity</p></div>
                                                                <div className="col-1"><p className="fs-5 text-center">Total Amt. Excl.</p></div>
                                                                <div className="col-2"><p className="fs-5 text-center">Total Discount</p></div>
                                                                <div className="col-2"><p className="fs-5 text-center">Tax (%)</p></div>
                                                                <div className="col-1"><p className="fs-5 text-center">Tax amount</p></div>
                                                                <div className="col-1"><p className="fs-5 text-center">Line Total</p></div>
                                                            </div>
                                                            {
                                                                itemArray.length === 0 ? <div className='fs-1 pb-15 text-center text-gray-600'>No GRs selected</div> :
                                                                    itemArray.map((obj, indx, arr) => {
                                                                        return (
                                                                            <div key={indx} className="row mb-5 gx-0 align-items-baseline">
                                                                                <div className="col-3 px-0">
                                                                                    <div className='bg-light-secondary rounded' style={{ padding: "0.95rem" }}>{obj.item_name}</div>
                                                                                    <div className='mt-1'>
                                                                                        <div style={{ position: "relative", display: "inline-block" }} >
                                                                                            <button className='btn btn-link p-0'
                                                                                                onClick={() => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].item_info_popover = true
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].item_info_popover = false
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                            ><i className="fa fa-info-circle mx-1 text-hover-primary" style={{ cursor: "pointer" }} ></i></button>
                                                                                            <div style={{ position: "absolute", top: "-60px", left: "0px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "10rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].item_info_popover ? "1" : "0", visibility: itemArray[indx].item_info_popover ? "visible" : "hidden" }}>
                                                                                                {
                                                                                                    obj.gr_info.map((x, i) => {
                                                                                                        return (
                                                                                                            <div key={i}>{x.gr_number} &#x279C; {x.unit} units</div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        </div>

                                                                                        {
                                                                                            itemArray[indx].item_type == "Goods" ?
                                                                                                <>
                                                                                                    <span className="badge badge-primary me-1">Goods</span>
                                                                                                    <span className='text-info'>HSN code: </span>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <span className="badge badge-success me-1">Service</span>
                                                                                                    <span className='text-info'>SAC code: </span>
                                                                                                </>
                                                                                        }

                                                                                        <div style={{ position: "relative", display: "inline-block" }} >
                                                                                            <span className='text-hover-primary' style={{ cursor: "pointer" }}
                                                                                                onClick={() => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].item_popover = true
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                            >{itemArray[indx].item_code == "" ? "Update " : itemArray[indx].item_code}<i className="fa fa-pencil ms-1"></i>
                                                                                            </span>
                                                                                            <div style={{ position: "absolute", top: "-90px", left: "80px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].item_popover ? "1" : "0", visibility: itemArray[indx].item_popover ? "visible" : "hidden" }}>
                                                                                                <p className='mb-1'>{itemArray[indx].item_type == "Goods" ? "HSN Code" : "SAC Code"}</p>
                                                                                                <div className='d-flex gap-1 mb-1'>
                                                                                                    <input type="text" className="form-control form-control-solid px-2" value={itemArray[indx].item_popover_input}
                                                                                                        onChange={(e) => {
                                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                                            temp[indx].item_popover_input = e.target.value
                                                                                                            setItemArray(temp)
                                                                                                        }}
                                                                                                    />
                                                                                                    <button className='btn btn-sm btn-secondary px-3' onClick={() => { setProductCodeModalShow(true); setProductCodeItemIndex(indx); setProductCodeItemType(itemArray[indx].item_type); }}><i className="fa fa-search p-0" aria-hidden="true"></i></button>
                                                                                                </div>
                                                                                                <div className='d-flex gap-1 mb-1'>
                                                                                                    <button className='btn btn-sm btn-success px-2 py-1'
                                                                                                        onClick={async () => {
                                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                                            await updateProductCode(temp[indx].item_id, temp[indx].item_type, temp[indx].item_popover_input)
                                                                                                            temp.forEach(x => {
                                                                                                                if (x.item_id === temp[indx].item_id) {
                                                                                                                    x.item_code = temp[indx].item_popover_input
                                                                                                                }
                                                                                                            })
                                                                                                            temp[indx].item_popover_input = ""
                                                                                                            temp[indx].item_popover = false
                                                                                                            setItemArray(temp)
                                                                                                        }}
                                                                                                    >Save</button>
                                                                                                    <button className='btn btn-sm btn-secondary px-2 py-1'
                                                                                                        onClick={() => {
                                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                                            temp[indx].item_popover_input = ""
                                                                                                            temp[indx].item_popover = false
                                                                                                            setItemArray(temp)
                                                                                                        }}
                                                                                                    >Close</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1 px-1">
                                                                                    <input type="number" className="form-control form-control-solid px-2" value={trimDecimalPlaces(itemArray[indx].rate, selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place).toString()}
                                                                                        onChange={(e) => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].rate = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                            temp[indx].pre_defined_rate = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                        onBlur={(e) => {
                                                                                            if (itemArray[indx].tax_calculation_method == "TAX_Inclusive") {
                                                                                                let temp = itemArray.map(a => { return { ...a } });
                                                                                                if (temp[indx].tax != null) {
                                                                                                    temp[indx].rate = temp[indx].pre_defined_rate == "" ? 0 : parseFloat(temp[indx].pre_defined_rate) / (1 + (temp[indx].tax.tax_group_full_details.tax_group_percentage / 100))
                                                                                                }
                                                                                                setItemArray(temp)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <div className='mt-1'>
                                                                                        <div style={{ position: "relative", display: "inline-block" }} >
                                                                                            <button className='btn p-0 text-hover-primary'
                                                                                                onClick={() => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].rate_popover = true
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                                onBlur={() => {
                                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                                    temp[indx].rate_popover = false
                                                                                                    setItemArray(temp)
                                                                                                }}
                                                                                            >Update<i className="fa fa-pencil ms-1" aria-hidden="true"></i>
                                                                                            </button>
                                                                                            <div style={{ position: "absolute", top: "-60px", left: "65px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "10rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].rate_popover ? "1" : "0", visibility: itemArray[indx].rate_popover ? "visible" : "hidden" }}>
                                                                                                <div className={`p-1 rounded cursor-pointer ${itemArray[indx].tax_calculation_method == "TAX_Inclusive" && "bg-primary"}`}
                                                                                                    onClick={() => {
                                                                                                        let temp = itemArray.map(a => { return { ...a } });
                                                                                                        temp[indx].tax_calculation_method = "TAX_Inclusive"
                                                                                                        if (temp[indx].tax != null) {
                                                                                                            temp[indx].rate = temp[indx].pre_defined_rate / (1 + (temp[indx].tax.tax_group_full_details.tax_group_percentage / 100))
                                                                                                        }
                                                                                                        setItemArray(temp)
                                                                                                    }}
                                                                                                >Tax Inclusive</div>
                                                                                                <div className={`p-1 rounded cursor-pointer ${itemArray[indx].tax_calculation_method == "TAX_Exclusive" && "bg-primary"}`}
                                                                                                    onClick={() => {
                                                                                                        let temp = itemArray.map(a => { return { ...a } });
                                                                                                        temp[indx].tax_calculation_method = "TAX_Exclusive"
                                                                                                        if (temp[indx].tax != null) {
                                                                                                            temp[indx].rate = temp[indx].pre_defined_rate
                                                                                                        }
                                                                                                        setItemArray(temp)
                                                                                                    }}
                                                                                                >Tax Exclusive</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1 px-1">
                                                                                    <div className='bg-light-secondary rounded' style={{ padding: "0.95rem" }}>{obj.quantity}</div>
                                                                                </div>
                                                                                <div className="col-1 px-1">
                                                                                    <div className='bg-light-secondary rounded' style={{ padding: "0.95rem" }}>{trimDecimalPlaces(((itemArray[indx].quantity * itemArray[indx].rate) - (itemArray[indx].discount_type.value == "amount" ? itemArray[indx].discount_unit : itemArray[indx].quantity * itemArray[indx].rate * itemArray[indx].discount_unit / 100)), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)}</div>
                                                                                </div>
                                                                                <div className='col-1 pe-0 ps-1'>
                                                                                    <input type="number" className="form-control form-control-solid px-2" value={itemArray[indx].discount_unit.toString()}
                                                                                        onChange={(e) => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].discount_unit = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-1 ps-0 pe-1'>
                                                                                    <Select
                                                                                        options={[{ label: "amount", value: "amount" }, { label: "%", value: "percentage" }]}
                                                                                        onChange={(val) => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].discount_type = val
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                        value={itemArray[indx].discount_type}
                                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-2 px-0'>
                                                                                    <Select
                                                                                        options={taxGroupList.filter(x => x.tax_group_type == obj.tax_group_type).map(x => { return { label: `(${x.tax_group_percentage}%) ${x.tax_group_name}`, value: x.tax_group_id, tax_group_full_details: x } })}
                                                                                        onChange={(val) => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].tax = val
                                                                                            if (val != null) {
                                                                                                if (temp[indx].tax_calculation_method == "TAX_Inclusive") {
                                                                                                    temp[indx].rate = temp[indx].pre_defined_rate / (1 + (val.tax_group_full_details.tax_group_percentage / 100))
                                                                                                }
                                                                                                else if (temp[indx].tax_calculation_method == "TAX_Exclusive") {
                                                                                                    temp[indx].rate = temp[indx].pre_defined_rate
                                                                                                }
                                                                                            }

                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                        value={itemArray[indx].tax}
                                                                                        isClearable
                                                                                        isDisabled={!obj.taxable}
                                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                                        menuPosition='fixed'
                                                                                        menuShouldBlockScroll={true}
                                                                                    />
                                                                                    {obj.taxable && <span className='text-gray-600'>{obj.tax_group_type} Taxes Applicable</span>}
                                                                                </div>
                                                                                <div className="col-1 px-1">
                                                                                    <div className='bg-light-secondary rounded' style={{ padding: "0.95rem" }}>{trimDecimalPlaces((itemArray[indx].tax == null ? 0 : (itemArray[indx].quantity * itemArray[indx].rate) * (itemArray[indx].tax.tax_group_full_details.tax_group_percentage / 100)), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)}</div>
                                                                                </div>
                                                                                <div className="col-1 px-1">
                                                                                    <div className='bg-light-secondary rounded' style={{ padding: "0.95rem" }}>{trimDecimalPlaces(((itemArray[indx].quantity * itemArray[indx].rate) + (itemArray[indx].tax == null ? 0 : (itemArray[indx].quantity * itemArray[indx].rate) * (itemArray[indx].tax.tax_group_full_details.tax_group_percentage / 100))), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)}</div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                            }
                                                        </div>
                                                    </div>
                                            }

                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <div className="">
                                                        <div className="fw-semibold fs-5 mb-3 text-dark">Tax Calculation</div>
                                                        {
                                                            itemArray.reduce((p, o) => {
                                                                if (o.tax == null) {
                                                                    return p
                                                                }
                                                                else {
                                                                    let tg = o.tax.tax_group_full_details

                                                                    let decimal = Math.pow(10, parseInt(selectedCurrency.currency_details.decimal_place));
                                                                    let total_taxable_amount = Math.round(((o.quantity * o.rate) * (tg.tax_group_percentage / 100)) * decimal) / decimal;
                                                                    let total_tax = tg.tax_group_details == null ? 0 : tg.tax_group_details.reduce((p, o) => { return p + Number(o.tax_percentage) }, 0);

                                                                    tg.tax_group_details.forEach((obj1, index1) => {
                                                                        if (p.find(x => x.tax_id == obj1.tax_id)) {
                                                                            let t = p.find(x => x.tax_id == obj1.tax_id);
                                                                            t.tax_amount = t.tax_amount + Math.round((Number(total_taxable_amount) * Number(obj1.tax_percentage) / Number(total_tax)) * decimal) / decimal;
                                                                        }
                                                                        else {
                                                                            let json = {};
                                                                            json.tax_id = obj1.tax_id;
                                                                            json.tax_name = obj1.tax_name;
                                                                            json.tax_percentage = obj1.tax_percentage;
                                                                            json.tax_amount = Math.round((Number(total_taxable_amount) * Number(obj1.tax_percentage) / Number(total_tax)) * decimal) / decimal;
                                                                            p.push(json);
                                                                        }
                                                                    })

                                                                    return p
                                                                }
                                                            }, []).map((obj, index) => {
                                                                return (
                                                                    <div key={index} className="d-flex text-gray-800 mb-3 fs-6">
                                                                        <div className="fw-semibold pe-5">{obj.tax_name} ({obj.tax_percentage}%):</div>
                                                                        <div className="text-end">{currencyList.find(x => x.currency_id == selectedCurrency.value).currency_symbol} {obj.tax_amount}</div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className='col-md-8'>
                                                    <div className='row'>
                                                        <div className='col-md-6 d-flex align-items-center'><p className='m-0 fs-5 flex-grow-1 text-end'>Sub Total</p></div>
                                                        <div className='col-md-6 my-2'>
                                                            <input type="text" className="form-control form-control-solid" value={subTotal} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-3 d-flex align-items-center justify-content-md-end'><p className='m-0 fs-5'>Tax added</p></div>
                                                        <div className="col-md-3 d-flex align-items-center">
                                                            <div className={`rounded mx-2 px-3 py-2 ${selectedTaxCategory == "TDS" && "bg-primary"}`} style={{ cursor: "pointer" }} onClick={() => { setSelectedTaxCategory("TDS"); setSelectedTax(null); }}>TDS</div>
                                                            <div className={`rounded mx-2 px-3 py-2 ${selectedTaxCategory == "TCS" && "bg-primary"}`} style={{ cursor: "pointer" }} onClick={() => { setSelectedTaxCategory("TCS"); setSelectedTax(null); }}>TCS</div>
                                                            {selectedTaxCategory != null && <div className='text-hover-primary' style={{ cursor: "pointer" }} onClick={() => { setSelectedTaxCategory(null); setSelectedTax(null); }}>&#10006;</div>}
                                                        </div>
                                                        <div className='col-md-3 my-2'>
                                                            <Select
                                                                options={taxList.filter(x => x.category == selectedTaxCategory).map(x => { return { label: `(${x.tax_percentage}%) ${x.tax_name}`, value: x.tax_id, tax_full_details: x } })}
                                                                onChange={(val) => { setSelectedTax(val) }}
                                                                isClearable
                                                                value={selectedTax}
                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                isDisabled={selectedTaxCategory == null}
                                                            />
                                                        </div>
                                                        <div className='col-md-3 my-2'>
                                                            <input type="text" className="form-control form-control-solid" value={trimDecimalPlaces(((selectedTaxCategory == "TDS" ? itemArray.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (selectedTax == null ? 0 : parseFloat(taxList.find(x => x.tax_id == selectedTax.value).tax_percentage)) / 100), selectedCurrency == null ? 3 : selectedCurrency.currency_details.decimal_place)} readOnly />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-6 my-2">
                                                            <input type="text" className="form-control form-control-solid" value={adjustment.text} onChange={(e) => { setAdjustment({ ...adjustment, text: e.target.value }) }} />
                                                        </div>
                                                        <div className='col-md-6 my-2'>
                                                            <input type="number" className="form-control form-control-solid" value={adjustment.display.toString()}
                                                                onChange={(e) => { setAdjustment({ ...adjustment, display: e.target.value, value: e.target.value == "" ? 0 : parseFloat(e.target.value) }) }}
                                                                onFocus={(e) => { if (adjustment.display === "0") { setAdjustment({ ...adjustment, display: "" }) } }}
                                                                onBlur={(e) => { if (adjustment.display === "") { setAdjustment({ ...adjustment, display: "0" }) } }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6 d-flex align-items-center'>
                                                            <h4 className='m-0'>Grand Total</h4>
                                                        </div>
                                                        <div className='col-md-6 d-flex align-items-center'>
                                                            <h3>{currencyList.length > 0 && selectedCurrency != null && currencyList.find(x => x.currency_id == selectedCurrency.value).currency_symbol} {grandTotal}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />

                                            <div className="row mb-5">
                                                <div className="col-md-6 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Internal Notes</label>
                                                    <textarea className="form-control form-control-solid" rows="3" value={internalNotes} onChange={(e) => { setInternalNotes(e.target.value) }} placeholder={"Looking forward for your business."}></textarea>
                                                </div>
                                                <div className="col-md-6 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Terms and Conditions</label>
                                                    <textarea className="form-control form-control-solid" rows="3" value={termsAndConditions} onChange={(e) => { setTermsAndConditions(e.target.value) }} placeholder={"Enter the terms and conditions of your business to be displayed in your transaction"}></textarea>
                                                </div>
                                            </div>

                                            <div className="row mb-5">
                                                <div className="col-md-12 my-2">
                                                    <label className="fs-5 fw-bold mb-2">Upload upto 5 files</label>
                                                    <Dropzone multiple={false} maxFiles={5} maxSize={5 * 1024 * 1024}
                                                        onDrop={acceptedFiles => {
                                                            if (acceptedFiles.length) {
                                                                if (uploadedDocuments.length === 5) {
                                                                    swal("Oops!", "Maximum number of files uploaded already, remove some to add more", "warning");
                                                                    return
                                                                }
                                                                if (uploadedDocuments.find(x => x.name === acceptedFiles[0].name)) {
                                                                    swal("Oops!", "Duplicate file cannot be uploaded", "warning");
                                                                    return
                                                                }

                                                                setUploadedDocuments([...uploadedDocuments, acceptedFiles[0]]);
                                                            }
                                                        }}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps()} className="border border-3 border-dashed border-success fs-1 fw-bold p-15 rounded text-center">
                                                                    <input {...getInputProps()} />
                                                                    {
                                                                        uploadedDocuments.length === 0 ? <p className='m-0'>Drag 'n' drop some files here, or click to select files</p> :
                                                                            <div className='d-flex gap-5 flex-wrap'>
                                                                                {
                                                                                    uploadedDocuments.map((x, i) => {
                                                                                        return (
                                                                                            <div key={i}>
                                                                                                <span className='me-3'>{x.name.slice(0, 15)}{x.name.length > 15 && "..."}</span>
                                                                                                <button className='btn btn-danger rounded-pill px-4 py-2' onClick={(e) => {
                                                                                                    e.stopPropagation();

                                                                                                    let t = [...uploadedDocuments]
                                                                                                    t.splice(i, 1)
                                                                                                    setUploadedDocuments(t)
                                                                                                }}>✖</button>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>

                                            {
                                                write &&
                                                <button type="button" className="btn btn-lg btn-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateBill() && billEntrySubmit(false) }}>
                                                    <span className="indicator-label">Save as Draft</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }
                                            {
                                                write &&
                                                <button type="button" className="btn btn-lg btn-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { validateBill() && billEntrySubmit(true) }}>
                                                    <span className="indicator-label">Save</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }
                                        </>
                                        :
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <PageError />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div >

            <Modal show={goodsReceiveModalShow} onHide={() => { handleGoodsReceiveModalClose() }} size="xl" centered>
                <Modal.Header>
                    <Modal.Title>Create a GR Entry for Additional Items</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleGoodsReceiveModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>

                        <div className="row mb-sm-5 gx-0">
                            <div className="col-md-6 px-1 mb-2">
                                <label className="fs-5 fw-bold mb-2 required">Warehouse</label>
                                <Select
                                    options={warehouseList.map(x => { return { label: x.name, value: x.wid } })}
                                    onChange={(val) => { setSelectedWarehouse(val); }}
                                    value={selectedWarehouse}
                                    placeholder="Select Warehouse..."
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                            <div className="col-md-6 px-1 mb-2">
                                <label className="required fs-5 fw-bold mb-2">Goods Received Number</label>
                                <input type="text" className="form-control form-control-solid" value={goodsReceivedNumber} readOnly />
                            </div>
                        </div>

                        <div className='mt-5 mb-20'>
                            <label className="fs-5 fw-bold mb-2">Goods Received Items</label>
                            <div className='' style={{ overflowX: "auto" }}>
                                <div className='container px-0' style={{ width: "80rem" }}>

                                    <div className="row mb-5 gx-0">
                                        <div className="col-5"><p className="fs-5 text-center">Item</p></div>
                                        <div className="col-2"><p className="fs-5 text-center">Received Qty</p></div>
                                        <div className="col-4"><p className="fs-5 text-center">Additional data</p></div>
                                        <div className="col-1"><p className="fs-5 text-center"></p></div>
                                    </div>
                                    {
                                        goodsReceiveItemArray.length == 0 ? <div className='fs-2 text-center text-grey-600'>No Items Added</div> :
                                            goodsReceiveItemArray.map((obj, indx) => {
                                                return (
                                                    <div key={indx} className="row py-2 gx-0 align-items-center">
                                                        <div className="col-5 px-0">
                                                            <Select
                                                                options={itemList.map(x => { return { label: x.product_name, value: x.pid } })}
                                                                onChange={(val) => {
                                                                    if (goodsReceiveItemArray.find(x => x.item_id === val.value)) {
                                                                        swal("Oops!", "Duplicate items cannot be added", "warning")
                                                                        return
                                                                    }

                                                                    let pd = itemList.find(x => x.pid == val.value)

                                                                    let temp = goodsReceiveItemArray.map(a => { return { ...a } });
                                                                    temp[indx].item_id = val.value
                                                                    temp[indx].item_name = val.label
                                                                    temp[indx].item_stock_type = pd.tracking_inventory_type
                                                                    temp[indx].received_qty = 0
                                                                    temp[indx].item_data = null
                                                                    setGoodsReceiveItemArray(temp)
                                                                }}
                                                                value={obj.item_id == null ? null : { label: obj.item_name, value: obj.item_id }}
                                                                onInputChange={(val) => { getItems(val); }}
                                                                isLoading={itemApiState}
                                                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                placeholder="Select Product..."
                                                                menuPosition={'fixed'}
                                                                menuShouldBlockScroll={true}
                                                            />
                                                        </div>
                                                        <div className="col-2 px-1 text-center">
                                                            <input type="number" className="form-control form-control-solid px-2" value={obj.received_qty.toString()} onChange={(e) => {
                                                                if (e.target.value < 0) {
                                                                    swal("Alert!", "Negative unit not allowed", "warning")
                                                                    return
                                                                }

                                                                let temp = goodsReceiveItemArray.map(a => { return { ...a } })
                                                                temp[indx].received_qty = e.target.value == "" ? 0 : parseInt(e.target.value)
                                                                setGoodsReceiveItemArray(temp)
                                                            }} />
                                                        </div>
                                                        <div className="col-4 px-1 text-center">
                                                            {
                                                                obj.item_stock_type == "Track Serial Number" ?
                                                                    <>
                                                                        {
                                                                            obj.item_data == null ?
                                                                                <button className='btn btn-primary' onClick={() => { setAddSerialModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setSerialArray(obj.item_data); }} >Add New Serial Numbers</button> :
                                                                                <>
                                                                                    <span className='fs-4 mx-4'>{obj.item_data.length} Serials Added</span>
                                                                                    <button className='btn btn-light-success px-4' onClick={() => { setAddSerialModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setSerialArray(obj.item_data); }} >
                                                                                        <i className="fa-solid fa-pencil fs-2 p-0"></i>
                                                                                    </button>
                                                                                </>
                                                                        }
                                                                    </>
                                                                    : obj.item_stock_type == "Track Batches" ?
                                                                        <>
                                                                            {
                                                                                obj.item_data == null ?
                                                                                    <button className='btn btn-primary' onClick={() => { setAddBatchModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setBatchArray(obj.item_data); }} >Add New Batches</button> :
                                                                                    <>
                                                                                        <span className='fs-4 mx-4'>{obj.item_data.length} Batches with {obj.item_data.reduce((p, o) => p + o.batch_quantity, 0)} Quantity Added</span>
                                                                                        <button className='btn btn-light-success px-4' onClick={() => { setAddBatchModalShow(true); setModalRefProductId(obj.item_id); obj.item_data !== null && setBatchArray(obj.item_data); }} >
                                                                                            <i className="fa-solid fa-pencil fs-2 p-0"></i>
                                                                                        </button>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                        : <div className='text-gray-600 fs-4 text-center'>No data required for this item</div>
                                                            }
                                                        </div>
                                                        <div className="col-1 px-1 text-center">
                                                            <button className='btn btn-link btn-sm text-hover-danger' style={{ flex: "1" }} onClick={() => {
                                                                let temp = goodsReceiveItemArray.map(a => { return { ...a } })
                                                                temp.splice(indx, 1)
                                                                setGoodsReceiveItemArray(temp)
                                                            }}>✖</button>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }
                                    <button className="btn btn-sm btn-primary" onClick={() => { setGoodsReceiveItemArray([...goodsReceiveItemArray, { item_id: null, item_name: null, item_stock_type: null, received_qty: 0, item_data: null }]) }}> Add +</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleGoodsReceiveModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={goodsReceiveSubmitButtonState ? "on" : "off"} disabled={goodsReceiveSubmitButtonState} onClick={() => { validateReceivedGoods() && instantGoodsReceiveSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={addSerialModalShow} onHide={() => { handleAddSerialModalClose() }} centered>
                <Modal.Header>
                    <Modal.Title>Add Serial Numbers</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddSerialModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>
                        <div className='d-flex align-items-center gap-3'>
                            <BarcodeScannerComponent
                                width={350}
                                height={220}
                                onUpdate={(err, result) => {
                                    if (!result)
                                        return

                                    let tag = result.text;
                                    console.log("tag", tag, "serialArray", serialArray)

                                    if (!serialArray.includes(tag)) {
                                        setSerialArray([...serialArray, tag]);
                                    }
                                }}
                                videoConstraints={{
                                    width: 1280,
                                    height: 720,
                                    facingMode: selectedCamera == "user" ? selectedCamera : { exact: selectedCamera }
                                }}
                            />
                            <button className="btn btn-secondary rounded-pill" onClick={() => { selectedCamera == "user" ? setSelectedCamera("environment") : setSelectedCamera("user") }}>
                                <svg version="1.1" width="32" height="32" viewBox="0 0 1000 1000">
                                    <g transform="translate(0.000000,380.000000) scale(0.100000,-0.100000)">
                                        <path d="M3257.7,2389.6c-99.6-51.7-227.8-197.2-390.6-444.2c-225.9-340.8-55.5-300.6-1294.3-306.3l-1062.6-5.7l-103.4-57.4c-59.4-32.5-135.9-97.6-178.1-149.3c-139.8-178.1-132.1,19.1-126.4-3049.9l5.7-2755.1l40.2-88.1c88.1-189.6,247-308.3,461.4-342.7c149.3-24.9,8630.9-24.9,8780.3,0c214.4,34.5,373.3,153.2,461.4,342.7l40.2,88.1l5.7,2755.1c5.7,3069.1,13.4,2871.9-126.3,3049.9c-42.1,51.7-118.7,116.8-178,149.3l-103.4,57.4l-1062.6,5.7c-1238.7,5.8-1068.3-34.5-1294.3,306.3c-166.6,250.8-291,394.4-394.4,446.1c-68.9,34.5-135.9,36.4-1742.3,36.4C3403.2,2426,3320.9,2424.1,3257.7,2389.6z M6621.7,1983.7c30.6-34.5,128.3-168.5,214.4-296.8c162.7-243.1,268-354.2,377.2-400.1c51.7-21.1,254.6-26.8,1102.8-26.8c999.4,0,1041.5-1.9,1100.9-38.3c34.5-21.1,68.9-61.3,76.6-90c9.6-28.7,15.3-1259.8,15.3-2734c0-2552.1-1.9-2684.3-34.5-2734c-19.1-30.6-55.5-61.3-82.3-70.8c-65.1-24.9-8719-24.9-8784.1,0c-26.8,9.6-63.2,40.2-82.3,70.8c-32.5,49.8-34.5,181.9-34.5,2734c0,1474.2,5.7,2705.3,15.3,2734c7.7,28.7,42.1,68.9,76.6,90c59.4,36.4,101.5,38.3,1100.9,38.3c848.2,0,1051.1,5.7,1102.8,26.8c109.1,45.9,214.4,157,377.2,400.1c86.2,128.3,183.8,262.3,214.4,296.8l59.4,61.3H5000h1562.3L6621.7,1983.7z"></path>
                                        <path d="M4779.8,461.6c-254.6-30.6-538-126.4-758.2-256.6C3870.4,117,3832.1,71,3832.1-24.7c0-91.9,59.4-164.7,145.5-181.9c55.5-9.6,99.5,3.8,281.4,93.8c292.9,145.5,381,168.5,673.9,180c210.6,7.7,275.7,3.8,421.2-30.6c492.1-118.7,896-459.5,1089.4-917.1c47.9-113,88.1-262.3,116.8-434.6l11.5-76.6l-149.3,145.5c-178.1,174.2-227.8,208.7-296.8,208.7c-124.4,0-225.9-113-199.1-222.1c9.6-36.4,151.3-193.4,428.9-472.9L6771-2148l388.7,386.7c214.4,214.4,402.1,411.6,417.4,440.4c42.1,82.3,32.5,157-28.7,224c-45.9,47.9-70.8,59.4-135.9,59.4c-78.5,0-91.9-9.6-268-181.9l-185.7-183.8l-11.5,124.5c-46,482.5-325.5,997.5-716.1,1313.4C5830.9,358.2,5270,522.9,4779.8,461.6z"></path><path d="M2811.6-1052.8c-365.7-367.6-415.5-425-415.5-478.6c0-88.1,36.4-151.3,105.3-185.7c34.5-15.3,72.8-28.7,86.2-28.7c53.6,0,155.1,72.8,298.7,214.4l155.1,153.2l11.5-227.8c17.2-323.6,90-570.5,250.8-853.9c275.7-482.5,752.4-830.9,1296.2-943.9c218.3-47.9,585.9-47.9,800.3-1.9c225.9,47.9,465.2,149.3,664.3,279.5c229.8,151.2,298.7,225.9,302.5,327.4c1.9,68.9-7.7,88.1-63.2,137.9c-91.9,82.3-174.2,68.9-342.7-61.3c-622.2-480.6-1510.6-425-2067.8,130.2c-281.4,279.6-461.4,708.4-463.3,1093.3v124.4l178.1-174.2c101.5-99.6,201-181.9,231.7-189.5c122.5-30.6,264.2,111,233.6,233.6c-9.6,36.4-153.2,195.3-419.3,463.3c-222.1,222.1-409.7,405.9-415.5,405.9C3232.8-635.4,3041.4-823.1,2811.6-1052.8z"></path>
                                    </g>
                                </svg>
                            </button>
                        </div>

                        <div className='d-flex gap-2'>
                            <input type="text" className="form-control form-control-solid px-2 flex-grow-1" value={serialInput} onChange={(e) => { setSerialInput(e.target.value.trim()) }}
                                onKeyDown={(e) => {
                                    if ((e.key === "Enter") && (serialInput != "")) {
                                        if (serialArray.includes(e.target.value)) {
                                            swal("Oops!", "Duplicate Serial cannot be selected", "warning")
                                            return
                                        }

                                        setSerialArray([...serialArray, e.target.value.trim()])
                                        setSerialInput("")
                                    }
                                }}
                            />
                            <button className='btn btn-primary'
                                onClick={(e) => {
                                    if (serialArray.includes(serialInput)) {
                                        swal("Oops!", "Duplicate Serial cannot be selected", "warning")
                                        return
                                    }

                                    setSerialArray([...serialArray, serialInput])
                                    setSerialInput("")
                                }}
                            >+&nbsp;Add</button>
                        </div>

                        <label className='fs-5 fw-bold pt-5 pb-1'>Added Serials</label>
                        {
                            serialArray.length === 0 ? <div>No serials added</div> :
                                <div className="d-flex align-items-center gap-2 gap-lg-3 flex-wrap">
                                    {
                                        serialArray.map((obj, index) => {
                                            return (
                                                <div key={index} className="d-flex gap-1 align-items-center bg-secondary px-3 rounded">
                                                    <span className="fs-3 fw-bold">{obj}</span>
                                                    <button className="btn px-2 text-hover-danger"
                                                        onClick={(e) => {
                                                            let t = [...serialArray]
                                                            t.splice(index, 1)
                                                            setSerialArray(t)
                                                        }}
                                                    ><i className="fa fa-trash fs-2 p-0" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddSerialModalClose() }}>Cancel</button>
                    <button className="btn btn-success rounded" data-kt-indicator={saveButtonState ? "on" : "off"} disabled={saveButtonState}
                        onClick={async () => {
                            if (serialArray.length !== goodsReceiveItemArray.find(x => x.item_id === modalRefProductId).received_qty) {
                                swal("Oops!", "Serial Number count mismatch", "warning")
                                return false
                            }

                            const allSerials = await getProductStockData(modalRefProductId)

                            const availableSerials = allSerials.filter(x => x.available_flag)

                            const alreadyPresent = {}

                            serialArray.forEach(x => {
                                const match = availableSerials.find(y => y.serialName === x)
                                if (match) {
                                    const key = `${match.warehouse_id},${match.branch_id}`;
                                    if (!alreadyPresent[key]) {
                                        alreadyPresent[key] = [];
                                    }
                                    alreadyPresent[key].push(x);
                                }
                            })
                            if (Object.entries(alreadyPresent).length > 0) {
                                swal({
                                    title: "Oops!",
                                    text: "Following Serial Numbers cannot be added 👇",
                                    icon: "warning",
                                    content: (
                                        <div>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                <div key={index} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")}</p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            )
                                            )}
                                        </div>
                                    )
                                })
                            } else {
                                const allHistorySerials = allSerials.filter(x => !x.available_flag && !x.committed_flag)

                                const previouslyAddedSerials = []

                                serialArray.forEach(x => {
                                    if (allHistorySerials.find(y => y.serialName === x)) {
                                        previouslyAddedSerials.push(x);
                                    }
                                })

                                if (previouslyAddedSerials.length > 0) {
                                    let willProceed = await swal({
                                        title: "Are you sure?",
                                        text: `${previouslyAddedSerials.join(", ")} were previously added. Would you still want to proceed with them?`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                    })
                                    if (!willProceed) return
                                }

                                let t = goodsReceiveItemArray.map(x => { return { ...x } })
                                let t2 = t.find(x => x.item_id === modalRefProductId)
                                t2.item_data = serialArray.length === 0 ? null : serialArray
                                setGoodsReceiveItemArray(t)

                                handleAddSerialModalClose()
                            }
                        }}
                    >
                        <span className="indicator-label">Save</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </Modal.Footer>
            </Modal >

            <Modal show={addBatchModalShow} onHide={() => { handleAddBatchModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Add Batches</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleAddBatchModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1'>
                        {
                            batchArray.length === 0 ? <div className='fs-3 text-gray-600 text-center'>No batches added</div> :
                                batchArray.map((obj, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <div className="row gx-0">
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Batch Reference</label>
                                                    <input type="text" className="form-control mb-2" placeholder="Enter Batch Reference" value={obj.batch_reference}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].batch_reference = e.target.value.trim()
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Manufacturer</label>
                                                    <input type="text" className="form-control mb-2" placeholder="Enter Manufacturer" value={obj.manufacturer}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].manufacturer = e.target.value.trim()
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Manufactured Date</label>
                                                    <input type="date" className="form-control" value={obj.manufactured_date}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].manufactured_date = e.target.value
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row gx-0 mb-3">
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Expiry Date</label>
                                                    <input type="date" className="form-control" value={obj.expiry_date}
                                                        onChange={(e) => {
                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].expiry_date = e.target.value
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <label className="required form-label">Batch Quantity</label>
                                                    <input type="number" min="0" className="form-control mb-2" value={obj.batch_quantity.toString()}
                                                        onChange={(e) => {
                                                            if (parseInt(e.target.value) < 0) {
                                                                swal("Oops!", "Quantity must be greater than 0", "warning")
                                                                return
                                                            }

                                                            let t = batchArray.map(x => { return { ...x } })
                                                            t[i].batch_quantity = e.target.value === "" ? 0 : parseInt(e.target.value)
                                                            setBatchArray(t)
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-1">
                                                    <button className='btn btn-danger rounded-pill mt-8' onClick={() => {
                                                        let t = batchArray.map(a => { return { ...a } })
                                                        t.splice(i, 1)
                                                        setBatchArray(t)
                                                    }}>✖</button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                        }
                        <button className='btn btn-primary' onClick={() => { setBatchArray([...batchArray, { batch_reference: "", manufacturer: "", manufactured_date: "", expiry_date: "", batch_quantity: 0 }]) }}>+ Add</button>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleAddBatchModalClose() }}>Cancel</button>
                    <button className="btn btn-success rounded" data-kt-indicator={saveButtonState ? "on" : "off"} disabled={saveButtonState}
                        onClick={async () => {
                            for (let i = 0; i < batchArray.length; i++) {
                                if (batchArray[i].batch_reference == "") {
                                    swal("Oops!", `Batch Reference required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].manufacturer == "") {
                                    swal("Oops!", `Manufacturer required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].manufactured_date == "") {
                                    swal("Oops!", `Manufactured Date required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].expiry_date == "") {
                                    swal("Oops!", `Expiry Date required for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (new Date(batchArray[i].expiry_date) < new Date(batchArray[i].manufactured_date)) {
                                    swal("Oops!", `Expiry Date must be greater than manufactured date for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                                if (batchArray[i].batch_quantity <= 0) {
                                    swal("Oops!", `Batch Quantity should be greater than 0 for Batch entry - ${i + 1}`, "warning")
                                    return
                                }
                            }

                            if (batchArray.reduce((p, o) => p + o.batch_quantity, 0) !== goodsReceiveItemArray.find(x => x.item_id === modalRefProductId).received_qty) {
                                swal("Oops!", "Batch quantity total mismatch", "warning")
                                return false
                            }

                            let batches = await getProductStockData(modalRefProductId)

                            const alreadyPresent = {}

                            batchArray.forEach(x => {
                                const match = batches.find(y => y.Batch_Reference === x.batch_reference)
                                if (match) {
                                    const key = `${match.warehouse_id},${match.branch_id}`;
                                    if (!alreadyPresent[key]) {
                                        alreadyPresent[key] = [];
                                    }
                                    alreadyPresent[key].push(x.batch_reference);
                                }
                            })
                            if (Object.entries(alreadyPresent).length > 0) {
                                swal({
                                    title: "Oops!",
                                    text: "Following Batches cannot be added 👇",
                                    icon: "warning",
                                    content: (
                                        <div>
                                            {Object.entries(alreadyPresent).map((obj, index) => (
                                                <div key={index} className="border-primary mb-3 d-flex flex-column" style={{ border: "2px solid #007bff", borderRadius: "0.5rem", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", padding: '20px', boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
                                                    {<p className="fw-bold d-flex align-items-center">{obj[1].join(", ")} </p>}
                                                    {<p className="my-1 d-flex align-items-center"><span className="bullet bg-danger me-2"></span>Already present in <span className="fw-bold px-1">{warehouseList.find(x => x.wid === obj[0].split(',')[0]).name}</span> of <span className="fw-bold px-1">{branchList.find(x => x.branch_id === obj[0].split(',')[1]).branch_name}</span></p>}
                                                </div>
                                            )
                                            )}
                                        </div>
                                    )
                                })
                            } else {
                                let t = goodsReceiveItemArray.map(x => { return { ...x } })
                                let t2 = t.find(x => x.item_id === modalRefProductId)
                                t2.item_data = batchArray.length === 0 ? null : batchArray
                                setGoodsReceiveItemArray(t)

                                handleAddBatchModalClose()
                            }
                        }}
                    >
                        <span className="indicator-label">Save</span>
                        <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal show={gstInListModalShow} onHide={() => { setGstInListModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title>GSTIN List</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setGstInListModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#f4f6f9" }}>
                    <div className='container'>
                        {
                            gstinList.length === 0 ?
                                <div className="overflow-auto pb-5">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
                                                <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                            <div className="mb-3 mb-md-0 fw-semibold">
                                                <h4 className="text-gray-900 fw-bold">No Data Available.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="d-flex flex-column gap-5">

                                        {
                                            gstinList.sort((a, b) => a.add_date_time - b.add_date_time).map((obj, index) => {
                                                return (
                                                    <div key={index} className="position-relative fv-row w-100 flex-md-root" style={{ cursor: "pointer" }} onClick={() => { setSelectedGstin(obj); setSelectedSourceOfSupply({ label: obj.place_of_supply, value: obj.place_of_supply }); setGstInListModalShow(false); }}>
                                                        <div className="card card-flush h-lg-100">
                                                            <div className="card-header mt-6">
                                                                <div className="card-title flex-column"><h3 className="fw-bolder mb-1">{obj.GstIn}</h3></div>
                                                            </div>
                                                            <div className="d-flex flex-wrap">
                                                                <div className="card-body p-9">
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.legal_name}</div>
                                                                        <div className="fw-semibold text-gray-400">Business Legal Name</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.gst_treatment}</div>
                                                                        <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                    </div>
                                                                    <div className="border border-dashed rounded min-w-90px py-3 px-4 mx-2 mb-3">
                                                                        <div className="fs-6 fw-bold text-gray-700">{obj.place_of_supply}</div>
                                                                        <div className="fw-semibold text-gray-400">Source of Supply</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            selectedGstin != null && selectedGstin.unique_id === obj.unique_id &&
                                                            <div className='position-absolute' style={{ top: "3rem", right: "7rem" }} >
                                                                <svg width="30" height="30" viewBox="0 0 64 64">
                                                                    <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setGstInListModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={termsModalShow} onHide={() => { handleNewTermsModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure Payment Terms</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleNewTermsModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Term Name</label>
                            </div>
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Number of Days</label>
                            </div>
                        </div>
                        {
                            termsPanelList.length > 0 ?
                                termsPanelList.map((x, indx) => {
                                    return (
                                        <div key={indx} className="row mb-2">
                                            <div className='col-5'>
                                                <input type="text" className="form-control form-control-solid" value={x.term_name}
                                                    onChange={(e) => {
                                                        let temp = termsPanelList.map(x => { return { ...x } })
                                                        temp[indx].term_name = e.target.value
                                                        setTermsPanelList(temp)
                                                    }}
                                                />
                                            </div>
                                            <div className='col-5'>
                                                <input type="number" min="0" className="form-control form-control-solid" value={x.no_of_days}
                                                    onChange={(e) => {
                                                        if (e.target.value >= 0) {
                                                            let temp = termsPanelList.map(x => { return { ...x } })
                                                            temp[indx].no_of_days = e.target.value
                                                            setTermsPanelList(temp)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='col-2 my-auto'>
                                                <button className='btn btn-sm btn-outline-danger rounded-pill border border-danger'
                                                    onClick={() => {
                                                        let temp = termsPanelList.map(a => { return { ...a } })
                                                        temp.splice(indx, 1)
                                                        setTermsPanelList(temp)
                                                    }}
                                                >&#10006;</button>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <p className='text-center'>No terms available. Create a new term</p>
                        }
                        <div className="row">
                            <div className='col-6'>
                                <button className='btn btn-link' onClick={() => { setTermsPanelList([...termsPanelList, { term_name: "", no_of_days: "" }]) }} >Add a term</button>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={termsSubmitButtonState ? "on" : "off"} disabled={termsSubmitButtonState} onClick={() => { newTermsSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={() => { handleNewTermsModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={currencyModalShow} onHide={() => { handleNewCurrencyModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Currency</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleNewCurrencyModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2 required">Currency Code</label>
                                <Select
                                    options={Object.keys(currency_arr.currency_arr[0]).map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyCode(e); setCurrencySymbol(currency_arr.currency_arr[0][e.value].currency_symbol); }}
                                    value={selectedCurrencyCode}
                                    placeholder="--Choose a Currency--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
                                <input type="text" className="form-control form-control-solid" value={currencySymbol} onChange={(e) => { setCurrencySymbol(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                                <Select
                                    options={[0, 2, 3].map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyDecimalPlace(e) }}
                                    value={selectedCurrencyDecimalPlace}
                                    placeholder="--Choose Decimal Places--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2">Format</label>
                                <Select
                                    options={["1,234,567.89", "1.234.567,89", "1 234 567,89"].map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyFormat(e) }}
                                    value={selectedCurrencyFormat}
                                    placeholder="--Choose a Format--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState ? "on" : "off"} disabled={currencySubmitButtonState} onClick={() => { newCurrencySubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={() => { handleNewCurrencyModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={AddressModalShow} onHide={() => { setAddressModalShow(false); }} centered>
                <Modal.Header>
                    <Modal.Title>Select {AddressType === "billing" ? "Billing" : "Shipping"} Address</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddressModalShow(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row gx-9 gy-6">
                            {
                                addressList.map((obj, indx) => {
                                    return (
                                        <div key={indx} className="card card-dashed flex-wrap p-6" style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (AddressType == "billing") {
                                                    setSelectedBillingAddress(obj)
                                                }
                                                else {
                                                    setSelectedShippingAddress(obj)
                                                }
                                                setAddressModalShow(false)
                                            }}
                                        >
                                            <div className="d-flex flex-column py-2">
                                                <div className="d-flex align-items-center fs-5 fw-bold mb-5">Address {indx + 1} {selectedBillingAddress != null && selectedBillingAddress.id === obj.id && "(Billing)"} {selectedShippingAddress != null && selectedShippingAddress.id === obj.id && "(Shipping)"} </div>
                                                <div className="fs-6 fw-semibold text-gray-600">
                                                    {obj.state}
                                                    <br />{obj.city},{obj.pincode}
                                                    <br />{obj.country}
                                                </div>
                                            </div>
                                            {
                                                (selectedBillingAddress != null && selectedShippingAddress != null) && (selectedBillingAddress.id === obj.id || selectedShippingAddress.id === obj.id) &&
                                                <div className='position-absolute' style={{ top: "1rem", right: "1rem" }} >
                                                    <svg width="30" height="30" viewBox="0 0 64 64">
                                                        <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setAddressModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={productCodeModalShow} onHide={() => { setProductCodeModalShow(false); setProductCodeItemIndex(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Find {productCodeItemType == "Goods" ? "HSN" : "SAC"} Code</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setProductCodeModalShow(false); setProductCodeItemIndex(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="container">

                            <label className="form-label">Search {productCodeItemType == "Goods" ? "HSN" : "SAC"} code for your item</label>
                            <div className="d-flex gap-1">
                                <input type="text" className="form-control" value={productCodeSearch} onChange={(e) => { setProductCodeSearch(e.target.value) }} />
                                <button className="btn btn-primary btn-sm rounded" onClick={(e) => { getProductCodeList(productCodeItemType, productCodeSearch) }} disabled={productCodeListApiState}>Search</button>
                            </div>

                            {
                                productCodeList.length > 0 && productCodeList.map((obj, index) => {
                                    return (
                                        <div key={index} className="align-items-center border border-primary cursor-pointer d-flex gap-2 my-2 p-1 rounded"
                                            onClick={() => {
                                                setProductCodeModalShow(false);
                                                let temp = itemArray.map(a => { return { ...a } })
                                                temp[productCodeItemIndex].item_popover_input = obj.codes
                                                setItemArray(temp)

                                                setProductCodeItemIndex(null);
                                                setProductCodeItemType(null);
                                                setProductCodeSearch("");
                                                setProductCodeList([]);
                                                setProductCodeListApiState(false);
                                            }}
                                        >
                                            <div className="symbol symbol-40px">
                                                <span className="symbol-label bg-light">
                                                    <span className="svg-icon svg-icon-2 svg-icon-primary">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <p className="fs-6 text-gray-800 text-hover-primary fw-semibold mb-1">{obj.code_description}</p>
                                                <span className="fs-7 text-muted fw-semibold">#{obj.codes}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default CreateBill;