import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import './date-range.css'

import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';
import { Collapse } from 'antd';

function Reports() {
    const [state, setstate] = useState(2);
    const [reportList, setReportList] = useState([])
    const [FilterReportList, setFilterReportList] = useState([])
    const [tempReportList, setTempReportList] = useState([])
    const [searchReport, setSearchReport] = useState('');
    const [activeKey, setActiveKey] = useState([]);
    const { Panel } = Collapse;

    var page_name = "reports";
    const { user, read, write, visibility, organization_id, user_role, rolePermissionDetails } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (rolePermissionDetails !== null) {
            setTempReportList(rolePermissionDetails.find(x => x.key == "My View").inside_components.find(x => (x.hasOwnProperty("key") && x.key == "all_report")).inside_components)
        }
    }, [rolePermissionDetails])

    useEffect(() => {
        if (tempReportList.length > 0) {
            const basicReports = [];
            const otherReports = [];

            for (const obj of tempReportList) {
                if (!obj.hasOwnProperty("key") && obj.read === 1 && obj.write === 1) {
                    basicReports.push({ report_name: "BASIC REPORT", pages: [{ page_name: Object.keys(obj)[0].replaceAll("-", " ").toUpperCase(), page_url: Object.keys(obj)[0] }] });
                } else {
                    const nestedReports = [];
                    if (obj.inside_components) {
                        for (const innerObj of obj.inside_components) {
                            if (innerObj.read === 1 && innerObj.write === 1) {
                                nestedReports.push({ page_name: Object.keys(innerObj)[0].replaceAll("-", " ").toUpperCase(), page_url: Object.keys(innerObj)[0] });
                            }
                        }
                    }
                    if (nestedReports.length > 0) {
                        otherReports.push({ report_name: obj.key.replaceAll("_", " ").toUpperCase(), pages: nestedReports });
                    }
                }
            }

            const basicReportObj = basicReports.length > 0 ? { report_name: "BASIC REPORT", pages: basicReports.reduce((acc, curr) => acc.concat(curr.pages), []) } : null;
            setstate(1);
            setReportList([basicReportObj, ...otherReports]);
            setFilterReportList([basicReportObj, ...otherReports]);
        }

    }, [tempReportList])

    function searchReports(searchTerm) {
        const results = [];
        searchTerm = searchTerm.toLowerCase(); // Convert search term to lowercase for case-insensitive matching

        for (const report of reportList) {
            const matchingPages = report.pages.filter(page => {
                const pageNameLower = page.page_name.toLowerCase();
                const pageUrlLower = page.page_url.toLowerCase();

                return (
                    pageNameLower.includes(searchTerm) ||
                    pageUrlLower.includes(searchTerm) ||
                    report.report_name.toLowerCase().includes(searchTerm)
                );
            });

            if (matchingPages.length) {
                results.push({
                    report_name: report.report_name,
                    pages: matchingPages,
                });
            } else if (searchTerm === "") { // Handle case where search term is empty (return all reports)
                results.push(report);
            }
        }

        return results;
    }

    useEffect(() => {
        if (searchReport !== "") {
            const searchRes = searchReports(searchReport);
            setFilterReportList(searchRes);
            setActiveKey(new Array(searchRes.length).fill(0).map((_, i) => i));
        }
        else {
            setFilterReportList(reportList);
            setActiveKey([]);
        }
    }, [searchReport])

    const handleChange = (key) => {
        const newActiveKey = activeKey.includes(key)
            ? activeKey.filter((item) => item !== key) // Reset for clicked key
            : key; // Update for newly clicked key
        setActiveKey(newActiveKey);
    };

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Reports</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body py-3">
                                            <div className='m-5'>
                                                <input type="text" className='form-control fw-bold border border-primary' value={searchReport} onChange={(e) => { setSearchReport(e.target.value) }} placeholder='Search Report...' />
                                            </div>
                                            <Collapse onChange={handleChange} activeKey={activeKey}>
                                                {
                                                    FilterReportList.length > 0 ?
                                                        <>
                                                            {
                                                                FilterReportList.map((report, index) => {
                                                                    return (
                                                                        <Panel header={report.report_name} key={index}>
                                                                            <div class="d-flex align-items-center gap-2 gap-lg-3 flex-wrap">
                                                                                {report.pages.map((page, index1) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Link to={page.page_url} class="btn btn-sm btn-light d-flex align-items-center">
                                                                                                <div class="text-gray-600 fw-bold">{page.page_name}</div>
                                                                                            </Link>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </Panel>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                    <div className="fs-6 text-gray-600"> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </Collapse>
                                        </div>
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <div className="card">
                                        <div className="card-body pt-6">
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        </div>
                                    </div>
                                    :
                                    <PageError />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reports;