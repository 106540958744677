import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';

function ForgotPassword(props) {
	const [password, setPassword] = useState("")
	const [repeatPassword, setRepeatPassword] = useState("")
	const [passwordRating, setPasswordRating] = useState(0)
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

	const passwordChecker = (password) => {

		let a = 0
		if (password.length >= 8) {
			a += 25
		}
		if (/[a-z][A-Z]/.test(password)) {
			a += 25
		}
		if (/[0-9]/.test(password)) {
			a += 25;
		}
		if (/[^a-zA-Z0-9]/.test(password)) {
			a += 25;
		}
		console.log(a);
		return a;
	}

	const register = (e) => {

		e.preventDefault();
		var object = {};
		object.password = password;
		object.cpassword = repeatPassword;
		object.changePasswordId = props.match.params.id;

		if (password === "") {
			swal("Warning!", "Password cannot be empty", "warning");
			return false;
		}

		if (password.length < 8) {
			swal("Warning!", "Password must have atleast 8 characters.", "warning");
			return false;
		}

		if (repeatPassword === "") {
			swal("Warning!", "Please confirm your password by re-typing.", "warning");
			return false;
		}

		if (repeatPassword !== password) {
			swal("Warning!", "Confirm and match both the passwords.", "warning");
			setRepeatPassword("")
			return false;
		}

		setMainSubmitButtonState(true);

		axios.post(`${process.env.REACT_APP_SERVER_URL}/forgot-password`, object).then((res) => {
			setMainSubmitButtonState(false);
			if (res.data.operation == "success") {
				swal("Great!", "Your password is successfully updated. ", "success", {
					buttons: {
						continue: {
							text: "OK",
							value: "continue",
						},
					}
				}).then((result) => {
					//console.log(result);
					switch (result) {
						case "continue":
							window.location = `/login`;
							break;
						default:
							window.location = `/login`;
							break;

					}
				});
			}
			else {
				if (res.data.message != "danger") {
					swal("Oops!", res.data.message, "error").then((res) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "something went wrong", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
	}


	return (
		<>
			<div className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bg-forgot-password bgi-no-repeat">
				<div className="d-flex flex-column flex-root" style={{ maxHeight: "100vh", height: "100vh" }}>
					<div className="d-flex flex-column flex-column-fluid flex-lg-row">
						<div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
							<div className="d-flex flex-center flex-lg-start flex-column mb-7">
								{/* <a href="index.html" className="mb-7"> */}
								<img alt="Logo" src={`${process.env.REACT_APP_LOGO}`} />
								{/* </a> */}
								<h2 className="text-white fw-normal m-0">Ultimate Business Management Solution!</h2>
							</div>
						</div>
						<div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
							<div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
								<div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
									<form className="form w-100">
										<div className="text-center mb-10">
											<h1 className="text-gray-900 fw-bolder mb-3">Setup New Password</h1>
											<div className="text-gray-500 fw-semibold fs-6">Have you already reset the password ?
												<a href="/login" className="link-primary fw-bold">Sign in</a></div>
										</div>
										<div className="fv-row mb-8">
											<div className="mb-1">
												<div className="position-relative mb-3">
													<input className="form-control bg-transparent" type={isPasswordVisible ? "text" : "password"} placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value); setPasswordRating(passwordChecker(e.target.value)) }} />
													<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 cursor-pointer" onClick={() => { setIsPasswordVisible(!isPasswordVisible) }}  >
														<i className={`bi fs-2 ${isPasswordVisible ? "bi-eye" : "bi-eye-slash"}`}></i>
													</span>
												</div>
												<div className="d-flex align-items-center mb-3">
													{
														(() => {
															let a = []
															if (passwordRating === 100) {
																a = ["#00774cd9", "#00774cd9", "#00774cd9", "#00774cd9"]
															}
															else if (passwordRating === 75) {
																a = ["#ffc700", "#ffc700", "#ffc700", "#959595d9"]
															}
															else if (passwordRating === 50) {
																a = ["#f27a00d9", "#f27a00d9", "#959595d9", "#959595d9"]
															}
															else if (passwordRating === 25) {
																a = ["#b60000bf", "#959595d9", "#959595d9", "#959595d9"]
															}
															else {
																a = ["#959595d9", "#959595d9", "#959595d9", "#959595d9"]
															}
															return <>
																<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[0] }}></div>
																<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[1] }}></div>
																<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[2] }}></div>
																<div className="flex-grow-1 rounded h-5px me-2" style={{ backgroundColor: a[3] }}></div>
															</>
														})()
													}
												</div>
											</div>
											{passwordRating < 100 && (
												<div className="text-muted">Use 8 or more characters with a mix of letters, numbers &amp; symbols.</div>
											)}
										</div>

										<div className="fv-row mb-8">
											<input className="form-control bg-transparent mb-2" placeholder="Confirm Password" value={repeatPassword} onChange={(e) => { setRepeatPassword(e.target.value) }} />
											{
												((repeatPassword !== "") && (password !== "") && (repeatPassword !== password)) ?
													<div className="text-danger">Password Mismatch.</div>
													:
													((repeatPassword !== "") && (password !== "") && (repeatPassword === password)) ?
														<div className="text-success">Password Match.</div>
														:
														<div className="text-muted">Confirm Password.</div>

											}
										</div>
										<div className="d-grid mb-10">
											<button type="button" onClick={(e) => { register(e) }} className="btn btn-primary">
												<span className="indicator-label">Submit</span>
												<span className="indicator-progress">Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
										</div>
									</form>
								</div>
								<div className="d-flex flex-stack px-lg-10">
									<div className="d-flex fw-semibold text-primary fs-base gap-5">
										<a href="https://www.spoterp.com/terms.html" target="_blank">Terms</a>
										<a href="https://www.spoterp.com/faq.html" target="_blank">FAQs</a>
										<a href="https://www.spoterp.com/erp-software-features.html" target="_blank">SpotERP Features</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default ForgotPassword;